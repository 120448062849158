import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { Widget } from 'daos/model_types';
import { bullseyePointerSolid, LpIcon } from 'features/common/lp_icon';
import { getOnClickViewOptionsForItemType } from 'features/dashboards_v2/widget/helpers';
import { useGetItemScopeForWidget } from 'features/dashboards_v2/widget/use_get_item_for_scope';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { OnClickViewDropdown } from 'features/dashboards_v2/widget_settings/on_click_view_dropdown';
import { Title } from 'features/dashboards_v2/widget_settings/title';
import { getItemForId } from 'state/entities/selectors/item';

export function ScheduleSummarySettings({ widget }: { widget: Widget }) {
  const { config } = widget;
  const { getFieldProps } = useFormikContext();
  const locationFilterItemId = getFieldProps<string | null>('locationFilterItemId').value;

  const widgetScopeItem = useGetItemScopeForWidget(widget);
  const formScopeItem = useSelector((state) =>
    locationFilterItemId ? getItemForId(state, Number(locationFilterItemId)) : undefined,
  );

  const scopeItemType = formScopeItem?.itemType ?? widgetScopeItem?.itemType;

  const includeOptions = getOnClickViewOptionsForItemType(scopeItemType, config.onClickView);

  return (
    <WidgetSettingsModalContent
      header={
        <>
          <LpIcon icon={bullseyePointerSolid} /> Schedule Summary
        </>
      }
      content={
        <>
          <Title widgetId={widget.id} />
          <LocationSetting disablePackageStatusSelection />
          <OnClickViewDropdown scopeItemType={scopeItemType} include={includeOptions} />
        </>
      }
    />
  );
}
