import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'semantic-ui-react';

import { PackageStatusIcon } from 'containers/shared/item_icon';
import ItemLink from 'containers/shared/item_link';
import LpLink from 'containers/shared/lp_link';
import { PackageStatus } from 'daos/enums';
import { LibraryResource } from 'daos/model_types';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, fileSpreadSheet, pollSolid } from 'features/common/lp_icon';
import { isLibraryResourceDashboard, libraryResourceClickThruRoute } from 'features/library/helpers';
import { SearchDashboardRowActionDropdown } from 'features/library/table/library_action_dropdown';
import { LibraryLocation } from 'features/library/types';
import { frontend } from 'lib/urls';
import { getLibraryLocationForLibraryResourceId } from 'state/entities/selectors/library_resources';

interface ResultLibraryResourceRowProps {
  libraryResource: LibraryResource;
  resultNumber: number;
}

export const ResultLibraryResource = ({ libraryResource, resultNumber }: ResultLibraryResourceRowProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const libraryLocation = useSelector((state) => getLibraryLocationForLibraryResourceId(state, libraryResource.id));

  const packageStatus = libraryResource.packageStatus;

  const collectionLinksByPackageStatus = useMemo(() => {
    return {
      [PackageStatus.SCHEDULED]: frontend.scheduledCollection.url({ organizationId, workspaceId }),
      [PackageStatus.BACKLOG]: frontend.pendingCollection.url({ organizationId, workspaceId }),
      [PackageStatus.ARCHIVED]: frontend.archiveCollection.url({ organizationId, workspaceId }),
      [PackageStatus.TEMPLATE]: frontend.templateCollection.url({ organizationId, workspaceId }),
      [PackageStatus.CAPACITY]: frontend.capacityCollection.url({ organizationId, workspaceId }),
    };
  }, [organizationId, workspaceId]);

  const dashboardUsedOnItem = useMemo(() => {
    if (libraryLocation === LibraryLocation.Collection && packageStatus) {
      return (
        <LpLink to={collectionLinksByPackageStatus[packageStatus]}>
          <PackageStatusIcon className="inherited-location__item-icon" packageStatus={packageStatus} />
          <span className="item-name">{packageStatusDisplayNameByPackageStatus[packageStatus]}</span>
        </LpLink>
      );
    } else {
      return <ItemLink itemId={Number(libraryResource.itemId)} showIcon openScheduleTab={false} />;
    }
  }, [collectionLinksByPackageStatus, libraryLocation, libraryResource.itemId, packageStatus]);

  const isDashboard = isLibraryResourceDashboard(libraryResource.libraryResourceType);
  const icon = isDashboard ? pollSolid : fileSpreadSheet;

  if (!libraryLocation) {
    return null;
  }

  const libraryResourceUrl = libraryResourceClickThruRoute({ libraryLocation, libraryResource });

  return (
    <List.Item className="lp-search__results-row">
      <div className="lp-search__results-row-count">{resultNumber.toString().padStart(2, '0')}</div>

      <div className="lp-search__results-row-icons">
        <LpIcon icon={icon} size="1x" color={`#${libraryResource.color}`} />
        <LpLink to={libraryResourceUrl} className="lp-search__results-row-icons-name-text">
          {libraryResource.name}
        </LpLink>
      </div>
      <div className="lp-search__results-row-on">on</div>
      {dashboardUsedOnItem}
      <SearchDashboardRowActionDropdown libraryResource={libraryResource} openRoute={libraryResourceUrl} />
    </List.Item>
  );
};
