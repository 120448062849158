import { SyncProjectJobErrorType } from 'daos/external_integration_enums';
import { ExternalIntegrationSyncErrorDetails } from 'daos/model_types';

export enum SyncErrorModalType {
  SyncError = 'syncError',
  PushTasksToJiraError = 'pushTasksToJiraError',
}

export interface GroupedPushTasksToJiraError {
  errorType: SyncProjectJobErrorType;
  errors: ReadonlyArray<PushTasksToJiraError>;
}

export enum SyncAndPushStatus {
  InProgress = 'inProgress',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}
export type JiraSyncPushError =
  | SyncProjectJobErrorType.IssueTypeUnset
  | SyncProjectJobErrorType.MandatoryFieldValueUnset
  | SyncProjectJobErrorType.IssuePushClosedSprint;

export interface PushTasksToJiraErrorOverview {
  readonly createdIssues: Array<{ id: string; key: string }>;
  readonly errors: ReadonlyArray<PushTasksToJiraError>;
}

export interface PushTasksToJiraError {
  readonly errorType: SyncProjectJobErrorType;
  readonly errorDetails: ExternalIntegrationSyncErrorDetails;
}
