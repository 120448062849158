import useHashParams from 'hooks/use_hash_params';
import useQueryParams from 'hooks/use_query_params';

export const useRedirectLocation = (): string | undefined => {
  const queryParams = useQueryParams();
  const hash = useHashParams();

  if (!('from' in queryParams)) {
    return;
  }
  const { from } = queryParams;
  let redirectLocation = from;
  if (Object.keys(hash).length) {
    redirectLocation += '#';
    Object.keys(hash).forEach((h) => {
      redirectLocation += `${h}=${hash[h]}&`;
    });
    redirectLocation = redirectLocation?.replace(/&$/, '');
  }
  return redirectLocation;
};
