import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { OrganizationUserDao } from 'daos/organization_user';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { GuestUser, GuestUsersTable } from 'features/guest/guest_users_table';
import { PlanGadgetSidebar } from 'features/organization_directory/manage_account/plan_gadget';
import {
  getOrganizationHubFilteredOrgUsers,
  getOrganizationHubUsersFetchComplete,
} from 'features/organization_directory/selectors';
import { ActionsSection } from 'features/organization_directory/users/actions_section';
import { OrgUsersQuickFilterType } from 'features/organization_directory/users/quick_filter';
import { getUsersById } from 'state/entities/selectors/user';

export const OrgDirUsersDashboardGuests = () => {
  //todo outpost goes here
  //https://relight-internal.liquidplannerlab.com/organization/19/workspace/19/item/539585

  const users = useSelector(getUsersById);
  const filteredOrgUsers = useSelector(getOrganizationHubFilteredOrgUsers);
  const fetchComplete = useSelector(getOrganizationHubUsersFetchComplete);

  const guestUsers = useMemo(() => {
    return filteredOrgUsers.reduce((acc: Array<GuestUser>, filteredOrgUser) => {
      acc.push({
        ...OrganizationUserDao.id(filteredOrgUser.id),
        userId: filteredOrgUser.user.id,
        userName: filteredOrgUser.username,
        email: users[filteredOrgUser.user.id]?.email ?? '',
        lastAccess: filteredOrgUser.lastAccessedAt ?? filteredOrgUser.createdAt,
      });
      return acc;
    }, []);
  }, [filteredOrgUsers, users]);

  return (
    <div className="organization-directory__users-grid">
      <ActionsSection filterType={OrgUsersQuickFilterType.DASHBOARD_GUESTS} showAdd={false} />
      <div className="organization-directory__users-table-area">
        {!fetchComplete && <LpOverlayLoader />}
        <GuestUsersTable guestUsers={guestUsers} />
      </div>
      <PlanGadgetSidebar includeContactSupport={true} />
    </div>
  );
};
