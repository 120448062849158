import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UploadFile, UploadFileDao } from 'daos/upload_file';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';

export const useImageUpload = (setS3Id: (s3Id: string) => void) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const handleImageUpload = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const imageFile = files?.[0];

    if (imageFile) {
      const { uuid } = dispatch(UploadFileDao.dashboardImage({ organizationId, workspaceId }, imageFile));

      dispatch(
        awaitRequestFinish<UploadFile>(uuid, {
          onSuccess: ({ data }) => {
            setS3Id(data.s3Id);
          },
        }),
      );
    }
  };

  return { handleImageUpload };
};
