export const NovaInitiativeSvg = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="initiative" clipPath="url(#clip0_3165_5669)">
        <path
          id="Vector"
          d="M4 1.1499C2.51793 1.1499 1.25 2.30056 1.25 3.7999V15.1999C1.25 16.6992 2.51793 17.8499 4 17.8499H16C17.4821 17.8499 18.75 16.6992 18.75 15.1999V3.7999C18.75 2.30056 17.4821 1.1499 16 1.1499H4Z"
          stroke="black"
          strokeWidth="1.5"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.18312 11.4924C8.13371 11.4019 8.03629 11.2905 7.94904 11.1899L7.59774 10.7889C7.46034 10.635 7.3242 10.4822 7.19295 10.3238C6.48426 9.46226 6.23142 8.49734 6.44168 7.45491C6.75655 5.89352 8.25049 4.75674 9.99237 4.75C11.7622 4.75674 13.2562 5.89352 13.571 7.45491C13.7813 8.49734 13.5285 9.46226 12.8198 10.3238C12.691 10.48 12.556 10.6327 12.4199 10.7844L12.1415 11.1023C12.0005 11.2663 11.8717 11.4247 11.8437 11.5651C11.8361 11.6017 11.832 11.6377 11.8298 11.6736V13.1267C11.8298 13.7468 11.2852 14.25 10.6143 14.25H9.39869C8.72769 14.25 8.18312 13.7468 8.18312 13.1267V11.4924ZM10.8353 10.8801C10.9365 10.7062 11.0623 10.5517 11.1873 10.4047L11.4814 10.0711L11.5383 10.0069C11.6447 9.88695 11.7511 9.76705 11.8522 9.64311C12.3518 9.03653 12.5232 8.38838 12.3761 7.66161C12.1707 6.64614 11.1557 5.87779 10.0154 5.8733C8.85701 5.87779 7.84206 6.64614 7.63662 7.66161C7.49076 8.38838 7.66214 9.03653 8.16049 9.64311C8.2145 9.7085 8.26948 9.773 8.32512 9.83701C8.39464 9.91705 8.4652 9.99631 8.53613 10.0756L8.8254 10.4058C8.96466 10.5643 9.09776 10.7165 9.20371 10.8801H10.8353Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3165_5669">
          <rect width="20" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
