import { useSelector } from 'react-redux';

import { ScheduleDirective } from 'daos/enums';
import { getHasPredecessors, getHasSuccessors } from 'state/entities/selectors/dependency';
import { getItemForId } from 'state/entities/selectors/item';

export const useIconsForItem = (itemId: number) => {
  const item = useSelector((state) => getItemForId(state, itemId));
  const hasPredecessors = useSelector((state) => getHasPredecessors(state, itemId));
  const hasSuccessors = useSelector((state) => getHasSuccessors(state, itemId));

  const asapStatus = item?.scheduleDirective ?? ScheduleDirective.NORMAL;

  return {
    asapStatus,
    hasSuccessorOrPredecessor: hasPredecessors || hasSuccessors,
    isScheduleLate: !!item?.late,
  };
};
