import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { CustomFieldType } from 'daos/enums';
import { CustomField, CustomFieldValue } from 'daos/model_types';
import { PicklistChoiceDao } from 'daos/picklist_choice';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  addFieldValue,
  getAvailableFieldValueOptions,
  getOrgUserIdsForWsUserIds,
  getSelectedFieldValueTypeIds,
  removeFieldValue,
  updateFieldValue,
} from 'features/common/data_grid/add_edit_grid/multi_field_value_modal/helpers';
import PicklistDropdown from 'features/common/inputs/dropdowns/picklist_dropdown';
import UserDropdown from 'features/common/inputs/dropdowns/user_dropdown';
import { frontend } from 'lib/urls';
import { getActivePrioritizedPicklistChoicesForFieldId } from 'state/entities/selectors/custom_field';
import {
  getNonGuestOrganizationUsersActiveInCurrentWorkspace,
  getCurrentWsUserToOrgUserMapping,
  getCurrentOrgUserToWsUserMapping,
} from 'state/entities/selectors/user';

interface ContentProps {
  customField: CustomField;
  fieldValues: ReadonlyArray<Partial<CustomFieldValue>>;
  setFieldValues: Dispatch<SetStateAction<ReadonlyArray<Partial<CustomFieldValue>>>>;
}

export const Content = (props: ContentProps) => {
  switch (props.customField.fieldType) {
    case CustomFieldType.MULTI_PICKLIST:
      return <MultiPicklistContent {...props} />;
    case CustomFieldType.MULTI_USER:
      return <MultiUserContent {...props} />;
    default:
      return null;
  }
};

function MultiPicklistContent({ customField, fieldValues, setFieldValues }: ContentProps) {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const picklistChoices = useSelector((state) => getActivePrioritizedPicklistChoicesForFieldId(state, customField.id));

  if (!picklistChoices.length) {
    return (
      <>
        Picklist values haven&apos;t been set up yet. They can be added in{' '}
        <LpLink to={frontend.customizeDataFields.url({ organizationId, workspaceId })}>Custom Data Fields</LpLink>.
      </>
    );
  }
  const selectedPicklistChoiceIds = getSelectedFieldValueTypeIds(fieldValues, 'picklistChoice');

  const availablePicklistOptions = getAvailableFieldValueOptions(selectedPicklistChoiceIds, picklistChoices);

  const handleChangeValue =
    (fieldValue: Partial<CustomFieldValue>) =>
    (_: SyntheticEvent, { value: picklistChoiceId }: { value: number | undefined }) => {
      if (!picklistChoiceId) {
        setFieldValues(removeFieldValue(fieldValue, 'picklistChoice'));
      } else {
        const newResource = PicklistChoiceDao.id(picklistChoiceId);
        setFieldValues(updateFieldValue(fieldValue, newResource, 'picklistChoice'));
      }
    };

  const handleAddValue = (_: SyntheticEvent, { value: picklistChoiceId }: { value: number | undefined }) => {
    if (picklistChoiceId) {
      const newResource = PicklistChoiceDao.id(picklistChoiceId);
      setFieldValues(addFieldValue(newResource, 'picklistChoice', customField));
    }
  };

  return (
    <>
      {fieldValues.map((fieldValue) => {
        const picklistChoiceId = fieldValue.picklistChoice?.id;
        return (
          <PicklistDropdown
            className="multi-field-value-modal__dropdown"
            fieldChoiceId={picklistChoiceId}
            key={picklistChoiceId}
            onChange={handleChangeValue(fieldValue)}
            picklist={availablePicklistOptions(picklistChoiceId)}
          />
        );
      })}

      {!!availablePicklistOptions(undefined)?.length && (
        <PicklistDropdown
          className="multi-field-value-modal__dropdown"
          onChange={handleAddValue}
          picklist={availablePicklistOptions(undefined)}
          placeHolder="Add"
        />
      )}
    </>
  );
}

function MultiUserContent({ customField, fieldValues, setFieldValues }: ContentProps) {
  const orgUsers = useSelector(getNonGuestOrganizationUsersActiveInCurrentWorkspace);
  const orgUserIdsByWsUserIds = useSelector(getCurrentWsUserToOrgUserMapping);
  const wsUserIdsByOrgUserIds = useSelector(getCurrentOrgUserToWsUserMapping);

  const selectedWorkspaceUserIds = getSelectedFieldValueTypeIds(fieldValues, 'workspaceUserValue');
  const selectedOrgUserIds = getOrgUserIdsForWsUserIds(selectedWorkspaceUserIds, orgUserIdsByWsUserIds);

  const availableOrgUserOptions = getAvailableFieldValueOptions(selectedOrgUserIds, orgUsers);

  const handleChangeValue =
    (fieldValue: Partial<CustomFieldValue>) =>
    (_: SyntheticEvent, { value: orgUserId }: { value: number | undefined }) => {
      if (!orgUserId) {
        setFieldValues(removeFieldValue(fieldValue, 'workspaceUserValue'));
      } else {
        const wsUserId = wsUserIdsByOrgUserIds[orgUserId] ?? 0;
        const newResource = WorkspaceUserDao.id(wsUserId);
        setFieldValues(updateFieldValue(fieldValue, newResource, 'workspaceUserValue'));
      }
    };

  const handleAddValue = (_: SyntheticEvent, { value: orgUserId }: { value: number | undefined }) => {
    if (orgUserId) {
      const wsUserId = wsUserIdsByOrgUserIds[orgUserId] ?? 0;
      const newResource = WorkspaceUserDao.id(wsUserId);
      setFieldValues(addFieldValue(newResource, 'workspaceUserValue', customField));
    }
  };

  return (
    <>
      {fieldValues.map((fieldValue) => {
        const wsUserId = fieldValue.workspaceUserValue?.id ?? 0;
        const orgUserId = orgUserIdsByWsUserIds[wsUserId];

        return (
          <UserDropdown
            className="multi-field-value-modal__dropdown"
            clearable
            key={wsUserId}
            onChange={handleChangeValue(fieldValue)}
            orgUsers={availableOrgUserOptions(orgUserId)}
            placeholder=""
            selectedOrgUserId={orgUserId}
          />
        );
      })}

      {!!availableOrgUserOptions(undefined).length && (
        <UserDropdown
          className="multi-field-value-modal__dropdown multi-field-value-modal__dropdown--add-user"
          clearable={false}
          onChange={handleAddValue}
          orgUsers={availableOrgUserOptions(undefined)}
          placeholder="Add"
        />
      )}
    </>
  );
}
