import useHashParams from 'hooks/use_hash_params';

import { IterationsTabKey } from './types';

export const useIterationModalParams = <T extends { iterationPanelSection: IterationsTabKey }>() => {
  const { iterationId: iterationModalIdParam, panelSection: iterationPanelSection } = useHashParams();

  const iterationModalId = isNaN(Number(iterationModalIdParam)) ? 0 : Number(iterationModalIdParam);

  return {
    iterationModalId,
    iterationPanelSection,
  } as T & { iterationModalId: number };
};
