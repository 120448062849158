import { useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { SelectionDropdownOptions } from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { getCurrentOrganizationUser, getCurrentWorkspaceUser } from 'state/entities/selectors/user';

import { SelectDropdownRenderer } from '.';

interface UserTypeSelectionDropdownProps {
  className?: string;
  disabled?: boolean;
  onSelectClick: (value: SelectionDropdownOptions) => void;
  tabIndex: number;
}

const userTypeMenuProps = [
  SelectionDropdownOptions.UnselectAll,
  SelectionDropdownOptions.SelectResources,
  SelectionDropdownOptions.SelectPlaceholders,
  SelectionDropdownOptions.SelectMembers,
].map((selection) => ({
  text: selection,
  value: selection,
}));

export const UserTypeSelectionDropdown = (props: UserTypeSelectionDropdownProps) => {
  const { className, disabled, onSelectClick, tabIndex } = props;
  const currentWorkspaceUser = useSelector(getCurrentWorkspaceUser);
  const currentOrgUser = useSelector(getCurrentOrganizationUser);

  const isWorkspaceManager = !!currentWorkspaceUser?.manager;
  const isAdmin = !!currentOrgUser?.admin;

  const handleSelectClick = (_: React.SyntheticEvent, { value }: DropdownItemProps) => {
    onSelectClick(value as SelectionDropdownOptions);
  };

  const menuItems = userTypeMenuProps.map((dropdownProps) => {
    return (
      <Dropdown.Item
        {...dropdownProps}
        key={dropdownProps.value}
        disabled={!isWorkspaceManager || (!isAdmin && dropdownProps.value === SelectionDropdownOptions.SelectMembers)}
        onClick={handleSelectClick}
      />
    );
  });

  return <SelectDropdownRenderer className={className} disabled={disabled} menuItems={menuItems} tabIndex={tabIndex} />;
};
