import { filterLight } from 'features/common/lp_icon';

import LpSearchInput, { LpSearchOptionalProps } from '.';

import './index.scss';

interface LpSearchInputControlledProps extends LpSearchOptionalProps {
  initialValue: string;
  onChange: (
    _: React.SyntheticEvent<Element, Event>,
    {
      value,
    }: {
      value: string;
    },
  ) => void;
  onClear: () => void;
  isDirty: boolean;
}

const LpSearchInputControlled = ({
  initialValue,
  onChange,
  onClear,
  isDirty,
  className,
  onKeyDown,
  autoFocus = false,
  disabled = false,
  icon = filterLight,
  placeholder = 'Filter',
}: LpSearchInputControlledProps) => {
  const calcClearInputIfEnabled = () => !disabled && onClear();

  return (
    <LpSearchInput
      onKeyDown={onKeyDown}
      className={className}
      onClear={calcClearInputIfEnabled}
      onChange={onChange}
      initialValue={initialValue}
      isDirty={isDirty}
      autoFocus={autoFocus}
      disabled={disabled}
      icon={icon}
      placeholder={placeholder}
    />
  );
};

export default LpSearchInputControlled;
