import { useCallback, useMemo } from 'react';

import MultiSelectModal from 'containers/shared/multi_select_modal';
import { resetAvailableOptions } from 'containers/shared/multi_select_modal/multi_select_helpers';
import { SelectionList } from 'features/common/selection_list/types';

interface TimesheetExportCustomColumnModalProps {
  onClose: () => void;
  defaultTsExportGridColumns: ReadonlyArray<SelectionList>;
  allGridColumns: ReadonlyArray<SelectionList>;
  selectedGridColumns: ReadonlyArray<SelectionList>;
  updateTimesheetExportGridSelectedColumns: (selectedColumns: ReadonlyArray<SelectionList>) => void;
}

const TimesheetExportCustomColumnModal = ({
  onClose,
  defaultTsExportGridColumns,
  allGridColumns,
  selectedGridColumns,
  updateTimesheetExportGridSelectedColumns,
}: TimesheetExportCustomColumnModalProps) => {
  const initAvailableOptions = resetAvailableOptions([...allGridColumns], [...selectedGridColumns]);

  const handleAddUsersToReview = useCallback(
    (_available: Array<SelectionList>, selected: Array<SelectionList>) => {
      const selectedColumns = selected.length ? [...selected] : [...allGridColumns];
      updateTimesheetExportGridSelectedColumns(selectedColumns);
      onClose();
    },
    [allGridColumns, onClose, updateTimesheetExportGridSelectedColumns],
  );
  const resetColumns = useMemo(() => {
    const defaultColumnIds = defaultTsExportGridColumns.map((defaultCol) => defaultCol.id);

    return {
      selected: defaultTsExportGridColumns,
      available: allGridColumns.filter((col) => !defaultColumnIds.includes(col.id)),
    };
  }, [allGridColumns, defaultTsExportGridColumns]);

  return selectedGridColumns && allGridColumns ? (
    <MultiSelectModal
      closeModal={onClose}
      initAvailableOptions={initAvailableOptions}
      initSelectedOptions={selectedGridColumns}
      onCloseAction={handleAddUsersToReview}
      resetColumnsOptions={resetColumns}
      availableTitle="Available"
      selectedTitle="Selected"
    />
  ) : null;
};

export default TimesheetExportCustomColumnModal;
