import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserType } from 'daos/enums';
import { WorkspaceUserFilter } from 'daos/filter_properties';
import { User, WorkspaceUser } from 'daos/model_types';
import { filterAnd, filterNull } from 'daos/shared';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { awaitRequestFinish } from 'lib/api';
import { getUsersById } from 'state/entities/selectors/user';
import { EntityLookupById } from 'state/entities/types';

const initialWorkspaceUserCounts = {
  [UserType.Member]: 0,
  [UserType.Placeholder]: 0,
  [UserType.Resource]: 0,
  guest: 0,
};

export const useFetchWorkspaceUserCountsOnOrganizationModified = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const usersById = useSelector(getUsersById);

  const [workspaceUserCounts, setWorkspaceUserCounts] = useState(initialWorkspaceUserCounts);

  const fetchWorkspaceUserCounts = useCallback(() => {
    const { uuid } = dispatch(
      WorkspaceUserDao.fetchAll(
        { organizationId, workspaceId },
        {
          filter: filterAnd(filterNull(WorkspaceUserFilter.DisconnectedAt, true)),
          include: { includeUser: true, includeFields: true },
        },
      ),
    );

    dispatch(
      awaitRequestFinish<ReadonlyArray<WorkspaceUser>>(uuid, {
        onSuccess: ({ data }) => {
          const counts = data.reduce(reduceWorkspaceUsersByType(usersById), { ...initialWorkspaceUserCounts });
          setWorkspaceUserCounts(counts);
        },
      }),
    );
  }, [dispatch, organizationId, usersById, workspaceId]);

  useEffect(() => fetchWorkspaceUserCounts(), [fetchWorkspaceUserCounts]);

  useEvents({
    event: Events.ORGANIZATION_MODIFIED,
    callback: fetchWorkspaceUserCounts,
    scope: EventScopes.None,
  });

  return { workspaceUserCounts, fetchWorkspaceUserCounts };
};

function reduceWorkspaceUsersByType(usersById: EntityLookupById<User>) {
  return function (acc: typeof initialWorkspaceUserCounts, wsUser: WorkspaceUser): typeof initialWorkspaceUserCounts {
    const userType = usersById[wsUser.user.id]?.userType;

    if (userType) {
      if (wsUser.guestUser) {
        acc.guest = acc.guest + 1;
      } else {
        acc[userType] = acc[userType] + 1;
      }
    }

    return acc;
  };
}
