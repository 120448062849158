import classNames from 'classnames';
import { noop } from 'lodash';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Menu } from 'semantic-ui-react';

import { StatusFilterGroups, WorkloadDayRange } from 'daos/enums';
import { LocationFilterAssigneeSettings } from 'daos/types';
import GoBackToDashboardButton from 'features/common/buttons/go_back_to_dashboard_button';
import { CustomItemFilterLocation } from 'features/common/custom_item_filter/types';
import { AssignedFilterDropdown } from 'features/common/inputs/dropdowns/assigned_filter_dropdown';
import { FolderStatusFilterDropdown } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown';
import { FolderFilterStatus } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';
import {
  useMapOrgUsersAndGroupsToDropdownOptions,
  useMapOrgUsersForGroupIdToDropdownOptions,
} from 'features/common/inputs/dropdowns/hooks/use_map_org_users_and_groups_to_options';
import { TaskStatusFilterDropdown } from 'features/common/inputs/dropdowns/task_status_filter_dropdown';
import LpSearchInputUncontrolled from 'features/common/inputs/lp_search_input/lp_search_input_uncontrolled';
import { circleQuestionSolid, LpIcon } from 'features/common/lp_icon';
import { isWorkloadFilterFiltered } from 'features/common/workload/shared';
import { useHasGeneralTeamBasedPlanning } from 'hooks/use_has_feature';
import { getIsGuestCurrentWorkspaceUser } from 'state/entities/selectors/user';
import { lpGray500 } from 'style/variables';

import { WorkloadDayRangeDropdown } from './workload_day_range_dropdown';

import './workload_top_controls.scss';

interface AssignedDropdownProps {
  className: string;
  onChange: (newValue: Partial<LocationFilterAssigneeSettings>) => void;
  value: LocationFilterAssigneeSettings;
}
const ItemAssignedDropdown = ({ className, onChange, value }: AssignedDropdownProps) => {
  const options = useMapOrgUsersAndGroupsToDropdownOptions();

  return <AssignedFilterDropdown className={className} onChange={onChange} options={options} value={value} />;
};

interface GroupAssignedDropdownProps extends AssignedDropdownProps {
  groupId: number;
}
const GroupAssignedDropdown = ({ className, groupId, onChange, value }: GroupAssignedDropdownProps) => {
  const options = useMapOrgUsersForGroupIdToDropdownOptions(groupId);

  return <AssignedFilterDropdown className={className} onChange={onChange} options={options} value={value} />;
};

interface WorkloadTopControlsProps {
  assignedFilterValue?: LocationFilterAssigneeSettings;
  dayRange: WorkloadDayRange;
  expandAllUsers: () => void;
  groupId?: number;
  handleAssignedFilterValueChange?: (newValue: Partial<LocationFilterAssigneeSettings>) => void;
  includePlannedWork: boolean;
  includeProjectFilter?: boolean;
  inputValue: string;
  isWidget?: boolean;
  setDayRange: Dispatch<SetStateAction<WorkloadDayRange>>;
  setDebouncedValue: RefObject<(value: string) => void>;
  setIncludePlannedWork: Dispatch<SetStateAction<boolean>>;
}
export const WorkloadTopControls = ({
  assignedFilterValue,
  dayRange,
  expandAllUsers,
  groupId,
  handleAssignedFilterValueChange = noop,
  includePlannedWork,
  includeProjectFilter = true,
  inputValue,
  isWidget = false,
  setDayRange,
  setDebouncedValue,
  setIncludePlannedWork,
}: WorkloadTopControlsProps) => {
  const isGuest = useSelector(getIsGuestCurrentWorkspaceUser);

  const handleFilterInputChange = (value: string) => {
    setDebouncedValue.current?.(value);
    expandAllUsers();
  };

  const hasGeneralTeamBasedPlanningFeature = useHasGeneralTeamBasedPlanning();
  const togglePlannedHours = () => setIncludePlannedWork((prev) => !prev);

  return (
    <Menu borderless className="lp-portfolio-grid-top-controls" secondary>
      {isWidget && (
        <Menu.Item fitted>
          <GoBackToDashboardButton />
        </Menu.Item>
      )}
      <Menu.Item fitted>
        <WorkloadDayRangeDropdown dayRange={dayRange} setDayRange={setDayRange} />
      </Menu.Item>
      <Menu.Item fitted>
        <LpSearchInputUncontrolled initialValue={inputValue} onChange={handleFilterInputChange} />
      </Menu.Item>
      <Menu.Menu position="right">
        {hasGeneralTeamBasedPlanningFeature && (
          <Menu.Item fitted className="lp-portfolio-grid-top-controls__hide-planned-hours">
            <Checkbox
              checked={!includePlannedWork}
              onChange={togglePlannedHours}
              label="Hide Planned Hours"
              disabled={isGuest}
            />
            <LpIcon icon={circleQuestionSolid} size="sm" color={lpGray500} />
          </Menu.Item>
        )}
        {!isWidget && (
          <>
            <Menu.Item fitted>
              <TaskStatusFilterDropdown
                customItemFilterLocation={CustomItemFilterLocation.Workload}
                dataIsAllActive
                defaultTaskStatus={StatusFilterGroups.Active}
              />
            </Menu.Item>
            {includeProjectFilter && (
              <Menu.Item fitted>
                <FolderStatusFilterDropdown
                  customItemFilterLocation={CustomItemFilterLocation.Workload}
                  dataIsAllActive
                  defaultFolderStatus={FolderFilterStatus.Scheduled}
                />
              </Menu.Item>
            )}
            {!!assignedFilterValue && (
              <Menu.Item fitted>
                {groupId ? (
                  <GroupAssignedDropdown
                    className={classNames({
                      'v2-dashboard-filter__assigned-filter': true,
                      'v2-dashboard-filter__assigned-filter--filtered': isWorkloadFilterFiltered(assignedFilterValue),
                    })}
                    groupId={groupId}
                    onChange={handleAssignedFilterValueChange}
                    value={assignedFilterValue}
                  />
                ) : (
                  <ItemAssignedDropdown
                    className={classNames({
                      'v2-dashboard-filter__assigned-filter': true,
                      'v2-dashboard-filter__assigned-filter--filtered': isWorkloadFilterFiltered(assignedFilterValue),
                    })}
                    onChange={handleAssignedFilterValueChange}
                    value={assignedFilterValue}
                  />
                )}
              </Menu.Item>
            )}
          </>
        )}
      </Menu.Menu>
    </Menu>
  );
};
