import { noop } from 'lodash';
import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import ConfirmDeleteModal from 'containers/shared/confirm_delete_modal';
import { ItemDao } from 'daos/item';
import { BulkOptionPopupContent, BulkOptionPopup } from 'features/common/bulk_selection/options/option_popup';
import { getBulkSelectedItemIds } from 'features/common/bulk_selection/selectors';
import { setJobId } from 'features/common/bulk_selection/slice';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { JobEventData } from 'features/common/events/types';
import { LpIcon, trashXmarkSolid } from 'features/common/lp_icon';
import { awaitRequestFinish } from 'lib/api';
import { pluralize } from 'lib/helpers';

interface SharedProps {
  disabled: boolean;
  onDeleteSuccess?: () => void;
}

interface DeleteBulkItemsProps extends SharedProps {
  setEstimateModalAssignmentId?: Dispatch<SetStateAction<number | undefined>>;
}

interface ItemPanelDeleteProps extends SharedProps {
  itemPanelId: number;
}

type DeleteButtonProps = DeleteBulkItemsProps & { selectedItemIds: ReadonlyArray<number> };

export const confirmItemDeleteModalProps = (selectedItemIds: ReadonlyArray<number>) => {
  const count = selectedItemIds.length;
  if (count === 1) {
    return {
      itemId: selectedItemIds[0],
    };
  } else {
    return {
      selectedIds: selectedItemIds,
      deleteTargetName: count ? `${count} ${pluralize('item', count)}` : '',
    };
  }
};

const DeleteItemsButtonAndModal = ({
  disabled,
  selectedItemIds,
  onDeleteSuccess = noop,
  setEstimateModalAssignmentId,
}: DeleteButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const toggleModal = () => setIsOpen((isOpen) => !isOpen);

  const handleDelete = useDeleteItems({ onDeleteSuccess, selectedItemIds });

  return (
    <>
      <BulkOptionPopup content={BulkOptionPopupContent.Delete}>
        <Button
          className="icon"
          disabled={disabled}
          data-testid={'item-delete-button'}
          onClick={toggleModal}
          size="small"
        >
          <LpIcon icon={trashXmarkSolid} />
        </Button>
      </BulkOptionPopup>

      {isOpen && (
        <ConfirmDeleteModal
          onDelete={handleDelete}
          onClose={handleClose}
          setEstimateModalAssignmentId={setEstimateModalAssignmentId}
          {...confirmItemDeleteModalProps(selectedItemIds)}
        />
      )}
    </>
  );
};

export const DeleteBulkItemsButton = ({ disabled, setEstimateModalAssignmentId }: DeleteBulkItemsProps) => {
  const bulkSelectedItemIds = useSelector(getBulkSelectedItemIds);
  return (
    <DeleteItemsButtonAndModal
      disabled={disabled}
      selectedItemIds={bulkSelectedItemIds}
      setEstimateModalAssignmentId={setEstimateModalAssignmentId}
    />
  );
};

export const ItemPanelDeleteButton = ({ disabled, itemPanelId, onDeleteSuccess }: ItemPanelDeleteProps) => (
  <DeleteItemsButtonAndModal disabled={disabled} selectedItemIds={[itemPanelId]} onDeleteSuccess={onDeleteSuccess} />
);

export const useDeleteItems = ({
  onDeleteSuccess,
  selectedItemIds,
}: {
  onDeleteSuccess: () => void;
  selectedItemIds: ReadonlyArray<number>;
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  return useCallback(() => {
    const { uuid } = dispatch(ItemDao.destroyBulk({ organizationId, workspaceId }, [...selectedItemIds]));
    dispatch(
      awaitRequestFinish<JobEventData>(uuid, {
        onSuccess: ({ data }) => {
          dispatch(setJobId(data.jobId));
          onDeleteSuccess();
        },
      }),
    );
  }, [dispatch, onDeleteSuccess, organizationId, selectedItemIds, workspaceId]);
};
