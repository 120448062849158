import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { DropdownItemProps } from 'semantic-ui-react';

import { FilterStatusType } from 'daos/enums';
import { CostCode } from 'daos/model_types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpFormDropdownOptionsProps } from 'features/common/forms/lp_form_dropdown';
import { readonlyArray, groupRecordBy } from 'lib/readonly_record';
import { EntityLookupById } from 'state/entities/types';
import { RootState } from 'state/root_reducer';

const emptyCostCodeArray = readonlyArray<CostCode>([]);

export const getCostCodesById = (state: RootState): EntityLookupById<CostCode> => state.entities.costCodes;

export const getCostCodeForId = (state: RootState, id: number) => getCostCodesById(state)[id];

const getCostCodesByWorkspaceId = createSelector(getCostCodesById, (costCodesById) => {
  return groupRecordBy(costCodesById, (costCode) => costCode.workspace.id);
});

const getCurrentWorkspaceCostCodes = createSelector(
  getCostCodesByWorkspaceId,
  getCurrentWorkspaceId,
  (costCodesByWorkspace, currentWorkspaceId) => {
    return costCodesByWorkspace[currentWorkspaceId] ?? emptyCostCodeArray;
  },
);

const getCurrentWorkspaceCostCodesSortedByName = createSelector(getCurrentWorkspaceCostCodes, (costCodes) => {
  return readonlyArray(sortBy(costCodes, (costCode) => costCode.name));
});

export const getActiveCurrentWorkspaceCostCodesSortedByName = createSelector(
  getCurrentWorkspaceCostCodesSortedByName,
  (costCodes) => {
    return readonlyArray(costCodes.filter((costCode) => !costCode.archived));
  },
);

export const getActiveCostCodeOptions = createSelector(
  getActiveCurrentWorkspaceCostCodesSortedByName,
  (costCodes): Array<DropdownItemProps> => {
    return costCodes.map((costCode) => {
      return {
        content: costCode.name,
        text: costCode.name,
        value: costCode.id,
        search: costCode.name,
        key: costCode.name.toLowerCase(),
      };
    });
  },
);

export const getActiveCostCodeRateOptions = createSelector(
  getActiveCurrentWorkspaceCostCodesSortedByName,
  (costCodes): Array<LpFormDropdownOptionsProps> => {
    return costCodes.reduce<Array<LpFormDropdownOptionsProps>>((prev, costCode) => {
      const optionalTextWithSpace = costCode.billable ? ' (Billable)' : '';

      prev.push({
        key: `${costCode.id}`,
        search: `${costCode.name.toLowerCase()}${optionalTextWithSpace}`,
        text: `${costCode.name}${optionalTextWithSpace}`,
        value: costCode.id,
      });

      return prev;
    }, []);
  },
);

const getArchivedCurrentWorkspaceCostCodesSortedByName = createSelector(
  getCurrentWorkspaceCostCodesSortedByName,
  (costCodes) => {
    return readonlyArray(costCodes.filter((costCode) => costCode.archived));
  },
);

export const getCostCodesForFilter = createSelector(
  (state: RootState) => state,
  (_: RootState, costCodeFilter: FilterStatusType) => costCodeFilter,
  (state, costCodeFilter) => {
    switch (costCodeFilter) {
      case FilterStatusType.INUSE:
        return getActiveCurrentWorkspaceCostCodesSortedByName(state);
      case FilterStatusType.ARCHIVED:
        return getArchivedCurrentWorkspaceCostCodesSortedByName(state);
      case FilterStatusType.ALL:
        return getCurrentWorkspaceCostCodesSortedByName(state);
    }
  },
);
