import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { forwardRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';

import { WidgetGroupSize } from 'daos/enums';
import { getWidgetGroupForId } from 'features/dashboards/selectors';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { Widgets } from 'features/dashboards_v2/dashboard/widgets';
import { WidgetId } from 'features/dashboards_v2/widget/types';
import { AddWidgetToWidgetGroupButton } from 'features/dashboards_v2/widget_group/add';
import { WidgetGroupToolbar } from 'features/dashboards_v2/widget_group/toolbar';

import './index.scss';

interface WidgetGroupProps {
  dashboardId: string;
  widgetGroupId: string;
  widgetIds: Array<WidgetId>;
  index: number;
  isDragging?: boolean;
  isOverlay?: boolean;
  layoutId?: string;
  draggableRef?: (el: HTMLElement | null) => void;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}
export const WidgetGroup = memo(
  forwardRef<HTMLDivElement, WidgetGroupProps>(
    (
      {
        dashboardId,
        widgetGroupId,
        widgetIds,
        index,
        isDragging,
        layoutId,
        isOverlay,
        draggableRef,
        attributes,
        listeners,
      },
      ref,
    ) => {
      const group = useSelector((state) => getWidgetGroupForId(state, widgetGroupId));
      const { inDesign, activeGroupId } = useDashboardContext();

      if (!group) {
        return null;
      }

      const gridColumn = `span ${group.widgetGroupSize === WidgetGroupSize.Half ? 1 : 2}`;

      const groupTitle = group.title ? <h3 className="widget-group__title-header">{group.title}</h3> : null;

      const header = inDesign ? (
        <WidgetGroupToolbar
          attributes={attributes}
          dashboardId={dashboardId}
          draggableRef={draggableRef}
          listeners={listeners}
          widgetGroupId={widgetGroupId}
        />
      ) : (
        groupTitle
      );

      return (
        <motion.div
          ref={ref}
          layoutId={layoutId}
          layout={!isOverlay}
          style={{
            gridColumn,
          }}
          className={classNames(
            'widget-group',
            isDragging && 'widget-group--dragging',
            isOverlay && 'widget-group--overlay',
            inDesign && 'widget-group--design',
            activeGroupId && 'widget-group--hidden',
          )}
        >
          {header}

          <motion.div className="widget-group-body">
            <Widgets
              key={widgetGroupId}
              dashboardId={dashboardId}
              widgetGroupId={widgetGroupId}
              widgetGroupIndex={index}
              widgetIds={widgetIds}
              isOverlay={isOverlay}
            />
          </motion.div>
          {inDesign && <AddWidgetToWidgetGroupButton widgetGroupId={widgetGroupId} />}
        </motion.div>
      );
    },
  ),
  areEqual,
);
