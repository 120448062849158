import classNames from 'classnames';
import { useInView, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WidgetSize, WidgetType } from 'daos/enums';
import { WidgetDao } from 'daos/widgets';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import './index.scss';
import { gray200, gray500 } from 'style/variables';

const FETCH_DELAY = 1000;

interface WidgetLoadProps {
  id: number;
  dashboardId: string;
  type: WidgetType;
  size: WidgetSize;
}

export const WidgetLoad = ({ id, dashboardId, type, size }: WidgetLoadProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const ref = useRef(null);
  const isInView = useInView(ref);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isInView) {
      const timeoutId = setTimeout(() => {
        if (!loading) {
          dispatch(
            WidgetDao.fetch(
              {
                organizationId,
                workspaceId,
                dashboardId: String(dashboardId),
                widgetId: String(id),
              },
              { include: { includeDashboard: true, includeLibraryResource: true, includeLocationFilterItem: true } },
            ),
          );
          setLoading(true);
        }
      }, FETCH_DELAY);
      return () => clearTimeout(timeoutId);
    }
  }, [dashboardId, dispatch, id, isInView, loading, organizationId, workspaceId]);

  return (
    <motion.div
      ref={ref}
      className={classNames('widget-loading', type?.toString(), size?.toString())}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
    >
      <div className="header">
        <Skeleton className="skeleton-title" />
        <div className="icons">
          <Skeleton className="skeleton-icon" />
          <Skeleton className="skeleton-icon" />
          <Skeleton className="skeleton-icon" />
        </div>
      </div>
      <div className="body">
        <Skeleton className="skeleton-body" />
      </div>
    </motion.div>
  );
};

const Skeleton = ({ className }: { className: string }) => {
  return (
    <motion.div
      className={`skeleton ${className}`}
      animate={{
        backgroundColor: [gray500, gray200],
      }}
      transition={{
        duration: 1.5,
        times: [0, 1.5],
        repeat: Infinity,
        repeatType: 'reverse',
      }}
    />
  );
};
