import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ExternalIntegrationDao } from 'daos/external_integration';
import { JiraAdvancedFiltersDao } from 'daos/jira_advanced_filters';
import { JiraProjectsDao } from 'daos/jira_projects';
import { JiraResourcesDao } from 'daos/jira_resources';
import { ConnectionInformation, JiraIntegration, JiraOauthAccount, JiraProjectDetails } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import {
  JiraProjectIssueCount,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  JiraTabKey,
  SyncErrorMessage,
  JiraProject,
  JiraFieldMappings,
  jiraFieldDateMapping,
  JiraAdvancedFiltering,
} from 'features/jira_project/modal/types';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import isDateValid from 'lib/helpers/date_valid';
import { ISO_DATE_FORMAT } from 'lib/localization';

import { getFiltersFromAdvancedFilters } from './sections/project_and_issues/advanced_filters/utils';

export const useGetJiraProjectModalScreenData = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const { oauthCredentialsId: oauthCredentialsIdFromReturnUrl } = useQueryParams();
  const {
    activeTab,
    isEditMode,
    setJiraAdvancedFilters,
    setJiraOAuthAccounts,
    setFormError,
    setJiraIntegrations,
    setJiraProjects,
    setJiraProjectDetails,
    setJiraProjectMappingFieldData,
    setJiraProjectMappingIssueTypes,
    setConnectionInformation,
    integrationId: externalIntegrationId,
    setIssueCount,
    setUserCount,
    setCredentialsError,
    setAddedJiraFieldMappingRowItems,
    setIsLoadingJiraProjects,
    setIsLoadingMappingScreenData,
  } = useJiraProjectModalContext();

  const oauthCredentialsId = values[JiraProjectModalFormFields.OauthCredentialsId];
  const cloudId = values[JiraProjectModalFormFields.CloudId];
  const selectedJiraProjectId = values[JiraProjectModalFormFields.JiraProjectId];
  const jiraProjectId = values[JiraProjectModalFormFields.JiraProjectId];
  const selectedIssueTypeIds = values[JiraProjectModalFormFields.IssueTypeIds];
  const selectedStatusIds = values[JiraProjectModalFormFields.IssueStatusIds];
  const selectedParentIssueIdsOrKeys = values[JiraProjectModalFormFields.ParentIssueIdsOrKeys];
  const selectedJiraDateField = values[JiraProjectModalFormFields.IssueCreatedDate];
  const selectedAdvancedFilters = values[JiraProjectModalFormFields.AdvancedFilters];

  const canFetchAccessibleResources = !!oauthCredentialsId;
  const canFetchProjects = !!cloudId && !!oauthCredentialsId;
  const canFetchProjectDetails = !!selectedJiraProjectId && !!oauthCredentialsId;
  const canFetchProjectMappingFields = isEditMode
    ? !!jiraProjectId && !!externalIntegrationId
    : !!jiraProjectId && selectedIssueTypeIds?.length > 0 && !!oauthCredentialsId && !!cloudId;
  const canFetchExternalIntegrationConnectionInformation = !!externalIntegrationId && !!oauthCredentialsId;
  const canFetchIssueAndUserCount =
    !!selectedJiraProjectId &&
    !!oauthCredentialsId &&
    !!cloudId &&
    (isEditMode || activeTab === JiraTabKey.ProjectAndIssuesFilter);
  const canFetchAdvancedFilters = !!selectedJiraProjectId && !!oauthCredentialsId && !!cloudId;

  const isValidDate = isDateValid(selectedJiraDateField, ISO_DATE_FORMAT, true);

  const getJiraOAuthAccounts = useCallback(() => {
    const getJiraAccountViewsFn = JiraResourcesDao.getJiraOAuthAccounts({ organizationId, workspaceId });

    const { uuid } = dispatch(getJiraAccountViewsFn);
    dispatch(
      awaitRequestFinish<ReadonlyArray<JiraOauthAccount>>(uuid, {
        onSuccess: ({ data }) => {
          setJiraOAuthAccounts(data);
          const matchingOauthAccount = data.find(
            (account) => account.oauthCredentialsId === Number(oauthCredentialsIdFromReturnUrl),
          );
          if (matchingOauthAccount) {
            setFieldValue(JiraProjectModalFormFields.OauthCredentialsId, matchingOauthAccount.oauthCredentialsId);
          }
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            setFormError(errors[0]?.detail ?? errors[0].title);
          }
        },
      }),
    );
  }, [
    dispatch,
    organizationId,
    workspaceId,
    setFormError,
    setJiraOAuthAccounts,
    oauthCredentialsIdFromReturnUrl,
    setFieldValue,
  ]);

  const getJiraAccessibleResources = useCallback(() => {
    if (!canFetchAccessibleResources) {
      return;
    }
    const jiraAccessibleResourcesFetchFn = JiraResourcesDao.getJiraAccessibleResources(
      {
        organizationId,
        workspaceId,
      },
      { query: { oauthCredentialsId } },
    );
    const { uuid } = dispatch(jiraAccessibleResourcesFetchFn);
    dispatch(
      awaitRequestFinish<ReadonlyArray<JiraIntegration>>(uuid, {
        onSuccess: ({ data }) => {
          setJiraIntegrations(data);
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            setFormError(errors[0]?.detail ?? errors[0].title);
          }
        },
      }),
    );
  }, [
    dispatch,
    organizationId,
    workspaceId,
    oauthCredentialsId,
    setFormError,
    setJiraIntegrations,
    canFetchAccessibleResources,
  ]);

  const fetchJiraProjects = useCallback(() => {
    if (!canFetchProjects) {
      return;
    }

    setIsLoadingJiraProjects(true);

    const jiraProjectsFetchFn = JiraProjectsDao.fetchJiraProjects(
      { organizationId, workspaceId },
      { oauthCredentialsId, cloudId },
    );

    const { uuid } = dispatch(jiraProjectsFetchFn);

    dispatch(
      awaitRequestFinish<ReadonlyArray<JiraProject>>(uuid, {
        onSuccess: ({ data }) => {
          setJiraProjects(data);
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            setFormError(errors[0]?.detail ?? errors[0].title);
          }
        },
        onFinish: () => {
          setIsLoadingJiraProjects(false);
        },
      }),
    );
  }, [
    canFetchProjects,
    setIsLoadingJiraProjects,
    organizationId,
    workspaceId,
    oauthCredentialsId,
    cloudId,
    dispatch,
    setJiraProjects,
    setFormError,
  ]);

  const fetchJiraProjectDetails = useCallback(() => {
    if (!canFetchProjectDetails) {
      return;
    }
    const jiraProjectDetailsFetchFn = JiraProjectsDao.fetchJiraProjectDetails(
      { organizationId, workspaceId, projectId: selectedJiraProjectId },
      { oauthCredentialsId, cloudId },
    );
    const { uuid } = dispatch(jiraProjectDetailsFetchFn);

    dispatch(
      awaitRequestFinish<JiraProjectDetails>(uuid, {
        onSuccess: ({ data }) => {
          setJiraProjectDetails(data);
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            setFormError(errors[0]?.detail ?? errors[0].title);
          }
        },
      }),
    );
  }, [
    dispatch,
    oauthCredentialsId,
    cloudId,
    organizationId,
    selectedJiraProjectId,
    workspaceId,
    setJiraProjectDetails,
    canFetchProjectDetails,
    setFormError,
  ]);

  const fetchJiraProjectMappingFields = useCallback(() => {
    if (!canFetchProjectMappingFields) {
      return;
    }
    const jiraIntegrationsFetchFn = JiraProjectsDao.fetchJiraProjectMappingFieldsWithIssueTypes(
      { organizationId, workspaceId },
      { cloudId, oauthCredentialsId, projectIdOrKey: jiraProjectId, issueTypeIds: selectedIssueTypeIds },
    );

    const { uuid } = dispatch(jiraIntegrationsFetchFn);
    setIsLoadingMappingScreenData(true);
    dispatch(
      awaitRequestFinish<JiraFieldMappings>(uuid, {
        onSuccess: ({ data: { issueTypes, fields } }) => {
          setJiraProjectMappingFieldData(fields);
          setJiraProjectMappingIssueTypes(issueTypes);
          if (!isEditMode) {
            const dueDateOption = fields.find((fieldMapping) => fieldMapping.id === 'duedate') ?? jiraFieldDateMapping;
            setAddedJiraFieldMappingRowItems(new Set([dueDateOption]));
          }
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            setFormError(errors[0]?.detail ?? errors[0].title);
          }
        },
        onFinish: () => {
          setIsLoadingMappingScreenData(false);
        },
      }),
    );
  }, [
    dispatch,
    organizationId,
    workspaceId,
    oauthCredentialsId,
    cloudId,
    jiraProjectId,
    selectedIssueTypeIds,
    setJiraProjectMappingFieldData,
    canFetchProjectMappingFields,
    setFormError,
    isEditMode,
    setAddedJiraFieldMappingRowItems,
    setJiraProjectMappingIssueTypes,
    setIsLoadingMappingScreenData,
  ]);

  const getExternalIntegrationConnectionInformation = useCallback(() => {
    if (!canFetchExternalIntegrationConnectionInformation) {
      return;
    }
    const externalIntegrationDetailsFn = ExternalIntegrationDao.getExternalIntegrationConnectionInformation({
      organizationId,
      workspaceId,
      externalIntegrationId,
    });
    const { uuid } = dispatch(externalIntegrationDetailsFn);

    dispatch(
      awaitRequestFinish<ConnectionInformation>(uuid, {
        onSuccess: ({ data }) => {
          if (!data?.email) {
            setCredentialsError(SyncErrorMessage.DisconnectedUser);
          }
          setConnectionInformation(data);
        },
      }),
    );
  }, [
    dispatch,
    externalIntegrationId,
    canFetchExternalIntegrationConnectionInformation,
    organizationId,
    workspaceId,
    setConnectionInformation,
    setCredentialsError,
  ]);

  const fetchJiraProjectIssueCount = useCallback(() => {
    if (!canFetchIssueAndUserCount) {
      return;
    }
    const jiraProjectIssuesCountFetchFn = JiraProjectsDao.fetchJiraIssuesCount(
      { organizationId, workspaceId },
      {
        cloudId,
        oauthCredentialsId,
        projectIdOrKey: selectedJiraProjectId,
        statusIds: selectedStatusIds,
        typeIds: selectedIssueTypeIds,
        parentIssueIdsOrKeys: selectedParentIssueIdsOrKeys,
        createdAfter: isValidDate ? selectedJiraDateField : '',
        advancedFilters: selectedAdvancedFilters,
      },
    );

    const { uuid } = dispatch(jiraProjectIssuesCountFetchFn);

    dispatch(
      awaitRequestFinish<JiraProjectIssueCount>(uuid, {
        onSuccess: ({ data }) => {
          setIssueCount(data.count);
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            setFormError(errors[0]?.detail ?? errors[0].title);
          }
        },
      }),
    );
  }, [
    selectedJiraProjectId,
    canFetchIssueAndUserCount,
    oauthCredentialsId,
    cloudId,
    organizationId,
    workspaceId,
    selectedStatusIds,
    selectedIssueTypeIds,
    selectedParentIssueIdsOrKeys,
    selectedJiraDateField,
    selectedAdvancedFilters,
    isValidDate,
    dispatch,
    setIssueCount,
    setFormError,
  ]);

  const fetchJiraProjectUserCount = useCallback(() => {
    if (!canFetchIssueAndUserCount) {
      return;
    }
    const jiraProjectUserCountFetchFn = JiraProjectsDao.fetchJiraProjectUserCount(
      { organizationId, workspaceId },
      {
        cloudId,
        oauthCredentialsId,
        projectId: selectedJiraProjectId,
      },
    );

    const { uuid } = dispatch(jiraProjectUserCountFetchFn);

    dispatch(
      awaitRequestFinish<number>(uuid, {
        onSuccess: ({ data }) => {
          setUserCount(data);
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            setFormError(errors[0]?.detail ?? errors[0].title);
          }
        },
      }),
    );
  }, [
    selectedJiraProjectId,
    canFetchIssueAndUserCount,
    oauthCredentialsId,
    cloudId,
    organizationId,
    workspaceId,
    dispatch,
    setFormError,
    setUserCount,
  ]);

  const getAdvancedFilters = useCallback(() => {
    if (!canFetchAdvancedFilters) {
      return;
    }
    const getFiltersFn = JiraAdvancedFiltersDao.fetchJiraAdvancedFilters(
      { organizationId, workspaceId },
      { cloudId, oauthCredentialsId },
      { projectIds: [jiraProjectId] },
    );
    const { uuid } = dispatch(getFiltersFn);
    dispatch(
      awaitRequestFinish<JiraAdvancedFiltering>(uuid, {
        onSuccess: ({ data: { visibleFieldNames: advancedFilters } }) => {
          const filters = getFiltersFromAdvancedFilters(advancedFilters);
          setJiraAdvancedFilters(filters);
        },
      }),
    );
  }, [
    setJiraAdvancedFilters,
    organizationId,
    workspaceId,
    dispatch,
    cloudId,
    oauthCredentialsId,
    canFetchAdvancedFilters,
    jiraProjectId,
  ]);

  useEffect(() => {
    getJiraOAuthAccounts();
  }, [getJiraOAuthAccounts]);

  useEffect(() => {
    if (canFetchAccessibleResources) {
      getJiraAccessibleResources();
    }
  }, [getJiraAccessibleResources, canFetchAccessibleResources]);

  useEffect(() => {
    if (canFetchProjects) {
      fetchJiraProjects();
    }
  }, [fetchJiraProjects, canFetchProjects]);

  useEffect(() => {
    if (canFetchProjectDetails) {
      fetchJiraProjectDetails();
    }
  }, [fetchJiraProjectDetails, canFetchProjectDetails]);

  useEffect(() => {
    if (canFetchProjectMappingFields) {
      fetchJiraProjectMappingFields();
    }
  }, [fetchJiraProjectMappingFields, canFetchProjectMappingFields]);

  useEffect(() => {
    if (canFetchExternalIntegrationConnectionInformation) {
      getExternalIntegrationConnectionInformation();
    }
  }, [getExternalIntegrationConnectionInformation, canFetchExternalIntegrationConnectionInformation]);

  useEffect(() => {
    if (canFetchIssueAndUserCount) {
      fetchJiraProjectIssueCount();
    }
  }, [fetchJiraProjectIssueCount, canFetchIssueAndUserCount]);

  useEffect(() => {
    if (canFetchIssueAndUserCount) {
      fetchJiraProjectUserCount();
    }
  }, [fetchJiraProjectUserCount, canFetchIssueAndUserCount]);

  useEffect(() => {
    if (canFetchAdvancedFilters) {
      getAdvancedFilters();
    }
  }, [getAdvancedFilters, canFetchAdvancedFilters]);
};
