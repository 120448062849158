const SESSION_STORAGE_KEY = '__utm';

export enum MarketingTagKeys {
  referer = 'referer',
  utm_campaign = 'utm_campaign',
  utm_content = 'utm_content',
  utm_medium = 'utm_medium',
  utm_source = 'utm_source',
  utm_term = 'utm_term',
}

export type MarketingTags = {
  [key in MarketingTagKeys]?: string;
};

export const setMarketingTags = (value: MarketingTags) => {
  setValueOnSessionStore(value);
};

export const getMarketingTags = () => {
  return getFromSessionStore();
};

function getFromSessionStore(): MarketingTags {
  try {
    const currentValue = sessionStorage.getItem(SESSION_STORAGE_KEY) || '{}';
    return JSON.parse(currentValue);
    // eslint-disable-next-line unused-imports/no-unused-vars
  } catch (_err) {
    return {};
  }
}

function setValueOnSessionStore(value: MarketingTags) {
  const originalValue = getFromSessionStore();
  const newValue = { ...originalValue, ...value };

  try {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newValue));
    // eslint-disable-next-line unused-imports/no-unused-vars
  } catch (_err) {
    return;
  }
}
