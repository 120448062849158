import { useFormikContext } from 'formik';
import moment from 'moment-timezone';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { DateRangeType, LocationFilterSettings } from 'daos/types';
import { defaultLocationConfig } from 'daos/widgets';
import LpModal from 'features/common/modals/lp_modal';
import { getWidgetForId, getWidgetGroupForId } from 'features/dashboards/selectors';
import { InheritedLocation } from 'features/dashboards_v2/common/inherited_location';
import { isDefaultFilter } from 'features/dashboards_v2/common/location_filters';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { ItemFilter, ItemFilterProps } from 'features/dashboards_v2/item_filter/index';
import {
  getLocationScopeAndFiltersForWidgetGroupId,
  getLocationScopeForDashboardId,
} from 'features/dashboards_v2/selectors';
import { useGetDisplayDateRanges } from 'lib/date_range';

import './item_filter.scss';

interface ItemFilterModalProps extends Omit<ItemFilterProps, 'onChange'> {
  combinesWithDashboardFilter?: boolean;
  combinesWithWidgetGroupFilter?: boolean;
  showDateRangeFilter?: boolean;
  dataIsAllActive?: boolean;
  onApply: (value: Partial<LocationFilterSettings>) => void;
  onCancel: () => void;
  title: string;
}

function isDateRangePresent(from: string | null, to: string | null) {
  if (!from || !to) {
    return false;
  }
  return true;
}
const isDateRangeValid = (to: string | null, from: string | null) => {
  if (moment(to).isSameOrAfter(moment(from))) {
    return true;
  } else {
    return false;
  }
};

export const ItemFilterModal = ({
  className,
  combinesWithDashboardFilter = false,
  combinesWithWidgetGroupFilter = false,
  disableLocationFilter = false,
  disableProjectFilter = false,
  dataIsAllActive = false,
  showDateRangeFilter = false,
  includeUnassigned,
  includeDisconnected,
  inheritedLocationScope,
  onApply,
  onCancel,
  showLocationFilter = false,
  title,
  values,
}: ItemFilterModalProps) => {
  const { isSubmitting } = useFormikContext();
  const [state, setState] = useState(values);
  const [dateRangeError, setDateRangeError] = useState(false);

  const handleApply = () => {
    if (state.dateRangeFilterType === DateRangeType.CalendarDates) {
      if (
        isDateRangePresent(state.dateRangeFilterFrom, state.dateRangeFilterTo) &&
        isDateRangeValid(state.dateRangeFilterTo, state.dateRangeFilterFrom)
      ) {
        setDateRangeError(false);
        onApply(state);
      } else {
        setDateRangeError(true);
      }
    } else {
      onApply(state);
    }
  };
  const handleCancel = () => onCancel();

  const handleChange = useCallback(
    (newValue: Partial<LocationFilterSettings>) => {
      setDateRangeError(false);
      setState((prev) => ({ ...prev, ...newValue }));
    },
    [setState],
  );

  const handleReset = () =>
    setState({
      ...values,
      ...defaultLocationConfig,
    });

  const { showSettingsForWidget: widgetId, dashboard } = useDashboardContext();
  const widget = useSelector((state) => getWidgetForId(state, widgetId ?? ''));
  const widgetGroup = useSelector((state) => getWidgetGroupForId(state, widget?.widgetGroupId ?? ''));

  const { widgetGroupLocationScope, groupIsFiltered } = useSelector((state) =>
    getLocationScopeAndFiltersForWidgetGroupId(state, widgetId ?? ''),
  );
  const { dashboardDateRange, widgetGroupDateRange } = useGetDisplayDateRanges(dashboard, widgetGroup ?? null);

  const dashboardLocationScope = useSelector((state) =>
    dashboard?.id ? getLocationScopeForDashboardId(state, dashboard.id.toString()) : undefined,
  );

  const dashboardIsFiltered = dashboard ? !isDefaultFilter(dashboard.config, false) : false;

  return (
    <LpModal
      size="mini"
      className="v2-dashboard-filter-modal"
      header={title}
      content={
        <>
          {combinesWithDashboardFilter && (
            <InheritedLocation
              classname="v2-dashboard-filter__supporting-text"
              name="Combines with Dashboard Scope and Date Range Filter:"
              isFiltered={dashboardIsFiltered}
              locationScope={dashboardLocationScope}
              dateRange={dashboardDateRange}
            />
          )}

          {combinesWithWidgetGroupFilter && (
            <InheritedLocation
              classname="v2-dashboard-filter__supporting-text"
              name="Combines with Group Scope and Date Range Filter:"
              isFiltered={groupIsFiltered}
              locationScope={widgetGroupLocationScope}
              dateRange={widgetGroupDateRange}
            />
          )}

          <ItemFilter
            className={className}
            disableLocationFilter={disableLocationFilter}
            disableProjectFilter={disableProjectFilter}
            dataIsAllActive={dataIsAllActive}
            includeUnassigned={includeUnassigned}
            includeDisconnected={includeDisconnected}
            inheritedLocationScope={inheritedLocationScope}
            showLocationFilter={showLocationFilter}
            onChange={handleChange}
            values={state}
            disableDateRangeFilter={!showDateRangeFilter}
            dateRangeError={dateRangeError}
          />
        </>
      }
      actions={[
        <Button
          className="reset-button"
          key="reset"
          floated="left"
          content="Reset"
          onClick={handleReset}
          type="button"
        />,
        <Button key="cancel" content="Cancel" onClick={handleCancel} type="button" />,
        <Button disabled={isSubmitting} key="save" primary content="Apply" type="submit" onClick={handleApply} />,
      ]}
    />
  );
};
