import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Breadcrumb } from 'semantic-ui-react';

import ItemIcon from 'containers/shared/item_icon';
import LpLink from 'containers/shared/lp_link';
import { ItemType } from 'daos/enums';
import { Item } from 'daos/model_types';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { usePackageCollectionUrlForPackageStatus } from 'hooks/use_package_collection_url_for_package_status';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { frontend } from 'lib/urls';
import { getItemForId, getPackageStatusForItemId, getAncestryForItemId } from 'state/entities/selectors/item';

interface ItemPanelBreadcrumbProps {
  isClickable: boolean;
  itemId: number;
}

interface BreadcrumbSectionProps {
  itemId?: number;
  name?: string | null;
  url?: string;
  useIcon?: boolean;
}

const MAX_SECTIONS = 4;
const MAX_SECTIONS_WITH_ELLIPSIS = 5;

const BreadcrumbSection = ({ itemId, name, url, useIcon }: BreadcrumbSectionProps) => (
  <Breadcrumb.Section>
    {useIcon && <ItemIcon itemId={itemId} size="sm" />}
    <Breadcrumb.Section {...(url ? { to: url, as: LpLink } : {})} content={name} />
    <Breadcrumb.Divider />
  </Breadcrumb.Section>
);

const BreadcrumbSections = ({ itemId, isClickable }: ItemPanelBreadcrumbProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, itemId));
  const packageStatusName = packageStatus ? packageStatusDisplayNameByPackageStatus[packageStatus] : '';
  const packageCollectionUrl = usePackageCollectionUrlForPackageStatus(packageStatus);
  const allItemsUrl = frontend.projectsAll.url({ workspaceId, organizationId });
  const ancestors = useSelector((state) => getAncestryForItemId(state, itemId))
    .filter((ancestor) => ancestor.itemType !== ItemType.WORKSPACE_ROOTS && ancestor.id !== itemId)
    .reverse();

  const breadcrumbSections = ancestors.reduce((acc: Array<ReactNode>, ancestor: Item) => {
    if (acc.length < MAX_SECTIONS_WITH_ELLIPSIS) {
      const url = isClickable
        ? ancestor.itemType === ItemType.PACKAGES
          ? frontend.singlePackage.url({ workspaceId, organizationId, packageId: ancestor.id })
          : frontend.projectProject.url({ workspaceId, organizationId, itemId: ancestor.id })
        : undefined;

      acc.unshift(
        <BreadcrumbSection
          key={ancestor.id}
          itemId={ancestor.id}
          name={acc.length < MAX_SECTIONS ? ancestor.name : HORIZONTAL_ELLIPSIS}
          useIcon={acc.length < MAX_SECTIONS}
          url={url}
        />,
      );
    }

    return acc;
  }, []);

  if (breadcrumbSections.length < MAX_SECTIONS_WITH_ELLIPSIS) {
    breadcrumbSections.unshift(
      <BreadcrumbSection
        key={packageStatusName}
        name={breadcrumbSections.length === MAX_SECTIONS ? HORIZONTAL_ELLIPSIS : packageStatusName}
        url={isClickable ? packageCollectionUrl : undefined}
      />,
    );
  }

  if (breadcrumbSections.length < MAX_SECTIONS_WITH_ELLIPSIS) {
    breadcrumbSections.unshift(
      <BreadcrumbSection
        key="all"
        name={breadcrumbSections.length === MAX_SECTIONS ? HORIZONTAL_ELLIPSIS : NavigationMenuDisplay.ALL}
        url={isClickable ? allItemsUrl : undefined}
      />,
    );
  }

  return <>{breadcrumbSections}</>;
};

const ItemPanelBreadcrumb = ({ itemId, isClickable }: ItemPanelBreadcrumbProps) => {
  const location = useLocation();
  const item = useSelector((state) => getItemForId(state, itemId));
  const itemType = item?.itemType;
  const tabName = itemType === ItemType.TASKS ? TabNames.Assignments : TabNames.Planning;
  const itemNameUrl = `${location.pathname}#panelId=${itemId}&panelSection=${tabName}Tab`;

  if (!item) {
    return null;
  }

  return (
    <div className="lp-item-panel__breadcrumb-container">
      <Breadcrumb size="tiny" className="lp-item-panel__breadcrumb">
        <BreadcrumbSections itemId={itemId} isClickable={isClickable} />
      </Breadcrumb>
      <div className="lp-item-panel__item-name">
        <ItemIcon itemId={itemId} size="sm" />
        <Breadcrumb.Section {...(isClickable ? { as: LpLink } : {})} to={itemNameUrl} content={item.name} />
      </div>
    </div>
  );
};

export default ItemPanelBreadcrumb;
