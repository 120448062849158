import { useSelector } from 'react-redux';

import { getOrganizationUserInCurrentOrgForUserId } from 'state/entities/selectors/user';

interface OrgUserUserNameProps {
  orgUserId: number;
}

const OrgUserUserName = ({ orgUserId }: OrgUserUserNameProps) => {
  const orgUser = useSelector((state) => getOrganizationUserInCurrentOrgForUserId(state, orgUserId));

  return <>{orgUser?.username ?? ''}</>;
};

export default OrgUserUserName;
