import { PackageStatus } from 'daos/enums';
import { ItemTypesDisplaySingular } from 'daos/item_enums';
import { EllipsisActionDropdownMethods } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/ellipsis_action_dropdown_methods';
import { EllipsisActionMenuConfig } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/reduce_ellipsis_action_menu_items';
import { EllipsisActionViewLocation } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { readonlyArray } from 'lib/readonly_record';

export type EllipsisActionMenuItems = (
  dropdownItemMethods: EllipsisActionDropdownMethods,
  incrementedMenuKey: () => number,
  accessChecks: {
    manage: { workspaceRoot: boolean; package: boolean; item: boolean };
    edit: { project: boolean; item: boolean };
    observe: { item: boolean };
  },
  packageStatus?: PackageStatus | null,
  hasJiraIntegration?: boolean,
  hasManageAccess?: boolean,
) => ReadonlyArray<EllipsisActionMenuConfig>;

export const packageMenuItems: EllipsisActionMenuItems = (
  dropdownItemMethods,
  incrementedMenuKey,
  accessChecks,
  packageStatus,
  hasJiraIntegration,
  hasManageAccess,
) =>
  readonlyArray([
    {
      dropdownItem: dropdownItemMethods.open(accessChecks.observe.item, undefined),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.PackageView, replacementMenuItem: null }],
    },
    { dropdownItem: dropdownItemMethods.edit(incrementedMenuKey()) },
    { dropdownItem: dropdownItemMethods.copyLink(incrementedMenuKey()) },
    { dropdownItem: dropdownItemMethods.divider('divider-1-pkg') },
    {
      dropdownItem: dropdownItemMethods.add(
        accessChecks.manage.item,
        ItemTypesDisplaySingular.Project,
        incrementedMenuKey(),
      ),
    },
    {
      dropdownItem: hasJiraIntegration ? dropdownItemMethods.addJira(hasManageAccess, incrementedMenuKey()) : null,
    },
    {
      dropdownItem: dropdownItemMethods.projectTemplate(accessChecks.manage.item, incrementedMenuKey()),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.TemplatePackageView, replacementMenuItem: null }],
    },
    {
      dropdownItem: dropdownItemMethods.duplicate(accessChecks.manage.workspaceRoot, undefined, incrementedMenuKey()),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.PackageView, replacementMenuItem: null }],
    },
    { dropdownItem: dropdownItemMethods.divider('divider-2-pkg') },
    {
      dropdownItem:
        packageStatus === PackageStatus.TEMPLATE
          ? dropdownItemMethods.createPackageFromTemplate(accessChecks.manage.workspaceRoot, incrementedMenuKey())
          : dropdownItemMethods.packageToTemplate(accessChecks.manage.workspaceRoot, incrementedMenuKey()),
    },
    {
      dropdownItem: dropdownItemMethods.memberAccess(accessChecks.manage.item, incrementedMenuKey()),
    },
    {
      dropdownItem: dropdownItemMethods.delete(accessChecks.manage.workspaceRoot, incrementedMenuKey()),
    },
  ]);

export const projectMenuItems: EllipsisActionMenuItems = (dropdownItemMethods, incrementedMenuKey, accessChecks) =>
  readonlyArray([
    {
      dropdownItem: dropdownItemMethods.open(accessChecks.observe.item, undefined),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.ProjectAncestorRow, replacementMenuItem: null }],
    },
    { dropdownItem: dropdownItemMethods.edit(incrementedMenuKey()) },
    {
      dropdownItem: dropdownItemMethods.move(accessChecks.manage.package, undefined),
    },
    {
      dropdownItem: dropdownItemMethods.copyLink(incrementedMenuKey()),
    },
    { dropdownItem: dropdownItemMethods.divider('divider-1-project') },
    {
      dropdownItem: dropdownItemMethods.add(
        accessChecks.edit.item,
        ItemTypesDisplaySingular.Task,
        incrementedMenuKey(),
      ),
      modifyOnViewLocation: [
        {
          view: EllipsisActionViewLocation.ScheduledPackageCollectionView,
          replacementMenuItem: dropdownItemMethods.add(accessChecks.manage.package, undefined, incrementedMenuKey()),
        },
        {
          view: EllipsisActionViewLocation.PendingPackageCollectionView,
          replacementMenuItem: dropdownItemMethods.add(accessChecks.manage.package, undefined, incrementedMenuKey()),
        },
        {
          view: EllipsisActionViewLocation.ArchivedPackageCollectionView,
          replacementMenuItem: dropdownItemMethods.add(accessChecks.manage.package, undefined, incrementedMenuKey()),
        },
        {
          view: EllipsisActionViewLocation.TemplatePackageView,
          replacementMenuItem: dropdownItemMethods.add(accessChecks.manage.package, undefined, incrementedMenuKey()),
        },
        { view: EllipsisActionViewLocation.PackageView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.add(
        accessChecks.edit.item,
        ItemTypesDisplaySingular.Folder,
        incrementedMenuKey(),
      ),
      modifyOnViewLocation: [
        { view: EllipsisActionViewLocation.ScheduledPackageCollectionView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.PendingPackageCollectionView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.ArchivedPackageCollectionView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.TemplatePackageView, replacementMenuItem: null },
        {
          view: EllipsisActionViewLocation.PackageView,
          replacementMenuItem: dropdownItemMethods.add(
            accessChecks.manage.package,
            ItemTypesDisplaySingular.Project,
            incrementedMenuKey(),
          ),
        },
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.projectTemplate(accessChecks.manage.package, incrementedMenuKey()),
      modifyOnViewLocation: [
        { view: EllipsisActionViewLocation.ProjectAncestorRow, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.TemplatePackageView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.duplicate(
        accessChecks.manage.package,
        ItemTypesDisplaySingular.Project,
        incrementedMenuKey(),
      ),
      modifyOnViewLocation: [
        { view: EllipsisActionViewLocation.ProjectAncestorRow, replacementMenuItem: null },
        {
          view: EllipsisActionViewLocation.PackageView,
          replacementMenuItem: dropdownItemMethods.duplicate(
            accessChecks.manage.package,
            ItemTypesDisplaySingular.Project,
            incrementedMenuKey(),
          ),
        },
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.divider('divider-2-project'),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.GridView, replacementMenuItem: null }],
    },
    {
      dropdownItem: dropdownItemMethods.projectToTemplate(accessChecks.manage.item, incrementedMenuKey()),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.TemplatePackageView, replacementMenuItem: null }],
    },
    {
      dropdownItem: dropdownItemMethods.createProjectFromTemplate(accessChecks.manage.item, incrementedMenuKey()),
      modifyOnViewLocation: [
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.ItemListRow, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.WorkloadTaskRow, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.PackageView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.ProjectAncestorRow, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.PendingPackageCollectionView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.ScheduledPackageCollectionView, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.ArchivedPackageCollectionView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.memberAccess(accessChecks.manage.item, incrementedMenuKey()),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.GridView, replacementMenuItem: null }],
    },
    {
      dropdownItem: dropdownItemMethods.delete(accessChecks.manage.package, incrementedMenuKey()),
    },
  ]);

export const folderMenuItems: EllipsisActionMenuItems = (dropdownItemMethods, incrementedMenuKey, accessChecks) =>
  readonlyArray([
    {
      dropdownItem: dropdownItemMethods.open(accessChecks.edit.item, undefined),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.ProjectAncestorRow, replacementMenuItem: null }],
    },
    { dropdownItem: dropdownItemMethods.edit(incrementedMenuKey()) },
    {
      dropdownItem: dropdownItemMethods.move(accessChecks.edit.project, undefined),
    },
    { dropdownItem: dropdownItemMethods.copyLink(incrementedMenuKey()) },
    { dropdownItem: dropdownItemMethods.divider('divider-1-folder') },
    {
      dropdownItem: dropdownItemMethods.add(
        accessChecks.edit.project,
        ItemTypesDisplaySingular.Task,
        incrementedMenuKey(),
      ),
    },
    {
      dropdownItem: dropdownItemMethods.add(accessChecks.edit.project, undefined, incrementedMenuKey()),
    },
    {
      dropdownItem: dropdownItemMethods.duplicate(accessChecks.edit.project, undefined, incrementedMenuKey()),
      modifyOnViewLocation: [{ view: EllipsisActionViewLocation.ProjectAncestorRow, replacementMenuItem: null }],
    },
    { dropdownItem: dropdownItemMethods.divider('divider-2-folder') },
    {
      dropdownItem: dropdownItemMethods.delete(accessChecks.edit.project, incrementedMenuKey()),
    },
  ]);

export const taskMenuItems: EllipsisActionMenuItems = (dropdownItemMethods, incrementedMenuKey, accessChecks) =>
  readonlyArray([
    { dropdownItem: dropdownItemMethods.edit(incrementedMenuKey()) },
    {
      dropdownItem: dropdownItemMethods.move(accessChecks.edit.project, undefined),
    },
    {
      dropdownItem: dropdownItemMethods.copyLink(incrementedMenuKey()),
    },
    { dropdownItem: dropdownItemMethods.divider('divider-1-task') },
    {
      dropdownItem: dropdownItemMethods.add(accessChecks.edit.project, undefined, incrementedMenuKey()),
      modifyOnViewLocation: [
        { view: EllipsisActionViewLocation.WorkloadTaskRow, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.add(
        accessChecks.edit.project,
        ItemTypesDisplaySingular.Folder,
        incrementedMenuKey(),
      ),
      modifyOnViewLocation: [
        { view: EllipsisActionViewLocation.WorkloadTaskRow, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.duplicate(accessChecks.edit.project, undefined, incrementedMenuKey()),
      modifyOnViewLocation: [
        { view: EllipsisActionViewLocation.WorkloadTaskRow, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.divider('divider-2-task'),
      modifyOnViewLocation: [
        { view: EllipsisActionViewLocation.WorkloadTaskRow, replacementMenuItem: null },
        { view: EllipsisActionViewLocation.GridView, replacementMenuItem: null },
      ],
    },
    {
      dropdownItem: dropdownItemMethods.delete(accessChecks.edit.project, incrementedMenuKey()),
    },
  ]);

export const assignmentMenuItems: EllipsisActionMenuItems = (dropdownItemMethods, incrementedMenuKey, accessChecks) =>
  readonlyArray([
    { dropdownItem: dropdownItemMethods.edit(incrementedMenuKey()) },
    {
      dropdownItem: dropdownItemMethods.divider('divider-1-assignment'),
    },
    {
      dropdownItem: dropdownItemMethods.delete(accessChecks.edit.project, incrementedMenuKey()),
    },
  ]);
