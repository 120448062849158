import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { IconDefinition, LpIcon } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

type OrganizationLinkCrumbProps =
  | {
      icon: IconDefinition;
    }
  | {
      svg: LpSvgs;
    };

export const OrganizationLinkCrumb = (props: OrganizationLinkCrumbProps) => {
  const organization = useSelector(getCurrentOrganization);
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  if (!organization) {
    return null;
  }

  const getIconComponent = () => {
    if ('icon' in props) {
      return <LpIcon icon={props.icon} />;
    }

    if ('svg' in props) {
      return <LpSvg icon={props.svg} />;
    }

    return null;
  };

  return (
    <>
      {getIconComponent()}{' '}
      <LpLink to={frontend.workspaceHub.url({ organizationId, workspaceId })}>{organization.name}</LpLink>
    </>
  );
};

export const OrganizationNameCrumb = ({ icon }: { icon: IconDefinition }) => {
  const organization = useSelector(getCurrentOrganization);

  if (!organization) {
    return null;
  }

  return (
    <>
      <LpIcon icon={icon} /> {organization.name}
    </>
  );
};

export const WorkspaceNameCrumb = ({ icon }: { icon?: IconDefinition } = {}) => {
  const currentWorkspace = useSelector(getCurrentWorkspace);

  if (!currentWorkspace) {
    return null;
  }
  if (icon) {
    return (
      <>
        <LpIcon icon={icon} /> {currentWorkspace.name}
      </>
    );
  }

  return <>{currentWorkspace.name}</>;
};
