import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownProps, Message, Table } from 'semantic-ui-react';

import { JiraTimesheetsDao } from 'daos/jira_timesheets';
import { WorkspaceDao } from 'daos/workspace';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import CostCodeDropdown from 'features/common/inputs/dropdowns/cost_code_dropdown';
import { LpIcon, questionCircleSolid } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { CostCodeError } from 'features/jira_project/modal/common/cost_code_error';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTablePicklistCell } from 'features/jira_project/modal/common/jira_project_modal_table_picklist_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import JiraProjectModalTableToggleRow from 'features/jira_project/modal/common/jira_project_modal_table_toggle_row';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { PushSettingRow } from 'features/jira_project/modal/sections/sync_settings_section/push_setting_row';
import { SubFolderOrganizationRow } from 'features/jira_project/modal/sections/sync_settings_section/subfolder_organization_row';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  TimesheetAuthResponse,
  TimesheetAuthStatus,
  WorklogOption,
} from 'features/jira_project/modal/types';
import { useBrowserTabActive } from 'hooks/use_browser_tab_active';
import { useHasFeature } from 'hooks/use_has_feature';
import { awaitRequestFinish } from 'lib/api';
import { FeatureFlag } from 'lib/feature_flags';
import { ProductName } from 'lib/use_product_name';
import { gray600 } from 'style/variables';

import { getWorklogOptions, hasAccessChanged, worklogOption } from './helpers';
import 'features/jira_project/modal/jira_project_modal.scss';

const SyncSettingsSection = () => {
  const hasPushFeature = useHasFeature(FeatureFlag.jiraIssueCreation);
  const dispatch = useDispatch();
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const { canModifyCostCode, isEditMode } = useJiraProjectModalContext();
  const { values, setFieldValue, errors, validateField } = useFormikContext<JiraProjectModalFormValues>();
  const { costCodeId, syncJiraWorklog, syncTimesheetWorklog } = values;
  const costCodeError = errors[JiraProjectModalFormFields.CostCodeId];
  const browserTabActive = useBrowserTabActive();
  const [tempoTimesheetAuth, setTempoTimesheetAuth] = useState<TimesheetAuthStatus>();
  const selectedWorklogOption = worklogOption(syncJiraWorklog, syncTimesheetWorklog);
  const [showAlert, setShowAlert] = useState(false);

  const handleCostCodeChange = (value: number | undefined) => {
    setFieldValue(JiraProjectModalFormFields.CostCodeId, value ?? null);
  };

  const fetchWorkspaceCostCodesOnFocus = useCallback(() => {
    if (!workspaceId) {
      return;
    }
    const costCodeFetchFn = WorkspaceDao.fetch(
      { workspaceId, organizationId },
      {
        include: {
          includeCostCodes: true,
        },
      },
    );

    const { uuid } = dispatch(costCodeFetchFn);
    dispatch(awaitRequestFinish(uuid, {}));
  }, [dispatch, organizationId, workspaceId]);

  const handleResetWorklogOption = useCallback(() => {
    setFieldValue(JiraProjectModalFormFields.SyncJiraWorklog, false);
    setFieldValue(JiraProjectModalFormFields.SyncTimesheetWorklog, false);
  }, [setFieldValue]);

  const handleDropdownChange = useCallback(
    (_: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
      switch (value) {
        case WorklogOption.DO_NOT_PULL:
          handleResetWorklogOption();
          break;
        case WorklogOption.PULL_FROM_JIRA:
          setFieldValue(JiraProjectModalFormFields.SyncJiraWorklog, true);
          setFieldValue(JiraProjectModalFormFields.SyncTimesheetWorklog, false);
          break;
        case WorklogOption.PULL_FROM_TEMPO_TIMESHEETS:
          setFieldValue(JiraProjectModalFormFields.SyncTimesheetWorklog, true);
          setFieldValue(JiraProjectModalFormFields.SyncJiraWorklog, false);
          break;
        default:
          break;
      }
    },
    [handleResetWorklogOption, setFieldValue],
  );

  const fetchJiraTimesheetsAuth = useCallback(() => {
    if (!workspaceId) {
      return;
    }
    const jiraTimesheetsAuthFetchFn = JiraTimesheetsDao.fetchAuth(
      {
        organizationId,
        workspaceId,
      },
      {
        cloudId: values[JiraProjectModalFormFields.CloudId],
        oauthCredentialsId: values[JiraProjectModalFormFields.OauthCredentialsId],
      },
    );

    const { uuid } = dispatch(jiraTimesheetsAuthFetchFn);
    dispatch(
      awaitRequestFinish<TimesheetAuthResponse>(uuid, {
        onSuccess: ({ data }) => {
          setTempoTimesheetAuth(data.result);
          if (isEditMode) {
            hasAccessChanged(syncTimesheetWorklog, data.result, setShowAlert);
          }
        },
      }),
    );
  }, [dispatch, isEditMode, organizationId, syncTimesheetWorklog, values, workspaceId]);

  useEffect(() => {
    validateField(JiraProjectModalFormFields.CostCodeId);
  }, [costCodeId, validateField, values]);

  useEffect(() => {
    if (browserTabActive) {
      fetchWorkspaceCostCodesOnFocus();
    }
  }, [fetchWorkspaceCostCodesOnFocus, browserTabActive]);

  useEffect(() => {
    fetchJiraTimesheetsAuth();
  }, [fetchJiraTimesheetsAuth]);

  const removeCostCodeField = () => {
    setFieldValue(JiraProjectModalFormFields.CostCodeId, null);
  };

  const shouldShowCostCode = selectedWorklogOption !== WorklogOption.DO_NOT_PULL;

  const shouldShowTimesheetOption = syncTimesheetWorklog || tempoTimesheetAuth === TimesheetAuthStatus.Success;

  return (
    <>
      {showAlert ? (
        <Message
          icon={true}
          onDismiss={() => setShowAlert(false)}
          warning
          visible
          size="tiny"
          className="jira-project-modal__content-sync-settings-warning-alert"
        >
          <LpSvg icon={LpSvgs.TIMESHEET_LOGO} size={50} />
          <Message.Content>
            <Message.Header content="Update: Tempo Timesheets Access Unavailable" />
            It appears that you no longer have access to Tempo Timesheets. As a result, data can only be pulled from
            Jira. Please update your selection to &quot;Pull from Jira&quot; to continue syncing Time Entries.
          </Message.Content>
        </Message>
      ) : null}
      <JiraProjectModalInfo title="Sync Additional Information (Optional)" />
      <JiraProjectTable>
        <JiraProjectModalTableToggleRow
          jiraProjectModalFormField={JiraProjectModalFormFields.RankIssues}
          name={
            <>
              Reorder Jira Issues by <ProductName /> Priority
            </>
          }
          description={
            <div>
              Synchronize Jira issue ranks with the priority order defined in <ProductName />.
            </div>
          }
        />
        <JiraProjectModalTableToggleRow
          jiraProjectModalFormField={JiraProjectModalFormFields.CaptureSprints}
          name="Sync Jira Sprints"
          description={
            <div>
              Create a new iteration in <ProductName /> or link to an existing one when syncing Jira tasks with sprint
              associations.
            </div>
          }
        />
        <SubFolderOrganizationRow />
        {hasPushFeature && <PushSettingRow />}
        <JiraProjectModalTableRow>
          <JiraProjectModalTableNameCell content={<span>Pull Worklogs (Time Entries)</span>} />
          <Table.Cell>
            <div className="jira-project-modal__content-sync-settings-toggle-container">
              <Dropdown
                closeOnChange
                onChange={handleDropdownChange}
                options={getWorklogOptions(shouldShowTimesheetOption)}
                search={true}
                selection
                value={selectedWorklogOption}
              />
            </div>
          </Table.Cell>
          <JiraProjectModalTableDeleteCell
            onClick={handleResetWorklogOption}
            enabled={selectedWorklogOption !== WorklogOption.DO_NOT_PULL}
          />
        </JiraProjectModalTableRow>
        {shouldShowCostCode && (
          <JiraProjectModalTableRow>
            <JiraProjectModalTableNameCell
              content={
                <div className="jira-project-modal__content-sync-settings-costcode">
                  <span>Cost Code</span>
                  <LpPopUp
                    className="jira-project-modal__content-sync-settings-costcode-popup"
                    placement="top"
                    content="Categorize Jira time entries by selecting an existing Cost Code to understand where time goes"
                    trigger={<LpIcon color={gray600} icon={questionCircleSolid} />}
                  />
                </div>
              }
            />
            <JiraProjectModalTablePicklistCell
              picklist={
                <CostCodeDropdown
                  className="jira-project-modal__picklist"
                  currentCostCodeId={costCodeId ?? 0}
                  onChange={handleCostCodeChange}
                  shouldLabelDefaultCostCode
                  useOnGrid
                  includeIcon
                  shouldOpenCustomizeLinkInNewTab
                  hasError={!!errors[JiraProjectModalFormFields.CostCodeId]}
                  disabled={!canModifyCostCode}
                />
              }
              secondaryElement={costCodeError && <CostCodeError />}
            />
            <JiraProjectModalTableDeleteCell
              onClick={removeCostCodeField}
              enabled={!!costCodeId && canModifyCostCode}
            />
          </JiraProjectModalTableRow>
        )}
      </JiraProjectTable>
    </>
  );
};

export default SyncSettingsSection;
