import { CustomFieldType } from './enums';
import { CustomFieldFilter } from './types';

export function getCustomFieldFilterKeyForFieldType(
  fieldType: CustomFieldType | undefined,
): keyof Omit<CustomFieldFilter, 'customFieldId'> | undefined {
  switch (fieldType) {
    case CustomFieldType.CHECKBOX:
      return 'checkedValues';
    case CustomFieldType.CURRENCY:
      return 'currencyValues';
    case CustomFieldType.DATE:
      return 'dateValues';
    case CustomFieldType.LINK:
      return 'urlValues';
    case CustomFieldType.ITEM:
    case CustomFieldType.MULTI_ITEM:
      return 'itemIdValues';
    case CustomFieldType.NUMERIC:
      return 'numberValues';
    case CustomFieldType.PICKLIST:
    case CustomFieldType.MULTI_PICKLIST:
      return 'picklistChoiceIdValues';
    case CustomFieldType.TEXT:
    case CustomFieldType.MULTI_TEXT:
      return 'textValues';
    case CustomFieldType.USER:
    case CustomFieldType.MULTI_USER:
      return 'workspaceUserIdValues';
    default:
      return undefined;
  }
}
