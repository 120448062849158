import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { PicklistChoice } from 'daos/model_types';
import { PicklistChoiceDao } from 'daos/picklist_choice';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import RemoveSoleFieldValueCell, {
  RemoveSoleFieldValueCellSize,
} from 'features/common/custom_fields/custom_field_value/remove_sole_field_value_cell';
import PicklistDropdown, { PicklistDropdownProps } from 'features/common/inputs/dropdowns/picklist_dropdown';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { compareByPriority } from 'lib/helpers/comparison_helpers';
import { getFieldValueForId, getPicklistChoiceForId } from 'state/entities/selectors/custom_field';

import 'features/common/custom_fields/custom_fields.scss';

const CustomFieldPicklistValue = ({
  allowMultipleValues,
  disabled = false,
  fieldId,
  fieldValueId,
  otherSelectedPicklistChoiceIds,
  picklistChoices,
  resourceId,
}: CustomFieldValueProps & {
  allowMultipleValues: boolean;
  fieldValueId?: number;
  otherSelectedPicklistChoiceIds: ReadonlyArray<number>;
  picklistChoices: ReadonlyArray<PicklistChoice>;
}) => {
  const { updateFieldValue, createFieldValue, removeFieldValue } = useCustomFieldsApiActions(resourceId.type);
  const fieldValue = useSelector((state) => (fieldValueId ? getFieldValueForId(state, fieldValueId) : undefined));
  const fieldChoice = useSelector((state) =>
    fieldValue?.picklistChoice ? getPicklistChoiceForId(state, fieldValue.picklistChoice.id) : undefined,
  );

  const handleChange: PicklistDropdownProps['onChange'] = useCallback(
    (_: React.SyntheticEvent, { value }: { value: number | string }) => {
      if (!value || value === '') {
        fieldValue && removeFieldValue(resourceId.id, fieldValue.id);
      } else {
        const payload = { picklistChoice: PicklistChoiceDao.id(value as number) };
        if (fieldValue) {
          updateFieldValue(resourceId.id, fieldValue.id, payload);
        } else {
          createFieldValue(resourceId.id, fieldId, payload);
        }
      }
    },
    [createFieldValue, fieldId, fieldValue, removeFieldValue, resourceId, updateFieldValue],
  );

  const picklistDropdownChoices = getPicklistChoicesSortedByPriority(
    picklistChoices,
    otherSelectedPicklistChoiceIds,
    fieldChoice?.id,
  );

  return (
    <span className="custom-field-input">
      <PicklistDropdown
        placeHolder={'Add'}
        fieldChoiceId={fieldChoice?.id}
        picklist={picklistDropdownChoices}
        onChange={handleChange}
        disabled={disabled}
      />
      {allowMultipleValues && fieldValueId && (
        <RemoveSoleFieldValueCell
          size={RemoveSoleFieldValueCellSize.Button}
          valueIds={[fieldValueId]}
          disabled={disabled}
        />
      )}
    </span>
  );
};

export default CustomFieldPicklistValue;

function getPicklistChoicesSortedByPriority(
  allPicklistChoices: ReadonlyArray<PicklistChoice>,
  allOtherSelectedChoiceIds: ReadonlyArray<number>,
  fieldValuePicklistChoiceId?: number,
) {
  return allPicklistChoices
    .filter((choice) => {
      const isAnotherSelectedChoice = allOtherSelectedChoiceIds.includes(choice.id);
      if (!isAnotherSelectedChoice) {
        return !choice.archived || choice.id === fieldValuePicklistChoiceId;
      }
    })
    .sort(compareByPriority);
}
