import { useSelector } from 'react-redux';

import { FormattedAssignees } from 'containers/shared/custom_column/assigned';
import { StandardColumns } from 'containers/shared/custom_column/enum';
import OrgUserUserName from 'containers/shared/custom_column/org_user_username';
import { AddEditGridColumnKey } from 'features/common/data_grid/enums';
import { ItemFormatterProps, FormatterAddEditGridProps } from 'features/common/data_grid/types';
import { getAssigneeMapForAssignmentOrTaskId } from 'state/entities/selectors/assignment';
import { getItemForId } from 'state/entities/selectors/item';
import { getRateSheetForId } from 'state/entities/selectors/rate_sheet';

export const ItemCreatedByColumnFormatter = ({ row }: ItemFormatterProps) => {
  const item = useSelector((state) => getItemForId(state, row.id));
  if (!item) {
    return null;
  }

  const itemCreatedBy = item[StandardColumns.CreatedBy];

  return itemCreatedBy?.id ? <OrgUserUserName orgUserId={itemCreatedBy.id} /> : null;
};

export const ItemUpdatedByColumnFormatter = ({ row }: ItemFormatterProps) => {
  const item = useSelector((state) => getItemForId(state, row.id));
  if (!item) {
    return null;
  }

  const itemUpdatedBy = item[StandardColumns.UpdatedBy];

  return itemUpdatedBy?.id ? <OrgUserUserName orgUserId={itemUpdatedBy.id} /> : null;
};

export const AssignedColumnFormatter = ({ row }: ItemFormatterProps) => {
  const assigneeMap = useSelector((state) => getAssigneeMapForAssignmentOrTaskId(state, row.id));

  return <FormattedAssignees assigneeMap={assigneeMap} />;
};

export const ItemRateSheetColumnFormatter = ({ row, column }: ItemFormatterProps | FormatterAddEditGridProps) => {
  const columnKey = column.key as AddEditGridColumnKey.BillingRateSheet | AddEditGridColumnKey.PayRateSheet;
  const rowValue = row[columnKey];
  const rateSheet = useSelector((state) => getRateSheetForId(state, rowValue ?? ''));

  return <>{rateSheet?.name ?? ''}</>;
};
