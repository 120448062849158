import { FormikHelpers } from 'formik';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { CustomFieldType } from 'daos/enums';
import { File, Widget } from 'daos/model_types';
import { WidgetDao } from 'daos/widgets';
import { useGoBackToDashboardUrl } from 'features/dashboards_v2/common/use_go_back_to_dashboard_url';
import { ItemIntakeSettableField } from 'features/dashboards_v2/widget_click_through/intake/enums';
import {
  IntakeFormValue,
  IntakeWidgetData,
  IntakeWidgetItemFieldToFieldIdKey,
  WidgetIntakeFormUserInput,
} from 'features/dashboards_v2/widget_click_through/intake/types';
import { awaitRequestFinish } from 'lib/api';
import { convertHoursToSeconds } from 'lib/helpers';
import { rawStringToIsoDate } from 'lib/localization';

export const useSubmitIntakeWidgetForm = (openConfirmationModal: () => void, openErrorModal: () => void) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const backToDashboardUrl = useGoBackToDashboardUrl();

  const submitForm = useCallback(
    (widget: Widget<IntakeWidgetData>, itemFieldToFieldIdKey: IntakeWidgetItemFieldToFieldIdKey) =>
      (formValues: WidgetIntakeFormUserInput, formikHelpers: FormikHelpers<WidgetIntakeFormUserInput>) => {
        const { id: widgetId, dashboardId, organizationId, workspaceId } = widget;

        const hasConfirmationMessage = !!widget.data.form?.confirmationMessage;

        const itemIntakePayload = buildItemIntakePayloadBody(formValues, itemFieldToFieldIdKey);

        const { uuid } = dispatch(
          WidgetDao.intakeWidgetFormPost({ organizationId, workspaceId, dashboardId, widgetId }, itemIntakePayload),
        );

        dispatch(
          awaitRequestFinish(uuid, {
            onSuccess: () => {
              if (hasConfirmationMessage) {
                openConfirmationModal();
              } else {
                history.push(backToDashboardUrl);
              }
              formikHelpers.setSubmitting(false);
            },
            onError: openErrorModal,
            onFinish: () => formikHelpers.setSubmitting(false),
          }),
        );
      },
    [backToDashboardUrl, dispatch, history, openConfirmationModal, openErrorModal],
  );

  return submitForm;
};

function isEmptyField(fieldValue: IntakeFormValue) {
  return fieldValue === undefined || fieldValue === '' || (Array.isArray(fieldValue) && !fieldValue.length);
}

function convertWorkLimitInHoursToWorkLimitInSeconds(workLimitValue: string) {
  const workLimit = workLimitValue.replace(/h$/i, '');
  return convertHoursToSeconds(Number(workLimit));
}

function buildItemIntakePayloadBody(
  formValues: WidgetIntakeFormUserInput,
  itemFieldToFieldIdKey: IntakeWidgetItemFieldToFieldIdKey,
) {
  return Object.entries(formValues).reduce((acc: Record<string, Array<string>>, [key, value]) => {
    if (isEmptyField(value)) {
      return acc;
    }

    if (Array.isArray(value)) {
      if (itemFieldToFieldIdKey[key] === ItemIntakeSettableField.Files) {
        const arrayOfFiles = value as Array<File>;
        acc[key] = arrayOfFiles.map((file: File) => file.id.toString());
      } else {
        acc[key] = value.map(String);
      }
      return acc;
    }

    if (value) {
      if (itemFieldToFieldIdKey[key] === CustomFieldType.DATE) {
        acc[key] = [rawStringToIsoDate(value.toString())];
        return acc;
      }

      if (itemFieldToFieldIdKey[key] === ItemIntakeSettableField.WorkLimit) {
        acc[key] = [convertWorkLimitInHoursToWorkLimitInSeconds(value.toString()).toString()];
        return acc;
      }

      acc[key] = [value.toString()];
      return acc;
    }

    return acc;
  }, {});
}
