import { throttle } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { renderDeleteIcon } from 'features/jira_project/modal/helpers';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import {
  isJiraAutoCompleteMatch,
  stripHTML,
} from 'features/jira_project/modal/sections/project_and_issues/advanced_filters/utils';
import {
  JiraAutoCompleteResult,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
} from 'features/jira_project/modal/types';
import { awaitRequestFinish } from 'lib/api';
import { ApiRequest } from 'lib/api/types';

interface JiraProjectAutocompleteDropdownProps {
  placeholder: string;
  index: number;
  fetchSuggestionsFn: (value: string) => ApiRequest;
  selectedValues:
    | JiraProjectModalFormValues[JiraProjectModalFormFields.ParentIssueIdsOrKeys]
    | ReadonlyArray<JiraAutoCompleteResult>;
  setFieldValue: ({ newValue, index }: { newValue?: JiraAutoCompleteResult; index?: number }) => void;
  showRemoveButton?: boolean;
}

const noneOption: JiraAutoCompleteResult = { displayName: 'None', value: '' };

const getFilteredSuggestions = (
  suggestions: Array<JiraAutoCompleteResult>,
  selectedValues: ReadonlyArray<JiraAutoCompleteResult | string>,
  selectedOption: JiraAutoCompleteResult | string | undefined,
) => {
  return suggestions.filter((suggestion) => {
    const isSelected = selectedValues.some((selected) =>
      typeof selected === 'string' ? selected === suggestion.value : selected.value === suggestion.value,
    );
    return !isSelected || (typeof selectedOption !== 'string' && suggestion.value === selectedOption?.value);
  });
};

export const JiraProjectAutocompleteDropdown = ({
  placeholder,
  index,
  showRemoveButton = false,
  fetchSuggestionsFn,
  selectedValues,
  setFieldValue,
}: JiraProjectAutocompleteDropdownProps) => {
  const dispatch = useDispatch();
  const { setFormError } = useJiraProjectModalContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<Array<JiraAutoCompleteResult>>([noneOption]);

  const selectedOption = selectedValues[index];

  const fetchSuggestions = useCallback(
    async (value: string) => {
      const { uuid } = dispatch(fetchSuggestionsFn(value));

      dispatch(
        awaitRequestFinish<Array<JiraAutoCompleteResult>>(uuid, {
          onSuccess: ({ data: suggestions }) => {
            const filteredSuggestions = getFilteredSuggestions(suggestions, selectedValues, selectedOption);
            setSuggestions([noneOption, ...filteredSuggestions]);
          },
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormError(errors[0]?.detail ?? errors[0].title);
            }
          },
          onFinish: () => {
            setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, fetchSuggestionsFn, selectedOption, setFormError, selectedValues],
  );

  const handleDelete = ({ index }: { index: number }) => {
    setFieldValue({ index, newValue: undefined });
  };

  const handleSelect = ({ newValue, index }: { newValue?: string; index: number }) => {
    if (!newValue) {
      handleDelete({ index });
    } else {
      const selectedOption = suggestions.find((suggestion) => suggestion.value === newValue);
      setFieldValue({ index, newValue: selectedOption });
    }
  };

  const handleOpen = () => {
    setIsOpen(true);

    throttledFetchSuggestions('');

    if (selectedOption && !suggestions.some((suggestion) => isJiraAutoCompleteMatch({ suggestion, selectedOption }))) {
      setSuggestions([noneOption, ...suggestions.filter((suggestion) => suggestion.value !== noneOption.value)]);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const renderLabel = (item: DropdownItemProps) => <span dangerouslySetInnerHTML={{ __html: item.displayName }} />;

  const throttledFetchSuggestions = useRef(
    throttle((value: string) => fetchSuggestions(value), 750, { leading: true, trailing: true }),
  ).current;

  const handleInputChange = (newValue: string) => {
    setIsLoading(true);
    throttledFetchSuggestions(newValue);
  };

  const handleDropdownChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data;
    if (typeof value === 'string') {
      if (value === '') {
        handleSelect({ index });
      } else {
        handleSelect({ newValue: value, index });
      }
    }
  };

  const options = suggestions.map((suggestion) => ({
    key: suggestion.value,
    text: stripHTML(suggestion.displayName),
    value: suggestion.value,
    content: <span dangerouslySetInnerHTML={{ __html: suggestion.displayName }} />,
  }));

  const getText = () => {
    if (!selectedOption) {
      return '';
    }
    return typeof selectedOption === 'string' ? selectedOption : stripHTML(selectedOption.displayName);
  };

  const getValue = () => {
    if (!selectedOption) {
      return '';
    }
    return typeof selectedOption === 'string' ? selectedOption : selectedOption.value;
  };

  return (
    <span className="custom-field-input">
      <PortalDropdown>
        <Dropdown
          fluid
          search
          selection
          options={options}
          onSearchChange={(_, { searchQuery }) => handleInputChange(searchQuery)}
          onChange={handleDropdownChange}
          onOpen={handleOpen}
          onClose={handleClose}
          open={isOpen}
          loading={isLoading}
          placeholder={placeholder}
          value={getValue()}
          text={getText()}
          selectOnNavigation={false}
          selectOnBlur={false}
          renderLabel={renderLabel}
        />
      </PortalDropdown>
      {showRemoveButton &&
        renderDeleteIcon({
          onClick: () => handleDelete({ index }),
          className: 'remove-custom-field',
        })}
    </span>
  );
};
