import { useSelector } from 'react-redux';

import Banner from 'features/common/banner';
import { SessionStorageKey, useSessionState } from 'hooks/use_session_state';
import { getActiveBanner } from 'state/entities/selectors/banners';
import { lpBrandWhite } from 'style/variables';
import './global_notification_banner.scss';

const GlobalNotificationBanner = () => {
  const currentBanner = useSelector(getActiveBanner);
  const [dismissedBannerId, setDismissedBannerId] = useSessionState(-1, SessionStorageKey.DismissedBanner);

  if (!currentBanner || !currentBanner.content || currentBanner?.id <= dismissedBannerId) {
    return <div className="lp-banner-placeholder"></div>;
  }

  const dismissCurrentBanner = () => {
    setDismissedBannerId(currentBanner.id);
  };

  return (
    <Banner
      dismissable
      onDismiss={dismissCurrentBanner}
      style={{
        color: lpBrandWhite,
        backgroundColor: `#${currentBanner.color}`,
      }}
      className="lp-global-notification-banner"
    >
      <span dangerouslySetInnerHTML={{ __html: currentBanner.content }} />
    </Banner>
  );
};

export default GlobalNotificationBanner;
