import { OriginView } from 'lib/avo/client';

import { url } from './url_builder';

export const frontend = {
  login: url('/login', 'Login'),
  loginSso: url('/login/sso', 'SSO Login'),
  logout: url('/logout', 'Logout'),
  trial: url('/trial', 'Trial Signup'),
  forgotPassword: url('/forgot_password', 'Forgot Password'),
  setPassword: url('/set_password', 'Set Password'),
  resetPassword: url('/reset_password', 'Reset Password'),
  invitationAccept: url('/invitations', 'Accept Invitation'),
  emailConfirmation: url('/email_confirmation', 'Email Confirmation'),
  emailConfirmationExpired: url('/email_confirmation_expired', 'Expired Email Confirmation'),
  disconnected: url('/welcome_back', 'Disconnected User Login'),
  disconnectedRestartAccount: url('/welcome_back/restart_account', 'Disconnected User - Restart Account'),
  portableManageAccount: url('/manage_account', 'Portable Manage Account'),
  missingAccount: url('/missing_account', 'Missing Account'),
  portableAcademy: url('/academy', 'Portable Academy'),
  dashboardPassports: url('/guest_passports', 'Guest Passports'),

  authenticated: url('/organization'),
  organization: url('/organization/:organizationId(\\d+)'),
  workspace: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)'),
  workspaceHub: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/hub', 'Administration'),

  // --- Academy urls
  academy: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/academy', 'Academy Home'),
  track: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/academy/:courseId(\\d+)/:trackId(\\d+)',
    'Academy - Track',
  ),
  portableCourse: url('/academy/:courseId(\\d+)', 'Portable Course'),

  // --- Portable urls
  portable: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)', 'Portable Item'),
  portableChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)/changes',
    'Portable Item',
    OriginView.CHANGES,
  ),
  portableSchedule: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)/schedule',
    'Portable Item',
    OriginView.SCHEDULE,
  ),
  portableWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)/workload',
    'Portable Item',
    OriginView.WORKLOAD,
  ),
  portableBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)/board',
    'Portable Item',
    OriginView.BOARD,
  ),
  portableGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)/grid',
    'Portable Item',
    OriginView.GRID,
  ),
  portableAcademyLesson: url('/academy-lesson/:portableLinkName([a-z_]+)', 'Portable Academy Lesson'),
  portableDashboard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboard/:dashboardId(\\d+)',
    'Portable Dashboard',
  ),
  portableReport: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/report/:widgetId(\\d+)',
    'Portable Report',
  ),
  portableLibraryCollection: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/collection/library',
    'Portable Library',
  ),
  portableLibraryItem: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)/library',
    'Portable Item Library',
  ),
  portableLibraryItemDashboard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)/dashboard/:dashboardId(\\d+)',
    'Portable Library Item Dashboard',
  ),

  // --- Favorites urls
  favorites: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/favorites', 'Favorites'),

  // --- Report Hubs urls
  dashboardGuest: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboard_guest/:dashboardId(\\d+)',
    'Dashboard - Guest Access',
  ),
  dashboardGuestWidget: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboard_guest/:dashboardId(\\d+)/widget/:widgetId(\\d+)',
    'Dashboard - Guest Access - Widget Click-through View',
  ),
  workspaceLibrary: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/library',
    'Workspace -  Library',
  ),
  workspaceDashboard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)',
    'Workspace - Dashboard',
  ),
  workspaceDashboardDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/design',
  ),
  workspaceDashboardDesignLayout: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/design/layout',
    'Workspace - Dashboard - Design',
    OriginView.LAYOUT,
  ),
  workspaceDashboardDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/design/settings',
    'Workspace - Dashboard - Design',
    OriginView.SETTINGS_FILTER,
  ),
  workspaceDashboardGuestAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/design/guest-access',
    'Workspace - Dashboard - Design',
    OriginView.GUEST_ACCESS,
  ),
  workspaceDashboardGuestAccessInvite: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/design/guest-access/invite',
    'Workspace - Dashboard - Design',
    OriginView.INVITE_GUEST,
  ),
  workspaceDashboardDesignChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/design/changes',
  ),
  workspaceWidget: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)',
  ),
  workspaceWidgetChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)/changes',
    'Workspace - Dashboard - Changes Widget Click-through',
  ),
  workspaceWidgetListGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)/list_grid',
    'Workspace - Dashboard - List Widget Click-through',
  ),
  workspaceWidgetMetrics: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)/metrics',
    'Workspace - Dashboard - Metrics Widget Click-through',
  ),
  workspaceWidgetDashboardNote: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)/dashboard_note',
    'Workspace - Dashboard - Dashboard Note Widget Click-through',
  ),
  workspaceWidgetIntakeForm: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake',
    'Workspace - Dashboard - Intake Widget Click-through',
  ),
  workspaceWidgetIntakeSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake/settings',
    'Workspace - Dashboard - Intake Widget Settings',
  ),
  workspaceWidgetWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)/workload',
    'Workspace - Dashboard - Workload Widget Click-through',
  ),
  workspaceWidgetTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/dashboards/:dashboardId(\\d+)/widget/:widgetId(\\d+)/task_board',
    'Workspace - Dashboard - Task Board Widget Click-through',
  ),
  workspaceReport: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/reports/:widgetId(\\d+)',
    'Workspace - Report',
  ),
  workspaceReportDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/reports/:widgetId(\\d+)/design',
  ),
  workspaceReportDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/reports/:widgetId(\\d+)/design/settings',
    'Workspace - Report - Design',
  ),

  // --- Project Widget urls
  projectWidget: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)',
  ),
  projectWidgetChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/changes',
    'Project - Dashboard - Changes Widget Click-through',
  ),
  projectWidgetListGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/list_grid',
    'Project - Dashboard - List Widget Click-through',
  ),
  projectWidgetMetrics: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/metrics',
    'Project - Dashboard - Metrics Widget Click-through',
  ),
  projectWidgetDashboardNote: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/dashboard_note',
    'Project - Dashboard - Dashboard Note Widget Click-through',
  ),
  projectWidgetIntakeForm: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake',
    'Project - Dashboard - Intake Widget Click-through',
  ),
  projectWidgetIntakeSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake/settings',
    'Project - Dashboard - Intake Widget Settings',
  ),
  projectWidgetWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/workload',
    'Project - Dashboard - Workload Widget Click-through',
  ),
  projectWidgetTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/task_board',
    'Project - Dashboard - Task Board Widget Click-through',
  ),

  // --- Iterations urls

  iterationsPage: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/iterations'),

  // --- Collection urls
  scheduledCollection: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled'),
  scheduledCollectionPortfolio: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/portfolio',
    'Scheduled',
    OriginView.PORTFOLIO,
  ),
  scheduledCollectionLibrary: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/library',
    'Scheduled',
    OriginView.LIBRARY,
  ),
  scheduledCollectionReport: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/reports/:widgetId(\\d+)',
    'Scheduled - Report',
  ),
  scheduledCollectionReportDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/reports/:widgetId(\\d+)/design',
  ),
  scheduledCollectionReportDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/reports/:widgetId(\\d+)/design/settings',
    'Scheduled - Report - Design',
  ),

  scheduledCollectionDashboard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)',
    'Scheduled - Dashboard',
  ),
  scheduledCollectionDashboardDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/design',
  ),
  scheduledCollectionDashboardDesignLayout: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/design/layout',
    'Scheduled - Dashboard - Design',
    OriginView.LAYOUT,
  ),
  scheduledCollectionDashboardDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/design/settings',
    'Scheduled - Dashboard - Design',
    OriginView.SETTINGS_FILTER,
  ),
  scheduledCollectionDashboardDesignAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/design/guest-access',
    'Scheduled - Dashboard - Design',
    OriginView.GUEST_ACCESS,
  ),
  scheduledCollectionDashboardDesignAccessInvite: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/design/guest-access/invite',
    'Scheduled - Dashboard - Design',
    OriginView.INVITE_GUEST,
  ),

  scheduledCollectionWidget: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)',
  ),
  scheduledCollectionWidgetTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/task_board',
    'Scheduled - Dashboard - Task Board Widget Click-through',
  ),
  scheduledCollectionWidgetChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/changes',
    'Scheduled - Dashboard - Changes Widget Click-through',
  ),
  scheduledCollectionWidgetWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/workload',
    'Scheduled - Dashboard - Workload Widget Click-through',
  ),
  scheduledCollectionWidgetMetrics: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/metrics',
    'Scheduled - Dashboard - Metric Widget Click-through',
  ),
  scheduledCollectionWidgetDashboardNote: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/dashboard_note',
    'Scheduled - Dashboard - Dashboard Note Widget Click-through',
  ),
  scheduledCollectionWidgetListGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/list_grid',
    'Scheduled - Dashboard - List Widget Click-through',
  ),
  scheduledCollectionWidgetIntakeForm: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake',
    'Scheduled - Dashboard - Intake Widget Click-through',
  ),
  scheduledCollectionWidgetIntakeSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake/settings',
    'Scheduled - Dashboard - Intake Widget Settings',
  ),

  scheduledCollectionChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/changes',
    'Scheduled - Changes',
  ),
  scheduledCollectionGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/grid',
    'Scheduled - Grid',
  ),
  scheduledCollectionSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/search',
    'Scheduled - Search',
  ),
  scheduledCollectionWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/scheduled/workload',
    'Scheduled - Workload',
  ),

  pendingCollection: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending'),
  pendingCollectionPortfolio: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/portfolio',
    'Pending',
    OriginView.PORTFOLIO,
  ),
  pendingCollectionLibrary: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/library',
    'Pending',
    OriginView.LIBRARY,
  ),
  pendingCollectionReport: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/reports/:widgetId(\\d+)',
    'Pending - Report',
  ),
  pendingCollectionReportDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/reports/:widgetId(\\d+)/design',
  ),
  pendingCollectionReportDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/reports/:widgetId(\\d+)/design/settings',
    'Pending - Report - Design',
  ),
  pendingCollectionDashboard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)',
    'Pending - Dashboard',
  ),
  capacityCollection: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/capacity', 'Capacity'),
  capacityCollectionPortfolio: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/capacity/portfolio',
    'Capacity',
    OriginView.PORTFOLIO,
  ),
  capacityCollectionSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/capacity/search',
    'Capacity',
    OriginView.SEARCH,
  ),
  capacityCollectionChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/capacity/changes',
    'Capacity',
    OriginView.CHANGES,
  ),
  capacityCollectionCapacity: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/capacity/allocation',
    'Capacity',
    OriginView.ALLOCATION,
  ),
  pendingCollectionDashboardDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/design',
  ),
  pendingCollectionDashboardDesignLayout: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/design/layout',
    'Pending - Dashboard - Design',
    OriginView.LAYOUT,
  ),
  pendingCollectionDashboardDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/design/settings',
    'Pending - Dashboard - Design',
    OriginView.SETTINGS_FILTER,
  ),
  pendingCollectionDashboardDesignAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/design/guest-access',
    'Pending - Dashboard - Design',
    OriginView.GUEST_ACCESS,
  ),
  pendingCollectionDashboardDesignAccessInvite: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/design/guest-access/invite',
    'Pending - Dashboard - Design - Invite',
  ),

  pendingCollectionWidget: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)',
  ),
  pendingCollectionWidgetTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/task_board',
    'Pending - Dashboard - Task Board Widget Click-through',
  ),
  pendingCollectionWidgetChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/changes',
    'Pending - Dashboard - Changes Widget Click-through',
  ),
  pendingCollectionWidgetWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/workload',
    'Pending - Dashboard - Workload Widget Click-through',
  ),
  pendingCollectionWidgetMetrics: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/metrics',
    'Pending - Dashboard - Metric Widget Click-through',
  ),
  pendingCollectionWidgetDashboardNote: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/dashboard_note',
    'Pending - Dashboard - Dashboard Note Widget Click-through',
  ),
  pendingCollectionWidgetListGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/list_grid',
    'Pending - Dashboard - List Widget Click-through',
  ),
  pendingCollectionWidgetIntakeForm: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake',
    'Pending - Dashboard - Intake Widget Click-through',
  ),
  pendingCollectionWidgetIntakeSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake/settings',
    'Pending - Dashboard - Intake Widget Settings',
  ),

  pendingCollectionChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/changes',
    'Pending',
    OriginView.CHANGES,
  ),
  pendingCollectionGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/grid',
    'Pending',
    OriginView.GRID,
  ),
  pendingCollectionSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pending/search',
    'Pending',
    OriginView.SEARCH,
  ),

  archiveCollection: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive'),
  archiveCollectionPortfolio: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/portfolio',
    'Archived',
    OriginView.PORTFOLIO,
  ),
  archiveCollectionLibrary: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/library',
    'Archived',
    OriginView.LIBRARY,
  ),
  archiveCollectionReport: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/reports/:widgetId(\\d+)',
    'Archived - Report',
  ),
  archiveCollectionReportDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/reports/:widgetId(\\d+)/design',
  ),
  archiveCollectionReportDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/reports/:widgetId(\\d+)/design/settings',
    'Archived - Report - Design',
  ),
  archiveCollectionDashboard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)',
    'Archived - Dashboard',
  ),
  archiveCollectionDashboardDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/design',
  ),
  archiveCollectionDashboardDesignLayout: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/design/layout',
    'Archived - Dashboard - Design',
    OriginView.LAYOUT,
  ),
  archiveCollectionDashboardDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/design/settings',
    'Archived - Dashboard - Design',
    OriginView.SETTINGS_FILTER,
  ),
  archiveCollectionDashboardDesignAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/design/guest-access',
    'Archived - Dashboard - Design',
    OriginView.GUEST_ACCESS,
  ),
  archiveCollectionDashboardDesignAccessInvite: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/design/guest-access/invite',
    'Archived - Dashboard - Design - Invite',
  ),

  archiveCollectionWidget: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)',
  ),
  archiveCollectionWidgetTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/task_board',
    'Archived - Dashboard - Task Board Widget Click-through',
  ),
  archiveCollectionWidgetChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/changes',
    'Archived - Dashboard - Changes Widget Click-through',
  ),
  archiveCollectionWidgetWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/workload',
    'Archived - Dashboard - Workload Widget Click-through',
  ),
  archiveCollectionWidgetMetrics: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/metrics',
    'Archived - Dashboard - Metric Widget Click-through',
  ),
  archiveCollectionWidgetDashboardNote: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/dashboard_note',
    'Archived - Dashboard - Dashboard Note Widget Click-through',
  ),
  archiveCollectionWidgetListGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/list_grid',
    'Archived - Dashboard - List Widget Click-through',
  ),
  archiveCollectionWidgetIntakeForm: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake',
    'Archived - Dashboard - Intake Widget Click-through',
  ),
  archiveCollectionWidgetIntakeSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake/settings',
    'Archived - Dashboard - Intake Widget Settings',
  ),

  archiveCollectionChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/changes',
    'Archived',
    OriginView.CHANGES,
  ),
  archiveCollectionGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/grid',
    'Archived',
    OriginView.GRID,
  ),
  archiveCollectionSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/archive/search',
    'Archived',
    OriginView.SEARCH,
  ),

  templateCollection: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/template'),
  templateCollectionPortfolio: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/template/portfolio',
    'Template',
    OriginView.PORTFOLIO,
  ),
  templateCollectionChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/template/changes',
    'Template',
    OriginView.CHANGES,
  ),
  templateCollectionSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/template/search',
    'Template',
    OriginView.SEARCH,
  ),

  // --- Workspace Access Control urls
  workspaceAccess: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/access'),
  workspaceAccessControls: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/access/controls',
    'Workspace',
    OriginView.WORKSPACE_ACCESS,
  ),
  workspaceAccessRolesAndRules: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/access/roles_and_rules',
    'Workspace',
    OriginView.ROLES_AND_RULES,
  ),

  // --- Customize urls
  customize: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/customize'),
  customizeCostCodes: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/cost_codes',
    'Workspace - Cost Codes',
  ),
  customizeDevelopment: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/development'),
  customizeDataFields: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/custom_data_fields',
    'Workspace - Custom Data',
  ),
  customizeTaskStatus: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/custom_task_status',
    'Workspace - Customize Status',
  ),
  customizeRateSheets: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/rate_sheets',
    'Workspace - Rate Sheets Table',
  ),
  customizeBillingSheetRules: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/bill_sheets/:rateSheetId(\\d+)/rules',
    'Workspace - Billing Rate Sheets - Rules',
  ),
  customizeBillSheetRulesProjectsUsing: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/bill_sheets/:rateSheetId(\\d+)/rules/projects_using',
    'Workspace - Billing Rate Sheets - Projects Using',
  ),
  customizePaySheetRules: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pay_sheets/:rateSheetId(\\d+)/rules',
    'Workspace - Pay Rate Sheets - Rules',
  ),
  customizePaySheetRulesProjectsUsing: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/pay_sheets/:rateSheetId(\\d+)/rules/projects_using',
    'Workspace - Pay Rate Sheets - Projects Using',
  ),
  customizeWorkspaceSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/workspace_settings',
    'Workspace - Settings',
  ),

  // Story Point Schemes
  storyPointSchemes: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/story_point_schemes',
    'Workspace - Story Point Schemes',
  ),
  storyPointSchemeOwners: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/story_point_schemes/:storyPointSchemeId(\\d+)/owners',
    'Workspace - Story Point Schemes - Owners',
  ),

  // --- Landing pages for third-party integrations
  integrations: url('/integrations', 'Integrations'),
  slackIntegrations: url('/integrations/slack', 'Slack Integration'),
  jiraIntegrations: url('/integrations/jira', 'Jira Integration', OriginView.CONNECTIONS),
  jiraAbout: url('/integrations/jira/about', 'Jira Integration', OriginView.ABOUT),
  jiraIntegrationsCallback: url('/integrations/jira/callback'),

  // --- Single Sign On urls
  singleSignOn: url('/organization/:organizationId(\\d+)/single_sign_on', 'Single Sign On'),
  singleSignOnSetup: url('/organization/:organizationId(\\d+)/single_sign_on/setup_sso', 'Single Sign On - Setup SSO'),
  singleSignOnManage: url(
    '/organization/:organizationId(\\d+)/single_sign_on/manage_sso',
    'Single Sign On - Manage SSO',
  ),
  singleSignOnNexus: url(
    '/organization/:organizationId(\\d+)/single_sign_on/tempo-identity',
    'Single Sign On - New SSO Provider',
  ),
  singleSignOnTestUrl: url('v1/saml2/authenticate/:entityId([a-z-_\\d+]+)'),

  // --- API Setup urls
  api: url('/organization/:organizationId(\\d+)/api'),

  // --- Org Directory urls
  organizationHub: url('/organization/:organizationId(\\d+)/hub', 'Administration'),

  organizationHubUsers: url('/organization/:organizationId(\\d+)/hub/users', 'All Users', OriginView.LICENSED_USERS),
  organizationHubUsersInvite: url('/organization/:organizationId(\\d+)/hub/users/invite', 'All Users - Invite'),
  organizationHubUsersDashboardGuests: url(
    '/organization/:organizationId(\\d+)/hub/users/dashboard_guests',
    'All Users',
    OriginView.DASHBOARD_GUESTS,
  ),
  organizationHubUsersDisconnected: url(
    '/organization/:organizationId(\\d+)/hub/users/disconnected',
    'All Users',
    OriginView.DISCONNECTED_USERS,
  ),
  organizationHubUsersAdmins: url(
    '/organization/:organizationId(\\d+)/hub/users/admins',
    'All Users',
    OriginView.ADMINS,
  ),
  organizationHubUser: url(
    '/organization/:organizationId(\\d+)/hub/users/:organizationUserId(\\d+)',
    'Organization User Profile',
  ),
  organizationHubUsersPendingInvites: url(
    '/organization/:organizationId(\\d+)/hub/users/invite/pending',
    'All Users',
    OriginView.PENDING_INVITES,
  ),
  organizationHubUsersIntegrations: url(
    '/organization/:organizationId(\\d+)/hub/:organizationUserId(\\d+)/integrations',
  ),
  organizationHubUsersJiraIntegrations: url(
    '/organization/:organizationId(\\d+)/hub/:organizationUserId(\\d+)/integrations/jira',
    'Jira Integration',
    OriginView.CONNECTIONS,
  ),
  organizationHubUsersSlackIntegrations: url(
    '/organization/:organizationId(\\d+)/hub/:organizationUserId(\\d+)/integrations/slack',
    'Slack Integration',
  ),
  organizationHubUsersSlackIntegrationsAdmin: url(
    '/organization/:organizationId(\\d+)/hub/:organizationUserId(\\d+)/integrations/slack/admin',
    'Slack Integration - Admin',
  ),
  organizationHubUpgrade: url('/organization/:organizationId(\\d+)/hub/upgrade', 'Upgrade'),
  organizationHubManageAccount: url('/organization/:organizationId(\\d+)/hub/manage_account', 'Manage Account'),
  organizationHubManageAccountPlanUpgrade: url(
    '/organization/:organizationId(\\d+)/hub/manage_account/upgrade_plan',
    'Manage Account - Upgrade',
  ),
  organizationHubManageAccountPlanDowngrade: url(
    '/organization/:organizationId(\\d+)/hub/manage_account/downgrade_plan',
    'Manage Account - Downgrade',
  ),

  // --- MyDiscussions urls
  myDiscussions: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_discussions',
    'My Discussions',
  ),

  // --- MyWork urls
  myWork: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work', 'My Work'),
  myWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/workload',
    'My Work',
    OriginView.WORKLOAD,
  ),
  myWorkSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/search',
    'My Work',
    OriginView.SEARCH,
  ),
  myWorkTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/task_board',
    'My Work',
    OriginView.BOARD,
  ),
  myWorkTimesheet: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/timesheet',
    'My Work',
    OriginView.TIMESHEET,
  ),
  myWorkAvailability: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/availability',
    'My Work',
    OriginView.AVAILABILITY,
  ),
  myWorkChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/changes',
    'My Work',
    OriginView.CHANGES,
  ),
  myWorkProjectAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/project_access',
    'My Work',
    OriginView.PROJECT_ACCESS,
  ),
  myWorkGroupAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/group_access',
    'My Work',
    OriginView.GROUP_ACCESS,
  ),
  myWorkRolesAndRulesAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/roles_rules_access',
    'My Work',
    OriginView.ROLES_AND_RULES_ACCESS,
  ),
  myWorkProfile: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/my_work/profile',
    'My Work',
    OriginView.PROFILE,
  ),

  // Nova
  nova: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/nova'),
  novaBacklog: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/nova/plan/:planId(\\d+)/backlog'),
  novaScenarios: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/nova/plan/:planId(\\d+)/scenario/',
  ),
  novaScenario: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/nova/plan/:planId(\\d+)/scenario/:scenarioId(\\d+)',
  ),

  // --- Projects urls
  projects: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/projects'),
  projectsAll: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/projects/all', 'All Projects'),
  projectsSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/projects/search',
    'All Projects - Search',
  ),

  // --- Project urls
  project: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)'),
  projectProject: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/project',
    'Project',
    OriginView.SINGLE_PROJECT_VIEW,
  ),
  projectLibrary: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/library',
    'Project',
    OriginView.LIBRARY,
  ),
  projectLibraryReport: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/reports/:widgetId(\\d+)',
    'Project - Report',
  ),
  projectLibraryReportDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/reports/:widgetId(\\d+)/design',
  ),
  projectLibraryReportDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/reports/:widgetId(\\d+)/design/settings',
    'Project - Report - Design',
  ),
  projectAddTasks: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/add_tasks',
  ),
  projectAddTasksMulti: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/add_tasks/multi',
    'Project - Add Tasks',
    OriginView.ADD_MULTIPLE_TASKS,
  ),
  projectAddTasksImport: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/add_tasks/import',
    'Project - Add Tasks',
    OriginView.IMPORT_TASKS_FROM_EXCEL,
  ),
  projectBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/task_board',
    'Project',
    OriginView.TASK_BOARD,
  ),
  projectChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/changes',
    'Project',
    OriginView.CHANGES,
  ),
  projectTime: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/time',
    'Project',
    OriginView.TIME,
  ),
  projectDashboardDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/design',
  ),
  projectDashboardDesignLayout: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/design/layout',
    'Project - Dashboard - Design',
    OriginView.LAYOUT,
  ),
  projectDashboardDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/design/settings',
    'Project - Dashboard - Design',
    OriginView.SETTINGS_FILTER,
  ),
  projectDashboardGuestAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/design/guest-access',
    'Project - Dashboard - Design',
    OriginView.GUEST_ACCESS,
  ),
  projectDashboardGuestAccessInvite: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)/design/guest-access/invite',
    'Project - Dashboard - Design - Invite Guest',
  ),
  projectGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/grid',
    'Project',
    OriginView.GRID,
  ),
  projectSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/search',
    'Project',
    OriginView.SEARCH,
  ),
  projectWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/workload',
    'Project',
    OriginView.WORKLOAD,
  ),
  projectAllocation: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/capacity',
    'Project',
    OriginView.CAPACITY,
  ),
  projectLibraryDashboard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/project/:itemId(\\d+)/dashboard/:dashboardId(\\d+)',
    'Project - Dashboard',
  ),

  // --- Package urls
  package: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)'),
  singlePackage: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/package',
    'Package',
  ),
  packageChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/changes',
    'Package',
    OriginView.CHANGES,
  ),
  packageLibrary: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/library',
    'Package',
    OriginView.LIBRARY,
  ),
  packageLibraryReport: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/reports/:widgetId(\\d+)',
    'Package - Report',
  ),
  packageLibraryReportDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/reports/:widgetId(\\d+)/design',
  ),
  packageLibraryReportDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/reports/:widgetId(\\d+)/design/settings',
    'Package - Report - Design',
  ),
  packageLibraryDashboard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)',
    'Package - Dashboard',
  ),
  packageDashboardDesign: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/design',
  ),
  packageDashboardDesignLayout: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/design/layout',
    'Package - Dashboard - Design',
    OriginView.LAYOUT,
  ),
  packageDashboardDesignSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/design/settings',
    'Package - Dashboard - Design',
    OriginView.SETTINGS_FILTER,
  ),
  packageDashboardGuestAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/design/guest-access',
    'Package - Dashboard - Design',
    OriginView.GUEST_ACCESS,
  ),
  packageDashboardGuestAccessInvite: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/design/guest-access/invite',
    'Package - Dashboard - Design - Invite Guest',
  ),
  packageGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/grid',
    'Package',
    OriginView.GRID,
  ),
  packageSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/search',
    'Package',
    OriginView.SEARCH,
  ),
  packageWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/workload',
    'Package',
    OriginView.WORKLOAD,
  ),
  packageTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/task_board',
    'Package',
    OriginView.TASK_BOARD,
  ),
  packageAllocation: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/capacity',
    'Package - Capacity',
  ),

  // --- Package Widget urls
  packageWidget: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)',
  ),
  packageWidgetChanges: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/changes',
    'Package - Widget - Changes Widget Click-through',
  ),
  packageWidgetListGrid: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/list_grid',
    'Package - Widget - List Widget Click-through',
  ),
  packageWidgetMetrics: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/metrics',
    'Package - Widget - Metric Widget Click-through',
  ),
  packageWidgetDashboardNote: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/dashboard_note',
    'Package - Widget - Dashboard Note Widget Click-through',
  ),
  packageWidgetIntakeForm: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake',
    'Package - Widget - Intake Widget Click-through',
  ),
  packageWidgetIntakeSettings: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/intake/settings',
    'Package - Widget - Intake Widget Settings',
  ),
  packageWidgetWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/workload',
    'Package - Widget - Workload Widget Click-through',
  ),
  packageWidgetTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/package/:packageId(\\d+)/dashboard/:dashboardId(\\d+)/widget/:widgetId(\\d+)/task_board',
    'Package - Widget - Task Board Widget Click-through',
  ),

  people: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people', 'People', OriginView.PROFILES),
  workspaceGuestsList: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/guests/'),
  workspaceGuestProfile: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/guests/:userId(\\d+)',
    'People',
    OriginView.GUEST_PROFILE,
  ),
  membersList: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members'),
  memberInvitation: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/invitation',
    'Add Member',
  ),
  member: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)'),
  memberAvailability: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/availability',
    'People - Member Profile',
    OriginView.AVAILABILITY,
  ),
  memberTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/task_board',
    'People - Member Profile',
    OriginView.TASK_BOARD,
  ),
  memberWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/workload',
    'People - Member Profile',
    OriginView.WORKLOAD,
  ),
  memberAdminActions: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/admin_actions',
  ),
  memberChangeLog: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/change_log',
    'People - Member Profile',
    OriginView.CHANGES,
  ),
  memberSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/search',
    'People - Member Profile',
    OriginView.SEARCH,
  ),
  memberTimesheet: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/timesheet',
    'People - Member Profile',
    OriginView.TIMESHEET,
  ),
  memberProjectAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/project_access',
    'People - Member Profile',
    OriginView.PROJECT_ACCESS,
  ),
  memberGroupAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/group_access',
    'People - Member Profile',
    OriginView.GROUP_ACCESS,
  ),
  memberRolesAndRulesAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/members/:userId(\\d+)/roles_rules_access',
    'People - Member Profile',
    OriginView.ROLES_AND_RULES,
  ),

  placeholdersList: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/placeholders'),
  placeholdersAdd: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/placeholders/add',
    'People - Add Placeholder',
  ),
  placeholder: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/placeholders/:userId(\\d+)',
    'People - Placeholder Profile',
  ),

  resourcesList: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/resources'),
  resourcesAdd: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/resources/add',
    'People - Add Resource',
  ),
  resource: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/resources/:userId(\\d+)'),
  resourceWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/resources/:userId(\\d+)/workload',
    'People - Resource Profile',
    OriginView.WORKLOAD,
  ),
  resourceTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/resources/:userId(\\d+)/task_board',
    'People - Resource Profile',
    OriginView.TASK_BOARD,
  ),
  resourceTimesheet: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/resources/:userId(\\d+)/timesheet',
    'People - Resource Profile',
    OriginView.TASK_BOARD,
  ),
  resourceAvailability: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/resources/:userId(\\d+)/availability',
    'People - Resource Profile',
    OriginView.AVAILABILITY,
  ),
  resourceSearch: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/resources/:userId(\\d+)/search',
    'People - Resource Profile',
    OriginView.SEARCH,
  ),

  guest: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/guests/:userId(\\d+)',
    'Guest Profile',
  ),

  time: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/time', 'People', OriginView.TIME),

  teams: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/teams',
    'People',
    OriginView.TEAMS,
  ),
  team: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/teams/:groupId(\\d+)',
    'People - Team',
  ),
  teamWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/teams/:groupId(\\d+)/workload',
    'People - Team',
    OriginView.WORKLOAD,
  ),
  teamTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/teams/:groupId(\\d+)/task_board',
    'People - Team',
    OriginView.TASK_BOARD,
  ),
  teamProjects: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/teams/:groupId(\\d+)/projects',
    'People - Team',
  ),
  teamTime: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/teams/:groupId(\\d+)/time',
    'People - Team',
    OriginView.TIME,
  ),
  groups: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/groups',
    'People',
    OriginView.GROUPS,
  ),
  group: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/groups/:groupId(\\d+)',
    'People - Group',
    OriginView.MEMBERS,
  ),
  groupTaskBoard: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/groups/:groupId(\\d+)/task_board',
    'People - Group',
    OriginView.TASK_BOARD,
  ),
  groupWorkload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/groups/:groupId(\\d+)/workload',
    'People - Group',
    OriginView.WORKLOAD,
  ),
  groupTime: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/groups/:groupId(\\d+)/time',
    'People - Group',
    OriginView.TIME,
  ),
  groupProjectAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/groups/:groupId(\\d+)/project_access',
    'People - Group',
    OriginView.PROJECT_ACCESS,
  ),
  groupRolesAndRulesAccess: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/groups/:groupId(\\d+)/roles_rules_access',
    'People - Group',
    OriginView.ROLES_AND_RULES,
  ),
  membersAvailability: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/availability',
    'People - Member Profile',
    OriginView.AVAILABILITY,
  ),
  timesheetReview: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/timesheet_review'),
  timesheetExport: url('/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/people/timesheet_export'),

  itemFileDownload: url(
    '/organization/:organizationId(\\d+)/workspace/:workspaceId(\\d+)/item/:itemId(\\d+)/file/:s3id/download',
  ),
};

export const defaultLandingPage = frontend.scheduledCollectionPortfolio;
export const defaultGuestLandingPage = frontend.dashboardPassports;

export const thirdPartyUrls = {
  apiDocumentation: 'http://api-docs.liquidplanner.com/',
  productAdvisor: 'https://www.tempo.io/portfolio-manager-demo',
  portfolioManagerMktg: 'https://www.tempo.io/products/portfolio-manager',
  pricing: 'https://www.tempo.io/pricing/portfolio-manager',
  privacyPolicy: 'https://www.tempo.io/portfolio-manager-privacy',
  releaseNotes: 'https://help.tempo.io/release-notes/latest/portfolio-manager-release-notes',
  status: 'https://status.liquidplanner.com',
  tempoIo: 'https://www.tempo.io/',
  termsOfService: 'https://www.tempo.io/portfolio-manager-terms',
};
