import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import CustomFieldPicklistValue from 'features/common/custom_fields/custom_field_value/custom_field_picklist_value';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';
import { frontend } from 'lib/urls';

const CustomFieldPicklist = ({ fieldId, resourceId, disabled = false }: CustomFieldValueProps) => {
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);

  if (!field) {
    return null;
  }

  const { allowsMultipleValues, values, picklistChoices } = field;

  if (!picklistChoices?.some((choice) => !choice.archived)) {
    return (
      <>
        Picklist values haven&apos;t been set up yet. They can be added in{' '}
        <LpLink
          to={frontend.customizeDataFields.url({
            organizationId,
            workspaceId,
          })}
        >
          Custom Data Fields
        </LpLink>
        .
      </>
    );
  }

  const allCurrentlySelectedValueIds =
    values?.reduce((acc: Array<number>, values) => {
      if (values.picklistChoice) {
        acc.push(values.picklistChoice.id);
      }
      return acc;
    }, []) ?? [];

  const canAddPicklistValue = !values?.length || allowsMultipleValues;

  return (
    <>
      {values?.map((fieldValue) => (
        <CustomFieldPicklistValue
          key={fieldValue.id}
          allowMultipleValues={allowsMultipleValues}
          otherSelectedPicklistChoiceIds={allCurrentlySelectedValueIds.filter(
            (selectedValueId) => selectedValueId !== fieldValue.picklistChoice?.id,
          )}
          disabled={disabled}
          fieldId={fieldId}
          fieldValueId={fieldValue.id}
          picklistChoices={picklistChoices}
          resourceId={resourceId}
        />
      ))}
      {canAddPicklistValue && (
        <CustomFieldPicklistValue
          allowMultipleValues={allowsMultipleValues}
          otherSelectedPicklistChoiceIds={allCurrentlySelectedValueIds}
          disabled={disabled}
          fieldId={fieldId}
          picklistChoices={picklistChoices}
          resourceId={resourceId}
        />
      )}
    </>
  );
};

export default CustomFieldPicklist;
