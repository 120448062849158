import { useSignal } from '@preact/signals-react';
import { MotionConfig } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { Tab, TabProps } from 'semantic-ui-react';

import { ItemType, PackageStatus } from 'daos/enums';
import { OrderItemFetch } from 'daos/item_enums';
import { SearchEventProps, SearchPageContext } from 'features/common/search/context';
import { SearchDashboardsAndReportsTab } from 'features/common/search/dashboard_and_reports_tab';
import { SearchFilesTab } from 'features/common/search/files_tab';
import { SearchHeader } from 'features/common/search/header';
import { HelpfulInfo } from 'features/common/search/helpful_info';
import { SearchItemsTab } from 'features/common/search/items_tab';
import { LibraryLocation } from 'features/library/types';
import useQueryParams from 'hooks/use_query_params';
import { getCurrentOrganizationUser, getOrganizationUserInCurrentOrgForUserId } from 'state/entities/selectors/user';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

import { SearchContext, SearchLocation } from './enums';

import './search.scss';

interface SearchProps {
  searchContext: SearchContext;
  itemTypes: ItemType | Array<ItemType>;
  packageStatuses?: Array<PackageStatus>;
  ancestorId?: number;
  order?: OrderItemFetch;
  orgUserId?: number;
  includeDone?: boolean;
  searchLocation: SearchLocation;
  libraryLocation?: LibraryLocation;
  workspaceRootId?: number;
}

enum TabNames {
  PlanItems = 'Plan Items',
  AttachedFiles = 'Attached Files',
  DashboardsAndReports = 'Dashboard & Reports',
}

export enum TabQueryParam {
  PlanItems = 'plan_items',
  AttachedFiles = 'attached_files',
  DashboardsAndReports = 'dashboards_and_reports',
}

const searchTabQueryParam = {
  [TabNames.PlanItems]: TabQueryParam.PlanItems,
  [TabNames.AttachedFiles]: TabQueryParam.AttachedFiles,
  [TabNames.DashboardsAndReports]: TabQueryParam.DashboardsAndReports,
};

const searchTabQueryParamToDisplayName = {
  [TabQueryParam.PlanItems]: TabNames.PlanItems,
  [TabQueryParam.AttachedFiles]: TabNames.AttachedFiles,
  [TabQueryParam.DashboardsAndReports]: TabNames.DashboardsAndReports,
};

const Search = ({
  searchContext,
  searchLocation,
  order,
  itemTypes,
  packageStatuses,
  orgUserId,
  ancestorId,
  includeDone = false,
  libraryLocation,
}: SearchProps) => {
  const [showHelpInfo, setShowHelpInfo] = useState(true);
  const params = useParams<{ userId: string }>();
  const userId = Number(params.userId);
  const orgMember = useSelector((state) =>
    userId ? getOrganizationUserInCurrentOrgForUserId(state, userId) : undefined,
  );
  const currentOrgUser = useSelector(getCurrentOrganizationUser);
  const username = orgMember ? orgMember.username : currentOrgUser?.username;
  const searchEvent = useSignal<SearchEventProps | null>(null);
  const workspace = useSelector(getCurrentWorkspace);

  const history = useHistory();
  const searchType = useQueryParams().searchType as TabQueryParam;
  const pathName = useLocation().pathname;

  const searchProps = useMemo(
    () => ({
      order,
      itemTypes,
      packageStatuses,
      orgUserId,
      ancestorId,
      includeDone,
      libraryLocation,
    }),
    [ancestorId, libraryLocation, includeDone, itemTypes, order, orgUserId, packageStatuses],
  );

  const handleSearchTabChange = (tabProps: TabProps) => {
    const activeIndex = Number(tabProps.activeIndex);
    const selectedPaneMenuItem = tabProps.panes?.[activeIndex]?.menuItem;

    if (selectedPaneMenuItem) {
      const queryParam = searchTabQueryParam[selectedPaneMenuItem as TabNames];

      history.push(`${pathName}?searchType=${queryParam}`);
    }
  };

  const panes = useMemo(
    () => [
      {
        menuItem: TabNames.PlanItems,
        render: () => (
          <Tab.Pane attached={false} className="lp-search__tab">
            <SearchItemsTab
              searchProps={searchProps}
              searchContext={searchContext}
              emptyState={
                <HelpfulInfo showHelpInfo={showHelpInfo} username={username} searchLocation={searchLocation} />
              }
            />
          </Tab.Pane>
        ),
      },
      workspace?.fileUploads
        ? {
            menuItem: TabNames.AttachedFiles,
            render: () => (
              <Tab.Pane attached={false} className="lp-search__tab">
                <SearchFilesTab
                  searchProps={searchProps}
                  emptyState={
                    <HelpfulInfo showHelpInfo={showHelpInfo} username={username} searchLocation={searchLocation} />
                  }
                />
              </Tab.Pane>
            ),
          }
        : {},
      {
        menuItem: TabNames.DashboardsAndReports,
        render: () => (
          <Tab.Pane attached={false} className="lp-search__tab">
            <SearchDashboardsAndReportsTab
              searchProps={searchProps}
              emptyState={
                <HelpfulInfo showHelpInfo={showHelpInfo} username={username} searchLocation={searchLocation} />
              }
            />
          </Tab.Pane>
        ),
      },
    ],
    [workspace?.fileUploads, searchProps, searchContext, showHelpInfo, username, searchLocation],
  );

  const activeIndex = useMemo(() => {
    const index = panes.findIndex((pane) => pane.menuItem === searchTabQueryParamToDisplayName[searchType]);
    const activeTabOrFirstIndex = Math.max(0, index);

    return activeTabOrFirstIndex;
  }, [panes, searchType]);

  return (
    <MotionConfig transition={{ type: 'spring' }}>
      <SearchPageContext.Provider value={{ searchEvent }}>
        <SearchHeader setShowHelpInfo={setShowHelpInfo} />
        <Tab
          activeIndex={activeIndex}
          onTabChange={(_, data) => handleSearchTabChange(data)}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </SearchPageContext.Provider>
    </MotionConfig>
  );
};

export default Search;
