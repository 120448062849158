import { AddEditGridRows } from 'features/common/data_grid/types';
import { isValidDateRange } from 'lib/helpers/date_valid';

export const validItemName = (itemName: string | undefined) => {
  if (!itemName) {
    return false;
  }

  const trimmedItemName = itemName.trim();

  if (!trimmedItemName.length) {
    return false;
  }

  return true;
};

export const validEfforts = ({
  highEffortHours = 0,
  lowEffortHours = 0,
}: {
  highEffortHours?: string | number;
  lowEffortHours?: string | number;
}) => {
  const low = Number(lowEffortHours);
  const high = Number(highEffortHours);

  return low <= high && low >= 0;
};

export const validWorkLimit = (workLimitValue: string | number) => {
  const workLimit = Number(workLimitValue);

  return workLimit >= 0;
};

export const validateAddGridRows = (rows: AddEditGridRows) =>
  rows.every(
    (row) =>
      validItemName(row.name) &&
      isValidDateRange(row.targetStart ?? '', row.targetFinish ?? '') &&
      validEfforts({ highEffortHours: row.highEffortHours, lowEffortHours: row.lowEffortHours }) &&
      validWorkLimit(row.workLimit ?? 0),
  );

export const getAddGridCellErrorCount = (rows: AddEditGridRows) => {
  let totalErrorCount = 0;

  rows.forEach((row) => {
    if (!isValidDateRange(row.targetStart ?? '', row.targetFinish ?? '')) {
      totalErrorCount++;
    }

    if (!validEfforts({ highEffortHours: row.highEffortHours, lowEffortHours: row.lowEffortHours })) {
      totalErrorCount++;
    }

    if (!validWorkLimit(row.workLimit ?? 0)) {
      totalErrorCount++;
    }
  });

  return totalErrorCount;
};
