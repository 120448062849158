import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { StatusFilterGroups } from 'daos/enums';
import { Item, TaskStatus } from 'daos/model_types';
import { compareByPriority } from 'lib/helpers/comparison_helpers';
import { ReadonlyRecord } from 'lib/readonly_record';
import { getItemsById } from 'state/entities/selectors/item';
import {
  taskStatusMatchesStatusGroup,
  getTaskStatusesById,
  getActiveCurrentWorkspaceTaskStatusesSortedByPriority,
} from 'state/entities/selectors/task_status';

interface UseTaskStatusFilterHookProps {
  ids: ReadonlyArray<number>;
  filterStatus: StatusFilterGroups;
  customTaskStatusIds?: ReadonlySet<number>;
}

interface UseTaskStatusFilterHookValues {
  filteredIds: ReadonlyArray<number>;
  filteredIdsLookUpByTaskStatus?: { [taskStatusId: string]: Array<number> };
  taskStatusIds: ReadonlyArray<number>;
}

interface GetItemsForTaskStatusFilterProps {
  itemIds: ReadonlyArray<number>;
  taskStatusFilter: StatusFilterGroups;
  customTaskStatusIds?: ReadonlySet<number>;
  itemsById: ReadonlyRecord<string, Item>;
  taskStatusesById: ReadonlyRecord<string, TaskStatus>;
  activeTaskStatuses: ReadonlyArray<TaskStatus>;
}
interface GetAtRiskItemsTaskStatusFilterProps {
  filterOption: StatusFilterGroups.Asap | StatusFilterGroups.atRisk;
  itemIds: ReadonlyArray<number>;
  itemsById: ReadonlyRecord<string, Item>;
  taskStatusesById: ReadonlyRecord<string, TaskStatus>;
}

const getItemsForTaskStatusFilter = ({
  itemIds,
  taskStatusFilter,
  itemsById,
  taskStatusesById,
  activeTaskStatuses,
  customTaskStatusIds,
}: GetItemsForTaskStatusFilterProps) => {
  const filteredIds: Array<number> = [];
  const taskStatuses: Set<TaskStatus> = new Set(activeTaskStatuses);

  itemIds.forEach((id) => {
    const item = itemsById[id];
    const taskStatus = item?.taskStatus ? taskStatusesById[item.taskStatus.id] : undefined;

    if (taskStatus) {
      if (taskStatusMatchesStatusGroup(taskStatus, taskStatusFilter, customTaskStatusIds)) {
        filteredIds.push(id);

        if (taskStatus.archived) {
          taskStatuses.add(taskStatus);
        }
      }
    } else {
      // Don't filter out non-task items (which won't have a task status)
      filteredIds.push(id);
    }
  });

  const prioritizedActiveAndInUseArchivedTaskStatusIds = Array.from(taskStatuses)
    .filter((taskStatus) => taskStatusMatchesStatusGroup(taskStatus, taskStatusFilter, customTaskStatusIds))
    .sort(compareByPriority)
    .map((taskStatus) => taskStatus.id);

  return { filteredIds, taskStatusIds: prioritizedActiveAndInUseArchivedTaskStatusIds };
};

const getFilterOptionItemsTaskStatus = ({
  filterOption,
  itemIds,
  itemsById,
  taskStatusesById,
}: GetAtRiskItemsTaskStatusFilterProps) => {
  const filteredItemIds: Array<number> = [];
  const filteredTaskIdsByTaskStatus: { [taskStatusId: string]: Array<number> } = {};
  const taskStatusWithFilteredItems: Set<TaskStatus> = new Set();

  itemIds.forEach((id) => {
    const item = itemsById[id];
    const taskStatus = item?.taskStatus ? taskStatusesById[item.taskStatus.id] : undefined;
    const isAsapFilteredItem = filterOption === StatusFilterGroups.Asap && item?.scheduleDirective?.includes('asap');
    const isAtRiskFilteredItem = filterOption === StatusFilterGroups.atRisk && item?.late;

    if (taskStatus) {
      if (!filteredTaskIdsByTaskStatus[taskStatus.id]) {
        filteredTaskIdsByTaskStatus[taskStatus.id] = [];
      }

      if (isAsapFilteredItem || isAtRiskFilteredItem) {
        filteredTaskIdsByTaskStatus[taskStatus.id]?.push(id);
        taskStatusWithFilteredItems.add(taskStatus);
      }
    } else {
      filteredItemIds.push(id);
    }
  });

  const prioritizedTaskStatusIdsForLateItems = Array.from(taskStatusWithFilteredItems)
    .sort(compareByPriority)
    .map((taskStatus) => taskStatus.id);

  return {
    filteredIds: filteredItemIds,
    filteredIdsLookUpByTaskStatus: filteredTaskIdsByTaskStatus,
    taskStatusIds: prioritizedTaskStatusIdsForLateItems,
  };
};

const useTaskStatusFilter = ({
  ids = [],
  filterStatus,
  customTaskStatusIds,
}: UseTaskStatusFilterHookProps): UseTaskStatusFilterHookValues => {
  const itemsById = useSelector(getItemsById);
  const taskStatusesById = useSelector(getTaskStatusesById);
  const activeTaskStatuses = useSelector(getActiveCurrentWorkspaceTaskStatusesSortedByPriority);

  if (filterStatus === StatusFilterGroups.Asap || filterStatus === StatusFilterGroups.atRisk) {
    return getFilterOptionItemsTaskStatus({
      filterOption: filterStatus,
      itemIds: ids,
      itemsById,
      taskStatusesById,
    });
  }

  return getItemsForTaskStatusFilter({
    activeTaskStatuses,
    customTaskStatusIds,
    itemIds: ids,
    itemsById,
    taskStatusesById,
    taskStatusFilter: filterStatus,
  });
};

export default useTaskStatusFilter;

export const useTaskStatusFilterCallback = (itemIds: ReadonlyArray<number>) => {
  const itemsById = useSelector(getItemsById);
  const taskStatusesById = useSelector(getTaskStatusesById);
  const activeTaskStatuses = useSelector(getActiveCurrentWorkspaceTaskStatusesSortedByPriority);

  const getTasksForFilter = useCallback(
    (taskStatusFilter: StatusFilterGroups) => {
      const { filteredIds } = getItemsForTaskStatusFilter({
        itemsById,
        itemIds,
        taskStatusFilter,
        taskStatusesById,
        activeTaskStatuses,
      });

      return filteredIds;
    },
    [activeTaskStatuses, itemIds, itemsById, taskStatusesById],
  );

  return getTasksForFilter;
};
