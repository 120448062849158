import { useFormikContext } from 'formik';

import {
  AllowedCustomField,
  FieldMappingRowType,
  FieldMappingValue,
  JiraProjectModalFormFieldMapping,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
} from 'features/jira_project/modal/types';
import { convertFieldTypeToAllowedFieldType, getIsLpSystemField } from 'features/jira_project/modal/utils';

export const useFieldMappings = () => {
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const { fieldMappings } = values;

  const createFieldMappingItem = ({
    row,
    rowType,
    fieldId,
  }: {
    row: FieldMappingValue;
    rowType: FieldMappingRowType;
    fieldId: string;
  }) => {
    if (rowType === FieldMappingRowType.JiraToLp) {
      const isLpSystemField = getIsLpSystemField(fieldId);
      const newFieldMappingItem: JiraProjectModalFormFieldMapping = {
        sourceFieldId: row.id,
        lpCustomFieldId: isLpSystemField ? null : Number(fieldId),
        lpSystemField: isLpSystemField ? fieldId : null,
        fieldType: convertFieldTypeToAllowedFieldType(row),
        isPull: true,
        isPush: false,
      };

      return setFieldValue(JiraProjectModalFormFields.FieldMappings, [...fieldMappings, newFieldMappingItem]);
    }
    const isLpSystemField = getIsLpSystemField(row.id);
    const newFieldMappingItem: JiraProjectModalFormFieldMapping = {
      sourceFieldId: fieldId,
      lpCustomFieldId: isLpSystemField ? null : Number(row.id),
      lpSystemField: isLpSystemField ? row.id : null,
      fieldType: row.type as AllowedCustomField,
      isPull: false,
      isPush: true,
    };
    setFieldValue(JiraProjectModalFormFields.FieldMappings, [...fieldMappings, newFieldMappingItem]);
  };

  const findFieldMappingItem = ({ rowId, rowType }: { rowId: string; rowType: FieldMappingRowType }) => {
    switch (rowType) {
      case FieldMappingRowType.JiraToLp:
        return fieldMappings.find((item) => item.sourceFieldId === rowId);
      case FieldMappingRowType.LpToJira:
        return fieldMappings.find((item) => item.lpCustomFieldId === Number(rowId) || item.lpSystemField === rowId);
    }
  };

  const updateFieldMappingItem = ({
    rowType,
    row,
    fieldId,
    isTwoWaySyncChecked,
  }: {
    rowType: FieldMappingRowType;
    row: FieldMappingValue;
    fieldId: string;
    isTwoWaySyncChecked?: boolean;
  }) => {
    if (rowType === FieldMappingRowType.JiraToLp) {
      const isLpSystemField = getIsLpSystemField(fieldId);
      const updatedFieldMappingValues = fieldMappings.map((item) =>
        item.sourceFieldId === row.id
          ? {
              ...item,
              lpSystemField: isLpSystemField ? fieldId : null,
              lpCustomFieldId: isLpSystemField ? null : Number(fieldId),
              ...(isTwoWaySyncChecked !== undefined && {
                isPush: isTwoWaySyncChecked,
              }),
            }
          : item,
      );
      return setFieldValue(JiraProjectModalFormFields.FieldMappings, updatedFieldMappingValues);
    }

    const updatedFieldMappingValues = fieldMappings.map((item) =>
      item.lpCustomFieldId === Number(row.id) || item.lpSystemField === row.id
        ? {
            ...item,
            sourceFieldId: fieldId,
            ...(isTwoWaySyncChecked !== undefined && {
              isPull: isTwoWaySyncChecked,
            }),
          }
        : item,
    );

    setFieldValue(JiraProjectModalFormFields.FieldMappings, updatedFieldMappingValues);
  };

  const deleteFieldMappingItem = ({ fieldMappingItem }: { fieldMappingItem: JiraProjectModalFormFieldMapping }) => {
    const updatedFieldMappingValues = values[JiraProjectModalFormFields.FieldMappings].filter(
      (item) =>
        item.lpCustomFieldId !== fieldMappingItem.lpCustomFieldId ||
        item.lpSystemField !== fieldMappingItem.lpSystemField ||
        item.sourceFieldId !== fieldMappingItem.sourceFieldId,
    );

    setFieldValue(JiraProjectModalFormFields.FieldMappings, updatedFieldMappingValues);
  };

  return {
    createFieldMappingItem,
    deleteFieldMappingItem,
    findFieldMappingItem,
    updateFieldMappingItem,
  };
};
