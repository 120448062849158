import { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ItemDao } from 'daos/item';
import { OrderItemFetch } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';
import { SearchItemsFilterProps, searchItemsFilter } from 'lib/quick_filter_and_search_helpers/search';

export type UseItemSearchProps = Omit<SearchItemsFilterProps, 'searchValue'>;

export const useItemSearch = (
  searchParams: UseItemSearchProps,
  setResults: Dispatch<SetStateAction<Array<Item> | undefined>>,
) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const itemSearch = useCallback(
    (searchValue: string, includeDone: boolean) => {
      setResults(undefined);

      const trimmedValue = searchValue.trim();

      if (!trimmedValue) {
        return;
      }

      const { uuid } = dispatch(
        ItemDao.itemsSearch(
          { organizationId, workspaceId },
          {
            filter: searchItemsFilter({ ...searchParams, searchValue, includeDone }),
            include: { includeAncestors: true, includeTaskStatus: true },
            query: { limit: 500, order: searchParams.order ?? OrderItemFetch.GlobalPriority },
          },
        ),
      );

      dispatch(awaitRequestFinish<ReadonlyArray<Item>>(uuid, { onSuccess: ({ data }) => setResults([...data]) }));
    },
    [dispatch, organizationId, searchParams, setResults, workspaceId],
  );

  return { itemSearch };
};
