import classNames from 'classnames';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';

import ItemIcon from 'containers/shared/item_icon';
import LpLink from 'containers/shared/lp_link';
import { Permission } from 'daos/enums';
import { ExternalIntegrationDao } from 'daos/external_integration';
import { ExternalIntegrationSyncProjectJob, JiraAccessibleResourcesView, JiraMapping, User } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, pencilSolid } from 'features/common/lp_icon';
import JiraModal from 'features/jira_project/modal';
import { handleJiraProjectDetailsResponse } from 'features/jira_project/modal/utils';
import { useHasAccess } from 'hooks/use_has_access';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getItemForId, getPackageIdForItemId, getPackageStatusForItemId } from 'state/entities/selectors/item';
import { getWorkspaceUserForSpaceIdAndUserId } from 'state/entities/selectors/user';
import { slate700 } from 'style/variables';

import './jira_connected_projects.scss';

const JiraConnectedProjectsCells = ({ jiraProjectId, itemId }: { jiraProjectId: number; itemId: number }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const item = useSelector((state) => getItemForId(state, itemId));
  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, itemId));
  const packageId = useSelector((state) => getPackageIdForItemId(state, itemId));
  const hasAccess = useHasAccess(Permission.EDIT, item);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [syncProjectJob, setSyncProjectJob] = useState<ExternalIntegrationSyncProjectJob | undefined>();
  const [jiraInstance, setJiraInstance] = useState<JiraAccessibleResourcesView | undefined>();
  const [authorizedUser, setAuthorizedUser] = useState<User | undefined>();
  const userId = authorizedUser?.id ?? 0;
  const workspaceUser = useSelector((state) => getWorkspaceUserForSpaceIdAndUserId(state, workspaceId, userId));

  const fetchJiraProjectDetails = useCallback(() => {
    setIsLoading(true);
    const externalIntegrationsFetchFn = ExternalIntegrationDao.fetch(
      {
        organizationId,
        workspaceId,
        externalIntegrationId: jiraProjectId,
      },
      {
        include: {
          syncProjectJobs: true,
          items: true,
          oauthCredentialsUsers: true,
          jiraAccessibleResourcesView: true,
        },
      },
    );

    const { uuid } = dispatch(externalIntegrationsFetchFn);

    dispatch(
      awaitRequestFinish<ReadonlyArray<JiraMapping>>(uuid, {
        onSuccess: ({ entities }) => {
          const { authorizedByUser, jiraSyncProjectJob, jiraAccessibleResourcesView } =
            handleJiraProjectDetailsResponse({
              users: entities.users,
              jiraSyncProjectJobs: entities.syncProjectJobs,
              jiraAccessibleResourcesView: entities.jiraAccessibleResourcesView,
            });
          setAuthorizedUser(authorizedByUser);
          setSyncProjectJob(jiraSyncProjectJob);
          setJiraInstance(jiraAccessibleResourcesView);
        },
        onFinish: () => {
          setIsLoading(false);
        },
      }),
    );
  }, [dispatch, jiraProjectId, organizationId, workspaceId]);

  const projectName = item ? item.name : '';
  const authorizedByDisplayName = authorizedUser ? `${authorizedUser.firstName} ${authorizedUser.lastName}` : '';
  const isWsEvicted = workspaceUser?.disconnectedAt;
  const jiraInstanceName = jiraInstance ? jiraInstance.name : '';
  const getLastSyncDate = useMemo(() => {
    if (syncProjectJob?.finishedAt) {
      const date = new Date(syncProjectJob?.finishedAt);
      return moment(date).fromNow();
    }
    return '';
  }, [syncProjectJob?.finishedAt]);

  useEffect(() => {
    fetchJiraProjectDetails();
  }, [fetchJiraProjectDetails]);

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Table.Cell
        data-testid="project-integrated-cell"
        className="jira-configuration__table-cell"
        content={
          <div className="jira-configuration__table-cell-project-name">
            <ItemIcon className="lp-ancestry-list-row__item-info-icon" itemId={itemId} />
            <LpLink
              key={itemId}
              to={frontend.projectProject.url({
                itemId: itemId,
                organizationId,
                workspaceId,
              })}
              data-e2e-test-id="project-grid-item"
            >
              {projectName}
            </LpLink>
          </div>
        }
      />
      <Table.Cell content={<div>{getLastSyncDate}</div>} />
      <Table.Cell content={<div>{jiraInstanceName}</div>} />
      <Table.Cell
        content={
          <div
            className={classNames('jira-configuration__table-cell-authorized-by', {
              'jira-configuration__table-cell-authorized-by--evicted': isWsEvicted,
            })}
          >
            {authorizedByDisplayName}
          </div>
        }
      />
      <Table.Cell
        content={
          <div className="jira-configuration__table-cell-edit">
            <Button onClick={openEditModal} basic>
              <LpIcon hoverText="Modify Setup" color={slate700} icon={pencilSolid} />
            </Button>
          </div>
        }
      />
      {isEditModalOpen && packageStatus && (
        <JiraModal
          setShowJiraProjectModal={setIsEditModalOpen}
          itemId={itemId}
          integrationId={jiraProjectId}
          packageId={packageId}
          canModify={hasAccess}
        />
      )}
    </>
  );
};

export default JiraConnectedProjectsCells;
