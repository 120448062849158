import { SyncType } from 'daos/model_types';
import { defineModel, reduceIncludedOptions } from 'daos/shared';
import { ExternalIntegrationIncludes } from 'daos/types';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

interface ExternalIntegrationParams {
  organizationId: number;
  workspaceId: number;
}

interface ExternalIntegration extends ExternalIntegrationParams {
  externalIntegrationId: number;
}

interface ExternalIntegrationOptions {
  include?: ExternalIntegrationIncludes;
  filter?: string;
  validate?: boolean;
}

interface SyncExternalIntegrationRequest {
  syncType: SyncType;
}

const externalIntegrationIncludes = ({
  externalIntegrationFieldMappings,
  jiraIssueFilters,
  jiraAccessibleResourcesView,
  jiraSyncSettings,
  syncProjectJobs,
  items,
  oauthCredentialsUsers,
}: ExternalIntegrationIncludes = {}) =>
  reduceIncludedOptions([
    externalIntegrationFieldMappings && 'externalIntegrationFieldMappings',
    jiraIssueFilters && 'jiraIssueFilters',
    jiraAccessibleResourcesView && 'jiraAccessibleResourcesView',
    jiraSyncSettings && 'jiraSyncSettings',
    syncProjectJobs && 'syncProjectJobs',
    items && 'items',
    oauthCredentialsUsers && 'oauthCredentials.users',
  ]);

const { resource } = defineModel({
  apiType: 'externalIntegrations',
  type: 'EXTERNAL_INTEGRATION',
});

const { EXTERNAL_INTEGRATION } = resource;

const fetchAllExternalIntegrations = (
  params: ExternalIntegrationParams,
  { filter, include }: ExternalIntegrationOptions = {},
) =>
  request(
    backend.externalIntegrations.url(params, {
      filter,
      include: externalIntegrationIncludes(include),
    }),
    EXTERNAL_INTEGRATION,
    { method: HttpMethod.GET },
  );

const getExternalIntegration = (
  params: ExternalIntegration,
  { include, validate }: ExternalIntegrationOptions = {},
) => {
  return request(
    backend.externalIntegration.url(params, { include: externalIntegrationIncludes(include), query: { validate } }),
    EXTERNAL_INTEGRATION,
    { method: HttpMethod.GET },
  );
};

const syncExternalIntegration = (params: ExternalIntegration, body: SyncExternalIntegrationRequest) =>
  request(backend.externalIntegrationProjectSync.url(params), EXTERNAL_INTEGRATION, {
    body: JSON.stringify({ data: body }),
    method: HttpMethod.POST,
  });

const getExternalIntegrationSyncStatus = (params: ExternalIntegration) =>
  request(backend.externalIntegrationProjectSync.url(params), EXTERNAL_INTEGRATION, { method: HttpMethod.GET });

const getExternalIntegrationSyncErrors = (params: ExternalIntegration) =>
  request(backend.externalIntegrationProjectSyncErrors.url(params), EXTERNAL_INTEGRATION, { method: HttpMethod.GET });

const getExternalIntegrationConnectionInformation = (params: ExternalIntegration) =>
  request(backend.jiraExternalIntegrationConnectionInformation.url(params), EXTERNAL_INTEGRATION, {
    method: HttpMethod.GET,
  });

export const ExternalIntegrationDao = {
  fetchAllExternalIntegrations,
  initiateExternalIntegrationSync: syncExternalIntegration,
  getExternalIntegrationSyncStatus,
  getExternalIntegrationSyncErrors,
  getExternalIntegrationConnectionInformation,
  fetch: getExternalIntegration,
} as const;
