import { useState, useCallback, ReactNode, ForwardRefExoticComponent, RefAttributes } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react';
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic';

import ItemPickerWorkSpaceRoot from 'containers/shared/item_picker/workspace_root';
import LpLink, { LpLinkProps } from 'containers/shared/lp_link';
import { ItemType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import RemoveSoleFieldValueCell, {
  RemoveSoleFieldValueCellSize,
} from 'features/common/custom_fields/custom_field_value/remove_sole_field_value_cell';
import { LpIcon, pencilLight } from 'features/common/lp_icon';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import itemTypeDisplayName from 'lib/display_helpers/item_display_name';
import { frontend } from 'lib/urls';
import { getFieldValueForId } from 'state/entities/selectors/custom_field';
import { getItemForId } from 'state/entities/selectors/item';

import 'features/common/custom_fields/custom_field_value/custom_field_value.scss';

const CustomFieldItemButton = ({
  content,
  itemId,
  itemType,
  onClick,
  size = 'mini',
  disabled = false,
}: {
  content: string;
  itemId?: number;
  itemType?: ItemType;
  onClick?: () => void;
  size?: SemanticSIZES;
  disabled: boolean;
}) => {
  const history = useHistory();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const buttonProps: {
    as: ReactNode | undefined | ForwardRefExoticComponent<LpLinkProps & RefAttributes<HTMLElement & HTMLAnchorElement>>;
    className: string | undefined;
    content: string;
    onClick: (() => void) | undefined;
    size: SemanticSIZES | undefined;
    to: string | undefined;
  } = {
    as: undefined,
    className: 'lp-custom-field-value__button-group-button',
    content,
    onClick,
    size,
    to: undefined,
  };
  switch (itemType) {
    case ItemType.PACKAGES:
    case ItemType.PROJECTS:
    case ItemType.FOLDERS: {
      if (itemId) {
        buttonProps.as = LpLink;
        buttonProps.to =
          itemType === ItemType.PACKAGES
            ? frontend.singlePackage.url({
                organizationId,
                workspaceId,
                packageId: itemId,
              })
            : frontend.project.url({
                organizationId,
                workspaceId,
                itemId,
              });
        buttonProps.onClick = () =>
          history.push({
            search: history.location.search,
          });
      }
      break;
    }
    case ItemType.TASKS: {
      buttonProps.onClick = () => {
        if (itemId) {
          history.push(`#panelId=${itemId}&panelSection=${TabNames.Assignments}Tab`);
        }
      };
      break;
    }
  }
  return <Button {...buttonProps} disabled={disabled} />;
};

const CustomFieldItemValue = ({
  fieldValueId,
  allowMultipleValues,
  resourceId,
  fieldId,
  disabled = false,
}: CustomFieldValueProps & {
  fieldValueId?: number;
  allowMultipleValues: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { updateFieldValue, createFieldValue } = useCustomFieldsApiActions(resourceId.type);
  const fieldValue = useSelector((state) => (fieldValueId ? getFieldValueForId(state, fieldValueId) : undefined));
  const item = useSelector((state) =>
    fieldValue?.itemValue ? getItemForId(state, fieldValue.itemValue.id) : undefined,
  );

  const itemId = item?.id;
  const itemType = item?.itemType;
  const itemName = item?.name ?? (item ? itemTypeDisplayName(item) : 'Item');

  const handlePickerOpen = () => setOpen(true);
  const handlePickerClose = () => setOpen(false);

  const handleSubmit = useCallback(
    (itemId: number) => {
      const payload = { itemValue: ItemDao.id(itemId) };
      if (fieldValue) {
        updateFieldValue(resourceId.id, fieldValue.id, payload);
      } else {
        createFieldValue(resourceId.id, fieldId, payload);
      }
      handlePickerClose();
    },
    [createFieldValue, fieldId, fieldValue, resourceId, updateFieldValue],
  );

  return (
    <span className={allowMultipleValues ? 'lp-custom-field-value__multi' : 'lp-custom-field-value'}>
      <Button.Group className="lp-custom-field-value__button-group">
        <CustomFieldItemButton
          content={itemId ? itemName : allowMultipleValues ? 'Add' : 'None'}
          itemId={itemId}
          itemType={itemType}
          onClick={!itemId ? handlePickerOpen : undefined}
          disabled={disabled}
        />
        <Button
          className="lp-custom-field-value__button-group-button icon"
          size="mini"
          onClick={handlePickerOpen}
          disabled={disabled}
        >
          {<LpIcon icon={pencilLight} />}
        </Button>
      </Button.Group>
      {allowMultipleValues && fieldValue && fieldValue.text !== '' && (
        <RemoveSoleFieldValueCell size={RemoveSoleFieldValueCellSize.Button} valueIds={[fieldValue.id]} />
      )}

      {open && <ItemPickerWorkSpaceRoot onCancel={handlePickerClose} onSelect={handleSubmit} />}
    </span>
  );
};

export default CustomFieldItemValue;
