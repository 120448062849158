import { APP_KEYS } from '@tempo-io/sui-navigation';

export const tempoApps = [
  {
    id: APP_KEYS.TIMESHEETS,
    installed: false,
  },
  {
    id: APP_KEYS.CAPACITY_PLANNER,
    installed: false,
  },
  {
    id: APP_KEYS.STRUCTURE,
    installed: false,
  },
  {
    id: APP_KEYS.FINANCIAL_MANAGER,
    installed: false,
  },
  {
    id: APP_KEYS.CUSTOM_CHARTS,
    installed: false,
  },
  {
    id: APP_KEYS.STRATEGIC_ROADMAPS,
    installed: false,
  },
  {
    id: APP_KEYS.PORTFOLIO_MANAGER,
    installed: true,
  },
];
