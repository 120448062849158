import moment from 'moment-timezone';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { ItemDateProps } from 'containers/shared/custom_column/types';
import { ItemType } from 'daos/enums';
import { Item } from 'daos/model_types';
import { getDeltaDays } from 'lib/deltas';
import { getItemIsLateRisk, itemGlobalPrioritySort } from 'lib/helpers';
import { compareByPriority } from 'lib/helpers/comparison_helpers';
import { readonlyArray, ReadonlyRecord } from 'lib/readonly_record';
import { memoizeNumericIds } from 'state/entities/selectors/helpers';
import { getItemMetricsForId } from 'state/entities/selectors/item_metric';
import { RootState } from 'state/root_reducer';

import { createCacheByIdConfig, createCacheByIdsConfig, getNumberArgument, getSecondParameter, or } from './shared';
import { getCurrentWorkspace } from './workspace';

export interface DateRange {
  start: moment.Moment;
  finish: moment.Moment;
}

const itemTypeOrder: ReadonlyRecord<ItemType, number> = {
  [ItemType.ASSIGNMENTS]: 0,
  [ItemType.TASKS]: 1,
  [ItemType.FOLDERS]: 3,
  [ItemType.PROJECTS]: 4,
  [ItemType.PACKAGES]: 5,
  [ItemType.WORKSPACE_ROOTS]: 6,
};

export const isAssignment = (item: Item | undefined) => item?.itemType === ItemType.ASSIGNMENTS;
export const isTask = (item: Item | undefined) => item?.itemType === ItemType.TASKS;
export const isFolder = (item: Item | undefined) => item?.itemType === ItemType.FOLDERS;
export const isProject = (item: Item | undefined) => item?.itemType === ItemType.PROJECTS;
export const isPackage = (item: Item | undefined) => item?.itemType === ItemType.PACKAGES;
export const isWorkspaceRoot = (item: Item | undefined) => item?.itemType === ItemType.WORKSPACE_ROOTS;

export const isContainer = or(isPackage, isProject, isFolder);
export const isFolderOrProject = or(isFolder, isProject);
export const isProjectOrPackage = or(isProject, isPackage);

export const getItemsById = (state: RootState) => state.entities.items;
export const getItemForId = (state: RootState, id: number) => getItemsById(state)[id];

export const getItemTypeForId = createSelector(getItemForId, (item) => item?.itemType);
export const getItemNameForId = createSelector(getItemForId, (item) => item?.name);

export const getItemTargetStartDeltaDaysForItemId = createSelector(getItemForId, (item) =>
  getDeltaDays({ expected: item?.expectedStart, target: item?.targetStart }),
);

export const getItemTargetFinishDeltaDaysForItemId = createSelector(getItemForId, (item) =>
  getDeltaDays({ expected: item?.expectedFinish, target: item?.targetFinish }),
);

export const getItemsForIds = createCachedSelector(
  getItemsById,
  (_: RootState, itemIds: ReadonlyArray<number>) => memoizeNumericIds(itemIds),
  (itemsById, ids) =>
    readonlyArray(
      ids.reduce((items: Array<Item>, itemId) => {
        const item = itemsById[itemId];

        if (item) {
          items.push(item);
        }
        return items;
      }, []),
    ),
)(createCacheByIdsConfig());

export const getItemsForIdsSortedByGlobalPriority = createSelector(getItemsForIds, (items) => {
  return readonlyArray([...items].sort(itemGlobalPrioritySort));
});

export const getAncestryForItemId = createCachedSelector(
  getItemsById,
  getNumberArgument,
  getSecondParameter,
  (itemsById, itemId, upToItemType?: ItemType) => {
    let item = itemsById[itemId];
    const reverseAncestry: Array<Item> = [];

    while (item) {
      if (!upToItemType || itemTypeOrder[item.itemType] < itemTypeOrder[upToItemType]) {
        reverseAncestry.push(item);
      }

      const itemParentId = item.parent?.id;
      item = itemParentId ? itemsById[itemParentId] : undefined;
    }

    return readonlyArray(reverseAncestry.reverse());
  },
)(createCacheByIdConfig());

export const getAncestryItemForItemId = createCachedSelector(
  getItemsById,
  getNumberArgument,
  getSecondParameter,
  (itemsById, itemId, withItemType?: ItemType) => {
    let item = itemsById[itemId];

    while (item) {
      if (!withItemType || item.itemType == withItemType) {
        return item;
      }

      const itemParentId = item.parent?.id;
      item = itemParentId ? itemsById[itemParentId] : undefined;
    }

    return undefined;
  },
)(createCacheByIdConfig());

export const getChildItemsForItemId = createCachedSelector(getItemsById, getNumberArgument, (itemsById, itemId) => {
  return Object.values(itemsById).filter((item) => item.parent?.id === itemId);
})(createCacheByIdConfig());

export const getChildItemsForItemIds = createCachedSelector(
  getItemsById,
  (_: RootState, itemIds: ReadonlyArray<number>) => memoizeNumericIds(itemIds),
  (itemsById, ids) => {
    return Object.values(itemsById).filter((item) => ids.includes(item.parent?.id ?? 0));
  },
)(createCacheByIdsConfig());

export const getAncestryForBreadcrumb = createCachedSelector(
  (state: RootState) => state,
  getAncestryForItemId,
  getNumberArgument,
  (state, ancestryItems, itemId) => {
    const isStartingItemAnAssignment = getItemForId(state, itemId)?.itemType === ItemType.ASSIGNMENTS;

    return readonlyArray(
      ancestryItems
        .slice(0, -1)
        .filter((ancestryItem) => !(isStartingItemAnAssignment && ancestryItem.itemType === ItemType.TASKS)),
    );
  },
)(createCacheByIdConfig());

export const getAncestryForItemIdsIndexedById = createCachedSelector(
  (state: RootState) => state,
  (_: RootState, itemIds: Array<number>) => itemIds,
  getSecondParameter,
  (state, itemIds, upToItemType?: ItemType) => {
    return itemIds.reduce((acc: { [id: number]: ReadonlyArray<Item> }, id) => {
      const itemAncestry = getAncestryForItemId(state, id, upToItemType);
      acc[id] = itemAncestry;
      return acc;
    }, {});
  },
)(createCacheByIdConfig());

export const getAncestryItemForItemIdsIndexedById = createCachedSelector(
  (state: RootState) => state,
  (_: RootState, itemIds: Array<number>) => itemIds,
  getSecondParameter,
  (state, itemIds, withItemType?: ItemType) => {
    return itemIds.reduce((acc: { [id: number]: Item | undefined }, id) => {
      acc[id] = getAncestryItemForItemId(state, id, withItemType);
      return acc;
    }, {});
  },
)(createCacheByIdConfig());

export const getAncestryIdsForItemIdStartingAtProjectLevel = createCachedSelector(
  getItemsById,
  getNumberArgument,
  (itemsById, itemId) => {
    let item = itemsById[itemId];
    const reverseAncestry: Array<number> = [];
    while (item && item.itemType !== ItemType.WORKSPACE_ROOTS && item.itemType !== ItemType.PACKAGES) {
      reverseAncestry.push(item.id);

      const itemParentId = item.parent?.id;
      item = itemParentId ? itemsById[itemParentId] : undefined;
    }
    return readonlyArray(reverseAncestry.reverse());
  },
)(createCacheByIdConfig());

export const getAncestryForItemIdStartingAtProjectLevel = (state: RootState, id: number) =>
  readonlyArray(getAncestryForItemId(state, id).slice(2));

export const getAncestorProjectForItemId = createCachedSelector(
  getItemsById,
  getNumberArgument,
  (itemsById, itemId) => {
    let item = itemsById[itemId];
    let ancestor = undefined;

    while (item) {
      if (item.itemType === ItemType.PROJECTS) {
        ancestor = item;
      }
      const itemParentId = item.parent?.id;
      item = itemParentId ? itemsById[itemParentId] : undefined;
    }
    return ancestor;
  },
)(createCacheByIdConfig());

export const getAncestorProjectIdForItemId = createCachedSelector(getAncestryForItemId, (ancestry) => {
  const project = ancestry[2];

  return project && project.id;
})(createCacheByIdConfig());

export const getPackageForItemId = createCachedSelector(getAncestryForItemId, (ancestry) => {
  if (ancestry.length < 2) {
    return null;
  }
  return ancestry[1];
})(createCacheByIdConfig());

export const getPackageIdForItemId = createCachedSelector(
  getPackageForItemId,
  (pkg) => pkg?.id ?? 0,
)(createCacheByIdConfig());

export const getPackageStatusForItemId = createCachedSelector(
  getPackageForItemId,
  (pkg) => pkg?.packageStatus,
)(createCacheByIdConfig());

export const getItemIdsSortedByPriorityForAPIResponseItemData = (items: Array<Item>) =>
  [...items].sort(compareByPriority).map((item) => item.id);

export const getCurrentWorkspaceRootItem = createSelector(
  getCurrentWorkspace,
  (state: RootState) => state.entities.items,
  (currentWorkspace, itemsById) => {
    return itemsById[currentWorkspace?.workspaceRoot.id ?? 0];
  },
);

export const getItemFileCountForItemId = createCachedSelector(
  getItemForId,
  (item) => item?.fileCount ?? 0,
)(createCacheByIdConfig());

export const getHasItemFiles = createCachedSelector(getItemForId, (item) => {
  return (item?.fileCount ?? 0) === 0;
})(createCacheByIdConfig());

export const getItemDatePropsForId = createCachedSelector(getItemForId, getItemMetricsForId, (item, itemMetric) => {
  const {
    createdAt,
    doneDate,
    expectedFinish,
    expectedStart,
    late,
    latestFinish,
    targetFinish,
    targetStart,
    updatedAt,
  } = item ?? {};

  const { effectiveTargetFinish, effectiveTargetStart } = itemMetric ?? {};

  const isLateRisk = getItemIsLateRisk({ effectiveTargetFinish, latestFinish, isLate: !!late });

  const isAtRisk = !!late || isLateRisk;

  const itemDates: ItemDateProps = {
    createdAt,
    doneDate: doneDate ?? undefined,
    expectedFinish: { finish: expectedFinish ?? '', isAtRisk: isAtRisk },
    expectedStart: expectedStart ?? undefined,
    finishRange: { expected: expectedFinish ?? '', latest: latestFinish ?? '', isAtRisk: isAtRisk },
    latestFinish: { finish: latestFinish ?? '', isAtRisk: isAtRisk },
    targetFinish: {
      finish: targetFinish ?? effectiveTargetFinish ?? '',
      inherited: !targetFinish && !!effectiveTargetFinish,
      isAtRisk: isAtRisk,
    },
    targetStart: {
      start: targetStart ?? effectiveTargetStart ?? '',
      inherited: !targetStart && !!effectiveTargetStart,
    },
    updatedAt,
  };
  return itemDates;
})(createCacheByIdConfig());
