import classNames from 'classnames';
import { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';

import 'features/jira_project/modal/jira_project_modal.scss';

export const JiraProjectModalTablePicklistCell = ({
  picklist,
  checkbox,
  checkboxText,
  secondaryElement,
  renderSecondaryElementInline = false,
}: {
  picklist: ReactNode;
  checkbox?: ReactNode;
  secondaryElement?: ReactNode;
  checkboxText?: string;
  renderSecondaryElementInline?: boolean;
}) => (
  <Table.Cell className="field-value picklist">
    <div
      className={classNames(
        'jira-project-modal__content-table-picklist',
        secondaryElement && !renderSecondaryElementInline && 'jira-project-modal__content-table-picklist-column',
      )}
    >
      <span className="custom-field-input">{picklist}</span>
      <div
        className={classNames(
          'jira-project-modal__content-table-picklist-secondary-container',
          !renderSecondaryElementInline && 'jira-project-modal__content-table-picklist-secondary-container-column',
        )}
      >
        <div className="jira-project-modal__content-table-picklist-checkbox">
          {checkbox}
          {checkboxText}
        </div>
        {secondaryElement}
      </div>
    </div>
  </Table.Cell>
);
