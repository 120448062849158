import { useSelector } from 'react-redux';

import { customColumnDefinitions } from 'containers/shared/custom_column';
import { SchedulingType, StatusFilterGroups, UserType } from 'daos/enums';
import { WidgetConfigColumn } from 'daos/widget_column';
import { useBulkSelectUnselectAll } from 'features/common/bulk_selection/hooks';
import { SelectionDropdownOptions } from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { ItemRow, PeopleRowUserData } from 'features/dashboards_v2/widget/widgets/reports/types';
import { TEXT_ALIGN_LEFT } from 'lib/constants';
import { getCurrentWorkspaceUser, getIsGuestCurrentWorkspaceUser } from 'state/entities/selectors/user';

const reduceRowsForUserType = (rowUserData: ReadonlyArray<PeopleRowUserData>, userType: UserType) => {
  return rowUserData.reduce((rowUserIds: Array<number>, user) => {
    if (user.userType === userType) {
      rowUserIds.push(user.userId);
    }
    return rowUserIds;
  }, []);
};

export const getBulkSelectedPeopleRows = (
  selection: SelectionDropdownOptions,
  rowUserData: ReadonlyArray<PeopleRowUserData>,
): Set<number> | undefined => {
  switch (selection) {
    case SelectionDropdownOptions.UnselectAll:
      return new Set();

    case SelectionDropdownOptions.SelectMembers: {
      const memberRowIds = reduceRowsForUserType(rowUserData, UserType.Member);

      return new Set(memberRowIds);
    }

    case SelectionDropdownOptions.SelectPlaceholders: {
      const placeholderRowIds = reduceRowsForUserType(rowUserData, UserType.Placeholder);

      return new Set(placeholderRowIds);
    }

    case SelectionDropdownOptions.SelectResources: {
      const resourceRowIds = reduceRowsForUserType(rowUserData, UserType.Resource);

      return new Set(resourceRowIds);
    }
  }
};

export const getCellClass = (configColumn: WidgetConfigColumn) => {
  return configColumn.customFieldId
    ? customColumnDefinitions[configColumn.column]?.customFieldCellClass?.(configColumn.customFieldType)
    : (customColumnDefinitions[configColumn.column]?.systemCellClass ?? TEXT_ALIGN_LEFT);
};

export const getColumnKey = (configColumn: WidgetConfigColumn) => {
  return configColumn.customFieldId ? String(configColumn.customFieldId) : configColumn.column;
};

export const getPeopleRowCheckboxDisabled = ({
  rowUserType,
  selectedUserType,
  hasAccess,
}: {
  rowUserType: UserType;
  selectedUserType: UserType | undefined;
  hasAccess: boolean;
}) => {
  if (!hasAccess) {
    return true;
  }
  return !!selectedUserType && rowUserType !== selectedUserType;
};

export const usePeopleGridSelectColumnProps = ({
  onSelectedRowsChange,
  rowUserData,
  selectedUserType,
}: {
  onSelectedRowsChange?: (selectedIds: Set<string | number>) => void;
  rowUserData: ReadonlyArray<PeopleRowUserData>;
  selectedUserType?: UserType;
}) => {
  const currentWorkspaceUser = useSelector(getCurrentWorkspaceUser);
  const isWorkspaceManager = !!currentWorkspaceUser?.manager;
  const isGuest = !!currentWorkspaceUser?.guestUser;

  const onBulkSelect = (selection: SelectionDropdownOptions) => {
    if (onSelectedRowsChange) {
      const selectedRows = getBulkSelectedPeopleRows(selection, rowUserData);
      selectedRows && onSelectedRowsChange(selectedRows);
    }
  };

  return {
    hasAccess: isWorkspaceManager,
    onSelect: onBulkSelect,
    selectedUserType,
    showSelectColumn: !isGuest,
  };
};

export const useItemGridSelectColumnProps = ({ rowData }: { rowData: ReadonlyArray<ItemRow> }) => {
  const currentWsUserIsGuest = useSelector(getIsGuestCurrentWorkspaceUser);
  const handleBulkSelect = useBulkSelectUnselectAll();
  const allRowIds = rowData.map((row) => row.itemId);

  if (currentWsUserIsGuest) {
    return;
  }

  const onBulkSelect = handleBulkSelect(allRowIds);

  return { onBulkSelect, rowData };
};

export const getItemRowSchedulingTypeMatchesStatusGroup = (
  schedulingType: SchedulingType | null,
  filter: StatusFilterGroups,
) => {
  switch (filter) {
    case StatusFilterGroups.All:
      return true;
    case StatusFilterGroups.ActiveAndOnHold:
      return schedulingType === SchedulingType.Scheduled || schedulingType === SchedulingType.Unscheduled;
    case StatusFilterGroups.Done:
      return schedulingType === SchedulingType.Done;
    case StatusFilterGroups.OnHold:
      return schedulingType === SchedulingType.Unscheduled;
    case StatusFilterGroups.Active:
      return schedulingType === SchedulingType.Scheduled;
    case StatusFilterGroups.Custom:
    case StatusFilterGroups.atRisk:
    case StatusFilterGroups.Asap:
    case StatusFilterGroups.None:
    default:
      return false;
  }
};
