import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

import { Item } from 'daos/model_types';
import { ReadonlyRecord } from 'lib/readonly_record';
import { resetStateExtraReducer } from 'state/root_actions';

type BoolLookup = ReadonlyRecord<string, boolean>;
type ItemLookup = ReadonlyRecord<number, Item>;

interface ItemPickerState {
  readonly items: ItemLookup;
  readonly selected: BoolLookup;
}

export const initialState: ItemPickerState = {
  items: {},
  selected: {},
};

const itemPickerSlice = createSlice({
  name: 'itemPicker',
  initialState,
  reducers: {
    clearItemPicker: () => initialState,
    selectItemPickerRow: (state, action: PayloadAction<number>) => {
      state.selected[action.payload] = true;
    },
    unselectItemPickerRow: (state, action: PayloadAction<number>) => {
      delete state.selected[action.payload];
    },
    unselectAllItemPickerRows: (state) => {
      state.selected = {};
    },
    updateItemPickerStore: (state, action: PayloadAction<ItemLookup | undefined>) => {
      const items = action.payload ?? {};
      state.items = castDraft(items);
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const {
  clearItemPicker,
  selectItemPickerRow,
  unselectAllItemPickerRows,
  unselectItemPickerRow,
  updateItemPickerStore,
} = itemPickerSlice.actions;

export default itemPickerSlice.reducer;
