import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

import { OrganizationUser } from 'daos/model_types';
import { ApiError } from 'lib/api/types';
import { resetStateExtraReducer } from 'state/root_actions';

interface OrgUsers {
  filteredOrgUsers: ReadonlyArray<OrganizationUser>;
  fetchComplete: boolean;
}

interface OrganizationHubState {
  readonly orgUsers: OrgUsers;
  readonly hasError: boolean;
  readonly userUpdateError?: ApiError;
}

export const initialState: OrganizationHubState = {
  orgUsers: { filteredOrgUsers: [], fetchComplete: false },
  hasError: false,
};

const organizationHubSlice = createSlice({
  name: 'organizationHub',
  initialState,
  reducers: {
    setFilteredOrgUsers: (state, action: PayloadAction<ReadonlyArray<OrganizationUser>>) => {
      state.orgUsers.filteredOrgUsers = castDraft(action.payload);
    },
    setOrganizationHubHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },
    setOrganizationHubUsersFetchComplete: (state, action: PayloadAction<boolean>) => {
      state.orgUsers.fetchComplete = action.payload;
    },
    setOrgHubUserUpdateError: (state, action: PayloadAction<ApiError | undefined>) => {
      state.userUpdateError = castDraft(action.payload);
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const {
  setFilteredOrgUsers,
  setOrgHubUserUpdateError,
  setOrganizationHubHasError,
  setOrganizationHubUsersFetchComplete,
} = organizationHubSlice.actions;

export default organizationHubSlice.reducer;
