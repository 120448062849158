export const NovaEventSvg = () => {
  return (
    <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="event">
        <path
          id="Vector"
          d="M12.6049 16.9887C12.0183 16.9887 11.5208 16.777 11.1125 16.3535C10.7042 15.9301 10.5 15.4117 10.5 14.7984C10.5 14.1851 10.7025 13.665 11.1076 13.2381C11.5126 12.8113 12.0085 12.5978 12.5951 12.5978C13.1817 12.5978 13.6792 12.8095 14.0875 13.233C14.4958 13.6565 14.7 14.1748 14.7 14.7881C14.7 15.4014 14.4975 15.9215 14.0924 16.3484C13.6874 16.7753 13.1916 16.9887 12.6049 16.9887ZM4.725 20.2819C4.29188 20.2819 3.9211 20.118 3.61266 19.7902C3.30422 19.4624 3.15 19.0774 3.15 18.6353V6.56031C3.15 6.11817 3.30422 5.7332 3.61266 5.40541C3.9211 5.07761 4.29188 4.91372 4.725 4.91372H6.3V2.71826H7.875V4.91372H13.125V2.71826H14.7V4.91372H16.275C16.7081 4.91372 17.0789 5.07761 17.3874 5.40541C17.6958 5.7332 17.85 6.11817 17.85 6.56031V18.6353C17.85 19.0774 17.6958 19.4624 17.3874 19.7902C17.0789 20.118 16.7081 20.2819 16.275 20.2819H4.725ZM4.725 18.6353H16.275V10.4024H4.725V18.6353ZM4.725 8.75576H16.275V6.56031H4.725V8.75576Z"
          fill="#1D1D1B"
        />
      </g>
    </svg>
  );
};
