import { useDispatch } from 'react-redux';

import { ItemType, StatusFilterGroups } from 'daos/enums';
import { CustomFieldFilterWithRequiredId } from 'daos/types';
import {
  setCustomItemFilterAsap,
  setCustomItemFilterAtRisk,
  setCustomItemFilterCreatedByUserId,
  setCustomItemFilterCustomFieldProperties,
  setCustomItemFilterCustomTaskStatusFilter,
  setCustomItemFilterDropdownTaskStatusFilter,
  setCustomItemFilterHasFiles,
  setCustomItemFilterNameContains,
  setCustomItemFilterStatusSelection,
  setCustomItemFilterTaskStatusIds,
  setCustomItemFilterWorkLimitRisk,
} from 'features/common/custom_item_filter/slice';
import { CustomItemFilterLocation } from 'features/common/custom_item_filter/types';

export const useApplyTaskFilter = (location: CustomItemFilterLocation) => {
  const dispatch = useDispatch();
  const itemType = ItemType.TASKS;

  return ({
    createdByUserId,
    nameContainsValue,
    otherCustomFieldProperties,
    selectedAsap,
    selectedHasFiles,
    selectedWorkLimitRisk,
    selectedAtRisk,
    selectedTaskStatusFilter,
    selectedTaskStatusIds,
    statusFilterSelection,
  }: {
    createdByUserId: number | undefined;
    nameContainsValue: string;
    otherCustomFieldProperties: ReadonlyArray<CustomFieldFilterWithRequiredId>;
    selectedAsap: boolean;
    selectedHasFiles: boolean;
    selectedWorkLimitRisk: boolean;
    selectedAtRisk: boolean;
    selectedTaskStatusFilter: StatusFilterGroups | undefined;
    selectedTaskStatusIds: ReadonlyArray<number>;
    statusFilterSelection: StatusFilterGroups;
  }) => {
    const getDropdownTaskStatusFilter = () => {
      const isCustomAsap = selectedAsap && selectedTaskStatusFilter !== StatusFilterGroups.All;
      const isOnlyAsap =
        selectedAsap &&
        !selectedAtRisk &&
        !selectedWorkLimitRisk &&
        selectedTaskStatusFilter === StatusFilterGroups.All;

      const isCustomAtRisk =
        (selectedAtRisk || selectedWorkLimitRisk) && selectedTaskStatusFilter !== StatusFilterGroups.All;
      const isScheduleRisk =
        selectedAtRisk && selectedWorkLimitRisk && !selectedAsap && selectedTaskStatusFilter === StatusFilterGroups.All;

      const isAsapAndAtRisk = selectedAsap && selectedAtRisk;
      const hasNoCustomTaskStatusIdsNameCreatedByOrCustomFields =
        !selectedTaskStatusIds.length && !nameContainsValue && !createdByUserId && !otherCustomFieldProperties.length;
      const hasNoCustomFiltering =
        !isAsapAndAtRisk && !isCustomAsap && !isCustomAtRisk && hasNoCustomTaskStatusIdsNameCreatedByOrCustomFields;

      if (isOnlyAsap && hasNoCustomTaskStatusIdsNameCreatedByOrCustomFields) {
        return StatusFilterGroups.Asap;
      }

      if (isScheduleRisk && hasNoCustomTaskStatusIdsNameCreatedByOrCustomFields) {
        return StatusFilterGroups.atRisk;
      }

      if (!hasNoCustomFiltering) {
        return StatusFilterGroups.Custom;
      }

      return selectedTaskStatusFilter ?? StatusFilterGroups.All;
    };

    const getCustomTaskStatusFilter = () => {
      if (
        (selectedTaskStatusFilter === StatusFilterGroups.Custom ||
          selectedTaskStatusFilter === StatusFilterGroups.atRisk ||
          selectedTaskStatusFilter === StatusFilterGroups.Asap) &&
        !selectedTaskStatusIds.length
      ) {
        return StatusFilterGroups.All;
      }

      if (selectedTaskStatusIds.length) {
        return StatusFilterGroups.Custom;
      }

      return selectedTaskStatusFilter ?? StatusFilterGroups.All;
    };

    if (selectedTaskStatusFilter) {
      const dropdownTaskStatusFilter = getDropdownTaskStatusFilter();
      const customTaskStatusFilter = getCustomTaskStatusFilter();
      dispatch(setCustomItemFilterDropdownTaskStatusFilter({ location, dropdownTaskStatusFilter }));
      dispatch(setCustomItemFilterCustomTaskStatusFilter({ location, customTaskStatusFilter }));
      dispatch(setCustomItemFilterTaskStatusIds({ location, taskStatusIds: selectedTaskStatusIds }));
    }
    dispatch(setCustomItemFilterStatusSelection({ location, itemType, statusFilterSelection }));
    dispatch(setCustomItemFilterAsap({ location, asap: selectedAsap }));
    dispatch(setCustomItemFilterHasFiles({ location, itemType, hasFiles: selectedHasFiles }));
    dispatch(setCustomItemFilterAtRisk({ location, itemType, atRisk: selectedAtRisk }));
    dispatch(setCustomItemFilterWorkLimitRisk({ location, itemType, workLimitRisk: selectedWorkLimitRisk }));
    dispatch(setCustomItemFilterNameContains({ location, itemType, nameContains: nameContainsValue || undefined }));
    dispatch(setCustomItemFilterCreatedByUserId({ location, itemType, createdByUserId }));
    dispatch(
      setCustomItemFilterCustomFieldProperties({
        location,
        itemType,
        customFieldProperties: otherCustomFieldProperties.length ? otherCustomFieldProperties : undefined,
      }),
    );
  };
};
