import { isNumber } from 'lodash';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps, Input, InputOnChangeData, Ref } from 'semantic-ui-react';

import ItemPickerWorkSpaceRoot from 'containers/shared/item_picker/workspace_root';
import { CustomFieldDao } from 'daos/custom_field';
import { CustomFieldType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { Item, CustomField, CustomFieldValue, PicklistChoice, ResourceId, WorkspaceUser } from 'daos/model_types';
import { PicklistChoiceDao } from 'daos/picklist_choice';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { useCloseEditorOnScrollListener } from 'features/common/data_grid/add_edit_grid/cell_editors/use_scroll_listener';
import { EditorAddEditGridProps } from 'features/common/data_grid/types';
import PicklistDropdown from 'features/common/inputs/dropdowns/picklist_dropdown';
import UserDropdown, { UserDropdownPlaceHolderText } from 'features/common/inputs/dropdowns/user_dropdown';
import { LpDatePicker } from 'features/common/inputs/lp_date_picker';
import { blurElementOnEnterKey } from 'lib/helpers/blur_element_on_key_press';
import { KeypressKeys } from 'lib/helpers/keypress_keys';
import { getPicklistChoicesSortedByPriorityForFieldId } from 'state/entities/selectors/custom_field';
import {
  getCurrentWsUserToOrgUserMapping,
  getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername,
  getCurrentOrgUserToWsUserMapping,
} from 'state/entities/selectors/user';

const getCustomFieldCellValue = (
  field: CustomField,
  fieldValue: Partial<CustomFieldValue> | undefined,
  newValue: {
    [fieldValueType: string]:
      | string
      | boolean
      | number
      | ResourceId<WorkspaceUser>
      | ResourceId<PicklistChoice>
      | ResourceId<Item>
      | null;
  },
): Partial<CustomFieldValue> => {
  return {
    type: 'fieldValues',
    id: fieldValue?.id,
    fieldType: field.fieldType,
    field: CustomFieldDao.id(field.id),
    ...newValue,
  };
};

const CustomFieldInputElement = ({
  type,
  onChange,
  onBlur,
  value,
}: {
  type: string;
  onChange: (_: SyntheticEvent, { value }: InputOnChangeData) => void;
  onBlur: (_: SyntheticEvent) => void;
  value: string | number;
}) => {
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Input
      ref={inputRef}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      onKeyDown={blurElementOnEnterKey}
    />
  );
};

const TextEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];
  const initialText = fieldValue?.text ?? '';
  const [content, setContent] = useState(initialText);

  const handleBlur = (_: SyntheticEvent) => {
    const text = content.trim() || null;
    const updatedValue = getCustomFieldCellValue(field, fieldValue, { text });
    props.onRowChange({ ...props.row, [field.id]: [updatedValue] }, true);
  };

  const handleChange = (_: SyntheticEvent, { value }: { value: string }) => {
    setContent(value);
  };

  return CustomFieldInputElement({ type: 'text', onChange: handleChange, onBlur: handleBlur, value: content });
};

const LinkEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];
  const initialUrl = fieldValue?.url ?? '';
  const [content, setContent] = useState(initialUrl);

  const handleBlur = (_: SyntheticEvent) => {
    const url = content.trim() || null;
    const updatedValue = getCustomFieldCellValue(field, fieldValue, { url });
    props.onRowChange({ ...props.row, [fieldId]: [updatedValue] }, true);
  };

  const handleChange = (_: SyntheticEvent, { value }: { value: string }) => {
    setContent(value);
  };

  return CustomFieldInputElement({ type: 'text', onChange: handleChange, onBlur: handleBlur, value: content });
};

const CurrencyEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];
  const initialCurrency = fieldValue?.currency ?? '';
  const [content, setContent] = useState(initialCurrency);

  const handleBlur = (_: SyntheticEvent) => {
    const currency = content ? Number(content) : null;
    const updatedValue = getCustomFieldCellValue(field, fieldValue, { currency });
    props.onRowChange({ ...props.row, [fieldId]: [updatedValue] }, true);
  };

  const handleChange = (_: SyntheticEvent, { value }: { value: string }) => {
    setContent(value);
  };

  return CustomFieldInputElement({ type: 'number', onChange: handleChange, onBlur: handleBlur, value: content });
};

const NumericEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];
  const initialNumber = fieldValue?.number ?? '';
  const [content, setContent] = useState(initialNumber);

  const handleBlur = (_: SyntheticEvent) => {
    const number = content ? Number(content) : null;
    const updatedValue = getCustomFieldCellValue(field, fieldValue, { number });
    props.onRowChange({ ...props.row, [fieldId]: [updatedValue] }, true);
  };

  const handleChange = (_: SyntheticEvent, { value }: { value: string }) => {
    setContent(value);
  };

  return CustomFieldInputElement({ type: 'number', onChange: handleChange, onBlur: handleBlur, value: content });
};

const checkboxEnterEditModeTimeoutMilliseconds = 32;
const CheckboxEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  const checkboxRef = useRef<HTMLDivElement>(null);
  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];
  const checked = !!fieldValue?.checked;

  function setCheckedValue(checked: boolean, commit = false) {
    const updatedValue = getCustomFieldCellValue(field, fieldValue, { checked });
    props.onRowChange({ ...props.row, [fieldId]: [updatedValue] }, commit);
  }

  const handleChange = (_: SyntheticEvent, { checked }: CheckboxProps) => {
    if (checked !== undefined) {
      setCheckedValue(checked);
    }
  };

  useEffect(() => {
    const input = checkboxRef.current?.querySelector('input');

    if (!input) {
      return;
    }

    const keyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case KeypressKeys.Escape:
          props.onClose();
          break;
        case KeypressKeys.Enter:
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          setCheckedValue(!input.checked);
          break;
        case KeypressKeys.Tab: {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          setCheckedValue(input.checked, true);
          break;
        }
      }
    };

    input.addEventListener('keydown', keyDown);

    setTimeout(() => {
      input.focus();
      setCheckedValue(!input.checked);
    }, checkboxEnterEditModeTimeoutMilliseconds);

    return () => {
      input.removeEventListener('keydown', keyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Ref innerRef={checkboxRef}>
      <Checkbox className="edit-custom-field__checkbox" toggle onChange={handleChange} checked={checked} />
    </Ref>
  );
};

const PicklistEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  useCloseEditorOnScrollListener(props.onClose);

  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];
  const picklistChoiceId = fieldValue?.picklistChoice?.id;
  const picklistOptions = useSelector((state) => getPicklistChoicesSortedByPriorityForFieldId(state, fieldId));

  const handleChange = (_: SyntheticEvent, { value }: { value: number }) => {
    if (isNumber(value) || value === undefined) {
      const picklistChoice = value ? PicklistChoiceDao.id(value) : null;
      const updatedValue = getCustomFieldCellValue(field, fieldValue, { picklistChoice });
      props.onRowChange({ ...props.row, [fieldId]: [updatedValue] }, true);
    }
  };

  return (
    <PicklistDropdown
      fieldChoiceId={picklistChoiceId}
      picklist={picklistOptions}
      onChange={handleChange}
      isOpen={true}
      usePortal
      onClose={() => props.onClose()}
    />
  );
};

const DateEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  useCloseEditorOnScrollListener(props.onClose);

  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];
  const date = fieldValue?.date ?? '';

  useEffect(() => {
    const dayPickerInputElement = document.querySelector('.DayPickerInput input') as HTMLInputElement;
    if (dayPickerInputElement) {
      dayPickerInputElement.click();
    }
  }, []);

  const handleChange = (updatedDate: string) => {
    const updatedValue = getCustomFieldCellValue(field, fieldValue, { date: updatedDate || null });
    props.onRowChange({ ...props.row, [fieldId]: [updatedValue] }, true);
  };

  return <LpDatePicker open allowEmpty onChange={handleChange} onClose={props.onClose} value={date} />;
};

const ItemEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];

  // TODO: once we have ItemPick worked out. set this back to True.
  // Edit Custom Fields: edit CustomField Item
  // https://relight-internal.liquidplannerlab.com/organization/19/workspace/19/item/227397
  const [open, setOpen] = useState(false);

  const closeModal = () => setOpen(false);

  const handleSelect = (itemId: number) => {
    const updatedValue = getCustomFieldCellValue(field, fieldValue, { itemValue: ItemDao.id(itemId) });
    props.onRowChange({ ...props.row, [fieldId]: [updatedValue] }, true);
  };

  return <>{open && <ItemPickerWorkSpaceRoot onCancel={closeModal} onSelect={handleSelect} />}</>;
};

const UserEditor = (props: EditorAddEditGridProps, field: CustomField) => {
  useCloseEditorOnScrollListener(props.onClose);

  const orgUsers = useSelector(getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername);
  const orgUserIdByWsUserId = useSelector(getCurrentWsUserToOrgUserMapping);
  const wsUserIdByOrgUserId = useSelector(getCurrentOrgUserToWsUserMapping);

  const row = props.row;
  const fieldId = field.id;
  const fieldValue = row[fieldId]?.[0];
  const selectedWorkspaceUserId = fieldValue?.workspaceUserValue?.id;

  const selectedOrgUserId = selectedWorkspaceUserId && orgUserIdByWsUserId[selectedWorkspaceUserId];

  const handleChange = (_: SyntheticEvent, { value }: { value: number }) => {
    const updatedWsUserId = wsUserIdByOrgUserId[value];
    const workspaceUserValue = updatedWsUserId ? WorkspaceUserDao.id(updatedWsUserId) : null;
    const updatedValue = getCustomFieldCellValue(field, fieldValue, { workspaceUserValue });
    props.onRowChange({ ...props.row, [fieldId]: [updatedValue] }, true);
  };

  return (
    <UserDropdown
      clearable={true}
      onChange={handleChange}
      selectedOrgUserId={selectedOrgUserId}
      placeholder={UserDropdownPlaceHolderText.SelectUser}
      orgUsers={orgUsers}
      selectOnBlur={true}
      isOpen={true}
      usePortal
      onClose={() => props.onClose()}
    />
  );
};

export const CustomFieldEditor = (field: CustomField, props: EditorAddEditGridProps) => {
  switch (field.fieldType) {
    case CustomFieldType.MULTILINE_TEXT:
    case CustomFieldType.TEXT:
      return TextEditor(props, field);
    case CustomFieldType.CURRENCY:
      return CurrencyEditor(props, field);
    case CustomFieldType.PICKLIST:
      return PicklistEditor(props, field);
    case CustomFieldType.NUMERIC:
      return NumericEditor(props, field);
    case CustomFieldType.LINK:
      return LinkEditor(props, field);
    case CustomFieldType.CHECKBOX:
      return CheckboxEditor(props, field);
    case CustomFieldType.DATE:
      return DateEditor(props, field);
    case CustomFieldType.ITEM:
      return ItemEditor(props, field);
    case CustomFieldType.USER:
      return UserEditor(props, field);
    default:
      return null;
  }
};
