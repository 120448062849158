import { useSelector } from 'react-redux';

import { ListWidgetType, WidgetType, widgetTypeDisplay } from 'daos/enums';
import { Item, Widget } from 'daos/model_types';
import { getLocationNameFromScope, getTitleOrInheritedTitle } from 'features/dashboards_v2/common/titles';
import { getInheritedLocationScopeForWidgetId } from 'features/dashboards_v2/selectors';
import { getItemForId } from 'state/entities/selectors/item';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

import { IntakeTypeDisplay } from './widgets/intake/helpers';
import { LinkedNoteWidgetData } from './widgets/linked_note/types';

export function useWidgetTitlePlaceholder(widget?: Widget | null) {
  return useDefaultWidgetTitle(widget) ?? 'Widget Title';
}

export const defaultNamesForListTypes = {
  [ListWidgetType.Folders]: 'Subfolder List',
  [ListWidgetType.Packages]: 'Package List',
  [ListWidgetType.Picklists]: 'Picklist List',
  [ListWidgetType.Projects]: 'Project List',
  [ListWidgetType.Tasks]: 'Task List',
  [ListWidgetType.Assignment]: 'Assignment List',
  [ListWidgetType.Users]: 'People List',
};

function getDefaultWidgetTitleForWidgetType(widget: Widget, locationFilterItemName: string) {
  let defaultWidgetTitle = widgetTypeDisplay[widget.widgetType];

  switch (widget.widgetType) {
    case WidgetType.Intake: {
      if (widget.config.intakeType) {
        defaultWidgetTitle = `New ${IntakeTypeDisplay[widget.config.intakeType]}`;
      }

      break;
    }

    case WidgetType.LinkedNote: {
      const { fieldName } = (widget.data as LinkedNoteWidgetData | null) ?? {};

      if (fieldName) {
        defaultWidgetTitle = fieldName;
      }
      break;
    }

    case WidgetType.BoardSummary:
    case WidgetType.ScheduleSummary:
    case WidgetType.PropertiesSummary: {
      if (locationFilterItemName) {
        defaultWidgetTitle = locationFilterItemName;
      }
    }
  }

  return defaultWidgetTitle;
}

function widgetTypeSupportsLocationSetting(widgetType: WidgetType) {
  if (widgetType === WidgetType.Image || widgetType === WidgetType.DashboardNote || widgetType === WidgetType.Intake) {
    return false;
  } else {
    return true;
  }
}

function getDefaultWidgetTitle(
  widget: Widget,
  locationFilterItem: Item | undefined,
  inheritedLocationName: string,
  locationName: string,
) {
  if (!widgetTypeDisplay[widget.widgetType]) {
    return null;
  }
  const defaultTitle = getDefaultWidgetTitleForWidgetType(widget, locationFilterItem?.name ?? '');

  if (!widgetTypeSupportsLocationSetting(widget.widgetType)) {
    return defaultTitle;
  }

  const title = getTitleOrInheritedTitle({
    inheritedLocationName,
    locationName: locationName,
    defaultTitle: defaultTitle,
  });

  return title;
}

export function useDefaultWidgetTitle(widget?: Widget | null) {
  const locationFilterItemId = widget?.config.locationFilterItemId;
  const locationFilterItem = useSelector((state) =>
    locationFilterItemId ? getItemForId(state, Number(locationFilterItemId)) : undefined,
  );
  const inheritedLocationScope = useSelector((state) =>
    getInheritedLocationScopeForWidgetId(state, widget?.id?.toString() ?? ''),
  );
  const currentWorkspace = useSelector(getCurrentWorkspace);

  if (!widget) {
    return null;
  }

  const inheritedLocationName = getLocationNameFromScope({
    locationItemFilterName: inheritedLocationScope?.locationFilterItem?.name ?? '',
    locationItemFilterPackageStatus: inheritedLocationScope?.locationFilterPackageStatus ?? null,
    locationItemFilterType: inheritedLocationScope?.locationFilterItem?.itemType ?? null,
    currentWorkspaceName: currentWorkspace?.name ?? '',
  });

  const locationName = getLocationNameFromScope({
    locationItemFilterName: locationFilterItem?.name ?? '',
    locationItemFilterPackageStatus: widget?.config?.locationFilterPackageStatus ?? null,
    locationItemFilterType: locationFilterItem?.itemType ?? null,
    currentWorkspaceName: currentWorkspace?.name ?? '',
  });

  return getDefaultWidgetTitle(widget, locationFilterItem, inheritedLocationName, locationName);
}
