import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { Model, ResourceId, User } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { updateBody, createBody, resource } = defineModel({
  apiType: 'organizationSsoSettings',
  relationships: [],
  type: 'ORGANIZATION_SSO_SETTINGS',
});

const { resource: nexusSamlConfigStatusResource } = defineModel({
  apiType: 'nexusSamlConfigStatus',
  type: 'NEXUS_SAML_CONFIG_STATUS',
});

const { resource: nexusSamlSetupLinkResource } = defineModel({
  apiType: 'nexusSamlSetupLink',
  type: 'NEXUS_SAML_SETUP_LINK',
});

export interface NexusSamlConfigStatus extends Model<'nexusSamlConfigStatus'> {
  readonly canSetupSaml: boolean;
  readonly isSamlConfigured: boolean;
}

export interface NexusSamlSetupLink extends Model<'nexusSamlSetupLink'> {
  readonly samlSetupLink?: string;
}

export interface OrganizationSsoSettings extends Model<'organizationSSOSettings'> {
  readonly createdAt: string;
  readonly createdBy: ResourceId<User>;
  readonly enableSsoAllMembers: boolean;
  readonly enableSsoNewMembersDefault: boolean;
  readonly certContent: string;
  readonly entityId: string;
  readonly hasMultiOrgUsers: boolean;
  readonly identityProviderUrl: string;
  readonly metadataUrl: string;
  readonly multiOrgUsersIds: ReadonlyArray<number>;
  readonly consumerUrl: string;
  readonly organizationId: string;
  readonly singleSignOnUrl: string;
  readonly ssoEnabled: boolean;
  readonly ssoTroubleshootContent: string;
  readonly logoutUrl: string | null;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User>;
  readonly verifiedDate: string | null;
}

const { ORGANIZATION_SSO_SETTINGS, resourceId } = resource;

interface SingleSignOnParams {
  organizationId: number;
}

interface SingleSsoParams extends SingleSignOnParams {
  organizationSsoSettingsId: number;
}

const fetchCurrentOrganization = (params: SingleSignOnParams) =>
  request(backend.organizationSingleSignOnSettings.url(params), ORGANIZATION_SSO_SETTINGS, {
    method: HttpMethod.GET,
  });

const singleSignOnSettingsCreate = (params: SingleSignOnParams, singleSignOn: Partial<OrganizationSsoSettings>) =>
  request(backend.organizationSingleSignOnSettings.url(params), ORGANIZATION_SSO_SETTINGS, createBody(singleSignOn));

const singleSignOnSettingFetch = (params: SingleSsoParams) =>
  request(backend.organizationSingleSignOnSetting.url(params), ORGANIZATION_SSO_SETTINGS, {
    method: HttpMethod.GET,
  });

const singleSignOnSettingsUpdate = (params: SingleSsoParams, singleSignOn: Partial<OrganizationSsoSettings>) =>
  request(
    backend.organizationSingleSignOnSetting.url(params),
    ORGANIZATION_SSO_SETTINGS,
    updateBody(params.organizationSsoSettingsId, singleSignOn),
  );

const fetchNexusSamlConfigStatus = (params: SingleSignOnParams) =>
  request(
    backend.organizationSingleSignOnNexusSamlConfigStatus.url(params),
    nexusSamlConfigStatusResource.NEXUS_SAML_CONFIG_STATUS,
    { method: HttpMethod.GET },
  );

const createNexusSamlSetupLink = (params: SingleSignOnParams) =>
  request(
    backend.organizationSingleSignOnNexusSamlSetupLink.url(params),
    nexusSamlSetupLinkResource.NEXUS_SAML_SETUP_LINK,
    { method: HttpMethod.GET },
  );

export const OrganizationSsoSettingsDao = {
  fetchCurrentOrgSettings: fetchCurrentOrganization,
  create: singleSignOnSettingsCreate,
  fetch: singleSignOnSettingFetch,
  id: resourceId,
  update: singleSignOnSettingsUpdate,
  fetchNexusSamlConfigStatus,
  createNexusSamlSetupLink,
} as const;
