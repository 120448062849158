import { useMemo } from 'react';

import { ScheduleEntryType } from 'daos/enums';
import { ReadonlyRecord } from 'lib/readonly_record';

import WorkloadNub from './workload_nub';

interface WorkloadDateRangeNubsProps {
  dailyAvailabilitySeconds: Record<string, number>;
  dailyWorkSecondsByDate: Record<string, number>;
  isParentTaskLate?: boolean;
  isRollup?: boolean;
  lateByDate: ReadonlyRecord<string, number>;
  nubColor?: string;
  scheduleTypeByDate?: ReadonlyRecord<string, ScheduleEntryType>;
  timeOffByDate: ReadonlyRecord<string, number>;
  username: string;
  workISOFinishDate?: string;
  workISOStartDate?: string;
  workloadDates: ReadonlyArray<string>;
}

const WorkloadDateRangeNubs = ({
  dailyAvailabilitySeconds,
  dailyWorkSecondsByDate,
  isParentTaskLate = false,
  isRollup,
  lateByDate,
  nubColor,
  scheduleTypeByDate = {},
  timeOffByDate,
  username,
  workISOFinishDate,
  workISOStartDate,
  workloadDates,
}: WorkloadDateRangeNubsProps) => {
  const lastDateIndex = workloadDates.length - 1;
  const lastWorkloadDate = workloadDates[lastDateIndex];

  const workloadScheduledBeyondRange = useMemo(() => {
    const finishDateBeyondRange = !!workISOFinishDate && !!lastWorkloadDate && workISOFinishDate > lastWorkloadDate;
    const startDateBeyondRange = !!workISOStartDate && !!lastWorkloadDate && workISOStartDate > lastWorkloadDate;

    return startDateBeyondRange || finishDateBeyondRange;
  }, [lastWorkloadDate, workISOFinishDate, workISOStartDate]);

  return (
    <div className="workload-date-range">
      {workloadDates.map((date, idx) => (
        <WorkloadNub
          dailyWorkSeconds={dailyWorkSecondsByDate[date]}
          date={date}
          isParentTaskLate={isParentTaskLate}
          isRollup={isRollup}
          key={date}
          late={lateByDate[date] !== undefined}
          nubColor={nubColor}
          username={username}
          timeOff={timeOffByDate[date] !== undefined}
          unavailable={(dailyAvailabilitySeconds[date] ?? 0) === 0}
          workScheduledBeyondRange={lastDateIndex === idx && workloadScheduledBeyondRange}
          planned={scheduleTypeByDate[date] === ScheduleEntryType.Planned}
        />
      ))}
    </div>
  );
};

export default WorkloadDateRangeNubs;
