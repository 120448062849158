import { Route, Switch } from 'react-router';

import { OrganizationSsoSettings } from 'daos/organization_sso_settings';
import PageNotFound from 'features/common/errors/page_not_found';
import { NexusSamlSetup } from 'features/single_sign_on/nexus';
import { useHasFeature, useHasSystemFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { frontend } from 'lib/urls';

import { ManageSso } from './manage';
import { SetupSso } from './setup';

const SingleSignOnRoutes = ({
  fetchSSO,
  organizationSsoSettings,
}: {
  fetchSSO: () => void;
  organizationSsoSettings: OrganizationSsoSettings;
}) => {
  const hasGlobalNexusAuth = useHasSystemFeature(FeatureFlag.nexusAuthGlobal);
  const useNexusAuth = useHasFeature(FeatureFlag.useNexusAuth);

  return (
    <Switch>
      <Route
        path={frontend.singleSignOnSetup.pattern}
        render={() => <SetupSso organizationSsoSettings={organizationSsoSettings} fetchSSO={fetchSSO} />}
      />

      <Route
        path={frontend.singleSignOnManage.pattern}
        render={() => <ManageSso organizationSsoSettings={organizationSsoSettings} fetchSSO={fetchSSO} />}
      />
      {hasGlobalNexusAuth && useNexusAuth && (
        <Route path={frontend.singleSignOnNexus.pattern} render={() => <NexusSamlSetup />} />
      )}
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default SingleSignOnRoutes;
