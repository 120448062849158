import { PlanFamily } from 'daos/enums';
import { defineModel } from 'daos/shared';
import { PlanPricingTier } from 'daos/types';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { Plan } from './model_types';
import { backend } from './urls';

const { resource } = defineModel({
  apiType: 'plans',
  type: 'PLAN',
});

const { PLAN, resourceId } = resource;
const freeFamilies: ReadonlyArray<PlanFamily> = [PlanFamily.Free, PlanFamily.UltimateTrial];
const paidFamilies: ReadonlyArray<PlanFamily> = [PlanFamily.Essentials, PlanFamily.Professional, PlanFamily.Ultimate];

export const upgradedPlanFamilies: ReadonlyArray<PlanFamily> = [...paidFamilies, PlanFamily.Other];

export const isTrialOrFree = (planFamily?: PlanFamily) => {
  return !!planFamily && freeFamilies.includes(planFamily);
};

export const planPricingTier = (plan: Plan, licenses: number): PlanPricingTier | null =>
  plan.pricingTiers.find(
    (pricingTier) =>
      licenses >= pricingTier.startingUnit && (pricingTier.endingUnit === null || licenses <= pricingTier.endingUnit),
  ) ?? null;

const planFetchAll = (params: { organizationId: number }) =>
  request(backend.organizationPlans.url(params), PLAN, { method: HttpMethod.GET });

export const PlanDao = {
  id: resourceId,
  fetchAll: planFetchAll,
} as const;
