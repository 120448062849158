import classNames from 'classnames';
import { CSSProperties, memo, ReactNode } from 'react';
import { areEqual } from 'react-window';

import LpPopUp from 'features/common/lp_popup';

import './lp_nub.scss';

interface LpNubProps {
  children?: ReactNode;
  hoverContent: ReactNode;
  isEmpty?: boolean;
  nubClassName?: string;
  nubPopupClassName?: string;
  nubStyle?: CSSProperties;
}

export const LpNub = memo(
  ({ children, hoverContent, isEmpty, nubClassName, nubPopupClassName, nubStyle }: LpNubProps) => {
    const nubContentStyle = nubStyle ? nubStyle : {};
    const nubContent = (
      <div
        style={nubContentStyle}
        className={classNames(`lp-nub__content`, nubClassName, { 'lp-nub__content--empty': isEmpty })}
      >
        {children}
      </div>
    );

    const baseNub = <div className="lp-nub">{nubContent}</div>;

    return (
      <LpPopUp
        className={classNames('lp-nub__hover', nubPopupClassName)}
        positionStrategy="fixed"
        placement="top"
        trigger={baseNub}
        content={hoverContent}
      />
    );
  },
  areEqual,
);
