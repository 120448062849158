import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { AdvancedFilters } from 'features/jira_project/modal/types';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'jiraProjects',
  type: 'JIRA_PROJECT',
});

const { JIRA_PROJECT } = resource;

interface JiraProjectsParams {
  organizationId: number;
  workspaceId: number;
}

interface JiraProjectDetailsParams extends JiraProjectsParams {
  projectId: string;
}

interface JiraQuery {
  oauthCredentialsId: number;
  cloudId: string;
}

interface JiraProjectIssuesCountQuery extends JiraQuery {
  projectIdOrKey: string;
  typeIds: ReadonlyArray<string>;
  statusIds: ReadonlyArray<string>;
  parentIssueIdsOrKeys: ReadonlyArray<string>;
  createdAfter: string;
  advancedFilters?: AdvancedFilters;
}

interface JiraProjectUserCountQuery extends JiraQuery {
  projectId: string;
}

interface JiraProjectMappingFieldsQuery extends JiraQuery {
  projectIdOrKey: string;
  issueTypeIds: ReadonlyArray<string>;
}

interface CreateIssuesRequest {
  itemIds: ReadonlyArray<number>;
  externalIntegrationId: number;
}

const fetchJiraProjects = (params: JiraProjectsParams, query: JiraQuery) =>
  request(backend.jiraProjects.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });

const fetchJiraProjectDetails = (params: JiraProjectDetailsParams, query: JiraQuery) =>
  request(backend.jiraProjectDetails.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });

const fetchJiraIssuesCount = (params: JiraProjectsParams, data: JiraProjectIssuesCountQuery) => {
  return request(backend.jiraProjectIssuesCount.url(params), JIRA_PROJECT, {
    method: HttpMethod.POST,
    body: JSON.stringify(data),
  });
};

const fetchJiraProjectUserCount = (params: JiraProjectsParams, query: JiraProjectUserCountQuery) => {
  return request(backend.jiraProjectUserCount.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });
};

const fetchJiraProjectMappingFieldsWithIssueTypes = (
  params: JiraProjectsParams,
  query: JiraProjectMappingFieldsQuery,
) => {
  return request(backend.jiraProjectMappingFieldsWithIssueTypes.url(params, { query }), JIRA_PROJECT, {
    method: HttpMethod.GET,
  });
};

const pushItemsToJira = (params: JiraProjectsParams, data: CreateIssuesRequest) => {
  return request(backend.jiraPushItems.url(params), JIRA_PROJECT, {
    method: HttpMethod.POST,
    body: JSON.stringify(data),
  });
};

export const JiraProjectsDao = {
  fetchJiraProjects,
  fetchJiraProjectDetails,
  fetchJiraIssuesCount,
  fetchJiraProjectMappingFieldsWithIssueTypes,
  fetchJiraProjectUserCount,
  pushItemsToJira,
} as const;
