import { ItemType, PackageStatus, SchedulingType } from 'daos/enums';
import { FileFilter, ItemFilter, LibraryResourceFilter } from 'daos/filter_properties';
import { FolderStatus, OrderItemFetch } from 'daos/item_enums';
import {
  filterAnd,
  filterContainsIgnoreCase,
  filterOr,
  filterHasAncestor,
  filterItemType,
  filterPackageStatus,
  filterAncestorPackageStatus,
  filterChildOrganizationUser,
  filterTaskStatusSchedulingTypeOrNull,
  filterFolderStatusOrNull,
  filterIsId,
  filterEq,
  filterNull,
} from 'daos/shared';
import { LibraryLocation } from 'features/library/types';

export interface SearchItemsFilterProps {
  ancestorId?: number;
  itemTypes: ItemType | Array<ItemType>;
  orgUserId?: number;
  packageStatuses?: Array<PackageStatus>;
  searchValue: string;
  includeDone: boolean;
  order?: OrderItemFetch;
  libraryLocation?: LibraryLocation;
}

export const searchItemsFilter = ({
  ancestorId,
  itemTypes,
  orgUserId,
  packageStatuses,
  searchValue,
  includeDone,
}: SearchItemsFilterProps): string => {
  const taskStatusFilterSchedulingTypes = [SchedulingType.Scheduled, SchedulingType.Unscheduled];

  const folderStatusFilterSchedulingTypes = [FolderStatus.ACTIVE, FolderStatus.ON_HOLD];

  if (includeDone) {
    taskStatusFilterSchedulingTypes.push(SchedulingType.Done);
    folderStatusFilterSchedulingTypes.push(FolderStatus.DONE);
  }

  return filterAnd(
    ancestorId ? filterHasAncestor(ancestorId) : '',
    packageStatuses ? filterOr(filterPackageStatus(packageStatuses), filterAncestorPackageStatus(packageStatuses)) : '',
    orgUserId ? filterChildOrganizationUser(orgUserId) : '',
    filterItemType(itemTypes),
    filterTaskStatusSchedulingTypeOrNull(taskStatusFilterSchedulingTypes),
    filterFolderStatusOrNull(folderStatusFilterSchedulingTypes),
    filterOr(
      filterContainsIgnoreCase(ItemFilter.Name, searchValue),
      _getValidItemIdFilter(searchValue),
      filterAnd(
        filterItemType(ItemType.TASKS),
        filterOr(
          filterContainsIgnoreCase(ItemFilter.ChildName, searchValue),
          filterContainsIgnoreCase(ItemFilter.ChildOrganizationUserUsername, searchValue),
        ),
      ),
      filterContainsIgnoreCase(ItemFilter.Description, searchValue),
      filterContainsIgnoreCase(ItemFilter.FieldValue, searchValue),
    ),
  );
};

export const searchItemFilesFilter = ({
  ancestorId,
  itemTypes,
  orgUserId,
  packageStatuses,
  searchValue,
  includeDone,
}: SearchItemsFilterProps): string => {
  const taskStatusFilterSchedulingTypes = [SchedulingType.Scheduled, SchedulingType.Unscheduled];

  const folderStatusFilterSchedulingTypes = [FolderStatus.ACTIVE, FolderStatus.ON_HOLD];

  if (includeDone) {
    taskStatusFilterSchedulingTypes.push(SchedulingType.Done);
    folderStatusFilterSchedulingTypes.push(FolderStatus.DONE);
  }

  return filterAnd(
    ancestorId ? filterHasAncestor(ancestorId) : '',
    packageStatuses ? filterOr(filterPackageStatus(packageStatuses), filterAncestorPackageStatus(packageStatuses)) : '',
    orgUserId ? filterChildOrganizationUser(orgUserId) : '',
    filterItemType(itemTypes),
    filterTaskStatusSchedulingTypeOrNull(taskStatusFilterSchedulingTypes),
    filterFolderStatusOrNull(folderStatusFilterSchedulingTypes),
    filterContainsIgnoreCase(FileFilter.Filename, searchValue),
  );
};

export const searchLibraryResourceFilter = ({
  ancestorId,
  searchValue,
  packageStatuses,
  libraryLocation,
}: SearchItemsFilterProps): string => {
  const filters: Array<string> = [];

  const packageStatus = packageStatuses?.[0];

  if (ancestorId) {
    filters.push(filterEq(LibraryResourceFilter.ItemAncestorId, ancestorId));
  }

  if (libraryLocation === LibraryLocation.Workspace) {
    filters.push(filterNull(LibraryResourceFilter.PackageStatus, true));
  }

  if (libraryLocation === LibraryLocation.Collection) {
    packageStatus && filters.push(filterOr(filterEq(LibraryResourceFilter.PackageStatus, packageStatus)));
  }

  if (searchValue) {
    filters.push(
      filterOr(
        filterContainsIgnoreCase(LibraryResourceFilter.Name, searchValue),
        filterContainsIgnoreCase(LibraryResourceFilter.ItemName, searchValue),
      ),
    );
  }

  return filterAnd(...filters);
};

/**
 * @private exported for testing only
 */
export function _getValidItemIdFilter(searchValue: string) {
  const trimmedValue = searchValue.trim();
  const numberValue = Number(trimmedValue);

  const isNumber = !Number.isNaN(numberValue);
  const isGtZero = numberValue > 0;
  const isSafeInteger = Number.isSafeInteger(numberValue);
  const isIntegerValue = parseInt(trimmedValue) === numberValue;

  const isValidItemId = isNumber && isIntegerValue && isGtZero && isSafeInteger;

  return isValidItemId ? filterIsId(numberValue) : '';
}
