import classNames from 'classnames';
import { noop } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Image } from 'semantic-ui-react';

import { WidgetSize, WidgetType } from 'daos/enums';
import { RelativePriorityType } from 'daos/item_enums';
import { WidgetGroup } from 'daos/model_types';
import { WidgetConfig } from 'daos/types';
import { WidgetGroupDao } from 'daos/widget_groups';
import { WidgetDao, defaultConfig } from 'daos/widgets';
import { cardsBlankRegular, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { DashboardType } from 'features/dashboards/dashboard_types';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { AddWidgetParams, WidgetGalleryOptionType } from 'features/dashboards_v2/gallery/add_widget_modal/types';
import { LocationScope } from 'features/dashboards_v2/item_filter/types';
import {
  getDefaultLocationScopeForDashboardId,
  getDefaultLocationScopeForWidgetGroupId,
} from 'features/dashboards_v2/selectors';
import { getFirstNoteCustomFieldId } from 'features/dashboards_v2/widget_settings/selectors';
import { awaitRequestFinish } from 'lib/api';
import { SuccessPayload } from 'lib/api/types';

import {
  assignmentList,
  boardSummary,
  changes,
  dashboardNote,
  folderList,
  image,
  insights,
  linkedNote,
  metricsTally,
  packageList,
  peopleList,
  projectIntake,
  projectList,
  propertiesSummary,
  scheduleSummary,
  taskIntake,
  taskList,
  workload,
} from './gallery_widget_options';

import './add_widget_modal.scss';

interface WidgetGalleryOption {
  key: WidgetGalleryOptionType;
  type: WidgetType;
  name: string;
  preview: HTMLImageElement;
  config: Partial<WidgetConfig>;
  widgetSize?: WidgetSize;
}

function useWidgetGalleryOptions(scope: LocationScope) {
  const { locationFilterItem } = scope;
  const scopeItemType = locationFilterItem?.itemType;
  const firstActiveNoteFieldId = useSelector((state) =>
    scopeItemType ? getFirstNoteCustomFieldId(state, scopeItemType) : undefined,
  );

  const { dashboard } = useDashboardContext();
  const dashboardType = dashboard?.dashboardType;

  const getIntakeWidgets = useCallback(() => {
    if (dashboardType === DashboardType.projects) {
      return [taskIntake];
    }

    return [projectIntake, taskIntake];
  }, [dashboardType]);

  const galleryOptions = useMemo(() => {
    const widgetOptions = [
      scheduleSummary,
      workload,
      boardSummary,
      insights,
      changes,
      propertiesSummary,
      dashboardNote,
      linkedNote(firstActiveNoteFieldId),
      image,
      metricsTally,
      ...getIntakeWidgets(),
      taskList,
      projectList,
      packageList,
      folderList,
      assignmentList,
      peopleList,
    ];

    return widgetOptions;
  }, [firstActiveNoteFieldId, getIntakeWidgets]);

  return galleryOptions;
}
interface WidgetGalleryButtonsProps {
  selectedWidgetGalleryOption: WidgetGalleryOption | null;
  scope: LocationScope;
  onClick: (widgetGalleryOption: WidgetGalleryOption) => void;
  onDoubleClick: (widgetGalleryOption: WidgetGalleryOption) => void;
}

const WidgetGalleryButtons = ({
  selectedWidgetGalleryOption,
  scope,
  onClick,
  onDoubleClick,
}: WidgetGalleryButtonsProps) => {
  const galleryOptions = useWidgetGalleryOptions(scope);

  const handleOnClick = (widgetGalleryOption: WidgetGalleryOption) => () => onClick(widgetGalleryOption);
  const handleOnDoubleClick = (widgetGalleryOption: WidgetGalleryOption) => () => onDoubleClick(widgetGalleryOption);

  return (
    <>
      {galleryOptions.map((widgetGalleryOption) => (
        <button
          key={widgetGalleryOption.key}
          className={classNames({
            'widget-preview': true,
            'widget-preview--selected': selectedWidgetGalleryOption?.key === widgetGalleryOption.key,
          })}
          onClick={handleOnClick(widgetGalleryOption)}
          onDoubleClick={handleOnDoubleClick(widgetGalleryOption)}
        >
          <div className="widget-preview__name">{widgetGalleryOption.name}</div>
          <div className="widget-preview__image">
            <Image draggable={false} src={widgetGalleryOption.preview} />
          </div>
        </button>
      ))}
    </>
  );
};

export function AddWidgetModal({ dashboardId }: { dashboardId: string }) {
  const [selectedWidgetGalleryOption, setSelectedWidgetGalleryOption] = useState<WidgetGalleryOption | null>(null);
  const [isWidgetBeingCreated, setIsWidgetBeingCreated] = useState(false);

  const {
    setShowAddWidgetModal,
    setAddWidgetModalProps,
    addWidgetModalProps: { widgetGroupId },
  } = useDashboardContext();

  const createWidgetScope = useSelector((state) => {
    if (widgetGroupId) {
      return getDefaultLocationScopeForWidgetGroupId(state, widgetGroupId);
    }
    return getDefaultLocationScopeForDashboardId(state, dashboardId);
  });

  function resetAndCloseModal() {
    setSelectedWidgetGalleryOption(null);
    setAddWidgetModalProps({ widgetGroupId: null });
    setShowAddWidgetModal(false);
  }

  const responseHandlers = { onFinish: () => resetAndCloseModal() };
  const { createWidgetAndCloseModal } = useCreateWidgetOrWidgetGroup(responseHandlers);

  function createWidget(widgetGalleryOption: WidgetGalleryOption | null) {
    setIsWidgetBeingCreated(true);
    createWidgetAndCloseModal(widgetGalleryOption);
  }

  const handleCreateWidget = () => createWidget(selectedWidgetGalleryOption);

  const onClick = (widgetGalleryOption: WidgetGalleryOption) => setSelectedWidgetGalleryOption(widgetGalleryOption);

  const onDoubleClick = (widgetGalleryOption: WidgetGalleryOption) =>
    isWidgetBeingCreated ? noop : createWidget(widgetGalleryOption);

  return (
    <LpModal
      className="add-widget-modal"
      header={
        <>
          <LpIcon icon={cardsBlankRegular} /> Widget Gallery
        </>
      }
      content={
        <div className="add-widget-modal__content">
          <WidgetGalleryButtons
            selectedWidgetGalleryOption={selectedWidgetGalleryOption}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            scope={createWidgetScope}
          />
        </div>
      }
      actions={
        <>
          <Button
            loading={isWidgetBeingCreated}
            onClick={handleCreateWidget}
            disabled={!selectedWidgetGalleryOption || isWidgetBeingCreated}
            primary
          >
            Add Widget
          </Button>
          <Button onClick={resetAndCloseModal}>Close Gallery</Button>
        </>
      }
    />
  );
}

interface ResponseHandlers {
  onFinish: () => void;
}

function useCreateWidgetOrWidgetGroup(responseHandlers: ResponseHandlers) {
  const { createWidgetGroupAction } = useCreateWidgetGroupAction(RelativePriorityType.BEFORE);
  const { createWidgetAction } = useCreateWidgetAction();
  const {
    addWidgetModalProps: { widgetGroupId, relativePriority },
  } = useDashboardContext();

  const createWidgetAndCloseModal = (widgetGalleryOption: WidgetGalleryOption | null) => {
    widgetGroupId
      ? createWidgetAction({
          widgetGalleryOption,
          widgetGroupId,
          responseHandlers,
          relativePriority,
        })
      : createWidgetGroupAction({
          onSuccess: (resp: SuccessPayload<WidgetGroup>) => {
            createWidgetAction({
              widgetGalleryOption,
              widgetGroupId: resp.data.id.toString(),
              relativePriority,
            });
          },
          onFinish: responseHandlers.onFinish,
        });
  };

  return { createWidgetAndCloseModal };
}

function useCreateWidgetGroupAction(relativePriorityType: RelativePriorityType) {
  const dispatch = useDispatch();
  const { dashboardId, organizationId, workspaceId } = useParams<AddWidgetParams>();

  const createWidgetGroupAction = ({
    onSuccess,
    onFinish,
  }: {
    onSuccess: (res: SuccessPayload<WidgetGroup>) => void;
    onFinish: () => void;
  }) => {
    const { uuid } = dispatch(
      WidgetGroupDao.create(
        { dashboardId, organizationId: Number(organizationId), workspaceId: Number(workspaceId) },
        { relativePriority: { type: relativePriorityType } },
      ),
    );

    dispatch(awaitRequestFinish(uuid, { onSuccess, onFinish }));
  };

  return { createWidgetGroupAction };
}

interface CreateWidgetActionProps {
  widgetGalleryOption: WidgetGalleryOption | null;
  widgetGroupId: string;
  responseHandlers?: ResponseHandlers;
  relativePriority?: RelativePriorityType;
}

function useCreateWidgetAction() {
  const dispatch = useDispatch();
  const { dashboardId, organizationId, workspaceId } = useParams<AddWidgetParams>();

  const createWidgetAction = ({
    widgetGalleryOption,
    widgetGroupId,
    responseHandlers = {} as ResponseHandlers,
    relativePriority,
  }: CreateWidgetActionProps) => {
    if (widgetGalleryOption && widgetGroupId) {
      const { uuid } = dispatch(
        WidgetDao.create(
          { dashboardId, organizationId: Number(organizationId), workspaceId: Number(workspaceId) },
          {
            widgetGroupId,
            widgetType: widgetGalleryOption.type,
            config: { ...defaultConfig, ...widgetGalleryOption.config },
            relativePriority: { type: relativePriority ?? RelativePriorityType.AFTER },
            widgetSize: widgetGalleryOption.widgetSize ?? WidgetSize.Full,
          },
        ),
      );

      dispatch(awaitRequestFinish(uuid, responseHandlers));
    }
  };

  return { createWidgetAction };
}
