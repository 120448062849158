import { StandardColumns } from 'containers/shared/custom_column/enum';
import { UserColumn, CustomFieldColumn, NonCustomFieldColumn } from 'containers/shared/custom_column/types';
import { PackageStatus, UserWorkspaceSettingFieldName } from 'daos/enums';
import { UserWorkspaceSettings } from 'daos/model_types';

export type UserSelectableColumnView = 'Portfolio' | 'Project';

export const getDefaultUserSelectableColumns = (
  viewType: UserSelectableColumnView,
  packageStatus: PackageStatus | undefined,
): Array<NonCustomFieldColumn> => {
  if (!packageStatus) {
    return [];
  }

  switch (packageStatus) {
    case PackageStatus.BACKLOG:
      return viewType === 'Project'
        ? [
            { columnOption: StandardColumns.TotalWorkRange },
            { columnOption: StandardColumns.Assigned },
            { columnOption: StandardColumns.AssignmentCountTotal },
          ]
        : [
            { columnOption: StandardColumns.TotalWorkRange },
            { columnOption: StandardColumns.TargetStart },
            { columnOption: StandardColumns.TargetFinish },
          ];
    case PackageStatus.ARCHIVED:
      return viewType === 'Project'
        ? [
            { columnOption: StandardColumns.Assigned },
            { columnOption: StandardColumns.Logged },
            { columnOption: StandardColumns.DoneDate },
          ]
        : [
            { columnOption: StandardColumns.TotalWorkRange },
            { columnOption: StandardColumns.Logged },
            { columnOption: StandardColumns.PercentComplete },
          ];
    case PackageStatus.TEMPLATE:
      return viewType === 'Project'
        ? [
            { columnOption: StandardColumns.Assigned },
            { columnOption: StandardColumns.RemainingWorkRange },
            { columnOption: StandardColumns.AssignmentCountTotal },
          ]
        : [
            { columnOption: StandardColumns.RemainingWorkRange },
            { columnOption: StandardColumns.TaskCountTotal },
            { columnOption: StandardColumns.Description },
          ];
    case PackageStatus.SCHEDULED:
      return viewType === 'Project'
        ? [
            { columnOption: StandardColumns.Assigned },
            { columnOption: StandardColumns.RemainingWorkRange },
            { columnOption: StandardColumns.ExpectedFinish },
          ]
        : [
            { columnOption: StandardColumns.TotalWorkRange },
            { columnOption: StandardColumns.TargetFinish },
            { columnOption: StandardColumns.ExpectedFinish },
          ];
    case PackageStatus.CAPACITY:
      return [
        { columnOption: StandardColumns.TargetStart },
        { columnOption: StandardColumns.TargetFinish },
        { columnOption: StandardColumns.PlannedEstimate },
      ];
  }
};

export const getUserColumnsSettingsFieldNameForPackageStatus = (
  packageStatus: PackageStatus | undefined,
  viewType: UserSelectableColumnView,
) => {
  if (!packageStatus || packageStatus === PackageStatus.TEMPLATE) {
    return undefined;
  }

  switch (packageStatus) {
    case PackageStatus.SCHEDULED:
      return viewType === 'Portfolio'
        ? UserWorkspaceSettingFieldName.ScheduledPortfolioColumns
        : UserWorkspaceSettingFieldName.ScheduledProjectColumns;
    case PackageStatus.BACKLOG:
      return viewType === 'Portfolio'
        ? UserWorkspaceSettingFieldName.PendingPortfolioColumns
        : UserWorkspaceSettingFieldName.PendingProjectColumns;
    case PackageStatus.ARCHIVED:
      return viewType === 'Portfolio'
        ? UserWorkspaceSettingFieldName.ArchivedPortfolioColumns
        : UserWorkspaceSettingFieldName.ArchivedProjectColumns;
  }
};

export const getUserColumns = (
  viewType: UserSelectableColumnView,
  userSelectableColumns: ReadonlyArray<UserColumn>,
  packageStatus: PackageStatus | undefined,
  currentWorkspaceUserSettings: UserWorkspaceSettings | undefined,
) => {
  const settingFieldName = getUserColumnsSettingsFieldNameForPackageStatus(packageStatus, viewType);

  if (settingFieldName && currentWorkspaceUserSettings?.[settingFieldName].length) {
    return currentWorkspaceUserSettings[settingFieldName].map((columnOption): UserColumn => {
      //If the column is numeric, it is a customField
      if (/^\d+$/.test(columnOption)) {
        return { columnOption: StandardColumns.CustomField, fieldId: Number(columnOption) } as CustomFieldColumn;
      }

      return { columnOption } as NonCustomFieldColumn;
    });
  }

  if (userSelectableColumns.length) {
    return userSelectableColumns;
  }

  return getDefaultUserSelectableColumns(viewType, packageStatus);
};
