import { useSelector } from 'react-redux';

import { OrganizationLinkCrumb, WorkspaceNameCrumb } from 'features/common/breadcrumbs/breadcrumb_helpers';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { LpSvgs } from 'features/common/lp_svg';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import { getCurrentOrganizationUser } from 'state/entities/selectors/user';

import './jira_integration_top_nav_header.scss';

const JiraIntegrationTopNavHeader = ({ navMenuDisplay }: { navMenuDisplay: NavigationMenuDisplay }) => {
  const organization = useSelector(getCurrentOrganization);
  const organizationUser = useSelector(getCurrentOrganizationUser);

  if (!organization || !organizationUser) {
    return null;
  }

  return (
    <TopNavHeader
      breadcrumb={
        <LpBreadcrumb
          className="jira-integration-top-nav-header"
          sections={[
            { key: 'organization-name', content: <OrganizationLinkCrumb svg={LpSvgs.JIRA} /> },
            { key: 'workspace-name', content: <WorkspaceNameCrumb /> },
            { key: navMenuDisplay, content: navMenuDisplay },
          ]}
        />
      }
    />
  );
};

export default JiraIntegrationTopNavHeader;
