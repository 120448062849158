export const NovaEpicSvg = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="epic" clipPath="url(#clip0_3165_5660)">
        <path
          id="Vector"
          d="M12.918 9.2999L9.75 13.0585V10.4499V9.6999H9H7.07721L10.25 5.93354V8.5499V9.2999H11H12.918ZM18.75 3.7999C18.75 2.30056 17.4821 1.1499 16 1.1499H4C2.51793 1.1499 1.25 2.30056 1.25 3.7999V15.1999C1.25 16.6992 2.51793 17.8499 4 17.8499H16C17.4821 17.8499 18.75 16.6992 18.75 15.1999V3.7999Z"
          stroke="black"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3165_5660">
          <rect width="20" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
