import { useSelector } from 'react-redux';

import { FeatureFlag } from 'lib/feature_flags';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import { getSystemFeatureFlagDefaults, getSystemFeatureFlagValues } from 'state/entities/selectors/system_settings';

export const useHasFeature = (feature: FeatureFlag) => {
  const organization = useSelector(getCurrentOrganization);
  const featureFlagDefaults = useSelector(getSystemFeatureFlagDefaults);

  return (organization?.featureFlags ?? featureFlagDefaults)[feature];
};

export const useHasSystemFeature = (feature: FeatureFlag) => useSelector(getSystemFeatureFlagValues)[feature];

/** @description Use this hook for all display changes regardless of an organization enabling groups feature */
export const useHasTeamBasedPlanning = () => useHasFeature(FeatureFlag.teamBasedPlanning);

/**
 * @description Use this hook for all team based planning features to be included in Team25 demos.
 * It returns true when `teamBasedPlanning` flag is enabled and the current org has groups feature enabled.
 * Team based planning is only intended to work when an organization plan includes groups (included in upgraded access controls).
 */
export const useHasTeam25TeamBasedPlanning = () => {
  const hasGroupsEnabled = !!useSelector(getCurrentOrganization)?.flags.hasUpgradedAccessControls;
  const hasTeamBasedPlanning = useHasFeature(FeatureFlag.teamBasedPlanning);

  return hasGroupsEnabled && hasTeamBasedPlanning;
};

/**
 *  @description Use this hook for all ongoing team based planning features not included in Team25 demos.
 * It encompasses all Team25 demo feature work (including the organization flag for groups) as well.
 * */
export const useHasGeneralTeamBasedPlanning = () => {
  const hasTeam25Planning = useHasTeam25TeamBasedPlanning();
  const hasGeneralTeamBasedPlanning = useHasFeature(FeatureFlag.generalTeamBasedPlanning);

  return hasTeam25Planning && hasGeneralTeamBasedPlanning;
};
