import { find } from 'lodash';
import { LruObjectCache, ParametricKeySelector } from 're-reselect';

import { Item, ResourceId } from 'daos/model_types';
import { RootState } from 'state/root_reducer';

const cacheByFirstTwoParameters = (_: RootState, id: string | number | ResourceId<Item>, id2: number) =>
  /* eslint-disable-next-line @typescript-eslint/no-base-to-string */
  id !== undefined && id2 !== undefined ? [id, id2].join(',') : '';

const cacheById = <T>(_: RootState, id: T): string => '' + id || '';

const cacheByIds = <T>(_: RootState, ids: Array<T> | ReadonlyArray<T>): string =>
  ids && ids.length ? ids.join(',') : '';

export const getNumberArgument = (_: RootState, value: number): number => value;

export const getStringArgument = (_: RootState, value: string): string => value;

export const getSecondParameter = <T>(_: RootState, __: any, p2: T): T => p2;

export const or: <T>(...fns: Array<(obj: T) => boolean>) => (obj: T) => boolean =
  (...fns) =>
  (obj) =>
    !!find(fns, (fn) => fn(obj));

const CACHE_SIZE = 20;

export function createCacheByFirstTwoParametersConfig(cacheSize = CACHE_SIZE) {
  return createCachedSelectorConfig(cacheByFirstTwoParameters, cacheSize);
}

export function createCacheByIdsConfig<T>(cacheSize = CACHE_SIZE) {
  return createCachedSelectorConfig<RootState, Array<T> | ReadonlyArray<T>>(cacheByIds, cacheSize);
}

export function createCacheByIdConfig<T>(cacheSize = CACHE_SIZE) {
  return createCachedSelectorConfig<RootState, T>(cacheById, cacheSize);
}

function createCachedSelectorConfig<S, P>(fn: ParametricKeySelector<S, P>, cacheSize = 20) {
  return {
    keySelector: fn,
    cacheObject: new LruObjectCache({ cacheSize }),
  };
}
