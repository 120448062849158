import { useSelector } from 'react-redux';

import { ItemType, Permission } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { EllipsisActionDropdownMethods } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/ellipsis_action_dropdown_methods';
import {
  assignmentMenuItems,
  folderMenuItems,
  packageMenuItems,
  projectMenuItems,
  taskMenuItems,
} from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/ellipsis_action_menu_items';
import {
  EllipsisActionMenuItem,
  reduceEllipsisActionMenuItems,
} from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/reduce_ellipsis_action_menu_items';
import { EllipsisActionViewLocation } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { useHasAccess } from 'hooks/use_has_access';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { readonlyArray } from 'lib/readonly_record';
import {
  getAncestorProjectIdForItemId,
  getPackageForItemId,
  getPackageStatusForItemId,
} from 'state/entities/selectors/item';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

export const useEllipsisActionMenuItems = (
  itemId: number,
  itemType: ItemType,
  dropdownItemMethods: EllipsisActionDropdownMethods,
  viewLocation: EllipsisActionViewLocation | undefined,
): { dropdownMenuItems: ReadonlyArray<EllipsisActionMenuItem> | readonly [] } => {
  const workspaceRoot = useSelector(getCurrentWorkspace)?.workspaceRoot;
  const packageId = useSelector((state) => getPackageForItemId(state, itemId))?.id ?? 0;
  const projectId = useSelector((state) => getAncestorProjectIdForItemId(state, itemId)) ?? 0;
  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, itemId));
  const hasJiraIntegration = useHasFeature(FeatureFlag.jiraIntegration);
  const hasManageAccess = useHasAccess(Permission.MANAGE, ItemDao.id(itemId));

  const workspaceResource = workspaceRoot ?? ItemDao.id(0);
  const packageResource = ItemDao.id(packageId);
  const projectResource = ItemDao.id(projectId);
  const itemResource = ItemDao.id(itemId);

  const accessChecks = {
    manage: {
      workspaceRoot: useHasAccess(Permission.MANAGE, workspaceResource),
      package: useHasAccess(Permission.MANAGE, packageResource),
      item: useHasAccess(Permission.MANAGE, itemResource),
    },
    edit: {
      project: useHasAccess(Permission.EDIT, projectResource),
      item: useHasAccess(Permission.EDIT, itemResource),
    },
    observe: {
      item: useHasAccess(Permission.OBSERVE, itemResource),
    },
  };

  let dropdownMenuItems;
  let menuKey = 0;
  const incrementedMenuKey = () => (menuKey += 1);

  switch (itemType) {
    case ItemType.PACKAGES: {
      dropdownMenuItems = reduceEllipsisActionMenuItems(
        packageMenuItems(
          dropdownItemMethods,
          incrementedMenuKey,
          accessChecks,
          packageStatus,
          hasJiraIntegration,
          hasManageAccess,
        ),
        viewLocation,
      );
      break;
    }
    case ItemType.PROJECTS: {
      dropdownMenuItems = reduceEllipsisActionMenuItems(
        projectMenuItems(dropdownItemMethods, incrementedMenuKey, accessChecks),
        viewLocation,
      );
      break;
    }
    case ItemType.FOLDERS: {
      dropdownMenuItems = reduceEllipsisActionMenuItems(
        folderMenuItems(dropdownItemMethods, incrementedMenuKey, accessChecks),
        viewLocation,
      );
      break;
    }
    case ItemType.TASKS: {
      dropdownMenuItems = reduceEllipsisActionMenuItems(
        taskMenuItems(dropdownItemMethods, incrementedMenuKey, accessChecks),
        viewLocation,
      );
      break;
    }
    case ItemType.ASSIGNMENTS: {
      dropdownMenuItems = reduceEllipsisActionMenuItems(
        assignmentMenuItems(dropdownItemMethods, incrementedMenuKey, accessChecks),
        viewLocation,
      );
      break;
    }
    case ItemType.WORKSPACE_ROOTS: {
      dropdownMenuItems = readonlyArray([]);
    }
  }

  return { dropdownMenuItems };
};
