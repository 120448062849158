import { useHistory } from 'react-router';

import useHashParams from 'hooks/use_hash_params';

export const useStoryPointsSchemeModalParams = (editSchemeId?: number) => {
  const history = useHistory();
  const { newStoryPointsScheme } = useHashParams();
  const { editStoryPointsSchemeId } = useHashParams();

  const closeStoryPointsSchemeModal = () => {
    history.replace({ ...window.location, hash: '' });
  };
  const openStoryPointsSchemeModal = () => {
    history.replace({ ...window.location, hash: '#newStoryPointsScheme' });
  };
  const openEditStoryPointsSchemeModal = () => {
    if (editSchemeId) {
      history.replace({ ...window.location, hash: `#editStoryPointsSchemeId=${editSchemeId}` });
    }
  };

  const showNewStoryPointsSchemeModal = newStoryPointsScheme != undefined;
  const showEditStoryPointsSchemeModal = editStoryPointsSchemeId != undefined;

  return {
    showStoryPointsSchemeModal: showNewStoryPointsSchemeModal || showEditStoryPointsSchemeModal,
    closeStoryPointsSchemeModal,
    openStoryPointsSchemeModal,
    openEditStoryPointsSchemeModal,
  };
};
