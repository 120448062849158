import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { PlanFamily, planFamilyDisplayText } from 'daos/enums';
import { SubscriptionDao } from 'daos/subscription';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { banRegular, LpIcon } from 'features/common/lp_icon';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { awaitRequestFinish } from 'lib/api';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import { getCurrentOrganizationSubscription } from 'state/entities/selectors/subscription';

import './pending_changes.scss';

export const ManageAccountPendingChanges = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const organization = useSelector(getCurrentOrganization);
  const currentOrganizationSubscription = useSelector(getCurrentOrganizationSubscription);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const { formatLocalDate } = useLocalizedFormats();

  if (!organization || !currentOrganizationSubscription || !currentOrganizationSubscription.hasScheduledChanges) {
    return null;
  }

  const nextBillingOn = currentOrganizationSubscription.nextBillingOn;
  const nextRenewalPlanFamily = currentOrganizationSubscription.nextRenewalPlanFamily;
  const nextRenewalBillingPeriod = currentOrganizationSubscription.nextRenewalBillingPeriod;
  const nextRenewalPlanQuantity = currentOrganizationSubscription.nextRenewalPlanQuantity;

  const billingCycleText =
    nextRenewalPlanFamily === PlanFamily.Free ? '' : ` / ${capitalize(nextRenewalBillingPeriod)}`;

  const cancelPendingChanges = () => {
    setIsCanceling(true);
    const { uuid } = dispatch(
      SubscriptionDao.cancelSubscriptionChange({
        organizationId,
      }),
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => setIsCanceled(true),
        onFinish: () => setIsCanceling(false),
      }),
    );
  };

  const cancelChangesAction = isCanceled ? (
    <span className="manage-account__pending-changes__cancel-pending">Cancel Pending</span>
  ) : (
    <span className="manage-account__pending-changes__cancel" onClick={cancelPendingChanges}>
      <LpIcon className={'manage-account__pending-changes__cancel-icon'} icon={banRegular} />
      Cancel Change
    </span>
  );

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted active={isCanceling}>
        <Loader>Canceling</Loader>
      </Dimmer>
      <div
        className={classNames('manage-account__pending-changes', {
          'manage-account__pending-changes-canceled': isCanceled,
        })}
      >
        <span className="manage-account__pending-changes__renewal-date">
          At your next renewal{nextBillingOn && ` on ${formatLocalDate(nextBillingOn)}`} :
        </span>
        {organization?.flags?.allowSelfService && cancelChangesAction}

        <p className="manage-account__pending-changes__changed-to">{`Change plan to ${planFamilyDisplayText[nextRenewalPlanFamily]} ${billingCycleText} / ${nextRenewalPlanQuantity} licenses`}</p>
      </div>
    </Dimmer.Dimmable>
  );
};
