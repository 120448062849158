import { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Authentication } from 'features/authentication';
import { Logout } from 'features/authentication/authenticated/logout';
import EventBus from 'features/common/events/event_bus';
import { ErrorBoundary } from 'features/errors/error_boundary';
import { RouteLogger } from 'lib/datadog/logger/route_logger';
import GoogleAnalyticsTracker from 'lib/google_analytics/google_analytics_tracker';
import { frontend } from 'lib/urls';
import { store } from 'state/store';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/600-italic.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/700-italic.css';
import '@fontsource/open-sans/800.css';
import '@fontsource/open-sans/800-italic.css';

import 'semantic-ui-less/semantic.less';
import './style/main.scss';

const App = () => {
  const [selectedOrgId, setSelectedOrgId] = useState<number | undefined>(undefined);
  const [selectedWsId, setSelectedWsId] = useState<number | undefined>(undefined);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <RouteLogger orgId={selectedOrgId} wsId={selectedWsId} />
        <ErrorBoundary>
          <GoogleAnalyticsTracker>
            <EventBus>
              <Switch>
                <Route
                  exact
                  path={frontend.logout.pattern}
                  render={() => <Logout setSelectedWsId={setSelectedWsId} setSelectedOrgId={setSelectedOrgId} />}
                />
                <Route
                  path="*"
                  render={() => (
                    <Authentication
                      selectedOrgId={selectedOrgId}
                      setSelectedOrgId={setSelectedOrgId}
                      selectedWsId={selectedWsId}
                      setSelectedWsId={setSelectedWsId}
                    />
                  )}
                />
              </Switch>
            </EventBus>
          </GoogleAnalyticsTracker>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
};

const container = document.getElementById('root');

createRoot(container!).render(<App />);
