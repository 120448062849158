import { WorkloadGroup, WorkloadItem } from 'daos/model_types';

export enum WorkloadCellType {
  Name = 'name',
  DateNub = 'dateNub',
  ScheduledHours = 'scheduledHours',
  PlannedHours = 'plannedHours',
  AvailabilityScheduled = 'availabilityScheduled',
  AvailabilityPlanned = 'availabilityPlanned',
}

export interface WorkloadRowData {
  group?: WorkloadGroup;
  item?: WorkloadItem;
}

export type WorkloadRows = ReadonlyArray<WorkloadRowData>;
