import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { OrganizationUserDao } from 'daos/organization_user';
import { useHasAccess } from 'hooks/use_has_access';
import { getSystemTimezones } from 'state/entities/selectors/system_settings';
import { getOrganizationUserForId, getWorkspaceUserForOrganizationUserId } from 'state/entities/selectors/user';

interface OrgUserTimezoneDropdownProps {
  className?: string;
  orgUserId: number;
  updateOrgUser: ({ timezone }: { timezone: string }) => void;
}

const OrgUserTimezoneDropdown = ({ orgUserId, updateOrgUser, className }: OrgUserTimezoneDropdownProps) => {
  const timezones = useSelector(getSystemTimezones);
  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const wsUser = useSelector((state) => getWorkspaceUserForOrganizationUserId(state, orgUserId));

  const hasEditAccess = useHasAccess(Permission.EDIT, OrganizationUserDao.id(orgUserId));

  if (!orgUser || !wsUser) {
    return null;
  }

  const timeZonesDropdownOptions = timezones.map((timezone, idx) => {
    return {
      text: timezone,
      value: timezone,
      key: timezone + idx,
    };
  });

  const handleTimezoneUpdate = (_: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    if (value !== orgUser.timezone) {
      const timezoneAsString = String(value);
      updateOrgUser({ timezone: timezoneAsString });
    }
  };

  return (
    <Dropdown
      className={classNames('user-profile__about-table-timezone', className)}
      disabled={!hasEditAccess}
      icon={null}
      onChange={handleTimezoneUpdate}
      selectOnNavigation={false}
      options={timeZonesDropdownOptions}
      placeholder="None"
      search
      selection
      text={orgUser.timezone}
      value={orgUser.timezone}
    />
  );
};

export default OrgUserTimezoneDropdown;
