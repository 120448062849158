import { FieldInputProps, FieldMetaProps, useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Form, Table } from 'semantic-ui-react';
import * as yup from 'yup';

import LpFormError from 'containers/shared/form_errors/lp_form_error';
import { Permission } from 'daos/enums';
import { InvitationDao } from 'daos/invitation';
import { SubjectAccessForItemGrid, Workspace } from 'daos/model_types';
import { OrganizationDao } from 'daos/organization';
import { accessRoleByPermission } from 'daos/permission';
import LpFormCheckbox from 'features/common/forms/lp_form_checkbox';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import CustomErrorMessage from 'features/errors/custom_error_message';
import BeyondLimitModal from 'features/organization_directory/manage_account/beyond_limit_modal';
import { reduceWorkspaceIdsForInvitation } from 'features/organization_directory/users/invite/helpers';
import { InviteErrors } from 'features/organization_directory/users/invite/invite_errors';
import { WorkspaceInformationForInvitationUser } from 'features/organization_directory/users/invite/selectors';
import { awaitRequestFinish } from 'lib/api';
import { ErrorCodes } from 'lib/api/types';
import { getAllMembersAccessControlPermission } from 'lib/get_all_members_access_control_permission';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'state/entities/selectors/organization';

const validationSchema = yup
  .object()
  .shape({
    workspaceIds: yup.object().shape({ key: yup.string(), value: yup.boolean() }),
  })
  .test('at-least-one-space-required', function ({ workspaceIds }) {
    const hasError = !Object.values(workspaceIds).filter((it) => {
      return it;
    }).length;

    if (hasError) {
      return this.createError({
        message: lpErrorText.workspaceNeeded,
        path: 'noWorkspaceIdsSelected',
      });
    }

    return true;
  });

interface WorkspaceRowProps {
  workspace: Workspace;
  workspaceInformation?: WorkspaceInformationForInvitationUser;
  getFieldMeta: (name: string) => FieldMetaProps<string>;
  getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
  currentWorkspaceId: number;
  key: number;
  subjectAccessForItemGrid: SubjectAccessForItemGrid | undefined;
}
function WorkspaceRow({
  workspace,
  workspaceInformation,
  getFieldProps,
  getFieldMeta,
  currentWorkspaceId,
  subjectAccessForItemGrid,
}: WorkspaceRowProps) {
  if (!subjectAccessForItemGrid) {
    return null;
  }
  const allMemberAccessControlPermission = getAllMembersAccessControlPermission(subjectAccessForItemGrid);

  const accessPermissionDisplayMessage =
    allMemberAccessControlPermission === Permission.NONE
      ? 'with no access'
      : `as ${accessRoleByPermission[allMemberAccessControlPermission]}`;

  return (
    <Table.Row key={workspace.id}>
      <Table.Cell collapsing>
        <LpFormCheckbox
          className="users-invite__workspace-checkbox-wrapper"
          disabled={
            workspaceInformation?.workspaceUsersByWorkspaceId[workspace.id] &&
            !workspaceInformation?.workspaceUsersByWorkspaceId[workspace.id]?.wsUser.disconnectedAt
          }
          fieldKey={`workspaceIds[${workspace.id}]`}
          getFieldMeta={getFieldMeta}
          getFieldProps={getFieldProps}
          e2eTestId="invite-user-workspace-checkbox"
          label={
            <label>
              <>{workspace.id === currentWorkspaceId ? `${workspace.name} (your current workspace)` : workspace.name}</>{' '}
              <span className="users-invite__workspace-access-display-message">
                {accessPermissionDisplayMessage} (Default)
              </span>
            </label>
          }
        />
      </Table.Cell>
    </Table.Row>
  );
}

export const WorkspacesForm = ({
  currentWorkspaceId,
  existingUserEmail,
  newUserEmail,
  onCancel,
  onInvitationSuccess,
  organizationId,
  workspaceInformation,
  workspaces,
  selectedWorkspaces,
}: {
  currentWorkspaceId: number;
  existingUserEmail: string;
  newUserEmail: string;
  onCancel: () => void;
  onInvitationSuccess: () => void;
  organizationId: number;
  workspaceInformation?: WorkspaceInformationForInvitationUser;
  workspaces: Array<Workspace>;
  selectedWorkspaces: ReadonlyArray<string>;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const organization = useSelector(getCurrentOrganization);

  const [hasWorkspaceUserRecordInAllAvailableWorkspaces, setHasWorkspaceUserRecordInAllAvailableWorkspaces] =
    useState(false);

  const workspaceUsersSize = workspaceInformation?.wsUserCount;
  const workspacesLength = workspaces?.length;
  const [isFetching, setIsFetching] = useState(false);
  const [subjectAccessForItemGrids, setSubjectAccessForItemGrids] = useState<ReadonlyArray<SubjectAccessForItemGrid>>(
    [],
  );

  const [showBeyondLimitModal, setShowBeyondLimitModal] = useState(false);
  const hideBeyondLimitModal = () => setShowBeyondLimitModal(false);

  const defaultWorkspaceIds = (workspaces: Array<Workspace>) => {
    return workspaces.reduce((acc: { [workspaceId: string]: boolean }, workspace) => {
      acc[workspace.id] = selectedWorkspaces.includes(workspace.id.toString());
      return acc;
    }, {});
  };

  const fetchOrganizationAccessBulk = useCallback(() => {
    setIsFetching(true);
    const { uuid } = dispatch(OrganizationDao.fetchAccessBulk({ organizationId }));

    dispatch(
      awaitRequestFinish<ReadonlyArray<SubjectAccessForItemGrid>>(uuid, {
        onFinish: () => setIsFetching(false),
        onSuccess: ({ data }) => setSubjectAccessForItemGrids(data),
      }),
    );
  }, [dispatch, organizationId]);

  useEffect(() => {
    fetchOrganizationAccessBulk();
  }, [fetchOrganizationAccessBulk]);

  useEffect(() => {
    if (hasWorkspaceUserRecordInAllAvailableWorkspaces === false && workspaceUsersSize === workspacesLength) {
      setHasWorkspaceUserRecordInAllAvailableWorkspaces(true);
    }
  }, [hasWorkspaceUserRecordInAllAvailableWorkspaces, workspaceUsersSize, workspacesLength]);

  const {
    isSubmitting,
    status: inviteError,
    errors,
    setStatus: setInviteError,
    handleSubmit,
    getFieldMeta,
    getFieldProps,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      workspaceIds: defaultWorkspaceIds(workspaces),
      noWorkspaceIdsSelected: false,
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ workspaceIds }) => {
      const email = newUserEmail !== '' ? newUserEmail : existingUserEmail;
      const { uuid } = dispatch(
        InvitationDao.create({
          email,
          organization: OrganizationDao.id(organizationId),
          workspaces: reduceWorkspaceIdsForInvitation(workspaceIds),
        }),
      );

      dispatch(
        awaitRequestFinish(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              if (errors[0].code === ErrorCodes.LimitExceeded) {
                return setShowBeyondLimitModal(true);
              }

              setInviteError(errors[0]);
            } else {
              setInviteError(['Something went wrong. Please try again.']);
            }
          },
          onSuccess: () => {
            onInvitationSuccess();
            resetForm();
          },
          onFinish: () => setSubmitting(false),
        }),
      );
    },
  });

  if (!organization) {
    return null;
  }

  const cancelInvitation = () => {
    resetForm();
    onCancel();
    history.push(frontend.organizationHubUsersInvite.url({ organizationId: organization.id }));
  };

  const formError = errors.noWorkspaceIdsSelected;

  if (isFetching) {
    return <LpOverlayLoader />;
  }

  return (
    <>
      {inviteError && (
        <CustomErrorMessage className="confirm-invite__error" content={<InviteErrors error={inviteError} />} />
      )}
      {showBeyondLimitModal && <BeyondLimitModal onClose={hideBeyondLimitModal} />}

      <Form className="confirm-invite" loading={isSubmitting} onSubmit={handleSubmit}>
        <div className="confirm-invite__scroll-element">
          <Table className="users-invite__table">
            <Table.Body>
              {workspaces.map((workspace) => {
                const subjectAccessForItemGrid = subjectAccessForItemGrids.find(
                  (access) => access.itemId === workspace.workspaceRoot.id,
                );
                return (
                  <WorkspaceRow
                    workspace={workspace}
                    workspaceInformation={workspaceInformation}
                    currentWorkspaceId={currentWorkspaceId}
                    getFieldMeta={getFieldMeta}
                    getFieldProps={getFieldProps}
                    key={workspace.id}
                    subjectAccessForItemGrid={subjectAccessForItemGrid}
                  />
                );
              })}
            </Table.Body>
          </Table>
        </div>

        {formError && <LpFormError className="confirm-invite__error" error={formError} />}

        <div className="confirm-invite__footer">
          <Button onClick={cancelInvitation} content={'Cancel'} type="button" />
          <Button
            disabled={
              (hasWorkspaceUserRecordInAllAvailableWorkspaces && !workspaceInformation?.isDisconnected) || isSubmitting
            }
            data-e2e-test-id={'invite-user-workspace-submit'}
            content="Invite"
            primary
            type="submit"
          />
        </div>
      </Form>
    </>
  );
};
