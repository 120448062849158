import classNames from 'classnames';
import { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';

import 'features/jira_project/modal/jira_project_modal.scss';

export const JiraProjectModalTableNameCell = ({
  content,
  leftIcon,
  rightIcon,
  hasMultipleRows,
}: {
  content: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  hasMultipleRows?: boolean;
}) => (
  <Table.Cell
    className={classNames(
      hasMultipleRows && 'jira-project-modal__content-table-name-multiple-rows',
      'field-value picklist',
    )}
  >
    <div className="custom-field__row-field">
      <div className="jira-project-modal__content-table-name-container">
        <div
          className={classNames(
            'jira-project-modal__content-table-name-content',
            hasMultipleRows && 'custom-field-input',
          )}
        >
          {leftIcon} {content}
        </div>
        {rightIcon}
      </div>
    </div>
  </Table.Cell>
);
