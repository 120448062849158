import { AdvancedFilters, JiraAutoCompleteResult } from 'features/jira_project/modal/types';

interface UseAdvancedFiltersProps {
  parentId: string;
  advancedFilters: AdvancedFilters;
  setAdvancedFilters: (filters: AdvancedFilters) => void;
  dropdowns: Array<number>;
  setDropdowns: (dropdowns: Array<number>) => void;
}

export const useAdvancedFilters = ({
  parentId,
  advancedFilters,
  setAdvancedFilters,
  dropdowns,
  setDropdowns,
}: UseAdvancedFiltersProps) => {
  const selectedValues = advancedFilters[parentId] ?? [];

  const setFieldValueForParentId = ({ newValue, index }: { newValue?: JiraAutoCompleteResult; index?: number }) => {
    if (index === undefined) {
      return setAdvancedFilters({ ...advancedFilters, [parentId]: [] });
    }
    const updatedValues = [...selectedValues];

    if (newValue === undefined) {
      updatedValues.splice(index, 1);
    } else {
      updatedValues[index] = newValue;
    }

    setAdvancedFilters({ ...advancedFilters, [parentId]: updatedValues });
  };

  const newDropdowns =
    selectedValues.length === 0 ? [0] : Array.from({ length: selectedValues.length + 1 }, (_, i) => i);

  if (JSON.stringify(newDropdowns) !== JSON.stringify(dropdowns)) {
    setDropdowns(newDropdowns);
  }

  return { selectedValues, setFieldValue: setFieldValueForParentId, newDropdowns };
};
