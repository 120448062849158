import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { ExternalIntegrationDao } from 'daos/external_integration';
import {
  EstimationType,
  SourceSystem,
  StoryPointSchemeActions,
  TaskGroupHierarchy,
  TaskPushSetting,
} from 'daos/external_integration_enums';
import { JiraExternalIntegrationDao } from 'daos/jira_external_integration';
import { ExternalIntegrationFieldMapping, JiraIssueFilter, JiraMapping, JiraSyncSettings } from 'daos/model_types';
import { LpTabbedModalAsLpFormikForm } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import Content from 'features/jira_project/modal/content';
import { JiraProjectModalProvider } from 'features/jira_project/modal/context/jira_project_modal_provider';
import { Footer } from 'features/jira_project/modal/footer';
import Header from 'features/jira_project/modal/header';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { JiraTabKey } from 'features/jira_project/modal/types';
import {
  convertStoryPointsToString,
  getDisplayNameInEditMode,
  getExistingStoryPointsSchemesNames,
  getInitialValues,
  getSyncErrorMessage,
  handleJiraProjectDetailsResponse,
  populateJiraToLpRows,
  populateLpToJiraRows,
} from 'features/jira_project/modal/utils';
import { validateActiveTab } from 'features/jira_project/modal/validation';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import {
  getNonSystemFieldsByFieldTypesUsedOnTasks,
  supportedJiraMappingCustomFieldTypes,
} from 'state/entities/selectors/custom_field';
import { getExternalIntegrationsForItemId } from 'state/entities/selectors/external_integration';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

interface JiraIntegrationModalProps {
  setShowJiraProjectModal: (show: boolean) => void;
  packageId: number;
  itemId?: number;
  integrationId?: number;
  canModify?: boolean;
  hasFieldMappingWarning?: boolean;
  setShouldActivatePushToJiraButton?: (shouldActivate: boolean) => void;
}

const JiraProjectModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const workspace = useSelector(getCurrentWorkspace);
  const defaultCostCode = workspace?.defaultCostCode?.id;
  const costCodeRequired = workspace?.costCodeRequired;
  const customLpFields = useSelector(
    (state) => getNonSystemFieldsByFieldTypesUsedOnTasks(state, supportedJiraMappingCustomFieldTypes),
    isEqual,
  );
  const [jiraFieldMappings, setJiraFieldMappings] = useState<ReadonlyArray<ExternalIntegrationFieldMapping>>([]);
  const [jiraSyncSettings, setJiraSyncSettings] = useState<JiraSyncSettings>();
  const [jiraProjectId, setJiraProjectId] = useState('');
  const [jiraIssueFilters, setJiraIssueFilters] = useState<JiraIssueFilter>();
  const [oAuthCredentialsId, setOAuthCredentialsId] = useState(0);
  const [jiraCloudId, setJiraCloudId] = useState('');

  const { oauthCredentialsId: oauthCredentialsIdFromReturnUrl } = useQueryParams();

  const {
    handleCloseButtonClick,
    setShowConfirmCloseModal,
    showConfirmCloseModal,
    submitHandler,
    activeTab,
    completedTabs,
    previousTabKey,
    handlePanelClose,
    itemId,
    packageId,
    isEditMode,
    setIsLoadingContent,
    setFormError,
    setCredentialsError,
    setCanModifyCostCode,
    setCanModifyStoryPoints,
    jiraProjectMappingFieldData,
    setAddedJiraFieldMappingRowItems,
    setAddedLpFieldMappingRowItems,
    setInitialValuesForAnalyticsOnly,
    setCanSelectLowerHierarchicalLevel,
    selectedSchemeActionDisplayName,
    setSelectedSchemeActionDisplayName,
    storyPointSchemeOwner,
    schemesWithStoryPoints,
  } = useJiraProjectModalContext();

  const existingSchemeNames = getExistingStoryPointsSchemesNames(schemesWithStoryPoints);

  const externalIntegrations = useSelector((state) => getExternalIntegrationsForItemId(state, itemId ?? 0));
  const externalIntegration = externalIntegrations.find(
    (integration) => integration.sourceSystem === SourceSystem.JIRA,
  );
  const externalIntegrationId = externalIntegration?.id;
  const shouldUpdateOauthCredentials =
    !!oauthCredentialsIdFromReturnUrl &&
    !!externalIntegrationId &&
    Number(oauthCredentialsIdFromReturnUrl) !== oAuthCredentialsId;
  const currentSchemeDisplayName = getDisplayNameInEditMode(schemesWithStoryPoints, storyPointSchemeOwner);

  const jiraProjectModalContent = {
    header: <Header />,
    content: <Content />,
    actions: <Footer />,
  };

  useEffect(() => {
    if (!completedTabs[previousTabKey]) {
      if (isEditMode) {
        return history.push({
          pathname: history.location.pathname,
          search: `?packageId=${packageId}${
            oauthCredentialsIdFromReturnUrl ? `&oauthCredentialsId=${oauthCredentialsIdFromReturnUrl}` : ''
          }`,
          hash: `panelSection=${JiraTabKey.JiraInstance}`,
        });
      }
      switch (activeTab) {
        case JiraTabKey.ProjectAndIssuesFilter:
        case JiraTabKey.SyncSettings:
        case JiraTabKey.FieldMapping:
        case JiraTabKey.RemainingEstimates:
        case JiraTabKey.Launch:
          return history.push({
            pathname: history.location.pathname,
            search: `?packageId=${packageId}`,
            hash: `panelSection=${JiraTabKey.Welcome}`,
          });
        case JiraTabKey.JiraInstance:
          return history.push({
            pathname: history.location.pathname,
            search: `?packageId=${packageId}`,
            hash: `panelSection=${JiraTabKey.JiraInstance}`,
          });
        default:
          return history.push({
            pathname: history.location.pathname,
            search: `?packageId=${packageId}`,
            hash: `panelSection=${JiraTabKey.Welcome}`,
          });
      }
    }
  }, [activeTab, completedTabs, history, previousTabKey, isEditMode, packageId, oauthCredentialsIdFromReturnUrl]);

  const fetchExternalIntegrationDetails = useCallback(() => {
    if (!externalIntegrationId) {
      return;
    }
    setIsLoadingContent(true);
    const externalIntegrationDetailsFetchFn = ExternalIntegrationDao.fetch(
      {
        organizationId,
        workspaceId,
        externalIntegrationId,
      },
      {
        include: {
          externalIntegrationFieldMappings: true,
          jiraIssueFilters: true,
          jiraAccessibleResourcesView: true,
          jiraSyncSettings: true,
        },
        validate: true,
      },
    );

    const { uuid } = dispatch(externalIntegrationDetailsFetchFn);

    dispatch(
      awaitRequestFinish<JiraMapping>(uuid, {
        onSuccess: ({ data: { credentialsId, sourceItemId }, entities }) => {
          const { jiraFieldMappings, jiraIssueFilters, jiraAccessibleResourcesView, jiraSyncSettings } =
            handleJiraProjectDetailsResponse({
              jiraIssueFilters: entities.jiraIssueFilters,
              externalIntegrationFieldMappings: entities.externalIntegrationFieldMappings,
              jiraAccessibleResourcesView: entities.jiraAccessibleResourcesView,
              jiraSyncSettings: entities.jiraSyncSettings,
            });

          if (!jiraIssueFilters || !jiraFieldMappings || !jiraAccessibleResourcesView) {
            return;
          }

          setInitialValuesForAnalyticsOnly({
            advancedFilters: {},
            fieldMappings: jiraFieldMappings,
            issueTypeIds: [...jiraIssueFilters.issueTypeIds],
            issueStatusIds: [...jiraIssueFilters.issueStatusIds],
            parentIssueIdsOrKeys: [...jiraIssueFilters.parentIssueIdsOrKeys],
            jiraProjectId: sourceItemId,
            cloudId: jiraAccessibleResourcesView.cloudId,
            oauthCredentialsId: credentialsId,
            highEstimate: jiraSyncSettings?.estimationMapping?.estimationSettingDto?.highEstimate ?? 0,
            lowEstimate: jiraSyncSettings?.estimationMapping?.estimationSettingDto?.lowEstimate ?? 0,
            costCodeId: jiraSyncSettings?.costCodeId ?? null,
            issueCreatedDate: jiraIssueFilters?.issueCreatedDate,
            syncJiraWorklog: !!jiraSyncSettings?.syncJiraWorklog,
            syncTimesheetWorklog: !!jiraSyncSettings?.syncTimesheetWorklog,
            taskGroupHierarchy: jiraSyncSettings?.taskGroupHierarchy ?? TaskGroupHierarchy.GroupFlatLevel,
            rankIssues: !!jiraSyncSettings?.rankIssues,
            captureSprints: !!jiraSyncSettings?.captureSprints,
            estimationType: jiraSyncSettings?.estimationMapping?.estimationType ?? EstimationType.RemainingTime,
            storyPointSchemeActions:
              jiraSyncSettings?.estimationMapping.estimationSettingDto?.action ?? StoryPointSchemeActions.CreateNew,
            storyPointSchemeName:
              jiraSyncSettings?.estimationMapping.estimationSettingDto?.storyPointSchemeDto?.displayName ?? '',
            existingSchemeId: jiraSyncSettings?.estimationMapping.estimationSettingDto?.storyPointSchemeDto?.id ?? null,
            storyPoints: jiraSyncSettings?.estimationMapping.estimationSettingDto?.storyPointSchemeDto?.storyPoints
              ? convertStoryPointsToString(
                  jiraSyncSettings?.estimationMapping.estimationSettingDto.storyPointSchemeDto?.storyPoints,
                )
              : null,
            selectedExistingSchemeToCopy: null,
            taskPushSetting: jiraSyncSettings?.taskPushSetting ?? TaskPushSetting.Off,
          });
          setJiraIssueFilters(jiraIssueFilters);
          setJiraFieldMappings(jiraFieldMappings);
          setOAuthCredentialsId(credentialsId);
          setJiraProjectId(sourceItemId);
          setJiraCloudId(jiraAccessibleResourcesView.cloudId);
          setJiraSyncSettings(jiraSyncSettings);
          setCanModifyCostCode(!jiraSyncSettings?.costCodeId);
          setCanModifyStoryPoints(
            isEditMode && jiraSyncSettings?.estimationMapping?.estimationType === EstimationType.RemainingTime,
          );
          setSelectedSchemeActionDisplayName(currentSchemeDisplayName);
          setCanSelectLowerHierarchicalLevel({
            isFlatListDisabled:
              isEditMode && jiraSyncSettings?.taskGroupHierarchy !== TaskGroupHierarchy.GroupFlatLevel,
            isEpicBasedDisabled:
              isEditMode && jiraSyncSettings?.taskGroupHierarchy === TaskGroupHierarchy.GroupByParentAncestry,
          });
        },
        onFinish: () => {
          setIsLoadingContent(false);
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            setFormError(errors[0].title);
          }
        },
      }),
    );
  }, [
    externalIntegrationId,
    setIsLoadingContent,
    organizationId,
    workspaceId,
    dispatch,
    setInitialValuesForAnalyticsOnly,
    setCanModifyCostCode,
    setCanModifyStoryPoints,
    isEditMode,
    setSelectedSchemeActionDisplayName,
    currentSchemeDisplayName,
    setCanSelectLowerHierarchicalLevel,
    setFormError,
  ]);

  const updateOauthCredentials = useCallback(() => {
    if (!shouldUpdateOauthCredentials) {
      return;
    }

    const jiraFieldMappingSubmitFn = JiraExternalIntegrationDao.update(
      { organizationId, workspaceId, externalIntegrationId },
      { oauthCredentialsId: Number(oauthCredentialsIdFromReturnUrl) },
    );

    const { uuid } = dispatch(jiraFieldMappingSubmitFn);
    setIsLoadingContent(true);

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => {
          setFormError(undefined);
          fetchExternalIntegrationDetails();
        },
        onError: ({ errors }) => {
          if (errors[0]) {
            const { code } = errors[0];
            setCredentialsError(getSyncErrorMessage(code));
          }
        },
        onFinish: () => {
          setIsLoadingContent(false);
        },
      }),
    );
  }, [
    dispatch,
    organizationId,
    workspaceId,
    externalIntegrationId,
    oauthCredentialsIdFromReturnUrl,
    setIsLoadingContent,
    setFormError,
    shouldUpdateOauthCredentials,
    setCredentialsError,
    fetchExternalIntegrationDetails,
  ]);

  const fetchData = useCallback(() => {
    if (shouldUpdateOauthCredentials) {
      updateOauthCredentials();
    } else {
      fetchExternalIntegrationDetails();
    }
  }, [fetchExternalIntegrationDetails, shouldUpdateOauthCredentials, updateOauthCredentials]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!isEditMode) {
      return;
    }
    setAddedJiraFieldMappingRowItems(
      populateJiraToLpRows({
        fieldMappings: jiraFieldMappings,
        jiraProjectMappingFieldData,
      }),
    );
    setAddedLpFieldMappingRowItems(
      populateLpToJiraRows({
        fieldMappings: jiraFieldMappings,
        lpCustomFields: customLpFields,
      }),
    );
  }, [
    jiraFieldMappings,
    jiraProjectMappingFieldData,
    setAddedJiraFieldMappingRowItems,
    customLpFields,
    setAddedLpFieldMappingRowItems,
    setInitialValuesForAnalyticsOnly,
    isEditMode,
  ]);

  return (
    <>
      <Formik
        validationSchema={() =>
          validateActiveTab(activeTab, existingSchemeNames, costCodeRequired, selectedSchemeActionDisplayName)
        }
        onSubmit={submitHandler}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={getInitialValues({
          jiraIssueFilters,
          externalIntegrationFieldMappings: jiraFieldMappings,
          jiraSyncSettings,
          jiraProjectId,
          jiraCloudId,
          oAuthCredentialsId,
          defaultCostCode,
          storyPointScheme: storyPointSchemeOwner,
        })}
        enableReinitialize
      >
        <LpTabbedModalAsLpFormikForm
          className="jira-project-modal"
          onClose={handleCloseButtonClick}
          {...jiraProjectModalContent}
        />
      </Formik>
      {showConfirmCloseModal && (
        <BaseConfirmModal
          content={<span>Closing this window will cancel the integration setup and discard all changes.</span>}
          onClose={() => setShowConfirmCloseModal(false)}
          onConfirm={() => handlePanelClose({})}
          confirmButtonText="Leave"
        />
      )}
    </>
  );
};

const JiraModal = ({
  setShowJiraProjectModal,
  packageId,
  itemId,
  integrationId,
  canModify = true,
  hasFieldMappingWarning = false,
  setShouldActivatePushToJiraButton,
}: JiraIntegrationModalProps) => (
  <JiraProjectModalProvider
    setShowJiraProjectModal={setShowJiraProjectModal}
    packageId={packageId}
    itemId={itemId}
    integrationId={integrationId}
    canModify={canModify}
    hasFieldMappingWarning={hasFieldMappingWarning}
    setShouldActivatePushToJiraButton={setShouldActivatePushToJiraButton}
  >
    <JiraProjectModal />
  </JiraProjectModalProvider>
);

export default JiraModal;
