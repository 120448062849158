import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { GroupType } from 'daos/enums';
import { GroupDao } from 'daos/group';
import { Group, OrganizationUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { GroupAvatarDisplay } from 'features/common/avatars/group_avatar';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { LpIcon, userFriendSolid, peopleGroupSolid, usersSolid } from 'features/common/lp_icon';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { getAllCurrentWorkspaceWorkTeams } from 'state/entities/selectors/group';
import { getItemWorkGroupsByGroupIdForGroupIds } from 'state/entities/selectors/item_work_groups';
import {
  getCurrentWorkspaceNonGuestActiveAndDisconnectedOrgUsersAndGroups,
  getCurrentWorkspaceNonGuestActiveOrgUsersAndGroups,
  getCurrentWorkspaceUserDisconnectedByOrgUserIdMap,
} from 'state/entities/selectors/user';
import { getOrgUsersForGroupId } from 'state/entities/selectors/workspace_user_group';
import { gray600, slate600 } from 'style/variables';

const anyoneOption = {
  key: 'anyone',
  search: 'anyone',
  text: (
    <>
      <LpIcon className="anyone-icon" icon={userFriendSolid} color={slate600} /> Anyone
    </>
  ),
  value: JSON.stringify('anyone'),
};

const unassignedOption = {
  key: 'unassigned',
  search: 'unassigned',
  text: <>Unassigned</>,
  value: JSON.stringify('unassigned'),
};

const mapOrgUserToOption = (orgUser: OrganizationUser, isDisconnected?: boolean) => {
  const orgUserResource = OrganizationUserDao.id(orgUser);
  const serializedOrgUser = JSON.stringify(orgUserResource);

  return {
    key: serializedOrgUser,
    search: orgUser.username.toLowerCase(),
    text: (
      <div className="dropdown-option-row">
        <UserAvatar marginRight orgUserId={orgUser.id} size={AvatarSize.ExtraSmall_1_2} />
        <div
          className={classNames(isDisconnected ? 'dropdown-option-row__disconnected' : 'dropdown-option-row__active')}
        >
          {orgUser.username}
        </div>
      </div>
    ),
    value: serializedOrgUser,
  };
};

const mapGroupToOption = (group: Group) => {
  const groupResource = GroupDao.id(group);
  const serializedGroup = JSON.stringify(groupResource);

  return {
    key: serializedGroup,
    search: group.name.toLowerCase(),
    text: (
      <>
        <GroupAvatarDisplay color={group.color} icon={group.icon} name={group.name} size={AvatarSize.ExtraSmall_1_2} />
        <div className="v2-dashboard-filter__assigned-filter--group-name">
          <span>
            {group.name} {group.archivedAt !== null && ' (archived)'}
          </span>
          <LpIcon icon={groupTypeIcon(group.groupType)} color={gray600} />
        </div>
      </>
    ),
    value: serializedGroup,
  };
};

const groupTypeIcon = (groupType: GroupType) => {
  return groupType === GroupType.Administrative ? usersSolid : peopleGroupSolid;
};

export const useMapOrgUsersAndGroupsToDropdownOptions = ({
  includeUnassigned,
  includeDisconnected,
}: { includeUnassigned?: boolean; includeDisconnected?: boolean } = {}) => {
  const { activeOrgUsers, groups } = useSelector((state) => {
    return includeDisconnected
      ? getCurrentWorkspaceNonGuestActiveAndDisconnectedOrgUsersAndGroups(state)
      : getCurrentWorkspaceNonGuestActiveOrgUsersAndGroups(state);
  });

  const isOrgUserWorkspaceUserDisconnected = useSelector(getCurrentWorkspaceUserDisconnectedByOrgUserIdMap);

  const { connectedUsers, disconnectedUsers } = activeOrgUsers.reduce(
    (acc: { connectedUsers: Array<OrganizationUser>; disconnectedUsers: Array<OrganizationUser> }, user) => {
      if (isOrgUserWorkspaceUserDisconnected[user.id]) {
        acc.disconnectedUsers.push(user);
      } else {
        acc.connectedUsers.push(user);
      }
      return acc;
    },
    { connectedUsers: [], disconnectedUsers: [] },
  );

  const sortUsersByName = (users: Array<OrganizationUser>) => {
    users.sort((a, b) => a.username.localeCompare(b.username));
  };

  sortUsersByName(connectedUsers);
  sortUsersByName(disconnectedUsers);

  const mapUserOptionsWithDisconnectedValue = (orgUser: OrganizationUser) => {
    const isDisconnected = isOrgUserWorkspaceUserDisconnected[orgUser.id];
    return mapOrgUserToOption(orgUser, isDisconnected);
  };

  const allWorkTeams = useSelector(getAllCurrentWorkspaceWorkTeams);

  const itemWorkGroups = useSelector((state) =>
    getItemWorkGroupsByGroupIdForGroupIds(
      state,
      allWorkTeams.map((team) => team.id),
    ),
  );

  const { activeWorkTeams, archivedInUseWorkTeams } = allWorkTeams.reduce(
    (acc: { activeWorkTeams: Array<Group>; archivedInUseWorkTeams: Array<Group> }, team) => {
      const archived = team.archivedAt !== null;
      if (archived) {
        if (itemWorkGroups.get(team.id)) {
          acc.archivedInUseWorkTeams.push(team);
        }
      } else {
        acc.activeWorkTeams.push(team);
      }
      return acc;
    },
    { activeWorkTeams: [], archivedInUseWorkTeams: [] },
  );

  const hasTeamBasedPlanningFeature = useHasFeature(FeatureFlag.teamBasedPlanning);
  const workTeams = [...activeWorkTeams, ...archivedInUseWorkTeams].sort((a, b) => a.name.localeCompare(b.name));

  const options = [
    anyoneOption,
    ...connectedUsers.map(mapUserOptionsWithDisconnectedValue),
    ...(hasTeamBasedPlanningFeature ? workTeams.map(mapGroupToOption) : []),
    ...groups.map(mapGroupToOption),
    ...disconnectedUsers.map(mapUserOptionsWithDisconnectedValue),
  ];

  if (includeUnassigned) {
    options.splice(1, 0, unassignedOption);
  }

  return options;
};

export const useMapOrgUsersForGroupIdToDropdownOptions = (groupId: number) => {
  const groupOrgUsers = useSelector((state) => (groupId ? getOrgUsersForGroupId(state, groupId) : undefined));

  if (groupOrgUsers) {
    return [anyoneOption, ...groupOrgUsers.map((group) => mapOrgUserToOption(group, false))];
  }

  return [];
};
