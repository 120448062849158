import useHashParams from 'hooks/use_hash_params';

export const useItemPanelParams = () => {
  const { panelId: itemPanelIdParam, panelSection: itemPanelSection } = useHashParams();

  const itemPanelIdNumber = Number(itemPanelIdParam);
  const itemPanelId = isNaN(itemPanelIdNumber) ? 0 : itemPanelIdNumber;

  return {
    itemPanelId,
    itemPanelSection,
  };
};
