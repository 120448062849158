import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { JiraAuthorizationDao } from 'daos/jira_authorization';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, exclamationTriangleSolid, plusSolid } from 'features/common/lp_icon';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { awaitRequestFinish } from 'lib/api';

import { JiraCredentialsCreateTable } from './jira_credentials_create_table';
import { JiraCredentialsEditTable } from './jira_credentials_edit_table';

import 'features/jira_project/modal/jira_project_modal.scss';

const JiraInstanceAndCredentialsSection = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const [showReplaceAuthModal, setShowReplaceAuthModal] = useState(false);

  const { isEditMode, packageId, jiraOAuthAccounts, jiraIntegrations, credentialsError, canModify } =
    useJiraProjectModalContext();

  const jiraOauthRequest = () => {
    const jiraOauthRequestFn = JiraAuthorizationDao.jiraOAuthRequest(
      { organizationId, workspaceId },
      { returnUrl: `${location.pathname}?packageId=${packageId}${location.hash}` },
    );
    const { uuid } = dispatch(jiraOauthRequestFn);

    dispatch(
      awaitRequestFinish<{ url: string }>(uuid, {
        onSuccess: ({ data }) => {
          window.location.href = data.url;
        },
      }),
    );
  };

  const handleOauthButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    jiraOauthRequest();
  };

  const handleReplaceAuthButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowReplaceAuthModal(true);
  };

  const handleReplaceAuth = () => {
    jiraOauthRequest();
  };

  const closeReplaceAuthModal = () => {
    setShowReplaceAuthModal(false);
  };

  return (
    <>
      {showReplaceAuthModal && (
        <BaseConfirmModal
          confirmButtonText="Replace"
          content={
            <>
              <p>
                This action will replace the current Jira authentication with yours on this project, attributing any
                subsequent changes to you.
              </p>
              <p>Note that you must have access to the same project in Jira to which this project is connected.</p>
            </>
          }
          onClose={closeReplaceAuthModal}
          onConfirm={handleReplaceAuth}
        />
      )}
      <JiraProjectModalInfo
        testId="jiraProjectModalCredentials"
        title="Instance & Credentials"
        helperText={
          !isEditMode &&
          'Click the + New Instance button to connect to a new Jira Instance or choose from the list of available instances.'
        }
        button={
          isEditMode ? (
            <Button
              className="jira-project-modal__content-info-helper-button-red"
              onClick={handleReplaceAuthButtonClick}
              disabled={!canModify}
            >
              Replace Authentication
            </Button>
          ) : (
            <Button className="jira-project-modal__content-info-helper-button" onClick={handleOauthButtonClick} primary>
              <LpIcon icon={plusSolid} />
              New Instance
            </Button>
          )
        }
      />
      <JiraProjectTable>
        {isEditMode ? (
          <JiraCredentialsEditTable />
        ) : (
          <JiraCredentialsCreateTable jiraIntegrations={jiraIntegrations} jiraOAuthAccounts={jiraOAuthAccounts} />
        )}
      </JiraProjectTable>
      {credentialsError && (
        <span className="jira-project-modal__content-info-credentials-error">
          <LpIcon icon={exclamationTriangleSolid} />
          <span>{credentialsError}</span>
        </span>
      )}
    </>
  );
};

export default JiraInstanceAndCredentialsSection;
