// heavily inspired by https://github.com/erikras/react-redux-universal-hot-example/blob/master/src/redux/create.js
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import { API_REQUEST, AWAIT_REQUEST_FINISH } from 'lib/api/types';
import bugsnagClient from 'lib/bugsnag';
import { rootReducer, RootState } from 'state/root_reducer';

import sagas from './sagas';

const sagaMiddleware: SagaMiddleware<RootState> = createSagaMiddleware({
  onError(error: Error) {
    bugsnagClient?.notify(error);
  },
});

export default function createStore(initialState?: RootState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: location.origin.includes('localhost'),
    middleware: (defaultMiddleware) =>
      defaultMiddleware({
        serializableCheck: {
          // These actions are flagged by the built-in serializability middleware
          // See: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
          ignoredActions: [API_REQUEST, AWAIT_REQUEST_FINISH],
        },
      }).concat(sagaMiddleware),
  });

  sagaMiddleware.run(sagas);

  return store;
}
