import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { UserDao } from 'daos/user';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { resetRootState } from 'state/root_actions';

const REDIRECT_TO_QUERY_PARAM = 'redirectTo';

interface LogoutProps {
  setSelectedOrgId: Dispatch<SetStateAction<number | undefined>>;
  setSelectedWsId: Dispatch<SetStateAction<number | undefined>>;
}

export const Logout = ({ setSelectedOrgId, setSelectedWsId }: LogoutProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const queryStringParams = new URLSearchParams(location.search);
  const redirectTo = queryStringParams.get(REDIRECT_TO_QUERY_PARAM);

  const [fetchComplete, setFetchComplete] = useState(false);

  useEffect(() => {
    dispatch(resetRootState());
    setSelectedOrgId(undefined);
    setSelectedWsId(undefined);
  }, [dispatch, setSelectedOrgId, setSelectedWsId]);

  useEffect(() => {
    if (!fetchComplete) {
      const { uuid } = dispatch(UserDao.logout());

      dispatch(
        awaitRequestFinish(uuid, {
          onSuccess: () => {
            setFetchComplete(true);
          },
        }),
      );
    }
  }, [dispatch, fetchComplete, history]);

  useEffect(() => {
    if (fetchComplete) {
      history.replace(redirectTo ? redirectTo : frontend.login.url({}));
    }
  }, [fetchComplete, history, redirectTo]);

  if (!fetchComplete) {
    return <LpOverlayLoader />;
  }

  return null;
};
