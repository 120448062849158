import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { EmailConfirmationDao } from 'daos/email_confirmation';
import { EmailConfirmationError } from 'features/authentication/authenticated/email_confirmation_error';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { clearApiError, setApiError } from 'features/errors/slice';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';

interface EmailConfirmationProps {
  token: string;
}

export const EmailConfirmation = ({ token }: EmailConfirmationProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [fetchComplete, setFetchComplete] = useState(false);
  const [hasConfirmationError, setHasConfirmationError] = useState(false);

  const newEmail = useQueryParams().newEmail;

  useEffect(() => {
    return () => {
      dispatch(clearApiError());
    };
  }, [dispatch]);

  useEffect(() => {
    setFetchComplete(false);
    const { uuid } = dispatch(EmailConfirmationDao.verifyConfirmation({ token }));

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => {
          setHasConfirmationError(false);
          if (newEmail) {
            history.push(
              frontend.logout.url(
                {},
                { query: { redirectTo: frontend.login.url({}, { query: { newEmail: 'true' } }) } },
              ),
            );
          } else {
            history.push('/');
          }
        },
        onFinish: () => {
          setFetchComplete(true);
        },
        onError: ({ errors }) => {
          setHasConfirmationError(true);
          if (errors[0]) {
            setApiError(errors[0]);
          }
        },
      }),
    );
  }, [newEmail, dispatch, history, token]);

  if (!fetchComplete) {
    return <LpOverlayLoader />;
  }

  return hasConfirmationError ? <EmailConfirmationError /> : null;
};
