import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { LocationFilterSettings } from 'daos/types';
import { LpFormikDropdown } from 'features/common/forms/formik/dropdown';
import { useGetItemScopeForWidget } from 'features/dashboards_v2/widget/use_get_item_for_scope';
import { WidgetSettingsV2Field } from 'features/dashboards_v2/widget_settings/field';
import { getLinkToNoteOptionsForItemType } from 'features/dashboards_v2/widget_settings/selectors';
import { getItemForId } from 'state/entities/selectors/item';

export const LinkToNoteDropdown = ({ widget }: { widget: Widget }) => {
  const { getFieldProps } = useFormikContext<LocationFilterSettings>();
  const locationFilterItemId = getFieldProps<string | null>('locationFilterItemId').value;

  const widgetScopeItem = useGetItemScopeForWidget(widget);
  const formScopeItem = useSelector((state) =>
    locationFilterItemId ? getItemForId(state, Number(locationFilterItemId)) : undefined,
  );

  const scopeItemType = formScopeItem?.itemType ?? widgetScopeItem?.itemType;

  const dropdownOptions = useSelector((state) =>
    scopeItemType ? getLinkToNoteOptionsForItemType(state, scopeItemType) : [],
  );

  return (
    <WidgetSettingsV2Field name="Link To">
      <LpFormikDropdown
        name="customFieldId"
        disabled={scopeItemType === ItemType.WORKSPACE_ROOTS}
        options={dropdownOptions}
        selection
        placeholder="Please select a Note"
      />
    </WidgetSettingsV2Field>
  );
};
