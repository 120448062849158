import { noop } from 'lodash';
import { useCallback, useState, useEffect } from 'react';

export const useDirtyInputUpdate = ({
  onChangeCallback = noop,
  onClear = noop,
  initialValue,
}: {
  onChangeCallback: (_: string) => void;
  onClear?: () => void;
  initialValue?: string;
}) => {
  const [inputValue, setInputValue] = useState<string>(initialValue ?? '');
  const [isDirty, setIsDirty] = useState<boolean>(!!initialValue);

  const setDirtyBasedOnSearchTerm = useCallback((searchTerm: string): void => {
    if (searchTerm.length > 0) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, []);

  const onChange = useCallback(
    (_: React.SyntheticEvent, { value }: { value: string }) => {
      setInputValue(value);
      setDirtyBasedOnSearchTerm(value);
      onChangeCallback(value);
    },
    [onChangeCallback, setDirtyBasedOnSearchTerm],
  );

  const clearInput = useCallback(() => {
    setInputValue('');
    setDirtyBasedOnSearchTerm('');
    onChangeCallback('');
    onClear();
  }, [onClear, onChangeCallback, setDirtyBasedOnSearchTerm]);

  useEffect(() => {
    if (initialValue === '') {
      setInputValue('');
      setIsDirty(false);
    }
  }, [initialValue]);

  return {
    isDirty,
    inputValue,
    setInputValue,
    onChange,
    clearInput,
    setIsDirty,
  };
};
