import { SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ItemType, PackageStatus, Permission } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { OrderItemFetch } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { filterAncestorPackageStatus, filterAnd, filterItemType, filterOr, filterPackageStatus } from 'daos/shared';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { hasAccess } from 'hooks/use_has_access';
import { awaitRequestFinish } from 'lib/api';

const filterProjectsByPackage = (packages: ReadonlyArray<Item>, data: ReadonlyArray<Item>) => {
  const projectsByPackage: Record<number, Array<Item>> = {};

  packages.forEach((currentPackage) => {
    projectsByPackage[currentPackage.id] = data.filter((item) => item?.parent?.id === currentPackage.id);
  });

  return projectsByPackage;
};

export const useFetchTemplatePackageAndProjects = ({
  setPackages,
  setProjectsByPackage,
}: {
  setPackages: (value: SetStateAction<ReadonlyArray<Item>>) => void;
  setProjectsByPackage?: (value: SetStateAction<Record<string, ReadonlyArray<Item>>>) => void;
}) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const dispatch = useDispatch();

  const filterPackages = (data: ReadonlyArray<Item>) =>
    data.filter((item) => item.itemType === ItemType.PACKAGES && hasAccess(Permission.OBSERVE, item));

  const fetchPackages = useCallback(() => {
    const { uuid } = dispatch(
      ItemDao.fetchAll(
        { organizationId, workspaceId },
        {
          filter: filterAnd(
            filterOr(filterPackageStatus(PackageStatus.TEMPLATE), filterAncestorPackageStatus(PackageStatus.TEMPLATE)),
            filterItemType([ItemType.PACKAGES, ItemType.PROJECTS]),
          ),
          query: {
            order: OrderItemFetch.GlobalPriority,
          },
        },
      ),
    );

    dispatch(
      awaitRequestFinish<ReadonlyArray<Item>>(uuid, {
        onSuccess: ({ data }) => {
          const filteredPackages = filterPackages(data);
          setPackages(filteredPackages);

          setProjectsByPackage?.(filterProjectsByPackage(filteredPackages, data));
        },
      }),
    );
  }, [dispatch, organizationId, setPackages, setProjectsByPackage, workspaceId]);

  return { fetchPackages };
};
