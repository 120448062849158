import { GuestAssignee, GuestBoardTask, GuestTasksByStatusId } from 'features/common/task_board/guest/types';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';

import { GuestTaskBoardWidgetData, GuestWidgetTask } from './types';

export const getTaskAssignees = (orgUsersById: GuestTaskBoardWidgetData['orgUsersById'], task: GuestWidgetTask) => {
  return task.assignees.reduce((acc: Array<GuestAssignee>, orgUserId) => {
    const ancestor = orgUsersById[orgUserId];

    if (ancestor) {
      acc.push(ancestor);
    }
    return acc;
  }, []);
};

export const getTaskAncestors = (ancestorsById: GuestTaskBoardWidgetData['ancestorsById'], task: GuestWidgetTask) => {
  return task.ancestors.reduce((acc: Array<{ id: number; name: string }>, ancestorId) => {
    const ancestor = ancestorsById[ancestorId];

    if (ancestor) {
      acc.push({ id: ancestor.id, name: ancestor.name });
    }
    return acc;
  }, []);
};

export const getGuestBoardTasks = (
  guestWidgetTasks: ReadonlyArray<GuestWidgetTask>,
  data: GuestTaskBoardWidgetData,
): Array<GuestBoardTask> => {
  return guestWidgetTasks.map((widgetTask) => {
    return {
      ...widgetTask,
      assignees: getTaskAssignees(data.orgUsersById, widgetTask),
      ancestry: getTaskAncestors(data.ancestorsById, widgetTask),
      storyPointsValue: widgetTask.storyPointsValue,
      totalWorkExpected: formatAsHours(convertSecondsToHours(widgetTask.totalWorkExpected)),
    };
  });
};

export const getGuestTaskBoardTasksByStatusId = (data: GuestTaskBoardWidgetData) => {
  const entries = Object.entries(data.tasksByStatusId);
  const tasksByStatusId: GuestTasksByStatusId = {};

  entries.forEach(([taskStatusIdKey, widgetTasks]) => {
    tasksByStatusId[Number(taskStatusIdKey)] = getGuestBoardTasks(widgetTasks, data);
  });

  return tasksByStatusId;
};
