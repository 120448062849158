import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { customColumnDefinitions } from 'containers/shared/custom_column';
import { Columns, StandardColumns } from 'containers/shared/custom_column/enum';
import { TaskStatusIcon } from 'containers/shared/task_icon_maker';
import { SchedulingType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { calculatorSolid, LpIcon } from 'features/common/lp_icon';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { getMetricsTallyCheckboxValue } from 'features/dashboards_v2/widget/widgets/metrics_tally/common';
import {
  MetricsTallyWidgetData,
  MetricsTallyWidgetRange,
  MetricsTallyWidgetRow,
  MetricsTallyWidgetTaskStatusData,
} from 'features/dashboards_v2/widget/widgets/metrics_tally/types';
import { formatAsHoursWithNoRounding } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';
import { getTaskStatusesById } from 'state/entities/selectors/task_status';

import './index.scss';

export function MetricsTallyRowContent({ row }: { row: MetricsTallyWidgetRow }) {
  switch (row.columnOption.column) {
    case StandardColumns.ClippedEffort:
    case StandardColumns.Logged:
    case StandardColumns.OnHoldHours:
    case StandardColumns.RemainingWorkExpected:
    case StandardColumns.TimesheetScheduled:
    case StandardColumns.TotalWorkExpected:
    case StandardColumns.TotalWorkScheduled:
    case StandardColumns.UncertainWork:
    case StandardColumns.UnusedEffortExpected:
      return <>{formatAsHoursWithNoRounding(convertSecondsToHours((row.data as number | null) ?? 0))}</>;

    case StandardColumns.WorkLimit:
      return <>{formatSecondsAsHoursOrNotAvailable(row.data as number | null)}</>;

    case StandardColumns.WorkLimitDelta:
      return <>{formatSecondsAsHoursOrNotAvailable(row.data as number | null)}</>;

    case StandardColumns.PercentComplete:
    case StandardColumns.PercentTasksComplete:
      return <>{Math.round(row.data as number)}%</>;

    case StandardColumns.ProjectCountScheduled:
    case StandardColumns.ProjectCountDone:
    case StandardColumns.ProjectCountOnHold:
    case StandardColumns.ProjectCountTotal:
    case StandardColumns.TaskCountActive:
    case StandardColumns.TaskCountDone:
    case StandardColumns.TaskCountOnHold:
    case StandardColumns.TaskCountTotal:
      return <>{row.data as number}</>;

    case StandardColumns.RemainingWorkRange:
    case StandardColumns.TotalWorkRange: {
      const range = row.data as MetricsTallyWidgetRange;
      const expectedSecondsAsHours = formatAsHoursWithNoRounding(convertSecondsToHours(range.expectedSeconds));
      const lowSecondAsHours = formatAsHoursWithNoRounding(convertSecondsToHours(range.lowSeconds));
      const highSecondsAsHours = formatAsHoursWithNoRounding(convertSecondsToHours(range.highSeconds));
      return (
        <>
          {expectedSecondsAsHours}{' '}
          <span className="widget-metrics-tally__body-row-content--range">
            ({lowSecondAsHours}&nbsp;-&nbsp;{highSecondsAsHours})
          </span>
        </>
      );
    }
    case StandardColumns.TaskStatus: {
      const taskStatusData = row.data as MetricsTallyWidgetTaskStatusData;
      const expectedSecondsAsHours = formatAsHoursWithNoRounding(
        convertSecondsToHours(taskStatusData.remaining.expectedSeconds),
      );
      return (
        <>
          {taskStatusData.taskCount} tasks ({expectedSecondsAsHours})
        </>
      );
    }
    default:
      return null;
  }
}

export const MetricsTallyRowAside = ({
  taskStatusId,
  column,
}: {
  taskStatusId: number | null;

  // Investigate: Why are there FrontendColumnOptions in this list if these icons are only
  // used in the properties_summary widget? (...which supports only server-side columns)
  column: Columns;
}) => {
  const taskStatusesById = useSelector(getTaskStatusesById);
  const taskStatus = taskStatusesById[taskStatusId ?? 0];
  const archived = !!taskStatus?.archived;
  const displayName = taskStatusId
    ? `${taskStatusesById[taskStatusId]?.name}${archived ? ' (archived)' : ''}`
    : (customColumnDefinitions[column]?.displayText ?? column);
  const displayIcon = taskStatus ? (
    <TaskStatusIcon
      hasToolTip={false}
      schedulingType={taskStatus.schedulingType ?? SchedulingType.Scheduled}
      color={`#${taskStatus.color}`}
    />
  ) : (
    <LpIcon icon={calculatorSolid} />
  );
  return (
    <div className="widget-metrics-tally__body-row-aside">
      {displayName} {displayIcon}
    </div>
  );
};

export const MetricsTallyWidget = ({ widget }: { widget: WidgetWithData }) => {
  const widgetData = widget.data as MetricsTallyWidgetData;

  return (
    <RenderedWidget
      body={
        <div className="widget-metrics-tally__body">
          {widgetData.rows.map((row) => {
            const key = JSON.stringify(getMetricsTallyCheckboxValue(row.columnOption));
            return (
              <Fragment key={key}>
                <MetricsTallyRowAside taskStatusId={row.columnOption.taskStatusId} column={row.columnOption.column} />
                <div className="widget-metrics-tally__body-row-content">
                  <MetricsTallyRowContent row={row} />
                </div>
              </Fragment>
            );
          })}
        </div>
      }
    />
  );
};

function formatSecondsAsHoursOrNotAvailable(value: number | null): string {
  return value === null ? 'N/A' : formatAsHoursWithNoRounding(convertSecondsToHours(value));
}
