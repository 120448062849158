import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStateExtraReducer } from 'state/root_actions';

interface DuplicateState {
  [itemId: number]: boolean;
}

export const initialState: DuplicateState = {};

const duplicateSlice = createSlice({
  name: 'duplicate',
  initialState,
  reducers: {
    setItemDuplicationStatus: (
      state,
      action: PayloadAction<{ itemIds: ReadonlyArray<number>; isDuplicating: boolean }>,
    ) => {
      action.payload.itemIds.forEach((itemId) => (state[itemId] = action.payload.isDuplicating));
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { setItemDuplicationStatus } = duplicateSlice.actions;

export default duplicateSlice.reducer;
