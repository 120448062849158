import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { MAX_EMAIL_LENGTH } from 'lib/constants';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { getEmailRegexValidator } from 'state/entities/selectors/system_settings';

export const useYupEmailValidator = () => {
  const environmentEmailRegexString = useSelector(getEmailRegexValidator);
  const rfc5322RegexPattern = new RegExp(environmentEmailRegexString);

  return yup
    .string()
    .trim()
    .max(MAX_EMAIL_LENGTH)
    .email(lpErrorText.email)
    .matches(rfc5322RegexPattern, lpErrorText.email)
    .required(lpErrorText.email);
};
