import { ItemType } from 'daos/enums';
import { ItemTypesDisplaySingular } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { readonlyRecord, ReadonlyRecord } from 'lib/readonly_record';

function itemTypeDisplayName(item: Item) {
  return itemTypeToSingularDisplay[item.itemType];
}

export function itemHasDefaultName(item: Item) {
  return item.name === defaultItemName(item.itemType);
}

export function getItemNameForItem(item: Item) {
  return item.itemType === ItemType.WORKSPACE_ROOTS
    ? ItemTypesDisplaySingular.WorkspaceRoot
    : (item.name ?? 'No Description');
}

const DEFAULT_ITEM_NAME_PREFIX = 'Untitled';
export const itemTypeToSingularDisplay = readonlyRecord<ItemType, ItemTypesDisplaySingular>({
  [ItemType.ASSIGNMENTS]: ItemTypesDisplaySingular.Assignment,
  [ItemType.PROJECTS]: ItemTypesDisplaySingular.Project,
  [ItemType.TASKS]: ItemTypesDisplaySingular.Task,
  [ItemType.FOLDERS]: ItemTypesDisplaySingular.Folder,
  [ItemType.WORKSPACE_ROOTS]: ItemTypesDisplaySingular.WorkspaceRoot,
  [ItemType.PACKAGES]: ItemTypesDisplaySingular.Package,
});

export const singularDisplayToItemType = readonlyRecord<ItemTypesDisplaySingular, ItemType>({
  [ItemTypesDisplaySingular.Assignment]: ItemType.ASSIGNMENTS,
  [ItemTypesDisplaySingular.Project]: ItemType.PROJECTS,
  [ItemTypesDisplaySingular.Task]: ItemType.TASKS,
  [ItemTypesDisplaySingular.Folder]: ItemType.FOLDERS,
  [ItemTypesDisplaySingular.WorkspaceRoot]: ItemType.WORKSPACE_ROOTS,
  [ItemTypesDisplaySingular.Package]: ItemType.PACKAGES,
});

export function defaultItemName(itemType: ItemType): string {
  const itemName = itemTypeToSingularDisplay[itemType];
  return `${DEFAULT_ITEM_NAME_PREFIX} ${itemName}`;
}

export const itemTypeToPluralDisplayName: ReadonlyRecord<ItemType, string> = {
  [ItemType.ASSIGNMENTS]: 'Assignments',
  [ItemType.FOLDERS]: 'Sub-Folders',
  [ItemType.PACKAGES]: 'Packages',
  [ItemType.PROJECTS]: 'Projects',
  [ItemType.TASKS]: 'Tasks',
  [ItemType.WORKSPACE_ROOTS]: 'Workspace Roots',
};

export default itemTypeDisplayName;
