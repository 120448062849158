import { uniqBy } from 'lodash';
import { createSelector } from 'reselect';

import { PlanFamily } from 'daos/enums';
import { Plan } from 'daos/model_types';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import { EntityLookupById } from 'state/entities/types';
import { RootState } from 'state/root_reducer';

const PlanFamilySortOrder: Readonly<Record<PlanFamily, number>> = {
  [PlanFamily.Free]: 0,
  [PlanFamily.UltimateTrial]: 1,
  [PlanFamily.Essentials]: 2,
  [PlanFamily.Professional]: 3,
  [PlanFamily.Ultimate]: 4,
  [PlanFamily.Other]: 5,
};

const getPlansById = (state: RootState): EntityLookupById<Plan> => state.entities.plans;
export const getPlanForId = (state: RootState, id: number) => getPlansById(state)[id];

const getPublicPlans = createSelector(getPlansById, (plansById) =>
  Object.values(plansById).filter((plan) => plan.publicPlan && plan.legacyPlan !== true),
);

export const getActivePlans = createSelector(getPublicPlans, (publicPlans) =>
  Object.values(publicPlans).filter((plan) => plan.legacyPlan !== true),
);

export const getCurrentOrganizationPlan = (state: RootState) => {
  const currentOrganization = getCurrentOrganization(state);
  return currentOrganization ? getPlanForId(state, currentOrganization.planId) : undefined;
};

const getActivePlansOrderedByFamily = createSelector(getActivePlans, (publicPlans) =>
  Object.values(publicPlans).sort((a, b) => PlanFamilySortOrder[a.family] - PlanFamilySortOrder[b.family]),
);

export const getActivePublicPlanFamilies = createSelector(getActivePlansOrderedByFamily, (sortedPublicPlans) =>
  uniqBy(sortedPublicPlans, 'family').map((plan) => plan.family),
);
