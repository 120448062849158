import { PackageStatusIcon } from 'containers/shared/item_icon';
import { TaskStatusIcon } from 'containers/shared/task_icon_maker';
import { WidgetOnClickViewType, UserType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { LpIcon, folderClosedSolid, folderClosedRegular } from 'features/common/lp_icon';
import { ItemStatus, PeopleRowUserData } from 'features/dashboards_v2/widget/widgets/reports/types';
import { frontend } from 'lib/urls';

export const getItemIconForStatus = (itemStatus: ItemStatus, isPlanned: boolean) => {
  const { color, folderStatus, packageStatus, taskSchedulingType } = itemStatus;
  const size = '1x';
  const iconHexColor = color ? `#${color}` : '';

  if (taskSchedulingType) {
    return <TaskStatusIcon color={iconHexColor} schedulingType={taskSchedulingType} isPlanned={isPlanned} />;
  }

  if (folderStatus) {
    return <LpIcon color={iconHexColor} icon={isPlanned ? folderClosedRegular : folderClosedSolid} size={size} />;
  }

  if (packageStatus) {
    return (
      <PackageStatusIcon color={color ?? undefined} packageStatus={packageStatus} size={size} isPlanned={isPlanned} />
    );
  }
};

function getActiveMemberUrl(
  onClickView: WidgetOnClickViewType | null,
  params: { organizationId: string; workspaceId: string; userId: number },
) {
  switch (onClickView) {
    case WidgetOnClickViewType.Availability:
      return frontend.memberAvailability.url(params);
    case WidgetOnClickViewType.Board:
      return frontend.memberTaskBoard.url(params);
    case WidgetOnClickViewType.Profile:
      return frontend.member.url(params);
    case WidgetOnClickViewType.Timesheet:
      return frontend.memberTimesheet.url(params);
    case WidgetOnClickViewType.Workload:
      return frontend.memberWorkload.url(params);
    default:
      return '';
  }
}

function getActiveResourceUrl(
  onClickView: WidgetOnClickViewType | null,
  params: { organizationId: string; workspaceId: string; userId: number },
) {
  switch (onClickView) {
    case WidgetOnClickViewType.Availability:
      return frontend.resourceAvailability.url(params);
    case WidgetOnClickViewType.Board:
      return frontend.resourceTaskBoard.url(params);
    case WidgetOnClickViewType.Profile:
      return frontend.resource.url(params);
    case WidgetOnClickViewType.Timesheet:
      return frontend.resourceTimesheet.url(params);
    case WidgetOnClickViewType.Workload:
      return frontend.resourceWorkload.url(params);
    default:
      return '';
  }
}

export function getOnClickViewUrlForPeopleListWidget(widget: WidgetWithData, user: PeopleRowUserData) {
  const {
    organizationId,
    workspaceId,
    config: { onClickView },
  } = widget;

  if (onClickView === WidgetOnClickViewType.None) {
    return '';
  }

  const { disconnected, userType, userId } = user;

  const params = { organizationId, workspaceId, userId };

  switch (userType) {
    case UserType.Placeholder:
      return frontend.placeholder.url(params);

    case UserType.Resource: {
      if (disconnected) {
        return frontend.resource.url(params);
      }
      return getActiveResourceUrl(onClickView, params);
    }

    case UserType.Member: {
      if (disconnected) {
        return frontend.member.url(params);
      }

      return getActiveMemberUrl(onClickView, params);
    }
  }
}
