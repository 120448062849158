import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { User } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

enum ModelType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  USER = 'USER',
  USER_AUTH_SETTINGS = 'USER_AUTH_SETTINGS',
}

const { resource: loginResource } = defineModel({ apiType: 'logins', type: ModelType.LOGIN });
const { resource: logoutResource } = defineModel({ apiType: 'logouts', type: ModelType.LOGOUT });
const { updateBody, resource: userResource } = defineModel({ apiType: 'users', type: ModelType.USER });
const { resource: userAuthSettingsResource } = defineModel({
  apiType: 'userAuthSettings',
  type: ModelType.USER_AUTH_SETTINGS,
});

const { LOGIN } = loginResource;
const { LOGOUT } = logoutResource;
const { USER, resourceId } = userResource;
const { USER_AUTH_SETTINGS } = userAuthSettingsResource;

interface Includes {
  includeOrganization?: boolean;
  includeActiveBanner?: boolean;
  includeOrgAndWsUsers?: boolean;
  includeWsUsersAndWorkspaces?: boolean;
  includeMyInAppNotifications?: boolean;
}

const includes = ({
  includeOrganization,
  includeActiveBanner,
  includeOrgAndWsUsers,
  includeWsUsersAndWorkspaces,
  includeMyInAppNotifications,
}: Includes = {}) =>
  reduceIncludedOptions([
    includeOrganization && 'organizationUsers.organization',
    includeActiveBanner && 'activeBanners',
    includeOrgAndWsUsers && 'organizationUsers.organization,workspaceUsers',
    includeWsUsersAndWorkspaces && 'workspaceUsers.workspace',
    'dashboardGuests.dashboards,dashboardGuests.workspaces',
    includeMyInAppNotifications && 'myInAppNotifications',
  ]);

interface SingleUserParams {
  userId: number;
}

interface UserOptions {
  include?: Includes;
  type?: string;
  skipReduce?: boolean;
}

interface LoginProps {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface LoginOptionsProps {
  email: string;
  lastAccessedOrgId: number | null;
}

interface SsoLoginCheckProps {
  email: string;
  redirectLocation: string | undefined;
  redirectHash: string | undefined;
}

const fetchCurrent = ({ include, skipReduce }: UserOptions = {}) =>
  request(backend.userCurrent.url({}, { include: includes(include) }), USER, {
    meta: { skipReduce },
    method: HttpMethod.GET,
  });

const fetchCurrentUserAuthSettings = () =>
  request(backend.currentUserAuth.url({}), USER_AUTH_SETTINGS, { method: HttpMethod.GET });

const userUpdate = (params: SingleUserParams, user: Partial<User>) =>
  request(backend.user.url(params), USER, updateBody(params.userId, user));

const login = ({ email, password, rememberMe }: LoginProps) => {
  const data = new FormData();
  data.append('email', email);
  data.append('password', password);
  data.append('remember-me', `${rememberMe}`);

  return request(backend.login.url({}, { include: includes({ includeOrganization: true }) }), LOGIN, {
    body: data,
    method: HttpMethod.POST,
  });
};

const ssoLoginCheck = ({ email, redirectLocation, redirectHash }: SsoLoginCheckProps) => {
  return request(backend.loginSso.url({}, { query: { _ts: Date.now() } }), LOGIN, {
    body: JSON.stringify({
      email,
      redirectLocation,
      redirectHash,
    }),
    method: HttpMethod.POST,
  });
};

const loginOptions = ({ email, lastAccessedOrgId }: LoginOptionsProps) => {
  return request(backend.loginOptions.url({}), USER_AUTH_SETTINGS, {
    body: JSON.stringify({
      email,
      lastAccessedOrgId,
    }),
    method: HttpMethod.POST,
  });
};

const logout = () => request(backend.logout.url({}), LOGOUT, { method: HttpMethod.GET });

export const UserDao = {
  fetchCurrent,
  id: resourceId,
  login,
  loginOptions,
  ssoLoginCheck,
  logout,
  update: userUpdate,
  fetchCurrentUserAuthSettings,
} as const;
