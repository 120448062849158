import { filter, sortBy, thru } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { OrganizationUser } from 'daos/model_types';
import { groupRecordBy, readonlyArray } from 'lib/readonly_record';
import {
  getCurrentOrganizationUserForWorkspaceUser,
  getOrganizationUserInCurrentOrgForUserId,
  getWorkspaceUserById,
} from 'state/entities/selectors/user';
import { RootState } from 'state/root_reducer';

import { createCacheByFirstTwoParametersConfig, createCacheByIdConfig, getNumberArgument } from './shared';

const getWorkspaceUserGroupsById = (state: RootState) => state.entities.workspaceUserGroups;

export const getWsUserGroupForId = ({ entities }: RootState, wsUserGroupId: number) =>
  entities.workspaceUserGroups[wsUserGroupId];

export const getWsUserGroupForGroupIdAndWsUserId = createCachedSelector(
  (state: RootState, groupId: number) => getWsUserGroupsForGroupId(state, groupId),
  (_: RootState, __: number, wsUserId: number) => wsUserId,
  (wsUserGroups, wsUserID) => {
    return wsUserGroups.find((group) => group.workspaceUser?.id == wsUserID);
  },
)(createCacheByFirstTwoParametersConfig());

const getWsUserGroupsForGroupId = createCachedSelector(
  getWorkspaceUserGroupsById,
  getNumberArgument,
  (groupsById, groupId) => readonlyArray(filter(groupsById, (wsUserGroup) => wsUserGroup.group.id === groupId)),
)(createCacheByIdConfig());

export const getWsUserGroupsSortedByUsernameForGroupId = createCachedSelector(
  (state: RootState) => state,
  (state: RootState, groupId: number) => getWsUserGroupsForGroupId(state, groupId),
  (state, wsUserGroups) =>
    readonlyArray(
      sortBy(wsUserGroups, (wsUserGroup) =>
        thru(getWorkspaceUserById(state, wsUserGroup.workspaceUser.id), (wsUser) => {
          const userId = wsUser?.user.id;
          const orgUser = userId ? getOrganizationUserInCurrentOrgForUserId(state, userId) : undefined;
          return [orgUser?.username.toLowerCase(), wsUser?.id];
        }),
      ),
    ),
)(createCacheByIdConfig());

export const getWsUserGroupActiveUsersForGroupId = createCachedSelector(
  getWsUserGroupsForGroupId,
  (state) => state.entities.workspaceUsers,
  (wsUserGroups, wsUsersById) =>
    readonlyArray(
      wsUserGroups.filter((wsUserGroup) => {
        const wsUser = wsUsersById[wsUserGroup.workspaceUser.id];
        return wsUser?.disconnectedAt === null;
      }),
    ),
)(createCacheByIdConfig());

export const getOrgUsersForGroupId = createCachedSelector(
  getWsUserGroupsSortedByUsernameForGroupId,
  (state) => state.entities.workspaceUsers,
  (state) => state,
  (wsUserGroups, wsUsersById, state) =>
    readonlyArray(
      wsUserGroups.reduce((acc: Array<OrganizationUser>, wsUserGroup) => {
        const wsUser = wsUsersById[wsUserGroup.workspaceUser.id];

        const orgUser =
          wsUser?.disconnectedAt === null ? getCurrentOrganizationUserForWorkspaceUser(state, wsUser) : undefined;

        if (orgUser) {
          acc.push(orgUser);
        }

        return acc;
      }, []),
    ),
)(createCacheByIdConfig());

const getWsUserGroupsByWsUserId = createSelector(getWorkspaceUserGroupsById, (wsUserGroupsById) =>
  groupRecordBy(wsUserGroupsById, (e) => e.workspaceUser.id),
);

export const getWsUserGroupsForWsUserId = createCachedSelector(
  getWsUserGroupsByWsUserId,
  getNumberArgument,
  (wsUserGroupsByWsUserId, id) => wsUserGroupsByWsUserId[id],
)(createCacheByIdConfig());
