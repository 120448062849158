import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { ResetPasswordDao, ResetPassword as ResetPasswordModel } from 'daos/reset_password';
import { ClickHereToLogin } from 'features/authentication/unauthenticated/token/click_here_to_login';
import { SetPasswordForm } from 'features/authentication/unauthenticated/token/set_password/set_password_form';
import { LpMotionFadeInAndUp } from 'features/common/animated_divs';
import { LpHeader } from 'features/common/as_components';
import RecordNotFound from 'features/common/errors/record_not_found';
import RequestFailed from 'features/common/errors/request_failed';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { awaitRequestFinish } from 'lib/api';
import { ErrorCodes } from 'lib/api/types';

import 'features/authentication/unauthenticated/token/set_password/index.scss';

interface SetPasswordProps {
  token: string;
}

interface SetPasswordState {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly claimedAt: string | null;
  readonly expired: boolean;
  readonly id: number;
}

export const SetPassword = ({ token }: SetPasswordProps) => {
  const dispatch = useDispatch();

  const [tokenNotFound, setTokenNotFound] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [resetPassword, setResetPassword] = useState<SetPasswordState | undefined>(undefined);

  useEffect(() => {
    const { uuid } = dispatch(ResetPasswordDao.get({ token }));

    dispatch(
      awaitRequestFinish<ResetPasswordModel>(uuid, {
        onError: ({ errors }) => {
          if (errors[0]) {
            if (errors[0].code === ErrorCodes.RecordNotFound) {
              setTokenNotFound(true);
              return;
            } else {
              setUnknownError(true);
            }
          }
        },
        onSuccess: ({ data, entities }) => {
          const user = entities.users?.[data.user.id];

          const updatedState = {
            email: user?.email ?? '',
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            claimedAt: data.claimedAt,
            expired: data.expired,
            id: data.id,
          };

          setResetPassword(updatedState);
        },
      }),
    );
  }, [dispatch, token]);

  if (tokenNotFound) {
    return (
      <>
        <LpMotionFadeInAndUp className="set-password">
          <RecordNotFound className="set-password__initial-fetch-errors" recordType="reset password link" />
          <ClickHereToLogin />
        </LpMotionFadeInAndUp>

        <LpLegalTermsFooter />
      </>
    );
  }

  if (unknownError) {
    return (
      <>
        <LpMotionFadeInAndUp className="set-password">
          <RequestFailed />
          <ClickHereToLogin />
        </LpMotionFadeInAndUp>

        <LpLegalTermsFooter />
      </>
    );
  }

  return !resetPassword ? (
    <LpOverlayLoader />
  ) : (
    <>
      <LpMotionFadeInAndUp className="set-password">
        <div className="set-password__header-block">
          <LpHeader headerType="h1" className="set-password__header">{`Create Password`}</LpHeader>

          <strong className="set-password__sub-header-text">{"You're almost there!"}</strong>
        </div>
        <SetPasswordForm
          token={token}
          resetPasswordId={resetPassword.id}
          expired={resetPassword.expired}
          claimedAt={resetPassword.claimedAt}
        />
      </LpMotionFadeInAndUp>

      <LpLegalTermsFooter />
    </>
  );
};
