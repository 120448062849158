import { isNumber } from 'lodash';
import { SyntheticEvent, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import TaskIconMaker from 'containers/shared/task_icon_maker';
import { Item } from 'daos/model_types';
import { getCurrentWorkspaceId, getCurrentOrganizationId } from 'features/common/current/selectors';
import { EditorAddEditGridProps } from 'features/common/data_grid/types';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { updateTaskIdsIndexedByTaskStatusId } from 'features/common/task_board/slice';
import { frontend } from 'lib/urls';
import {
  getTaskStatusForId,
  getActiveCurrentWorkspaceTaskStatusesSortedByPriority,
} from 'state/entities/selectors/task_status';
import './task_status_dropdown.scss';

interface TaskStatusDropdownProps {
  disabled?: DropdownProps['disabled'];
  item?: Item;
  onChange: (event: SyntheticEvent<HTMLElement>, taskStatusId: number) => void;
  taskStatusId?: number;
  useOnGrid?: boolean;

  open?: boolean;
  onClose?: EditorAddEditGridProps['onClose'];
}

const TaskStatusDropdown = ({
  disabled,
  item,
  onChange,
  taskStatusId = 0,
  useOnGrid = false,
  open,
  onClose,
}: TaskStatusDropdownProps) => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);

  const activeTaskStatuses = useSelector(getActiveCurrentWorkspaceTaskStatusesSortedByPriority);
  const currentTaskStatus = useSelector((state) => getTaskStatusForId(state, taskStatusId));

  const dropdownOptions = useMemo(() => {
    const taskStatuses = currentTaskStatus?.archived ? [...activeTaskStatuses, currentTaskStatus] : activeTaskStatuses;

    const options: Array<DropdownItemProps> = taskStatuses.map((ts) => ({
      disabled: ts.archived,
      text: (
        <span>
          <TaskIconMaker taskStatusId={ts.id} hasToolTip={false} /> {ts.name}
        </span>
      ),
      value: ts.id,
      search: ts.name.toLocaleLowerCase(),
      key: ts.id,
    }));

    options.push({
      text: (
        <LpLink
          removeFromTabOrder={true}
          to={frontend.customizeTaskStatus.url({
            organizationId,
            workspaceId,
          })}
          className="task-status-dropdown__customize"
        >
          <em>Customize Task Status</em>
        </LpLink>
      ),
      search: 'customize task status',
      key: 'customize task status',
    });

    return options;
  }, [activeTaskStatuses, currentTaskStatus, organizationId, workspaceId]);

  const handleChange = (event: SyntheticEvent<HTMLElement, Event>, { value: taskStatusId }: DropdownProps) => {
    if (item?.taskStatus && isNumber(taskStatusId)) {
      dispatch(
        updateTaskIdsIndexedByTaskStatusId({
          taskId: item.id,
          sourceTaskStatusId: item.taskStatus.id,
          destinationTaskStatusId: taskStatusId,
        }),
      );
    }

    if (isNumber(taskStatusId)) {
      onChange(event, taskStatusId);
    }
  };

  const taskStatusDropdown = (
    <Dropdown
      className="task-status-dropdown"
      closeOnChange
      search={lpDropdownSearch}
      disabled={disabled}
      placeholder="None"
      onChange={handleChange}
      options={dropdownOptions}
      selectOnNavigation={false}
      selection
      value={taskStatusId}
      onClose={() => onClose?.()}
      open={open}
    />
  );

  return useOnGrid ? <PortalDropdown>{taskStatusDropdown}</PortalDropdown> : taskStatusDropdown;
};

export default TaskStatusDropdown;
