import { PackageStatus } from 'daos/enums';
import { FileFilter, ItemFilter, LibraryResourceFilter, WorkspaceUserFilter } from 'daos/filter_properties';
import {
  filterAnd,
  filterChildCustomField,
  filterContainsIgnoreCase,
  filterCustomField,
  filterEq,
  filterNull,
  filterOr,
  filterParentCustomField,
  filterWsUserCustomField,
} from 'daos/shared';
import { LibraryLocation } from 'features/library/types';

export enum CustomOptions {
  CreatedBy = 'createdBy',
  Description = 'description',
  CustomField = 'customField',
  CostCode = 'costCode',
}

export interface QuickFilterColumn {
  columnKey: string;
  customFieldId?: number | string;
}

interface QuickColumnFilterOptions {
  includeChildFilters?: boolean;
  includeParentFilters?: boolean;
}

export interface ItemQuickFilterProps {
  columns?: ReadonlyArray<QuickFilterColumn>;
  includeChildFilters?: boolean;
  includeParentFilters?: boolean;
  inputValue: string;
}

interface TaskFilterProps extends ItemQuickFilterProps {
  includeContainsAncestorName?: boolean;
  isWorkload?: boolean;
}

interface ProjectFilterProps extends ItemQuickFilterProps {
  includeContainsAncestorName?: boolean;
}

export const quickColumnFilter = (
  value: string,
  columns: ReadonlyArray<QuickFilterColumn> = [],
  { includeChildFilters = false, includeParentFilters = false }: QuickColumnFilterOptions = {},
): ReadonlyArray<string> => {
  const filters: Array<string> = [];
  const customFieldIds: Array<string> = [];

  columns.forEach((column) => {
    switch (column.columnKey) {
      case CustomOptions.CreatedBy:
        filters.push(filterContainsIgnoreCase(ItemFilter.CreatedByUsername, value));
        includeChildFilters && filters.push(filterContainsIgnoreCase(ItemFilter.ChildCreatedBy, value));
        includeParentFilters && filters.push(filterContainsIgnoreCase(ItemFilter.ParentCreatedByUsername, value));
        break;
      case CustomOptions.Description:
        filters.push(filterContainsIgnoreCase(ItemFilter.Description, value));
        includeChildFilters && filters.push(filterContainsIgnoreCase(ItemFilter.ChildDescription, value));
        includeParentFilters && filters.push(filterContainsIgnoreCase(ItemFilter.ParentDescription, value));
        break;
      case CustomOptions.CostCode:
        filters.push(filterContainsIgnoreCase(ItemFilter.CostCodeName, value));
        break;
      case CustomOptions.CustomField:
        column.customFieldId && customFieldIds.push(`${column.customFieldId}`);
        break;
    }
  });

  return filters.concat([
    filterCustomField(value, customFieldIds),
    includeChildFilters ? filterChildCustomField(value, customFieldIds) : '',
    includeParentFilters ? filterParentCustomField(value, customFieldIds) : '',
  ]);
};

export const taskInputQuickFilter = ({
  columns,
  inputValue,
  includeContainsAncestorName,
  includeChildFilters,
  includeParentFilters,
  isWorkload,
}: TaskFilterProps): string => {
  if (inputValue === '') {
    return inputValue;
  }

  return filterOr(
    filterContainsIgnoreCase(ItemFilter.Name, inputValue),
    filterContainsIgnoreCase(ItemFilter.TaskStatusName, inputValue),
    filterContainsIgnoreCase(
      isWorkload ? ItemFilter.ActiveChildOrganizationUserUsername : ItemFilter.ChildOrganizationUserUsername,
      inputValue,
    ),
    filterContainsIgnoreCase(ItemFilter.ScheduleDirective, inputValue),
    filterContainsIgnoreCase(ItemFilter.WorkingTeam, inputValue),
    includeContainsAncestorName ? filterContainsIgnoreCase(ItemFilter.AncestorName, inputValue) : '',
    ...quickColumnFilter(inputValue, columns, { includeChildFilters, includeParentFilters }),
  );
};

export const packageInputQuickFilter = ({
  columns,
  inputValue,
  includeChildFilters,
  includeParentFilters,
}: ItemQuickFilterProps): string => {
  if (inputValue === '') {
    return inputValue;
  }

  return filterOr(
    filterContainsIgnoreCase(ItemFilter.Name, inputValue),
    filterContainsIgnoreCase(ItemFilter.ChildName, inputValue),
    filterContainsIgnoreCase(ItemFilter.ChildWorkingTeam, inputValue),
    ...quickColumnFilter(inputValue, columns, { includeChildFilters, includeParentFilters }),
  );
};

export const projectInputQuickFilter = ({
  columns,
  includeContainsAncestorName,
  includeChildFilters,
  includeParentFilters,
  inputValue,
}: ProjectFilterProps) => {
  if (inputValue === '') {
    return inputValue;
  }

  return filterOr(
    filterContainsIgnoreCase(ItemFilter.Name, inputValue),
    includeContainsAncestorName ? filterContainsIgnoreCase(ItemFilter.AncestorName, inputValue) : '',
    includeParentFilters ? filterContainsIgnoreCase(ItemFilter.ParentName, inputValue) : '',
    filterContainsIgnoreCase(ItemFilter.WorkingTeam, inputValue),
    ...quickColumnFilter(inputValue, columns, { includeParentFilters, includeChildFilters }),
  );
};

export const assignmentQuickFilterQueryString = ({
  options,
  inputValue,
  columns = [],
}: {
  options: Array<string>;
  inputValue: string;
  columns: ReadonlyArray<QuickFilterColumn>;
}) => {
  if (!inputValue) {
    return filterAnd(...options);
  }
  return filterAnd(
    ...options,
    filterOr(
      filterContainsIgnoreCase(ItemFilter.Name, inputValue),
      filterContainsIgnoreCase(ItemFilter.ParentName, inputValue),
      filterContainsIgnoreCase(ItemFilter.AncestorName, inputValue),
      filterContainsIgnoreCase(ItemFilter.ParentScheduleDirective, inputValue),
      filterContainsIgnoreCase(ItemFilter.ParentTaskStatusName, inputValue),
      filterContainsIgnoreCase(ItemFilter.OrganizationUserUsername, inputValue),
      filterContainsIgnoreCase(ItemFilter.WorkingTeam, inputValue),
      ...quickColumnFilter(inputValue, columns, { includeParentFilters: true }),
    ),
  );
};

export const userInputQuickFilter = ({
  inputValue,
  columns = [],
}: {
  inputValue: string;
  columns: ReadonlyArray<QuickFilterColumn>;
}): string => {
  if (inputValue === '') {
    return inputValue;
  }

  const customFieldIds: Array<string> = [];

  columns.map((column) => {
    if (column.columnKey === CustomOptions.CustomField) {
      column.customFieldId && customFieldIds.push(`${column.customFieldId}`);
    }
  });

  return filterOr(
    filterContainsIgnoreCase(WorkspaceUserFilter.UserFirstName, inputValue),
    filterContainsIgnoreCase(WorkspaceUserFilter.UserLastName, inputValue),
    filterContainsIgnoreCase(WorkspaceUserFilter.Username, inputValue),
    filterContainsIgnoreCase(WorkspaceUserFilter.UserType, inputValue),
    filterContainsIgnoreCase(WorkspaceUserFilter.WorkingTeam, inputValue),
    filterWsUserCustomField(inputValue, customFieldIds),
  );
};

export const fileQuickFilterQueryString = (inputValue: string) => {
  if (inputValue === '') {
    return '';
  }

  return filterContainsIgnoreCase(FileFilter.Filename, inputValue);
};

interface LibraryViewDashboardFilterProps {
  inputValue: string;
  itemId: number;
  libraryLocation: LibraryLocation;
  packageStatus: PackageStatus | null | undefined;
}

export const getLibraryViewLibraryResourceFilter = ({
  inputValue,
  itemId,
  libraryLocation,
  packageStatus,
}: LibraryViewDashboardFilterProps) => {
  const filters: Array<string> = [filterEq(LibraryResourceFilter.ItemId, itemId)];

  if (libraryLocation === LibraryLocation.Workspace) {
    filters.push(filterNull(LibraryResourceFilter.PackageStatus, true));
  }

  if (libraryLocation === LibraryLocation.Collection) {
    packageStatus && filters.push(filterEq(LibraryResourceFilter.PackageStatus, packageStatus));
  }

  if (inputValue) {
    filters.push(
      filterOr(
        filterContainsIgnoreCase(LibraryResourceFilter.Name, inputValue),
        filterContainsIgnoreCase(LibraryResourceFilter.UpdatedBy, inputValue),
      ),
    );
  }

  return filterAnd(...filters);
};
