import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Comment } from 'semantic-ui-react';

import { WidgetWithData } from 'daos/model_types';
import { ChangeCard } from 'features/common/changes/changes_card';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { ChangesWidgetData } from 'features/dashboards_v2/widget/widgets/changes/types';
import { mergeEntities } from 'state/entities/slice';

import './index.scss';

export function ChangesWidget({ widget }: { widget: WidgetWithData }) {
  const dispatch = useDispatch();
  const widgetData = widget.data as ChangesWidgetData;

  useEffect(() => {
    dispatch(mergeEntities(widgetData.included));
  }, [dispatch, widgetData]);

  const hasChanges = widgetData && widgetData.changeIds.length > 0;

  return (
    <RenderedWidget
      body={
        <>
          {hasChanges ? (
            <Comment.Group minimal>
              {widgetData.changeIds.map((changeId) => (
                <ChangeCard changeId={changeId} key={changeId} />
              ))}
            </Comment.Group>
          ) : (
            <div className="changes-tabs__retained-text">No recent changes available.</div>
          )}
        </>
      }
    />
  );
}
