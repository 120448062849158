import { groupBy, sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import initials from 'lib/display_helpers/initials';
import { getTaskStatusForId } from 'state/entities/selectors/task_status';
import { RootState } from 'state/root_reducer';

import { createCacheByIdConfig, createCacheByIdsConfig } from './shared';

export const getWorkspacesById = (state: RootState) => state.entities.workspaces;

export const getWorkspaceForId = ({ entities }: RootState, id: number) => entities.workspaces[id];

export const getCurrentWorkspace = createSelector(
  (state: RootState) => state,
  getCurrentWorkspaceId,
  (state, workspaceId) => getWorkspaceForId(state, workspaceId),
);

export const getCurrentWorkspaceRootId = createSelector(
  getCurrentWorkspace,
  (workspace) => workspace?.workspaceRoot.id,
);

export const getCurrentWorkspaceDefaultTaskStatus = createSelector(
  (state: RootState) => state,
  getCurrentWorkspace,
  (state, workspace) => {
    const defaultTaskStatusId = workspace?.defaultTaskStatus?.id ?? 0;
    return getTaskStatusForId(state, defaultTaskStatusId);
  },
);

const getWorkspacesIndexedByOrgId = createSelector(getWorkspacesById, (workspaces) =>
  groupBy(workspaces, (ws) => ws.organization.id),
);

export const getWorkspacesForOrgId = createCachedSelector(
  getWorkspacesIndexedByOrgId,
  (_: RootState, id: number) => id,
  (orgs, id) => {
    return orgs[id];
  },
)(createCacheByIdConfig());

export const getWorkspacesForOrgIdSortedByName = createCachedSelector(
  getWorkspacesIndexedByOrgId,
  (_: RootState, id: number) => id,
  (orgs, id) => {
    return sortBy(orgs[id], (e) => (e.name || '').toLowerCase());
  },
)(createCacheByIdConfig());

export const getWorkspaceDataForCards = createSelector(
  getWorkspaceForId,
  getCurrentWorkspaceId,
  (workspace, currentWsId) => {
    return (
      workspace && {
        active: workspace.id === currentWsId ? '--active' : '',
        initials: initials(workspace.name) || '',
        name: workspace.name,
      }
    );
  },
);

export const getWorkspaceNameForId = createCachedSelector(
  getWorkspaceForId,
  (workspace) => workspace?.name,
)(createCacheByIdConfig());

const getWorkspaceNamesForIds = createCachedSelector(
  (state: RootState) => state,
  (_: RootState, workspaceIds: ReadonlyArray<number>) => workspaceIds,
  (state, workspaceIds) => {
    const workspaceNames = workspaceIds.reduce((acc: Array<string>, workspaceId) => {
      const workspaceName = getWorkspaceNameForId(state, workspaceId);
      if (workspaceName) {
        acc.push(workspaceName);
      }
      return acc;
    }, []);
    return workspaceNames;
  },
)(createCacheByIdsConfig());

export const getCommaSeparatedWorkspaceNamesForIds = createCachedSelector(getWorkspaceNamesForIds, (workspaceNames) =>
  workspaceNames.join(', '),
)(createCacheByIdsConfig());
