import { KeyboardEvent, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';

import { trackViewUrl } from 'features/academy/helpers';
import { getLessonForId, getTrackForId, getTrackLessonForId } from 'features/academy/selectors';

export const AcademyOutPostLesson = ({
  trackLessonId,
  handleNavItemClick,
  onKeyDown,
}: {
  trackLessonId: string;
  handleNavItemClick: (event: SyntheticEvent) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
}) => {
  const trackLesson = useSelector((state) => getTrackLessonForId(state, trackLessonId));
  const lesson = useSelector((state) => (trackLesson ? getLessonForId(state, trackLesson.academyLessonId) : undefined));
  const track = useSelector((state) => (trackLesson ? getTrackForId(state, trackLesson.academyTrackId) : undefined));

  if (!lesson || !track) {
    return null;
  }

  const courseId = track.academyCourseId;
  const trackId = track.id;
  const lessonId = lesson.id;

  return (
    <li className="tempo-nav-item justify-center">
      <a
        className="tempo-nav-item-link"
        href={trackViewUrl({ courseId, trackId, lessonId, path: location.pathname })}
        onClick={handleNavItemClick}
        onKeyDown={onKeyDown}
      >
        <span className="tempo-styled-bullet-in-list-no-bullet"></span>
        <span className="tempo-nav-item-link-content tempo-label-text">{lesson.title}</span>
      </a>
    </li>
  );
};
