import { useSelector } from 'react-redux';

import { getChangeForId } from 'state/entities/selectors/changes';
import { getCustomFieldById } from 'state/entities/selectors/custom_field';
import { getItemForId } from 'state/entities/selectors/item';

import { ChangeDescription, ItemDescription } from './change_card_description_sections';
import { itemDescriptionComesFirst } from './helpers';

export const ChangeCardDescription = ({ changeId }: { changeId: number }) => {
  const change = useSelector((state) => getChangeForId(state, changeId));

  const changeItemId = change?.item.id;
  const item = useSelector((state) => (changeItemId ? getItemForId(state, changeItemId) : undefined));

  const changeFieldId = change?.field?.id;
  const fieldName = useSelector((state) =>
    changeFieldId ? getCustomFieldById(state, changeFieldId)?.name : undefined,
  );

  if (!change || !item) {
    return null;
  }

  const itemType = item.itemType;
  const changeRecordType = change.record.type;
  const changeActionType = change.changeType;

  const changeDescription = (
    <ChangeDescription
      itemType={itemType}
      changeActionType={changeActionType}
      changeRecordType={changeRecordType}
      fieldName={fieldName}
    />
  );

  const itemDescription = (
    <ItemDescription
      changeActionType={changeActionType}
      changeItemId={change.item.id}
      item={item}
      changeRecord={change.record}
    />
  );

  const isItemDescriptionFirst = itemDescriptionComesFirst({ changeRecordType, changeActionType });

  return (
    <>
      {isItemDescriptionFirst ? (
        <>
          {itemDescription}
          {changeDescription}
        </>
      ) : (
        <>
          {changeDescription}
          {itemDescription}
        </>
      )}
      <div className="lp-change__card-content-name">by {change.changedByName}</div>
    </>
  );
};
