import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ResetPasswordDao } from 'daos/reset_password';
import { validationSchema } from 'features/authentication/helpers';
import { PasswordForm } from 'features/authentication/unauthenticated/token/password_form';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';

export const SetPasswordForm = ({
  expired,
  claimedAt,
  token,
  resetPasswordId,
}: {
  token: string;
  resetPasswordId: number;
  expired: boolean;
  claimedAt: string | null;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizationId, workspaceId } = useQueryParams();

  const { isSubmitting, status, setStatus, handleSubmit, getFieldMeta, getFieldProps, setSubmitting } = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ password }) => {
      setStatus(undefined);

      const { uuid } = dispatch(ResetPasswordDao.patch({ password, token, resetPasswordId }));

      dispatch(
        awaitRequestFinish(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setStatus(errors[0]);
            }
            setSubmitting(false);
          },
          onSuccess: () => {
            organizationId && workspaceId && history.push(frontend.people.url({ organizationId, workspaceId }));
          },
        }),
      );
    },
  });

  const handleDismissApiError = () => setStatus(undefined);

  return (
    <PasswordForm
      expired={expired}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      getFieldMeta={getFieldMeta}
      getFieldProps={getFieldProps}
      handleDismissApiError={handleDismissApiError}
      status={status}
      claimedAt={claimedAt}
    />
  );
};
