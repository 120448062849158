import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Label, Popup } from 'semantic-ui-react';

import { caretDownSolid, LpIcon } from 'features/common/lp_icon';
import { useHasUnifiedLeftNav } from 'features/organization/left_nav/tempo_nav/hooks';
import { getCurrentOrganizationUser } from 'state/entities/selectors/user';

import { UserMenuContext } from './context';
import { UserMenuTable } from './table';

import './user_menu.scss';

const UserMenu = () => {
  const currentOrgUser = useSelector(getCurrentOrganizationUser);
  const hasUnifiedNav = useHasUnifiedLeftNav();

  const [isOpen, setIsOpen] = useState(false);

  const popupMenuOpen = () => setIsOpen(true);
  const popupMenuClose = () => setIsOpen(false);

  if (!currentOrgUser || hasUnifiedNav) {
    return null;
  }

  return (
    <Popup
      basic
      className="user-menu"
      content={
        <UserMenuContext.Provider value={{ onClose: popupMenuClose }}>
          <UserMenuTable userName={currentOrgUser.username} userId={currentOrgUser.user.id} />
        </UserMenuContext.Provider>
      }
      hideOnScroll
      on="click"
      onOpen={popupMenuOpen}
      onClose={popupMenuClose}
      open={isOpen}
      position="bottom left"
      trigger={
        <Label className="user-menu__trigger" image data-e2e-test-id="logout-user-trigger">
          <div className="user-menu__trigger-user-name">{currentOrgUser.username}</div>
          <LpIcon className="user-menu__trigger-icon" icon={caretDownSolid} />
        </Label>
      }
    />
  );
};

export default UserMenu;
