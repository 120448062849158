import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { PackageStatus, SchedulingType, StatusFilterGroups } from 'daos/enums';
import { ItemMetrics } from 'daos/model_types';
import { FolderFilterStatus } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { compare } from 'lib/helpers/fast_string_compare';
import { DateRange, getItemsForIds } from 'state/entities/selectors/item';
import { getItemMetricsById } from 'state/entities/selectors/item_metric';
import { getTaskStatusesById } from 'state/entities/selectors/task_status';
import { EntityLookupById } from 'state/entities/types';

export enum ScheduleViewType {
  Column = 'Column', // used on PPP views and lists
  Grid = 'Grid',
  ItemPanel = 'ItemPanel',
  AssignmentTable = 'AssignmentTable',
  Widget = 'Widget', // used only for the schedule widget
}

export const calcItemsDateRange = ({
  allItemMetrics,
  allTaskStatuses,
  localNow,
  customProjectFilterStatus,
  customTaskStatusIds,
  packageStatus,
  projectFilterStatus,
  scheduleColumnExpanded,
  taskStatusFilter,
  viewType,
  items,
}: {
  allItemMetrics: EntityLookupById<
    Pick<
      ItemMetrics,
      | 'earliestDoneDate'
      | 'effectiveTargetFinish'
      | 'effectiveTargetStart'
      | 'latestDoneDate'
      | 'rollupEarliestActiveTargetFinish'
      | 'rollupEarliestTargetStart'
      | 'rollupLatestTargetFinish'
    >
  >;
  allTaskStatuses?: EntityLookupById<{ schedulingType: SchedulingType }>;
  localNow: moment.Moment;
  customProjectFilterStatus?: FolderFilterStatus;
  customTaskStatusIds?: ReadonlyArray<number>;
  packageStatus?: PackageStatus | null;
  projectFilterStatus?: FolderFilterStatus;
  scheduleColumnExpanded?: boolean;
  taskStatusFilter?: StatusFilterGroups;
  viewType: ScheduleViewType;
  items: ReadonlyArray<{
    id: number;
    targetStart: string | null;
    targetFinish: string | null;
    expectedStart: string | null;
    latestFinish: string | null;
    doneDate: string | null;
  }>;
}): DateRange => {
  if (packageStatus === PackageStatus.TEMPLATE) {
    return {
      start: localNow,
      finish: localNow,
    };
  }

  let startDate: string | null | undefined = null;
  let finishDate: string | null | undefined = null;

  function leastStart(date: string | null | undefined) {
    if (date) {
      if (!startDate) {
        startDate = date;
      } else if (compare(date, startDate) < 0) {
        startDate = date;
      }
    }
  }

  function greatestFinish(date: string | null | undefined) {
    if (date) {
      if (!finishDate) {
        finishDate = date;
      } else if (compare(date, finishDate) > 0) {
        finishDate = date;
      }
    }
  }

  function isFilterIncludeDone() {
    if (
      taskStatusFilter === StatusFilterGroups.All ||
      taskStatusFilter == StatusFilterGroups.Done ||
      projectFilterStatus === FolderFilterStatus.All ||
      projectFilterStatus === FolderFilterStatus.Done ||
      customProjectFilterStatus === FolderFilterStatus.All ||
      customProjectFilterStatus === FolderFilterStatus.Done
    ) {
      return true;
    }

    if (taskStatusFilter === StatusFilterGroups.Custom && customTaskStatusIds) {
      for (let i = 0; i < customTaskStatusIds.length; i++) {
        const customTaskStatusId = customTaskStatusIds[i];

        if (customTaskStatusId) {
          const customTaskStatus = allTaskStatuses?.[customTaskStatusId];
          if (customTaskStatus?.schedulingType === SchedulingType.Done) {
            return true;
          }
        }
      }
    }

    return false;
  }

  const isScheduledPortfolio = packageStatus === PackageStatus.SCHEDULED;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!item) {
      continue;
    }

    const { id: itemId, targetStart, targetFinish, expectedStart, latestFinish, doneDate } = item;
    const {
      effectiveTargetStart,
      effectiveTargetFinish,
      rollupEarliestActiveTargetFinish,
      rollupEarliestTargetStart,
      rollupLatestTargetFinish,
      earliestDoneDate,
      latestDoneDate,
    } = allItemMetrics[itemId] ?? {};

    switch (viewType) {
      case ScheduleViewType.AssignmentTable:
        leastStart(expectedStart);
        greatestFinish(latestFinish);
        break;
      case ScheduleViewType.Grid:
        leastStart(expectedStart);
        leastStart(doneDate);
        if (!doneDate) {
          leastStart(targetFinish);
        }

        greatestFinish(latestFinish);
        greatestFinish(targetFinish);
        greatestFinish(doneDate);
        greatestFinish(rollupEarliestTargetStart);
        greatestFinish(rollupLatestTargetFinish);
        greatestFinish(effectiveTargetStart);
        greatestFinish(effectiveTargetFinish);
        break;
      case ScheduleViewType.Widget:
      case ScheduleViewType.ItemPanel:
        leastStart(rollupEarliestActiveTargetFinish);
        leastStart(targetStart);
        leastStart(expectedStart);
        leastStart(effectiveTargetFinish);
        leastStart(doneDate);

        greatestFinish(latestFinish);
        greatestFinish(targetFinish);
        greatestFinish(doneDate);
        greatestFinish(rollupEarliestTargetStart);
        greatestFinish(rollupLatestTargetFinish);
        greatestFinish(effectiveTargetStart);
        greatestFinish(effectiveTargetFinish);

        break;
      case ScheduleViewType.Column:
        leastStart(rollupEarliestActiveTargetFinish);
        leastStart(doneDate);
        greatestFinish(latestFinish);
        greatestFinish(targetFinish);
        greatestFinish(doneDate);
        greatestFinish(rollupEarliestTargetStart);
        greatestFinish(rollupLatestTargetFinish);

        leastStart(isScheduledPortfolio ? expectedStart : targetStart);

        if (isFilterIncludeDone()) {
          leastStart(earliestDoneDate);
          greatestFinish(latestDoneDate);
        }
        if (scheduleColumnExpanded) {
          leastStart(rollupEarliestTargetStart);
        }
    }
  }

  const start = startDate ? moment(startDate) : localNow;
  const finish = finishDate ? moment(finishDate) : localNow;

  return {
    start,
    finish,
  };
};

export const useItemsDateRange = (
  itemIds: ReadonlyArray<number>,
  {
    customProjectFilterStatus,
    customTaskStatusIds,
    packageStatus,
    projectFilterStatus,
    scheduleColumnExpanded,
    taskStatusFilter,
    viewType,
  }: {
    customProjectFilterStatus?: FolderFilterStatus;
    customTaskStatusIds?: ReadonlyArray<number>;
    packageStatus?: PackageStatus | null;
    projectFilterStatus?: FolderFilterStatus;
    scheduleColumnExpanded?: boolean;
    taskStatusFilter?: StatusFilterGroups;
    viewType: ScheduleViewType;
  },
) => {
  const { currentBrowserDateTime } = useLocalizedFormats();
  const localNow = currentBrowserDateTime();

  const items = useSelector((state) => getItemsForIds(state, itemIds));
  const allItemMetrics = useSelector(getItemMetricsById);
  const allTaskStatuses = useSelector(getTaskStatusesById);

  return calcItemsDateRange({
    allItemMetrics,
    allTaskStatuses,
    customProjectFilterStatus,
    customTaskStatusIds,
    items,
    localNow,
    packageStatus,
    projectFilterStatus,
    scheduleColumnExpanded,
    taskStatusFilter,
    viewType,
  });
};
