import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Button, Image } from 'semantic-ui-react';

import { PmLegalTermsFooter } from 'containers/shared/footers/pm_legal_terms_footer';
import { UnauthenticatedHeader } from 'containers/shared/headers/unauthenticated_header';
import { UnauthenticatedTitles, PageNames } from 'containers/shared/headers/unauthenticated_titles';
import LpLink from 'containers/shared/lp_link';
import { EmailConfirmationDao } from 'daos/email_confirmation';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import circleCheckIcon from 'static/img/tabler-icon-circle-check.svg';
import tablerIconMailExclamation from 'static/img/tabler-icon-mail-exclamation.svg';

import './index.scss';

export const EmailConfirmationErrorV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(false);

  const isEmailConfirmationRoute = Boolean(
    useRouteMatch({
      path: frontend.emailConfirmation.url({}),
      exact: true,
    }),
  );

  const handleResendEmailConfirmation = () => {
    setIsSubmitting(true);

    const { uuid } = dispatch(EmailConfirmationDao.reCreateConfirmation());

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => {
          setConfirmationSent(true);
        },
        onFinish: () => {
          setIsSubmitting(false);
        },
      }),
    );
  };

  return (
    <div className="pm-email-confirmation-error">
      <UnauthenticatedHeader className="pm-email-confirmation-error__header" />
      <div className="pm-email-confirmation-error__content">
        {confirmationSent ? (
          <>
            <h3 className="pm-email-confirmation-error__email-sent">
              An account confirmation link has been sent to the email you provided. Please check your inbox.
            </h3>
            <Image src={circleCheckIcon} />
            <LpLink className="pm-email-confirmation-error__log-in-link" to={frontend.login.url({})}>
              Return to Log In
            </LpLink>
          </>
        ) : (
          <>
            <Image src={tablerIconMailExclamation} className="pm-email-confirmation-error__icon" />
            <UnauthenticatedTitles
              className="pm-email-confirmation-error__title"
              pageName={
                isEmailConfirmationRoute ? PageNames.EMAIL_CONFIRMATION_ERROR : PageNames.EMAIL_CONFIRMATION_EXPIRED
              }
            />
            <Button
              className="pm-email-confirmation-error__submit-button"
              content={isEmailConfirmationRoute ? 'Take Me To The App' : 'Send Confirmation →'}
              onClick={
                isEmailConfirmationRoute
                  ? () => {
                      history.push('/');
                    }
                  : handleResendEmailConfirmation
              }
              disabled={isSubmitting}
              type="submit"
            />
          </>
        )}
      </div>
      {!confirmationSent && <PmLegalTermsFooter className="pm-signup__footer" />}
    </div>
  );
};
