import classNames from 'classnames';
import { isNumber } from 'lodash';
import { SyntheticEvent, useState } from 'react';
import { Button, DropdownProps, Form, StrictCheckboxProps } from 'semantic-ui-react';

import ItemPickerWorkSpaceRoot from 'containers/shared/item_picker/workspace_root';
import { ControlledCustomFieldCurrency } from 'features/common/custom_fields/custom_field_value/custom_field_currency';
import PicklistDropdown from 'features/common/inputs/dropdowns/picklist_dropdown';
import { UserDropdownPlaceHolderText } from 'features/common/inputs/dropdowns/user_dropdown';
import { circleXmarkLight, LpIcon } from 'features/common/lp_icon';
import { WidgetMotionDiv } from 'features/dashboards_v2/widget_click_through/intake/display_helpers';
import {
  filteredDropdownOptions,
  useIntakeFormField,
} from 'features/dashboards_v2/widget_click_through/intake/form/helpers';
import { IntakeWidgetSelectedItem } from 'features/dashboards_v2/widget_click_through/intake/intake_widget_selected_item';
import {
  IntakeCustomFieldPicklistWidgetData,
  IntakeCustomFieldUserWidgetData,
} from 'features/dashboards_v2/widget_click_through/intake/types';
import { blurElementOnEnterKey } from 'lib/helpers/blur_element_on_key_press';

import { UserDropdownRenderer } from './decoupled_user_dropdown';
import { useUpdateAndRemoveMultiFormFieldValues } from './use_update_and_remove_custom_field_values';

interface IntakeCustomFieldInputProps {
  fieldIdKey: string;
  isDisplayOnly: boolean;
  autoFocus?: boolean;
}

export const IntakeCurrency = ({
  currencyUnit,
  fieldIdKey,
  isDisplayOnly,
  hasAutoFocus,
}: IntakeCustomFieldInputProps & { currencyUnit: string | null; hasAutoFocus: boolean }) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } = useIntakeFormField<string>(fieldIdKey);

  const handleBlur = (value: string) => {
    setFormFieldError(undefined);
    setFormFieldValue(value);
  };

  return (
    <ControlledCustomFieldCurrency
      hasError={!!fieldMeta.error}
      hasAutoFocus={hasAutoFocus}
      currencyValue={formFieldValue ?? ''}
      currencyUnit={currencyUnit}
      onBlur={handleBlur}
      disabled={isDisplayOnly}
    />
  );
};

export const IntakeCheckbox = ({ fieldIdKey, isDisplayOnly: disabled }: IntakeCustomFieldInputProps) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } = useIntakeFormField<boolean>(fieldIdKey);

  const handleChange = (_: SyntheticEvent, { checked }: StrictCheckboxProps) => {
    if (checked !== undefined) {
      setFormFieldError(undefined);
      setFormFieldValue(checked);
    }
  };

  return (
    <Form.Checkbox error={!!fieldMeta.error} disabled={disabled} onChange={handleChange} checked={formFieldValue} />
  );
};

export const IntakePicklist = ({
  fieldIdKey,
  isDisplayOnly,
  intakePicklistChoices,
}: IntakeCustomFieldInputProps & {
  intakePicklistChoices: Record<string, IntakeCustomFieldPicklistWidgetData>;
}) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError, setFormFieldTouched } =
    useIntakeFormField<number>(fieldIdKey);
  const picklistOptions = Object.values(intakePicklistChoices);

  const handleChange = (_: SyntheticEvent, { value }: { value: number }) => {
    if (isNumber(value) || value === undefined) {
      setFormFieldError(undefined);
      setFormFieldTouched(true);
      setFormFieldValue(value);
    }
  };

  return (
    <PicklistDropdown
      error={!!fieldMeta.error}
      usePortal
      disabled={isDisplayOnly}
      fieldChoiceId={formFieldValue}
      picklist={picklistOptions}
      onChange={handleChange}
      placeHolder="Select"
      className="intake-widget-user-form-section__field-dropdown"
    />
  );
};

export const IntakeItem = ({ fieldIdKey, isDisplayOnly }: IntakeCustomFieldInputProps) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } = useIntakeFormField<number>(fieldIdKey);

  const [open, setOpen] = useState(false);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const handleSelect = (itemId: number) => {
    setFormFieldValue(itemId);
    setFormFieldError(undefined);
    closeModal();
  };

  return (
    <div className="intake-form-item-picker">
      {open && <ItemPickerWorkSpaceRoot onCancel={closeModal} onSelect={handleSelect} />}
      <Button
        negative={fieldMeta.touched && !!fieldMeta.error}
        content="Select"
        onClick={openModal}
        disabled={isDisplayOnly}
      />
      {formFieldValue && (
        <div className="intake-form-item-picker__selected-item-click-area" onClick={openModal}>
          <IntakeWidgetSelectedItem itemId={formFieldValue} disableLinking />
        </div>
      )}
    </div>
  );
};

export const IntakeUser = ({
  fieldIdKey,
  isDisplayOnly,
  intakeUserChoices,
}: IntakeCustomFieldInputProps & { intakeUserChoices: Record<string, IntakeCustomFieldUserWidgetData> }) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } = useIntakeFormField<number>(fieldIdKey);

  const handleChange = (_event: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setFormFieldError(undefined);
    setFormFieldValue(value as number);
  };

  const orgUsersOptions = Object.values(intakeUserChoices);

  return (
    <UserDropdownRenderer
      className="intake-widget-user-form-section__field-dropdown"
      disabled={isDisplayOnly}
      clearable={true}
      error={!!fieldMeta.error}
      onChange={handleChange}
      selectedUserId={formFieldValue}
      placeholder={UserDropdownPlaceHolderText.SelectUser}
      userOptions={orgUsersOptions}
      selectOnBlur={true}
      usePortal
    />
  );
};

export const IntakeMultiText = ({
  autoFocus,
  fieldIdKey,
  isDisplayOnly,
  placeholder,
  type,
}: IntakeCustomFieldInputProps & {
  placeholder?: string;
  type: 'number' | 'text';
}) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } =
    useIntakeFormField<Array<string>>(fieldIdKey);
  const [content, setContent] = useState('');

  const handleInitialFieldValueChange = (_: SyntheticEvent, { value }: { value: string }) => {
    setFormFieldError(undefined);
    setContent(value);
  };

  const { removeMultiValue, addOrUpdateMultiValue } = useUpdateAndRemoveMultiFormFieldValues<string>({
    setFormFieldValue,
  });

  const createNewInputField = (_: SyntheticEvent) => {
    if (content) {
      setFormFieldError(undefined);
      addOrUpdateMultiValue(formFieldValue.length, content, formFieldValue);
      setContent('');
    }
  };

  const removeFieldIfEmpty = (index: number) => (_: SyntheticEvent) => {
    const value = formFieldValue[index];
    if (!value) {
      removeMultiValue(index, formFieldValue);
    }
  };

  const handleChange =
    (index: number) =>
    (_: SyntheticEvent, { value }: { value: string }) => {
      addOrUpdateMultiValue(index, value, formFieldValue);
    };

  const handleClear = (index: number) => (_: SyntheticEvent) => removeMultiValue(index, formFieldValue);

  return (
    <div className="intake-form-custom-field-multi__text">
      {formFieldValue?.map((value, index) => (
        <WidgetMotionDiv className="intake-form-custom-field-multi" key={index}>
          <Form.Input
            className="intake-form-custom-field-input"
            disabled={isDisplayOnly}
            error={!!fieldMeta.error?.[index]}
            onBlur={removeFieldIfEmpty(index)}
            onChange={handleChange(index)}
            onKeyDown={blurElementOnEnterKey}
            type={type}
            value={value}
          />
          {!isDisplayOnly && (
            <LpIcon
              className={classNames('intake-form-custom-field-multi__icon', {
                'intake-form-custom-field-multi__icon--disabled': value === '',
              })}
              icon={circleXmarkLight}
              onClick={handleClear(index)}
            />
          )}
        </WidgetMotionDiv>
      ))}
      {!isDisplayOnly && (
        <div className="intake-form-custom-field-multi">
          <Form.Input
            autoFocus={autoFocus}
            className="intake-form-custom-field-input"
            disabled={isDisplayOnly}
            onBlur={createNewInputField}
            onChange={handleInitialFieldValueChange}
            onKeyDown={blurElementOnEnterKey}
            placeholder={placeholder}
            type={type}
            value={content}
          />
        </div>
      )}
    </div>
  );
};

export const IntakeMultiPicklist = ({
  fieldIdKey,
  isDisplayOnly,
  intakePicklistChoices,
}: IntakeCustomFieldInputProps & {
  intakePicklistChoices: Record<string, IntakeCustomFieldPicklistWidgetData>;
}) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } =
    useIntakeFormField<Array<number>>(fieldIdKey);
  const picklistOptions = Object.values(intakePicklistChoices);

  const filteredPicklistOptions = (pickListId: number | undefined) => {
    return filteredDropdownOptions<IntakeCustomFieldPicklistWidgetData>(pickListId, formFieldValue, picklistOptions);
  };

  const { removeMultiValue, addOrUpdateMultiValue } = useUpdateAndRemoveMultiFormFieldValues<number>({
    setFormFieldValue,
  });

  const handleChange =
    (index: number) =>
    (_: SyntheticEvent, { value }: { value: number }) => {
      if (formFieldValue.length === index && !value) {
        return;
      }

      setFormFieldError(undefined);

      if (!value) {
        removeMultiValue(index, formFieldValue);
      }

      if (value) {
        addOrUpdateMultiValue(index, value, formFieldValue);
      }
    };

  const handleClear = (index: number) => (_: SyntheticEvent) => removeMultiValue(index, formFieldValue);

  return (
    <div
      className={classNames('intake-form-custom-field-multi__picklist', {
        'intake-form-custom-field-multi__picklist--display-only': isDisplayOnly,
      })}
    >
      {formFieldValue?.map((picklistId, index) => (
        <WidgetMotionDiv className="intake-form-custom-field-multi" key={index}>
          <PicklistDropdown
            disabled={isDisplayOnly}
            error={!!fieldMeta.error}
            fieldChoiceId={picklistId}
            onChange={handleChange(index)}
            picklist={filteredPicklistOptions(picklistId)}
            placeHolder="Select"
            className="intake-widget-user-form-section__field-dropdown"
            usePortal
          />

          {!isDisplayOnly && (
            <LpIcon
              className="intake-form-custom-field-multi__icon"
              icon={circleXmarkLight}
              onClick={handleClear(index)}
            />
          )}
        </WidgetMotionDiv>
      ))}

      {!isDisplayOnly && (
        <PicklistDropdown
          disabled={isDisplayOnly}
          fieldChoiceId={undefined}
          error={!!fieldMeta.error}
          onChange={handleChange(formFieldValue.length)}
          picklist={filteredPicklistOptions(undefined)}
          placeHolder="Select"
          className="intake-widget-user-form-section__field-dropdown"
          usePortal
        />
      )}
    </div>
  );
};

export const IntakeMultiItem = ({ fieldIdKey, isDisplayOnly }: IntakeCustomFieldInputProps) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } =
    useIntakeFormField<Array<number>>(fieldIdKey);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

  const closeModal = () => {
    setSelectedIndex(undefined);
    setOpen(false);
  };

  const openModal = (index: number) => (_: SyntheticEvent) => {
    setOpen(true);
    setSelectedIndex(index);
  };

  const { removeMultiValue, addOrUpdateMultiValue } = useUpdateAndRemoveMultiFormFieldValues<number>({
    setFormFieldValue,
  });

  const handleSelect = (itemId: number) => {
    if (selectedIndex !== undefined) {
      addOrUpdateMultiValue(selectedIndex, itemId, formFieldValue);
      setFormFieldError(undefined);
    }

    closeModal();
  };

  const handleClear = (index: number) => (_: SyntheticEvent) => removeMultiValue(index, formFieldValue);

  return (
    <div className="intake-form-custom-field-multi__item">
      {open && <ItemPickerWorkSpaceRoot onCancel={closeModal} onSelect={handleSelect} />}
      {formFieldValue?.map((itemId, index) => {
        return (
          <WidgetMotionDiv className="intake-form-custom-field-multi" key={index}>
            {!isDisplayOnly && (
              <Button
                negative={fieldMeta.touched && !!fieldMeta.error}
                content="Select"
                onClick={openModal(index)}
                disabled={isDisplayOnly}
              />
            )}

            <div className="intake-form-custom-field-multi__selected-item-click-area" onClick={openModal(index)}>
              <IntakeWidgetSelectedItem itemId={itemId} disableLinking />
            </div>

            {!isDisplayOnly && (
              <LpIcon
                className={classNames(
                  'intake-form-custom-field-multi__icon',
                  'intake-form-custom-field-multi__icon--margin-left',
                )}
                icon={circleXmarkLight}
                onClick={handleClear(index)}
              />
            )}
          </WidgetMotionDiv>
        );
      })}

      {!isDisplayOnly && (
        <Button
          className="intake-form-custom-field-multi__item-select"
          content="Select"
          disabled={isDisplayOnly}
          negative={fieldMeta.touched && !!fieldMeta.error}
          onClick={openModal(formFieldValue.length)}
        />
      )}
    </div>
  );
};

export const IntakeMultiUser = ({
  fieldIdKey,
  isDisplayOnly,
  intakeUserChoices,
}: IntakeCustomFieldInputProps & { intakeUserChoices: Record<string, IntakeCustomFieldUserWidgetData> }) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } =
    useIntakeFormField<Array<number>>(fieldIdKey);

  const orgUsersOptions = Object.values(intakeUserChoices);

  const filteredUserOptions = (orgUserId: number | undefined) => {
    return filteredDropdownOptions<IntakeCustomFieldUserWidgetData>(orgUserId, formFieldValue, orgUsersOptions);
  };

  const { removeMultiValue, addOrUpdateMultiValue } = useUpdateAndRemoveMultiFormFieldValues<number>({
    setFormFieldValue,
  });

  const handleChange =
    (index: number) =>
    (_: SyntheticEvent, { value }: { value: number }) => {
      if (formFieldValue.length === index && !value) {
        return;
      }

      setFormFieldError(undefined);

      if (!value) {
        removeMultiValue(index, formFieldValue);
      }

      if (value) {
        addOrUpdateMultiValue(index, value, formFieldValue);
      }
    };

  const handleClear = (index: number) => (_: SyntheticEvent) => removeMultiValue(index, formFieldValue);

  return (
    <div
      className={classNames('intake-form-custom-field-multi__user', {
        'intake-form-custom-field-multi__user--display-only': isDisplayOnly,
      })}
    >
      {formFieldValue?.map((orgUserId, index) => {
        return (
          <WidgetMotionDiv className="intake-form-custom-field-multi" key={index}>
            <UserDropdownRenderer
              className="intake-widget-user-form-section__field-dropdown"
              disabled={isDisplayOnly}
              clearable={true}
              error={!!fieldMeta.error}
              onChange={handleChange(index)}
              selectedUserId={orgUserId}
              placeholder={UserDropdownPlaceHolderText.SelectUser}
              userOptions={orgUsersOptions}
              selectOnBlur={true}
              usePortal
            />

            {!isDisplayOnly && (
              <LpIcon
                className={classNames('intake-form-custom-field-multi__icon', {
                  'intake-form-custom-field-multi__icon--disabled': !orgUserId,
                })}
                icon={circleXmarkLight}
                onClick={handleClear(index)}
              />
            )}
          </WidgetMotionDiv>
        );
      })}

      {!isDisplayOnly && (
        <UserDropdownRenderer
          className="intake-widget-user-form-section__field-dropdown"
          disabled={isDisplayOnly}
          clearable={true}
          error={!!fieldMeta.error}
          onChange={handleChange(formFieldValue.length)}
          selectedUserId={undefined}
          placeholder={UserDropdownPlaceHolderText.SelectUser}
          userOptions={filteredUserOptions(undefined)}
          selectOnBlur={true}
          usePortal
        />
      )}
    </div>
  );
};
