import { get } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { backend } from 'daos/urls';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';

type ImageUploadHandler = (
  blobInfo: {
    id: () => string;
    name: () => string;
    filename: () => string;
    blob: () => Blob;
    base64: () => string;
    blobUri: () => string;
    uri: () => string | undefined;
  },
  progress: (percent: number) => void,
) => Promise<string>;

export const useHandleImageUpload = () => {
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));

  const handleImageUpload = useCallback<ImageUploadHandler>(
    ({ blob, filename }) => {
      return new Promise((resolve, reject) => {
        const imageUploadUrl = backend.fieldImages.url({ workspaceId, organizationId });

        const formData = new FormData();
        formData.append('file', blob(), filename());

        fetch(imageUploadUrl, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              return reject('Unable to upload image');
            }
            return response.json();
          })
          .then((json) => {
            const s3Id = get<string | null>(json, 'data.s3Id', null);

            if (!s3Id) {
              return reject('Unable to upload image');
            }

            const imageDownloadUrl = backend.fieldImage.url({ workspaceId, organizationId, s3Id });

            return resolve(imageDownloadUrl);
          })
          .catch((err) => {
            if (err instanceof Error) {
              reject(err.toString());
            }
          });
      });
    },
    [organizationId, workspaceId],
  );
  return { handleImageUpload };
};
