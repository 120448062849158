import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { DashboardOrderBy } from './enums';
import { Dashboard } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { DashboardV2Config } from './types';
import { backend } from './urls';

const { createBody, updateBody, resource, deleteBody } = defineModel({
  apiType: 'dashboards',
  type: 'DASHBOARD',
});

const { DASHBOARD, resourceId } = resource;

interface Includes {
  includeItem?: boolean;
  includeItemAncestors?: boolean;
  includeDashboardLocationFilterItem?: boolean;
  includeWidgetGroupLocationFilterItem?: boolean;
  includeWidgetLocationFilterItem?: boolean;
  includeWidgetGroups?: boolean;
  includeWidgets?: boolean;
  includeLibraryResources?: boolean;
  includeLibraryResourcesItem?: boolean;
  includeWorkspaceField?: boolean;
  includeWorkspaceFieldValues?: boolean;
  includeWidgetsMeta?: boolean;
}

const includes = ({
  includeItem,
  includeItemAncestors,
  includeDashboardLocationFilterItem,
  includeWidgetGroupLocationFilterItem,
  includeWidgetLocationFilterItem,
  includeWidgetGroups,
  includeWidgets,
  includeLibraryResources,
  includeLibraryResourcesItem,
  includeWorkspaceField,
  includeWorkspaceFieldValues,
  includeWidgetsMeta,
}: Includes = {}) =>
  reduceIncludedOptions([
    includeItem && 'item',
    includeItemAncestors && 'item.ancestors',
    includeDashboardLocationFilterItem && 'locationFilterItem',
    includeWidgetLocationFilterItem && 'widgets.locationFilterItem.fieldValues.itemValue',
    includeWidgetGroups && 'widgetGroups',
    includeWidgetGroupLocationFilterItem && 'widgetGroups.locationFilterItem',
    includeWidgets && 'widgets',
    includeLibraryResources && 'libraryResource',
    includeLibraryResourcesItem && 'libraryResource.item',
    includeWorkspaceField && 'workspace.fields',
    includeWorkspaceFieldValues && 'workspace.workspaceUsers.fieldValues',
    includeWidgetsMeta && 'widgetsMeta',
  ]);

interface DashboardsParams {
  organizationId: string | number;
  workspaceId: string | number;
}

interface SingleDashboardParams extends DashboardsParams {
  dashboardId: string;
}

interface DashboardByItemIdParams extends DashboardsParams {
  itemId: number | string;
}

interface DashboardOptions {
  filter?: string;
  include?: Includes;
  query?: { limit?: number; order?: DashboardOrderBy };
}

type CreateDashboard = Pick<Dashboard, 'itemId'>;

type CreateWorkspaceDashboard = Pick<
  Dashboard,
  'headerImageS3Id' | 'headerText' | 'itemId' | 'name' | 'color' | 'dashboardType'
> & {
  config?: Partial<DashboardV2Config>;
};

type PatchDashboard = Partial<
  Pick<Dashboard, 'headerImageS3Id' | 'headerText' | 'name' | 'color'> & {
    config?: Partial<DashboardV2Config>;
  }
>;

const dashboardFetch = (params: DashboardsParams & SingleDashboardParams, { include }: DashboardOptions = {}) =>
  request(backend.dashboard.url(params, { include: includes(include) }), DASHBOARD, { method: HttpMethod.GET });

const dashboardFetchOrCreateByItemId = (params: DashboardByItemIdParams, { include }: DashboardOptions = {}) =>
  request(backend.dashboardByItemId.url(params, { include: includes(include) }), DASHBOARD, {
    method: HttpMethod.POST,
  });

const dashboardFetchAll = (params: DashboardsParams, { include, filter, query }: DashboardOptions = {}) =>
  request(backend.dashboards.url(params, { filter, include: includes(include), query }), DASHBOARD, {
    method: HttpMethod.GET,
  });
const dashboardCreate = (
  { organizationId, workspaceId }: DashboardsParams,
  dashboard: CreateDashboard | CreateWorkspaceDashboard,
) => request(backend.dashboards.url({ organizationId, workspaceId }), DASHBOARD, createBody(dashboard));

const dashboardUpdate = (params: DashboardsParams & SingleDashboardParams, data: PatchDashboard) =>
  request(backend.dashboard.url(params), DASHBOARD, updateBody(Number(params.dashboardId), data));

const dashboardDuplicate = (params: DashboardsParams & SingleDashboardParams) =>
  request(backend.dashboardDuplicate.url(params), DASHBOARD, { method: HttpMethod.POST });

const fetchInFocus = (params: DashboardsParams, { include }: DashboardOptions = {}) =>
  request(
    backend.dashboardsInFocus.url(params, {
      include: includes(include),
    }),
    DASHBOARD,
    { method: HttpMethod.GET },
  );

const dashboardDestroy = (params: SingleDashboardParams) =>
  request(backend.dashboard.url(params), DASHBOARD, deleteBody(Number(params.dashboardId)));

export const DashboardDao = {
  duplicate: dashboardDuplicate,
  fetch: dashboardFetch,
  fetchOrCreateByItemId: dashboardFetchOrCreateByItemId,
  fetchAll: dashboardFetchAll,
  update: dashboardUpdate,
  fetchInFocus: fetchInFocus,
  create: dashboardCreate,
  destroy: dashboardDestroy,
  id: resourceId,
} as const;
