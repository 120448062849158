import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { customColumnDefinitions } from 'containers/shared/custom_column';
import { StandardColumns } from 'containers/shared/custom_column/enum';
import { ListWidgetType } from 'daos/enums';
import { WidgetConfig } from 'daos/types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { JiraFieldsDisplayText } from 'features/dashboards_v2/widget/widgets/jira_custom_fields';
import { useItemListWidgetContext } from 'features/dashboards_v2/widget/widgets/list/context';
import { getDateRangeDisplay } from 'features/dashboards_v2/widget/widgets/list/use_widget_date_range';
import { useHasTeamBasedPlanning } from 'hooks/use_has_feature';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getCustomFieldForId } from 'state/entities/selectors/custom_field';

const HeaderCellContent = ({ columnDefinition }: { columnDefinition: WidgetConfigColumn }) => {
  const { column, customFieldId } = columnDefinition;
  const customField = useSelector((state) => (customFieldId ? getCustomFieldForId(state, customFieldId) : undefined));
  const { formatLocalDate } = useLocalizedFormats();
  const { dateRange } = useItemListWidgetContext();
  const hasTeamBasedPlanning = useHasTeamBasedPlanning();

  if (customFieldId && customField?.name) {
    if (customField?.name.includes('(Jira)')) {
      return <JiraFieldsDisplayText name={customField.name} />;
    } else {
      return <>{customField.name}</>;
    }
  }

  const defaultColumnHeader = <>{customColumnDefinitions[column]?.displayText ?? column}</>;

  switch (column) {
    case StandardColumns.Groups: {
      if (hasTeamBasedPlanning) {
        return <>Administrative Groups</>;
      }
      return defaultColumnHeader;
    }
    case StandardColumns.ScheduleBar:
      return <>{dateRange ? getDateRangeDisplay(dateRange, formatLocalDate) : 'Predictive Schedule'}</>;
    case StandardColumns.RemainingWorkRange:
      return <>Remaining Estimate</>;
    case StandardColumns.TotalWorkRange:
      return <>Total Work</>;

    default:
      return defaultColumnHeader;
  }
};

const HeaderCell = ({ columnDefinition }: { columnDefinition: WidgetConfigColumn }) => {
  const isScheduleColumn = columnDefinition.column === StandardColumns.ScheduleBar;

  return (
    <Table.HeaderCell
      content={<HeaderCellContent columnDefinition={columnDefinition} />}
      collapsing
      textAlign={isScheduleColumn ? 'left' : 'center'}
    />
  );
};

export const ListTableHeader = ({
  config,
  previewColumns,
}: {
  config: WidgetConfig;
  previewColumns: ReadonlyArray<WidgetConfigColumn>;
}) => {
  const listType = config.listType ?? 'Items';
  const listTypeHeader = listType === ListWidgetType.Users ? 'People' : capitalize(listType);
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content={listTypeHeader} />

        {previewColumns.map((column) => (
          <HeaderCell key={`${column.column}_${column.customFieldId}`} columnDefinition={column} />
        ))}
      </Table.Row>
    </Table.Header>
  );
};
