import { createAction } from '@reduxjs/toolkit';

import { ErrorPayload, SuccessPayload } from 'lib/api/types';

export const requestStarted = createAction<string>('request/REQUEST_STARTED');

export const requestFinishedSuccess = createAction(
  'request/REQUEST_FINISHED_SUCCESS',
  (uuid: string, success: true, result: SuccessPayload<any> | undefined, meta?: { skipReduce?: boolean }) => ({
    payload: { meta, resultPayload: result, success, uuid },
  }),
);

export const requestFinishedFailure = createAction(
  'requests/REQUEST_FINISHED_FAILURE',
  (uuid: string, success: false, result: ErrorPayload, meta?: { skipReduce?: boolean }) => ({
    payload: { meta, resultPayload: result, success, uuid },
  }),
);
