import classNames from 'classnames';
import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { ContentCategory } from 'daos/model_types';
import { backend } from 'daos/urls';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  fileAudioSolid,
  fileCodeSolid,
  fileCsvSolid,
  fileExcelSolid,
  fileImageSolid,
  fileLinesSolid,
  filePdfSolid,
  filePowerpointSolid,
  fileSolid,
  fileVideoSolid,
  fileWordSolid,
  fileZipperSolid,
  LpIcon,
} from 'features/common/lp_icon';
import { getFileForId } from 'state/entities/selectors/file';
import { gray600 } from 'style/variables';

import './file_icon_and_name.scss';

const getFileIcon = (fileType: ContentCategory | null | undefined) => {
  switch (fileType) {
    case ContentCategory.Audio:
      return fileAudioSolid;
    case ContentCategory.Code:
      return fileCodeSolid;
    case ContentCategory.CSV:
      return fileCsvSolid;
    case ContentCategory.Excel:
      return fileExcelSolid;
    case ContentCategory.Image:
      return fileImageSolid;
    case ContentCategory.PDF:
      return filePdfSolid;
    case ContentCategory.Powerpoint:
      return filePowerpointSolid;
    case ContentCategory.Text:
      return fileLinesSolid;
    case ContentCategory.Word:
      return fileWordSolid;
    case ContentCategory.Video:
      return fileVideoSolid;
    case ContentCategory.Archive:
      return fileZipperSolid;
    case ContentCategory.Chart:
    case ContentCategory.Other:
    default:
      return fileSolid;
  }
};

export const FileIconAndName = ({
  fileId,
  itemId,
  isLinkable = true,
}: {
  fileId: number;
  itemId: number;
  isLinkable?: boolean;
}) => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);

  const file = useSelector((state) => getFileForId(state, fileId));

  if (!file) {
    return null;
  }

  const isDeleted = !isLinkable;

  const { s3Id, contentCategory, filename } = file;

  const fileUrl = s3Id
    ? backend.itemFilesDownload.url({
        organizationId,
        workspaceId,
        itemId,
        s3id: s3Id,
      })
    : '';

  return (
    <FileIconAndNameRenderer
      contentCategory={contentCategory}
      filename={filename}
      isDeleted={isDeleted}
      isLinkable={isLinkable}
      fileUrl={fileUrl}
    />
  );
};

export const FileIconAndNameRenderer = ({
  fileUrl,
  filename,
  contentCategory,
  isLinkable = true,
  isDeleted = false,
}: {
  fileUrl: string;
  filename: string | null;
  contentCategory: ContentCategory | null;
  isLinkable?: boolean;
  isDeleted?: boolean;
}) => {
  return (
    <div className="file-icon-and-name">
      <LpIcon className="file-icon-and-name__file-icon" icon={getFileIcon(contentCategory)} color={gray600} size="lg" />
      <LpLink
        className={classNames('file-icon-and-name__file-name', { 'file-icon-and-name__file-name--deleted': isDeleted })}
        target="_blank"
        to={fileUrl}
        disabled={!isLinkable}
      >
        {filename}
      </LpLink>
      {isDeleted ? <span className="file-icon-and-name__file-deleted">{'(deleted)'}</span> : null}
    </div>
  );
};
