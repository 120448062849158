import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { InvitationDao } from 'daos/invitation';
import { Workspace } from 'daos/model_types';
import { OrganizationDao } from 'daos/organization';
import { OrganizationUserDao } from 'daos/organization_user';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentOrganizationId, getCurrentUserId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { setCurrentWorkspaceId } from 'features/common/current/slice';
import { LpIcon, globeSolid as workspaceIcon } from 'features/common/lp_icon';
import BeyondLimitModal from 'features/organization_directory/manage_account/beyond_limit_modal';
import { reduceWorkspaceIdsForInvitation } from 'features/organization_directory/users/invite/helpers';
import { InviteErrorModal } from 'features/organization_directory/users/invite/invite_errors';
import {
  DisconnectModal,
  DisconnectModalProps,
} from 'features/organization_directory/users/profile/workspace_memberships/disconnect_modal';
import { awaitRequestFinish } from 'lib/api';
import { ApiError, ErrorCodes } from 'lib/api/types';
import { frontend } from 'lib/urls';
import {
  getCurrentOrganizationUser,
  getOrganizationUserForId,
  getUserForId,
  getWorkspaceUsersById,
  getCurrentOrganizationWorkspaceUsersForUserIdByWorkspaceId,
} from 'state/entities/selectors/user';

enum AccessTooltips {
  OtherAdmins = "You must revoke this user's organization admin access before you may disconnect them",
  Yourself = 'You may not disconnect yourself from a workspace',
}

export const OrgDirUsersProfileWsMembershipsTable = ({ memberWorkspaces }: { memberWorkspaces: Array<Workspace> }) => {
  const dispatch = useDispatch();

  const { organizationUserId: organizationUserIdString } = useParams<{ organizationUserId: string }>();

  const profileOrganizationUserId = Number(organizationUserIdString);

  const workspaceUsers = useSelector(getWorkspaceUsersById);
  const organizationId = useSelector(getCurrentOrganizationId);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const currentUserId = useSelector(getCurrentUserId);
  const currentOrganizationUser = useSelector(getCurrentOrganizationUser);
  const currentOrganizationUserId = currentOrganizationUser?.id;

  const profileOrganizationUser = useSelector((state) => getOrganizationUserForId(state, profileOrganizationUserId));
  const profileUserId = profileOrganizationUser?.user?.id;
  const profileUser = useSelector((state) => (profileUserId ? getUserForId(state, profileUserId) : undefined));

  const workspaceUsersByWorkspaceId = useSelector((state) =>
    profileUserId ? getCurrentOrganizationWorkspaceUsersForUserIdByWorkspaceId(state, profileUserId) : {},
  );

  const isAdminProfile = profileOrganizationUser?.admin;

  const [invited, setInvited] = useState(new Set());

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [disconnectModalProps, setDisconnectModalProps] = useState<DisconnectModalProps | undefined>(undefined);

  const [shouldOpenBeyondLimit, setShouldOpenBeyondLimit] = useState(false);
  const [reconnectError, setReconnectError] = useState<ApiError>();

  const showLicenseLimitModal = () => {
    setShouldOpenBeyondLimit(true);
  };
  const hideLicenseLimitModal = () => {
    setShouldOpenBeyondLimit(false);
  };

  const handleInviteClick = (workspaceId: number, workspaceIds: { [workspaceId: string]: boolean }) => {
    // TODO: Add in response handling
    // https://relight-internal.liquidplannerlab.com/organization/19/workspace/19/item/225989
    if (profileUser) {
      dispatch(
        InvitationDao.create({
          email: profileUser.email,
          organization: OrganizationDao.id(organizationId),
          workspaces: reduceWorkspaceIdsForInvitation(workspaceIds),
          workspaceAccess: undefined,
        }),
      );
    }

    setInvited((prevSet) => prevSet.add(workspaceId) && new Set(prevSet));
  };

  const toggleModalState = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const handleDisconnectClick = (workspaceId: number, workspaceUserId: number) => {
    if (isAdminProfile) {
      return;
    }

    // this order matters - stale data in modal could occur if the toggle came before the setter (after the first open)
    setDisconnectModalProps({
      workspaceId,
      workspaceUserId,
      organizationUserId: profileOrganizationUserId,
      close: toggleModalState,
    });
    toggleModalState();
  };

  const fetchOrgUser = () => {
    const { uuid } = dispatch(
      OrganizationUserDao.fetch({ organizationId, organizationUserId: profileOrganizationUserId }),
    );
    dispatch(awaitRequestFinish(uuid, {}));
  };

  const handleConnectClick = (workspaceId: number, workspaceUserId: number) => {
    const { uuid } = dispatch(
      WorkspaceUserDao.update(
        { organizationId, workspaceId, workspaceUserId },
        { id: workspaceUserId, disconnected: false },
      ),
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onError: ({ errors }) => {
          if (errors[0]) {
            if (errors[0].code === ErrorCodes.LimitExceeded) {
              return showLicenseLimitModal();
            }
            setReconnectError(errors[0]);
          }
        },
        onSuccess: fetchOrgUser,
      }),
    );
  };

  const closeReconnectErrorModal = () => setReconnectError(undefined);

  const handleWorkspaceChange = (wsId: number) => () => {
    dispatch(setCurrentWorkspaceId(wsId));
  };

  return (
    <>
      {isModalOpen && disconnectModalProps && <DisconnectModal {...disconnectModalProps} />}

      {shouldOpenBeyondLimit && <BeyondLimitModal onClose={hideLicenseLimitModal} />}
      {reconnectError && <InviteErrorModal error={reconnectError} onClose={closeReconnectErrorModal} />}
      {!!memberWorkspaces.length && (
        <Table compact className="organization-directory__users-profile-membership-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={'Workspace Memberships'} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {memberWorkspaces.map((ws) => {
              const workspaceId = ws.id;
              const workspaceName = ws.name;

              const workspaceUserId = workspaceUsersByWorkspaceId[workspaceId]?.id ?? 0;
              const disconnectedAt = workspaceUsers[workspaceUserId]?.disconnectedAt;
              const isCurrentWorkspace = workspaceId === currentWorkspaceId;
              const isLoggedInUserAndCurrentWorkspace = isCurrentWorkspace && profileUserId === currentUserId;

              const currentWorkspaceOrDisconnected = () => {
                const workspaceNameText = disconnectedAt ? (
                  <>
                    <span className="organization-directory__users-profile-workspace-memberships-name--disconnected">
                      {workspaceName}
                    </span>
                    <span className="organization-directory__users-profile-workspace-memberships-name--disconnected-meta">
                      &nbsp; (Disconnected)
                    </span>
                  </>
                ) : (
                  workspaceName
                );

                if (isCurrentWorkspace) {
                  return (
                    <LpLink
                      className="organization-directory__users-profile-workspace-memberships-name"
                      to={frontend.workspace.url({ workspaceId, organizationId })}
                    >
                      {workspaceNameText}
                    </LpLink>
                  );
                }

                return (
                  <LpLink
                    className="organization-directory__users-profile-workspace-memberships-name"
                    to={frontend.workspaceHub.url({ organizationId, workspaceId })}
                    onClick={handleWorkspaceChange(workspaceId)}
                  >
                    {workspaceNameText}
                  </LpLink>
                );
              };

              const inviteConnectOrDisconnectButton = () => {
                if (!workspaceUserId && !invited.has(workspaceId)) {
                  return (
                    <Button
                      className="organization-directory__users-profile-workspace-memberships-tri-state-buttons"
                      onClick={() => handleInviteClick(workspaceId, { [workspaceId]: true })}
                      primary
                    >
                      Invite
                    </Button>
                  );
                }

                if (!workspaceUserId && invited.has(workspaceId)) {
                  return (
                    <div className="organization-directory__users-profile-workspace-memberships-tri-state-buttons">
                      Invite Sent
                    </div>
                  );
                }

                if (disconnectedAt) {
                  return (
                    <Button
                      onClick={() => handleConnectClick(workspaceId, workspaceUserId)}
                      className="organization-directory__users-profile-workspace-memberships-tri-state-buttons"
                      primary
                    >
                      Connect
                    </Button>
                  );
                }

                const lpIconTitle = () => {
                  if (isAdminProfile) {
                    return profileOrganizationUserId === currentOrganizationUserId
                      ? AccessTooltips.Yourself
                      : AccessTooltips.OtherAdmins;
                  }
                };

                return (
                  <Button
                    title={lpIconTitle()}
                    negative
                    onClick={() => handleDisconnectClick(workspaceId, workspaceUserId)}
                    className={classNames(
                      'organization-directory__users-profile-workspace-memberships-tri-state-buttons',
                      isAdminProfile &&
                        'organization-directory__users-profile-workspace-memberships-disconnect--is-admin',
                    )}
                  >
                    Disconnect
                  </Button>
                );
              };

              return (
                <Table.Row key={workspaceId}>
                  <Table.Cell className="organization-directory__users-profile-workspace-memberships-cell">
                    <LpIcon icon={workspaceIcon} /> &nbsp;
                    {currentWorkspaceOrDisconnected()}
                    &nbsp; {isLoggedInUserAndCurrentWorkspace ? '(Logged In)' : null}
                    {inviteConnectOrDisconnectButton()}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
