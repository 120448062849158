import { keyBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { AcademyOutpostTrackLesson } from 'features/academy/daos/model_types';
import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { getTrackLessonsById } from 'features/academy/selectors';
import { getOutpostsById } from 'features/academy/selectors/outpost';
import { getCurrentUserId } from 'features/common/current/selectors';
import { readonlyRecord } from 'lib/readonly_record';
import { createCacheByIdConfig } from 'state/entities/selectors/shared';
import { RootState } from 'state/root_reducer';

// track progress selectors
const getTrackProgressById = (state: RootState) => state.entities.academyTrackProgress;

const getCurrentUserTrackProgressByTrackId = createSelector(
  getTrackProgressById,
  getCurrentUserId,
  (trackProgressById, currentUserId) => {
    const currentUserIdAsString = String(currentUserId);

    const currentUserProgress = Object.values(trackProgressById).filter(
      (progress) => progress.userId === currentUserIdAsString,
    );
    return readonlyRecord(keyBy(currentUserProgress, (progress) => progress.academyTrackId));
  },
);

export const getCurrentUserTrackProgressForTrackId = createCachedSelector(
  getCurrentUserTrackProgressByTrackId,
  (_: RootState, trackId: string) => trackId,
  (currentUserTrackProgressByTrackId, trackId) => {
    return currentUserTrackProgressByTrackId[trackId];
  },
)(createCacheByIdConfig());

// lesson progress selectors
const getLessonProgressById = (state: RootState) => state.entities.academyLessonProgress;

const getCurrentUserLessonProgressByLessonId = createSelector(
  getLessonProgressById,
  getCurrentUserId,
  (lessonProgressById, currentUserId) => {
    const currentUserIdAsString = String(currentUserId);

    const currentUserProgress = Object.values(lessonProgressById).filter(
      (progress) => progress.userId === currentUserIdAsString,
    );
    return readonlyRecord(keyBy(currentUserProgress, (progress) => progress.academyLessonId));
  },
);

export const getCurrentUserLessonProgressForLessonId = createCachedSelector(
  getCurrentUserLessonProgressByLessonId,
  (_: RootState, lessonId: string) => lessonId,
  (currentUserLessonProgressByLessonId, lessonId) => {
    return currentUserLessonProgressByLessonId[lessonId];
  },
)(createCacheByIdConfig());

const getUnfinishedOutpostTrackLessonsForLocation = createSelector(
  (_: RootState, outpostLocation: OutpostLocation) => outpostLocation,
  getOutpostsById,
  getTrackLessonsById,
  getCurrentUserLessonProgressByLessonId,
  (outpostLocation, outpostTrackLessonsMap, trackLessonsMap, lessonProgressMap) => {
    return Object.values(outpostTrackLessonsMap)
      .filter((outpostTrackLesson) => outpostTrackLesson.outpost === outpostLocation)
      .reduce((unfinishedLessons: Array<AcademyOutpostTrackLesson>, currentLesson: AcademyOutpostTrackLesson) => {
        const trackLesson = trackLessonsMap[currentLesson.academyTrackLessonId];
        const lessonProgress = trackLesson ? lessonProgressMap[trackLesson.academyLessonId] : undefined;

        if (!lessonProgress) {
          unfinishedLessons.push(currentLesson);
        }
        return unfinishedLessons;
      }, []);
  },
);

export const getUnfinishedOutpostTrackLessonsForLocationOrderedByPriority = createSelector(
  getUnfinishedOutpostTrackLessonsForLocation,
  (lessons) => {
    return [...lessons].sort((a, b) => a.priority.localeCompare(b.priority));
  },
);
