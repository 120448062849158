import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

import { ReadonlyRecord } from 'lib/readonly_record';
import { resetStateExtraReducer } from 'state/root_actions';

type TaskIdsByTaskStatusId = ReadonlyRecord<number, ReadonlyArray<number>>;

interface TaskBoardState {
  readonly taskIds: ReadonlyArray<number>;
  readonly taskIdsIndexedByTaskStatusId: TaskIdsByTaskStatusId;
}

export const initialState: TaskBoardState = {
  taskIds: [],
  taskIdsIndexedByTaskStatusId: {},
};

const taskBoardSlice = createSlice({
  name: 'taskBoard',
  initialState,
  reducers: {
    clearTaskBoard: () => initialState,
    setTaskBoardData: (
      state,
      action: PayloadAction<{ taskIds: Array<number>; taskIdsIndexedByTaskStatusId: TaskIdsByTaskStatusId }>,
    ) => {
      state.taskIds = action.payload.taskIds;
      state.taskIdsIndexedByTaskStatusId = castDraft(action.payload.taskIdsIndexedByTaskStatusId);
    },
    setTaskBoardTaskIds: (state, action: PayloadAction<Array<number>>) => {
      state.taskIds = action.payload;
    },
    updateTaskIdsIndexedByTaskStatusId: (
      state,
      action: PayloadAction<{ taskId: number; sourceTaskStatusId: number; destinationTaskStatusId: number }>,
    ) => {
      const { taskId, sourceTaskStatusId, destinationTaskStatusId } = action.payload;
      const sourceTaskIdsByStatusId = state.taskIdsIndexedByTaskStatusId[sourceTaskStatusId] ?? [];
      const destinationTaskIdsByStatusId = state.taskIdsIndexedByTaskStatusId[destinationTaskStatusId] ?? [];

      state.taskIdsIndexedByTaskStatusId[sourceTaskStatusId] = sourceTaskIdsByStatusId.filter((id) => id !== taskId);
      state.taskIdsIndexedByTaskStatusId[destinationTaskStatusId] = destinationTaskIdsByStatusId.concat(taskId);
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { clearTaskBoard, setTaskBoardData, setTaskBoardTaskIds, updateTaskIdsIndexedByTaskStatusId } =
  taskBoardSlice.actions;

export default taskBoardSlice.reducer;
