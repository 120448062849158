import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { SchedulingLimitType } from 'daos/enums';
import { SchedulingLimit } from 'daos/model_types';
import { readonlyArray, groupRecordBy } from 'lib/readonly_record';
import { createCacheByIdConfig, getNumberArgument } from 'state/entities/selectors/shared';
import { getCurrentOrganizationUsersByUserId, getWorkspaceUsersByUserId } from 'state/entities/selectors/user';
import { EntityLookupById } from 'state/entities/types';
import { RootState } from 'state/root_reducer';

const emptySchedulingLimitArray = readonlyArray<SchedulingLimit>([]);

const getSchedulingLimitsById = (state: RootState): EntityLookupById<SchedulingLimit> =>
  state.entities.schedulingLimits;

export const getSchedulingLimitForId = (state: RootState, id: number) => getSchedulingLimitsById(state)[id];

const getSchedulingLimitsByItemId = createSelector(getSchedulingLimitsById, (schedulingLimitsById) => {
  return groupRecordBy(schedulingLimitsById, (schedulingLimit) => schedulingLimit.item.id);
});

export const getSchedulingLimitsForItemId = createCachedSelector(
  getSchedulingLimitsByItemId,
  getNumberArgument,
  (schedulingLimitsByItemId, itemId) => {
    return schedulingLimitsByItemId[itemId] ?? emptySchedulingLimitArray;
  },
)(createCacheByIdConfig());

export interface SortableLimitFormat {
  name: string;
  limitType: SchedulingLimitType;
  limitSeconds: number;
  limitId: number;
  disconnectedLimitUserId: number | null;
}

export const getSchedulingLimitsForItemIdForDisplay = createSelector(
  getSchedulingLimitsForItemId,
  getCurrentOrganizationUsersByUserId,
  getWorkspaceUsersByUserId,
  (limits: ReadonlyArray<SchedulingLimit>, orgUsersByUserId, wsUsersByUserId) => {
    return limits.map((limit) => {
      const orgUser = orgUsersByUserId[limit.user.id];
      const wsUser = wsUsersByUserId[limit.user.id]?.[0];
      const userName = orgUser?.username;

      const isDisconnected = Boolean(wsUser?.disconnectedAt?.length) || Boolean(orgUser?.disconnectedAt?.length);

      return {
        name: userName ?? 'Unknown',
        limitType: limit.limitType,
        limitSeconds: limit.limitSeconds,
        limitId: limit.id,
        disconnectedLimitUserId: isDisconnected ? limit.user.id : null,
      };
    });
  },
);
