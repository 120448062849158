import { useSelector } from 'react-redux';

import { displayName } from 'lib/helpers/display_name_helpers';
import { getOrganizationUserInCurrentOrgForUserId, getUserForId } from 'state/entities/selectors/user';

export const useUserFirstLastOrUsername = (userId: number | undefined) => {
  const orgUser = useSelector((state) =>
    userId ? getOrganizationUserInCurrentOrgForUserId(state, userId) : undefined,
  );
  const user = useSelector((state) => (userId ? getUserForId(state, userId) : undefined));

  if (!user || !orgUser) {
    return undefined;
  }

  return displayName({
    firstName: user.firstName,
    lastName: user.lastName,
    username: orgUser.username,
    userType: user.userType,
  });
};

export const useUsernameForUserId = (userId: number | undefined) => {
  const orgUser = useSelector((state) =>
    userId ? getOrganizationUserInCurrentOrgForUserId(state, userId) : undefined,
  );
  return orgUser?.username;
};
