import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { OrganizationUser } from 'daos/model_types';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { useFilterDropdownOptions } from 'features/common/inputs/dropdowns/user_dropdown/shared/use_filter_dropdown_options';
import { getWorkspaceUsersForWorkspaceIdIndexedByUserId } from 'state/entities/selectors/user';

import './use_org_user_dropdown_options.scss';

export const useOrgUserDropdownOptions = ({
  orgUsers,
  selectedOrgUserId,
  clearable,
  filterDisconnected,
  existingIds = [],
}: {
  orgUsers: ReadonlyArray<OrganizationUser>;
  selectedOrgUserId?: number;
  clearable: DropdownProps['clearable'];
  filterDisconnected?: boolean;
  existingIds?: Array<number | undefined>;
}) => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const currentWorkspaceUsersByUserId = useSelector((state) =>
    getWorkspaceUsersForWorkspaceIdIndexedByUserId(state, workspaceId),
  );

  const filteredOrgUsers = orgUsers.filter((orgUser) => {
    return !existingIds.includes(orgUser.id);
  });

  const { filterDisconnectedUsers } = useFilterDropdownOptions();

  const dropdownOptions = useMemo(() => {
    const options: Array<DropdownItemProps & { search: string }> = filteredOrgUsers.map((orgUser) => {
      const workspaceUser = currentWorkspaceUsersByUserId[orgUser.user.id];
      const isDisconnected = !!workspaceUser?.disconnectedAt;
      return {
        text: (
          <span className={classNames('dropdown-field__user', isDisconnected && 'evicted-user')}>
            <UserAvatar orgUserId={orgUser.id} size={AvatarSize.ExtraSmall_1_2} />
            {orgUser.username}
          </span>
        ),
        value: orgUser.id,
        search: orgUser.username.toLocaleLowerCase(),
        key: orgUser.id,
      };
    });

    return filterDisconnected
      ? filterDisconnectedUsers({ dropdownOptions: options, selectedOrgUserId, clearable })
      : options;
  }, [
    clearable,
    currentWorkspaceUsersByUserId,
    filterDisconnected,
    filterDisconnectedUsers,
    filteredOrgUsers,
    selectedOrgUserId,
  ]);

  return dropdownOptions;
};
