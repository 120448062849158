import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectPickerModal } from 'containers/shared/template_picker/common';
import { ItemDao } from 'daos/item';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { pluralize } from 'lib/helpers';

import './template_picker.scss';

interface TemplatePickerModalProps {
  handleCloseModal: () => void;
  parentItemId: number;
  fetchItems?: () => void;
}

const TemplatePickerModal = ({ handleCloseModal, parentItemId, fetchItems }: TemplatePickerModalProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const [selectedProjects, setSelectedProjects] = useState<Set<number>>(new Set());

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateSelectedProjectsSet = (id: number) => {
    if (selectedProjects.has(id)) {
      selectedProjects.delete(id);
    } else {
      selectedProjects.add(id);
    }
    setSelectedProjects(new Set(selectedProjects));
  };

  const handleTemplateCreatedEvent = () => {
    if (loading) {
      fetchItems && fetchItems();
      handleCloseModal();
      setLoading(false);
    }
  };

  useEvents({
    event: Events.ASYNC_JOB,
    callback: handleTemplateCreatedEvent,
    scope: EventScopes.None,
  });

  const handleSubmit = () => {
    dispatch(
      ItemDao.duplicateBulk(
        {
          organizationId,
          workspaceId,
        },
        Array.from(selectedProjects),
        parentItemId,
      ),
    );
    setLoading(true);
  };

  return (
    <ProjectPickerModal
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
      selected={selectedProjects}
      setSelected={setSelectedProjects}
      updateSelected={updateSelectedProjectsSet}
      actionButtonDisabled={selectedProjects.size <= 0 || loading}
      actionButtonText={`Add ${pluralize('Project', selectedProjects.size)}`}
      loading={loading}
    />
  );
};

export default TemplatePickerModal;
