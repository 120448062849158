import { StandardColumns } from 'containers/shared/custom_column/enum';
import { NonCustomFieldColumnOption } from 'containers/shared/custom_column/types';
import { FolderFilterStatus } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';
import { FeatureFlag } from 'lib/feature_flags';
import { ReadonlyRecord } from 'lib/readonly_record';

import {
  AccessType,
  BillingPeriod,
  ChangeModelType,
  FolderStatusFilter,
  IntakeWidgetType,
  ItemType,
  ListWidgetType,
  PackageStatus,
  Permission,
  PlanFamily,
  PropertyChangeHistoryProperties,
  StatusFilterGroups,
  SubscriptionStatus,
  TrialStatus,
  WidgetDataErrorType,
  WidgetOnClickViewType,
} from './enums';
import { RelativePriorityType } from './item_enums';
import { WidgetConfigColumn } from './widget_column';

export interface Currency {
  readonly currencyCode: string;
  readonly displayName: string;
  readonly symbol: string;
}
export interface CustomFieldFilter {
  readonly customFieldId: number;
  readonly textValues: ReadonlyArray<string> | null;
  readonly currencyValues: ReadonlyArray<number> | null;
  readonly numberValues: ReadonlyArray<number> | null;
  readonly dateValues: ReadonlyArray<string> | null;
  readonly checkedValues: ReadonlyArray<boolean> | null;
  readonly picklistChoiceIdValues: ReadonlyArray<number> | null;
  readonly itemIdValues: ReadonlyArray<string> | null;
  readonly workspaceUserIdValues: ReadonlyArray<number> | null;
  readonly urlValues: ReadonlyArray<string> | null;
  readonly htmlValues: ReadonlyArray<string> | null;
}

export type CustomFieldFilterWithRequiredId = Partial<CustomFieldFilter> & Pick<CustomFieldFilter, 'customFieldId'>;

export type DailyWork = ReadonlyRecord<string, string | null>;

export type DashboardV2Config = LocationFilterSettings;

export type InvitationWorkspaceAccess = Permission | 'orgAdmin' | 'workspaceManager';
export interface ItemAccessForSubjectGridRow {
  readonly itemId: number;
  readonly manage: AccessType;
  readonly edit: AccessType;
  readonly observe: AccessType;
  readonly expandedChildRows: ReadonlyArray<ItemAccessForSubjectGridRow> | null;
}

export interface ExternalIntegrationIncludes {
  jiraIssueFilters?: boolean;
  externalIntegrationFieldMappings?: boolean;
  jiraAccessibleResourcesView?: boolean;
  jiraSyncSettings?: boolean;
  syncProjectJobs?: boolean;
  items?: boolean;
  oauthCredentialsUsers?: boolean;
}

export type ItemIncludes = {
  includeAlerts?: boolean;
  includeAncestorsItemMetrics?: boolean;
  includeAncestorPredecessorDependencies?: boolean;
  includeAncestors?: boolean;
  includeAncestorSuccessorDependencies?: boolean;
  includeAssignments?: boolean;
  includeChildItemMetrics?: boolean;
  includeChildOrganizationUser?: boolean;
  includeChildren?: boolean;
  includeChildrenFieldValues?: boolean;
  includeChildrenPredecessorDependencies?: boolean;
  includeChildrenSuccessorDependencies?: boolean;
  includeChildrenTimesheetEntries?: boolean;
  includeDashboards?: boolean;
  includeDiscussions?: boolean;
  includeExternalIntegrations?: boolean;
  includeExternalIntegrationsSyncSettings?: boolean;
  includeEstimates?: boolean;
  includeFields?: boolean;
  includeItemAcls?: boolean;
  includeItemMetrics?: boolean;
  includeItemPins?: boolean;
  includeItemWorkGroups?: boolean;
  includeIterations?: boolean;
  includeParent?: boolean;
  includePredecessorDependencies?: boolean;
  includeSchedulingLimits?: boolean;
  includeSuccessorDependencies?: boolean;
  includeStoryPoint?: boolean;
  includeTaskStoryPointSchemesOwnersAndPoints?: boolean;
  includeTasks?: boolean;
  includeTaskStatus?: boolean;
  includeTimesheetEntries?: boolean;
  includeFiles?: boolean;
  includeItemFiles?: boolean;
  includeTimesheetEntryLocks?: boolean;
  includeTimesheetEntryLockExceptions?: boolean;
  includeOrganizationUser?: boolean;
  includeWorkspacePicklistChoices?: boolean;
  includeWorkspaceUsers?: boolean;
} & (
  | { includeEffectiveFieldValues?: never; includeFieldValues?: never }
  | { includeEffectiveFieldValues: boolean; includeFieldValues?: never }
  | { includeEffectiveFieldValues?: never; includeFieldValues: boolean }
);
export interface ItemOptions {
  include?: ItemIncludes;
  skipReduce?: boolean;
  filter?: string;
  query?: {
    limit?: number;
    order?: string;
    columnHeaders?: string;
    itemNameHeader?: string;
    continuationToken?: number | null;
  };
}

// Intentionally using StandardColumnOptions for these because widget columns must correspond with the server-side enum
interface ListWidgetDefaultSystemColumns {
  [ListWidgetType.Packages]: ReadonlyArray<StandardColumns>;
  [ListWidgetType.Projects]: ReadonlyArray<StandardColumns>;
  [ListWidgetType.Folders]: ReadonlyArray<StandardColumns>;
  [ListWidgetType.Tasks]: ReadonlyArray<StandardColumns>;
  [ListWidgetType.Assignment]: ReadonlyArray<StandardColumns>;
  [ListWidgetType.Picklists]: ReadonlyArray<StandardColumns>;
  [ListWidgetType.Users]: ReadonlyArray<StandardColumns>;
}

export interface LocationFilterSettings
  extends LocationFilterAssigneeSettings,
    LocationFilterItemSettings,
    LocationFilterProjectSettings,
    LocationFilterTaskSettings,
    DateRangeFilterSettings {}

export interface LocationFilterAssigneeSettings {
  readonly groupIdFilter: number | null;
  readonly organizationUserIdFilter: number | null;
}

export interface LocationFilterItemSettings {
  readonly locationFilterItemId: string | null;
  readonly locationFilterPackageStatus: PackageStatus | null;
}

export interface LocationFilterProjectSettings {
  readonly projectCreatedByUserId: number | null;
  readonly projectCustomFieldFilters: ReadonlyArray<CustomFieldFilterWithRequiredId> | null;
  readonly projectNameFilter: string | null;
  readonly projectScheduleRiskOnly: boolean;
  readonly projectHasFileFilter: boolean;
  readonly projectWorkLimitRiskOnly: boolean;
  readonly projectStatusFilter: FolderStatusFilter | null;
  readonly statusFilterSelection?: FolderFilterStatus | StatusFilterGroups;
}

export interface LocationFilterTaskSettings {
  readonly taskCreatedByUserId: number | null;
  readonly taskCustomFieldFilters: ReadonlyArray<CustomFieldFilterWithRequiredId> | null;
  readonly taskNameFilter: string | null;
  readonly taskScheduleDirectiveAsapOnly: boolean; // asap in project/package/workspace
  readonly taskScheduleRiskOnly: boolean;
  readonly taskWorkLimitRiskOnly: boolean;
  readonly taskStatusFilter: StatusFilterGroups | null; // asap, atRisk, and none are not valid options
  readonly taskStatusIdsFilter: ReadonlyArray<number> | null;
  readonly taskHasFileFilter: boolean;
  readonly statusFilterSelection?: FolderFilterStatus | StatusFilterGroups;
}

export type OrganizationFeatureFlags = Readonly<{
  readonly [Property in FeatureFlag]: boolean;
}>;

export interface OrganizationFlags {
  readonly taskCount: number;
  readonly maxTaskCount: number | null;
  readonly maxTaskCountHardLimit: number | null;

  readonly fileLimitInGb: number | null;
  readonly sumFileSizeInMb: number | null;

  readonly projectCount: number;
  readonly maxProjectCount: number | null;
  readonly maxProjectCountHardLimit: number | null;

  readonly workspaceCount: number;
  readonly maxWorkspaceCount: number | null;

  readonly licensesPending: number;
  readonly licensesUsed: number;
  readonly totalLicenses: number;

  readonly resourceCount: number;
  readonly maxResourceCount: number;

  readonly hasApi: boolean;
  readonly hasFileFeature: boolean;
  readonly hasDataCustomization: boolean;
  readonly hasSso: boolean;
  readonly hasSlackIntegration: boolean;
  readonly hasUpgradedAccessControls: boolean;
  readonly hasUpgradedTimeTracking: boolean;
  readonly premiumFeaturesTrial: TrialStatus;
  readonly subscriptionStatus: SubscriptionStatus;
  readonly trialEnd: string | null;
  readonly allowSelfService: boolean;
}

export interface PlanPricingTier {
  readonly startingUnit: number;
  readonly endingUnit: number | null;
  readonly priceCents: number;
  readonly monthlyPriceCents: number;
}

// Intentionally using StandardColumnOptions for these because these columns are provided by the application server
export interface ColumnsSelection {
  [ItemType.PACKAGES]: ReadonlyArray<StandardColumns>;
  [ItemType.PROJECTS]: ReadonlyArray<StandardColumns>;
  [ItemType.FOLDERS]: ReadonlyArray<StandardColumns>;
  [ItemType.TASKS]: ReadonlyArray<StandardColumns>;
  [ItemType.ASSIGNMENTS]: ReadonlyArray<StandardColumns>;
}

export interface PropertyChange {
  readonly value: any;
}
export interface PropertyChangeHistory {
  readonly new?: PropertyChange;
  readonly old?: PropertyChange;
  readonly property: PropertyChangeHistoryProperties;
}

export interface ChangeEntity {
  id: number;
  type: ChangeModelType;
}

interface RateColumn {
  readonly family: PlanFamily;
  readonly planId: number;
  readonly monthlyPlanEquivalent: number | null;

  readonly licenseCents: number | null;
  readonly monthlyLicenseCents: number | null;
  readonly licenseSavings: number | null;

  readonly taskLimit: number | null;
  readonly projectLimit: number | null;
  readonly workspaceLimit: number | null;
  readonly fileStorageLimitInGB: number | null;
}

export interface RateTable {
  readonly maxLicenseSavings: number | null;
  readonly billingPeriod: BillingPeriod;
  readonly free: RateColumn | null;
  readonly essentials: RateColumn | null;
  readonly professional: RateColumn | null;
  readonly ultimate: RateColumn | null;
}

export interface RelativePriority {
  readonly id?: number;
  readonly type: RelativePriorityType;
}

export interface SubjectAccessGridRow {
  readonly workspaceManager: boolean;
  readonly everyone: boolean;
  readonly groupId: number | null;
  readonly workspaceUserId: number | null;
  readonly manage: AccessType;
  readonly edit: AccessType;
  readonly observe: AccessType;
}
export interface SystemColumns {
  readonly iterationTable: ReadonlyArray<StandardColumns>;
  readonly iterationTableDefaults: ReadonlyArray<StandardColumns>;
  readonly listWidgetDefaults: ListWidgetDefaultSystemColumns;
  readonly listWidgets: ColumnsSelection;
  readonly metricsWidget: ReadonlyArray<StandardColumns>;
  readonly metricsWidgetDefaults: ReadonlyArray<StandardColumns>;
  readonly peopleReportWidget: ReadonlyArray<StandardColumns>;
  readonly ppp: ColumnsSelection;
  readonly propertiesWidget: ReadonlyArray<StandardColumns>;
  readonly propertiesWidgetDefaults: ReadonlyArray<StandardColumns>;
}

// Columns will be a NonCustomFieldColumnOption or a string of the customFieldId
export type UserCustomColumn = ReadonlyArray<NonCustomFieldColumnOption | string>;

export interface WeekData {
  readonly weekNumber: number;
  readonly weekStart: string;
  readonly weekEnd: string;
  readonly workSeconds: number;
}

export type WidgetGroupConfig = LocationFilterSettings & DateRangeFilterSettings & {};

export type WidgetConfig = LocationFilterSettings & {
  readonly title: string | null;
  readonly columns: ReadonlyArray<WidgetConfigColumn> | null;
  readonly content: string | null;
  readonly customFieldId: number | null;
  readonly showAlertIcons: boolean;
  readonly showTotals: boolean;
  readonly includePlannedHours: boolean | null;
  readonly intakeType: IntakeWidgetType | null;
  readonly listType: ListWidgetType | null;
  readonly s3Id: string | null;
  readonly onClickView: WidgetOnClickViewType | null;
  readonly onClickViewFieldId: number | null;
  readonly listPreviewRows: number | null;
  readonly listPreviewColumns: number | null;
};

export interface DateRangeFilterSettings {
  readonly dateRangeFilterType: string | null;
  readonly dateRangeFilterFrom: string | null;
  readonly dateRangeFilterTo: string | null;
  readonly dateRangeIncludeAllOverride: boolean;
}

export interface WidgetData {
  errors: ReadonlyArray<WidgetDataErrorType>;
}

export enum DateRangeType {
  AllDates = 'allDates',
  CalendarDates = 'calendarDates',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  NextMonth = 'nextMonth',
  YearToDate = 'yearToDate',
  ThreeWeekWindow = 'threeWeekWindow',
}
