import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { OrganizationUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { useHasAccess } from 'hooks/use_has_access';
import { FormatSettingsToDisplay } from 'lib/localization';
import { DateFormat, FirstDayOfWeek, NumberFormat, TimeFormat, WeekNumberFormat } from 'lib/localization_enums';
import { getWorkspaceUserForOrganizationUserId } from 'state/entities/selectors/user';

interface OrgUserFormatSettingsDropdownProps {
  className?: string;
  orgUserId: number;
  orgUserProperty: Partial<OrganizationUser>;
  formatSettingToDisplay: FormatSettingsToDisplay;
  formatSettingsEnum:
    | typeof TimeFormat
    | typeof NumberFormat
    | typeof DateFormat
    | typeof FirstDayOfWeek
    | typeof WeekNumberFormat;
  orgUserPropertyString: keyof OrganizationUser;
  updateOrgUser: (format: Partial<OrganizationUser>) => void;
}

const OrgUserFormatSettingsDropdown = ({
  orgUserId,
  updateOrgUser,
  formatSettingToDisplay,
  orgUserProperty,
  orgUserPropertyString,
  formatSettingsEnum,
  className,
}: OrgUserFormatSettingsDropdownProps) => {
  const wsUser = useSelector((state) => getWorkspaceUserForOrganizationUserId(state, orgUserId));
  const hasEditAccess = useHasAccess(Permission.EDIT, OrganizationUserDao.id(orgUserId));

  const formatDropdownOptions = Object.values(formatSettingsEnum).map((formatSettingOption, idx) => {
    const formatSetting = formatSettingOption as keyof typeof formatSettingsEnum;

    return {
      text: formatSettingToDisplay[formatSetting],
      value: formatSettingOption,
      key: formatSettingOption + idx,
    };
  });

  const handleFormatUpdate = (_: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    if (value !== orgUserProperty) {
      const newFormatSettingUnknown = value as unknown;
      const newFormatSetting = newFormatSettingUnknown as typeof formatSettingsEnum;

      updateOrgUser({ [orgUserPropertyString]: newFormatSetting });
    }
  };

  if (!orgUserProperty || !wsUser) {
    return null;
  }

  const orgUserPropertyValueUnknown = Object.values(orgUserProperty)[0] as unknown;
  const orgUserPropertyValue = orgUserPropertyValueUnknown as keyof typeof formatSettingsEnum;
  const textToDisplay = formatSettingToDisplay[orgUserPropertyValue];

  return (
    <Dropdown
      className={classNames(className)}
      disabled={!hasEditAccess}
      icon={null}
      onChange={handleFormatUpdate}
      selectOnNavigation={false}
      options={formatDropdownOptions}
      placeholder="None"
      search
      selection
      text={textToDisplay}
      value={orgUserPropertyValueUnknown as string}
    />
  );
};

export default OrgUserFormatSettingsDropdown;
