import { createSelector } from 'reselect';

import {
  defaultPropsWidgetColumn,
  defaultSortableWidgetConfigColumn,
  mapColumnOptionsToWidgetColumnDefinitions,
  MetricWidgetColumn,
  PropsWidgetColumn,
} from 'daos/widget_column';
import { getColumnSelectionList } from 'features/common/selection_list';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import {
  getMetricsWidgetColumnsExceptTaskStatus,
  getMetricsWidgetDefaultColumns,
  getPropertiesWidgetDefaultColumns,
  getSystemFeatureFlagDefaults,
} from 'state/entities/selectors/system_settings';
import { getActiveCurrentWorkspaceTaskStatusesSortedByPriority } from 'state/entities/selectors/task_status';

export const getDefaultPropertiesWidgetColumns = createSelector(getPropertiesWidgetDefaultColumns, (columns) =>
  mapColumnOptionsToWidgetColumnDefinitions<PropsWidgetColumn>(defaultPropsWidgetColumn, columns),
);

export const getDefaultMetricWidgetColumns = createSelector(getMetricsWidgetDefaultColumns, (columns) =>
  mapColumnOptionsToWidgetColumnDefinitions<MetricWidgetColumn>(defaultSortableWidgetConfigColumn, columns),
);

export const getMetricsWidgetColumns = createSelector(
  getMetricsWidgetColumnsExceptTaskStatus,
  getActiveCurrentWorkspaceTaskStatusesSortedByPriority,
  getSystemFeatureFlagDefaults,
  getCurrentOrganization,
  (columns, taskStatuses) =>
    getColumnSelectionList({
      columnOptions: columns,
      taskStatuses,
    }),
);
