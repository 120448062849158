import { noop } from 'lodash';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { DraggableId, FluidDragActions, Position, PreDragActions, SensorAPI } from 'react-beautiful-dnd';

const tab = 9;
const enter = 13;
const escape = 27;

type PredicateFn<T> = (value: T) => boolean;

function findIndex<T>(list: Array<T>, predicate: PredicateFn<T>): number {
  if (list.findIndex) {
    return list.findIndex(predicate);
  }

  // Using a for loop so that we can exit early
  for (let i = 0; i < list.length; i++) {
    const each = list[i];

    if (each && predicate(each)) {
      return i;
    }
  }

  // Array.prototype.find returns -1 when nothing is found
  return -1;
}

function find<T>(list: Array<T>, predicate: PredicateFn<T>): T | null | undefined {
  if (list.find) {
    return list.find(predicate);
  }

  const index: number = findIndex(list, predicate);

  if (index !== -1) {
    return list[index];
  }

  // Array.prototype.find returns undefined when nothing is found
  return undefined;
}

type KeyMap = Record<number, true>;
const preventedKeys: KeyMap = {
  [enter]: true,
  [tab]: true,
};
function preventStandardKeyEvents(event: KeyboardEvent) {
  if (preventedKeys[event.keyCode]) {
    event.preventDefault();
  }
}

const supportedPageVisibilityEventName: string = ((): string => {
  const base = 'visibilitychange';

  // Server side rendering
  if (typeof document === 'undefined') {
    return base;
  }

  // See https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
  const candidates: Array<string> = [base, `ms${base}`, `webkit${base}`, `moz${base}`, `o${base}`];
  const supported: string | null | undefined = find(
    candidates,
    (eventName: string): boolean => `on${eventName}` in document,
  );
  return supported || base;
})();

type UnbindFn = () => void;

function getOptions(shared?: EventOptions, fromBinding?: EventOptions | null | undefined): EventOptions {
  return { ...shared, ...fromBinding };
}

function bindEvents(doc: Document, bindings: Array<EventBinding>, sharedOptions?: EventOptions) {
  const unbindings: Array<UnbindFn> = bindings.map((binding: EventBinding): UnbindFn => {
    const options: EventOptions = getOptions(sharedOptions, binding.options);

    doc.addEventListener(binding.eventName, binding.fn, options);
    return function unbind() {
      doc.removeEventListener(binding.eventName, binding.fn, options);
    };
  });
  // Return a function to unbind events
  return function unbindAll() {
    unbindings.forEach((unbind: UnbindFn) => {
      unbind();
    });
  };
}

interface Idle {
  type: 'IDLE';
}
interface Pending {
  type: 'PENDING';
  point: Position;
  actions: PreDragActions;
}
interface Dragging {
  type: 'DRAGGING';
  actions: FluidDragActions;
}
type Phase = Idle | Pending | Dragging;
const idle: Idle = {
  type: 'IDLE',
};
interface GetCaptureArgs {
  cancel: () => void;
  completed: () => void;
  getPhase: () => Phase;
  setPhase: (phase: Phase) => void;
}

interface EventOptions {
  passive?: boolean;
  capture?: boolean;
  // sometimes an event might only event want to be bound once
  once?: boolean;
}
interface EventBinding {
  eventName: string;
  fn: (...args: Array<any>) => any;
  options?: EventOptions;
}

function coords(e: PointerEvent) {
  return { x: e.pageX, y: e.pageY };
}

function getCaptureBindings({ cancel, completed, getPhase, setPhase }: GetCaptureArgs): Array<EventBinding> {
  return [
    {
      eventName: 'pointermove',
      fn: (event: PointerEvent) => {
        const point = coords(event);

        if (!point) {
          return;
        }

        const phase = getPhase();

        // Already dragging
        if (phase.type === 'DRAGGING') {
          // preventing default as we are using this event
          event.preventDefault();
          phase.actions.move(point);
          return;
        }

        // There should be a pending drag at this point
        if (phase.type !== 'PENDING') {
          return;
        }

        // preventing default as we are using this event
        event.preventDefault();
        // Lifting at the current point to prevent the draggable item from
        // jumping by the sloppyClickThreshold
        const actions: FluidDragActions = phase.actions.fluidLift(point);
        setPhase({
          type: 'DRAGGING',
          actions,
        });
      },
    },
    {
      eventName: 'pointercancel',
      fn: cancel,
    },
    {
      eventName: 'pointerup',
      fn: (event: PointerEvent) => {
        const phase = getPhase();

        if (phase.type !== 'DRAGGING') {
          cancel();
          return;
        }

        // preventing default as we are using this event
        event.preventDefault();
        phase.actions.drop({
          shouldBlockNextClick: true,
        });
        completed();
      },
    },
    {
      eventName: 'pointerdown',
      fn: (event: PointerEvent) => {
        // this can happen during a drag when the user clicks a button
        // other than the primary mouse button
        if (getPhase().type === 'DRAGGING') {
          event.preventDefault();
          return;
        }

        cancel();
      },
    },
    {
      eventName: 'keydown',
      fn: (event: KeyboardEvent) => {
        const phase = getPhase();

        // Abort if any keystrokes while a drag is pending
        if (phase.type === 'PENDING') {
          cancel();
          return;
        }

        // cancelling a drag
        if (event.keyCode === escape) {
          event.preventDefault();
          cancel();
          return;
        }

        preventStandardKeyEvents(event);
      },
    },
    {
      eventName: 'resize',
      fn: cancel,
    },
    {
      eventName: 'scroll',
      // kill a pending drag if there is a window scroll
      options: {
        passive: true,
        capture: false,
      },
      fn: () => {
        if (getPhase().type === 'PENDING') {
          cancel();
        }
      },
    }, // Cancel on page visibility change
    {
      eventName: supportedPageVisibilityEventName,
      fn: cancel,
    },
  ];
}

export function usePointerEventsSensor(api: SensorAPI) {
  const phaseRef = useRef<Phase>(idle);
  const unbindEventsRef = useRef<() => void>(noop);
  const startCaptureBinding: EventBinding = useMemo(
    () => ({
      eventName: 'pointerdown',
      fn: (event: PointerEvent) => {
        // Event already used
        if (event.defaultPrevented) {
          return;
        }

        // Do not start a drag if any modifier key is pressed
        if (event.ctrlKey || event.metaKey || event.shiftKey || event.altKey) {
          return;
        }

        const draggableId: DraggableId | null | undefined = api.findClosestDraggableId(event);

        if (!draggableId) {
          return;
        }

        const actions: PreDragActions | null | undefined = api.tryGetLock(
          draggableId, // stop is defined later
          stop,
          {
            sourceEvent: event,
          },
        );

        if (!actions) {
          return;
        }

        const point = coords(event);
        if (!point) {
          return;
        }
        // consuming the event
        event.preventDefault();

        // unbind this listener
        unbindEventsRef.current();
        // using this function before it is defined as their is a circular usage pattern
        startPendingDrag(actions, point);
      },
    }), // not including startPendingDrag as it is not defined initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api],
  );

  const listenForCapture = useCallback(
    function listenForCapture() {
      const options = {
        capture: true,
        passive: false,
      };

      unbindEventsRef.current = bindEvents(
        document,
        [
          startCaptureBinding,
          {
            eventName: 'dragstart',
            fn: noop,
          },
        ],
        options,
      );
    },
    [startCaptureBinding],
  );

  const stop = useCallback(() => {
    const current: Phase = phaseRef.current;

    if (current.type === 'IDLE') {
      return;
    }

    phaseRef.current = idle;
    unbindEventsRef.current();
    listenForCapture();
  }, [listenForCapture]);

  const cancel = useCallback(() => {
    const phase: Phase = phaseRef.current;

    stop();

    if (phase.type === 'DRAGGING') {
      phase.actions.cancel({
        shouldBlockNextClick: true,
      });
    }

    if (phase.type === 'PENDING') {
      phase.actions.abort();
    }
  }, [stop]);

  const bindCapturingEvents = useCallback(
    function bindCapturingEvents() {
      const options = {
        capture: true,
        passive: false,
      };
      const bindings: Array<EventBinding> = getCaptureBindings({
        cancel,
        completed: stop,
        getPhase: () => phaseRef.current,
        setPhase: (phase: Phase) => {
          phaseRef.current = phase;
        },
      });
      unbindEventsRef.current = bindEvents(document, bindings, options);
    },
    [cancel, stop],
  );

  const startPendingDrag = useCallback(
    function startPendingDrag(actions: PreDragActions, point: Position) {
      phaseRef.current = {
        type: 'PENDING',
        point,
        actions,
      };
      bindCapturingEvents();
    },
    [bindCapturingEvents],
  );

  useLayoutEffect(
    function mount() {
      listenForCapture();
      // kill any pending window events when unmounting
      return () => {
        unbindEventsRef.current();
      };
    },
    [listenForCapture],
  );
}
