import { useSelector } from 'react-redux';

import { Widget } from 'daos/model_types';
import { getInheritedLocationScopeForWidgetId } from 'features/dashboards_v2/selectors';
import { getItemForId } from 'state/entities/selectors/item';

export function useGetItemScopeForWidget(widget?: Widget | null) {
  const item = useSelector((state) => {
    if (widget) {
      return widget.config.locationFilterItemId
        ? getItemForId(state, Number(widget.config.locationFilterItemId))
        : getInheritedLocationScopeForWidgetId(state, widget.id.toString())?.locationFilterItem;
    }
  });

  return item;
}
