import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { TaskStatusIcon } from 'containers/shared/task_icon_maker';
import { ItemType, SchedulingType } from 'daos/enums';
import { Item } from 'daos/model_types';
import { clearBulkData } from 'features/common/bulk_selection/slice';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, circleSmallSolid } from 'features/common/lp_icon';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { getItemNameForItem } from 'lib/display_helpers/item_display_name';
import { frontend } from 'lib/urls';
import { isAssignment, getItemForId } from 'state/entities/selectors/item';
import { slate700 } from 'style/variables';

import ItemIcon from './item_icon';
import LpLink from './lp_link';

import './item_link.scss';

interface ItemLinkProps {
  className?: string;
  disabled?: boolean;
  itemId: number;
  openScheduleTab: boolean;
  showIcon?: boolean;
}

interface ControlledItemLinkBaseProps {
  className?: string;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent<Element, Event>) => void;
  showIcon: boolean;
  to: string;
}
interface ControlledItemLinkProps extends ControlledItemLinkBaseProps {
  item: Item;
}

interface ControlledAssignmentLinkProps {
  assignmentName: string;
  className: string;
  disabled?: boolean;
  isDone: boolean;
  taskName: string;
  taskSchedulingType?: SchedulingType | null;
  taskStatusHexColor?: string | null;
  to: string;
}

export const ControlledAssignmentLinkRenderer = ({
  assignmentName,
  className,
  disabled = false,
  isDone,
  taskName,
  taskSchedulingType,
  taskStatusHexColor,
  to,
}: ControlledAssignmentLinkProps) => {
  return (
    <LpLink
      className={classNames(className, {
        'item-link': true,
        'item-link--disabled': disabled,
      })}
      to={to}
      disabled={disabled}
    >
      <>
        <TaskStatusIcon color={taskStatusHexColor ?? ''} schedulingType={taskSchedulingType} />
        <span className="item-link__name">{taskName}</span>
      </>
      <>
        <LpIcon className="icon lp-item-icon assignments xs" icon={circleSmallSolid} color={slate700} size={'xs'} />
        <span
          className={classNames({
            'item-link__name item-link__name--assignment': !isDone,
            'item-link__name item-link__name--done-assignment': isDone,
          })}
        >
          {assignmentName}
        </span>
      </>
    </LpLink>
  );
};

export const ControlledItemLinkRenderer = ({
  className,
  disabled = false,
  itemIcon,
  itemName,
  onClick,
  to,
}: {
  className?: string;
  disabled?: boolean;
  itemIcon?: JSX.Element;
  itemName: string | null;
  onClick?: ControlledItemLinkProps['onClick'];
  to: ControlledItemLinkBaseProps['to'];
}) => {
  return (
    <LpLink
      className={classNames(className, {
        'item-link': true,
        'item-link--disabled': disabled,
      })}
      to={to}
      onClick={onClick}
      disabled={disabled}
    >
      {itemIcon}
      <span className="item-link__name">{itemName}</span>
    </LpLink>
  );
};

const ControlledItemLink = ({ className, disabled = false, item, onClick, showIcon, to }: ControlledItemLinkProps) => {
  const itemName = getItemNameForItem(item);

  const iconSize = isAssignment(item) ? 'xs' : '1x';
  const itemIcon = showIcon ? <ItemIcon itemId={item.id} size={iconSize} /> : undefined;

  return (
    <ControlledItemLinkRenderer
      className={className}
      disabled={disabled}
      itemIcon={itemIcon}
      itemName={itemName}
      onClick={onClick}
      to={to}
    />
  );
};

const ItemLink = ({ className, disabled = false, itemId, openScheduleTab, showIcon = false }: ItemLinkProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const item = useSelector((state) => getItemForId(state, itemId));

  const tabName = openScheduleTab ? TabNames.Scheduling : TabNames.Assignments;
  const itemLinkUrl = useItemLinkUrl(itemId, tabName);

  if (!item) {
    return null;
  }
  const isItemPanelLink = item.itemType === ItemType.TASKS || item.itemType === ItemType.ASSIGNMENTS;

  const handleClick = (e: SyntheticEvent) => {
    if (isItemPanelLink) {
      e.preventDefault();
      if (item.parent) {
        const taskId = item.itemType === ItemType.ASSIGNMENTS ? item.parent.id : itemId;
        history.push(`#panelId=${taskId}&panelSection=${tabName}Tab`);
        dispatch(clearBulkData());
      }
    }
  };

  return (
    <ControlledItemLink
      className={className}
      item={item}
      onClick={handleClick}
      showIcon={showIcon}
      to={itemLinkUrl}
      disabled={disabled}
    />
  );
};

export default ItemLink;

function useItemLinkUrl(itemId: number, tabName: TabNames) {
  const item = useSelector((state) => getItemForId(state, itemId));
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const locationPathName = useLocation().pathname;

  if (!item) {
    return '';
  }

  switch (item.itemType) {
    case ItemType.WORKSPACE_ROOTS:
      return frontend.workspaceHub.url({ organizationId, workspaceId });
    case ItemType.PACKAGES:
      return frontend.singlePackage.url({ organizationId, workspaceId, packageId: itemId });
    case ItemType.PROJECTS:
    case ItemType.FOLDERS:
      return frontend.projectProject.url({ itemId, organizationId, workspaceId });
    case ItemType.TASKS:
      return `${locationPathName}#panelId=${itemId}&panelSection=${tabName}Tab`;
    case ItemType.ASSIGNMENTS:
      return `${locationPathName}#panelId=${item.parent?.id ?? 0}&panelSection=${tabName}Tab`;
    default:
      return '';
  }
}
