import { SetStateAction, Dispatch, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import FolderStatusIcon from 'containers/shared/folder_status_icon';
import ItemIcon from 'containers/shared/item_icon';
import { PackageStatus } from 'daos/enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { LpIcon } from 'features/common/lp_icon';
import { isPackage, isProject, isWorkspaceRoot, getItemForId } from 'state/entities/selectors/item';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';
import { gray500 } from 'style/variables';

import { packageIcon, errorIcon } from './item_move_helpers';

interface ItemPickerModalProps {
  atWorkspaceRoot: boolean;
  currentItemId: number;
  packageStatus: PackageStatus | null;
  parentId: number;
  updatePackageStatus: Dispatch<SetStateAction<PackageStatus | null>>;
}

const ItemMoveTableParent = ({
  atWorkspaceRoot,
  packageStatus,
  currentItemId,
  updatePackageStatus,
  parentId,
}: ItemPickerModalProps) => {
  const currentItem = useSelector((state) => getItemForId(state, currentItemId));
  const parentItem = useSelector((state) => getItemForId(state, parentId));
  const workspace = useSelector(getCurrentWorkspace);
  const parentItemType = parentItem?.itemType;

  useEffect(() => {
    if (packageStatus && parentItem && isProject(parentItem)) {
      updatePackageStatus(null);
    }
  }, [packageStatus, parentItem, parentItemType, updatePackageStatus]);

  const parentErrorIcon = useMemo(() => {
    const icon = currentItem && parentItem && errorIcon(currentItem, parentItem);
    return icon && <LpIcon size="sm" color={gray500} icon={icon} />;
  }, [currentItem, parentItem]);

  const headerIcon = useMemo(() => {
    if (packageStatus && !atWorkspaceRoot && parentItem && !isPackage(parentItem)) {
      return <LpIcon size="lg" icon={packageIcon(packageStatus)} />;
    }

    return <ItemIcon size="lg" itemId={parentItem?.id} />;
  }, [atWorkspaceRoot, parentItem, packageStatus]);

  const headerName = useMemo(() => {
    if (atWorkspaceRoot) {
      return workspace?.name;
    }

    if (packageStatus && isWorkspaceRoot(parentItem)) {
      return packageStatusDisplayNameByPackageStatus[packageStatus];
    }

    return parentItem?.name;
  }, [atWorkspaceRoot, packageStatus, parentItem, workspace?.name]);

  if (!parentItem) {
    return null;
  }

  return (
    <Button className="item-picker-modal__content-parent" basic fluid disabled>
      <span>To:</span> {headerIcon}{' '}
      <FolderStatusIcon
        folderStatus={parentItem.folderStatus}
        className="item-picker-modal__content-parent-folder-status-icon"
      />
      <span>{headerName}</span> {parentErrorIcon}
    </Button>
  );
};

export default ItemMoveTableParent;
