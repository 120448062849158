import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getGroupForId } from 'state/entities/selectors/group';
import {
  getItemWorkGroupForItemId,
  getSelfOrAncestryItemWorkGroupForItemId,
} from 'state/entities/selectors/item_work_groups';

export const TeamName = ({ itemId }: { itemId: number }) => {
  const primaryItemWorkGroup = useSelector((state) => getItemWorkGroupForItemId(state, itemId));
  const relatedItemWorkGroup = useSelector((state) => getSelfOrAncestryItemWorkGroupForItemId(state, itemId));
  const group = useSelector((state) => getGroupForId(state, relatedItemWorkGroup?.group.id ?? 0));
  const teamName = group?.name;

  if (!teamName) {
    return null;
  }

  return (
    <span
      className={classNames({
        'team-name': true,
        'team-name--inherited': primaryItemWorkGroup === undefined,
      })}
    >
      [ {teamName} ]
    </span>
  );
};
