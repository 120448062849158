import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { ItemType } from 'daos/enums';
import { ExternalIntegration } from 'daos/model_types';
import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import { useItemPanelParams } from 'features/item_panel/use_item_panel_params';
import { useLoggedWorkForItemIds } from 'hooks/use_logged_work';
import { formatAsHoursWithNoRounding } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';
import { ProductName } from 'lib/use_product_name';
import { getIntegratedJiraProjectsForItemIds } from 'state/entities/selectors/external_integration';
import { getItemForId } from 'state/entities/selectors/item';

import './confirm_delete_modal.scss';
interface ConfirmDeleteModalProps {
  confirmButtonText?: string;
  content?: string | JSX.Element;
  itemId?: number;
  deleteTargetName?: string;
  onDelete: () => void;
  onClose: () => void;
  error?: React.ReactNode | null;
  setEstimateModalAssignmentId?: Dispatch<SetStateAction<number | undefined>>;
  uniqueActionWord?: string;
  selectedIds?: ReadonlyArray<number>;
}

const LoggedWorkWarning = ({ loggedWork }: { loggedWork: number }) => {
  const loggedHours = formatAsHoursWithNoRounding(convertSecondsToHours(loggedWork));
  return (
    <span className="logged-work-warning-text"> This action will also delete {loggedHours} of time tracking data.</span>
  );
};

const ConfirmDeleteModal = ({
  onDelete,
  onClose,
  confirmButtonText = 'Delete',
  content,
  itemId,
  deleteTargetName,
  error,
  setEstimateModalAssignmentId,
  uniqueActionWord = 'DELETE',
  selectedIds,
}: ConfirmDeleteModalProps) => {
  const history = useHistory();
  const { itemPanelId } = useItemPanelParams();
  const item = useSelector((state) => (itemId ? getItemForId(state, itemId) : undefined));
  const parentItem = useSelector((state) => (item?.parent?.id ? getItemForId(state, item.parent.id) : undefined));
  const itemIds = selectedIds ?? [itemId];
  const loggedWork = useLoggedWorkForItemIds(itemIds);
  const jiraProjects = useSelector((state) => getIntegratedJiraProjectsForItemIds(state, itemIds));

  const handleItemNameClick = () => {
    if (itemPanelId === itemId) {
      onClose();
      return;
    }

    if (itemId) {
      history.push(`#panelId=${itemId}`);
    }
  };

  const getContent = () => {
    if (content) {
      return content;
    }

    if (deleteTargetName) {
      return (
        <>
          <span>You are about to {uniqueActionWord}</span> {deleteTargetName}.
          {loggedWork > 0 && <LoggedWorkWarning loggedWork={loggedWork} />}
          <JiraWarning jiraProjects={jiraProjects} selectedIds={selectedIds} />
        </>
      );
    }

    const error = (
      <>
        Something went wrong. Please contact <ProductName /> <ContactSupportLink text="support" />.
      </>
    );

    if (item) {
      switch (item.itemType) {
        case ItemType.ASSIGNMENTS: {
          const assignmentName = item.name ?? 'Assignment';

          return (
            <>
              <span>You are about to DELETE</span>{' '}
              <span className="clickable" onClick={() => setEstimateModalAssignmentId?.(item.id)}>
                {assignmentName}
              </span>{' '}
              on{' '}
              <span
                className="clickable"
                onClick={() => {
                  if (parentItem?.id) {
                    history.push(`#panelId=${parentItem.id}`);
                  }
                }}
              >
                {parentItem?.name ?? 'Task'}
              </span>
              {loggedWork > 0 && <LoggedWorkWarning loggedWork={loggedWork} />}
            </>
          );
        }

        case ItemType.TASKS:
        case ItemType.FOLDERS:
        case ItemType.PACKAGES:
        case ItemType.PROJECTS: {
          const itemName = item.name ?? 'Item';

          return (
            <>
              <span>You are about to DELETE</span>{' '}
              <span className="clickable" onClick={handleItemNameClick}>
                {itemName}.
              </span>
              {loggedWork > 0 && <LoggedWorkWarning loggedWork={loggedWork} />}
              <JiraWarning jiraProjects={jiraProjects} selectedIds={selectedIds} />
            </>
          );
        }

        default:
          return error;
      }
    } else {
      return error;
    }
  };

  return (
    <BaseConfirmModal
      confirmButtonText={confirmButtonText}
      content={getContent()}
      error={error}
      onClose={onClose}
      onConfirm={onDelete}
    />
  );
};

const JiraWarning = ({
  jiraProjects,
  selectedIds,
}: {
  jiraProjects: Array<ExternalIntegration>;
  selectedIds: ReadonlyArray<number> | undefined;
}) => {
  const article = selectedIds ? 'a' : 'this';
  return jiraProjects.length > 0 ? (
    <p className="jira-project-warning-text">
      Deleting {article} Jira integrated project will permanently break its connection with Jira.
    </p>
  ) : null;
};

export default ConfirmDeleteModal;
