import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ItemType, PackageStatus } from 'daos/enums';
import useWidgetParams from 'features/dashboards/hooks/use_widget_params';
import { frontend } from 'lib/urls';
import { getItemForId } from 'state/entities/selectors/item';
import { getLibraryResourcesForDashboardId } from 'state/entities/selectors/library_resources';
import { getIsGuestCurrentWorkspaceUser } from 'state/entities/selectors/user';

export const useGoBackToDashboardUrl = () => {
  const { organizationId, workspaceId } = useParams<{
    organizationId: string;
    workspaceId: string;
  }>();
  const { itemId, dashboardId } = useWidgetParams();
  const item = useSelector((state) => (itemId ? getItemForId(state, Number(itemId)) : undefined));

  const libraryResourceDashboard = useSelector((state) =>
    dashboardId ? getLibraryResourcesForDashboardId(state, Number(dashboardId)) : undefined,
  );

  const packageStatus = libraryResourceDashboard?.packageStatus;

  const isGuestWsUser = useSelector(getIsGuestCurrentWorkspaceUser);

  if (isGuestWsUser) {
    return dashboardId
      ? frontend.dashboardGuest.url({ organizationId, workspaceId, dashboardId })
      : frontend.dashboardPassports.url({});
  }

  if (item && dashboardId) {
    switch (item.itemType) {
      case ItemType.PACKAGES:
        return frontend.packageLibraryDashboard.url({ organizationId, workspaceId, packageId: item.id, dashboardId });
      case ItemType.PROJECTS:
        return frontend.projectLibraryDashboard.url({ organizationId, workspaceId, itemId: item.id, dashboardId });
      case ItemType.WORKSPACE_ROOTS:
        {
          if (packageStatus === PackageStatus.SCHEDULED) {
            return frontend.scheduledCollectionDashboard.url({ organizationId, workspaceId, dashboardId });
          }
          if (packageStatus === PackageStatus.BACKLOG) {
            return frontend.pendingCollectionDashboard.url({ organizationId, workspaceId, dashboardId });
          }
          if (packageStatus === PackageStatus.ARCHIVED) {
            return frontend.archiveCollectionDashboard.url({ organizationId, workspaceId, dashboardId });
          }
        }
        return frontend.workspaceDashboard.url({ organizationId, workspaceId, dashboardId });
    }
  }
  return '';
};

export const useControlledGoBackToDashboardUrl = ({
  organizationId,
  workspaceId,
  itemId,
  dashboardId,
}: {
  organizationId: string;
  workspaceId: string;
  itemId: string;
  dashboardId: string;
}) => {
  const item = useSelector((state) => (itemId ? getItemForId(state, Number(itemId)) : undefined));

  if (item) {
    switch (item.itemType) {
      case ItemType.PACKAGES:
        return frontend.packageLibraryDashboard.url({ organizationId, workspaceId, packageId: item.id, dashboardId });
      case ItemType.PROJECTS:
        return frontend.projectLibraryDashboard.url({ organizationId, workspaceId, itemId: item.id, dashboardId });
      case ItemType.WORKSPACE_ROOTS:
        return dashboardId ? frontend.workspaceDashboard.url({ organizationId, workspaceId, dashboardId }) : '';
    }
  }
  return '';
};
