import { useEffect } from 'react';
import { SortColumn } from 'react-data-grid';

import { UserType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import {
  SortableDataGridContextProvider,
  useItemDataGridSortOrder,
  usePeopleDataGridSortOrder,
} from 'features/common/data_grid/context';
import { BaseSortableGrid } from 'features/common/data_grid/grids/lp_sorable_data_grid';
import { useSafeItemGridCellClick } from 'features/common/data_grid/hooks/use_item_grid_cell_click';
import {
  useItemGridSelectColumnProps,
  usePeopleGridSelectColumnProps,
} from 'features/common/data_grid/safe_widgets/helpers';
import { EntityColumn, EntityRow, SafeGridModalState } from 'features/common/data_grid/types';
import { getItemTypeForListWidgetType } from 'features/dashboards/widget_click_through/grids/helpers';
import { ItemListWidgetContext } from 'features/dashboards_v2/widget/widgets/list/context';
import {
  getDateRangeDisplay,
  useWidgetDateRange,
} from 'features/dashboards_v2/widget/widgets/list/use_widget_date_range';
import { GridSummaryRow, ItemRow, PeopleRow } from 'features/dashboards_v2/widget/widgets/reports/types';
import { useHasTeam25TeamBasedPlanning } from 'hooks/use_has_feature';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';

import {
  getSafeWidgetGridItemRows,
  getSafeWidgetGridPeopleRows,
  getSafeWidgetItemGridColumns,
  getSafeWidgetPeopleGridColumns,
} from './column_rows';

interface SafeWidgetGridProps {
  fetchWidgetRowData: (sortORder: string) => void;
  isFetchComplete: boolean;
  onSelectedRowsChange?: (selectedIds: Set<string | number>) => void;
  selectedRows?: Set<number>;
  widget: WidgetWithData;
}

interface SafeItemWidgetGridProps extends SafeWidgetGridProps {
  configColumns: ReadonlyArray<WidgetConfigColumn>;
  rowData: ReadonlyArray<ItemRow>;
  summaryRow: ReadonlyArray<GridSummaryRow>;
  modalState?: SafeGridModalState;
}

interface SafePeopleWidgetGridProps extends SafeWidgetGridProps {
  configColumns: ReadonlyArray<WidgetConfigColumn>;
  rowData: ReadonlyArray<PeopleRow>;
  selectedUserType?: UserType;
  summaryRow: ReadonlyArray<GridSummaryRow>;
}

const SafeWidgetItemGrid = ({
  configColumns,
  fetchWidgetRowData,
  isFetchComplete,
  rowData,
  widget,
  summaryRow,
  modalState,
  ...lpDataGridSelectRowProps
}: SafeItemWidgetGridProps) => {
  const columnKeys = configColumns.map((col) => (col.customFieldId ? String(col.customFieldId) : col.column));
  const sortOrder = useItemDataGridSortOrder(columnKeys);

  const { formatLocalDate } = useLocalizedFormats();
  const selectColumnProps = useItemGridSelectColumnProps({ rowData });

  useEffect(() => {
    fetchWidgetRowData(sortOrder);
  }, [fetchWidgetRowData, sortOrder]);

  const dateRange = useWidgetDateRange(rowData);
  const dateRangeDisplay = dateRange ? getDateRangeDisplay(dateRange, formatLocalDate) : undefined;
  const columns = getSafeWidgetItemGridColumns(widget, dateRangeDisplay, configColumns, selectColumnProps);
  const rows = getSafeWidgetGridItemRows(rowData);

  const itemType = getItemTypeForListWidgetType(widget.config.listType);
  const { onItemGridCellClick } = useSafeItemGridCellClick(
    itemType,
    Number(widget.organizationId),
    Number(widget.workspaceId),
    modalState,
  );

  const handleRowCellClick = (
    row: EntityRow<ItemRow, GridSummaryRow>,
    column: EntityColumn<ItemRow, GridSummaryRow>,
  ) => {
    onItemGridCellClick({ row, column });
  };

  return (
    <ItemListWidgetContext.Provider value={{ dateRange }}>
      <BaseSortableGrid
        {...lpDataGridSelectRowProps}
        columns={columns}
        summaryRow={summaryRow}
        rows={rows}
        isFetchComplete={isFetchComplete}
        onRowClick={handleRowCellClick}
      />
    </ItemListWidgetContext.Provider>
  );
};

const SafeWidgetPeopleGrid = ({
  configColumns,
  fetchWidgetRowData,
  isFetchComplete,
  rowData,
  selectedUserType,
  summaryRow,
  widget,
  ...lpDataGridSelectRowProps
}: SafePeopleWidgetGridProps) => {
  const columnKeys = configColumns.map((col) => (col.customFieldId ? String(col.customFieldId) : col.column));
  const sortOrder = usePeopleDataGridSortOrder(columnKeys);
  const hasTeam25TeamBasedPlanning = useHasTeam25TeamBasedPlanning();

  useEffect(() => {
    fetchWidgetRowData(sortOrder);
  }, [fetchWidgetRowData, sortOrder]);

  const selectColumnProps = usePeopleGridSelectColumnProps({
    onSelectedRowsChange: lpDataGridSelectRowProps.onSelectedRowsChange,
    rowUserData: rowData.map((row) => row.user),
    selectedUserType,
  });

  const columns = getSafeWidgetPeopleGridColumns(widget, configColumns, selectColumnProps, hasTeam25TeamBasedPlanning);
  const rows = getSafeWidgetGridPeopleRows(rowData);

  return (
    <BaseSortableGrid
      {...lpDataGridSelectRowProps}
      columns={columns}
      summaryRow={summaryRow}
      rows={rows}
      isFetchComplete={isFetchComplete}
    />
  );
};

export const SortableSafeWidgetItemGrid = ({
  initialSortColumn,
  ...gridProps
}: SafeItemWidgetGridProps & { initialSortColumn?: SortColumn }) => {
  return (
    <SortableDataGridContextProvider initialSortColumn={initialSortColumn}>
      <SafeWidgetItemGrid {...gridProps} />
    </SortableDataGridContextProvider>
  );
};

export const SortableSafeWidgetPeopleGrid = ({
  initialSortColumn,
  ...gridProps
}: SafePeopleWidgetGridProps & { initialSortColumn?: SortColumn }) => {
  return (
    <SortableDataGridContextProvider initialSortColumn={initialSortColumn}>
      <SafeWidgetPeopleGrid {...gridProps} />
    </SortableDataGridContextProvider>
  );
};
