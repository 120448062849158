export const NovaCalendarSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
      <mask
        id="mask0_3467_50318"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="15"
        height="16"
      >
        <rect y="0.5" width="15" height="15" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3467_50318)">
        <path
          d="M3.31734 13.9373C3.00161 13.9373 2.73438 13.8279 2.51563 13.6091C2.29688 13.3904 2.1875 13.1232 2.1875 12.8074V4.44211C2.1875 4.12638 2.29688 3.85914 2.51563 3.64039C2.73438 3.42164 3.00161 3.31227 3.31734 3.31227H4.18266V1.99023H5.14422V3.31227H9.87984V1.99023H10.8173V3.31227H11.6827C11.9984 3.31227 12.2656 3.42164 12.4844 3.64039C12.7031 3.85914 12.8125 4.12638 12.8125 4.44211V12.8074C12.8125 13.1232 12.7031 13.3904 12.4844 13.6091C12.2656 13.8279 11.9984 13.9373 11.6827 13.9373H3.31734ZM3.31734 12.9998H11.6827C11.7308 12.9998 11.7748 12.9797 11.8148 12.9396C11.8549 12.8996 11.875 12.8555 11.875 12.8074V6.94211H3.125V12.8074C3.125 12.8555 3.14505 12.8996 3.18516 12.9396C3.22516 12.9797 3.26922 12.9998 3.31734 12.9998ZM3.125 6.00461H11.875V4.44211C11.875 4.39398 11.8549 4.34992 11.8148 4.30992C11.7748 4.26982 11.7308 4.24977 11.6827 4.24977H3.31734C3.26922 4.24977 3.22516 4.26982 3.18516 4.30992C3.14505 4.34992 3.125 4.39398 3.125 4.44211V6.00461Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};
