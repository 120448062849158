import { noop } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button, Grid, Message } from 'semantic-ui-react';

import './index.scss';
import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import { LpIcon, exclamationTriangleSolid } from 'features/common/lp_icon';

interface ErrorPageProps {
  reset: () => void;
}

export const ErrorPage = ({ reset }: ErrorPageProps) => {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener(
      'popstate',
      () => {
        reset();
      },
      { once: true },
    );

    return () => window.removeEventListener('popstate', noop);
  });

  const goBack = () => {
    history.goBack();
  };

  const refresh = () => {
    window.location.reload();
  };

  return (
    <Grid centered={true} columns={2} className="error-page">
      <Grid.Column className="error-page__column">
        <Button.Group>
          <Button onClick={goBack}>Back</Button>
          <Button.Or />
          <Button onClick={refresh}>Refresh</Button>
        </Button.Group>
        <Message error={true} className="error-page__unknown-error">
          <LpIcon className="error-page__unknown-error-icon" icon={exclamationTriangleSolid} size="10x" />
          <Message.Content className="error-page__unknown-error-content">
            <Message.Header content="We've hit an unexpected error." />
            <p className="error-page__unknown-error-content-text">
              Select from the options above and
              <ContactSupportLink text="contact support" /> if you have any trouble.
            </p>
          </Message.Content>
        </Message>
      </Grid.Column>
    </Grid>
  );
};
