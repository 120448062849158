import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { FillEvent } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { ItemDao } from 'daos/item';
import { Item } from 'daos/model_types';
import { clearBulkData } from 'features/common/bulk_selection/slice';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  getDeletedFieldValueIds,
  updateRowCustomFieldValuesOnSave,
} from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import { LpAddEditGrid } from 'features/common/data_grid/add_edit_grid/lp_add_edit_data_grid';
import { AddEditGridColumns, AddEditGridRow } from 'features/common/data_grid/types';
import LpLoaderAnimatedGrid from 'features/common/loaders/lp_item_panel_loader';
import LpModal from 'features/common/modals/lp_modal';
import { setApiError } from 'features/errors/slice';
import { awaitRequestFinish } from 'lib/api';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { getEditGridCustomFieldValuesByItemId, getFieldsById } from 'state/entities/selectors/custom_field';
import { removeEntities } from 'state/entities/slice';

import './lp_edit_grid_modal.scss';

interface EditGridModalProps {
  columns: AddEditGridColumns;
  createPayload: (rows: Array<AddEditGridRow>) => Array<Partial<Item> & { id: number }>;
  fetchItems: () => void;
  helpText: JSX.Element;
  itemTypeDisplay: string;
  initialRows: Array<AddEditGridRow>;
  onFill?: (props: FillEvent<AddEditGridRow>) => AddEditGridRow;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  validateRows: (rows: Array<AddEditGridRow>) => boolean;
  onSaveAndClose?: () => void;
  shouldClearBulkDataOnClose?: boolean;
  restrictSizeToContent?: boolean;
}

const EditGridModal = ({
  columns,
  createPayload,
  fetchItems,
  helpText,
  itemTypeDisplay,
  initialRows,
  onFill,
  setOpen,
  validateRows,
  onSaveAndClose,
  shouldClearBulkDataOnClose = true,
  restrictSizeToContent = false,
}: EditGridModalProps) => {
  const dispatch = useDispatch();

  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const customFieldsById = useSelector(getFieldsById);
  const fieldValuesByItemId = useSelector(getEditGridCustomFieldValuesByItemId);

  const [rows, setRows] = useState(initialRows);
  const [hasAllValidRows, setHasAllValidRows] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const handleModalClose = useCallback(() => setOpen(false), [setOpen]);
  const handleSaveAction = () => handleSave(false);
  const handleSaveAndCloseAction = () => handleSave(true, onSaveAndClose);

  const handleRowsChange = useCallback(
    (rows: Array<AddEditGridRow>) => {
      setRows(rows);
      setHasAllValidRows(validateRows(rows));
    },
    [validateRows],
  );

  const removeDeletedCustomFieldValues = useCallback(() => {
    const deletedFieldValueIds = getDeletedFieldValueIds(rows, customFieldsById, fieldValuesByItemId);
    dispatch(removeEntities({ ids: deletedFieldValueIds, entityType: 'fieldValues' }));
  }, [customFieldsById, dispatch, fieldValuesByItemId, rows]);

  const handleSave = useCallback(
    (closeModal: boolean, onSaveAndClose?: () => void) => {
      if (hasAllValidRows) {
        setIsSaving(true);
        const payload = createPayload(rows);

        const { uuid } = dispatch(ItemDao.updateBulk({ organizationId, workspaceId }, payload));
        dispatch(
          awaitRequestFinish<ReadonlyArray<Item>>(uuid, {
            onError: ({ errors }) => {
              setIsSaving(false);

              const error = errors[0];
              if (error) {
                dispatch(setApiError(error));
              }
            },
            onSuccess: ({ entities }) => {
              if (!closeModal) {
                const updatedRows = updateRowCustomFieldValuesOnSave({ rows, fieldValuesById: entities.fieldValues });
                setRows(updatedRows);
              }

              setIsSaving(false);
              removeDeletedCustomFieldValues();

              if (closeModal) {
                fetchItems();
                handleModalClose();
                onSaveAndClose?.();
                shouldClearBulkDataOnClose && dispatch(clearBulkData());
              }
            },
          }),
        );
      }
    },
    [
      createPayload,
      dispatch,
      fetchItems,
      handleModalClose,
      hasAllValidRows,
      organizationId,
      removeDeletedCustomFieldValues,
      rows,
      workspaceId,
      shouldClearBulkDataOnClose,
    ],
  );

  return (
    <LpModal
      closeOnEscape={false}
      className={classNames('lp-edit-grid-modal', restrictSizeToContent && 'lp-edit-grid-modal--restrict_size')}
      onClose={handleModalClose}
      size="large"
      header={
        <div className="lp-edit-grid-modal__header">
          {`Edit ${itemTypeDisplay}`}
          <div className="bulk-help-text">{helpText}</div>
        </div>
      }
      content={
        isSaving ? (
          <LpLoaderAnimatedGrid label={`Saving${HORIZONTAL_ELLIPSIS}`} />
        ) : (
          <LpAddEditGrid
            restrictSizeToContent={restrictSizeToContent}
            columns={columns}
            onFill={onFill}
            onRowsChange={handleRowsChange}
            rows={rows}
          />
        )
      }
      actions={
        <>
          <Button size="small" onClick={handleModalClose} type="button" content="Cancel" />
          <Button
            content="Save"
            disabled={!hasAllValidRows || isSaving}
            onClick={handleSaveAction}
            primary
            type="submit"
          />
          <Button
            className="lp-edit-grid-modal__actions-save-and-close-button"
            content="Save & Close"
            disabled={!hasAllValidRows || isSaving}
            primary
            type="button"
            onClick={handleSaveAndCloseAction}
          />
        </>
      }
    />
  );
};

export default EditGridModal;
