import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Segment } from 'semantic-ui-react';

import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { WorkspaceInformationForInvitationUser } from 'features/organization_directory/users/invite/selectors';
import { frontend } from 'lib/urls';
import { getWorkspacesForOrgId } from 'state/entities/selectors/workspace';

import { UserForm } from './user_form';
import { WorkspacesForm } from './workspaces_form';

export const InviteUserForm = ({
  existingUserEmail,
  isAccountConfirmed,
  newUserEmail,
  setInvitationSent,
  setNewUserEmail,
  workspaceInformation,
  selectedWorkspaces,
}: {
  existingUserEmail: string;
  isAccountConfirmed: boolean;
  newUserEmail: string;
  setInvitationSent: Dispatch<SetStateAction<boolean>>;
  setNewUserEmail: Dispatch<SetStateAction<string>>;
  workspaceInformation: WorkspaceInformationForInvitationUser | undefined;
  selectedWorkspaces: ReadonlyArray<string>;
}) => {
  const history = useHistory();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const workspaces = useSelector((state) => getWorkspacesForOrgId(state, organizationId));

  const onConfirmInviteCancel = () => {
    setNewUserEmail('');
    setInvitationSent(false);
  };

  const onConfirmInviteSuccess = () => {
    setNewUserEmail('');
    setInvitationSent(true);
    history.replace(frontend.organizationHubUsersInvite.url({ organizationId }));
  };

  return newUserEmail === '' ? (
    <UserForm
      isAccountConfirmed={isAccountConfirmed}
      setInvitationSent={setInvitationSent}
      setNewUserEmail={setNewUserEmail}
    />
  ) : (
    <>
      Workspaces
      <Segment className="users-invite__invitation-form-content">
        <WorkspacesForm
          currentWorkspaceId={workspaceId}
          existingUserEmail={existingUserEmail}
          newUserEmail={newUserEmail}
          onCancel={onConfirmInviteCancel}
          onInvitationSuccess={onConfirmInviteSuccess}
          organizationId={organizationId}
          workspaceInformation={workspaceInformation}
          workspaces={workspaces ?? []}
          selectedWorkspaces={selectedWorkspaces}
        />
      </Segment>
    </>
  );
};
