import { useSelector } from 'react-redux';

import { ScheduleDirective, ItemType, SchedulingType } from 'daos/enums';
import { FolderStatus } from 'daos/item_enums';
import { useItemDataForStatus } from 'hooks/use_item_data_for_status';
import { getAssignmentsForItemId } from 'state/entities/selectors/assignment';
import { getItemForId } from 'state/entities/selectors/item';
import { getTaskStatusForId } from 'state/entities/selectors/task_status';

export enum ScheduleHoverStatus {
  Clipped = 'clipped',
  Normal = 'normal',
  NotComplete = 'not-complete',
  TrackingOnly = 'tracking-only',
}

export const calcItemDataForScheduleTooltip = ({
  doneDate,
  expectedFinish,
  expectedStart,
  folderStatus,
  hasAssignedOrgUser,
  highEffort,
  isLate,
  isLateRisk,
  itemType,
  latestFinish,
  needsAssignment,
  needsEstimate,
  scheduleDirective,
  taskStatusSchedulingType,
}: {
  doneDate: string | null;
  expectedFinish: string | null;
  expectedStart: string | null;
  folderStatus: FolderStatus | null;
  hasAssignedOrgUser: boolean;
  highEffort: number | null;
  isLate: boolean;
  isLateRisk: boolean;
  itemType: ItemType;
  latestFinish: string | null;
  needsAssignment: boolean;
  needsEstimate: boolean;
  scheduleDirective: ScheduleDirective | null;
  taskStatusSchedulingType: SchedulingType | undefined;
}) => {
  if (isLate || isLateRisk) {
    return ScheduleHoverStatus.Clipped;
  }

  const scheduleDataForTaskAndContainers = () => {
    const isAssignmentNeeded = needsAssignment && !needsEstimate;
    const isEstimateNeeded = !needsAssignment && needsEstimate;
    const isAssignmentAndEst = needsAssignment && needsEstimate;
    const isContainer =
      itemType === ItemType.FOLDERS || itemType === ItemType.PROJECTS || itemType === ItemType.PACKAGES;
    const isContainerScheduled =
      expectedStart !== expectedFinish || expectedFinish !== latestFinish || expectedStart !== latestFinish;
    const isContainerDoneOrOnHold = folderStatus === FolderStatus.DONE || folderStatus === FolderStatus.ON_HOLD;

    if (isContainer) {
      if (isContainerScheduled || isContainerDoneOrOnHold) {
        return ScheduleHoverStatus.Normal;
      }
      return ScheduleHoverStatus.NotComplete;
    }

    if (taskStatusSchedulingType) {
      if (taskStatusSchedulingType === SchedulingType.Unscheduled || taskStatusSchedulingType === SchedulingType.Done) {
        return ScheduleHoverStatus.Normal;
      }

      if (isAssignmentNeeded || isEstimateNeeded || isAssignmentAndEst) {
        return ScheduleHoverStatus.NotComplete;
      }
    }

    return ScheduleHoverStatus.Normal;
  };

  const scheduleDataForAssignment = () => {
    const isAssignmentTrackingOnly = scheduleDirective === ScheduleDirective.TRACKING_ONLY;
    const needsAssignee = !doneDate && !hasAssignedOrgUser && !!highEffort;
    const assignmentNeedsEstimate = !doneDate && hasAssignedOrgUser && !highEffort;
    const needsAssigneeAndEstimate = !doneDate && !hasAssignedOrgUser && !highEffort;

    if (isAssignmentTrackingOnly) {
      return ScheduleHoverStatus.TrackingOnly;
    }

    if (needsAssignee || assignmentNeedsEstimate || needsAssigneeAndEstimate) {
      return ScheduleHoverStatus.NotComplete;
    }

    return ScheduleHoverStatus.Normal;
  };

  return itemType === ItemType.ASSIGNMENTS ? scheduleDataForAssignment() : scheduleDataForTaskAndContainers();
};

export const useItemDataForScheduleTooltip = (itemId?: number) => {
  const item = useSelector((state) => (itemId ? getItemForId(state, itemId) : undefined));
  const taskStatus = useSelector((state) =>
    item?.taskStatus ? getTaskStatusForId(state, item.taskStatus.id) : undefined,
  );

  const needsAssignment = useSelector((state) => {
    const itemAssignments = (itemId && getAssignmentsForItemId(state, itemId)) || [];
    return !itemAssignments.length || itemAssignments.every((assignment) => !assignment.organizationUser);
  });

  const needsEstimate = useSelector((state) => {
    const itemAssignments = (itemId && getAssignmentsForItemId(state, itemId)) || [];
    return itemAssignments.every((assignment) => !assignment.highEffort);
  });

  const { isLate, isLateRisk } = useItemDataForStatus(itemId);

  if (!item) {
    return;
  }

  return calcItemDataForScheduleTooltip({
    doneDate: item.doneDate,
    highEffort: item.highEffort,
    hasAssignedOrgUser: !!item.organizationUser,
    scheduleDirective: item.scheduleDirective,
    expectedStart: item.expectedStart,
    expectedFinish: item.expectedFinish,
    latestFinish: item.latestFinish,
    itemType: item.itemType,
    folderStatus: item.folderStatus,
    taskStatusSchedulingType: taskStatus?.schedulingType,
    isLate,
    isLateRisk,
    needsAssignment,
    needsEstimate,
  });
};
