import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { UserAuthSettings } from 'daos/model_types';
import { UserDao } from 'daos/user';
import { AuthType } from 'features/authentication/enums';
import { awaitRequestFinish } from 'lib/api';

const useCurrentUserAuthType = function () {
  const dispatch = useDispatch();
  const [authType, setAuthType] = useState<AuthType | undefined>();

  useEffect(() => {
    const { uuid } = dispatch(UserDao.fetchCurrentUserAuthSettings());

    dispatch(
      awaitRequestFinish<UserAuthSettings>(uuid, {
        onSuccess: ({ data }) => {
          setAuthType(data.hasSAML ? AuthType.SAML : AuthType.LP_AUTH);
        },
      }),
    );
  }, [dispatch]);

  return authType;
};

export default useCurrentUserAuthType;
