import { Table } from 'semantic-ui-react';

import { widgetTypeDisplay, WidgetType } from 'daos/enums';
import { WidgetWithData, WorkloadGroup } from 'daos/model_types';
import WorkloadDateRangeNubs from 'features/common/workload/workload_table/workload_date_range_nubs';
import { IconHeader } from 'features/dashboards_v2/widget/common/headers';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { useDefaultWidgetTitle } from 'features/dashboards_v2/widget/use_default_widget_title';
import { useGetItemScopeForWidget } from 'features/dashboards_v2/widget/use_get_item_for_scope';
import { WorkloadWidgetData } from 'features/dashboards_v2/widget/widgets/workload/types';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { daysUntilStartDisplayText } from 'lib/display_helpers/days_until_start_display_text';

import './index.scss';

const DEFAULT_ROW_VIEW_LIMIT = 5;

export const WorkloadWidget = ({ widget }: { widget: WidgetWithData }) => {
  const item = useGetItemScopeForWidget(widget);

  const defaultWidgetTitle = useDefaultWidgetTitle(widget);

  const widgetData = widget.data as WorkloadWidgetData;
  const rowViewLimit = widget.config.listPreviewRows ?? DEFAULT_ROW_VIEW_LIMIT;

  const { dates, daysUntilStart, endDate, startDate, groups } = widgetData.workload;
  const workloadAvailable = !!groups.length;

  if (!item) {
    return <RenderedWidget noContent />;
  }

  const widgetTitle = widget.config.title ?? (
    <IconHeader itemId={item.id} title={defaultWidgetTitle ?? widgetTypeDisplay[WidgetType.ScheduleSummary]} />
  );
  return (
    <RenderedWidget
      header={
        <>
          <div>
            {widgetTitle}
            {` ( Top ${rowViewLimit} )`}
          </div>
          <div className="v2-widget-workload__sub-header">
            {workloadAvailable ? (
              <WorkloadStartTimeline
                daysUntilStart={daysUntilStart}
                startDate={startDate}
                endDate={endDate}
                numDays={dates.length ?? 0}
              />
            ) : (
              'No work scheduled'
            )}
          </div>
        </>
      }
      body={
        workloadAvailable ? (
          <WorkloadTable
            startDate={startDate}
            endDate={endDate}
            dates={dates}
            groups={groups}
            rowViewLimit={rowViewLimit}
          />
        ) : null
      }
    />
  );
};

function WorkloadStartTimeline({
  daysUntilStart,
  startDate,
  endDate,
  numDays,
}: {
  daysUntilStart: number;
  startDate: string;
  endDate: string;
  numDays: number;
}) {
  const { formatLocalDate } = useLocalizedFormats();

  return (
    <span>
      {`${daysUntilStartDisplayText(daysUntilStart)} ${formatLocalDate(startDate)} - ${formatLocalDate(
        endDate,
      )} (${numDays}d)`}
    </span>
  );
}

function WorkloadTable({
  startDate,
  endDate,
  dates,
  groups,
  rowViewLimit,
}: {
  startDate: string;
  endDate: string;
  dates: ReadonlyArray<string>;
  groups: ReadonlyArray<WorkloadGroup>;
  rowViewLimit: number;
}) {
  return (
    <Table unstackable definition compact basic>
      <Table.Body>
        {groups.map((workloadGroup, idx) => {
          return idx < rowViewLimit ? (
            <Table.Row className="v2-widget-workload__row" key={workloadGroup.id}>
              <Table.Cell collapsing textAlign="right" className="v2-widget-workload__row-name">
                {workloadGroup.username}
              </Table.Cell>
              <Table.Cell className="v2-widget-workload__row-nubs">
                <WorkloadDateRangeNubs
                  dailyAvailabilitySeconds={workloadGroup?.dailyAvailabilitySeconds ?? {}}
                  dailyWorkSecondsByDate={workloadGroup.dailyWorkSeconds}
                  isRollup={true}
                  nubColor={undefined}
                  lateByDate={workloadGroup.lateByDate}
                  timeOffByDate={workloadGroup?.timeOffByDate ?? {}}
                  workloadDates={dates}
                  workISOFinishDate={endDate}
                  workISOStartDate={startDate}
                  username={workloadGroup.username}
                  scheduleTypeByDate={workloadGroup.scheduleTypeByDate}
                />
              </Table.Cell>
            </Table.Row>
          ) : null;
        })}
      </Table.Body>
    </Table>
  );
}
