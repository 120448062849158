import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { ColumnIndex, FrontendColumns, StandardColumns } from 'containers/shared/custom_column/enum';
import { getUserColumns } from 'lib/helpers/user_custom_columns';
import { readonlyArray } from 'lib/readonly_record';
import { createCacheByIdsConfig } from 'state/entities/selectors/shared';
import { getCurrentWorkspaceUserSettings } from 'state/entities/selectors/user_workspace_settings';
import { RootState } from 'state/root_reducer';

export const getPortfolioGridItemIdsByPackageId = ({ portfolioGrid }: RootState) => portfolioGrid.itemsByPackageId;
export const getPortfolioGridItemIdsForPackageId = (state: RootState, id: number) =>
  getPortfolioGridItemIdsByPackageId(state)[id];

export const getProjectIdsForExpandedPackages = ({ portfolioGrid }: RootState) =>
  Object.values(portfolioGrid.itemsByPackageId).reduce((acc, projectRow) => acc.concat(projectRow), []);

export const getPortfolioGridPackagesCollapsed = ({ portfolioGrid }: RootState) => portfolioGrid.collapsePackages;

export const getPortfolioGridPackageCollapsed = ({ portfolioGrid }: RootState, id: number) =>
  portfolioGrid.collapsePackages[id] ?? true;

export const getPortfolioGridExpandedIdsForPackageIds = createCachedSelector(
  (state: RootState) => state.portfolioGrid.collapsePackages,
  (_: RootState, pkgIds: Array<number>) => pkgIds,
  (collapsedLookup, pkgIds) =>
    readonlyArray(
      pkgIds.filter((id) => Object.prototype.hasOwnProperty.call(collapsedLookup, id) && !collapsedLookup[id]),
    ),
)(createCacheByIdsConfig());

export const getPortfolioGridPackageStatus = ({ portfolioGrid }: RootState) => portfolioGrid.packageStatus;

export const getPortfolioGridUserSelectableColumns = createSelector(
  ({ portfolioGrid }: RootState) => portfolioGrid.userSelectableColumns,
  getPortfolioGridPackageStatus,
  getCurrentWorkspaceUserSettings,
  (userSelectableColumns, packageStatus, currentWorkspaceUserSettings) =>
    getUserColumns('Portfolio', userSelectableColumns, packageStatus, currentWorkspaceUserSettings),
);

export const getPortfolioGridUserSelectedColumnOptionForIndex = createSelector(
  getPortfolioGridUserSelectableColumns,
  (_: RootState, columnIndex: ColumnIndex) => columnIndex,
  (userSelectableColumns, columnIndex) =>
    userSelectableColumns[columnIndex]?.columnOption ?? StandardColumns.TotalWorkRange,
);

export const getPortfolioGridUserSelectableColumnFieldIdForIndex = createSelector(
  getPortfolioGridUserSelectableColumns,
  (_: RootState, columnIndex: ColumnIndex) => columnIndex,
  (userSelectableColumns, columnIndex) => {
    const column = userSelectableColumns[columnIndex];

    if (column?.columnOption === StandardColumns.CustomField) {
      return column.fieldId;
    }
  },
);

export const getPortfolioGridSelectedColumnNameForIndex = createSelector(
  (state: RootState) => state.entities.fields,
  (_: RootState, columnIndex: ColumnIndex) => columnIndex,
  getPortfolioGridUserSelectableColumns,
  (fieldsById, columnIndex, userSelectedColumns) => {
    const column = userSelectedColumns[columnIndex];

    if (!column) {
      return FrontendColumns.Empty;
    }

    if (column.columnOption !== StandardColumns.CustomField) {
      return column.columnOption;
    }

    const field = fieldsById[column.fieldId];
    return field?.fieldType ?? FrontendColumns.Empty;
  },
);

export const getPortfolioGridPackageIdsOrderedByPriority = (state: RootState) => [...state.portfolioGrid.packageIds];
export const getPortfolioPackageIdSet = createSelector(
  getPortfolioGridPackageIdsOrderedByPriority,
  (packageIds) => new Set(packageIds),
);

export const getPortfolioGridProjectsIds = createSelector(getPortfolioGridItemIdsByPackageId, (itemsByPackageId) =>
  readonlyArray([
    ...Object.values(itemsByPackageId).reduce((acc: Array<number>, projectIdsForPackage: ReadonlyArray<number>) => {
      acc.push(...projectIdsForPackage);
      return acc;
    }, []),
  ]),
);

export const getPortfolioGridItemIds = createSelector(
  getPortfolioGridPackageIdsOrderedByPriority,
  getPortfolioGridProjectsIds,
  (packageIds, projectIds) => [...packageIds, ...projectIds],
);
