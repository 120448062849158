import { DatePicker, DatePickerDate, DatePickerI18n } from '@vaadin/date-picker/vaadin-date-picker';
import { noop } from 'lodash';
import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';

import { LpDatePickerProps } from 'features/common/inputs/lp_date_picker';
import { getDatePickerDefaultPlaceholder } from 'features/common/inputs/lp_date_picker/helpers';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { DEFAULT_FIRST_DAY_OF_WEEK, firstDayOfWeekToJsDayNumber } from 'lib/localization';
import { WorkdayTimeType } from 'lib/workday_time_type';
import { getCurrentOrganizationUser } from 'state/entities/selectors/user';

import './index.scss';

interface LpDatePickerWithWorkdayTimeTypeProps extends LpDatePickerProps {
  workdayTimeType: WorkdayTimeType;
}

export const LpDatePickerWithWorkdayTimeType = memo(
  ({
    onClose,
    onChange,
    onBlur,
    onValidityChanged,
    onInput,
    placeholder,
    value,
    error,
    open,
    workdayTimeType,
    allowEmpty,
    disabled,
    min,
    inline,
    rounded,
    small,
    className,
    showWeekNumbers,
  }: LpDatePickerWithWorkdayTimeTypeProps) => {
    const {
      parseLocalDateWithWorkspaceDateFormatAsMoment,
      parseLocalDateWithWorkspaceTimeAsIsoDateTime,
      formatLocalDate,
      convertDatePartsToMomentTZ,
      convertLocalDateToSpecifiedTimezoneAndFormat,
    } = useLocalizedFormats();
    const organizationUser = useSelector(getCurrentOrganizationUser);
    const firstDayOfWeek = organizationUser?.firstDayOfWeek ?? DEFAULT_FIRST_DAY_OF_WEEK;
    const ref = useRef<HTMLInputElement & DatePicker>(null);

    useEffect(() => {
      const el = ref.current;
      if (!el) {
        return;
      }

      const overlay = document.querySelector('vaadin-date-picker-overlay');

      const i18nOptions: Partial<DatePickerI18n> = {
        firstDayOfWeek: firstDayOfWeekToJsDayNumber[firstDayOfWeek],
        formatDate({ day, month, year }: DatePickerDate) {
          const date = convertDatePartsToMomentTZ({ year, month, date: day });

          return formatLocalDate(date.toISOString());
        },
        parseDate(date: string) {
          if (!date) {
            return;
          }
          const local = parseLocalDateWithWorkspaceDateFormatAsMoment(date);

          if (!local.isValid()) {
            return;
          }

          return {
            year: local.year(),
            month: local.month(),
            day: local.date(),
          };
        },
      };

      Object.assign(el.i18n, i18nOptions);

      function click(e: Event) {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
      function change(e: Event) {
        const target = e.target as HTMLInputElement | null;
        if (!target) {
          onClose?.();
          return;
        }

        const localString = formatLocalDate(target.value);
        const local = parseLocalDateWithWorkspaceTimeAsIsoDateTime(localString, workdayTimeType);

        if (localString === '-') {
          allowEmpty && onChange('');
        } else if (local) {
          onChange(local);
        }

        onClose?.();
      }

      function opened(e: Event) {
        const target = e.target as DatePicker | null;
        if (!target) {
          return;
        }

        if (target.opened) {
          return;
        }

        onClose?.();
      }

      function validityChanged() {
        const el = ref.current;
        if (!el) {
          return;
        }

        onValidityChanged?.(el.invalid);
      }

      function input(e: Event) {
        const el = e.target as HTMLInputElement;
        if (!el) {
          return;
        }

        onInput?.(el.value);
      }

      el.addEventListener('change', change);
      el.addEventListener('opened-changed', opened);
      el.addEventListener('invalid-changed', validityChanged);
      el.addEventListener('input', input);
      el.addEventListener('blur', onBlur ?? noop);
      overlay?.addEventListener('click', click);

      return () => {
        el.removeEventListener('change', change);
        el.removeEventListener('opened-changed', opened);
        el.removeEventListener('invalid-changed', validityChanged);
        el.removeEventListener('input', input);
        el.removeEventListener('blur', blur);
        overlay?.removeEventListener('click', click);
      };
    }, [
      allowEmpty,
      convertDatePartsToMomentTZ,
      firstDayOfWeek,
      formatLocalDate,
      onBlur,
      onChange,
      onClose,
      onInput,
      onValidityChanged,
      parseLocalDateWithWorkspaceDateFormatAsMoment,
      parseLocalDateWithWorkspaceTimeAsIsoDateTime,
      workdayTimeType,
    ]);

    useEffect(() => {
      const el = ref.current;
      if (!el) {
        return;
      }

      if (value) {
        const nextValue = convertLocalDateToSpecifiedTimezoneAndFormat(value, 'YYYY-MM-DD');
        if (nextValue !== '-') {
          el.value = nextValue;
        }
      } else {
        el.value = '';
        const underlyingInput = el.inputElement as HTMLInputElement;
        underlyingInput.value = '';
        el.clear();
      }
    }, [convertLocalDateToSpecifiedTimezoneAndFormat, value]);

    useEffect(() => {
      const el = ref.current;
      if (!el) {
        return;
      }

      if (min) {
        el.min = convertLocalDateToSpecifiedTimezoneAndFormat(min, 'YYYY-MM-DD');
      }
    }, [convertLocalDateToSpecifiedTimezoneAndFormat, min]);

    useEffect(() => {
      const el = ref.current;
      if (!el) {
        return;
      }

      if (open) {
        el.open();
      }
    }, [open]);

    useEffect(() => {
      const el = ref.current;
      if (!el) {
        return;
      }
      const defaultPlaceholder = getDatePickerDefaultPlaceholder(organizationUser?.dateFormat);

      el.clearButtonVisible = !!allowEmpty;
      el.placeholder = placeholder ?? defaultPlaceholder;
      el.disabled = !!disabled;
      el.required = !allowEmpty;
      el.invalid = !!error;
      el.className = className ?? '';
      el.showWeekNumbers = !!showWeekNumbers;
    }, [
      allowEmpty,
      className,
      convertLocalDateToSpecifiedTimezoneAndFormat,
      disabled,
      error,
      min,
      organizationUser?.dateFormat,
      placeholder,
      showWeekNumbers,
    ]);

    return <vaadin-date-picker small={small} rounded={rounded} inline={inline} ref={ref} />;
  },
  areEqual,
);
