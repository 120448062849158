import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ItemType, PackageStatus } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { FolderStatus, RelativePriorityType } from 'daos/item_enums';
import { filterAnd, filterFolderStatusOrNull, filterItemType, filterParentId } from 'daos/shared';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { clearItemPicker, updateItemPickerStore } from 'features/common/item_picker/slice';
import { awaitRequestFinish } from 'lib/api';
import { ApiError } from 'lib/api/types';
import { getItemForId, getPackageStatusForItemId } from 'state/entities/selectors/item';

import ItemMoveDialog from './item_move_dialog';

interface ItemMoveModalProps {
  errorBody?: ApiError;
  handleMove?: (parentId: number, location: RelativePriorityType) => void;
  header?: JSX.Element;
  itemId: number;
  onClose: () => void;
}

const ItemMoveModal = ({ errorBody, handleMove, header, itemId, onClose }: ItemMoveModalProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const item = useSelector((state) => getItemForId(state, itemId));
  const parentItemId = item?.parent?.id;
  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, itemId));
  const isTemplate = packageStatus === PackageStatus.TEMPLATE;
  const [fetchComplete, setFetchComplete] = useState(false);

  useEffect(() => {
    if (!fetchComplete && parentItemId) {
      const { uuid } = dispatch(
        ItemDao.fetchAll(
          { organizationId, workspaceId },
          {
            filter: filterAnd(
              filterItemType([ItemType.FOLDERS, ItemType.PACKAGES, ItemType.PROJECTS]),
              filterParentId(parentItemId),
              filterFolderStatusOrNull([FolderStatus.ACTIVE, FolderStatus.ON_HOLD]),
            ),
            include: { includeParent: true },
          },
        ),
      );

      dispatch(
        awaitRequestFinish(uuid, {
          onSuccess: ({ entities }) => {
            const items = entities.items ?? {};
            dispatch(updateItemPickerStore(items));
          },
          onFinish: () => setFetchComplete(true),
        }),
      );
    }
  }, [dispatch, fetchComplete, organizationId, parentItemId, workspaceId]);

  useEffect(() => {
    return () => {
      dispatch(clearItemPicker());
    };
  }, [dispatch]);

  return (
    <ItemMoveDialog
      errorBody={errorBody}
      filterItemTypes={[ItemType.PACKAGES, ItemType.PROJECTS, ItemType.FOLDERS]}
      handleMove={handleMove}
      header={header}
      isTemplate={isTemplate}
      itemId={itemId}
      onClose={onClose}
    />
  );
};

export default ItemMoveModal;
