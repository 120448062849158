import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { areEqual } from 'react-window';

import { caretRightSolid, exclamationSolid, LpIcon } from 'features/common/lp_icon';
import { LpNub } from 'features/common/lp_nub';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getContrast } from 'lib/color';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';
import { gray500, gray700, lpBrandWhite } from 'style/variables';

import './workload_date_range.scss';

interface WorkloadNubProps {
  dailyWorkSeconds: number | undefined;
  date: string;
  isParentTaskLate: boolean;
  isRollup?: boolean;
  late: boolean;
  nubColor?: string;
  planned: boolean;
  taskName?: string | null;
  timeOff: boolean;
  unavailable: boolean;
  username: string;
  workScheduledBeyondRange: boolean;
}

interface NubPopUpHoverText {
  displayName?: string;
  taskName?: string;
  defaultValue: string;
}

export const getWorkloadNubPopHoverText = ({
  date,
  dailyWorkSeconds,
  displayName,
  itemName,
  formatLocalDate,
  hasTimeOff,
  unavailable,
  workScheduledBeyondRange,
}: {
  date: string;
  dailyWorkSeconds: number | undefined;
  displayName: string;
  itemName: string;
  formatLocalDate: (date: string) => string;
  hasTimeOff: boolean;
  unavailable: boolean;
  workScheduledBeyondRange: boolean;
}): NubPopUpHoverText => {
  const dailyWorkHours = formatAsHours(convertSecondsToHours(dailyWorkSeconds ?? 0));

  const popUpData = {} as NubPopUpHoverText;

  if (hasTimeOff) {
    popUpData.defaultValue = `${displayName} has time off on ${formatLocalDate(date)}`;
    return popUpData;
  } else if (unavailable) {
    popUpData.defaultValue = `${displayName} is unavailable on ${formatLocalDate(date)}`;
    return popUpData;
  }

  if (workScheduledBeyondRange) {
    popUpData.defaultValue = `Beyond ${formatLocalDate(date)}`;
    return popUpData;
  }

  popUpData.defaultValue = `${formatLocalDate(date)} (${dailyWorkHours})`;
  popUpData.taskName = itemName ?? '';
  popUpData.displayName = displayName;
  return popUpData;
};

interface NubVariantsParams {
  dailyWorkSeconds: number | undefined;
  hasWorkScheduledOnDate: boolean;
  workScheduledBeyondRange: boolean;
  isRollup: boolean;
  renderAsLate: boolean;
  isEmptyWorkDate: boolean;
  timeOff: boolean;
  isParentTaskLate: boolean;
  unavailable: boolean;
  plannedWork: boolean;
}

export const getNubClassNames = (styleProps: NubVariantsParams) => {
  const {
    zeroWorkScheduled,
    workScheduled,
    workScheduledBeyondRange,
    rollUpRenderedAsLate,
    rollUp,
    unavailableWithLateParent,
    emptyWithLateParent,
    unavailable,
    empty,
    timeOff,
    plannedWork,
  } = getNubStyleVariantFlags(styleProps);

  return classNames('workload-date-range__nub', {
    'workload-date-range__nub--zero-work-scheduled': zeroWorkScheduled,
    'workload-date-range__nub--work-scheduled': workScheduled,
    'workload-date-range__nub--work-scheduled-beyond-range': workScheduledBeyondRange,
    'workload-date-range__nub--roll-up-rendered-as-late': rollUpRenderedAsLate,
    'workload-date-range__nub--roll-up': rollUp,
    'workload-date-range__nub--unavailable-with-late-parent unavailable': unavailableWithLateParent,
    'workload-date-range__nub--empty-with-late-parent': emptyWithLateParent,
    'workload-date-range__nub--unavailable unavailable': unavailable,
    'workload-date-range__nub--empty': empty,
    'workload-date-range__nub--time-off': timeOff,
    'workload-date-range__planned_work_nub': plannedWork,
    'workload-date-range__planned_work_nub--zero-work-scheduled': plannedWork && zeroWorkScheduled,
    'workload-date-range__planned_work_nub--work-scheduled': plannedWork && workScheduled,
    'workload-date-range__planned_work_nub--work-scheduled-beyond-range': plannedWork && workScheduledBeyondRange,
    'workload-date-range__planned_work_nub--roll-up-rendered-as-late': plannedWork && rollUpRenderedAsLate,
    'workload-date-range__planned_work_nub--roll-up': plannedWork && rollUp,
    'workload-date-range__planned_work_nub--unavailable-with-late-parent unavailable':
      plannedWork && unavailableWithLateParent,
    'workload-date-range__planned_work_nub--empty-with-late-parent': plannedWork && emptyWithLateParent,
    'workload-date-range__planned_work_nub--unavailable unavailable': plannedWork && unavailable,
    'workload-date-range__planned_work_nub--empty': plannedWork && empty,
    'workload-date-range__planned_work_nub--time-off': plannedWork && timeOff,
  });
};

interface NubVariantFlags {
  zeroWorkScheduled: boolean;
  workScheduled: boolean;
  workScheduledBeyondRange: boolean;
  rollUpRenderedAsLate: boolean;
  rollUp: boolean;
  unavailableWithLateParent: boolean;
  emptyWithLateParent: boolean;
  unavailable: boolean;
  empty: boolean;
  timeOff: boolean;
  plannedWork: boolean;
}

const getNubStyleVariantFlags = ({
  dailyWorkSeconds,
  hasWorkScheduledOnDate,
  workScheduledBeyondRange,
  isRollup,
  renderAsLate,
  isEmptyWorkDate,
  timeOff,
  isParentTaskLate,
  unavailable,
  plannedWork,
}: NubVariantsParams): NubVariantFlags => {
  const noWorkScheduled = dailyWorkSeconds === 0;
  const isEmptyNub = isEmptyWorkDate && !timeOff;

  return {
    zeroWorkScheduled: noWorkScheduled,
    workScheduled: hasWorkScheduledOnDate,
    workScheduledBeyondRange: workScheduledBeyondRange,
    rollUpRenderedAsLate: isRollup && renderAsLate,
    rollUp: isRollup && !timeOff,
    unavailableWithLateParent: isEmptyWorkDate && !timeOff && isParentTaskLate && unavailable,
    emptyWithLateParent: isEmptyWorkDate && !timeOff && isParentTaskLate,
    unavailable: isEmptyWorkDate && !timeOff && unavailable,
    empty: isEmptyNub,
    timeOff: timeOff,
    plannedWork: plannedWork && !workScheduledBeyondRange && !isEmptyNub,
  };
};

export const nubSymbol = (renderAsLate: boolean) => {
  if (renderAsLate) {
    return <LpIcon className="workload-date-range__nub-risk-icon" icon={exclamationSolid} size="xs" />;
  } else {
    return '';
  }
};

const WorkloadNub = memo(
  ({
    date,
    isRollup = false,
    late,
    nubColor,
    planned,
    timeOff,
    workScheduledBeyondRange,
    dailyWorkSeconds,
    username,
    isParentTaskLate,
    unavailable,
    taskName,
  }: WorkloadNubProps) => {
    const { formatLocalDate } = useLocalizedFormats();

    const hasWorkScheduledOnDate = !!dailyWorkSeconds;
    const renderAsLate = hasWorkScheduledOnDate && late;

    const isEmptyWorkDate = !hasWorkScheduledOnDate && !workScheduledBeyondRange;
    const applyNubColor = !isRollup && (hasWorkScheduledOnDate || workScheduledBeyondRange);

    const nubStyleColor = useMemo(() => {
      return applyNubColor
        ? { backgroundColor: `#${nubColor}`, border: planned ? `solid #${nubColor}` : undefined }
        : {};
    }, [applyNubColor, nubColor, planned]);

    const alertIconStyleColor = useMemo(() => {
      if (applyNubColor) {
        return nubColor ? { color: `${getContrast(nubColor)}` } : {};
      }
      if (isRollup) {
        return { color: getContrast(gray700) };
      }
      return { color: getContrast(gray500) };
    }, [applyNubColor, isRollup, nubColor]);

    const computedNubStyles = useMemo(() => {
      return { ...alertIconStyleColor, ...nubStyleColor };
    }, [alertIconStyleColor, nubStyleColor]);

    const nubClassNames = getNubClassNames({
      dailyWorkSeconds,
      hasWorkScheduledOnDate,
      workScheduledBeyondRange,
      isRollup,
      renderAsLate,
      isEmptyWorkDate,
      timeOff,
      isParentTaskLate,
      unavailable,
      plannedWork: planned,
    });

    const popupInfo = getWorkloadNubPopHoverText({
      date,
      dailyWorkSeconds,
      displayName: username,
      itemName: isRollup ? 'Rollup' : (taskName ?? ''),
      formatLocalDate,
      hasTimeOff: timeOff,
      unavailable,
      workScheduledBeyondRange,
    });

    const nubPopUpHoverText = () => {
      if (popupInfo.taskName) {
        return (
          <>
            <strong>{popupInfo.displayName}</strong>
            <br />
            <i>{popupInfo.taskName}</i>
            <br />
            {popupInfo.defaultValue}
          </>
        );
      }

      return popupInfo.defaultValue;
    };

    const symbol = nubSymbol(renderAsLate);

    return (
      <LpNub
        hoverContent={nubPopUpHoverText()}
        nubPopupClassName="workload-date-range__nub-hover"
        nubClassName={nubClassNames}
        nubStyle={workScheduledBeyondRange ? nubStyleColor : computedNubStyles}
      >
        {workScheduledBeyondRange ? (
          <LpIcon
            icon={caretRightSolid}
            color={lpBrandWhite}
            className="workload-date-range__nub--work-scheduled-beyond-range-icon"
            size="1x"
          />
        ) : (
          symbol
        )}
      </LpNub>
    );
  },
  areEqual,
);

export default WorkloadNub;
