import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReadonlyRecord } from 'lib/readonly_record';
import { resetStateExtraReducer } from 'state/root_actions';

import { EventScopes } from './types';

interface EventsSliceState {
  readonly pausedEventScopes: ReadonlyRecord<string, boolean>;
}

export const initialState: EventsSliceState = {
  pausedEventScopes: {},
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setPausedEventScopes: (state, action: PayloadAction<{ eventScope: EventScopes; pause: boolean }>) => {
      state.pausedEventScopes[action.payload.eventScope] = action.payload.pause;
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { setPausedEventScopes } = eventsSlice.actions;
export default eventsSlice.reducer;
