import { createSelector } from 'reselect';

import { WorkloadGroup, WorkloadItem, OrganizationUser } from 'daos/model_types';
import { RootState } from 'state/root_reducer';

interface WorkloadGroupsById {
  [key: number]: WorkloadGroup;
}

interface WorkloadItemById {
  [key: number]: WorkloadItem;
}

export interface FlattenedWorkloadAvailabilityData {
  group: WorkloadGroup;
  dates: ReadonlyArray<string>;
  orgUser: OrganizationUser;
}

export const getWorkloadById = ({ entities }: RootState, id: number) => entities.workloads[id];

export const getWorkloadGroupsIndexedByGroupIdForWorkloadId = createSelector(getWorkloadById, (workload) => {
  return workload?.groups.reduce((acc: WorkloadGroupsById, group) => {
    acc[group.id] = group;
    return acc;
  }, {});
});

export const getWorkloadGroupItemsIndexedByItemIdForWorkloadIdAndOrgUserId = createSelector(
  getWorkloadGroupsIndexedByGroupIdForWorkloadId,
  (_: RootState, __: number, orgUserId: number) => orgUserId,
  (workloadGroupsById, orgUserId) => {
    return (
      workloadGroupsById?.[orgUserId]?.workloadItems.reduce((acc: WorkloadItemById, item) => {
        acc[item.id] = item;

        return acc;
      }, {}) ?? {}
    );
  },
);

export const getFlattenedWorkloadGroupsOrgUsersAndDates = createSelector(
  getWorkloadById,
  (state: RootState) => state.entities.organizationUsers,
  (workload, orgUsersById) => {
    return (
      workload?.groups.reduce<Array<FlattenedWorkloadAvailabilityData>>((acc, curr) => {
        const orgUserId = curr.organizationUser?.id;
        const orgUser = orgUsersById[orgUserId ?? ''];

        if (!orgUser) {
          return acc;
        }

        acc.push({
          group: curr,
          orgUser,
          dates: workload.dates,
        });

        return acc;
      }, []) ?? []
    );
  },
);
