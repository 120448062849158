import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError } from 'lib/api/types';
import { resetStateExtraReducer } from 'state/root_actions';

interface ErrorState {
  readonly apiError: Error | null;
}

export const initialState: ErrorState = {
  apiError: null,
};

class UnhandledApiError extends Error {
  readonly apiError: ApiError;
  constructor(apiError: ApiError) {
    const errorMessage = apiError.detail !== undefined ? `${apiError.title}: ${apiError.detail}` : apiError.title;
    super(errorMessage);
    this.apiError = apiError;
    Object.setPrototypeOf(this, UnhandledApiError.prototype);
  }
}

export const checkError = createAction<{ status: number }>('errors/CHECK_ERROR');

const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    clearApiError: () => initialState,
    setApiError: (state, action: PayloadAction<ApiError>) => {
      state.apiError = new UnhandledApiError(action.payload);
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { clearApiError, setApiError } = errorSlice.actions;
export default errorSlice.reducer;
