import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardGuest, WorkspaceUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { getDashboardsById } from 'features/dashboards/selectors';
import { awaitRequestFinish } from 'lib/api';
import { getDashboardGuests } from 'state/entities/selectors/dashboard_guest';
import { getIsGuestCurrentWorkspaceUser } from 'state/entities/selectors/user';

export const useFetchUserForProfile = ({
  organizationUserId = 0,
  workspaceUserId = 0,
}: {
  organizationUserId?: number;
  workspaceUserId?: number;
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const isCurrentWsUserGuest = useSelector(getIsGuestCurrentWorkspaceUser);

  const [fetchComplete, setFetchComplete] = useState(true);
  const [fetchError, setFetchError] = useState(false);

  const dashboards = Object.values(useSelector(getDashboardsById));
  const dashboardGuests = useSelector(getDashboardGuests);
  const dashboardGuestsByDashboardId = dashboards.reduce(
    (acc: { [dashboardId: number]: DashboardGuest }, dashboard) => {
      const dbGuest = dashboardGuests.find(
        (dbGuest) =>
          dbGuest.workspaceUserId === workspaceUserId.toString() && dbGuest.dashboardId === dashboard.id.toString(),
      );

      if (dbGuest) {
        acc[dashboard.id] = dbGuest;
      }

      return acc;
    },
    {},
  );

  const onFetchError = useCallback(() => setFetchError(true), [setFetchError]);
  const onFetchFinish = useCallback(
    (afterOnFinish: () => void) => {
      setFetchComplete(true);
      afterOnFinish();
    },
    [setFetchComplete],
  );

  const fetchOrgUser = useCallback(
    (afterOnFinish: () => void) => {
      if (organizationUserId) {
        setFetchError(false);
        setFetchComplete(false);

        const { uuid } = dispatch(
          OrganizationUserDao.fetch(
            { organizationId, organizationUserId },
            { include: { includeWorkspaceUsers: true, includeDashboardGuestWorkspaces: true } },
          ),
        );

        dispatch(
          awaitRequestFinish(uuid, {
            onError: onFetchError,
            onFinish: () => onFetchFinish(afterOnFinish),
          }),
        );
      }
    },
    [dispatch, onFetchError, onFetchFinish, organizationId, organizationUserId],
  );

  const fetchWorkspaceUser = useCallback(
    (afterOnFinish: () => void) => {
      if (workspaceUserId) {
        setFetchError(false);
        setFetchComplete(false);

        const { uuid } = dispatch(
          WorkspaceUserDao.fetch(
            { organizationId, workspaceId, workspaceUserId },
            { include: { includeGuestDashboards: true, includeGuestDashboardsAncestors: !isCurrentWsUserGuest } },
          ),
        );

        dispatch(
          awaitRequestFinish<WorkspaceUser>(uuid, {
            onError: onFetchError,
            onFinish: () => onFetchFinish(afterOnFinish),
          }),
        );
      }
    },
    [dispatch, isCurrentWsUserGuest, onFetchError, onFetchFinish, organizationId, workspaceId, workspaceUserId],
  );

  return {
    fetchComplete,
    fetchError,
    fetchOrgUser,
    fetchWorkspaceUser,
    guestDashboards: {
      dashboards: dashboards.filter((dashboard) => !!dashboardGuestsByDashboardId[dashboard.id]),
      dashboardGuestsByDashboardId,
    },
    setFetchComplete,
    setFetchError,
  };
};
