import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { loginWithNexus } from 'features/authentication/helpers';
import { useRedirectLocation } from 'hooks/use_redirect_location';
import { getNexusBaseUrl } from 'state/entities/selectors/system_settings';

const CHECK_SESSION_TIMEOUT = 10000;

interface CheckSessionEvent {
  hasSession: boolean;
  data?: Array<{
    app: string;
    appExternalId: string;
    organizationId: string;
    userExternalId: string;
    userId: string;
  }>;
  email?: string;
}

const appendCheckSessionIframe = (nexusBaseUrl: string) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.style.width = '0px';
  iframe.style.height = '0px';
  iframe.src = `${nexusBaseUrl}/check-session`;
  iframe.dataset.iframeId = 'iframe-check-session';
  document.body.appendChild(iframe);
};

const removeCheckSessionIframe = () => {
  const createdIframes = document?.querySelectorAll('[data-iframe-id="iframe-check-session"]');
  if (createdIframes?.length > 0) {
    createdIframes.forEach((iframe) => {
      iframe.remove();
    });
  }
};

const checkSessionListener = (
  nexusBaseUrl: string,
  redirectLocation: string | undefined,
  setIsCheckingSession: Dispatch<SetStateAction<boolean>>,
  event: MessageEvent<CheckSessionEvent>,
) => {
  if (event.origin !== nexusBaseUrl) {
    return;
  }

  removeCheckSessionIframe();
  window.removeEventListener('message', (event) =>
    checkSessionListener(nexusBaseUrl, redirectLocation, setIsCheckingSession, event),
  );

  const { hasSession } = event.data;
  if (hasSession) {
    loginWithNexus({ email: undefined, redirectLocation: redirectLocation });
    return;
  }
  setIsCheckingSession(false);
};

const addCheckSessionListener = (
  nexusBaseUrl: string,
  redirectLocation: string | undefined,
  setIsCheckingSession: Dispatch<SetStateAction<boolean>>,
) => {
  const messageListener = (event: MessageEvent) =>
    checkSessionListener(nexusBaseUrl, redirectLocation, setIsCheckingSession, event);
  window.addEventListener('message', messageListener);
  setTimeout(() => {
    removeCheckSessionIframe();
    window.removeEventListener('message', messageListener);
    setIsCheckingSession(false);
  }, CHECK_SESSION_TIMEOUT);
};

export const useNexusCheckSession = (setIsCheckingSession: Dispatch<SetStateAction<boolean>>) => {
  const nexusBaseUrl = useSelector(getNexusBaseUrl);
  const redirectLocation = useRedirectLocation();
  useEffect(() => {
    if (nexusBaseUrl) {
      setIsCheckingSession(true);
      appendCheckSessionIframe(nexusBaseUrl);
      addCheckSessionListener(nexusBaseUrl, redirectLocation, setIsCheckingSession);
    }
  }, [nexusBaseUrl, redirectLocation, setIsCheckingSession]);
};
