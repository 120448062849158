import classNames from 'classnames';
import { useMemo } from 'react';
import { Table } from 'semantic-ui-react';

import { CustomFieldNameBySourceType } from 'containers/shared/custom_field_name';
import { CustomFieldType, Permission } from 'daos/enums';
import { Item, ResourceId, WorkspaceUser } from 'daos/model_types';
import { visibilityPermissionLevel } from 'daos/permission';
import CustomFieldValue from 'features/common/custom_fields/custom_field_value';
import { eyeSolid, LpIcon } from 'features/common/lp_icon';
import { useHasAccess } from 'hooks/use_has_access';
import { FieldWithValueAndChoices } from 'state/entities/selectors/custom_field';

import RemoveSoleFieldValueCell from './custom_field_value/remove_sole_field_value_cell';

import 'features/common/custom_fields/custom_field_row.scss';

const CustomFieldRow = ({
  className,
  disabled,
  field,
  resource,
}: {
  className?: string;
  disabled: boolean;
  field: FieldWithValueAndChoices;
  resource: ResourceId<Item | WorkspaceUser>;
}) => {
  const sensitiveField = field.visibilityPermissionLevel !== Permission.OBSERVE;

  const { allowsMultipleValues, fieldType, archived, sourceSystem, name } = field;

  const fieldValuesIds = field?.values?.length ? field?.values.map((value) => value.id) : [];

  const hasAccess = useHasAccess(visibilityPermissionLevel[field.visibilityPermissionLevel as Permission], resource);

  const classNameForTableCell = useMemo(() => {
    switch (fieldType) {
      case CustomFieldType.TEXT:
      case CustomFieldType.MULTI_TEXT:
        return classNames('field-value textlist', allowsMultipleValues && 'multi');
      case CustomFieldType.ITEM:
      case CustomFieldType.MULTI_ITEM:
        return classNames('field-value item', allowsMultipleValues && 'multi');
      case CustomFieldType.LINK:
      case CustomFieldType.MULTI_LINK:
        return classNames('field-value link', allowsMultipleValues && 'multi');
      case CustomFieldType.PICKLIST:
      case CustomFieldType.MULTI_PICKLIST:
        return classNames('field-value picklist', allowsMultipleValues && 'multi');
      case CustomFieldType.USER:
      case CustomFieldType.MULTI_USER:
        return classNames('field-value user', allowsMultipleValues && 'multi');
      case CustomFieldType.NOTE:
        return 'custom-field-table__note';
      default:
        return '';
    }
  }, [allowsMultipleValues, fieldType]);

  if (!hasAccess) {
    return null;
  }

  return (
    <Table.Row className={classNames('custom-field__row', className)} key={field.id}>
      <Table.Cell
        content={
          <div className="custom-field__row-field">
            <div>
              <CustomFieldNameBySourceType name={name} sourceSystem={sourceSystem} />
              {archived && <span className="custom-field__row-field--archived">(Archived)</span>}
            </div>
            {sensitiveField && <LpIcon className="custom-field__row-field--sensitive" icon={eyeSolid} size="sm" />}
          </div>
        }
      />

      <Table.Cell
        className={classNameForTableCell}
        content={
          <CustomFieldValue disabled={disabled} resourceId={resource} fieldId={field.id} fieldType={fieldType} />
        }
      />

      <RemoveSoleFieldValueCell disabled={disabled} valueIds={fieldValuesIds} />
    </Table.Row>
  );
};

export default CustomFieldRow;
