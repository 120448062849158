import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import LpFormError from 'containers/shared/form_errors/lp_form_error';
import { WorkspaceDao } from 'daos/workspace';
import { useNameValidation } from 'features/administration/use_name_validation';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import LpFormInput from 'features/common/forms/lp_form_input';
import LpModal from 'features/common/modals/lp_modal';
import { setApiError } from 'features/errors/slice';
import { awaitRequestFinish } from 'lib/api';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';

const AddWorkspaceForm = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);

  const wsNameValidation = useNameValidation('Workspace');
  const validationSchema = yup.object().shape({
    workspaceName: wsNameValidation,
  });

  const { getFieldMeta, getFieldProps, submitForm, setStatus, status, isSubmitting } = useFormik({
    initialValues: {
      workspaceName: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({ workspaceName }) => {
      const { uuid } = dispatch(WorkspaceDao.create({ organizationId }, { name: workspaceName }));

      dispatch(
        awaitRequestFinish(uuid, {
          onError: ({ errors }) => {
            const error = errors[0];
            if (error) {
              dispatch(setApiError(error));
              setStatus(`${error.title}: $error.detail}`);
              onClose();
            } else {
              setStatus(lpErrorText.somethingWentWrong);
            }
          },
          onSuccess: () => onClose(),
        }),
      );
    },
  });

  return (
    <LpModal
      size="mini"
      onClose={onClose}
      header="Add Workspace"
      content={
        <Form loading={isSubmitting}>
          <LpFormInput
            label="Workspace Name"
            fieldKey="workspaceName"
            getFieldMeta={getFieldMeta}
            getFieldProps={getFieldProps}
          />
          {!!status && <LpFormError error={status} />}
        </Form>
      }
      actions={
        <>
          <Button content={'Cancel'} onClick={onClose} />
          <Button primary content={'Create'} onClick={submitForm} />
        </>
      }
    />
  );
};

export default AddWorkspaceForm;
