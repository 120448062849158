import * as yup from 'yup';

import { CustomFieldType } from 'daos/enums';
import { File } from 'daos/model_types';
import { schemaTestForTargetStartTargetFinish } from 'features/common/forms/schema/validation_helpers';
import {
  ItemIntakeRowSetting,
  ItemIntakeSettableField,
} from 'features/dashboards_v2/widget_click_through/intake/enums';
import {
  IntakeWidgetItemFieldToFieldIdKey,
  ItemIntakeFormSectionField,
  WidgetIntakeFormUserInputValidations,
} from 'features/dashboards_v2/widget_click_through/intake/types';
import { validateHoursAllowsEmptyValue } from 'features/item_panel/sections/planning_section/limits/validation';

export const validationSchema = (
  validationRules: WidgetIntakeFormUserInputValidations,
  itemFieldToFieldIdKey: IntakeWidgetItemFieldToFieldIdKey,
) => {
  return yup.lazy(() => {
    return yup.object(validationRules).test('target-start-before-target-finish', function (values) {
      const { createError } = this;
      return schemaTestForTargetStartTargetFinish({ values, itemFieldToFieldIdKey, createError });
    });
  });
};

enum YupValidationTypeBehavior {
  Boolean = 'boolean',
  Date = 'date',
  File = 'file',
  Link = 'link',
  Number = 'number',
  String = 'string',
  WorkLimit = 'workLimit',
}

const yupArrayValidationBehavior = (rowSetting: ItemIntakeRowSetting, primitiveType: YupValidationTypeBehavior) => {
  switch (rowSetting) {
    case ItemIntakeRowSetting.Required:
      return yup.array(yupValidateTypeBehavior(primitiveType)).min(1).required();
    case ItemIntakeRowSetting.Automatic:
    case ItemIntakeRowSetting.Optional:
    case ItemIntakeRowSetting.ReadOnly:
      return yup.array(yupValidateTypeBehavior(primitiveType));
    default:
      return yup.array(yup.string());
  }
};

const yupValidationBehavior = (rowSetting: ItemIntakeRowSetting, primitiveType: YupValidationTypeBehavior) => {
  switch (rowSetting) {
    case ItemIntakeRowSetting.Required:
      return yupValidateTypeBehavior(primitiveType).required();
    case ItemIntakeRowSetting.Automatic:
    case ItemIntakeRowSetting.Optional:
    case ItemIntakeRowSetting.ReadOnly:
      return yupValidateTypeBehavior(primitiveType);
    default:
      return yup.string();
  }
};

const yupValidateTypeBehavior = (primitiveType: YupValidationTypeBehavior) => {
  switch (primitiveType) {
    case YupValidationTypeBehavior.String:
      return yup.string();
    case YupValidationTypeBehavior.Number:
      return yup.number();
    case YupValidationTypeBehavior.Boolean:
      return yup.boolean();
    case YupValidationTypeBehavior.Date:
      return yup.date();
    case YupValidationTypeBehavior.File:
      return yup.mixed<File>();
    case YupValidationTypeBehavior.Link:
      return yup.string().url();
    case YupValidationTypeBehavior.WorkLimit:
      return yup.string().test('validate-work-limit', function (value) {
        const { isValidLimitHours } = validateHoursAllowsEmptyValue(value ?? '');
        return isValidLimitHours;
      });
    default:
      return yup.mixed<string | number | boolean | Date | File>();
  }
};

export const validationSchemaRules = (field: ItemIntakeFormSectionField, customFieldType?: CustomFieldType) => {
  if (customFieldType && field.itemField === ItemIntakeSettableField.CustomField) {
    switch (customFieldType) {
      case CustomFieldType.CHECKBOX:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.Boolean);
      case CustomFieldType.CURRENCY:
      case CustomFieldType.ITEM:
      case CustomFieldType.NUMERIC:
      case CustomFieldType.PICKLIST:
      case CustomFieldType.USER:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.Number);
      case CustomFieldType.DATE:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.Date);
      case CustomFieldType.LINK:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.Link);
      case CustomFieldType.NOTE:
      case CustomFieldType.TEXT:
      case CustomFieldType.MULTILINE_TEXT:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.String);
      case CustomFieldType.MULTI_ITEM:
      case CustomFieldType.MULTI_PICKLIST:
      case CustomFieldType.MULTI_USER:
        return yupArrayValidationBehavior(field.rowSetting, YupValidationTypeBehavior.Number);
      case CustomFieldType.MULTI_LINK:
        return yupArrayValidationBehavior(field.rowSetting, YupValidationTypeBehavior.Link);
      case CustomFieldType.MULTI_TEXT:
        return yupArrayValidationBehavior(field.rowSetting, YupValidationTypeBehavior.String);
      default:
        return yup.string();
    }
  } else {
    switch (field.itemField) {
      case ItemIntakeSettableField.TargetFinish:
      case ItemIntakeSettableField.TargetStart:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.Date);
      case ItemIntakeSettableField.Files:
        return yupArrayValidationBehavior(field.rowSetting, YupValidationTypeBehavior.File);
      case ItemIntakeSettableField.Color:
      case ItemIntakeSettableField.CostCode:
      case ItemIntakeSettableField.Description:
      case ItemIntakeSettableField.ProjectStatus:
      case ItemIntakeSettableField.Name:
      case ItemIntakeSettableField.ScheduleDirective:
      case ItemIntakeSettableField.TargetFinishType:
      case ItemIntakeSettableField.TaskStatus:
      case ItemIntakeSettableField.WorkType:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.String);
      case ItemIntakeSettableField.WorkLimit:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.WorkLimit);
      case ItemIntakeSettableField.RateSheetId:
        return yupValidationBehavior(field.rowSetting, YupValidationTypeBehavior.Number);
      default:
        return yup.string();
    }
  }
};
