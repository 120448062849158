import { createCachedSelector } from 're-reselect';
import { SortColumn, SortDirection as ReactDataGridSortDirection } from 'react-data-grid';
import { createSelector } from 'reselect';

import { StatusFilterGroups } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { WidgetConfig } from 'daos/types';
import { SortDirection } from 'daos/widget_column';
import { SelectionList } from 'features/common/selection_list/types';
import { getWidgetGridColumns } from 'features/dashboards/widget_click_through/grids/helpers';
import { DashboardsDraggableType } from 'features/dashboards_v2/context';
import {
  mapSelectionListToWidgetConfigColumns,
  mapWidgetConfigColumnsToSelectionList,
} from 'features/dashboards_v2/widget/common/columns';
import { WidgetGroupIds, WidgetIdsByGroupId } from 'features/dashboards_v2/widget/types';
import { compareByPriority } from 'lib/helpers/comparison_helpers';
import { readonlyArray } from 'lib/readonly_record';
import { getFieldsById } from 'state/entities/selectors/custom_field';
import { createCacheByIdConfig } from 'state/entities/selectors/shared';
import { getActiveCurrentWorkspaceTaskStatusesSortedByPriorityForFilter } from 'state/entities/selectors/task_status';
import { RootState } from 'state/root_reducer';

export const getDashboardForId = (state: RootState, id: string | number) => state.entities.dashboards[id];
export const getDashboardsById = (state: RootState) => state.entities.dashboards;

export const getDashboardForItemId = createCachedSelector(
  (_: RootState, itemId: string) => itemId,
  getDashboardsById,
  (itemId, dashboardMap) => {
    return Object.values(dashboardMap).find((dashboard) => dashboard.itemId === itemId);
  },
)(createCacheByIdConfig());

export const getDashboardIdForItemId = createCachedSelector(getDashboardForItemId, (dashboard) => {
  return dashboard?.id;
})(createCacheByIdConfig());

export const getWidgetGroupsById = (state: RootState) => state.entities.widgetGroups;
export const getWidgetGroupForId = (state: RootState, id: string) => state.entities.widgetGroups[id];
const getWidgetsById = (state: RootState) => state.entities.widgets;
export const getWidgetForId = (state: RootState, id: string) => state.entities.widgets[id];

const getWidgetGroupsForDashboardId = createCachedSelector(
  (_: RootState, dashboardId: string) => dashboardId,
  getWidgetGroupsById,
  (dashboardId, widgetGroupMap) => {
    return readonlyArray(
      Object.values(widgetGroupMap).filter((widgetGroup) => widgetGroup.dashboardId === dashboardId),
    );
  },
)(createCacheByIdConfig());

const getWidgetsForDashboardId = createCachedSelector(
  (_: RootState, dashboardId: string) => dashboardId,
  getWidgetsById,
  (dashboardId, widgetMap) => {
    return Object.values(widgetMap).filter((widget) => widget.dashboardId === dashboardId);
  },
)(createCacheByIdConfig());

export const getWidgetsSortedByPriorityForDashboardId = createCachedSelector(getWidgetsForDashboardId, (widgets) => {
  return readonlyArray([...widgets].sort(compareByPriority));
})(createCacheByIdConfig());

const getWidgetsSortedByPrioryByGroupIdForDashboardId = createCachedSelector(
  getWidgetsSortedByPriorityForDashboardId,
  (widgetsById) => {
    return Object.values(widgetsById).reduce<WidgetIdsByGroupId>((prev, curr) => {
      const widgetIds = prev[curr.widgetGroupId];

      if (widgetIds) {
        widgetIds.push({
          id: `w-${curr.id}`,
          widgetId: curr.id.toString(),
          widgetGroupId: curr.widgetGroupId,
          type: DashboardsDraggableType.Widget,
        });
      } else {
        prev[curr.widgetGroupId] = [
          {
            id: `w-${curr.id}`,
            widgetId: curr.id.toString(),
            widgetGroupId: curr.widgetGroupId,
            type: DashboardsDraggableType.Widget,
          },
        ];
      }

      return prev;
    }, {});
  },
)(createCacheByIdConfig());

export const getWidgetGroupIdsSortedByPriorityForDashboardId = createCachedSelector(
  getWidgetGroupsForDashboardId,
  getWidgetsSortedByPrioryByGroupIdForDashboardId,
  (widgetGroups, widgetIdsByGroupId) => {
    return [...widgetGroups].sort(compareByPriority).map<WidgetGroupIds>((widgetGroup) => ({
      id: `wg-${widgetGroup.id}`,
      widgetGroupId: widgetGroup.id.toString(),
      widgetIds: widgetIdsByGroupId[widgetGroup.id] ?? [],
      type: DashboardsDraggableType.Group,
    }));
  },
)(createCacheByIdConfig());

export const getPrioritizedTaskStatusIdsForWidgetId = createCachedSelector(
  (state: RootState) => state,
  (state: RootState, widgetId: string) => getWidgetForId(state, widgetId),
  (state, widget) => getWidgetConfigTaskStatusIds(state, widget?.config),
)(createCacheByIdConfig());

function getWidgetConfigTaskStatusIds(state: RootState, widgetConfig: WidgetConfig | undefined) {
  if (!widgetConfig?.taskStatusFilter) {
    return [];
  }

  const { taskStatusFilter, taskStatusIdsFilter } = widgetConfig;

  if (taskStatusFilter === StatusFilterGroups.Custom) {
    return taskStatusIdsFilter ?? [];
  }

  const prioritizedTaskStatuses = getActiveCurrentWorkspaceTaskStatusesSortedByPriorityForFilter(
    state,
    taskStatusFilter,
  );

  return readonlyArray(prioritizedTaskStatuses.map((ts) => ts.id));
}

export const getWidgetConfigSortColumn = createCachedSelector(getWidgetForId, (widget): SortColumn | undefined => {
  if (widget) {
    const widgetConfigSortColumn = widget.config.columns?.find((column) => column.direction !== null);

    if (widgetConfigSortColumn && widgetConfigSortColumn.direction) {
      const columnKey = widgetConfigSortColumn.customFieldId ?? widgetConfigSortColumn.column;
      return {
        columnKey: String(columnKey),
        direction: convertSortDirectionFromWidgetColumnSortDirection(widgetConfigSortColumn.direction),
      };
    }
  }
})(createCacheByIdConfig());

function convertSortDirectionFromWidgetColumnSortDirection(sortDirection: SortDirection): ReactDataGridSortDirection {
  switch (sortDirection) {
    case SortDirection.Ascending:
      return 'ASC';
    case SortDirection.Descending:
      return 'DESC';
  }
}

export const getListWidgetColumnSelectionList = createSelector(
  getFieldsById,
  (_: RootState, widget: Widget) => widget,
  (fieldsById, widget) => {
    const gridColumns = getWidgetGridColumns(widget.config.columns ?? [], widget.appliedDateRangeFilter?.ranges ?? []);

    return mapWidgetConfigColumnsToSelectionList(gridColumns, fieldsById, {});
  },
);

export const getMappedListWidgetConfigColumnsFromSelectionList = createSelector(
  (_: RootState, selectionList: ReadonlyArray<SelectionList>) => selectionList,
  getFieldsById,
  mapSelectionListToWidgetConfigColumns,
);
