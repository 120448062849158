import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import SelectionDropdown from 'features/common/inputs/dropdowns/selection_dropdown';
import { SelectionDropdownLocation } from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { useWorkloadTableContext } from 'features/common/workload/workload_table/context';
import WorkloadRail from 'features/common/workload/workload_table/workload_rail';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { useTrackBoundingBox } from 'hooks/use_track_bound_box';
import { daysUntilStartDisplayText } from 'lib/display_helpers/days_until_start_display_text';
import { getItemForId } from 'state/entities/selectors/item';

import { BASE_NUBS_COLUMN_WIDTH } from './helpers';

const TeamPlanningColumns = () => {
  return (
    <>
      <div className="workload-view__header-cell workload-view__header-cell-hours-column">Scheduled Hours</div>
      <div className="workload-view__header-cell workload-view__header-cell-hours-column">Planned Hours</div>
      <div className="workload-view__header-cell workload-view__header-cell-availability-column-span">
        Availability Used
      </div>
      <div className="workload-view__header-cell workload-view__header-cell-availability-used-column">Scheduled</div>
      <div className="workload-view__header-cell workload-view__header-cell-availability-used-column">
        Planned &amp; Actual
      </div>
    </>
  );
};

const NonTeamPlanningColumns = () => {
  return (
    <>
      <div className="workload-view__header-cell workload-view__header-cell-hours-column">Scheduled Hours</div>
      <div className="workload-view__header-cell workload-view__header-cell-availability-used-column">
        Availability Used
      </div>
    </>
  );
};

export const WorkloadHeader = () => {
  const { workload, setExpandedOrgUserIds, hasGeneralTeamBasedPlanning } = useWorkloadTableContext();
  const { formatLocalDate } = useLocalizedFormats();
  const { dayRange } = useWorkloadTableContext();

  const workLoadUserIds = workload?.groups.map((user) => user.id);
  const workloadItemId = workload?.item?.id;
  const item = useSelector((state) => (workloadItemId ? getItemForId(state, workloadItemId) : undefined));
  const { box: sizingBox, ref: sizingRef } = useTrackBoundingBox();

  if (!workload) {
    return null;
  }

  const nubsColumnWidth = dayRange * BASE_NUBS_COLUMN_WIDTH;

  return (
    <div
      className={classNames(
        'workload-view__header',
        hasGeneralTeamBasedPlanning && 'workload-view__header--team-planning',
      )}
    >
      <div key="Active Tasks" className="workload-view__header-cell workload-view__header-cell-name-column">
        <SelectionDropdown
          workLoadUserIds={workLoadUserIds}
          setSelectedItems={setExpandedOrgUserIds}
          location={SelectionDropdownLocation.Workload}
        />
        <span>Active Tasks</span>
      </div>
      <div
        style={{
          width: nubsColumnWidth,
        }}
        key="days from now..."
        className="workload-view__header-workload-rail-cell"
        ref={sizingRef}
      >
        <div className="workload-view__header-cell-today">
          <span className="workload-view__header-cell-days-from-now">
            {daysUntilStartDisplayText(workload.daysUntilStart)}
          </span>
          <p className="workload-view__header-cell-days-from-now-dates">
            {`${formatLocalDate(workload.startDate)} - ${formatLocalDate(workload.endDate)} (${
              workload.dates.length ?? 0
            }d)`}
          </p>
        </div>
        {(item?.itemType === ItemType.PACKAGES || item?.itemType === ItemType.PROJECTS) && (
          <WorkloadRail
            startDate={formatLocalDate(workload.startDate)}
            endDate={formatLocalDate(workload.endDate)}
            itemId={workloadItemId ?? 0}
            headerSizingBox={sizingBox}
          />
        )}
      </div>
      {hasGeneralTeamBasedPlanning ? <TeamPlanningColumns /> : <NonTeamPlanningColumns />}
    </div>
  );
};
