import classNames from 'classnames';
import { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';

import ToggleButton from 'features/common/inputs/toggle_button';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { JiraTable } from 'features/jira_project/modal/types';
import { isTableExpanded } from 'features/jira_project/modal/utils';

const getMandatoryAsterisk = (isReadOnly: boolean) => {
  return !isReadOnly ? '*' : '';
};

export const JiraProjectTable = ({
  children,
  title,
  optionText = false,
  actionButton,
  isEmpty,
  isReadOnly = false,
  collapsible = false,
  isLast = false,
  jiraTable,
}: {
  children: ReactNode;
  title?: ReactNode;
  optionText?: boolean;
  actionButton?: ReactNode;
  isEmpty?: boolean;
  jiraTable?: JiraTable;
  isReadOnly?: boolean;
  collapsible?: boolean;
  isLast?: boolean;
}) => {
  const {
    jiraToLpFieldMappingTableIsExpanded,
    setJiraToLpFieldMappingTableIsExpanded,
    lpToJiraFieldMappingTableIsExpanded,
    setLpToJiraFieldMappingTableIsExpanded,
  } = useJiraProjectModalContext();

  const isStoryPointsSchemeTable = jiraTable === JiraTable.StoryPointsScheme;

  const expanded = isTableExpanded({
    jiraTable,
    jiraToLpFieldMappingTableIsExpanded,
    lpToJiraFieldMappingTableIsExpanded,
  });

  const handleToggle = () => {
    if (jiraTable === JiraTable.JiraToLp) {
      setJiraToLpFieldMappingTableIsExpanded(!jiraToLpFieldMappingTableIsExpanded);
    }
    if (jiraTable === JiraTable.LpToJira) {
      setLpToJiraFieldMappingTableIsExpanded(!lpToJiraFieldMappingTableIsExpanded);
    }
  };

  return (
    <section>
      <div
        className={classNames(
          'jira-project-modal__content-info-helper-container',
          collapsible && 'jira-project-modal__content-info-helper-container-collapsible',
          !expanded && 'jira-project-modal__content-info-helper-container-collapsible-collapsed',
          isLast && 'jira-project-modal__content-info-helper-container-collapsible-last',
        )}
        onClick={collapsible ? handleToggle : undefined}
      >
        <div className="jira-project-modal__content-info-helper-text">
          {collapsible && <ToggleButton expanded={expanded} onToggle={handleToggle} />} {title}{' '}
          {optionText && <span className="jira-project-modal__content-info-helper-text-italic">(Optional)</span>}
        </div>
      </div>
      {expanded && (
        <div className={classNames(collapsible && 'jira-project-modal__content-table-container-expanded')}>
          <div className="jira-project-modal__content-table-action-button">{actionButton}</div>
          {isStoryPointsSchemeTable && (
            <div className="jira-project-modal__content-table-estimate-mapping-title">
              <div className="jira-project-modal__content-table-estimate-mapping-title-text">
                {`Story Points${getMandatoryAsterisk(isReadOnly)}`}
              </div>
              <div className="jira-project-modal__content-table-estimate-mapping-title-text">
                {`Remaining Estimates (Low - High)${getMandatoryAsterisk(isReadOnly)}`}
              </div>
            </div>
          )}
          <Table
            celled
            definition
            compact
            className={classNames(
              'custom-field-table',
              'jira-project-modal__content-table',
              collapsible && 'jira-project-modal__content-table-collapsible',
              isStoryPointsSchemeTable && 'jira-project-modal__content-table--story-points-scheme',
              isEmpty && 'jira-project-modal__content-table--empty',
            )}
          >
            <Table.Body>{children}</Table.Body>
          </Table>
        </div>
      )}
    </section>
  );
};
