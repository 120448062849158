import classNames from 'classnames';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Checkbox, Popup, Button, Label } from 'semantic-ui-react';

import { OrganizationUser } from 'daos/model_types';
import { OrganizationApiTokens, OrganizationApiToken } from 'daos/organization_api_tokens';
import { OrganizationUserDao } from 'daos/organization_user';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import UserDropdown from 'features/common/inputs/dropdowns/user_dropdown';
import LpInput from 'features/common/inputs/lp_input';
import { exclamationTriangleSolid, LpIcon, trashXmarkLight } from 'features/common/lp_icon';
import { CopyTextButton } from 'features/common/portable_link_handler/copy_text_button';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { useUserFirstLastOrUsername } from 'hooks/use_user_first_last_or_username';
import { awaitRequestFinish } from 'lib/api';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { getOrganizationUserForId } from 'state/entities/selectors/user';
import { lpBrandWhite } from 'style/variables';

interface ApiAccessTableRowProps {
  apiToken: OrganizationApiToken;
  handleDeleteTokenClick: (tokenId: number) => () => void;
  orgUsers: ReadonlyArray<OrganizationUser>;
  readOnly: boolean;
  setApiTokens: Dispatch<SetStateAction<Array<OrganizationApiToken>>>;
}

export const ApiAccessTableRow = ({
  apiToken,
  handleDeleteTokenClick,
  orgUsers,
  readOnly,
  setApiTokens,
}: ApiAccessTableRowProps) => {
  const dispatch = useDispatch();
  const { formatLocalDate } = useLocalizedFormats();
  const organizationId = useSelector(getCurrentOrganizationId);

  const apiOrgUser = useSelector((state) => getOrganizationUserForId(state, apiToken?.organizationUserId.id ?? 0));
  const isOrgUserDisconnected = !!apiOrgUser?.disconnectedAt || !apiOrgUser?.hasActiveWsNonGuestUser;

  const runAsUserOptions = getRunAsOrgUserOptions(orgUsers, apiToken);

  const updateApiToken = (orgApiTokenPayload: Partial<OrganizationApiToken>) => {
    const { uuid } = dispatch(
      OrganizationApiTokens.update(
        {
          organizationId,
          tokenId: apiToken.id,
        },
        orgApiTokenPayload,
      ),
    );

    dispatch(
      awaitRequestFinish<Array<OrganizationApiToken>>(uuid, {
        onSuccess: () => {
          setApiTokens((prevTokens) =>
            prevTokens.map((prevToken) => {
              if (prevToken.id === apiToken.id) {
                return { ...prevToken, ...orgApiTokenPayload };
              }
              return prevToken;
            }),
          );
        },
      }),
    );
  };
  const handleToggleActiveClick = () => {
    updateApiToken({ tokenActive: !apiToken.tokenActive });
  };
  const handleTokenNameChange = (newName: string) => updateApiToken({ description: newName });
  const handleTokenUserChange = (_: SyntheticEvent, { value }: { value: number }) =>
    updateApiToken({ organizationUserId: OrganizationUserDao.id(value) });

  return (
    <Table.Row disabled={readOnly}>
      <Table.Cell
        textAlign="center"
        content={
          <Checkbox
            checked={!isOrgUserDisconnected && apiToken.tokenActive}
            disabled={readOnly || isOrgUserDisconnected}
            onChange={handleToggleActiveClick}
          />
        }
      />
      <Table.Cell
        className="name-cell"
        content={<LpInput disabled={readOnly} fluid value={apiToken.description} onChange={handleTokenNameChange} />}
      />
      <Table.Cell
        className="run-as-cell"
        content={
          <>
            <UserDropdown
              apiOrgUserOptions={runAsUserOptions}
              clearable={false}
              disabled={readOnly}
              onChange={handleTokenUserChange}
              placeholder=""
              selectedOrgUserId={apiToken.organizationUserId.id}
              selectOnBlur={true}
            />
            {isOrgUserDisconnected && (
              <Label
                className="icon notice alert"
                content={
                  <LpIcon
                    color={lpBrandWhite}
                    hoverText="Token disabled: Member was disconnected"
                    icon={exclamationTriangleSolid}
                    size="sm"
                  />
                }
                color="red"
              />
            )}
          </>
        }
      />
      <Table.Cell
        className="token-cell"
        content={
          <>
            <Popup
              content={<span className="token-cell__popup">{apiToken.token}</span>}
              position="top center"
              hoverable
              trigger={
                <span className="token-cell__trigger">
                  {apiToken.token.substring(0, 7)}
                  {HORIZONTAL_ELLIPSIS}
                </span>
              }
            />
            <CopyTextButton className="token-cell__button" textToCopy={apiToken.token} />
          </>
        }
      />
      <Table.Cell textAlign="center" content={formatLocalDate(apiToken.createdAt)} />

      <Table.Cell content={useUserFirstLastOrUsername(apiToken.createdBy.id)} />
      <Table.Cell
        textAlign="center"
        content={
          <Button basic icon={<LpIcon icon={trashXmarkLight} />} onClick={handleDeleteTokenClick(apiToken.id)} />
        }
      />
    </Table.Row>
  );
};

function getRunAsOrgUserOptions(orgUsers: ReadonlyArray<OrganizationUser>, apiToken: OrganizationApiToken) {
  return orgUsers
    .filter((orgUser) => {
      const isActive = orgUser.disconnectedAt === null && orgUser.hasActiveWsNonGuestUser;
      const hasToken = orgUser.id === apiToken.organizationUserId.id;

      return isActive || hasToken;
    })
    .sort((a, b) => a.username.localeCompare(b.username))
    .map((orgUser) => ({
      text: (
        <span className={classNames({ 'evicted-user': !!orgUser.disconnectedAt || !orgUser.hasActiveWsNonGuestUser })}>
          <UserAvatar orgUserId={orgUser.id} size={AvatarSize.ExtraSmall_1_2} />
          {orgUser.username}
        </span>
      ),
      value: orgUser.id,
      search: orgUser.username.toLocaleLowerCase(),
      key: orgUser.id,
    }));
}
