import { noop } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDeleteModal from 'containers/shared/confirm_delete_modal';
import ItemMoveModal from 'containers/shared/move_modal';
import TemplatePickerModal from 'containers/shared/template_picker';
import { ItemType, PackageStatus } from 'daos/enums';
import { Item } from 'daos/model_types';
import { confirmItemDeleteModalProps, useDeleteItems } from 'features/common/bulk_selection/options/delete_items';
import { useMoveItems } from 'features/common/bulk_selection/options/move_items';
import {
  getBulkSelectedItemIds,
  getBulkSelectedItemsContextMenuType,
  getIsBulkSelectionActiveForId,
} from 'features/common/bulk_selection/selectors';
import { BulkSelectionOptionsItemType } from 'features/common/bulk_selection/types';
import { CreatePackageInCollectionModal } from 'features/common/create_package_in_collection_modal';
import { CreateProjectInPackageModal } from 'features/common/create_project_in_package_modal';
import EditProjectAndFolderGridModal from 'features/common/data_grid/add_edit_grid/modal_edit_grid/edit_item_grids/project_and_folder_modal';
import { EditTaskGridModal } from 'features/common/data_grid/add_edit_grid/modal_edit_grid/edit_item_grids/task_modal';
import { Events, EventScopes, JobEventDataResult, JobResultStatus } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { BulkItemEllipsisActionDropdown } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/bulk_item_dropdown';
import { ConfirmItemDuplicationModal } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/confirm_item_duplication_modal';
import { useDuplicateItems } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/hooks/use_duplicate_items';
import { useItemsHaveFiles } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/hooks/use_items_have_files';
import { SingleItemEllipsisActionDropdown } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/single_item_dropdown';
import { EllipsisActionDropdownProps } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import BeyondLimitModal from 'features/organization_directory/manage_account/beyond_limit_modal';
import { deleteProjectHubCurrentItemIds } from 'features/ppp/project/slice';
import { useItemListContext } from 'features/ppp/project/task_list/context';
import { ApiError } from 'lib/api/types';
import { pluralize } from 'lib/helpers';
import { getPackageStatusForItemId } from 'state/entities/selectors/item';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

import { PackageToTemplateModal } from './package_to_template_modal';
import './ellipsis_action_dropdown.scss';

const BulkEditComponent = ({
  fetchItems,
  isTemplateGrid,
  setOpenBulkEdit,
}: {
  fetchItems: () => void;
  isTemplateGrid: boolean;
  setOpenBulkEdit: Dispatch<SetStateAction<boolean>>;
}) => {
  const selectedItemIds = useSelector(getBulkSelectedItemIds);
  const bulkSelectionType = useSelector(getBulkSelectedItemsContextMenuType);
  if (bulkSelectionType === BulkSelectionOptionsItemType.Projects) {
    return (
      <EditProjectAndFolderGridModal
        itemType={ItemType.PROJECTS}
        fetchProjects={fetchItems}
        isTemplateGrid={isTemplateGrid}
        selectedProjectIds={selectedItemIds}
        setOpen={setOpenBulkEdit}
      />
    );
  }

  if (bulkSelectionType === BulkSelectionOptionsItemType.Folders) {
    return (
      <EditProjectAndFolderGridModal
        itemType={ItemType.FOLDERS}
        fetchProjects={fetchItems}
        isTemplateGrid={isTemplateGrid}
        selectedProjectIds={selectedItemIds}
        setOpen={setOpenBulkEdit}
      />
    );
  }

  if (bulkSelectionType === BulkSelectionOptionsItemType.Tasks) {
    return (
      <EditTaskGridModal
        fetchTasks={fetchItems}
        isTemplateGrid={isTemplateGrid}
        selectedTaskIds={selectedItemIds}
        setOpen={setOpenBulkEdit}
      />
    );
  }
  return null;
};

export const EllipsisActionDropdown = (props: EllipsisActionDropdownProps) => {
  const dispatch = useDispatch();
  const workspace = useSelector(getCurrentWorkspace);

  const { item, fetchItems = noop } = props;
  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, item.id));

  const [beyondLimitModalOpen, setBeyondLimitModalOpen] = useState(false);
  const showBeyondLimitModal = () => setBeyondLimitModalOpen(true);
  const closeBeyondLimitModal = () => setBeyondLimitModalOpen(false);

  const [openTemplatePickerModal, setOpenTemplatePickerModal] = useState(false);
  const closeTemplatePickerModal = () => setOpenTemplatePickerModal(false);

  const [openPackageToTemplateModal, setOpenPackageToTemplateModal] = useState<
    'loading' | 'success' | 'error' | undefined
  >();
  const closePackageToTemplateModal = () => setOpenPackageToTemplateModal(undefined);

  const [openProjectToTemplateModal, setOpenProjectToTemplateModal] = useState(false);
  const closeProjectToTemplateModal = () => setOpenProjectToTemplateModal(false);

  const [openTemplateToPackageModal, setOpenTemplateToPackageModal] = useState(false);
  const closeTemplateToPackageModal = () => setOpenTemplateToPackageModal(false);

  const [openTemplateToProjectModal, setOpenTemplateToProjectModal] = useState(false);
  const closeTemplateToProjectModal = () => setOpenTemplateToProjectModal(false);

  const [jobId, setJobId] = useState<string | undefined>();

  const selectedItemIds = useSelector(getBulkSelectedItemIds);
  const isBulkSelectionActive = useSelector((state) => getIsBulkSelectionActiveForId(state, item.id));
  const bulkSelectionType = useSelector(getBulkSelectedItemsContextMenuType);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const showConfirmDelete = () => setOpenConfirmDelete(true);
  const hideConfirmDelete = () => setOpenConfirmDelete(false);

  const onDeleteSuccess = () => {
    if (bulkSelectionType && bulkSelectionType != BulkSelectionOptionsItemType.Projects) {
      dispatch(deleteProjectHubCurrentItemIds(new Set([...selectedItemIds])));
    }
  };
  const handleDelete = useDeleteItems({ onDeleteSuccess, selectedItemIds });

  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [moveError, setMoveError] = useState<ApiError | undefined>(undefined);
  const showMoveModal = () => setOpenMoveModal(true);
  const closeMoveModal = () => setOpenMoveModal(false);
  const handleMoveAction = useMoveItems({
    closeMoveModal,
    fetchItems,
    selectedItemIds,
    setMoveError,
  });

  const [openBulkEdit, setOpenBulkEdit] = useState(false);
  const showBulkEdit = () => setOpenBulkEdit(true);

  const [itemsPendingDuplication, setItemsPendingDuplication] = useState<ReadonlyArray<Item>>([]);
  const { fetchItems: fetchProjectHubItems } = useItemListContext();
  const duplicateItems = useDuplicateItems();
  const itemsHaveFiles = useItemsHaveFiles();
  const handleDuplicateItems = (items: ReadonlyArray<Item>) => {
    duplicateItems(items, {
      onSuccess: () => {
        fetchItems();
        fetchProjectHubItems && fetchProjectHubItems();
      },
      onBeyondLimitError: showBeyondLimitModal,
    });
  };

  const onDuplicateItems = (items: ReadonlyArray<Item>) => {
    if (workspace?.fileUploads && packageStatus !== PackageStatus.TEMPLATE) {
      itemsHaveFiles(items, {
        onSuccess: (bool: boolean) => {
          bool ? setItemsPendingDuplication(items) : handleDuplicateItems(items);
        },
      });
    } else {
      handleDuplicateItems(items);
    }
  };

  const onDuplicateItem = (item: Item) => onDuplicateItems([item]);

  const onConfirmDuplicateItemsModal = () => {
    handleDuplicateItems(itemsPendingDuplication);
    setItemsPendingDuplication([]);
  };

  const handleTemplateCreatedEvent = (event?: string) => {
    if (event) {
      try {
        const eventData: JobEventDataResult = JSON.parse(event);
        if (eventData.jobId === jobId) {
          setOpenPackageToTemplateModal(eventData.result === JobResultStatus.SUCCESS ? 'success' : 'error');
          setJobId('');
        }
        // eslint-disable-next-line unused-imports/no-unused-vars
      } catch (_e) {
        // ignore
      }
    }
  };

  useEvents({
    event: Events.ASYNC_JOB,
    callback: handleTemplateCreatedEvent,
    scope: EventScopes.None,
  });

  return (
    <>
      {beyondLimitModalOpen && <BeyondLimitModal onClose={closeBeyondLimitModal} />}

      {openTemplatePickerModal && (
        <TemplatePickerModal
          fetchItems={fetchItems}
          handleCloseModal={closeTemplatePickerModal}
          parentItemId={item.itemType === ItemType.PROJECTS && item.parent ? item.parent.id : item.id}
        />
      )}

      {openProjectToTemplateModal && (
        <CreateProjectInPackageModal
          handleCloseModal={closeProjectToTemplateModal}
          projectId={item.id}
          isCreatingTemplate={true}
        />
      )}

      {openPackageToTemplateModal && (
        <PackageToTemplateModal
          openPackageToTemplateModal={openPackageToTemplateModal}
          closepackageToTemplateModal={closePackageToTemplateModal}
        />
      )}

      {openTemplateToPackageModal && (
        <CreatePackageInCollectionModal handleCloseModal={closeTemplateToPackageModal} packageId={item.id} />
      )}

      {openTemplateToProjectModal && (
        <CreateProjectInPackageModal
          handleCloseModal={closeTemplateToProjectModal}
          projectId={item.id}
          isCreatingTemplate={false}
        />
      )}

      {itemsPendingDuplication.length > 0 && <ConfirmItemDuplicationModal onClick={onConfirmDuplicateItemsModal} />}

      {isBulkSelectionActive ? (
        <>
          {openConfirmDelete && (
            <ConfirmDeleteModal
              onDelete={handleDelete}
              onClose={hideConfirmDelete}
              {...confirmItemDeleteModalProps(selectedItemIds)}
            />
          )}
          {openMoveModal && selectedItemIds[0] && (
            <ItemMoveModal
              errorBody={moveError}
              itemId={selectedItemIds[0]}
              handleMove={handleMoveAction}
              onClose={closeMoveModal}
              header={<span style={{ fontSize: '1.5rem' }}>Moving {pluralize('Item', selectedItemIds.length)}</span>}
            />
          )}
          {openBulkEdit && (
            <BulkEditComponent
              fetchItems={fetchItems}
              isTemplateGrid={props.isTemplateGrid}
              setOpenBulkEdit={setOpenBulkEdit}
            />
          )}
          <BulkItemEllipsisActionDropdown
            clickLocation={props.clickLocation}
            handleHideContextMenu={props.handleHideContextMenu}
            handleDuplicateItems={onDuplicateItems}
            showBulkEdit={showBulkEdit}
            showConfirmDelete={showConfirmDelete}
            showMoveModal={showMoveModal}
            showActionTriggerIcon={props.showActionTriggerIcon}
            triggerRef={props.triggerRef}
          />
        </>
      ) : (
        <SingleItemEllipsisActionDropdown
          {...props}
          setJobId={setJobId}
          handleDuplicateItem={onDuplicateItem}
          showBeyondLimitModal={showBeyondLimitModal}
          setOpenTemplatePickerModal={setOpenTemplatePickerModal}
          setOpenPackageToTemplateModal={setOpenPackageToTemplateModal}
          setOpenProjectToTemplateModal={setOpenProjectToTemplateModal}
          setOpenTemplateToPackageModal={setOpenTemplateToPackageModal}
          setOpenTemplateToProjectModal={setOpenTemplateToProjectModal}
        />
      )}
    </>
  );
};
