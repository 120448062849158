import { uniqBy } from 'lodash';
import { createSelector } from 'reselect';

import { ItemType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { Item, ResourceId } from 'daos/model_types';
import { itemGlobalPrioritySort } from 'lib/helpers';
import { readonlyArray } from 'lib/readonly_record';
import { getItemsForIds } from 'state/entities/selectors/item';
import { getNumberArgument } from 'state/entities/selectors/shared';
import { RootState } from 'state/root_reducer';

import { BulkSelectionOptionsItemType as BulkSelectionOptionItemType } from './types';

export const getBulkSelectedTaskStatusGroup = ({ bulkSelection }: RootState) => bulkSelection.selectedTaskStatusGroup;

export const getBulkSelectedItemIds = ({ bulkSelection }: RootState) => bulkSelection.selectedItemIds;
export const getBulkSelectedItemResources = createSelector(getBulkSelectedItemIds, (selectedItemIds) =>
  selectedItemIds.map((itemId) => ItemDao.id(itemId)),
);
export const getJobId = ({ bulkSelection }: RootState) => bulkSelection.jobId;

export const getIsBulkSelectionActive = ({ bulkSelection }: RootState) => !!bulkSelection.selectedItemIds.length;

export const getBulkSelectedItemIdsSet = createSelector(getBulkSelectedItemIds, (itemIds) => new Set(itemIds));

export const getIsBulkSelectionActiveForId = createSelector(
  getBulkSelectedItemIds,
  getNumberArgument,
  (selectedItemIds, itemId) => selectedItemIds.length > 1 && selectedItemIds.includes(itemId),
);

export const getBulkSelectedItems = createSelector(
  getBulkSelectedItemIds,
  (state: RootState) => state,
  (selectedItemIds, state) => {
    return getItemsForIds(state, selectedItemIds);
  },
);

export const getBulkSelectedItemsSortedByGlobalPriority = createSelector(getBulkSelectedItems, (items) =>
  readonlyArray([...items].sort(itemGlobalPrioritySort)),
);

export const getBulkSelectedItemParentResources = createSelector(getBulkSelectedItems, (selectedItems) => {
  const parentSet = new Set<ResourceId<Item>>();
  selectedItems.forEach((item) => item.parent && parentSet.add(item.parent));
  return readonlyArray(Array.from(parentSet));
});

const getBulkSelectedItemTypes = createSelector(getBulkSelectedItems, (selectedItems) =>
  selectedItems.reduce((acc: Array<ItemType>, item) => {
    const selectedItemType = item.itemType;

    if (!acc.includes(selectedItemType)) {
      acc.push(selectedItemType);
    }

    return acc;
  }, []),
);

export const getBulkSelectedItemsContextMenuType = createSelector(getBulkSelectedItemTypes, (selectedItemTypes) => {
  if (!selectedItemTypes.length) {
    return;
  }
  if (selectedItemTypes.every((type) => type === ItemType.PACKAGES)) {
    return BulkSelectionOptionItemType.Packages;
  }
  if (selectedItemTypes.every((type) => type === ItemType.PROJECTS)) {
    return BulkSelectionOptionItemType.Projects;
  }
  if (selectedItemTypes.every((type) => type === ItemType.TASKS)) {
    return BulkSelectionOptionItemType.Tasks;
  }
  if (selectedItemTypes.every((type) => type === ItemType.FOLDERS)) {
    return BulkSelectionOptionItemType.Folders;
  }
  if (selectedItemTypes.every((type) => type === ItemType.TASKS || type === ItemType.FOLDERS)) {
    return BulkSelectionOptionItemType.TasksAndFolders;
  }
  if (selectedItemTypes.every((type) => type === ItemType.ASSIGNMENTS)) {
    return BulkSelectionOptionItemType.Assignments;
  }
});

export const getIsSelectedBulkItemForItemId = createSelector(
  getBulkSelectedItemIds,
  (_: RootState, itemId: number) => itemId,
  (selectedItemIds, itemId) => {
    return selectedItemIds.includes(itemId);
  },
);

export const getBulkSelectedItemsHaveSameParent = createSelector(getBulkSelectedItems, (items) => {
  const firstItemParentId = items[0]?.parent?.id;
  return items.every((item) => item.parent?.id === firstItemParentId);
});

export const getItemHasBulkSelectedChildren = createSelector(
  getBulkSelectedItems,
  (_: RootState, itemId: number) => itemId,
  (selectedItems, itemId) => {
    return selectedItems.some((item) => item.parent?.id === itemId);
  },
);
export const getParentResourcesForSelectedItems = createSelector(getBulkSelectedItems, (selectedItems) =>
  uniqBy(
    selectedItems.map((selectedItem) => selectedItem.parent ?? ItemDao.id(0)),
    'id',
  ),
);
