import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Message, Segment } from 'semantic-ui-react';

import UnconfirmedEmailBanner from 'features/authentication/unauthenticated/token/invitation/unconfirmed_email_banner';
import { getCurrentUser } from 'features/common/current/selectors';
import { InvitationHeader } from 'features/common/invitation/header';
import { userSolid } from 'features/common/lp_icon';
import BeyondLimitModal from 'features/organization_directory/manage_account/beyond_limit_modal';
import { PlanGadgetSidebar } from 'features/organization_directory/manage_account/plan_gadget';
import useHashParams from 'hooks/use_hash_params';
import useOrganizationFetchOnOrganizationModified from 'hooks/use_organization_fetch_on_organization_modified';
import { ignoreCaseAndDiacriticalMarksCollator } from 'lib/helpers/ignore_case_and_diacritical_marks_collator';
import { readonlyArray } from 'lib/readonly_record';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import { getUsersById } from 'state/entities/selectors/user';

import { InviteUserForm } from './form';
import { getWorkspaceInformationForInvitationUser } from './selectors';

import './index.scss';

const UsersInvite = () => {
  const { email, workspaces: selectedWorkspacesParam } = useHashParams();
  const selectedWorkspaces = readonlyArray(selectedWorkspacesParam?.split(',') ?? []);

  const [newUserEmail, setNewUserEmail] = useState(decodeURI(email ?? ''));
  const [invitationSent, setInvitationSent] = useState(false);

  const organizationName = useSelector(getCurrentOrganization)?.name ?? '';
  const currentUser = useSelector(getCurrentUser);
  const isAccountConfirmed = currentUser?.unconfirmedEmail !== currentUser?.email;

  const usersById = useSelector(getUsersById);
  const existingUser = Object.values(usersById).find(
    (user) => ignoreCaseAndDiacriticalMarksCollator.compare(user.email, newUserEmail) === 0,
  );
  const existingUserEmail = existingUser?.email ?? '';
  const existingUserId = existingUser?.id ?? 0;
  const workspaceInformation = useSelector((state) =>
    existingUserId ? getWorkspaceInformationForInvitationUser(state, existingUserId) : undefined,
  );
  const isNewInvitation = newUserEmail === '';
  const isWsMember = !!workspaceInformation?.wsUserCount;
  const isNewOrgMember = !isNewInvitation && !isWsMember;

  const flags = useSelector(getCurrentOrganization)?.flags;

  const [showBeyondLimitModal, setShowBeyondLimitModal] = useState(false);
  const hideBeyondLimitModal = () => setShowBeyondLimitModal(false);

  useEffect(() => {
    setShowBeyondLimitModal(
      isNewOrgMember && !!flags && flags.licensesUsed + flags.licensesPending >= flags.totalLicenses,
    );
  }, [flags, isNewOrgMember]);

  useOrganizationFetchOnOrganizationModified();

  const headerSubtext = useMemo(() => {
    if (isNewInvitation) {
      return (
        <em>
          If this person is already a <strong>{organizationName}</strong> user, they will get access immediately. If
          not, they will receive a new user invitation and a license will be activated.
        </em>
      );
    }

    if (isWsMember) {
      return (
        <em>
          Please select the workspace(s) you&apos;d like to add <b>{existingUserEmail}</b>.
        </em>
      );
    }

    return (
      <em>
        <b>{newUserEmail}</b> will be invited into <b>{organizationName}</b>. Please select the workspace(s) you&apos;d
        like them to join.
      </em>
    );
  }, [existingUserEmail, isNewInvitation, isWsMember, newUserEmail, organizationName]);

  return (
    <div className="users-invite">
      {!isAccountConfirmed && <UnconfirmedEmailBanner />}
      {showBeyondLimitModal && <BeyondLimitModal onClose={hideBeyondLimitModal} />}

      <Segment
        className={classNames('users-invite__invitation-form', {
          'users-invite__invitation-form--overflow-hidden': newUserEmail,
        })}
      >
        {invitationSent && (
          <Message
            content={'Invitation sent! Do you want to send another?'}
            header="Your user invitation has been sent"
            icon="inbox"
            success
            className="confirm-invitation__sent"
          />
        )}

        <InvitationHeader
          headerText={`Invite a User to a Workspace(s):`}
          headerSubtext={headerSubtext}
          icon={userSolid}
          isOrgDirPage={true}
        />
        <InviteUserForm
          existingUserEmail={existingUserEmail}
          isAccountConfirmed={isAccountConfirmed}
          newUserEmail={newUserEmail}
          setInvitationSent={setInvitationSent}
          setNewUserEmail={setNewUserEmail}
          workspaceInformation={workspaceInformation}
          selectedWorkspaces={selectedWorkspaces}
        />
      </Segment>
      <PlanGadgetSidebar includeContactSupport={true} />
    </div>
  );
};

export default UsersInvite;
