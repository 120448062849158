import { SyntheticEvent, useCallback } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { TargetFinishType } from 'daos/item_enums';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import './target_finish_type_dropdown.scss';

interface TargetFinishTypeDropdownProps {
  disabled: boolean;
  onChange: (value: TargetFinishType) => void;
  onClose?: () => void;
  targetFinishType: TargetFinishType;
  isOpen?: boolean;
  useOnGrid?: boolean;
}

export const targetFinishTypeOptions = [
  {
    text: 'Keep Scheduling',
    search: 'keep scheduling',
    value: TargetFinishType.KeepScheduling,
  },
  {
    text: 'Stop Scheduling',
    search: 'stop scheduling',
    value: TargetFinishType.StopScheduling,
  },
];

const TargetFinishTypeDropdown = ({
  disabled,
  onChange,
  onClose,
  targetFinishType,
  isOpen,
  useOnGrid = false,
}: TargetFinishTypeDropdownProps) => {
  const handleChange = useCallback(
    (_: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      if (targetFinishType !== value) {
        onChange(value as TargetFinishType);
      }
    },
    [onChange, targetFinishType],
  );

  const targetFinishTypeDropdown = (
    <Dropdown
      closeOnChange
      onClose={onClose}
      className="lp-schedule-target-finish-dropdown"
      disabled={disabled}
      onChange={handleChange}
      options={targetFinishTypeOptions}
      selection
      value={targetFinishType}
      open={isOpen}
    />
  );

  return useOnGrid ? <PortalDropdown>{targetFinishTypeDropdown}</PortalDropdown> : targetFinishTypeDropdown;
};

export default TargetFinishTypeDropdown;
