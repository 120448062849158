import { useSelector } from 'react-redux';
import { Button, Image } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { LpColorHex, RelativePriorityType } from 'daos/item_enums';
import { backend } from 'daos/urls';
import { LpButtonAsLink } from 'features/common/as_components';
import { PortableDashboardLinkButton } from 'features/common/buttons/portable_links/portable_dashboard_link_button';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  barsSolid,
  checkCircleRegular,
  compassDraftingRegular,
  gripDotsVerticalSolid,
  LpIcon,
  plusCircleRegular,
} from 'features/common/lp_icon';
import { DashboardType } from 'features/dashboards/dashboard_types';
import { getDashboardForId } from 'features/dashboards/selectors';
import { AddWidgetGroupButton } from 'features/dashboards_v2/common/add_group_button';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { getDashboardIcon } from 'features/dashboards_v2/helpers';
import { useHasAccess } from 'hooks/use_has_access';
import { getItemForId } from 'state/entities/selectors/item';

import { useDashboardRoutes } from './routes/use_dashboard_routes';

interface DashboardV2HeaderProps {
  dashboardId: string;
  itemId: number;
}

interface DashboardHeaderRendererProps {
  actions: JSX.Element;
  color: string;
  dashboardType: DashboardType;
  headerText?: string | null;
  imageUrl?: string;
  name: string;
}

export const DashboardHeaderRenderer = ({
  actions,
  color,
  dashboardType,
  headerText,
  imageUrl,
  name,
}: DashboardHeaderRendererProps) => {
  return (
    <>
      <div className="v2-dashboards-view__header">
        <div className="v2-dashboards-view__header-info">
          <span className="v2-dashboards-view__header-icon" style={{ background: `#${color}` }}>
            <LpIcon icon={getDashboardIcon(dashboardType)} />
          </span>
          <h3 className="v2-dashboards-view__header-title">{name}</h3>
        </div>
        <div className="v2-dashboards-view__header-actions">{actions}</div>
      </div>

      {(headerText || imageUrl) && (
        <div className="v2-dashboards-view__sub-header">
          <div className="v2-dashboards-view__sub-header-container">
            {imageUrl && <Image centered className="v2-dashboards-view__sub-header-image" src={imageUrl} />}
            {headerText && <i className="v2-dashboards-view__sub-header-text">{headerText}</i>}
          </div>
        </div>
      )}
    </>
  );
};

export function DashboardV2Header({ dashboardId, itemId }: DashboardV2HeaderProps) {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const item = useSelector((state) => getItemForId(state, itemId));
  const dashboard = useSelector((state) => getDashboardForId(state, dashboardId));

  const canEditDashboard = useHasAccess(Permission.MANAGE, ItemDao.id(Number(dashboard?.itemId ?? 0)));

  const { isLoading, inDesign, setShowAddWidgetModal, packageStatus, libraryLocation, libraryResource } =
    useDashboardContext();

  const openAddWidgetModal = () => setShowAddWidgetModal(true);

  const { dashboardRoute, designLayoutRoute } = useDashboardRoutes({
    dashboardId,
    libraryLocation,
    packageStatus,
    itemId,
  });

  if (!dashboard || !item || !libraryResource) {
    return null;
  }

  const dashboardColor = dashboard.color ?? item.color ?? LpColorHex.EARL_GRAY;
  const dashboardTitle = dashboard.name ?? item.name ?? '';
  const dashboardHeaderText = dashboard.headerText;
  const dashboardHeaderImageS3id = dashboard.headerImageS3Id;

  const imageUrl = dashboardHeaderImageS3id
    ? backend.dashboardImage.url({ organizationId, workspaceId, s3Id: dashboardHeaderImageS3id })
    : undefined;

  const dashboardDesignHeader = (
    <div className="v2-dashboards-view__header v2-dashboards-view__header--in-design">
      <div className="v2-dashboards-view__header-text">
        <LpIcon className="v2-dashboards-view__header-text-icon" icon={compassDraftingRegular} />
        <span>
          <LpIcon icon={gripDotsVerticalSolid} />
          Use handles to rearrange
        </span>
        <span>
          <LpIcon icon={barsSolid} />
          Use menus to customize
        </span>
      </div>
      <div className="v2-dashboards-view__header-actions">
        <Button primary onClick={openAddWidgetModal}>
          <LpIcon icon={plusCircleRegular} /> Widget
        </Button>

        <AddWidgetGroupButton dashboardId={dashboardId} relativePriorityType={RelativePriorityType.BEFORE} />

        <LpButtonAsLink to={dashboardRoute} color="orange" disabled={!canEditDashboard || isLoading}>
          <LpIcon icon={checkCircleRegular} /> Done
        </LpButtonAsLink>
      </div>
    </div>
  );

  const dashboardBaseHeader = (
    <DashboardHeaderRenderer
      actions={
        <>
          <PortableDashboardLinkButton
            dashboardName={dashboard.defaultName}
            dashboardId={dashboardId}
            workspaceId={Number(workspaceId)}
            organizationId={Number(organizationId)}
          />
          <LpButtonAsLink primary to={designLayoutRoute} disabled={!canEditDashboard || isLoading}>
            <LpIcon icon={compassDraftingRegular} /> Design
          </LpButtonAsLink>
        </>
      }
      color={dashboardColor}
      dashboardType={dashboard.dashboardType}
      headerText={dashboardHeaderText}
      imageUrl={imageUrl}
      name={dashboardTitle}
    />
  );

  return inDesign ? dashboardDesignHeader : dashboardBaseHeader;
}
