import { useSelector } from 'react-redux';

import { PropertyChange } from 'daos/types';
import { FileIconAndName } from 'features/item_panel/sections/files_section/file_icon_and_name';
import { getItemFileForId } from 'state/entities/selectors/file';

export const ChangeCardFileProperty = ({
  propertyChangeData,
  itemId,
  itemFileId,
}: {
  propertyChangeData: PropertyChange;
  itemId: number;
  itemFileId: number;
}) => {
  const fileId = propertyChangeData.value;
  const itemFile = useSelector((state) => getItemFileForId(state, itemFileId));

  if (!fileId) {
    return null;
  }

  return <FileIconAndName fileId={fileId} itemId={itemId} isLinkable={!!itemFile} />;
};
