import { request } from 'lib/api';

import { OrganizationUserSlackIntegration } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { resource, deleteBody, deleteArrayBody, updateBody } = defineModel({
  apiType: 'organizationUserSlackIntegrations',
  type: 'ORGANIZATION_USER_SLACK_INTEGRATION',
});

interface OrganizationUserSlackIntegrationUrlParams {
  organizationId: number;
  organizationUserId: number;
}

interface SingleOrganizationUserSlackIntegrationParams extends OrganizationUserSlackIntegrationUrlParams {
  id: number;
}

const { ORGANIZATION_USER_SLACK_INTEGRATION, resourceId } = resource;

const destroy = (params: SingleOrganizationUserSlackIntegrationParams) =>
  request(
    backend.organizationUserSlackIntegration.url(params),
    ORGANIZATION_USER_SLACK_INTEGRATION,
    deleteBody(params.id),
  );

const destroyBulk = (params: OrganizationUserSlackIntegrationUrlParams, ids: Array<number>) =>
  request(
    backend.organizationUserSlackIntegrationBulk.url(params),
    ORGANIZATION_USER_SLACK_INTEGRATION,
    deleteArrayBody(ids),
  );

const update = (
  params: SingleOrganizationUserSlackIntegrationParams,
  orgUserSlackIntegration: Partial<OrganizationUserSlackIntegration>,
) =>
  request(
    backend.organizationUserSlackIntegration.url(params),
    ORGANIZATION_USER_SLACK_INTEGRATION,
    updateBody(params.id, orgUserSlackIntegration),
  );

export const OrganizationUserSlackIntegrationDao = {
  destroy,
  destroyBulk,
  update,
  id: resourceId,
  resource,
} as const;
