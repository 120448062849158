import { createPopper } from '@popperjs/core';
import { Modifier } from '@popperjs/core/lib/types';
import maxSize from 'popper-max-size-modifier';
import { RefObject } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

import { applyMaxSize, includesTopOrEnd } from 'features/common/inputs/dropdowns/portal_dropdown/helpers';

export const lpDropdownSearch = (options: Array<DropdownItemProps>, query: string) => {
  const searchTextLowercase = query.toLowerCase();
  return options.filter((opt) => {
    // Optional chain on search is intentional. Semantic UI will not allow any other type than Array<DropdownItemProps>
    // which has no `search` property. This protects searching when options are provided without this prop.
    return opt.search?.toLowerCase().includes(searchTextLowercase);
  });
};

const dropdownMaxHeight = 30 * 14;
const dropdownHeightOffset = 64;

export function getPopperFlipMutationObserver() {
  return new MutationObserver((mutations) => {
    mutations
      .filter((mutation) => mutation.type === 'attributes' && mutation.attributeName === 'data-popper-placement')
      .forEach((mutation) => {
        const target = mutation.target as HTMLDivElement;
        if (includesTopOrEnd.test(target.dataset.popperPlacement ?? '')) {
          target.classList.add('upward');
        } else {
          target.classList.remove('upward');
        }
      });
  });
}

export const maxHeightDropdownEffect = (
  dropdownRef: RefObject<HTMLElement>,
  triggerRef: RefObject<HTMLSpanElement>,
  modifiers: Array<Partial<Modifier<string, any>>> = [],
) => {
  const observer = getPopperFlipMutationObserver();

  if (triggerRef.current && dropdownRef.current) {
    observer.observe(dropdownRef.current, { attributeFilter: ['data-popper-placement'] });

    const portalDropdown = dropdownRef.current;

    createPopper(triggerRef.current, dropdownRef.current, {
      modifiers: [maxSize, applyMaxSize('.scrolling', dropdownMaxHeight, dropdownHeightOffset), ...modifiers],
    });

    portalDropdown.style.opacity = '1';
  }

  return () => {
    observer.disconnect();
  };
};
