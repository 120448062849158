import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { UserDao } from 'daos/user';
import { LpLogo } from 'features/shared/components/lp_logo';
import { frontend } from 'lib/urls';

export const MissingAccountTopBar = () => {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    dispatch(UserDao.logout());
    window.location.assign(frontend.login.url({}));
  };

  return (
    <div className="missing-account__top-bar">
      <div className="missing-account__logo-container">
        <LpLogo />
      </div>
      <Button onClick={handleLogout} className="missing-account__logout" color="blue">
        Logout
      </Button>
    </div>
  );
};
