import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Permission } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { filterAnd, filterIds } from 'daos/shared';
import { clearBulkData, setBulkSelectedItemIds } from 'features/common/bulk_selection/slice';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { SelectionDropdownOptions } from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { useHasAccessToMultipleResources } from 'hooks/use_has_access';

import { getBulkSelectedItemIds, getBulkSelectedItemParentResources } from './selectors';

interface GetBulkSelectOrUnselectAll {
  clearBulkIds: () => void;
  setBulkIds: (bulkIds: ReadonlyArray<number>) => void;
}

export const getBulkSelectOrUnselectAll =
  ({ clearBulkIds, setBulkIds }: GetBulkSelectOrUnselectAll) =>
  (selectItemIds: ReadonlyArray<number>) =>
  (selectionOption: SelectionDropdownOptions) => {
    if (selectionOption === SelectionDropdownOptions.UnselectAll) {
      clearBulkIds();
    } else {
      setBulkIds(selectItemIds);
    }
  };

export const useBulkSelectUnselectAll = () => {
  const dispatch = useDispatch();
  const clearBulkIds = () => {
    dispatch(clearBulkData());
  };
  const setBulkIds = (bulkIds: ReadonlyArray<number>) => {
    dispatch(setBulkSelectedItemIds(bulkIds));
  };

  return getBulkSelectOrUnselectAll({ clearBulkIds, setBulkIds });
};

export const useHasEditAccessOnBulkItems = () => {
  const selectedItemIds = useSelector(getBulkSelectedItemIds);
  const resources = selectedItemIds.map((itemId) => ItemDao.id(itemId));
  return useHasAccessToMultipleResources(Permission.EDIT, resources, 'items');
};

export const useHasManageAccessOnBulkItems = () => {
  const selectedItemIds = useSelector(getBulkSelectedItemIds);
  const resources = selectedItemIds.map((itemId) => ItemDao.id(itemId));
  return useHasAccessToMultipleResources(Permission.MANAGE, resources, 'items');
};

export const useHasManageAccessOnBulkItemParents = () => {
  const parentResources = useSelector(getBulkSelectedItemParentResources);
  return useHasAccessToMultipleResources(Permission.MANAGE, parentResources, 'items');
};

export const useSafeDataBulkSelectedItemFetch = (isSafeDataGrid?: boolean) => {
  const dispatch = useDispatch();
  const selectedItemIds = useSelector(getBulkSelectedItemIds);
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const shouldFetchItems = isSafeDataGrid && selectedItemIds.length;

  const fetchSelectedItems = useCallback(() => {
    if (selectedItemIds.length) {
      dispatch(
        ItemDao.fetchAll(
          { organizationId, workspaceId },
          {
            filter: filterAnd(filterIds([...selectedItemIds])),
            include: { includeParent: true, includeFieldValues: true },
          },
        ),
      );
    }
  }, [dispatch, organizationId, selectedItemIds, workspaceId]);

  useEffect(() => {
    if (shouldFetchItems) {
      fetchSelectedItems();
    }
  }, [fetchSelectedItems, shouldFetchItems]);
};
