import classNames from 'classnames';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import LpFormError from 'containers/shared/form_errors/lp_form_error';
import LpLink from 'containers/shared/lp_link';
import { OrganizationSsoSettingsDao, OrganizationSsoSettings } from 'daos/organization_sso_settings';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import FeatureTrialBannerWithIcon from 'features/common/feature_trial/feature_trial_banner_with_icon';
import LpFormInput from 'features/common/forms/lp_form_input';
import LpFormTextArea from 'features/common/forms/lp_form_textarea';
import { checkCircleSolid, keySolid, LpIcon, questionCircleSolid } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { CopyTextButton } from 'features/common/portable_link_handler/copy_text_button';
import { awaitRequestFinish } from 'lib/api';
import NewWindowLink from 'lib/display_helpers/url_links';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';
import { getCurrentOrganization } from 'state/entities/selectors/organization';

import { SetupHelpText } from './enums';

import './setup.scss';

const schema = yup.object().shape({
  identityProviderUrl: yup.string().trim().required(lpErrorText.issuerURL),
  certContent: yup.string().trim().required(lpErrorText.certContent),
  singleSignOnUrl: yup.string().trim().required(lpErrorText.singleSignOnUrl),
  logoutLandingUrl: yup.string().trim(),
});

const SingleSignOnFormLabel = ({
  labelText,
  subText,
  popupHelpContent,
  includeLabelModifier = false,
}: {
  labelText: string | JSX.Element;
  subText?: string | JSX.Element;
  popupHelpContent?: SetupHelpText | JSX.Element;
  includeLabelModifier?: boolean;
}) => {
  return (
    <>
      <div
        className={classNames('single-sign-on-form__label', {
          'single-sign-on-form__label--larger-text': includeLabelModifier,
        })}
      >
        {labelText}
        {popupHelpContent && (
          <LpPopUp
            className="single-sign-on-form__label-popup"
            placement="bottom"
            trigger={
              <div>
                <LpIcon className="single-sign-on-form__help-icon" icon={questionCircleSolid} />
              </div>
            }
            content={popupHelpContent}
          />
        )}
      </div>
      {subText && <p>{subText}</p>}
    </>
  );
};

const SuccessIcon = ({ showIcon }: { showIcon: boolean }) => (
  <AnimatePresence>
    {showIcon && (
      <motion.div
        initial={{ opacity: 0, y: 14 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: 'spring', delay: 0.2 }}
      >
        <LpIcon className="single-sign-on-form__success-icon" icon={checkCircleSolid} />
      </motion.div>
    )}
  </AnimatePresence>
);

export const SetupSso = ({
  organizationSsoSettings,
  fetchSSO,
}: {
  organizationSsoSettings: OrganizationSsoSettings;
  fetchSSO: () => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organization = useSelector(getCurrentOrganization);
  const organizationId = useSelector(getCurrentOrganizationId);

  const hasSSO = organization?.flags.hasSso;
  const isSsoVerified = !!organizationSsoSettings.verifiedDate;

  const {
    handleSubmit,
    getFieldMeta,
    getFieldProps,
    setStatus,
    setSubmitting,
    status,
    isSubmitting,
    dirty: formIsDirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      identityProviderUrl: organizationSsoSettings.identityProviderUrl ?? '',
      certContent: organizationSsoSettings.certContent ?? '',
      singleSignOnUrl: organizationSsoSettings.singleSignOnUrl ?? '',
      logoutUrl: organizationSsoSettings.logoutUrl ?? '',
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: ({ identityProviderUrl, singleSignOnUrl, certContent, logoutUrl }) => {
      if (organizationSsoSettings?.id) {
        setStatus(undefined);

        const { uuid } = dispatch(
          OrganizationSsoSettingsDao.update(
            { organizationId, organizationSsoSettingsId: organizationSsoSettings.id },
            {
              identityProviderUrl: identityProviderUrl.trim(),
              singleSignOnUrl: singleSignOnUrl.trim(),
              certContent: certContent.trim(),
              logoutUrl: logoutUrl.trim(),
            },
          ),
        );

        dispatch(
          awaitRequestFinish(uuid, {
            onSuccess: () => {
              fetchSSO();
            },

            onError: ({ errors }) => {
              const error = errors[0];
              if (error) {
                setStatus(`${error.title}: ${error.detail}`);
              } else {
                setStatus(lpErrorText.somethingWentWrong);
              }
              setSubmitting(false);
            },
          }),
        );
      }
    },
  });

  const testingUrl = frontend.singleSignOnTestUrl.url(
    {
      entityId: organizationSsoSettings.entityId,
    },
    { query: { RelayState: frontend.singleSignOnSetup.url({ organizationId }) } },
  );

  const handleNextStep = () => {
    history.push(
      frontend.singleSignOnManage.url({
        organizationId,
      }),
    );
  };

  const isFormComplete = !formIsDirty && !!organizationSsoSettings.identityProviderUrl;
  const isTestLogInDisabled = isSsoVerified || formIsDirty;
  const isNextStepButtonDisabled = !isSsoVerified || formIsDirty;

  return (
    <Form className="single-sign-on-form" onSubmit={handleSubmit} loading={isSubmitting}>
      {!!status && <LpFormError className="single-sign-on-form__error-status" error={status} />}
      {!hasSSO && (
        <FeatureTrialBannerWithIcon
          icon={keySolid}
          title={'Single Sign-On (SSO) is a Premium Feature'}
          description={
            <>
              <ProductName /> provides a centrally managed Single Sign-On (SSO) configuration that integrates
              <ProductName /> with your corporate SSO solution.
            </>
          }
        />
      )}
      <Form.Field disabled={!hasSSO} className="single-sign-on-form__step">
        <SingleSignOnFormLabel
          labelText={
            <>
              Step 1: Configure SSO <SuccessIcon showIcon={isFormComplete} />
            </>
          }
          subText={
            <>
              Provide the below attributes from your SSO identity provider. All of your{' '}
              <LpLink
                to={frontend.organizationHub.url({
                  organizationId,
                })}
              >
                workspaces
              </LpLink>{' '}
              use the same SSO configuration.
            </>
          }
          includeLabelModifier={true}
        />

        <Form.Field className="single-sign-on-form__issuer-url">
          <SingleSignOnFormLabel
            labelText={'Issuer URL'}
            popupHelpContent={
              <div>
                <h5>Your system may refer to this as:</h5>
                <li>Identity Provider Issuer</li>
                <li>Issuer URL</li>
                <li>IdP Entity ID URL / Issuer </li>
              </div>
            }
          />
          <LpFormInput
            fieldKey="identityProviderUrl"
            getFieldMeta={getFieldMeta}
            getFieldProps={getFieldProps}
            placeholder="Enter the Issuer URL that uniquely identifies your SSO identity provider."
          />
        </Form.Field>

        <Form.Field className="single-sign-on-form__sign-on-url">
          <SingleSignOnFormLabel
            labelText={'Sign-on URL'}
            popupHelpContent={
              <div>
                <h5>Your system may refer to this as:</h5>
                <li>Authentication URL</li>
                <li>Identity Provider (IdP) HTTP POST URL</li>
                <li>Identity Provider Single Sign-On URL</li>
                <li>SAML HTTP Endpoint</li>
                <li>SAML Request Endpoint</li>
                <li>SAML 2.0 Endpoint (HTTP)</li>
                <li>SP-initiated Endpoint</li>
                <li>POST Request Endpoint</li>
              </div>
            }
          />
          <LpFormInput
            fieldKey="singleSignOnUrl"
            getFieldMeta={getFieldMeta}
            getFieldProps={getFieldProps}
            placeholder="Enter the Remote Login URL from your SSO identity provider that will authenticate your users."
          />
        </Form.Field>

        {/*
          The Logout url field is commented out till SSO: SAML Logout - add logout functionality to user logout (v2)
          is complete, we will bring this back in.

          URL: https://relight-internal.liquidplannerlab.com/organization/19/workspace/19/my_work/workload#panelId=401991

        */}

        {/* <Form.Field className="single-sign-on-form__logout-landing-url">
          <SingleSignOnFormLabel
            labelText={'Logout Landing URL (Optional)'}
            popupHelpContent={SetupHelpText.LogoutUrl}
          />
          <LpFormInput
            fieldKey="logoutUrl"
            getFieldMeta={getFieldMeta}
            getFieldProps={getFieldProps}
            placeholder="Enter the redirect URL for logout if provided by your SSO identity provider. "
          />
        </Form.Field> */}

        <Form.Field className="single-sign-on-form__certificate">
          <SingleSignOnFormLabel
            labelText={'SSO Identity Provider Certificate'}
            popupHelpContent={SetupHelpText.IdentityProviderCertificate}
          />
          <div>
            <p>Upload Certificate File:</p>
            <LpFormTextArea
              className="single-sign-on-form__certificate-text-area"
              placeholder="Copy and paste the contents of the certificate provided by your SSO identity provider."
              autoFocus={false}
              rows={3}
              fieldKey="certContent"
              getFieldMeta={getFieldMeta}
              getFieldProps={getFieldProps}
            />
          </div>
        </Form.Field>

        <Form.Field className="single-sign-on-form__service-provider-details">
          <SingleSignOnFormLabel
            labelText={'Service Provider Details'}
            subText={'Use the below information to plug into your SSO identity provider configuration.'}
            popupHelpContent={SetupHelpText.ServiceProviderDetails}
          />
          <div className="single-sign-on-form__two-column-grid">
            <div className="single-sign-on-form__two-column-grid-column-one">
              <div>Metadata URL</div>
              <div>SAML Version</div>
              <div>Entity ID</div>
              <div>Assertion Consumer URL</div>
            </div>
            <div className="single-sign-on-form__two-column-grid-column-two">
              <div className="single-sign-on-form__two-column-grid-column-two-link">
                <NewWindowLink
                  className="single-sign-on-form__two-column-grid-column-two--ellipsis"
                  to={organizationSsoSettings.metadataUrl}
                >
                  {organizationSsoSettings.metadataUrl}
                </NewWindowLink>
                <CopyTextButton textToCopy={organizationSsoSettings.metadataUrl} />
              </div>
              <div>2.0</div>
              <div>{organizationSsoSettings.entityId}</div>
              <div className="single-sign-on-form__two-column-grid-column-two-link">
                <NewWindowLink
                  className="single-sign-on-form__two-column-grid-column-two--ellipsis"
                  to={organizationSsoSettings.consumerUrl}
                >
                  {organizationSsoSettings.consumerUrl}
                </NewWindowLink>
                <CopyTextButton textToCopy={organizationSsoSettings.consumerUrl} />
              </div>
            </div>
          </div>
        </Form.Field>

        <Button className="single-sign-on-form__button" primary type="submit" content="Save" />
      </Form.Field>

      <Form.Field disabled={!hasSSO} className="single-sign-on-form__step">
        <SingleSignOnFormLabel
          labelText={
            <>
              Step 2: Test SSO Configuration <SuccessIcon showIcon={isSsoVerified} />
            </>
          }
          includeLabelModifier={true}
        />
        <Form.Field className="single-sign-on-form__test-url">
          {isSsoVerified ? (
            <p>Your Test was successful. You can enable SSO for your organization.</p>
          ) : (
            <p>
              Click the <strong>Test Login</strong> button to test your SSO configuration settings.
            </p>
          )}
        </Form.Field>

        <NewWindowLink
          className={classNames({ 'single-sign-on-form__test-link--disabled': isTestLogInDisabled })}
          to={testingUrl}
        >
          <Button primary disabled={isTestLogInDisabled} type="button" content="Test Login" />
        </NewWindowLink>
      </Form.Field>

      <Form.Field disabled={!hasSSO} className="single-sign-on-form__step">
        <SingleSignOnFormLabel
          labelText={'Step 3: Enable and Manage SSO'}
          includeLabelModifier={true}
          subText={
            <>
              Click the <strong>Final Step</strong> button to open the Manage SSO view where you can enable SSO for this
              Organization and manage other SSO user settings.
            </>
          }
        />

        <Button
          className={'single-sign-on-form__next-step-button'}
          primary
          disabled={isNextStepButtonDisabled}
          onClick={handleNextStep}
          type="button"
          content="Final Step"
        />
      </Form.Field>

      <Prompt when={formIsDirty} message="Are you sure you want to leave this page? You have unsaved changes." />
    </Form>
  );
};
