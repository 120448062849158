import { RootState } from 'state/root_reducer';

export const getOrganizationHubFilteredOrgUsers = ({ organizationHub }: RootState) =>
  organizationHub.orgUsers.filteredOrgUsers;

export const getOrganizationHubFilteredAdminOrgUsers = (state: RootState) =>
  getOrganizationHubFilteredOrgUsers(state).filter((orgUser) => orgUser.admin);

export const getOrganizationHubHasError = ({ organizationHub }: RootState) => organizationHub.hasError;

export const getOrganizationHubUsersFetchComplete = ({ organizationHub }: RootState) =>
  organizationHub.orgUsers.fetchComplete;

export const getOrgHubUserUpdateError = ({ organizationHub }: RootState) => organizationHub.userUpdateError;
