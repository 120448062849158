import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { Permission, UserType } from 'daos/enums';
import { UserDao } from 'daos/user';
import LpInput from 'features/common/inputs/lp_input';
import { clearLocalValidationErrorForKey, setLocalValidationError } from 'features/common/local_validation/slice';
import { useHasAccess } from 'hooks/use_has_access';
import { shouldNameFieldBeDisabled } from 'lib/display_helpers/should_name_field_be_disabled';
import { getUserForId, getWorkspaceUserById } from 'state/entities/selectors/user';

function isNameRequired(userType: UserType | undefined) {
  if (userType) {
    switch (userType) {
      case UserType.Member:
      case UserType.Placeholder:
        return true;
      case UserType.Resource:
        return false;
    }
  } else {
    return true;
  }
}

interface FirstLastNameRowsProps {
  userId: number;
  wsUserId: number;
  updateUser: ({ firstName, lastName }: { firstName?: string; lastName?: string }) => void;
}

const FirstLastNameRows = ({ userId, wsUserId, updateUser }: FirstLastNameRowsProps) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => getUserForId(state, userId));
  const wsUser = useSelector((state) => getWorkspaceUserById(state, wsUserId));
  const hasEditAccess = useHasAccess(Permission.EDIT, UserDao.id(userId));

  const handleFirstNameChange = (firstName: string) => {
    if (isNameRequired(user?.userType) && !firstName.trim()) {
      dispatch(setLocalValidationError({ key: 'firstName', message: 'First name is required' }));
      return;
    }

    dispatch(clearLocalValidationErrorForKey('firstName'));
    updateUser({ firstName });
  };

  const handleLastNameChange = (lastName: string) => {
    if (isNameRequired(user?.userType) && !lastName.trim()) {
      dispatch(setLocalValidationError({ key: 'lastName', message: 'Last name is required' }));
      return;
    }

    dispatch(clearLocalValidationErrorForKey('lastName'));
    updateUser({ lastName });
  };

  if (!user || !wsUser) {
    return null;
  }

  return (
    <>
      <Table.Row>
        <Table.Cell>First Name</Table.Cell>
        <Table.Cell className="user-profile__table-cell-input">
          <LpInput
            className="user-profile__table-input"
            transparent={true}
            disabled={!hasEditAccess || shouldNameFieldBeDisabled(!!wsUser.disconnectedAt, user.userType)}
            value={user.firstName}
            onChange={handleFirstNameChange}
            placeholder={user.userType === UserType.Resource ? 'Optional' : undefined}
          />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Last Name</Table.Cell>
        <Table.Cell className="user-profile__table-cell-input">
          <LpInput
            className="user-profile__table-input"
            transparent={true}
            disabled={!hasEditAccess || shouldNameFieldBeDisabled(!!wsUser.disconnectedAt, user.userType)}
            value={user.lastName}
            onChange={handleLastNameChange}
            placeholder={user.userType === UserType.Resource ? 'Optional' : undefined}
          />
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default FirstLastNameRows;
