import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { motion } from 'framer-motion';
import { SyntheticEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Input, InputOnChangeData } from 'semantic-ui-react';

import { WidgetGroupSize } from 'daos/enums';
import { RelativePriorityType } from 'daos/item_enums';
import { WidgetGroupDao } from 'daos/widget_groups';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import PortalActionDropdown from 'features/common/inputs/dropdowns/portal_action_dropdown';
import {
  barsSolid,
  compressSolid,
  copyLight,
  expandSolid,
  filterLight,
  gripDotsVerticalSolid,
  LpIcon,
  plusCircleRegular,
  trashXmarkSolid,
} from 'features/common/lp_icon';
import { getWidgetGroupForId } from 'features/dashboards/selectors';
import { getTitleOrInheritedTitle, getLocationNameFromScope } from 'features/dashboards_v2/common/titles';
import { useDashboardContext } from 'features/dashboards_v2/context';
import {
  getInheritedLocationScopeForWidgetGroupId,
  getLocationScopeForWidgetGroupId,
} from 'features/dashboards_v2/selectors';
import { useOpenAddWidgetModal } from 'features/dashboards_v2/widget_group/use_open_add_widget_modal';
import { awaitRequestFinish } from 'lib/api';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

interface WidgetGroupToolbarProps {
  attributes?: DraggableAttributes;
  dashboardId: string;
  draggableRef?: (el: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
  widgetGroupId: string;
}

export function WidgetGroupToolbar({
  attributes,
  dashboardId,
  draggableRef,
  listeners,
  widgetGroupId,
}: WidgetGroupToolbarProps) {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const widgetGroup = useSelector((state) => getWidgetGroupForId(state, widgetGroupId));
  const widgetGroupTitle = widgetGroup?.title ?? '';
  const [title, setTitle] = useState(widgetGroupTitle);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuButtonRef = useRef(null);

  const { setFilterModalWidgetGroupId, setShowDeleteModal, setDeleteModalProps } = useDashboardContext();
  const openAddWidgetModal = useOpenAddWidgetModal(widgetGroupId, RelativePriorityType.BEFORE);
  const inheritedLocationScope = useSelector((state) =>
    getInheritedLocationScopeForWidgetGroupId(state, widgetGroupId),
  );
  const locationScope = useSelector((state) => getLocationScopeForWidgetGroupId(state, widgetGroupId));
  const currentWorkspace = useSelector(getCurrentWorkspace);

  if (!widgetGroup) {
    return null;
  }

  function onDropdownOpen() {
    setMenuOpen(true);
  }

  function onDropdownClose() {
    setMenuOpen(false);
  }

  function duplicateWidgetGroup() {
    const { uuid } = dispatch(
      WidgetGroupDao.duplicate(
        {
          organizationId,
          workspaceId,
          dashboardId,
          widgetGroupId,
        },
        {
          include: {
            includeWidgetGroupLocationFilterItem: true,
            includeWidgets: true,
          },
        },
      ),
    );

    dispatch(awaitRequestFinish(uuid, {}));
  }

  function deleteGroup(cleanUpAfterDelete: () => void) {
    const { uuid } = dispatch(
      WidgetGroupDao.destroy({
        organizationId,
        workspaceId,
        dashboardId,
        widgetGroupId,
      }),
    );

    dispatch(awaitRequestFinish(uuid, { onFinish: () => cleanUpAfterDelete() }));
  }

  function updateWidgetGroup(widgetGroupSize?: WidgetGroupSize) {
    const { uuid } = dispatch(
      WidgetGroupDao.update(
        {
          organizationId,
          workspaceId,
          dashboardId,
          widgetGroupId,
        },
        {
          title,
          widgetGroupSize,
        },
      ),
    );

    dispatch(awaitRequestFinish(uuid, {}));
  }

  const toggleWidgetGroupSize = () => {
    const widgetGroupSize =
      widgetGroup.widgetGroupSize === WidgetGroupSize.Half ? WidgetGroupSize.Full : WidgetGroupSize.Half;
    updateWidgetGroup(widgetGroupSize);
  };

  const handleTitleChange = (_: SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => setTitle(data.value);

  const handleTitleBlur = () => {
    if (widgetGroupTitle !== title) {
      updateWidgetGroup();
    }
  };

  const openFilterModal = () => setFilterModalWidgetGroupId(widgetGroupId);

  const inheritedLocationName = getLocationNameFromScope({
    locationItemFilterName: inheritedLocationScope.locationFilterItem?.name ?? '',
    locationItemFilterType: inheritedLocationScope.locationFilterItem?.itemType ?? null,
    locationItemFilterPackageStatus: inheritedLocationScope.locationFilterPackageStatus,
    currentWorkspaceName: currentWorkspace?.name ?? '',
  });

  const locationName = getLocationNameFromScope({
    locationItemFilterName: locationScope.locationFilterItem?.name ?? '',
    locationItemFilterType: locationScope.locationFilterItem?.itemType ?? null,
    locationItemFilterPackageStatus: locationScope.locationFilterPackageStatus,
    currentWorkspaceName: currentWorkspace?.name ?? '',
  });

  const groupTitlePlaceholder = getTitleOrInheritedTitle({
    inheritedLocationName,
    locationName,
    defaultTitle: 'Widget Group',
  });

  function confirmDeleteWidgetGroup() {
    setShowDeleteModal(true);
    setDeleteModalProps({
      deleteContent: (
        <>
          You are about to delete <strong>{widgetGroupTitle ?? groupTitlePlaceholder} Group</strong>
        </>
      ),
      deleteAction: deleteGroup,
    });
  }

  return (
    <motion.div layout className="widget-group-toolbar">
      <button className="widget-group-toolbar-drag-handle" ref={draggableRef} {...attributes} {...listeners}>
        <LpIcon icon={gripDotsVerticalSolid} />
      </button>
      <div className="widget-group-toolbar-title">
        <Input
          placeholder={groupTitlePlaceholder}
          value={title}
          onChange={handleTitleChange}
          onBlur={handleTitleBlur}
        />
      </div>
      <button className="widget-group-toolbar-filter" onClick={openFilterModal}>
        <LpIcon icon={filterLight} hoverText="Group Filter" />
      </button>

      <PortalActionDropdown
        trigger={
          <button ref={menuButtonRef} className="widget-group-toolbar-dropdown-trigger">
            <LpIcon icon={barsSolid} hoverText="Options" />
          </button>
        }
        open={menuOpen}
        triggerRef={menuButtonRef}
        onClose={onDropdownClose}
        onOpen={onDropdownOpen}
        className="widget-group-toolbar-dropdown"
      >
        {widgetGroup.widgetGroupSize === WidgetGroupSize.Half ? (
          <Dropdown.Item onClick={toggleWidgetGroupSize}>
            <LpIcon icon={expandSolid} /> Full-size
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={toggleWidgetGroupSize}>
            <LpIcon icon={compressSolid} /> Half-size
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={openAddWidgetModal}>
          <LpIcon icon={plusCircleRegular} /> Widget
        </Dropdown.Item>
        <Dropdown.Item onClick={duplicateWidgetGroup}>
          <LpIcon icon={copyLight} /> Duplicate
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={confirmDeleteWidgetGroup}>
          <LpIcon icon={trashXmarkSolid} /> Delete Group
        </Dropdown.Item>
      </PortalActionDropdown>
    </motion.div>
  );
}
