import { useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CheckboxProps, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import LpFormError from 'containers/shared/form_errors/lp_form_error';
import { Organization } from 'daos/model_types';
import { OrganizationDao } from 'daos/organization';
import { useNameValidation } from 'features/administration/use_name_validation';
import LpFormInput from 'features/common/forms/lp_form_input';
import LpFormLabel, { LpFormLabelStyleModifier } from 'features/common/forms/lp_form_label';
import LpModal from 'features/common/modals/lp_modal';
import {
  defaultOrganizationProjectTypeCheckboxValues,
  filteredOrganizationProjectTypes,
  ProjectTypeCheckboxFields,
} from 'features/shared/components/form_fields/form_fields_helpers';
import { awaitRequestFinish } from 'lib/api';
import { currentBrowserLocale, currentBrowserTimezone } from 'lib/localization';
import { getCurrentOrganizationUser } from 'state/entities/selectors/user';

import './add_organization_form_modal.scss';

const AddOrganizationFormModal = ({
  onClose,
  handleOrgSwitch,
}: {
  onClose: () => void;
  handleOrgSwitch: (e: SyntheticEvent | null, orgId: number) => void;
}) => {
  const dispatch = useDispatch();
  const currentOrgUser = useSelector(getCurrentOrganizationUser);
  const dateFormat = currentOrgUser?.dateFormat;
  const firstDayOfWeek = currentOrgUser?.firstDayOfWeek;
  const numberFormat = currentOrgUser?.numberFormat;
  const timezone = currentOrgUser?.timezone ?? currentBrowserTimezone();
  const timeFormat = currentOrgUser?.timeFormat;

  const orgNameValidation = useNameValidation('Organization');
  const wsNameValidation = useNameValidation('Workspace');

  const {
    handleSubmit,
    setStatus: setFormApiError,
    status: formApiError,
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    values: formValues,
    isSubmitting,
  } = useFormik({
    initialValues: {
      organizationName: '',
      workspaceName: '',
      organizationProjectTypes: defaultOrganizationProjectTypeCheckboxValues(),
    },
    validationSchema: yup.object().shape({
      organizationName: orgNameValidation,
      workspaceName: wsNameValidation,
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ organizationName, workspaceName, organizationProjectTypes }) => {
      const { uuid } = dispatch(
        OrganizationDao.create({
          name: organizationName,
          workspaceName,
          organizationProjectTypes: filteredOrganizationProjectTypes(organizationProjectTypes),
          creator: {
            locale: currentBrowserLocale(),
            dateFormat,
            firstDayOfWeek,
            numberFormat,
            timezone,
            timeFormat,
          },
        }),
      );

      dispatch(
        awaitRequestFinish<Organization>(uuid, {
          onSuccess: ({ data }) => handleOrgSwitch(null, data.id),
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormApiError(`${errors[0].title}: ${errors[0].detail}`);
            }
          },
        }),
      );
    },
  });

  const setWorkspaceNameBasedOnOrgName = () => {
    if (formValues.organizationName.trim() && !formValues.workspaceName.trim()) {
      setFieldValue('workspaceName', formValues.organizationName + ' Workspace');
    }
  };

  const handleCheckboxChange = (_: SyntheticEvent, { checked, name }: CheckboxProps) => {
    if (name) {
      setFieldValue('organizationProjectTypes', { ...formValues.organizationProjectTypes, [name]: !!checked });
    }
  };

  return (
    <LpModal
      className="add-organization-modal"
      onClose={onClose}
      header={'Add Organization'}
      content={
        <Form loading={isSubmitting}>
          {formApiError && <LpFormError error={formApiError} />}

          <Form.Field>
            <LpFormLabel text={'What is the name of your Organization?'} style={LpFormLabelStyleModifier.Bold} />
            <LpFormLabel
              text={"Centrally manage your organization's users and workspaces in your account"}
              style={LpFormLabelStyleModifier.Italic}
            />
            <LpFormInput
              fieldKey="organizationName"
              getFieldProps={getFieldProps}
              getFieldMeta={getFieldMeta}
              customOnBlur={setWorkspaceNameBasedOnOrgName}
            />
          </Form.Field>

          <Form.Field>
            <LpFormLabel text={'Please name your Workspace'} style={LpFormLabelStyleModifier.Bold} />
            <LpFormLabel text={'This is where your projects will live'} style={LpFormLabelStyleModifier.Italic} />
            <LpFormInput fieldKey="workspaceName" getFieldProps={getFieldProps} getFieldMeta={getFieldMeta} />
          </Form.Field>

          {ProjectTypeCheckboxFields({
            checkboxValues: formValues.organizationProjectTypes,
            className: 'add-organization-modal',
            onClick: handleCheckboxChange,
          })}
        </Form>
      }
      actions={
        <>
          <Button content={'Cancel'} onClick={onClose} />
          <Button primary content={'Save'} type="submit" onClick={() => handleSubmit()} />
        </>
      }
    />
  );
};

export default AddOrganizationFormModal;
