import { RootState } from 'state/root_reducer';

import { CustomItemFilterItemType, CustomItemFilterLocation } from './types';

/* Projects Only */
export const getCustomItemFilterDropdownProjectStatusFilterForLocation = (
  state: RootState,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location].projects.dropdownProjectStatusFilter;

export const getCustomItemFilterCustomProjectStatusFilterForLocation = (
  state: RootState,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location].projects.customProjectStatusFilter;

/* Tasks Only */
export const getCustomItemFilterDropdownTaskStatusFilterForLocation = (
  state: RootState,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location].tasks.dropdownTaskStatusFilter;

export const getCustomItemFilterCustomTaskStatusFilterForLocation = (
  state: RootState,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location].tasks.customTaskStatusFilter;

export const getCustomItemFilterTaskStatusIdsForLocation = (state: RootState, location: CustomItemFilterLocation) =>
  state.customItemFilter[location].tasks.taskStatusIds;

export const getCustomItemFilterAsapForLocation = (state: RootState, location: CustomItemFilterLocation) =>
  state.customItemFilter[location].tasks.otherCustomProperties.asap;

export const getCustomItemFilterHasFilesForLocation = (state: RootState, location: CustomItemFilterLocation) =>
  state.customItemFilter[location].tasks.otherCustomProperties.hasFiles;

/* By ItemType */
export const getCustomItemFilterNameContainsForItemTypeAndLocation = (
  state: RootState,
  itemType: CustomItemFilterItemType,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location][itemType].otherCustomProperties.nameContains;

export const getCustomItemFilterCreatedByUserIdForItemTypeAndLocation = (
  state: RootState,
  itemType: CustomItemFilterItemType,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location][itemType].otherCustomProperties.createdByUserId;

export const getCustomItemFilterAtRiskForItemTypeAndLocation = (
  state: RootState,
  itemType: CustomItemFilterItemType,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location][itemType].otherCustomProperties.atRisk;

export const getCustomItemFilterWorkLimitRiskForItemTypeAndLocation = (
  state: RootState,
  itemType: CustomItemFilterItemType,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location][itemType].otherCustomProperties.workLimitRisk;

export const getCustomItemFilterNameForItemTypeAndLocation = (
  state: RootState,
  itemType: CustomItemFilterItemType,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location][itemType].statusFilterSelection;

export const getCustomItemFilterHasFilesForItemTypeAndLocation = (
  state: RootState,
  itemType: CustomItemFilterItemType,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location][itemType].otherCustomProperties.hasFiles;

export const getCustomItemFilterCustomFieldPropertiesForItemTypeAndLocation = (
  state: RootState,
  itemType: CustomItemFilterItemType,
  location: CustomItemFilterLocation,
) => state.customItemFilter[location][itemType].otherCustomProperties.customFieldsAndValues;
