import { useSelector } from 'react-redux';

import { useHasTeam25TeamBasedPlanning } from 'hooks/use_has_feature';
import { getItemForId } from 'state/entities/selectors/item';

import { getPercentComplete } from './helpers/item_metric_calculations';

export const useIsItemPlanned = (itemId: number) => {
  const item = useSelector((state) => getItemForId(state, itemId));

  const hasTeam25TeamBasedPlanning = useHasTeam25TeamBasedPlanning();

  if (!item || !hasTeam25TeamBasedPlanning) {
    return false;
  }

  return item?.teamPlanned ?? false;
};

export const getPercentPlannedComplete = (
  lowRemainingWork: number,
  highRemainingWork: number,
  loggedWork: number,
  plannedEstimate: number,
) => {
  if (!plannedEstimate) {
    return null;
  }
  const remainingWork = (lowRemainingWork + highRemainingWork) / 2;
  const totalWork = remainingWork + loggedWork;
  return getPercentComplete(totalWork, plannedEstimate);
};
