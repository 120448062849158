import { AddEditGridColumnKey } from 'features/common/data_grid/enums';
import {
  AddEditGridColumns,
  PushItemsToJiraErrorByItemId,
  PushItemsToJiraErrorObj,
} from 'features/common/data_grid/types';
import { LpSystemId } from 'features/jira_project/modal/types';

export const reorderColumnsWithScheduleDirectiveOnly = ({ columns }: { columns: AddEditGridColumns }) => {
  return columns.filter(
    (column) => column.key === AddEditGridColumnKey.ScheduleDirective || column.key === AddEditGridColumnKey.Name,
  );
};

const columnKeyToSystemId = {
  [AddEditGridColumnKey.TargetStart]: LpSystemId.TargetStart,
  [AddEditGridColumnKey.TargetFinish]: LpSystemId.TargetFinish,
  [AddEditGridColumnKey.WorkLimit]: LpSystemId.WorkLimit,
  [AddEditGridColumnKey.PlannedEstimate]: LpSystemId.BenchmarkEstimate,
};

export const reorderColumnsWithSortedErrorsFirst = ({
  columns,
  jiraRequiredErrorIdsByItemId,
}: {
  columns: AddEditGridColumns;
  jiraRequiredErrorIdsByItemId: PushItemsToJiraErrorByItemId;
}): AddEditGridColumns => {
  const [columnsWithErrors, columnsWithoutErrors] = columns.reduce<[AddEditGridColumns, AddEditGridColumns]>(
    ([withErrors, withoutErrors], column) => {
      const columnKey = column.key as keyof typeof columnKeyToSystemId;
      const hasError = Object.values(jiraRequiredErrorIdsByItemId).some(
        (error: PushItemsToJiraErrorObj) =>
          error.lpFieldId.includes(Number(column.key)) ||
          error.lpSystemFieldId.includes(columnKeyToSystemId[columnKey]),
      );

      return hasError ? [[...withErrors, column], withoutErrors] : [withErrors, [...withoutErrors, column]];
    },
    [[], []],
  );

  const sortedErrorColumns = [...columnsWithErrors].sort((a, b) => {
    const nameA = typeof a.name === 'string' ? a.name : '';
    const nameB = typeof b.name === 'string' ? b.name : '';
    return nameA.localeCompare(nameB);
  });

  return [...sortedErrorColumns, ...columnsWithoutErrors];
};
