import { useSelector } from 'react-redux';

import { ItemType, PackageStatus, SchedulingType } from 'daos/enums';
import { FolderStatus } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { getItemIsLateRisk } from 'lib/helpers';
import { getItemForId } from 'state/entities/selectors/item';
import { getItemMetricsForId } from 'state/entities/selectors/item_metric';
import { getIterationForId, getIterationForItemId } from 'state/entities/selectors/iterations';
import { getTaskStatusForId } from 'state/entities/selectors/task_status';

enum TargetFinishOrEffectiveFinishEnforced {
  Target = 'TARGET',
  Effective = 'EFFECTIVE',
  None = 'NONE',
}

interface ItemDataForStatus {
  isClipped: boolean;
  isDone: boolean;
  isDoneAfterEffectiveTargetFinish: boolean;
  isDoneAfterTargetFinish: boolean;
  isDoneLate: boolean;
  isEffectivelyOnHold: boolean;
  isExpectedFinishAfterEffectiveTargetFinish: boolean;
  isExpectedFinishAfterTargetFinish: boolean;
  isExpectedStartAfterEffectiveTargetFinish: boolean;
  isExpectedStartBeforeEffectiveTargetStart: boolean;
  isExpectedStartEqualExpectedFinish: boolean;
  isLate: boolean;
  isLateRisk: boolean;
  isOnHold: boolean;
  isScheduled: boolean;
}

export const calcItemDataForStatus = ({
  doneDate,
  effectiveTargetFinish,
  effectiveTargetStart,
  expectedFinish,
  expectedStart,
  folderStatus,
  isClipped,
  isLate,
  itemType,
  latestFinish,
  packageStatus,
  schedulingType,
  targetFinish,
}: {
  doneDate: string | null | undefined;
  effectiveTargetFinish: string | null | undefined;
  effectiveTargetStart: string | null | undefined;
  expectedFinish: string | null | undefined;
  expectedStart: string | null | undefined;
  folderStatus: FolderStatus | null | undefined;
  isClipped: boolean;
  isLate: boolean;
  itemType: ItemType | undefined;
  latestFinish: string | null | undefined;
  packageStatus: PackageStatus | null | undefined;
  schedulingType: SchedulingType | undefined;
  targetFinish: string | null | undefined;
}): ItemDataForStatus => {
  let isOnHold = false;
  let isScheduled = false;
  let isEffectivelyOnHold = false;
  let isDone = !!doneDate;
  let isExpectedFinishAfterTargetFinish = false;
  let isExpectedFinishAfterEffectiveTargetFinish = false;

  const isLateRisk = getItemIsLateRisk({ effectiveTargetFinish, isLate, latestFinish });

  const isDoneAfterFinishDate = (finishDate?: string | null) =>
    !!doneDate && !!finishDate && doneDate.localeCompare(finishDate) > 0;

  const isDoneAfterTargetFinish = isDoneAfterFinishDate(targetFinish);
  const isDoneAfterEffectiveTargetFinish = isDoneAfterFinishDate(effectiveTargetFinish);

  const isExpectedStartBeforeEffectiveTargetStart =
    !!expectedStart && !!effectiveTargetStart && expectedStart < effectiveTargetStart;

  const isExpectedStartEqualExpectedFinish = !!expectedStart && !!expectedFinish && expectedStart === expectedFinish;

  const isExpectedStartAfterEffectiveTargetFinish =
    !!expectedStart && !!effectiveTargetFinish && expectedStart > effectiveTargetFinish;

  let isTargetFinishOrEffectiveFinishEnforced = TargetFinishOrEffectiveFinishEnforced.None;

  switch (itemType) {
    case ItemType.PACKAGES:
      isScheduled = !isDone && packageStatus === PackageStatus.SCHEDULED;
      isOnHold = !isDone && !isScheduled;
      break;
    case ItemType.PROJECTS:
    case ItemType.FOLDERS:
      isDone = isDone || folderStatus === FolderStatus.DONE;
      isOnHold = !isDone && folderStatus === FolderStatus.ON_HOLD;
      isScheduled = !isDone && folderStatus === FolderStatus.ACTIVE;
      break;
    case ItemType.TASKS:
      isDone = isDone || schedulingType === SchedulingType.Done;
      isOnHold = !isDone && schedulingType === SchedulingType.Unscheduled;
      isScheduled = !isDone && schedulingType === SchedulingType.Scheduled;
      break;
    case ItemType.ASSIGNMENTS:
      isScheduled = !isDone && (!!expectedStart || !!expectedFinish || !!latestFinish);
      isOnHold = !isDone && !isScheduled;
      break;
    default:
      break;
  }

  isEffectivelyOnHold = isScheduled && !expectedStart;

  if (targetFinish) {
    if (effectiveTargetFinish && effectiveTargetFinish < targetFinish) {
      isTargetFinishOrEffectiveFinishEnforced = TargetFinishOrEffectiveFinishEnforced.Effective;
    } else {
      isTargetFinishOrEffectiveFinishEnforced = TargetFinishOrEffectiveFinishEnforced.Target;
    }
  } else {
    isTargetFinishOrEffectiveFinishEnforced = effectiveTargetFinish
      ? TargetFinishOrEffectiveFinishEnforced.Effective
      : TargetFinishOrEffectiveFinishEnforced.None;
  }

  isExpectedFinishAfterTargetFinish =
    isTargetFinishOrEffectiveFinishEnforced === TargetFinishOrEffectiveFinishEnforced.Target &&
    !!targetFinish &&
    (isLate || (expectedFinish ?? '') > targetFinish);

  isExpectedFinishAfterEffectiveTargetFinish =
    isTargetFinishOrEffectiveFinishEnforced === TargetFinishOrEffectiveFinishEnforced.Effective &&
    (isLate || (!!effectiveTargetFinish && (expectedFinish ?? '') > effectiveTargetFinish));

  return {
    isClipped,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isDoneAfterTargetFinish,
    isDoneLate: isDoneAfterEffectiveTargetFinish || isDoneAfterTargetFinish,
    isEffectivelyOnHold,
    isExpectedFinishAfterEffectiveTargetFinish,
    isExpectedFinishAfterTargetFinish,
    isExpectedStartAfterEffectiveTargetFinish,
    isExpectedStartBeforeEffectiveTargetStart,
    isExpectedStartEqualExpectedFinish,
    isLate,
    isLateRisk,
    isOnHold,
    isScheduled,
  };
};

export const useItemDataForStatus = (itemId: number | undefined): ItemDataForStatus => {
  const item = useSelector((state) => (itemId ? getItemForId(state, itemId) : undefined));
  const itemMetrics = useSelector((state) => (itemId ? getItemMetricsForId(state, itemId) : undefined));
  const taskStatus = useSelector((state) => item?.taskStatus && getTaskStatusForId(state, item.taskStatus.id));

  return calcItemDataForStatus({
    doneDate: item?.doneDate,
    effectiveTargetFinish: itemMetrics?.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics?.effectiveTargetStart,
    expectedFinish: item?.expectedFinish,
    expectedStart: item?.expectedStart,
    folderStatus: item?.folderStatus,
    isClipped: !!item && item.clippedEffort !== null,
    isLate: !!item?.late,
    itemType: item?.itemType,
    latestFinish: item?.latestFinish,
    packageStatus: item?.packageStatus,
    schedulingType: taskStatus?.schedulingType,
    targetFinish: item?.targetFinish,
  });
};

export const useItemBelongsToDoneIteration = (item: Item | undefined): boolean => {
  const itemIteration = useSelector((state) => getIterationForId(state, item?.iterationId ?? 0));
  const parentId = item?.parent?.id ?? 0;
  const parentIteration = useSelector((state) => getIterationForItemId(state, parentId));

  return !!(itemIteration?.done || parentIteration?.done);
};

export function calculateAssignmentBarProps({
  folderStatus,
  schedulingType,
}: {
  folderStatus: FolderStatus | null | undefined;
  schedulingType: SchedulingType | null;
}) {
  const isOnHold = schedulingType === SchedulingType.Unscheduled;
  const isScheduled = schedulingType === SchedulingType.Scheduled;
  const isDone = schedulingType === SchedulingType.Done;
  const isEffectivelyOnHold = isScheduled && folderStatus === FolderStatus.ON_HOLD;

  return {
    isDone,
    isEffectivelyOnHold,
    isOnHold,
  };
}
