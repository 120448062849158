import { isNil } from 'lodash';

export type ReadonlyRecordKey = string | number | symbol;

export type ReadonlyRecord<KeyType extends ReadonlyRecordKey, RecordType> = {
  readonly [Property in KeyType]: RecordType;
};

export const associateRecordBy = <TKey extends ReadonlyRecordKey, T>(
  record: ReadonlyRecord<ReadonlyRecordKey, T>,
  associateFunction: (_: T) => TKey,
): ReadonlyRecord<TKey, T> => {
  const associateResults: Record<TKey, T> = Object.create(null);

  for (const key in record) {
    if (Object.prototype.hasOwnProperty.call(record, key)) {
      const value = record[key];
      if (value) {
        const newKey = associateFunction(value);
        associateResults[newKey] = value;
      }
    }
  }

  return associateResults;
};

export const groupRecordBy = <TKey extends ReadonlyRecordKey, T>(
  record: ReadonlyRecord<ReadonlyRecordKey, T>,
  groupingFunction: (_: T) => TKey | null | undefined,
): ReadonlyRecord<TKey, ReadonlyArray<T>> => {
  const groupedResults: Record<TKey, Array<T>> = Object.create(null);

  for (const key in record) {
    if (Object.prototype.hasOwnProperty.call(record, key)) {
      const value = record[key];
      if (value) {
        const newKey = groupingFunction(value);
        if (!isNil(newKey)) {
          const existingResultsForNewKey = groupedResults[newKey];
          if (existingResultsForNewKey !== undefined) {
            existingResultsForNewKey.push(value);
          } else {
            groupedResults[newKey] = [value];
          }
        }
      }
    }
  }

  return groupedResults;
};

export const recordValues = <T>(record: ReadonlyRecord<ReadonlyRecordKey, T>): ReadonlyArray<T> =>
  Object.values(record);

export const readonlyRecord = <TKey extends ReadonlyRecordKey, T>(record: Record<TKey, T>): ReadonlyRecord<TKey, T> => {
  return record;
};
export const readonlyArray = <T>(array: Array<T>): ReadonlyArray<T> => array;
