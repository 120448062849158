import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ClickableText from 'features/common/clickable_text';
import { getItemForId } from 'state/entities/selectors/item';

interface ItemNameProps {
  itemId: number;
}

const ItemName = ({ itemId }: ItemNameProps) => {
  const history = useHistory();
  const item = useSelector((state) => getItemForId(state, itemId));

  const handleItemClick = () => {
    history.push(`#panelId=${itemId}`);
  };

  if (!item || !item.name) {
    return null;
  }

  return <ClickableText onClick={handleItemClick} text={item.name} />;
};

export default ItemName;
