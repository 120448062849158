import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { WidgetType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { WidgetDao } from 'daos/widgets';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { getWidgetForId } from 'features/dashboards/selectors';
import { LinkedNoteContent } from 'features/dashboards_v2/widget/widgets/linked_note';
import { WidgetIntakeForm } from 'features/dashboards_v2/widget_click_through/intake/form';
import { DashboardNoteContent, WidgetNoteClickThroughRenderer } from 'features/dashboards_v2/widget_click_through/note';
import { WidgetWorkload } from 'features/dashboards_v2/widget_click_through/workload';
import { GuestWidgetGrid } from 'features/guest/dashboard/expanded_widget/grid';
import { awaitRequestFinish } from 'lib/api';

import { GuestWidgetTaskBoard } from './task_board';
import { UnsupportedClickThrough } from './unsupported';

export const GuestDashboardExpandedWidget = () => {
  const dispatch = useDispatch();
  const { dashboardId, widgetId } = useParams<{ dashboardId: string; widgetId: string }>();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const widget = useSelector((state) => getWidgetForId(state, widgetId));

  const fetchWidget = useCallback(() => {
    const { uuid } = dispatch(
      WidgetDao.fetch({
        organizationId,
        workspaceId,
        dashboardId,
        widgetId,
      }),
    );

    dispatch(awaitRequestFinish(uuid, {}));
  }, [dashboardId, dispatch, organizationId, widgetId, workspaceId]);

  useEffect(() => {
    fetchWidget();
  }, [fetchWidget]);

  if (!widget) {
    return null;
  }

  switch (widget.widgetType) {
    case WidgetType.BoardSummary:
      return <GuestWidgetTaskBoard />;
    case WidgetType.DashboardNote:
      return <WidgetNoteClickThroughRenderer content={<DashboardNoteContent widget={widget} />} />;
    case WidgetType.Intake:
      return <WidgetIntakeForm />;
    case WidgetType.LinkedNote:
      return <WidgetNoteClickThroughRenderer content={<LinkedNoteContent widget={widget as WidgetWithData} />} />;
    case WidgetType.List:
      return <GuestWidgetGrid widget={widget as WidgetWithData} />;
    case WidgetType.Workload:
      return <WidgetWorkload />;
    default:
      return <UnsupportedClickThrough />;
  }
};
