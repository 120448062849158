export interface LpSvgProps {
  color?: string;
}

export enum LpSvgs {
  JIRA_WHITE = 'jira_white',
  LP_WAVY = 'lp_wavy',
  JIRA = 'jira',
  JIRA_EPIC = 'jira_epic',
  JIRA_FEATURE = 'jira_feature',
  JIRA_SOFTWARE = 'jira_software',
  JIRA_BLUE_GRADIENT = 'jira_blue_gradient',
  PORTFOLIO = 'portfolio',
  PORTFOLIO_JIRA = 'portfolio_jira',
  TIMESHEET_LOGO = 'timesheet_logo',
  EPIC_BASED_SUBFOLDER = 'epic_based_subfolder',
  HIERARCHICAL_SUBFOLDERS = 'hierarchical_subfolders',
  FLAT_LIST = 'flat_list',
  PORTFOLIO_MANAGER_WAVY = 'portfolio_manager_wavy',
  TEMPO_LOGO = 'tempo_logo',
  TEMPO_LOGO_NO_TEXT = 'tempo_logo_no_text',
  TRASHCAN = 'trashcan',
  PLAN = 'plan',
  PUBLISHED_WITH_CHANGES = 'published_with_changes',
  NOVA_PLACEHOLDER_LOGO = 'nova_placeholder_logo',
  NOVA_EMPTY_PLANS = 'nova_empty_plans',
  NOVA_EPIC = 'nova_epic',
  NOVA_EVENT = 'nova_event',
  NOVA_INITIATIVE = 'nova_initiative',
  NOVA_GROUPS = 'nova_groups',
  NOVA_CALENDAR = 'nova_calendar',
  NOVA_ROCKET = 'nova_rocket',
  NOVA_TIMELINE = 'nova_timeline',
}
