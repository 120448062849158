import classNames from 'classnames';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';

import {
  ItemMetricColumnRenderer,
  TimesheetRollupColumnRenderer,
} from 'containers/shared/custom_column/column_renderers';
import { ColumnValueRenderer } from 'containers/shared/custom_column/column_value';
import { Columns, StandardColumns } from 'containers/shared/custom_column/enum';
import FolderStatusIcon from 'containers/shared/folder_status_icon';
import ItemIcon from 'containers/shared/item_icon';
import ItemLink from 'containers/shared/item_link';
import SchedulingLabels from 'containers/shared/notice_icons/scheduling_labels';
import { ItemType } from 'daos/enums';
import { ItemColorDisplay } from 'daos/item_enums';
import { CustomField } from 'daos/model_types';
import { GridModalState, ItemFormatterProps, ItemSummaryFormatterProps } from 'features/common/data_grid/types';
import { EllipsisActionDropdown } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown';
import { useEllipsisActionClickLocationContext } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/use_ellipsis_action_click_location';
import { EllipsisActionViewLocation } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { ItemAncestryBreadcrumb } from 'features/common/item_ancestry_breadcrumb';
import { LpIcon, squareSolid } from 'features/common/lp_icon';
import { getRendererPropsForWidgetColumn } from 'features/dashboards_v2/widget/widgets/properties_summary/helpers';
import { useClickLocationForContextMenu } from 'hooks/use_click_location_for_context_menu';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { useScheduleDateDisplay } from 'hooks/use_schedule_date_display';
import { formatAsHoursWithNoRounding } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';
import { getDateRangeForId } from 'state/entities/selectors/date_range_selector';
import { getItemForId } from 'state/entities/selectors/item';
import { getFilterAwareItemMetricValuePropsForId } from 'state/entities/selectors/item_metric';

import { itemGridConfigColumn } from './helpers';

type DateOption =
  | StandardColumns.DoneDate
  | StandardColumns.ExpectedFinish
  | StandardColumns.ExpectedStart
  | StandardColumns.FinishRange
  | StandardColumns.LatestFinish
  | StandardColumns.TargetFinish
  | StandardColumns.TargetStart;

export const ColorColumnFormatter = ({ row }: ItemFormatterProps) => {
  const color = row.color ?? '';
  const colorName = ItemColorDisplay[color];
  return (
    <span>
      <LpIcon color={`#${color}`} icon={squareSolid} /> {colorName}
    </span>
  );
};
export const DateColumnFormatter = ({ date }: { date: string }) => {
  const { formatLocalDate } = useLocalizedFormats();
  return <span>{formatLocalDate(date)}</span>;
};

export const DateRangeColumnFormatter = ({ row }: ItemFormatterProps) => {
  const dateRangeFilterValues = useSelector((state) => getDateRangeForId(state, row.id));
  const { formatLocalDate } = useLocalizedFormats();

  if (dateRangeFilterValues.length === 0) {
    return <span>No Date Range</span>;
  }

  const dateRangeStrings = dateRangeFilterValues.map((dateRangeFilterValue) => {
    const startDate = dateRangeFilterValue.startDate == null ? '' : formatLocalDate(dateRangeFilterValue.startDate);
    const endDate = dateRangeFilterValue.endDate == null ? '' : formatLocalDate(dateRangeFilterValue.endDate);
    return `${startDate} - ${endDate}`;
  });

  return <span>{dateRangeStrings.join(', ')}</span>;
};

export const HoursColumnFormatter = ({ seconds }: { seconds: number }) => {
  return <>{formatAsHoursWithNoRounding(convertSecondsToHours(seconds))}</>;
};

export const ItemAncestryNameColumnFormatter = ({
  buildAncestryUpToItemType,
  showAlertIcons,
  row,
}: ItemFormatterProps & { buildAncestryUpToItemType: ItemType; showAlertIcons: boolean }) => {
  const item = useSelector((state) => getItemForId(state, row.id));

  const { clickLocation, setClickLocation } = useClickLocationForContextMenu();
  const { handleShowContextMenu, handleHideContextMenu, triggerRef } = useEllipsisActionClickLocationContext(
    clickLocation,
    setClickLocation,
  );

  if (!item) {
    return null;
  }

  const modalState = (row.modalState as GridModalState) ?? {};

  return (
    <span onContextMenu={handleShowContextMenu} className="lp-data-grid__item">
      <EllipsisActionDropdown
        afterDelete={noop}
        clickLocation={clickLocation}
        fetchItems={noop}
        handleHideContextMenu={handleHideContextMenu}
        isTemplateGrid={false}
        item={item}
        setDeletionData={modalState.setDeletionData}
        setMoveItemId={modalState.setMoveItemId}
        showActionTriggerIcon={false}
        triggerRef={triggerRef}
        viewLocation={EllipsisActionViewLocation.GridView}
      />

      <ItemIcon itemId={item.id} hasToolTip={false} />

      <FolderStatusIcon folderStatus={item.folderStatus} className="icon" />

      <span className="lp-data-grid__item-name">
        <ItemLink itemId={row.id} openScheduleTab={false} />
        <ItemAncestryBreadcrumb buildAncestryUpToItemType={buildAncestryUpToItemType} itemId={row.id} />
      </span>

      {showAlertIcons && <SchedulingLabels itemId={row.id} />}
    </span>
  );
};

export const ItemMetricsColumnFormatter = ({ id, metricName }: { id: number; metricName: Columns }) => {
  const itemMetricProps = useSelector((state) => getFilterAwareItemMetricValuePropsForId(state, id));

  return <ItemMetricColumnRenderer column={metricName} itemMetricProps={itemMetricProps} />;
};

export const TimesheetRollupColumnFormatter = ({ id, columnName }: { id: number; columnName: Columns }) => {
  const itemMetricProps = useSelector((state) => getFilterAwareItemMetricValuePropsForId(state, id));

  return <TimesheetRollupColumnRenderer column={columnName} itemMetricProps={itemMetricProps} />;
};

export const ScheduleDateColumnFormatter = ({ row, dateOption }: ItemFormatterProps & { dateOption: DateOption }) => {
  const itemId = row.id;
  const date = row[dateOption];
  const noTargetStart = dateOption === StandardColumns.TargetStart && !date;
  const noTargetFinish = dateOption === StandardColumns.TargetFinish && !date;

  return (
    <span className={classNames({ 'lp-data-grid--inherited': noTargetStart || noTargetFinish })}>
      {useScheduleDateDisplay(itemId, dateOption)}
    </span>
  );
};

export const ItemGridSummaryFormatter = (props: ItemSummaryFormatterProps & { customField?: CustomField }) => {
  const { row, customField, column } = props;
  const configColumn = {
    customFieldId: customField?.id ?? null,
    customFieldName: customField?.name ?? null,
    column: itemGridConfigColumn({ customFieldId: customField?.id, columnKey: column.key }),
  };
  const isSummaryRow = true;

  const columnValueProps = getRendererPropsForWidgetColumn(configColumn, row.columnDataMap, isSummaryRow);

  return <ColumnValueRenderer selectedColumn={configColumn.column} {...columnValueProps} />;
};
