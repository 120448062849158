import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import LpFormInput from 'features/common/forms/lp_form_input';
import LpModal from 'features/common/modals/lp_modal';
import { getFieldValueForId } from 'state/entities/selectors/custom_field';

const schema = yup.object().shape({
  text: yup.string(),
  url: yup.string().url().required('URL is required'),
});

const CustomFieldLinkForm = ({
  onClose,
  fieldValueId,
  onSubmit,
}: {
  fieldValueId: number | undefined;
  onSubmit: ({ url, text }: { url: string; text: string }) => void;
  onClose: () => void;
}) => {
  const fieldValue = useSelector((state) => (fieldValueId ? getFieldValueForId(state, fieldValueId) : undefined));

  const { getFieldMeta, getFieldProps, submitForm } = useFormik({
    initialValues: {
      text: fieldValue?.text ?? '',
      url: fieldValue?.url ?? '',
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({ url, text }) => onSubmit({ url: url.trim(), text: text.trim() }),
  });

  return (
    <LpModal
      size="tiny"
      onClose={onClose}
      header={'Link'}
      content={
        <Form>
          <LpFormInput label="Url:" fieldKey="url" getFieldMeta={getFieldMeta} getFieldProps={getFieldProps} />
          <LpFormInput label="Description:" fieldKey="text" getFieldMeta={getFieldMeta} getFieldProps={getFieldProps} />
        </Form>
      }
      actions={
        <>
          <Button content={'Cancel'} onClick={onClose} />
          <Button primary type="submit" content={'Save'} onClick={submitForm} />
        </>
      }
    />
  );
};

export default CustomFieldLinkForm;
