import { keyBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { SystemSettings } from 'daos/model_types';
import { Currency, OrganizationFeatureFlags, ColumnsSelection } from 'daos/types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { getColumnSelectionListFromColumnOptionsRefactor } from 'features/common/selection_list';
import { FeatureFlag } from 'lib/feature_flags';
import { readonlyArray } from 'lib/readonly_record';
import { getFieldsById } from 'state/entities/selectors/custom_field';
import { createCacheByIdConfig } from 'state/entities/selectors/shared';
import { RootState } from 'state/root_reducer';

const getSystemSettings = (state: RootState, workspaceId = 0): SystemSettings | undefined => {
  return state.entities.systemSettings[workspaceId];
};

const getCurrentWorkspaceSystemSettings = createSelector(
  getCurrentWorkspaceId,
  (state: RootState) => state,
  (workspaceId, state) => {
    return state.entities.systemSettings[workspaceId];
  },
);

// Intentionally using StandardColumnOptions for these because widget columns must correspond with the server-side enum
const emptyColumnsArray = readonlyArray<StandardColumns>([]);
const emptyTimezoneArray = readonlyArray<string>([]);
const emptyCurrenciesArray = readonlyArray<Currency>([]);

export const allFeatureFlagsDisabled = Object.fromEntries(
  Object.values(FeatureFlag).map((key) => [key, false]),
) as OrganizationFeatureFlags;

export const getSystemFeatureFlagDefaults = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.featureFlagDefaults ?? allFeatureFlagsDisabled;
});

export const getSystemFeatureFlagValues = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.featureFlagValues ?? allFeatureFlagsDisabled;
});

export const getSystemTimezones = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.timezones ?? emptyTimezoneArray;
});

export const getSystemCurrencies = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.currencies ?? emptyCurrenciesArray;
});

const getSystemCurrenciesByCurrencyCode = createSelector(getSystemCurrencies, (systemCurrencies) => {
  return keyBy<Currency>(systemCurrencies, (currency) => currency.currencyCode);
});

export const getSystemCurrencyForCurrencyCode = (state: RootState, currencyCode: string) =>
  getSystemCurrenciesByCurrencyCode(state)[currencyCode];

export const getIsSlackIntegrationSupportedByEnvironment = createSelector(getSystemSettings, (systemSettings) => {
  return !!systemSettings?.allowSlackIntegration;
});

export const getNexusBaseUrl = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.nexusBaseUrl ?? '';
});

export const getEmailRegexValidator = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.emailRegex ?? '';
});

export const getMetricsWidgetColumnsExceptTaskStatus = createSelector(getSystemSettings, (systemSettings) => {
  if (!systemSettings) {
    return emptyColumnsArray;
  } else {
    return readonlyArray(
      systemSettings.columns.metricsWidget.filter((column) => column !== StandardColumns.TaskStatus),
    );
  }
});

export const getMetricsWidgetDefaultColumns = (state: RootState) => {
  return getSystemSettings(state)?.columns.metricsWidgetDefaults ?? emptyColumnsArray;
};

export const getPropertiesWidgetColumns = (state: RootState) => {
  return getSystemSettings(state)?.columns.propertiesWidget ?? emptyColumnsArray;
};
export const getPeopleReportWidgetColumns = (state: RootState, workspaceId = 0) => {
  return getSystemSettings(state, workspaceId)?.columns.peopleReportWidget ?? emptyColumnsArray;
};

export const getPropertiesWidgetDefaultColumns = (state: RootState) => {
  return getSystemSettings(state)?.columns.propertiesWidgetDefaults ?? emptyColumnsArray;
};

const defaultIterationTableColumns = readonlyArray([
  StandardColumns.TotalWorkRange,
  StandardColumns.ExpectedStart,
  StandardColumns.ExpectedFinish,
]);

export const getIterationTableDefaultColumns = createSelector(getCurrentWorkspaceSystemSettings, (systemSettings) => {
  return systemSettings?.columns.iterationTableDefaults ?? defaultIterationTableColumns;
});

const getIterationTableColumns = createSelector(getCurrentWorkspaceSystemSettings, (systemSettings) => {
  return systemSettings?.columns.iterationTable ?? defaultIterationTableColumns;
});

export const getIterationTableSelectionListColumns = createSelector(
  getIterationTableColumns,
  getFieldsById,
  (iterationColumns, fieldsById) => {
    return getColumnSelectionListFromColumnOptionsRefactor({
      columnOptions: iterationColumns,
      customFields: fieldsById,
    });
  },
);

export const getIterationTableDefaultSelectionListColumns = createSelector(
  getIterationTableDefaultColumns,
  (iterationColumns) => {
    return getColumnSelectionListFromColumnOptionsRefactor({
      columnOptions: iterationColumns,
      customFields: {},
    });
  },
);

export const getListWidgetsColumns = createCachedSelector(
  getCurrentWorkspaceSystemSettings,
  (_: RootState, key: keyof ColumnsSelection) => key,
  (systemSettings, key) => {
    if (!systemSettings) {
      return emptyColumnsArray;
    } else {
      return systemSettings.columns.listWidgets[key];
    }
  },
)(createCacheByIdConfig());

export const getPppsColumns = createCachedSelector(
  getCurrentWorkspaceSystemSettings,
  (_: RootState, key: keyof ColumnsSelection) => key,
  (systemSettings, key) => {
    if (!systemSettings) {
      return emptyColumnsArray;
    } else {
      return systemSettings.columns.ppp[key];
    }
  },
)(createCacheByIdConfig());
