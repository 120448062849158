import classNames from 'classnames';
import { motion, MotionProps, PanInfo } from 'framer-motion';
import { createContext, CSSProperties, ReactNode, ReactNodeArray, useContext, useEffect, useRef } from 'react';
/* eslint-disable no-restricted-imports */
import {
  DragDropContext,
  Draggable,
  DraggableChildrenFn,
  DraggableProvided,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
  DraggableRubric,
  DraggableStateSnapshot,
  DragStart,
  DragUpdate,
  Droppable,
  DroppableMode,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  TypeId,
  useKeyboardSensor,
} from 'react-beautiful-dnd';
/* eslint-enable no-restricted-imports */
import { Ref, Table } from 'semantic-ui-react';

import { tempoCellHoverHighlightColor } from 'style/variables';

import { usePointerEventsSensor } from './react-beautiful-dnd-sensor/use_pointer_events_sensor';

import './index.scss';

interface LpDragAndDropContextProps {
  droppableProvided: DroppableProvided | undefined;
  droppableStateSnapshot: DroppableStateSnapshot | undefined;
}
const LpDragAndDropContext = createContext<LpDragAndDropContextProps>({
  droppableProvided: undefined,
  droppableStateSnapshot: undefined,
});

function useLpDragAndDropContext() {
  return useContext(LpDragAndDropContext);
}

interface LpDraggableContextProps {
  draggableProvided: DraggableProvided | undefined;
  draggableStateSnapshot: DraggableStateSnapshot | undefined;
  draggableRubric?: DraggableRubric;
}
const LpDraggableContext = createContext<LpDraggableContextProps>({
  draggableProvided: undefined,
  draggableStateSnapshot: undefined,
});

function useLpDraggableContext() {
  return useContext(LpDraggableContext);
}

interface InternalContextProps {
  table: boolean;
}
const InternalContext = createContext<InternalContextProps>({
  table: false,
});

function useInternalContext() {
  return useContext(InternalContext);
}

interface LpDragAndDropProps {
  onDragEnd: (event: DropResult) => void;
  onDragStart?: (event: DragStart) => void;
  onDragUpdate?: (event: DragUpdate) => void;
  children: ReactNode | Array<ReactNode>;
  enableDefaultSensors?: boolean;
  table?: boolean;
}
function LpDragAndDrop({
  onDragEnd,
  onDragStart,
  onDragUpdate,
  children,
  enableDefaultSensors = false,
  table = false,
}: LpDragAndDropProps) {
  return (
    <InternalContext.Provider
      value={{
        table,
      }}
    >
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        enableDefaultSensors={enableDefaultSensors}
        sensors={enableDefaultSensors ? [] : [usePointerEventsSensor, useKeyboardSensor]}
      >
        {children}
      </DragDropContext>
    </InternalContext.Provider>
  );
}

interface LpDroppableProps {
  children: ReactNode | ReactNodeArray;
  className?: string;
  droppableId: string;
  dropDisabled?: boolean;
  mode?: DroppableMode;
  type?: TypeId;
  renderClone?: DraggableChildrenFn;
  renderPlaceholder?: boolean;
  isCombineEnabled?: boolean;
}
function LpDroppable({
  children,
  className,
  droppableId,
  dropDisabled,
  mode,
  type,
  renderClone,
  renderPlaceholder = true,
  isCombineEnabled,
}: LpDroppableProps) {
  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={dropDisabled}
      mode={mode}
      type={type}
      renderClone={renderClone}
      isCombineEnabled={isCombineEnabled}
    >
      {(provided, snapshot) => (
        <LpDragAndDropContext.Provider
          value={{
            droppableProvided: provided,
            droppableStateSnapshot: snapshot,
          }}
        >
          <LpDroppableContainer
            className={className}
            droppableProvided={provided}
            droppableStateSnapshot={snapshot}
            renderPlaceholder={renderPlaceholder}
          >
            {children}
          </LpDroppableContainer>
        </LpDragAndDropContext.Provider>
      )}
    </Droppable>
  );
}

interface LpDroppableContainerProps {
  children: ReactNode | Array<ReactNode>;
  className?: string;
  droppableProvided: DroppableProvided;
  droppableStateSnapshot: DroppableStateSnapshot;
  renderPlaceholder: boolean;
}
function LpDroppableContainer({
  children,
  className,
  droppableProvided,
  droppableStateSnapshot,
  renderPlaceholder,
}: LpDroppableContainerProps) {
  const { table } = useInternalContext();

  return table ? (
    <Ref innerRef={droppableProvided.innerRef}>
      <Table.Body
        {...droppableProvided.droppableProps}
        className={classNames(
          'lp-drag-and-drop-context',
          droppableStateSnapshot.isDraggingOver && 'lp-drag-and-drop-context__drag-active',
          className,
        )}
      >
        {children}
        {renderPlaceholder && droppableProvided.placeholder}
      </Table.Body>
    </Ref>
  ) : (
    <div
      ref={droppableProvided.innerRef}
      {...droppableProvided.droppableProps}
      className={classNames('lp-drag-and-drop-context', className)}
    >
      {children}
      {renderPlaceholder && droppableProvided.placeholder}
    </div>
  );
}

interface LpDraggableProvidedDragHandleProps extends Omit<DraggableProvidedDragHandleProps, 'onDragStart'> {
  onDragStart: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
}

type LpDraggableMotionProps = Pick<
  Partial<MotionProps>,
  'initial' | 'animate' | 'exit' | 'variants' | 'transition' | 'onAnimationComplete'
>;

function getStyle(style: DraggableProvidedDraggableProps['style'], snapshot: DraggableStateSnapshot) {
  if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
    return style;
  }

  const { moveTo } = snapshot.dropAnimation;
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

  return {
    ...style,
    transform: translate,
  };
}

interface LpDraggableItemProps extends LpDraggableMotionProps {
  children: ReactNode;
  className?: string;
  style: CSSProperties | null;
  draggableProvided: DraggableProvided;
  draggableStateSnapshot: DraggableStateSnapshot;
  hoverColor: string;
  selfDragHandle: boolean;
}
function LpDraggableItem({
  children,
  className,
  draggableProvided,
  draggableStateSnapshot,
  style = null,
  hoverColor,
  selfDragHandle,
  initial = { opacity: 0 },
  animate = { opacity: 1 },
  exit = { opacity: 1 },
  variants,
  transition = { duration: 0.1 },
  onAnimationComplete,
}: LpDraggableItemProps) {
  const { table } = useInternalContext();
  const ref = useRef<HTMLElement>(null);
  const provided = draggableProvided.dragHandleProps as unknown as LpDraggableProvidedDragHandleProps;

  useEffect(() => {
    ref.current?.style.setProperty('--lp-draggable-item-hover-color', hoverColor);
  }, [hoverColor]);

  return (
    <Ref innerRef={ref}>
      {table ? (
        <Ref innerRef={draggableProvided.innerRef}>
          <Table.Row
            className={classNames(
              'lp-draggable-item',
              className,
              draggableStateSnapshot.isDragging && 'dragging-active',
            )}
            {...draggableProvided.draggableProps}
            {...(selfDragHandle ? provided : {})}
            style={{
              ...style,
              ...draggableProvided.draggableProps.style,
            }}
          >
            {children}
          </Table.Row>
        </Ref>
      ) : (
        <motion.div
          initial={initial}
          animate={animate}
          exit={exit}
          transition={transition}
          variants={variants}
          onAnimationComplete={onAnimationComplete}
          key={draggableProvided.draggableProps['data-rbd-draggable-id']}
          ref={draggableProvided.innerRef}
          className={classNames('lp-draggable-item', className, draggableStateSnapshot.isDragging && 'dragging active')}
          {...draggableProvided.draggableProps}
          {...(selfDragHandle ? provided : {})}
          style={{
            ...style,
            ...getStyle(draggableProvided.draggableProps.style, draggableStateSnapshot),
          }}
        >
          {children}
        </motion.div>
      )}
    </Ref>
  );
}

interface LpDraggableProps extends LpDraggableMotionProps {
  children: ReactNode;
  className?: string;
  draggableId: string;
  index: number;
  dragDisabled?: boolean;
  style?: CSSProperties | null;
  hoverColor?: string;
  selfDragHandle?: boolean;
}
function LpDraggable({
  children,
  className,
  draggableId,
  index,
  dragDisabled,
  style = null,
  hoverColor = tempoCellHoverHighlightColor,
  selfDragHandle = false,
  initial,
  animate,
  exit,
  variants,
  transition,
  onAnimationComplete,
}: LpDraggableProps) {
  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={dragDisabled}>
      {(provided, snapshot) => (
        <LpDraggableContext.Provider
          value={{
            draggableProvided: provided,
            draggableStateSnapshot: snapshot,
          }}
        >
          <LpDraggableItem
            draggableProvided={provided}
            draggableStateSnapshot={snapshot}
            className={className}
            style={style}
            hoverColor={hoverColor}
            selfDragHandle={selfDragHandle}
            initial={initial}
            animate={animate}
            exit={exit}
            variants={variants}
            transition={transition}
            onAnimationComplete={onAnimationComplete}
          >
            {children}
          </LpDraggableItem>
        </LpDraggableContext.Provider>
      )}
    </Draggable>
  );
}

interface LpRenderedClone {
  children: ReactNode;
  className?: string;
  draggableProvided: DraggableProvided;
  draggableStateSnapshot: DraggableStateSnapshot;
  draggableRubric: DraggableRubric;
  hoverColor?: string;
}
function LpRenderedClone({
  children,
  className,
  draggableProvided,
  draggableStateSnapshot,
  draggableRubric,
  hoverColor = tempoCellHoverHighlightColor,
}: LpRenderedClone) {
  const { table } = useInternalContext();

  const draggableItem = (
    <LpDraggableItem
      draggableProvided={draggableProvided}
      draggableStateSnapshot={draggableStateSnapshot}
      className={className}
      style={null}
      hoverColor={hoverColor}
      selfDragHandle={false}
    >
      {children}
    </LpDraggableItem>
  );

  return (
    <LpDraggableContext.Provider
      value={{
        draggableProvided,
        draggableStateSnapshot,
        draggableRubric,
      }}
    >
      {table ? (
        <Table>
          <Table.Body>{draggableItem}</Table.Body>
        </Table>
      ) : (
        draggableItem
      )}
    </LpDraggableContext.Provider>
  );
}

export { useLpDragAndDropContext, useLpDraggableContext, LpDraggable, LpRenderedClone, LpDragAndDrop, LpDroppable };
