import { JiraAdvancedFilter, JiraAutoCompleteResult } from 'features/jira_project/modal/types';

const labelLookup = 'labels';
const sprintLookup = 'com.atlassian.greenhopper.service.sprint.Sprint';
const singleSelectLookup = 'com.atlassian.jira.issue.customfields.option.Option';

export const isSprint = (types: ReadonlyArray<String>) => types.includes(sprintLookup);
export const isSingleSelect = (types: ReadonlyArray<String>) => types.includes(singleSelectLookup);
const isLabels = (displayName: string) => displayName === labelLookup;

const stripQuotes = (str: string) => str.replace(/['"]+/g, '');

const stripBracketsAndInnerContent = (str: string) => {
  const regex = /^(.*)\[[^\]]*\]$/;
  const match = str.match(regex);

  return match ? match[1] : str;
};

export const formatNameColumn = (str: string) => {
  const withQuotesRemoved = stripQuotes(str);
  return stripBracketsAndInnerContent(withQuotesRemoved);
};

export const stripHTML = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const getKey = (index: number, selectedValues: Array<string>) => {
  const value = selectedValues[index];
  return value ? `${index}-${value}` : `${index}-empty`;
};

export const getFiltersFromAdvancedFilters = (advancedFilters: ReadonlyArray<JiraAdvancedFilter>) => {
  const label = advancedFilters.find((filter) => filter.displayName === labelLookup);
  const sprint = advancedFilters.find((filter) => filter.types.includes(sprintLookup));
  const singleSelectFields = advancedFilters.filter((filter) => filter.types.includes(singleSelectLookup));

  const filters = [...(label ? [label] : []), ...(sprint ? [sprint] : []), ...singleSelectFields].filter(
    (filter) => filter.searchable,
  );
  return filters;
};

export const getParentName = (filter: JiraAdvancedFilter): string => {
  if (filter.cfid) {
    return filter.cfid;
  }
  if (isSprint(filter.types)) {
    return filter.cfid ?? '';
  }
  if (isLabels(filter.value)) {
    return trimDoubleQuotes(filter.value);
  }
  return trimDoubleQuotes(filter.value) ?? trimDoubleQuotes(filter.displayName);
};

//  for some reason sometimes Jira sends us the value surrounded by double quotes which the suggestion API does not like
const trimDoubleQuotes = (str: string): string => {
  return str.replace(/^"|"$/g, '');
};

export const isJiraAutoCompleteMatch = ({
  suggestion,
  selectedOption,
}: {
  suggestion: JiraAutoCompleteResult;
  selectedOption: JiraAutoCompleteResult | string;
}) =>
  typeof selectedOption === 'string' ? suggestion.value === selectedOption : suggestion.value === selectedOption.value;
