export enum EntityFilter {
  Id = 'id',
  ItemId = 'itemId',
}

export enum LibraryResourceFilter {
  Id = 'id',
  Name = 'name',
  ItemId = 'itemId',
  ItemName = 'item.name',
  ItemAncestorId = 'item.ancestorId',
  UpdatedBy = 'updatedBy.username',
  PackageStatus = 'packageStatus',
}

export enum ItemFilter {
  ActiveChildOrganizationUserUsername = 'anyActiveChild.organizationUser.username',
  AncestorName = 'anyAncestor.name',
  AncestorPackageStatus = 'anyAncestor.packageStatus',
  ChildCreatedBy = 'anyChild.createdBy.username',
  ChildDescription = 'anyChild.description',
  ChildFilename = 'anyChild.filename',
  ChildName = 'anyChild.name',
  ChildOrganizationUser = 'anyChild.organizationUser',
  ChildOrganizationUserInGroupId = 'anyChild.organizationUser.inGroupId',
  ChildOrganizationUserUsername = 'anyChild.organizationUser.username',
  ChildQuickCustomFieldValueFilter = 'anyChild.quickCustomFieldValueFilter',
  ClippedEffort = 'schedule.clippedEffort',
  CostCodeName = 'costCode.name',
  CreatedByUserId = 'createdBy',
  CreatedByUsername = 'createdBy.username',
  DeletedByUserId = 'deletedBy',
  Description = 'description',
  DoneDate = 'doneDate',
  FieldValue = 'anyFieldValue.value',
  FolderStatus = 'folderStatus',
  GrandParentId = 'parent.parentId',
  HasAncestor = 'hasAncestor',
  HasAnyAncestor = 'anyAncestor.id',
  HasFiles = 'hasFiles',
  ItemType = 'itemType',
  Name = 'name',
  OrganizationUserUsername = 'organizationUser.username',
  PackageStatus = 'packageStatus',
  ParentCreatedByUserId = 'parent.createdBy',
  ParentCreatedByUsername = 'parent.createdBy.username',
  ParentDescription = 'parent.description',
  ParentId = 'parentId',
  ParentName = 'parent.name',
  ParentTaskStatusId = 'parent.taskStatusId',
  ParentTaskStatusName = 'parent.taskStatus.name',
  ParentQuickCustomFieldValueFilter = 'parent.quickCustomFieldValueFilter',
  QuickCustomFieldValueFilter = 'quickCustomFieldValueFilter',
  ScheduleDirective = 'scheduleDirective',
  ParentScheduleDirective = 'parent.scheduleDirective',
  ParentHasFiles = 'parent.hasFiles',
  ParentTaskStatusGroup = 'parent.taskStatusGroup',
  TaskStatusGroup = 'taskStatusGroup',
  TaskStatusId = 'taskStatusId',
  TaskStatusName = 'taskStatus.name',
  TaskStatusSchedulingType = 'taskStatusSchedulingType',
  TimesheetEntryCostCodeName = 'anyTimesheetEntry.costCode.name',
  WorkingTeam = 'workingTeam',
  ChildWorkingTeam = 'anyChild.workingTeam',
}

export enum OrganizationUserFilter {
  UserFullName = 'user.fullName',
  Username = 'username',
  UserFirstName = 'user.firstName',
  UserLastName = 'user.lastName',
  DisconnectedAt = 'disconnectedAt',
  UserType = 'user.userType',
  HasSlackIntegration = 'hasSlackIntegration',
  ActiveDashboardGuest = 'active.workspaceUser.guestUser',
  DashboardGuest = 'workspaceUser.guestUser',
}

export enum OrganizationSsoUserFilter {
  DisconnectedAt = 'organizationUser.disconnectedAt',
}

export enum WorkspaceUserFilter {
  AssignedToItemId = 'item.assignedToItemId',
  DashboardGuest = 'guestUser',
  DashboardGuestDashboardId = 'dashboardGuest.dashboardId',
  DisconnectedAt = 'disconnectedAt',
  Id = 'id',
  InGroupId = 'inGroupId',
  QuickCustomFieldValueFilter = 'quickCustomFieldValueFilter',
  UserEmail = 'user.email',
  UserFirstName = 'user.firstName',
  UserFullName = 'user.fullName',
  UserLastName = 'user.lastName',
  Username = 'organizationUser.username',
  UserType = 'user.userType',
  WorkingTeam = 'workingTeam',
}

export enum AvailabilityFilter {
  AvailabilityType = 'availabilityType',
  WorkspaceUserId = 'workspaceUserId',
}

export enum TimesheetExportFilter {
  WorkspaceUserId = 'workspaceUserId',
  UserGroupId = 'userGroupId',
  Date = 'date',
}

export enum FileFilter {
  BelongsToItemIdsOrChildren = 'belongsToItemIdsOrChildren',
  Filename = 'filename',
  Id = 'id',
}

export enum GroupAllocationsFilter {
  GroupId = 'groupId',
  ItemId = 'itemId',
}

export enum GroupsFilter {
  GroupIds = 'groupIds',
  GroupType = 'type',
}
