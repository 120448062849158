import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { getOrganizationUserForId } from 'state/entities/selectors/user';

import './pop_up_for_user_avatar.scss';

interface PopupForAvatarProps {
  children: ReactNode;
}
interface PopUpForAvatarOrgUserId extends PopupForAvatarProps {
  orgUserId: number;
  orgUserName?: never;
}
interface PopUpForAvatarOrgUserName extends PopupForAvatarProps {
  orgUserId?: never;
  orgUserName: string;
}

const PopUpForUserAvatar = ({
  orgUserName = '',
  orgUserId = 0,
  children,
}: PopUpForAvatarOrgUserId | PopUpForAvatarOrgUserName) => {
  const content = useSelector((state) => orgUserName || getOrganizationUserForId(state, orgUserId)?.username);

  return (
    <Popup
      hoverable
      position="bottom center"
      trigger={<span className="popup_user_avatar_wrapper">{children}</span>}
      content={content}
      basic
      size="mini"
    />
  );
};

export default PopUpForUserAvatar;
