import { Group } from 'daos/model_types';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { createBody, updateBody, deleteBody, resource } = defineModel({
  apiType: 'groups',
  relationships: ['workspaceUserGroups'],
  type: 'GROUP',
});

const { GROUP, resourceId } = resource;

interface GroupIncludes {
  includeWorkspaceUserGroups?: boolean;
  includeWorkspaceUserGroupsUsers?: boolean;
  includeItemWorkGroups?: boolean;
  includeItemWorkGroupsItems?: boolean;
  includeItemWorkGroupItemMetrics?: boolean;
}

const includes = ({
  includeWorkspaceUserGroups,
  includeWorkspaceUserGroupsUsers,
  includeItemWorkGroups,
  includeItemWorkGroupsItems,
  includeItemWorkGroupItemMetrics,
}: GroupIncludes = {}) =>
  reduceIncludedOptions([
    includeWorkspaceUserGroups && 'workspaceUserGroups',
    includeWorkspaceUserGroupsUsers && 'workspaceUserGroups.workspaceUser.user',
    includeItemWorkGroups && 'itemWorkGroups',
    includeItemWorkGroupsItems && 'itemWorkGroups.items',
    includeItemWorkGroupItemMetrics && 'itemWorkGroups.items.itemMetrics',
  ]);

interface GroupOptions {
  filter?: string;
  include?: GroupIncludes;
}

interface GroupsParams {
  organizationId: number;
  workspaceId: number;
}

interface TeamsParams {
  groups: ReadonlyArray<TeamParams>;
}

interface TeamParams {
  groupId: number;
  name: string | undefined;
}

interface SingleGroupParams extends GroupsParams {
  groupId: number;
}

interface GroupsAvailabilityOptions {
  filter?: string;
  query: { startDate: string; endDate: string };
}

const groupCreate = (params: GroupsParams, group: Partial<Group>) =>
  request(backend.groups.url(params), GROUP, createBody(group));

const groupDestroy = (params: SingleGroupParams, groupId: number) =>
  request(backend.group.url(params), GROUP, deleteBody(groupId));

const fetch = (params: SingleGroupParams, { include }: GroupOptions = {}) =>
  request(
    backend.group.url(params, {
      include: includes(include),
    }),
    GROUP,
    { method: HttpMethod.GET },
  );

const fetchAll = (params: GroupsParams, { include, filter }: GroupOptions = {}) =>
  request(
    backend.groups.url(params, {
      include: includes(include),
      filter,
    }),
    GROUP,
    { method: HttpMethod.GET },
  );

const groupUpdate = (params: SingleGroupParams, group: Partial<Group>) =>
  request(backend.group.url(params), GROUP, updateBody(params.groupId, group));

const teamsClone = (params: GroupsParams, teamsParams: TeamsParams, { include }: GroupOptions = {}) =>
  request(
    backend.groupsClone.url(params, {
      include: includes(include),
    }),
    GROUP,
    {
      body: JSON.stringify({ data: { groups: teamsParams.groups } }),
      method: HttpMethod.POST,
    },
  );

const teamsConvert = (params: SingleGroupParams, { include }: GroupOptions = {}) =>
  request(
    backend.groupsConvert.url(params, {
      include: includes(include),
    }),
    GROUP,
    {
      body: JSON.stringify({ data: { groupIds: [params.groupId] } }),
      method: HttpMethod.POST,
    },
  );

const fetchGroupsAvailability = (params: GroupsParams, { filter, query }: GroupsAvailabilityOptions) =>
  request(backend.groupsAvailability.url(params, { query, filter }), 'GROUPS_AVAILABILITY', {
    method: HttpMethod.GET,
  });

export const GroupDao = {
  create: groupCreate,
  destroy: groupDestroy,
  fetch,
  fetchAll,
  id: resourceId,
  update: groupUpdate,
  teamsClone,
  teamsConvert,
  fetchGroupsAvailability,
} as const;
