import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { ItemType, StatusFilterGroups, TaskStatusDropdownFilter } from 'daos/enums';
import { CustomTaskFilterModal } from 'features/common/custom_item_filter/custom_task_filter_modal';
import { useApplyTaskFilter } from 'features/common/custom_item_filter/hooks/use_apply_task_filter';
import {
  getCustomItemFilterAsapForLocation,
  getCustomItemFilterAtRiskForItemTypeAndLocation,
  getCustomItemFilterCreatedByUserIdForItemTypeAndLocation,
  getCustomItemFilterCustomFieldPropertiesForItemTypeAndLocation,
  getCustomItemFilterCustomTaskStatusFilterForLocation,
  getCustomItemFilterHasFilesForItemTypeAndLocation,
  getCustomItemFilterNameContainsForItemTypeAndLocation,
  getCustomItemFilterTaskStatusIdsForLocation,
  getCustomItemFilterWorkLimitRiskForItemTypeAndLocation,
} from 'features/common/custom_item_filter/selectors';
import { CustomItemFilterLocation, CustomTaskFilters } from 'features/common/custom_item_filter/types';
import {
  checkCircleSolid,
  circleSolid,
  dotCircleSolid,
  exclamationTriangleSolid,
  filterSolid,
  IconDefinition,
  LpIcon,
  pauseCircleSolid,
  plusCircleSolid,
  rectangleWideSolid,
} from 'features/common/lp_icon';
import { lpBrandWhite, lpRed, slate600 } from 'style/variables';

import './task_status_filter_dropdown.scss';

const TASK_AT_RISK = 'Tasks at Risk';

interface TaskStatusFilterDropdownOption {
  key: StatusFilterGroups;
  icon: IconDefinition;
  text: TaskStatusDropdownFilter | string;
  value: StatusFilterGroups;
  color: string;
  showWhenDataIsAllActive: boolean;
}

const options: Array<TaskStatusFilterDropdownOption> = [
  {
    key: StatusFilterGroups.All,
    icon: circleSolid,
    text: TaskStatusDropdownFilter.ALL,
    value: StatusFilterGroups.All,
    color: slate600,
    showWhenDataIsAllActive: false,
  },
  {
    key: StatusFilterGroups.ActiveAndOnHold,
    icon: plusCircleSolid,
    text: TaskStatusDropdownFilter.ACTIVE,
    value: StatusFilterGroups.ActiveAndOnHold,
    color: slate600,
    showWhenDataIsAllActive: false,
  },
  {
    key: StatusFilterGroups.Active,
    icon: dotCircleSolid,
    text: TaskStatusDropdownFilter.SCHEDULED,
    value: StatusFilterGroups.Active,
    color: slate600,
    showWhenDataIsAllActive: true,
  },
  {
    key: StatusFilterGroups.OnHold,
    icon: pauseCircleSolid,
    text: TaskStatusDropdownFilter.ON_HOLD,
    value: StatusFilterGroups.OnHold,
    color: slate600,
    showWhenDataIsAllActive: false,
  },
  {
    key: StatusFilterGroups.Done,
    icon: checkCircleSolid,
    text: TaskStatusDropdownFilter.DONE,
    value: StatusFilterGroups.Done,
    color: slate600,
    showWhenDataIsAllActive: false,
  },
  {
    key: StatusFilterGroups.atRisk,
    icon: exclamationTriangleSolid,
    text: TASK_AT_RISK,
    value: StatusFilterGroups.atRisk,
    color: lpRed,
    showWhenDataIsAllActive: true,
  },
  {
    key: StatusFilterGroups.Asap,
    icon: rectangleWideSolid,
    text: TaskStatusDropdownFilter.ASAP,
    value: StatusFilterGroups.Asap,
    color: slate600,
    showWhenDataIsAllActive: true,
  },
  {
    key: StatusFilterGroups.Custom,
    icon: filterSolid,
    text: TaskStatusDropdownFilter.CUSTOM,
    value: StatusFilterGroups.Custom,
    color: slate600,
    showWhenDataIsAllActive: true,
  },
];

export const ControlledTaskStatusFilterDropdown = ({
  className,
  dataIsAllActive = false,
  defaultTaskStatus = StatusFilterGroups.All,
  disabled,
  onChange,
  value,
}: {
  className?: string;
  dataIsAllActive?: boolean;
  defaultTaskStatus?: StatusFilterGroups;
  disabled?: boolean;
  onChange: (value: CustomTaskFilters) => void;
  value: CustomTaskFilters;
}) => {
  const [customItemFilterOpen, setCustomItemFilterOpen] = useState(false);
  const onOpenCustomItemFilterModal = () => setCustomItemFilterOpen(true);
  const closeCustomItemFilterModal = () => setCustomItemFilterOpen(false);
  const currentTaskStatus = getStatusFilterGroup(value);

  const handleModalChange = (newValue: CustomTaskFilters) => {
    onChange(newValue);
    closeCustomItemFilterModal();
  };

  const handleDropdownChange = (
    _: React.SyntheticEvent<HTMLDivElement>,
    { value: taskStatusFilter }: DropdownItemProps,
  ) => {
    if (taskStatusFilter === StatusFilterGroups.Custom) {
      onOpenCustomItemFilterModal();
    } else {
      onChange({
        taskCreatedByUserId: null,
        taskCustomFieldFilters: [],
        taskNameFilter: '',
        taskScheduleDirectiveAsapOnly: taskStatusFilter === StatusFilterGroups.Asap,
        taskScheduleRiskOnly: taskStatusFilter === StatusFilterGroups.atRisk,
        taskWorkLimitRiskOnly: taskStatusFilter === StatusFilterGroups.atRisk,
        taskStatusFilter:
          taskStatusFilter === StatusFilterGroups.Asap || taskStatusFilter === StatusFilterGroups.atRisk
            ? StatusFilterGroups.All
            : (taskStatusFilter as StatusFilterGroups),
        taskStatusIdsFilter: [],
        statusFilterSelection: taskStatusFilter as StatusFilterGroups,
      });
    }
  };

  const availableOptionFilter = useCallback(
    (option: TaskStatusFilterDropdownOption) => !dataIsAllActive || option.showWhenDataIsAllActive,
    [dataIsAllActive],
  );

  const getDefaultOption = useCallback(() => {
    const result = options.filter(availableOptionFilter).find((option) => option.value === defaultTaskStatus);

    if (result) {
      return result;
    }

    throw new Error(
      `Task status filter dropdown configured with a default task status of '${defaultTaskStatus}' that matches no menu entries.)`,
    );
  }, [availableOptionFilter, defaultTaskStatus]);

  const getSelectedOption = useCallback(() => {
    return (
      options.filter(availableOptionFilter).find((option) => option.value === currentTaskStatus) ?? getDefaultOption()
    );
  }, [availableOptionFilter, currentTaskStatus, getDefaultOption]);

  const dropdownOptions = options.filter(availableOptionFilter).map((option) => {
    return (
      <Dropdown.Item
        onClick={handleDropdownChange}
        key={option.key}
        text={
          <span style={{ pointerEvents: 'none' }}>
            <LpIcon className="icon" color={option.color} icon={option.icon as IconDefinition} /> {option.text}
          </span>
        }
        value={option.value}
      />
    );
  });

  const dropdownDisplay = useMemo(() => {
    const option = getSelectedOption();
    const isDefault = option === getDefaultOption();

    const iconColor = isDefault ? slate600 : lpBrandWhite;

    return (
      <span>
        <LpIcon className="icon" color={iconColor} icon={option.icon} /> {option.text}
      </span>
    );
  }, [getSelectedOption, getDefaultOption]);

  return (
    <>
      {customItemFilterOpen && (
        <CustomTaskFilterModal onApply={handleModalChange} onCancel={closeCustomItemFilterModal} value={value} />
      )}
      <Dropdown
        button
        direction="left"
        className={classNames(className, 'task-status-filter-dropdown', {
          'task-status-filter-dropdown--filtered': getSelectedOption() !== getDefaultOption(),
        })}
        disabled={disabled}
        selectOnNavigation={false}
        value={currentTaskStatus}
        trigger={dropdownDisplay}
      >
        <Dropdown.Menu>{dropdownOptions}</Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export const TaskStatusFilterDropdown = ({
  customItemFilterLocation,
  className,
  dataIsAllActive = false,
  defaultTaskStatus = StatusFilterGroups.All,
  disabled = false,
}: {
  customItemFilterLocation: CustomItemFilterLocation;
  className?: string;
  dataIsAllActive?: boolean;
  defaultTaskStatus?: StatusFilterGroups;
  disabled?: boolean;
}) => {
  const itemType = ItemType.TASKS;
  const applyFilter = useApplyTaskFilter(customItemFilterLocation);

  const taskStatusFilter: StatusFilterGroups = useSelector((state) =>
    getCustomItemFilterCustomTaskStatusFilterForLocation(state, customItemFilterLocation),
  );

  const taskStatusIds = useSelector((state) =>
    getCustomItemFilterTaskStatusIdsForLocation(state, customItemFilterLocation),
  );

  const asap = useSelector((state) => getCustomItemFilterAsapForLocation(state, customItemFilterLocation));

  const atRisk = useSelector((state) =>
    getCustomItemFilterAtRiskForItemTypeAndLocation(state, itemType, customItemFilterLocation),
  );

  const workLimitRisk = useSelector((state) =>
    getCustomItemFilterWorkLimitRiskForItemTypeAndLocation(state, itemType, customItemFilterLocation),
  );

  const nameContainsValue = useSelector((state) =>
    getCustomItemFilterNameContainsForItemTypeAndLocation(state, itemType, customItemFilterLocation),
  );

  const createdByUserId = useSelector((state) =>
    getCustomItemFilterCreatedByUserIdForItemTypeAndLocation(state, itemType, customItemFilterLocation),
  );

  const otherCustomFieldProperties = useSelector((state) =>
    getCustomItemFilterCustomFieldPropertiesForItemTypeAndLocation(state, itemType, customItemFilterLocation),
  );

  const taskHasFileFilter = useSelector((state) =>
    getCustomItemFilterHasFilesForItemTypeAndLocation(state, itemType, customItemFilterLocation),
  );

  const valuesForControlledComponents = {
    taskCreatedByUserId: createdByUserId,
    taskCustomFieldFilters: otherCustomFieldProperties ?? [],
    taskNameFilter: nameContainsValue ?? '',
    taskScheduleDirectiveAsapOnly: asap,
    taskScheduleRiskOnly: atRisk,
    taskWorkLimitRiskOnly: workLimitRisk,
    taskStatusFilter,
    taskStatusIdsFilter: taskStatusIds ?? [],
    taskHasFileFilter,
  };

  const handleApplyCustomFilters = useCallback(
    (newValue: CustomTaskFilters) => {
      applyFilter({
        createdByUserId: newValue.taskCreatedByUserId ?? 0,
        otherCustomFieldProperties: newValue.taskCustomFieldFilters ?? [],
        nameContainsValue: newValue.taskNameFilter ?? '',
        selectedAsap: newValue.taskScheduleDirectiveAsapOnly ?? false,
        selectedHasFiles: newValue.taskHasFileFilter ?? false,
        selectedAtRisk: newValue.taskScheduleRiskOnly ?? false,
        selectedTaskStatusFilter: newValue.taskStatusFilter ?? defaultTaskStatus,
        selectedWorkLimitRisk: newValue.taskWorkLimitRiskOnly ?? false,
        selectedTaskStatusIds: newValue.taskStatusIdsFilter ?? [],
        statusFilterSelection: newValue.statusFilterSelection
          ? (newValue.statusFilterSelection as unknown as StatusFilterGroups)
          : StatusFilterGroups.Custom,
      });
    },
    [applyFilter, defaultTaskStatus],
  );

  return (
    <ControlledTaskStatusFilterDropdown
      className={className}
      dataIsAllActive={dataIsAllActive}
      defaultTaskStatus={defaultTaskStatus}
      disabled={disabled}
      onChange={handleApplyCustomFilters}
      value={valuesForControlledComponents}
    />
  );
};

function getStatusFilterGroup({
  taskCreatedByUserId,
  taskNameFilter,
  taskStatusFilter,
  taskStatusIdsFilter,
  taskScheduleDirectiveAsapOnly,
  taskScheduleRiskOnly,
  taskWorkLimitRiskOnly,
  taskHasFileFilter,
  taskCustomFieldFilters,
}: CustomTaskFilters) {
  if (taskScheduleRiskOnly && taskWorkLimitRiskOnly) {
    return StatusFilterGroups.atRisk;
  }

  if (
    taskCreatedByUserId ||
    taskNameFilter ||
    taskCustomFieldFilters?.length ||
    (taskScheduleDirectiveAsapOnly && taskScheduleRiskOnly) ||
    taskWorkLimitRiskOnly ||
    taskScheduleRiskOnly ||
    taskHasFileFilter ||
    (taskScheduleDirectiveAsapOnly && taskStatusFilter !== StatusFilterGroups.All) ||
    taskStatusIdsFilter?.length
  ) {
    return StatusFilterGroups.Custom;
  } else if (taskScheduleDirectiveAsapOnly) {
    return StatusFilterGroups.Asap;
  } else if (taskStatusFilter === StatusFilterGroups.Custom) {
    // If none of the above conditions are true, then this isn't a custom
    // status filter
    return StatusFilterGroups.All;
  } else {
    return taskStatusFilter ?? StatusFilterGroups.All;
  }
}
