import { useFormikContext } from 'formik';
import { SyntheticEvent } from 'react';
import { Dropdown, DropdownItemProps, Table } from 'semantic-ui-react';

import { StoryPointSchemesWithStoryPoints } from 'features/administration/settings/settings_page/story_point_schemes/type';
import { LpIcon, questionCircleSolid } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import {
  getCopySchemeName,
  getSchemeFromId,
  getSchemeSelectionDropdownOptions,
  getIsValidSchemeForId,
} from 'features/jira_project/modal/sections/estimate_mapping_section/helpers';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  SchemeDisplayNames,
} from 'features/jira_project/modal/types';
import { convertStoryPointsToString } from 'features/jira_project/modal/utils';
import { gray600 } from 'style/variables';

export const SelectSchemeRow = ({
  schemes,
  selectedSchemeActionDisplayName,
}: {
  schemes: ReadonlyArray<StoryPointSchemesWithStoryPoints>;
  selectedSchemeActionDisplayName: SchemeDisplayNames;
}) => {
  const { setFieldError, setFieldValue, errors, values } = useFormikContext<JiraProjectModalFormValues>();
  const options = getSchemeSelectionDropdownOptions(schemes);
  const error =
    selectedSchemeActionDisplayName === SchemeDisplayNames.CopyExistingScheme &&
    errors[JiraProjectModalFormFields.SelectedExistingSchemeToCopy];
  const selectedExistingSchemeToCopyValue = values[JiraProjectModalFormFields.SelectedExistingSchemeToCopy];
  const selectedExistingSchemeToCopy = getSchemeFromId(schemes, selectedExistingSchemeToCopyValue ?? 0);

  if (options.length === 0) {
    return null;
  }

  const handleSelectedSchemeChange = (_: SyntheticEvent, { value }: DropdownItemProps) => {
    const selectedOption = getSchemeFromId(schemes, value as number);
    const selectedSchemeValue = getCopySchemeName(selectedOption);
    setFieldError(JiraProjectModalFormFields.SelectedExistingSchemeToCopy, undefined);
    setFieldError(JiraProjectModalFormFields.StoryPointSchemeName, undefined);
    setFieldError(JiraProjectModalFormFields.StoryPoints, undefined);

    setFieldValue(JiraProjectModalFormFields.SelectedExistingSchemeToCopy, value);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, selectedSchemeValue);
    setFieldValue(
      JiraProjectModalFormFields.StoryPoints,
      convertStoryPointsToString(selectedOption?.storyPoints ?? []),
    );
  };

  const helpText = (
    <div className="jira-project-modal__content-estimate-mapping-dropdown-help-text">
      Select a pre-existing story point scheme. Only properly configured schemes are available for copying; disabled
      schemes are unavailable due to misconfiguration or invalid setup.
    </div>
  );

  const selectSchemeTitle = (
    <>
      Select Scheme
      <LpPopUp placement="top" content={helpText} trigger={<LpIcon color={gray600} icon={questionCircleSolid} />} />
    </>
  );

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={selectSchemeTitle} />
      <Table.Cell className="jira-project-modal__content-estimate-mapping-dropdown">
        <Dropdown
          className="selection"
          text={selectedExistingSchemeToCopy?.schemeName ?? 'Select'}
          defaultValue={selectedExistingSchemeToCopyValue ?? 'Select'}
          name={JiraProjectModalFormFields.SelectedExistingSchemeToCopy}
          error={!!error}
        >
          <Dropdown.Menu>
            {options.map((option) => (
              <Dropdown.Item
                key={option.key}
                text={option.text}
                value={option.value}
                onClick={handleSelectedSchemeChange}
                disabled={!getIsValidSchemeForId(schemes, option.value)}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </JiraProjectModalTableRow>
  );
};
