import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Message } from 'semantic-ui-react';

import { NexusSamlConfigStatus, NexusSamlSetupLink, OrganizationSsoSettingsDao } from 'daos/organization_sso_settings';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { setApiError } from 'features/errors/slice';
import { awaitRequestFinish } from 'lib/api';

export const NexusSamlSetup = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);

  const [configStatus, setConfigStatus] = useState<NexusSamlConfigStatus>();

  const navigateToNexusSamlConfigLink = useCallback(() => {
    const { uuid } = dispatch(OrganizationSsoSettingsDao.createNexusSamlSetupLink({ organizationId }));

    dispatch(
      awaitRequestFinish<NexusSamlSetupLink>(uuid, {
        onSuccess: (response) => {
          const { samlSetupLink } = response.data;
          if (samlSetupLink) {
            window.open(samlSetupLink, '_blank');
          }
        },

        onError: ({ errors }) => {
          const error = errors[0];
          if (error) {
            dispatch(setApiError(error));
          }
        },
      }),
    );
  }, [dispatch, organizationId]);

  useEffect(() => {
    const { uuid } = dispatch(OrganizationSsoSettingsDao.fetchNexusSamlConfigStatus({ organizationId }));
    dispatch(
      awaitRequestFinish<NexusSamlConfigStatus>(uuid, {
        onSuccess: (response) => {
          setConfigStatus(response.data);
        },

        onError: ({ errors }) => {
          const error = errors[0];
          if (error) {
            dispatch(setApiError(error));
          }
        },
      }),
    );
  }, [dispatch, organizationId]);

  return (
    (configStatus && (
      <div className={'single-sign-on-form'}>
        {configStatus.canSetupSaml ? (
          <Button onClick={navigateToNexusSamlConfigLink} disabled={!configStatus.canSetupSaml}>
            {configStatus.isSamlConfigured
              ? 'Reconfigure SSO'
              : 'Complete SAML setup in our new authentication provider'}
          </Button>
        ) : (
          <Message>
            <Message.Header>This function is currently disabled</Message.Header>
          </Message>
        )}
      </div>
    )) ?? <div></div>
  );
};
