import { useFormikContext } from 'formik';
import { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, Dropdown, DropdownProps } from 'semantic-ui-react';

import { getSortColumnId } from 'containers/shared/custom_column/display_helpers';
import { Columns, StandardColumns } from 'containers/shared/custom_column/enum';
import { ListWidgetType } from 'daos/enums';
import { WidgetConfig } from 'daos/types';
import { PropsWidgetColumn, SortDirection, WidgetConfigColumn } from 'daos/widget_column';
import { WidgetGridColumnSelectModal } from 'features/common/data_grid/column_selector_modals';
import { getFilteredOptionsOnPlannedEstimate } from 'features/common/data_grid/helpers';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { arrowDownWideShortSolid, arrowUpShortWideSolid, lineColumnsLight, LpIcon } from 'features/common/lp_icon';
import { SelectionList } from 'features/common/selection_list/types';
import { getPropsWidgetColumn, mapSelectionListToColumns } from 'features/dashboards_v2/widget/common/columns';
import { useWidgetColumns } from 'features/dashboards_v2/widget_settings/use_widget_columns';
import { useHasTeam25TeamBasedPlanning } from 'hooks/use_has_feature';
import { WIDGET_ORDER_DEFAULT, HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { numberFromString } from 'lib/helpers';

import { WidgetSettingsV2Field } from './field';
import { sortableColumnOptions } from './helpers';

import './index.scss';

function compareColumnOrFieldId(
  currentColumn: SelectionList,
  sortedCustomFieldId: number | null,

  // Investigate: Why are there FrontendColumnOptions in this list if these icons are only
  // used in the properties_summary widget? (...which supports only server-side columns)
  sortedColumn: Columns | null,
) {
  const { column, customFieldId } = getPropsWidgetColumn(currentColumn.id as StandardColumns);

  if (column === StandardColumns.CustomField) {
    return customFieldId === sortedCustomFieldId;
  } else {
    return column === sortedColumn;
  }
}

const listTypeKey: keyof WidgetConfig = 'listType';
const columnsKey: keyof WidgetConfig = 'columns';
const showAlertIconsKey: keyof WidgetConfig = 'showAlertIcons';

export const PreviewColumns = ({
  allGridColumns,
  defaultColumns,
}: {
  allGridColumns: ReadonlyArray<SelectionList>;
  defaultColumns: ReadonlyArray<PropsWidgetColumn>;
}) => {
  const { setFieldValue, getFieldProps } = useFormikContext<WidgetConfig>();
  const columns = getFieldProps<ReadonlyArray<WidgetConfigColumn> | null>(columnsKey).value ?? [];
  const listType = getFieldProps<ListWidgetType | null>(listTypeKey).value;
  const showAlertIcons = getFieldProps<boolean>(showAlertIconsKey).value;
  const hasTeam25TeamBasedPlanning = useHasTeam25TeamBasedPlanning();

  const initialSortedColumn = columns?.find((column) => column.direction !== null) ?? null;

  // Investigate: Why are there FrontendColumnOptions in this list if these icons are only
  // used in the properties_summary widget? (...which supports only server-side columns)
  const [sortedColumn, setSortedColumn] = useState<Columns | null>(initialSortedColumn?.column ?? null);
  const [sortedCustomFieldId, setSortedCustomFieldId] = useState<number | null>(
    initialSortedColumn?.customFieldId ?? null,
  );
  const [sortedColumnDirection, setSortedColumnDirection] = useState<SortDirection | null>(
    initialSortedColumn?.direction ?? null,
  );

  function updateSelectedOptions(updatedColumns: Array<SelectionList>) {
    const isSortedColumnStillSelected = updatedColumns.some((currentColumn) => {
      return compareColumnOrFieldId(currentColumn, sortedCustomFieldId, sortedColumn);
    });

    if (!isSortedColumnStillSelected) {
      setSortedColumn(null);
      setSortedColumnDirection(null);
    }

    const parsedSortedColumn = sortedColumn ?? numberFromString(sortedColumn ?? '');
    setFieldValue(columnsKey, mapSelectionListToColumns(updatedColumns, parsedSortedColumn, sortedColumnDirection));
  }

  const {
    openSelectColumnModal,
    columnModalOpen,
    setColumnModalOpen,
    initialColumns,
    columnsSelected,
    setColumnsSelected,
  } = useWidgetColumns({
    defaultColumns,
    columns,
  });

  function onColumnSortChange(_: SyntheticEvent, data: DropdownProps) {
    if (data?.value?.toString() === WIDGET_ORDER_DEFAULT) {
      setSortedColumn(null);
      setSortedCustomFieldId(null);
      setSortedColumnDirection(null);
      setFieldValue(columnsKey, mapSelectionListToColumns(columnsSelected, null, null));
      return;
    }

    // Intentionally using StandardColumnOptions for these because widget columns must correspond with the server-side enum
    const { column, customFieldId } = getPropsWidgetColumn(data?.value as StandardColumns);

    setSortedColumn(column);
    setSortedCustomFieldId(customFieldId);

    if (!data.value) {
      setSortedColumnDirection(null);
    }

    if (data.value && !sortedColumnDirection) {
      setSortedColumnDirection(SortDirection.Descending);
    }

    const sortId = getSortColumnId(column, customFieldId);
    setFieldValue(
      columnsKey,
      mapSelectionListToColumns(columnsSelected, sortId, sortedColumnDirection || SortDirection.Descending),
    );
  }

  const sortableColumns = sortableColumnOptions(columnsSelected, listType);

  function onSortChange(direction: SortDirection) {
    setSortedColumnDirection(direction);
    const sortId = getSortColumnId(sortedColumn, sortedCustomFieldId);
    setFieldValue(columnsKey, mapSelectionListToColumns(columnsSelected, sortId, direction));
  }

  const sortDropdownValue =
    sortedColumn === StandardColumns.CustomField
      ? (sortedCustomFieldId?.toString() ?? WIDGET_ORDER_DEFAULT)
      : (sortedColumn ?? WIDGET_ORDER_DEFAULT);

  function handleExcludeAlertIcon() {
    setFieldValue(showAlertIconsKey, !showAlertIcons);
  }

  const allFeatureAwareColumns = getFilteredOptionsOnPlannedEstimate(allGridColumns, hasTeam25TeamBasedPlanning);
  const initialFeatureAwareColumns = getFilteredOptionsOnPlannedEstimate(initialColumns, hasTeam25TeamBasedPlanning);
  const selectedFeatureAwareColumns = getFilteredOptionsOnPlannedEstimate(columnsSelected, hasTeam25TeamBasedPlanning);

  return (
    <>
      <WidgetSettingsV2Field name="Table">
        <div>
          <Button
            type="button"
            className="v2-widget-settings-button"
            onClick={openSelectColumnModal}
            icon={<LpIcon icon={lineColumnsLight} />}
            content={`Data Columns${HORIZONTAL_ELLIPSIS}`}
          />

          <Checkbox
            className="v2-widget-settings-checkbox"
            label="Include Alert Icons"
            checked={showAlertIcons}
            onChange={handleExcludeAlertIcon}
          />
        </div>

        {columnModalOpen && (
          <WidgetGridColumnSelectModal
            allGridColumns={allFeatureAwareColumns}
            onClose={() => setColumnModalOpen(false)}
            initialGridColumns={initialFeatureAwareColumns}
            selectedGridColumns={selectedFeatureAwareColumns}
            setWidgetColumnsSelected={setColumnsSelected}
            updateSelectedOptions={updateSelectedOptions}
          />
        )}
      </WidgetSettingsV2Field>

      <WidgetSettingsV2Field name="Sort By">
        <div className="base-v2-widget-settings__content-options-field-column-sort">
          <Dropdown
            className="base-v2-widget-settings__content-options-field-column-sort-dropdown"
            onChange={onColumnSortChange}
            options={sortableColumns}
            placeholder={`Select a column to sort by${HORIZONTAL_ELLIPSIS}`}
            search={lpDropdownSearch}
            selection
            selectOnNavigation={false}
            value={sortDropdownValue}
          />

          {sortedColumn && (
            <>
              <Button
                type="button"
                primary={sortedColumnDirection === SortDirection.Descending}
                className="base-v2-widget-settings__content-options-field-column-sort-button"
                onClick={() => onSortChange(SortDirection.Descending)}
                icon={<LpIcon icon={arrowDownWideShortSolid} />}
              />

              <Button
                type="button"
                primary={sortedColumnDirection === SortDirection.Ascending}
                className="base-v2-widget-settings__content-options-field-column-sort-button"
                onClick={() => onSortChange(SortDirection.Ascending)}
                icon={<LpIcon icon={arrowUpShortWideSolid} />}
              />
            </>
          )}
        </div>
      </WidgetSettingsV2Field>
    </>
  );
};
