import { SortColumn, SortDirection } from 'react-data-grid';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { ListWidgetType, OrganizationUserOrderBy, WidgetType } from 'daos/enums';
import { OrderItemFetch } from 'daos/item_enums';
import { WidgetConfigColumn } from 'daos/widget_column';
import { WidgetDao } from 'daos/widgets';
import { SelectionList } from 'features/common/selection_list/types';
import { booleanCompare } from 'lib/helpers';

import { ClientSideColumnSort } from './enums';
import { EntityColumn, EntityColumns, EntityRows } from './types';

export const clientSideSortByTypeAndDirection = <R, S>(
  rows: EntityRows<R, S>,
  sortColumn: keyof R,
  sortDirection: SortDirection,
  sortType: ClientSideColumnSort | undefined,
) => {
  const isAscending = sortDirection === 'ASC';

  switch (sortType) {
    case ClientSideColumnSort.Boolean:
      return [...rows].sort((a, b) => {
        const cellA = a[sortColumn] as boolean;
        const cellB = b[sortColumn] as boolean;

        return isAscending ? booleanCompare(cellA, cellB) : booleanCompare(cellB, cellA);
      });

    case ClientSideColumnSort.Number:
      return [...rows].sort((a, b) => {
        const cellA = a[sortColumn] as number;
        const cellB = b[sortColumn] as number;

        return isAscending ? cellA - cellB : cellB - cellA;
      });

    case ClientSideColumnSort.String: {
      return [...rows].sort((a, b) => {
        const cellA = a[sortColumn] as string;
        const cellB = b[sortColumn] as string;

        return isAscending ? cellA.localeCompare(cellB) : cellB.localeCompare(cellA);
      });
    }

    default:
      return rows;
  }
};

export const getSelectedGridColumns = <R, S>(
  gridColumns: EntityColumns<R, S>,
  hiddenGridColumns: ReadonlyArray<string>,
  selectedGridColumns: ReadonlyArray<SelectionList>,
) => {
  const selected: Array<EntityColumn<R, S>> = [];
  const selectedCheckBoxColumn = gridColumns[0]; // adding the selection checkbox column
  const nameColumn = gridColumns[2]; // adding the name column

  if (selectedCheckBoxColumn) {
    selected.push(selectedCheckBoxColumn);
  }
  if (nameColumn) {
    selected.push(nameColumn);
  }

  selectedGridColumns.forEach((column) => {
    const matchColumnKey = (col: EntityColumn<R, S>) => col.key === column.id;

    const columnIdx = gridColumns.findIndex(matchColumnKey);
    const matchingColumn = gridColumns[columnIdx];

    if (matchingColumn && columnIdx !== -1) {
      selected.push(matchingColumn);
    }
  });

  const finalColumnArrangement = selected.filter((column) => {
    return !hiddenGridColumns.includes(column.key);
  });

  return finalColumnArrangement;
};

const getSortOrderQuery = (sortColumn: SortColumn, fallbackSort: string) => {
  const { direction, columnKey } = sortColumn;
  const directionAndSecondaryOrder = `${direction.toLocaleLowerCase()},${fallbackSort}`;

  const isCustomFieldKey = !!Number(columnKey);
  if (isCustomFieldKey) {
    return `customField(${Number(columnKey)}) ${directionAndSecondaryOrder}`;
  }

  return `${columnKey} ${directionAndSecondaryOrder}`;
};

export const getItemSortOrderQuery = (sortColumn: SortColumn | undefined) => {
  const fallbackSort = OrderItemFetch.AncestorPackageStatusThenGlobalPriority;

  if (!sortColumn) {
    return fallbackSort;
  }

  return getSortOrderQuery(sortColumn, fallbackSort);
};

export const getPeopleSortOrderQuery = (sortColumn: SortColumn | undefined) => {
  const fallbackSort = OrganizationUserOrderBy.Username;

  if (!sortColumn) {
    return fallbackSort;
  }

  return getSortOrderQuery(sortColumn, fallbackSort);
};

export const getFilteredOptionsOnPlannedEstimate = (
  options: Array<SelectionList> | ReadonlyArray<SelectionList>,
  hasTeamBasedPlanning: boolean,
) => {
  return hasTeamBasedPlanning ? options : options.filter((column) => column.id !== StandardColumns.PlannedEstimate);
};

export const getFilteredWidgetColumnsOnPlannedEstimate = (
  options: ReadonlyArray<WidgetConfigColumn>,
  hasTeamBasedPlanning: boolean,
) => {
  return hasTeamBasedPlanning ? options : options.filter((row) => row.column !== StandardColumns.PlannedEstimate);
};

export function getWidgetExportUrl(widgetType: WidgetType, widgetListType: ListWidgetType | null) {
  switch (widgetListType) {
    case ListWidgetType.Assignment:
    case ListWidgetType.Tasks:
    case ListWidgetType.Folders:
    case ListWidgetType.Projects:
    case ListWidgetType.Packages: {
      if (widgetType === WidgetType.TableReport) {
        return WidgetDao.exportReportXlsxUrl;
      }

      return WidgetDao.exportXlsxURL;
    }

    case ListWidgetType.Users: {
      if (widgetType === WidgetType.TableReport) {
        return WidgetDao.exportPeopleReportXlsxUrl;
      }

      return WidgetDao.exportPeopleListXlsxURL;
    }

    default:
      return WidgetDao.exportXlsxURL;
  }
}
