import { ChangeModelType, ChangeActionType } from 'daos/enums';

const descriptionComesFirstType = [ChangeModelType.Items, ChangeModelType.ItemFiles];

export function itemDescriptionComesFirst({
  changeRecordType,
  changeActionType,
}: {
  changeRecordType: ChangeModelType;
  changeActionType: ChangeActionType;
}) {
  if (changeActionType === ChangeActionType.DELETE && changeRecordType === ChangeModelType.Items) {
    return true;
  }

  return descriptionComesFirstType.includes(changeRecordType) && changeActionType === ChangeActionType.UPDATE;
}

const fieldValueChangeDescriber = (fieldName: string, changeAction: ChangeActionType) => {
  switch (changeAction) {
    case ChangeActionType.INSERT:
      return `${fieldName} added to`;
    case ChangeActionType.UPDATE:
      return `${fieldName} was updated on`;
    case ChangeActionType.DELETE:
      return `${fieldName} was removed from`;
  }
};

const itemChangeDescriber = {
  [ChangeActionType.INSERT]: 'CREATED',
  [ChangeActionType.UPDATE]: 'updated',
  [ChangeActionType.DELETE]: 'deleted',
};

const dependencyChangeDescriber = {
  [ChangeActionType.INSERT]: 'A predecessor was added for',
  [ChangeActionType.UPDATE]: 'A predecessor was updated for',
  [ChangeActionType.DELETE]: 'A predecessor was removed for',
};

const fileChangeDescriber = {
  [ChangeActionType.INSERT]: 'uploaded file to',
  [ChangeActionType.UPDATE]: 'uploaded file to',
  [ChangeActionType.DELETE]: 'removed file from',
};

const groupChangeDescriber = {
  [ChangeActionType.INSERT]: 'created group',
  [ChangeActionType.UPDATE]: 'updated group',
  [ChangeActionType.DELETE]: 'deleted group',
};

const itemAclChangeDescriber = {
  [ChangeActionType.INSERT]: 'Access was granted to',
  [ChangeActionType.UPDATE]: 'Access for updated on',
  [ChangeActionType.DELETE]: 'Access was revoked to',
};

const timesheetEntryChangeDescriber = {
  [ChangeActionType.INSERT]: 'Time was logged on',
  [ChangeActionType.UPDATE]: 'Time updated on',
  [ChangeActionType.DELETE]: 'Time updated on',
};

const schedulingLimitsChangeDescriber = {
  [ChangeActionType.INSERT]: 'A scheduling limit was created on',
  [ChangeActionType.UPDATE]: 'Scheduling limit was updated on',
  [ChangeActionType.DELETE]: 'Scheduling limit was removed on',
};

const itemFilesChangeDescriber = {
  [ChangeActionType.INSERT]: 'A File was uploaded on',
  [ChangeActionType.UPDATE]: 'A File was moved',
  [ChangeActionType.DELETE]: 'A File was removed from',
};

export const defaultChangeDescription = 'modified';

export const changeDescriber = (
  changeAction: ChangeActionType,
  changeRecordType: ChangeModelType,
  fieldName = 'custom data field',
): string => {
  switch (changeRecordType) {
    case ChangeModelType.Dependencies:
      return dependencyChangeDescriber[changeAction];
    case ChangeModelType.FieldValues:
      return fieldValueChangeDescriber(fieldName, changeAction);
    case ChangeModelType.Files:
      return fileChangeDescriber[changeAction];
    case ChangeModelType.Groups:
      return groupChangeDescriber[changeAction];
    case ChangeModelType.Items:
      return itemChangeDescriber[changeAction];
    case ChangeModelType.ItemAcls:
      return itemAclChangeDescriber[changeAction];
    case ChangeModelType.TimesheetEntries:
      return timesheetEntryChangeDescriber[changeAction];
    case ChangeModelType.SchedulingLimits:
      return schedulingLimitsChangeDescriber[changeAction];
    case ChangeModelType.ItemFiles:
      return itemFilesChangeDescriber[changeAction];
    default:
      return defaultChangeDescription;
  }
};
