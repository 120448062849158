/* eslint-disable check-file/filename-naming-convention */
import classNames from 'classnames';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Tab } from 'semantic-ui-react';

import { AcademyLessonType } from 'features/academy/daos/enums';
import { AcademyLesson } from 'features/academy/daos/model_types';
import { trackViewUrl } from 'features/academy/helpers';
import { WistiaVideo } from 'features/academy/modal/wistia_video';
import {
  getLessonForId,
  getTrackForId,
  getTrackLessonForTrackIdAndLessonId,
  getTrackLessonsSortedByPriorityForTrack,
} from 'features/academy/selectors';
import { getCurrentUserLessonProgressForLessonId } from 'features/academy/selectors/progress';
import { LpMenuItemAsLink } from 'features/common/as_components';
import { checkCircleSolid, LpIcon } from 'features/common/lp_icon';

interface TabCreationProps {
  tabComponent: ReactNode;
  menuItem: ReactNode;
  lessonId: string;
  active: boolean;
}

function createTab({ menuItem, tabComponent, lessonId, active }: TabCreationProps) {
  return {
    displayName: `lesson-${lessonId}`,
    menuItem: menuItem,
    render: () => {
      return (
        // render the pane as a div, otherwise semantic adds a border and margin/padding

        /* eslint-disable-next-line react/forbid-component-props */
        <Tab.Pane as="div" active={active} className="lp-academy-modal__content">
          {tabComponent}
        </Tab.Pane>
      );
    },
  };
}

const tabConfig = { fluid: true, vertical: true };

interface AcademyModalBodyParams {
  courseId: string;
  trackId: string;
  lessonId: string;
  autoPlay: boolean;
}

const AcademyModalBody = ({ courseId, trackId, lessonId, autoPlay }: AcademyModalBodyParams) => {
  const trackLessons = useSelector((state) => getTrackLessonsSortedByPriorityForTrack(state, trackId));
  const activeIndex = trackLessons.findIndex((trackLesson) => trackLesson.academyLessonId === lessonId);

  const panes = useMemo(
    () =>
      trackLessons.map((trackLesson, index) => {
        const active = activeIndex === index;

        return createTab({
          menuItem: (
            <AcademyTabMenuItem
              key={`lesson-${trackLesson.academyLessonId}`}
              courseId={courseId}
              trackId={trackId}
              lessonId={trackLesson.academyLessonId}
              active={active}
            />
          ),
          tabComponent: <AcademyTabContent lessonId={trackLesson.academyLessonId} autoPlay={autoPlay} />,
          lessonId: trackLesson.academyLessonId,
          active,
        });
      }),
    [trackLessons, activeIndex, courseId, trackId, autoPlay],
  );

  return <Tab activeIndex={activeIndex} panes={panes} menu={tabConfig} className="lp-academy-modal-sections" />;
};

interface AcademyTabMenuParams {
  courseId: string;
  trackId: string;
  lessonId: string;
  active: boolean;
}

const AcademyTabMenuItem = ({ courseId, trackId, lessonId, active }: AcademyTabMenuParams) => {
  const location = useLocation();
  const lesson = useSelector((state) => getLessonForId(state, lessonId));
  const track = useSelector((state) => getTrackForId(state, trackId));
  const lessonProgress = useSelector((state) => getCurrentUserLessonProgressForLessonId(state, lessonId));
  const trackLesson = useSelector((state) => getTrackLessonForTrackIdAndLessonId(state, { trackId, lessonId }));

  if (!lesson || !track || !trackLesson) {
    return null;
  }

  return (
    <LpMenuItemAsLink
      className="lp-academy-modal__menu-item"
      to={trackViewUrl({ courseId, trackId, lessonId, path: location.pathname })}
      active={active}
    >
      <span className="lp-academy-modal__menu-item-title">{lesson.title}</span>
      {lessonProgress && <LpIcon icon={checkCircleSolid} />}
    </LpMenuItemAsLink>
  );
};

const LessonPanelContent = ({ lesson, autoPlay }: { lesson: AcademyLesson; autoPlay: boolean }) => {
  if (lesson.academyLessonType === AcademyLessonType.WYSIWYG) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: lesson.content ?? '',
        }}
      />
    );
  }

  if (lesson.academyLessonType === AcademyLessonType.VideoOnly) {
    return <WistiaVideo key={lesson.videoUrlSlug} videoUrlSlug={lesson.videoUrlSlug} autoPlay={autoPlay} />;
  }

  return null;
};

interface AcademyTabContentProps {
  lessonId: string;
  autoPlay: boolean;
}

const AcademyTabContent = ({ lessonId, autoPlay }: AcademyTabContentProps) => {
  const lesson = useSelector((state) => getLessonForId(state, lessonId));
  const scrollingContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollingContainer.current) {
      return;
    }

    scrollingContainer.current.scrollTo(0, 0);
  }, [scrollingContainer, lessonId]);

  if (!lesson) {
    return null;
  }

  return (
    <div
      className={classNames('read-only-panel', {
        'read-only-panel--video': lesson.academyLessonType === AcademyLessonType.VideoOnly,
      })}
      ref={scrollingContainer}
    >
      <LessonPanelContent lesson={lesson} autoPlay={autoPlay} />
    </div>
  );
};

export default AcademyModalBody;
