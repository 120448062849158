import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { setApiError } from 'features/errors/slice';
import BeyondLimitModal from 'features/organization_directory/manage_account/beyond_limit_modal';
import { awaitRequestFinish } from 'lib/api';
import { ErrorCodes } from 'lib/api/types';
import { getOrganizationUserForId, getWorkspaceUserForOrganizationUserId } from 'state/entities/selectors/user';

interface ConvertGuestToPaidWorkspaceMemberProps {
  orgUserId: number;
}

export const ConvertGuestToPaidWorkspaceMember = ({ orgUserId }: ConvertGuestToPaidWorkspaceMemberProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const workspaceUserId = useSelector((state) => getWorkspaceUserForOrganizationUserId(state, orgUserId))?.id ?? 0;
  const organizationUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));

  const [showConfirm, setShowConfirm] = useState(false);
  const openConfirmModal = () => setShowConfirm(true);
  const closeConfirmModal = () => setShowConfirm(false);

  const [showBeyondLimitModal, setShowBeyondLimitModal] = useState(false);
  const openLimitModal = () => setShowBeyondLimitModal(true);
  const closeLimitModal = () => setShowBeyondLimitModal(false);

  if (!organizationUser) {
    return null;
  }

  const updateWorkspaceUser = () => {
    const { uuid } = dispatch(
      WorkspaceUserDao.update(
        { organizationId, workspaceId, workspaceUserId },
        { guestUser: false, disconnected: false },
      ),
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onError: ({ errors }) => {
          const error = errors?.[0];
          if (error) {
            if (error.code === ErrorCodes.LimitExceeded) {
              openLimitModal();
            } else {
              dispatch(setApiError(error));
            }
          }
        },
        onFinish: closeConfirmModal,
      }),
    );
  };

  const confirmMessage = getConfirmMessage(organizationUser.hasActiveWsNonGuestUser);

  return (
    <>
      <Button primary fluid content={'Upgrade to Member'} onClick={openConfirmModal} />
      {showBeyondLimitModal && <BeyondLimitModal onClose={closeLimitModal} />}
      {showConfirm && (
        <ConfirmConvertGuestModal
          message={confirmMessage}
          onClose={closeConfirmModal}
          onConfirm={updateWorkspaceUser}
        />
      )}
    </>
  );
};

function ConfirmConvertGuestModal({
  message,
  onClose,
  onConfirm,
}: {
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <BaseConfirmModal confirmButtonText="Confirm" content={message} onClose={onClose} onConfirm={onConfirm} primary />
  );
}

function getConfirmMessage(hasActiveWsNonGuestUser: boolean) {
  return hasActiveWsNonGuestUser
    ? 'Upgrading this guest will grant them full member access in this workspace.'
    : 'Upgrading this guest will grant them full member access in this workspace and consume a paid license.';
}
