import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import { ResetPasswordDao } from 'daos/reset_password';
import { ForgotPasswordErrors } from 'features/authentication/unauthenticated/forgot_password_v2/errors';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormInput from 'features/common/forms/lp_form_input';
import { awaitRequestFinish } from 'lib/api';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { frontend } from 'lib/urls';

const validationSchema = yup.object().shape({
  email: yup.string().trim().email(lpErrorText.email).required(lpErrorText.email),
});

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isSubmitting, status, setStatus, handleSubmit, getFieldMeta, getFieldProps, setSubmitting } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ email }) => {
      setStatus(undefined);

      const { uuid } = dispatch(ResetPasswordDao.forgotPassword({ email }));

      dispatch(
        awaitRequestFinish(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setStatus(errors[0]);
            }
            setSubmitting(false);
          },
          onSuccess: () => {
            history.push(frontend.login.url({}, { query: { reset: true } }));
          },
        }),
      );
    },
  });

  const handleDismissApiError = () => setStatus(undefined);

  return (
    <Form className="pm-forgot-password__form" loading={isSubmitting} onSubmit={handleSubmit}>
      {status && (
        <LpErrorMessage
          className="pm-forgot-password__api-error"
          error={status}
          customError={<ForgotPasswordErrors field={status.code} />}
          onDismiss={handleDismissApiError}
        />
      )}

      <LpFormInput
        className="pm-forgot-password__email"
        label="Email*"
        fieldKey="email"
        getFieldMeta={getFieldMeta}
        getFieldProps={getFieldProps}
        type="text"
        placeholder="Example@business.com"
      />

      <Button fluid primary content={'Send me a recovery Link →'} disabled={isSubmitting} type="submit" />
    </Form>
  );
};
