import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Input, InputOnChangeData } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { WidgetSize } from 'daos/enums';
import { WidgetDao } from 'daos/widgets';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import PortalActionDropdown from 'features/common/inputs/dropdowns/portal_action_dropdown';
import {
  barsSolid,
  cogSolid,
  compressSolid,
  copyLight,
  expandSolid,
  gripDotsVerticalSolid,
  LpIcon,
  trashXmarkSolid,
  windowMaximizeSolid,
} from 'features/common/lp_icon';
import { getWidgetForId } from 'features/dashboards/selectors';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { useItemWidgetClickThroughUrl } from 'features/dashboards_v2/widget/common/use_item_widget_click_through_url';
import { awaitRequestFinish } from 'lib/api';

import { useWidgetTitlePlaceholder } from './use_default_widget_title';

interface WidgetToolbarProps {
  dashboardId: string;
  widgetId: string;
  draggableRef?: (el: HTMLElement | null) => void;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}

export function WidgetToolbar({ dashboardId, widgetId, draggableRef, attributes, listeners }: WidgetToolbarProps) {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const widget = useSelector((state) => getWidgetForId(state, widgetId));
  const widgetUrl = useItemWidgetClickThroughUrl(widget);
  const widgetTitle = widget?.config.title ?? '';
  const [title, setTitle] = useState(widgetTitle);
  const { setShowSettingsForWidget, setShowDeleteModal, setDeleteModalProps } = useDashboardContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuButtonRef = useRef(null);
  const titlePlaceholder = useWidgetTitlePlaceholder(widget);

  useEffect(() => {
    setTitle(widgetTitle);
  }, [widgetTitle]);

  function updateWidgetSettings(widgetSize?: WidgetSize) {
    if (!widget) {
      return;
    }

    const { uuid } = dispatch(
      WidgetDao.update(
        {
          organizationId,
          workspaceId,
          dashboardId,
          widgetId,
        },
        {
          widgetSize,
          config: { ...widget.config, dateRangeIncludeAllOverride: false, title },
        },
      ),
    );

    dispatch(awaitRequestFinish(uuid, {}));
  }

  function onDropdownOpen() {
    setMenuOpen(true);
  }

  function onDropdownClose() {
    setMenuOpen(false);
  }

  function toggleWidgetSize() {
    updateWidgetSettings(widget?.widgetSize === WidgetSize.Half ? WidgetSize.Full : WidgetSize.Half);
  }

  function duplicateWidget() {
    const { uuid } = dispatch(
      WidgetDao.duplicate({
        organizationId,
        workspaceId,
        dashboardId,
        widgetId,
      }),
    );

    dispatch(awaitRequestFinish(uuid, {}));
  }

  function deleteWidget(cleanUpAfterDelete: () => void) {
    const { uuid } = dispatch(
      WidgetDao.destroy({
        organizationId,
        workspaceId,
        dashboardId,
        widgetId,
      }),
    );

    dispatch(awaitRequestFinish(uuid, { onFinish: () => cleanUpAfterDelete() }));
  }

  function confirmDeleteWidget() {
    setShowDeleteModal(true);
    setDeleteModalProps({
      deleteContent: (
        <>
          You are about to delete <strong>{widgetTitle || titlePlaceholder} Widget</strong>
        </>
      ),
      deleteAction: deleteWidget,
    });
  }

  function onSettingsClick() {
    setShowSettingsForWidget(widgetId);
  }

  if (!widget) {
    return null;
  }

  const handleTitleBlur = () => {
    if (widgetTitle !== title) {
      updateWidgetSettings();
    }
  };

  const handleTitleOnChange = (_: SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => setTitle(data.value);

  return (
    <div className="base-v2-widget__toolbar">
      <button className="base-v2-widget__toolbar-drag-handle" ref={draggableRef} {...attributes} {...listeners}>
        <LpIcon icon={gripDotsVerticalSolid} />
      </button>

      <div className="base-v2-widget__toolbar-title">
        <Input placeholder={titlePlaceholder} value={title} onChange={handleTitleOnChange} onBlur={handleTitleBlur} />
      </div>

      <div className="base-v2-widget__toolbar-expand">
        {widgetUrl && (
          <LpLink to={widgetUrl}>
            <LpIcon icon={windowMaximizeSolid} hoverText="More Detail" />{' '}
          </LpLink>
        )}
      </div>

      <button className="base-v2-widget__toolbar-settings" onClick={onSettingsClick}>
        <LpIcon icon={cogSolid} hoverText="Settings" />
      </button>

      <PortalActionDropdown
        trigger={
          <button ref={menuButtonRef} className="base-v2-widget__toolbar-dropdown-trigger">
            <LpIcon icon={barsSolid} hoverText="Options" />
          </button>
        }
        open={menuOpen}
        triggerRef={menuButtonRef}
        onClose={onDropdownClose}
        onOpen={onDropdownOpen}
        className="base-v2-widget__toolbar-dropdown"
      >
        <Dropdown.Item onClick={onSettingsClick}>
          <LpIcon icon={cogSolid} /> Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        {widget.widgetSize === WidgetSize.Half ? (
          <Dropdown.Item onClick={toggleWidgetSize}>
            <LpIcon icon={expandSolid} /> Full-size
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={toggleWidgetSize}>
            <LpIcon icon={compressSolid} /> Half-size
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={duplicateWidget}>
          <LpIcon icon={copyLight} /> Duplicate
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={confirmDeleteWidget}>
          <LpIcon icon={trashXmarkSolid} /> Delete Widget
        </Dropdown.Item>
      </PortalActionDropdown>
    </div>
  );
}
