import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'semantic-ui-react';

import { getCurrentUserId } from 'features/common/current/selectors';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { blurElementOnEnterKey } from 'lib/helpers/blur_element_on_key_press';
import { getOrganizationUserInCurrentOrgForUserId } from 'state/entities/selectors/user';

const CustomFieldNumeric = ({ resourceId, fieldId, disabled = false }: CustomFieldValueProps) => {
  const [value, setValue] = useState('');
  const userId = useSelector(getCurrentUserId);
  const orgUser = useSelector((state) => getOrganizationUserInCurrentOrgForUserId(state, userId));
  const { formatNumber } = useLocalizedFormats();

  const { updateFieldValue, createFieldValue, removeFieldValue } = useCustomFieldsApiActions(resourceId.type);
  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);
  const { allowsMultipleValues, values } = field ?? {};

  const [isEditing, setIsEditing] = useState(false);

  if (allowsMultipleValues) {
    throw new Error('multiple values not supported by numeric field');
  }

  const fieldValue = values?.[0];
  const fieldValueNumber = fieldValue?.number;

  useEffect(() => {
    setValue(String(fieldValueNumber ?? ''));
  }, [fieldValueNumber]);

  const handleChange = (_: React.SyntheticEvent, { value }: { value: string }) => {
    setValue(value);
  };

  const handleBlur = useCallback(() => {
    if (!value) {
      setValue('');
      fieldValue && removeFieldValue(resourceId.id, fieldValue.id);
    } else if (!isNaN(Number(value))) {
      const payload = { number: parseFloat(String(value)) };
      setValue(value);
      if (fieldValue) {
        updateFieldValue(resourceId.id, fieldValue.id, payload);
      } else {
        createFieldValue(resourceId.id, fieldId, payload);
      }
    } else {
      setValue(String(fieldValueNumber ?? ''));
    }
    setIsEditing(false);
  }, [createFieldValue, fieldId, fieldValue, fieldValueNumber, removeFieldValue, resourceId, updateFieldValue, value]);

  const formatedValue = useMemo(() => {
    return (orgUser && formatNumber(value, orgUser.numberFormat)) ?? '';
  }, [formatNumber, orgUser, value]);

  const startEditMode = () => setIsEditing(true);

  return (
    <>
      {isEditing ? (
        <Input
          className={`lp-custom-field-numeric__${fieldId}`}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={blurElementOnEnterKey}
          value={value}
          type="number"
        />
      ) : (
        <Input onFocus={startEditMode} value={formatedValue} disabled={disabled} />
      )}
    </>
  );
};

export default CustomFieldNumeric;
