import moment from 'moment-timezone';

import { EstimationType, SourceSystem, TaskGroupHierarchy } from 'daos/external_integration_enums';
import { ExternalIntegrationSyncErrorOverview, SyncType } from 'daos/model_types';
import {
  JiraProjectModalFormFieldMapping,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
} from 'features/jira_project/modal/types';
import { PushTasksToJiraError } from 'features/ppp/project/jira_controls/sync_error_modal/types';
import {
  IntegrationUpdatedProperties,
  OriginAction,
  EstimateType,
  StorypointScheme,
  PullWorklogs,
} from 'lib/avo/client';
import { dateDifference, ISO_DATE_FORMAT } from 'lib/localization';

export const getDateDiffFromIntegrationDate = (issuesDate: string) => {
  const localNow = moment(new Date(), ISO_DATE_FORMAT);
  const issuesDateToIso = moment(issuesDate, ISO_DATE_FORMAT);

  return dateDifference(localNow, issuesDateToIso, 'days');
};

export const getEstimationType = (estimationType: EstimationType) => {
  switch (estimationType) {
    case EstimationType.StoryPoints:
      return EstimateType.STORY_POINT;
    default:
      return EstimateType.TIME_REMAINING;
  }
};

export const getStoryPointsSchemeActionForAvo = (
  estimationType: EstimationType,
  selectedExistingSchemeToCopy: number | null,
  existingSchemeId: number | null,
) => {
  return estimationType === EstimationType.StoryPoints
    ? getStoryPointsSchemeAction(selectedExistingSchemeToCopy, existingSchemeId)
    : null;
};

const getStoryPointsSchemeAction = (selectedExistingSchemeToCopy: number | null, existingSchemeId: number | null) => {
  if (selectedExistingSchemeToCopy) {
    return StorypointScheme.COPIED;
  }
  if (existingSchemeId) {
    return StorypointScheme.EXISTING;
  }
  return StorypointScheme._NEW;
};

const getIssueTypeNames = (
  issueTypesSelected?: Array<string>,
  issueTypes?: ReadonlyArray<{ name: string; id: string }> | undefined,
) => {
  return (
    issueTypesSelected
      ?.map((issueType) => {
        const issue = issueTypes?.find((issue) => issue.id === issueType);
        return issue?.name;
      })
      .filter(Boolean) || []
  );
};

export const getPushedIssueTypes = (
  issueTypesSelected?: Array<string>,
  issueTypes?: ReadonlyArray<{ name: string; id: string }> | undefined,
) => {
  const issueTypeNames = getIssueTypeNames(issueTypesSelected, issueTypes);

  if (issueTypeNames && issueTypeNames.length > 1) {
    return issueTypeNames.join(',');
  } else if (issueTypeNames && issueTypeNames.length === 1) {
    return issueTypeNames[0];
  } else {
    return '';
  }
};

export const getNumberOfPulledFields = (fieldMappings: ReadonlyArray<JiraProjectModalFormFieldMapping>) =>
  fieldMappings.filter((field) => field.isPull).length;

export const getNumberOfPushedFields = (fieldMappings: ReadonlyArray<JiraProjectModalFormFieldMapping>) =>
  fieldMappings.filter((field) => field.isPush).length;

export const hasTwoWaySync = (fieldMappings: ReadonlyArray<JiraProjectModalFormFieldMapping>) =>
  fieldMappings.some((field) => field.isPull && field.isPush);

export const hasOneWaySync = (fieldMappings: ReadonlyArray<JiraProjectModalFormFieldMapping>) =>
  fieldMappings.some((field) => field.isPull && !field.isPush);

export const getJiraSyncType = (syncType: SyncType) => {
  switch (syncType) {
    case SyncType.FULL:
      return OriginAction.SYNCHRONIZE;
    case SyncType.PULL:
      return OriginAction.PULLED_FROM_JIRA;
    default:
      return null;
  }
};

interface FormValues {
  [key: string]: any;
}

const filterFields = (diff: FormValues): FormValues => {
  return Object.entries(diff).reduce((acc, [key, value]) => {
    if (value != null) {
      acc[key] = value;
    }
    return acc;
  }, {} as FormValues);
};

const getUpdatedFields = (formValues: FormValues, initialValues?: FormValues): FormValues => {
  const formsCombined = { ...initialValues, ...formValues };

  const updatedFields = Object.entries(formsCombined).reduce((diff, [key]) => {
    if (formValues[key] == null) {
      diff[key] = initialValues?.[key];
    } else if (initialValues?.[key] !== formValues[key]) {
      diff[key] = formValues[key];
    }

    const updatedFieldsFiltered = filterFields(diff);

    return updatedFieldsFiltered;
  }, {} as FormValues);

  return updatedFields;
};

export const getUpdatedAvoAttributes = ({
  formValues,
  initialValues,
  issueTypes,
  workspaceId,
  packageId,
}: {
  formValues: JiraProjectModalFormValues;
  initialValues?: JiraProjectModalFormValues;
  issueTypes: ReadonlyArray<{ name: string; id: string }> | undefined;
  workspaceId: number;
  packageId: number;
}) => {
  const updatedFields = getUpdatedFields(formValues, initialValues);
  const newValues: IntegrationUpdatedProperties = {
    integrationName: SourceSystem.JIRA,
    numberOfIssueStatuses: undefined,
    issuesCreatedAfter: undefined,
    oneWaySync: undefined,
    twoWaySync: undefined,
    remainingEstimate: undefined,
    numberOfPulledFields: undefined,
    numberOfPushedFields: undefined,
    pushedIssueTypes: undefined,
    oauthCredentialsId: undefined,
    subFolderOrganization: TaskGroupHierarchy.GroupFlatLevel,
    syncIssuePriorityOrder: true,
    manualPush: false,
    workspaceId: workspaceId.toString(),
    packageId: packageId.toString(),
  };

  Object.entries(updatedFields).forEach(([key, value]) => {
    switch (key) {
      case JiraProjectModalFormFields.IssueStatusIds:
        newValues.numberOfIssueStatuses = Array.isArray(value) ? value.length : 0;
        break;
      case JiraProjectModalFormFields.IssueCreatedDate:
        newValues.issuesCreatedAfter = getDateDiffFromIntegrationDate(value);
        break;
      case JiraProjectModalFormFields.SyncJiraWorklog:
        newValues.oneWaySync = value;
        break;
      case JiraProjectModalFormFields.LowEstimate:
        newValues.remainingEstimate = value;
        break;
      case JiraProjectModalFormFields.FieldMappings:
        newValues.numberOfPushedFields = getNumberOfPushedFields(formValues[JiraProjectModalFormFields.FieldMappings]);
        newValues.numberOfPulledFields = getNumberOfPulledFields(formValues[JiraProjectModalFormFields.FieldMappings]);
        newValues.oneWaySync = hasOneWaySync(formValues[JiraProjectModalFormFields.FieldMappings]);
        newValues.twoWaySync = hasTwoWaySync(formValues[JiraProjectModalFormFields.FieldMappings]);
        break;
      case JiraProjectModalFormFields.IssueTypeIds:
        newValues.pushedIssueTypes = getPushedIssueTypes(value, issueTypes);
        break;
      case JiraProjectModalFormFields.OauthCredentialsId:
        newValues.oauthCredentialsId = value;
        break;
      case JiraProjectModalFormFields.TaskGroupHierarchy:
        newValues.subFolderOrganization = value;
        break;
      case JiraProjectModalFormFields.RankIssues:
        newValues.syncIssuePriorityOrder = value;
        break;
      default:
        break;
    }
  });

  return newValues;
};

export const getErrorTypes = (
  errors: ReadonlyArray<ExternalIntegrationSyncErrorOverview> | ReadonlyArray<PushTasksToJiraError>,
) => {
  const errorTypes: Array<string> = [];
  errors.forEach((error) => {
    const errorType = error.errorType;
    errorTypes.push(errorType);
  });

  const uniqueErrorTypes = [...new Set(errorTypes)];

  return uniqueErrorTypes as Array<string>;
};

export const getWorklogValues = (syncJiraWorklog: boolean, syncTimesheetWorklog: boolean) => {
  if (syncTimesheetWorklog) {
    return PullWorklogs.PULL_FROM_TEMPO_TIMESHEETS;
  }

  if (syncJiraWorklog) {
    return PullWorklogs.PULL_FROM_JIRA;
  }

  return PullWorklogs.DO_NOT_PULL_ANY_WORKLOGS;
};
