import moment from 'moment-timezone';
import * as yup from 'yup';

import { nameAlreadyExistsError } from 'features/jira_project/modal/sections/estimate_mapping_section/scheme_name_input';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  JiraTabKey,
  SchemeDisplayNames,
} from 'features/jira_project/modal/types';
import { ISO_DATE_FORMAT } from 'lib/localization';

const mustBeHigherThanZero = (value: string | undefined | number) => {
  if (!value || value === '0') {
    return false;
  }
  return true;
};

const oauthCredentialsIdValidation = yup.number().min(1, 'Please select an email').required('Please select an email');
const cloudIdValidation = yup.string().trim().required('Please select a Jira instance');
const jiraProjectIdValidation = yup.string().trim().required('Please select a Jira project');
const jiraProjectIssueTypeIdsValidation = yup.array().min(1, 'Please select at least one issue type');
const jiraProjectStatusIdsValidation = yup.array().min(1, 'Please select at least one status');
const jiraProjectSelectSchemeValidation = (selectedSchemeActionDisplayName?: SchemeDisplayNames | string) => {
  return selectedSchemeActionDisplayName && selectedSchemeActionDisplayName === SchemeDisplayNames.CopyExistingScheme
    ? yup.string().trim().required('Please select a scheme')
    : yup.string().trim().notRequired();
};
const jiraProjectIssueDateFieldValidation = yup
  .string()
  .trim()
  .required('Please select a date field')
  .test('is-ISO', 'Invalid date format', (value) => {
    const date = moment(value);
    return date.isValid() && value === date.format(ISO_DATE_FORMAT);
  });
const jiraProjectLowEstimateFieldValidation = yup.number().required('Please select a low estimate field');
const jiraProjectHighEstimateFieldValidation = yup.number().required('Please select a high estimate field');
const jiraProjectStoryPointsEstimateValidation = (selectedSchemeActionDisplayName?: SchemeDisplayNames | string) => {
  const isCreatingNewScheme =
    selectedSchemeActionDisplayName === SchemeDisplayNames.CopyExistingScheme ||
    selectedSchemeActionDisplayName === SchemeDisplayNames.CreateNewScheme;
  return isCreatingNewScheme
    ? yup.array().of(
        yup
          .object()
          .required('required ')
          .shape({
            value: yup
              .number()
              .required('A value is required')
              .test('must-be-higher-than-zero', 'At least one story point value is required', (value) =>
                mustBeHigherThanZero(value),
              ),
            highEffort: yup
              .string()
              .trim()
              .required('A high effort value is required')
              .test('must-be-higher-than-zero', 'High effort must be greater than 0', (value) =>
                mustBeHigherThanZero(value),
              ),
            lowEffort: yup
              .string()
              .trim()
              .required('A low effort value is required')
              .test('must-be-higher-than-zero', 'Low effort must be greater than 0', (value) =>
                mustBeHigherThanZero(value),
              ),
          }),
      )
    : yup.array().notRequired();
};

const jiraProjectStorySchemeNameValidation = (
  existingSchemeNames: Array<string>,
  selectedSchemeActionDisplayName?: SchemeDisplayNames | string,
) => {
  if (
    selectedSchemeActionDisplayName === SchemeDisplayNames.CreateNewScheme ||
    selectedSchemeActionDisplayName === SchemeDisplayNames.CopyExistingScheme
  ) {
    return yup
      .string()
      .trim()
      .required('Please enter a scheme name')
      .notOneOf(existingSchemeNames, nameAlreadyExistsError);
  } else {
    return yup.string().trim().notRequired();
  }
};
const costCodeIdValidation = (costCodeRequired?: boolean) =>
  yup
    .mixed()
    .nullable()
    .test('costCodeId', 'Cost Code is required for logging time.', (value, { parent }) => {
      const { syncJiraWorklog } = parent as JiraProjectModalFormValues;
      if (!costCodeRequired) {
        return true;
      }
      return !(costCodeRequired && syncJiraWorklog) || Boolean(value);
    });

export const validateActiveTab = (
  activeTab: JiraTabKey,
  existingSchemeNames: Array<string>,
  costCodeRequired?: boolean,
  selectedSchemeActionDisplayName?: SchemeDisplayNames | string,
) => {
  switch (activeTab) {
    case JiraTabKey.JiraInstance:
      return yup.object().shape({
        [JiraProjectModalFormFields.OauthCredentialsId]: oauthCredentialsIdValidation,
        [JiraProjectModalFormFields.CloudId]: cloudIdValidation,
      });
    case JiraTabKey.ProjectAndIssuesFilter:
      return yup.object().shape({
        [JiraProjectModalFormFields.JiraProjectId]: jiraProjectIdValidation,
        [JiraProjectModalFormFields.IssueTypeIds]: jiraProjectIssueTypeIdsValidation,
        [JiraProjectModalFormFields.IssueStatusIds]: jiraProjectStatusIdsValidation,
        [JiraProjectModalFormFields.IssueCreatedDate]: jiraProjectIssueDateFieldValidation,
      });
    case JiraTabKey.SyncSettings:
      return yup.object().shape({
        [JiraProjectModalFormFields.CostCodeId]: costCodeIdValidation(costCodeRequired),
      });
    case JiraTabKey.RemainingEstimates:
      return yup.object().shape({
        [JiraProjectModalFormFields.LowEstimate]: jiraProjectLowEstimateFieldValidation,
        [JiraProjectModalFormFields.HighEstimate]: jiraProjectHighEstimateFieldValidation,
        [JiraProjectModalFormFields.SelectedExistingSchemeToCopy]: jiraProjectSelectSchemeValidation(
          selectedSchemeActionDisplayName,
        ),
        [JiraProjectModalFormFields.StoryPoints]: jiraProjectStoryPointsEstimateValidation(
          selectedSchemeActionDisplayName,
        ),
        [JiraProjectModalFormFields.StoryPointSchemeName]: jiraProjectStorySchemeNameValidation(
          existingSchemeNames,
          selectedSchemeActionDisplayName,
        ),
      });
    default:
      return yup.object().shape({});
  }
};
