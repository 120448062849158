import { createSelector } from 'reselect';

import { Workspace } from 'daos/model_types';
import { readonlyArray } from 'lib/readonly_record';
import { RootState } from 'state/root_reducer';

export const getCurrentOrganizationId = ({ current }: RootState) => {
  return current.organizationId ?? 0;
};

export const getCurrentWorkspaceId = ({ current }: RootState) => {
  return current.workspaceId ?? 0;
};

export const getIsCurrentUserAuthenticated = ({ current }: RootState) => {
  return current.userId !== undefined;
};

export const getCurrentUserId = ({ current }: RootState) => {
  return current.userId ?? 0;
};

export const getCurrentUserDisconnected = ({ current }: RootState) => {
  return current.disconnected;
};

export function getCurrentUser(state: RootState) {
  return state.entities.users[getCurrentUserId(state)];
}

export function getReloadOnNextNavigation({ current }: RootState) {
  return current.reloadOnNextNavigation;
}

export const getCurrentWorkspaceUsers = ({ current }: RootState) => {
  return current.workspaceUsers;
};

const getCurrentNonGuestWorkspaceUsers = createSelector(getCurrentWorkspaceUsers, (wsUsers) =>
  readonlyArray(wsUsers.filter((wsUser) => !wsUser.guestUser)),
);

const getCurrentWorkspacesById = ({ current }: RootState) => {
  return current.workspacesById;
};

export const getCurrentNonGuestConnectedWorkspaces = createSelector(
  getCurrentNonGuestWorkspaceUsers,
  getCurrentWorkspacesById,
  getCurrentOrganizationId,
  (nonGuestWsUsers, workspacesById, currentOrgId) => {
    const nonGuestWorkspaces = nonGuestWsUsers.reduce((acc: Array<Workspace>, wsUser) => {
      const isDisconnected = !!wsUser.disconnectedAt;
      const workspace = workspacesById[wsUser.workspace.id];
      const workspaceIsInCurrentOrg = workspace?.organization.id === currentOrgId;

      if (!isDisconnected && workspaceIsInCurrentOrg) {
        acc.push(workspace);
      }

      return acc;
    }, []);

    return readonlyArray(nonGuestWorkspaces);
  },
);
