import { noop } from 'lodash';
import { createContext, useState, useMemo, ReactNode, Dispatch, useContext, SetStateAction } from 'react';
import { Button } from 'semantic-ui-react';

interface ItemPanelActionsContext {
  actions: Array<ReactNode>;
  defaultActions: Array<ReactNode>;
  setActions: Dispatch<Array<ReactNode>>;
  handleModalClose: () => void;
  assignmentIds: ReadonlyArray<number>;
  setAssignmentIds: Dispatch<SetStateAction<ReadonlyArray<number>>>;
  isFetchingOnUpdate: boolean;
  setIsFetchingOnUpdate: Dispatch<SetStateAction<boolean>>;
  fetchItem: () => void;
}

const ItemPanelContext = createContext<ItemPanelActionsContext>({
  actions: [],
  defaultActions: [],
  setActions: noop,
  handleModalClose: noop,
  assignmentIds: [],
  setAssignmentIds: noop,
  isFetchingOnUpdate: false,
  setIsFetchingOnUpdate: noop,
  fetchItem: noop,
});

export function useItemPanelContext() {
  return useContext(ItemPanelContext);
}

interface ProviderProps {
  children?: ReactNode;
  onClose: () => void;
  fetchItem: () => void;
  assignmentIds: ReadonlyArray<number>;
  setAssignmentIds: Dispatch<SetStateAction<ReadonlyArray<number>>>;
  isFetchingOnUpdate: boolean;
  setIsFetchingOnUpdate: Dispatch<SetStateAction<boolean>>;
}

export const ItemPanelContextProvider = (props: ProviderProps) => {
  const defaultActions = useMemo(
    () => [
      <Button
        key="close-btn"
        onClick={props.onClose}
        content="Close"
        className="lp-item-panel__actions-container-close-button"
        primary
      />,
    ],
    [props],
  );
  const [actions, setActions] = useState<Array<ReactNode>>(defaultActions);
  const value: ItemPanelActionsContext = useMemo(
    () => ({
      actions,
      defaultActions,
      setActions,
      assignmentIds: props.assignmentIds,
      handleModalClose: props.onClose,
      isFetchingOnUpdate: props.isFetchingOnUpdate,
      setIsFetchingOnUpdate: props.setIsFetchingOnUpdate,
      setAssignmentIds: props.setAssignmentIds,
      fetchItem: props.fetchItem,
    }),
    [
      actions,
      defaultActions,
      props.assignmentIds,
      props.isFetchingOnUpdate,
      props.onClose,
      props.setAssignmentIds,
      props.setIsFetchingOnUpdate,
      props.fetchItem,
    ],
  );
  return <ItemPanelContext.Provider value={value}>{props.children}</ItemPanelContext.Provider>;
};
