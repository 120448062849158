/**
 * This function comes from `rsql-builder` and uses their regex to determine
 * which characters to escape.
 *
 * GitHub: https://github.com/RomiC/rsql-builder/blob/master/src/escape-value.ts
 *
 * @param value value to escape characters and whitespace
 * @returns String
 */

const CHARS_TO_ESCAPE = /["'();,=!~<>\s]/;

export const escapeValue = (value: string | number | boolean) => {
  const stringRepresentation = value.toString();

  if (CHARS_TO_ESCAPE.test(stringRepresentation) || !stringRepresentation.length) {
    const valWithEscapedQuotes = stringRepresentation.replace(/["]/g, '\\"'); // Replace all double-quotes with escaped double-quotes
    return `"${valWithEscapedQuotes}"`; //Wrap the string in double quotes
  }

  return stringRepresentation;
};
