import { createSelector } from 'reselect';

import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { associateRecordBy } from 'lib/readonly_record';
import { RootState } from 'state/root_reducer';

const getSubscriptionsById = (state: RootState) => state.entities.subscriptions;

const getSubscriptionByOrganizationId = createSelector(getSubscriptionsById, (subscriptionsById) => {
  return associateRecordBy(subscriptionsById, (subscription) => subscription.organizationId);
});

const getSubscriptionForOrganizationId = (state: RootState, organizationId: number) =>
  getSubscriptionByOrganizationId(state)[organizationId];

export const getCurrentOrganizationSubscription = (state: RootState) => {
  const currentOrganizationId = getCurrentOrganizationId(state);
  return getSubscriptionForOrganizationId(state, currentOrganizationId);
};
