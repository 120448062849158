import { useSelector } from 'react-redux';

import { CallToActionLink } from 'features/common/calls_to_action/link';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { thirdPartyUrls, frontend } from 'lib/urls';
import { getCurrentOrganizationUser } from 'state/entities/selectors/user';

import './button_actions.scss';

export const PremiumFeaturesTrialButtons = ({
  showTrialButton,
  seePlansButtonText,
}: {
  showTrialButton: boolean;
  seePlansButtonText: string;
}) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const orgUser = useSelector(getCurrentOrganizationUser);
  const isAdmin = orgUser?.admin;

  return (
    <div className="premium-features-trial-buttons">
      {showTrialButton && (
        <CallToActionLink
          to={frontend.organizationHubUpgrade.url({ organizationId })}
          className={'premium-features-trial-buttons__premium-features-trial'}
        >
          Premium Features Trial
        </CallToActionLink>
      )}

      {isAdmin && (
        <CallToActionLink
          to={frontend.organizationHubManageAccount.url({ organizationId })}
          className={'premium-features-trial-buttons__see-plans'}
        >
          {seePlansButtonText}
        </CallToActionLink>
      )}

      <CallToActionLink
        to={thirdPartyUrls.productAdvisor}
        className={'premium-features-trial-buttons__contact-us'}
        openInNewWindow
      >
        Contact Us
      </CallToActionLink>
    </div>
  );
};

export const UpgradeButtonLink = ({ buttonText }: { buttonText: string }) => {
  const organizationId = useSelector(getCurrentOrganizationId);

  return (
    <CallToActionLink
      to={frontend.organizationHubUpgrade.url({ organizationId })}
      className="dynamic-text-to-upgrade-page-button"
    >
      {buttonText}
    </CallToActionLink>
  );
};
