import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import ItemIcon from 'containers/shared/item_icon';
import ItemLink from 'containers/shared/item_link';
import { RiskLabel } from 'containers/shared/notice_icons/risk_label';
import SchedulingLabels from 'containers/shared/notice_icons/scheduling_labels';
import { ScheduleDirective, ItemType } from 'daos/enums';
import { DataGridViewType } from 'features/common/data_grid/enums';
import { getDataGridSelectedColumnsForPackageStatus } from 'features/common/data_grid/selectors';
import { GridModalState, ItemFormatterProps } from 'features/common/data_grid/types';
import { EllipsisActionDropdown } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown';
import { useEllipsisActionClickLocationContext } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/use_ellipsis_action_click_location';
import { EllipsisActionViewLocation } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { ItemAncestryBreadcrumb } from 'features/common/item_ancestry_breadcrumb';
import { useAssignmentColumnSelectionList } from 'features/common/item_column/use_item_column_selection_list';
import { useClickLocationForContextMenu } from 'hooks/use_click_location_for_context_menu';
import { CIRCULAR_BULLET_POINT } from 'lib/constants';
import { getItemForId, getPackageStatusForItemId } from 'state/entities/selectors/item';

export const AssignmentNameColumnFormatter = ({ row }: ItemFormatterProps) => {
  const assignment = useSelector((state) => getItemForId(state, row.id));

  if (!assignment) {
    return null;
  }

  return <span>{assignment.name ?? 'Assignment'}</span>;
};

export const BreadCrumbFormatter = ({
  row,
  buildAncestryUpToItemType,
  setEstimateModalAssignmentId,
  dataGridViewType,
  showAlertIcons,
}: ItemFormatterProps & {
  buildAncestryUpToItemType: ItemType;
  setEstimateModalAssignmentId: Dispatch<SetStateAction<number | undefined>>;
  dataGridViewType: DataGridViewType;
  showAlertIcons: boolean;
}) => {
  const history = useHistory();
  const assignment = useSelector((state) => getItemForId(state, row.id));
  const task = useSelector((state) => (assignment?.parent ? getItemForId(state, assignment.parent.id) : undefined));

  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, row.id));
  const allColumns = useAssignmentColumnSelectionList({ forPPPGrid: false });
  const assignmentsSelectedColumns = useSelector((state) =>
    getDataGridSelectedColumnsForPackageStatus(state, packageStatus, allColumns, dataGridViewType),
  );

  const hasLocationColumnSelected = assignmentsSelectedColumns.some((column) => column.id === StandardColumns.Location);

  const { clickLocation, setClickLocation } = useClickLocationForContextMenu();
  const { handleShowContextMenu, handleHideContextMenu, triggerRef } = useEllipsisActionClickLocationContext(
    clickLocation,
    setClickLocation,
  );

  if (!assignment || !task) {
    return null;
  }

  const openTaskPanel = () => history.push(`#panelId=${task.id}`);

  const handleAssignmentNameClick = () => setEstimateModalAssignmentId(assignment.id);
  const isDone = !!assignment?.doneDate;
  const assignmentSection = (
    <>
      <ItemLink itemId={task.id} openScheduleTab={false} /> <span className="middle-dot">{CIRCULAR_BULLET_POINT}</span>{' '}
      <span
        className={classNames({
          'assignment-name': !isDone,
          'done-assignment-name': isDone,
        })}
        onClick={handleAssignmentNameClick}
      >
        {assignment.name ?? 'Assignment'}
      </span>
    </>
  );

  const modalState = (row.modalState as GridModalState) ?? {};

  return (
    <span onContextMenu={handleShowContextMenu} className="lp-data-grid__item">
      <EllipsisActionDropdown
        clickLocation={clickLocation}
        handleHideContextMenu={handleHideContextMenu}
        isTemplateGrid={false}
        item={assignment}
        setDeletionData={modalState.setDeletionData}
        setEstimateModalAssignmentId={modalState.setEstimateModalAssignmentId}
        setMoveItemId={modalState.setMoveItemId}
        showActionTriggerIcon={false}
        triggerRef={triggerRef}
        viewLocation={EllipsisActionViewLocation.GridView}
      />

      <ItemIcon itemId={assignment.parent?.id} hasToolTip={false} />

      <span className="lp-data-grid__item-name">
        {assignmentSection}
        {!hasLocationColumnSelected && (
          <ItemAncestryBreadcrumb buildAncestryUpToItemType={buildAncestryUpToItemType} itemId={row.id} />
        )}
      </span>

      {showAlertIcons && (
        <>
          <SchedulingLabels ignoreWorkLimit ignoreRisk itemId={task.id} />
          {assignment.late && <RiskLabel onClick={openTaskPanel} />}
        </>
      )}
    </span>
  );
};

export const DoneColumnFormatter = ({ row }: ItemFormatterProps) => {
  const assignment = useSelector((state) => getItemForId(state, row.id));

  if (!assignment) {
    return null;
  }

  const isDone = assignment.doneDate ? 'Yes' : 'No';
  return <span>{isDone}</span>;
};

export const TrackingOnlyFormatter = ({ row }: ItemFormatterProps) => {
  const assignment = useSelector((state) => getItemForId(state, row.id));

  if (!assignment) {
    return null;
  }

  const isTrackingOnly = assignment.scheduleDirective === ScheduleDirective.TRACKING_ONLY ? 'Yes' : 'No';

  return <span>{isTrackingOnly}</span>;
};
