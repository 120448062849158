import { useSelector } from 'react-redux';

import { CustomFieldColumn } from 'containers/shared/custom_column/custom_field';
import { isMultipleValueField } from 'daos/custom_field';
import { ItemDao } from 'daos/item';
import { CustomField } from 'daos/model_types';
import { ItemFormatterProps } from 'features/common/data_grid/types';
import { getEffectiveFieldsAndValuesForResourceId } from 'state/entities/selectors/custom_field';

import { CustomFieldFormatters } from './custom_field';

export const CustomFieldFormatter = (customField: CustomField, props: ItemFormatterProps) => {
  const itemId = props.row.id;
  const fieldWithValues = useSelector(
    (state) => getEffectiveFieldsAndValuesForResourceId(state, ItemDao.id(itemId))[customField.id],
  );

  const singleValue = fieldWithValues?.values?.[0];

  if (isMultipleValueField(customField)) {
    return <CustomFieldColumn fieldId={parseInt(props.column.key, 10)} itemId={itemId} />;
  }

  if (singleValue) {
    return <CustomFieldFormatters value={singleValue} customField={customField} itemId={itemId} />;
  }

  return <CustomFieldColumn fieldId={parseInt(props.column.key, 10)} itemId={itemId} />;
};
