import { useSelector } from 'react-redux';

import ItemIcon from 'containers/shared/item_icon';
import ItemLink from 'containers/shared/item_link';
import LpLink from 'containers/shared/lp_link';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { usePackageCollectionUrlForPackageStatus } from 'hooks/use_package_collection_url_for_package_status';
import { isPackage, getItemForId, getPackageStatusForItemId } from 'state/entities/selectors/item';

interface ChangeCardParentPropertyProps {
  itemId: number;
  parentId: number;
}

const ChangeCardParentProperty = ({ itemId, parentId }: ChangeCardParentPropertyProps) => {
  const item = useSelector((state) => getItemForId(state, itemId));

  const parentItem = useSelector((state) => getItemForId(state, parentId));
  const parentPackageStatus = useSelector((state) => getPackageStatusForItemId(state, parentItem?.id ?? 0));
  const packageCollectionUrl = usePackageCollectionUrlForPackageStatus(parentPackageStatus);
  const parentItemIsPackage = isPackage(parentItem);

  if (!item) {
    return <>Unknown</>;
  }

  const itemLink = parentItemIsPackage ? (
    parentItem?.packageStatus ? (
      <LpLink to={packageCollectionUrl}>{packageStatusDisplayNameByPackageStatus[parentItem.packageStatus]}</LpLink>
    ) : null
  ) : (
    <ItemLink itemId={itemId} openScheduleTab={false} />
  );

  return (
    <>
      <ItemIcon
        className="lp-change__card-change-property-parent-icon"
        itemId={parentItemIsPackage ? parentItem?.id : itemId}
        hasToolTip={false}
      />
      {itemLink}
    </>
  );
};

export default ChangeCardParentProperty;
