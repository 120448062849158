import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { WidgetDataErrorType } from 'daos/enums';
import { LocationFilterSettings } from 'daos/types';
import { getWidgetForId, getWidgetGroupForId } from 'features/dashboards/selectors';
import { InheritedLocation } from 'features/dashboards_v2/common/inherited_location';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { LocationFilter } from 'features/dashboards_v2/item_filter/location';
import {
  getInheritedLocationScopeForWidgetId,
  getLocationScopeForDashboardId,
  getLocationScopeForWidgetGroupId,
  getWidgetDashboardIsFilteredForWidgetDashboardId,
  getWidgetGroupIsFilteredForWidgetGroupId,
} from 'features/dashboards_v2/selectors';
import { LocationFilterErrorLabel } from 'features/dashboards_v2/widget_data_error_warning';
import { WidgetSettingsV2Field } from 'features/dashboards_v2/widget_settings/field';
import { shouldDisplayDateRangeWarning, shouldDisplayFilteredDateRange, useGetDisplayDateRanges } from 'lib/date_range';

export const LocationSetting = ({
  disablePackageStatusSelection = false,
}: {
  disablePackageStatusSelection?: boolean;
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const { showSettingsForWidget: widgetId, dashboard } = useDashboardContext();
  const { setFieldValue, values } = useFormikContext<LocationFilterSettings>();

  const widget = useSelector((state) => (widgetId ? getWidgetForId(state, widgetId) : undefined));
  const widgetDataError = widget?.data?.errors.includes(WidgetDataErrorType.LOCATION_FILTER_ERROR);

  const widgetGroup = useSelector((state) => getWidgetGroupForId(state, widget?.widgetGroupId ?? ''));

  const dashboardIsFiltered = useSelector((state) =>
    widget?.dashboardId ? getWidgetDashboardIsFilteredForWidgetDashboardId(state, widget.dashboardId) : false,
  );

  const widgetGroupIsFiltered = useSelector((state) =>
    widget?.widgetGroupId ? getWidgetGroupIsFilteredForWidgetGroupId(state, widget.widgetGroupId) : false,
  );

  const inheritedLocationScope = useSelector((state) =>
    widgetId ? getInheritedLocationScopeForWidgetId(state, widgetId) : undefined,
  );

  const widgetGroupLocationScope = useSelector((state) =>
    widget?.widgetGroupId ? getLocationScopeForWidgetGroupId(state, widget?.widgetGroupId) : undefined,
  );

  const dashboardLocationScope = useSelector((state) =>
    widget?.dashboardId ? getLocationScopeForDashboardId(state, widget?.dashboardId) : undefined,
  );

  const { dashboardDateRange, widgetGroupDateRange } = useGetDisplayDateRanges(dashboard, widgetGroup ?? null);

  const handleChange = (locationFilterSettings: Partial<LocationFilterSettings>) => {
    Object.entries(locationFilterSettings).forEach(([key, value]) => {
      setFieldValue(key, value);
    });
    setIsDirty(true);
  };

  const displayDateRangeWarning = shouldDisplayDateRangeWarning(widget?.widgetType ?? null);
  const displayFilteredDateRange = shouldDisplayFilteredDateRange(widget?.widgetType ?? null);

  return (
    <WidgetSettingsV2Field name="Widget Scope">
      <LocationFilter
        disablePackageStatusSelection={disablePackageStatusSelection}
        inheritedLocationScope={inheritedLocationScope}
        onChange={handleChange}
        value={values}
      />
      <InheritedLocation
        name="Group Scope:"
        classname="base-v2-widget-settings__content-options-field-inherited-location"
        locationScope={widgetGroupLocationScope}
        isFiltered={widgetGroupIsFiltered}
        dateRange={displayFilteredDateRange ? widgetGroupDateRange : null}
      />
      <InheritedLocation
        name="Dashboard Scope:"
        classname="base-v2-widget-settings__content-options-field-inherited-location"
        locationScope={dashboardLocationScope}
        isFiltered={dashboardIsFiltered}
        dateRange={displayFilteredDateRange ? dashboardDateRange : null}
      />
      {displayDateRangeWarning && <div>This widget doesn&apos;t reflect date filters</div>}
      {widgetDataError && !isDirty && <LocationFilterErrorLabel />}
    </WidgetSettingsV2Field>
  );
};
