import { isNil } from 'lodash';

import { UserType } from 'daos/enums';
import { formatAsHours, formatAsHoursWithNoRounding } from 'lib/display_helpers/format_as_hours';
import { ReadonlyRecord } from 'lib/readonly_record';

import { ColumnIndex, Columns, StandardColumns } from './enum';

import { customColumnDefinitions } from '.';

export const columnNumberByIndex = {
  [ColumnIndex.Zero]: 1,
  [ColumnIndex.One]: 2,
  [ColumnIndex.Two]: 3,
};

export const userTypeDisplay: ReadonlyRecord<UserType, string> = {
  [UserType.Member]: 'Member',
  [UserType.Resource]: 'Resource',
  [UserType.Placeholder]: 'Placeholder',
};

export const optionalTextForCustomColumn = (column: string): string => {
  if (!isNaN(Number(column)) && Number.isInteger(parseFloat(column))) {
    return '(Custom)';
  }

  switch (column) {
    case StandardColumns.AssignmentCountTotal:
    case StandardColumns.AssignmentCountActive:
    case StandardColumns.AssignmentCountDone:
    case StandardColumns.TaskCountTotal:
    case StandardColumns.TaskCountActive:
    case StandardColumns.TaskCountDone:
    case StandardColumns.TaskCountOnHold:
    case StandardColumns.ProjectCountTotal:
    case StandardColumns.ProjectCountScheduled:
    case StandardColumns.ProjectCountDone:
    case StandardColumns.ProjectCountOnHold:
    case StandardColumns.StoryPointCountActive:
    case StandardColumns.StoryPointCountDone:
    case StandardColumns.StoryPointCountOnHold:
      return '(Count)';
    case StandardColumns.ExpectedFinish:
    case StandardColumns.LatestFinish:
    case StandardColumns.FinishRange:
    case StandardColumns.ExpectedStart:
      return '(Calculated)';
    default:
      return '';
  }
};

const customColumnText = (customColumn: Columns, hasTeamBasedPlanning = false) => {
  switch (customColumn) {
    case StandardColumns.Groups:
      return hasTeamBasedPlanning ? 'Administrative Groups' : 'Groups';
    case StandardColumns.RemainingWorkExpected:
      return 'Remaining Estimate (Expected)';
    case StandardColumns.TotalWorkExpected:
      return 'Total Work (Expected)';
    default:
      return customColumnDefinitions[customColumn]?.displayText ?? customColumn;
  }
};

export const addOptionalTextToCustomColumn = (customColumnOption: Columns, hasTeamBasedPlanning = false): string => {
  const columnText = customColumnText(customColumnOption, hasTeamBasedPlanning);
  const optionalText = optionalTextForCustomColumn(customColumnOption);
  return `${columnText} ${optionalText}`.trim();
};

export const columnsImpactedByWorkLimitAlert = new Set<Columns>([
  StandardColumns.WorkLimit,
  StandardColumns.WorkLimitDelta,
  StandardColumns.TotalWorkExpected,
  StandardColumns.TotalWorkHigh,
  StandardColumns.TotalWorkLow,
  StandardColumns.TotalWorkRange,
  StandardColumns.TotalWorkScheduled,
  StandardColumns.RemainingWorkExpected,
  StandardColumns.RemainingWorkHigh,
  StandardColumns.RemainingWorkLow,
  StandardColumns.RemainingWorkRange,
  StandardColumns.Logged,
  StandardColumns.LoggedBillable,
  StandardColumns.LoggedNonBillable,
]);

export const nonSortableColumns: ReadonlySet<Columns> = new Set([StandardColumns.Assigned]);

export function getSortColumnId(valueAsCustomColumnOptions: Columns | null, valueAsCustomFieldId: number | null) {
  return valueAsCustomColumnOptions === StandardColumns.CustomField ? valueAsCustomFieldId : valueAsCustomColumnOptions;
}

export const getFormattedDateOrEmptyString = (
  date: string | null | undefined,
  formatDate: (date: string) => string,
) => {
  return date ? formatDate(date) : '';
};

export const getHoursOrEmptyString = (hours: number | undefined) => {
  if (hours === undefined) {
    return '';
  }

  return formatAsHours(hours);
};

export const getHoursOrEmptyStringWithNoRounding = (hours: number | undefined) => {
  if (hours === undefined) {
    return '';
  }

  return formatAsHoursWithNoRounding(hours);
};

export const getValueOrEmptyString = (value: string | number | null | undefined) => {
  if (isNil(value)) {
    return '';
  }

  return `${value}`;
};
