import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Form } from 'semantic-ui-react';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu';

import { Permission, WidgetDataErrorType } from 'daos/enums';
import { LpButtonAsLink } from 'features/common/as_components';
import GoBackToDashboardButton from 'features/common/buttons/go_back_to_dashboard_button';
import { compassDraftingRegular, LpIcon } from 'features/common/lp_icon';
import useWidgetParams from 'features/dashboards/hooks/use_widget_params';
import { getDashboardForId, getWidgetForId } from 'features/dashboards/selectors';
import { useGoBackToDashboardUrl } from 'features/dashboards_v2/common/use_go_back_to_dashboard_url';
import { getItemForDashboardId } from 'features/dashboards_v2/selectors';
import { getIntakeSettingsUrl } from 'features/dashboards_v2/widget/widgets/intake/helpers';
import { useGetIntakeWidgetTitleOptions } from 'features/dashboards_v2/widget_click_through/intake/hooks/use_get_intake_widget_title_options';
import { ConfirmationModal, ErrorModal } from 'features/dashboards_v2/widget_click_through/intake/modals/index';
import { useFetchIntakeWidget } from 'features/dashboards_v2/widget_click_through/intake/use_fetch_widget';
import { hasAccess } from 'hooks/use_has_access';
import useHashParams from 'hooks/use_hash_params';
import {
  getLibraryLocationForLibraryResourceId,
  getLibraryResourcesForDashboardId,
} from 'state/entities/selectors/library_resources';

import { IntakeFormErrors } from './form_errors';
import { getFormInitialValuesAndValidationRules } from './helpers';
import { useSubmitIntakeWidgetForm } from './hooks/use_submit_intake_widget_form';
import { IntakeFormImageHeader } from './image_section';
import { IntakeFormButtons } from './intake_form_buttons';
import { validationSchema } from './schema';
import { IntakeFormSection } from './section';

import './index.scss';

export function WidgetIntakeForm() {
  const { intakeTitle: intakeTitleFromUrl } = useHashParams();
  const backToDashboardUrl = useGoBackToDashboardUrl();
  const { getIntakeWidgetTitleOptions } = useGetIntakeWidgetTitleOptions();
  const history = useHistory();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const openErrorModal = () => setIsErrorModalOpen(true);
  const closeErrorModal = () => setIsErrorModalOpen(false);

  const openConfirmationModal = () => setIsConfirmationModalOpen(true);
  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    history.push(backToDashboardUrl);
  };

  const submitForm = useSubmitIntakeWidgetForm(openConfirmationModal, openErrorModal);

  const { currentWidget, isLoading } = useFetchIntakeWidget();

  const missingIntakeParent = currentWidget?.data.errors.includes(WidgetDataErrorType.INTAKE_PARENT_MISSING);

  useEffect(() => {
    if (missingIntakeParent) {
      history.push(backToDashboardUrl);
    }
  }, [backToDashboardUrl, history, missingIntakeParent]);

  if (!currentWidget) {
    return null;
  }

  const { form } = currentWidget.data;

  if (!form) {
    return null;
  }

  const { itemType, sections, confirmationMessage, imageUrl } = form;

  const { initialIntakeTitle: intakeTitleFromTemplate } = getIntakeWidgetTitleOptions(itemType, sections);

  const { initialFormValues, validationRules, itemFieldToFieldIdKey } = getFormInitialValuesAndValidationRules({
    form,
    userInputForName: intakeTitleFromUrl || intakeTitleFromTemplate,
  });

  const dynamicSchema = validationSchema(validationRules, itemFieldToFieldIdKey);
  const validateOnlyOnSubmitProps = { validateOnBlur: false, validateOnChange: false };

  return (
    <Form className="intake-form">
      <Menu className="intake-form__menu" borderless secondary>
        <Menu.Item fitted>
          <GoBackToDashboardButton />
        </Menu.Item>

        <Menu.Menu position="right">
          <NavToIntakeSettingsButton />
        </Menu.Menu>
      </Menu>

      {isErrorModalOpen && (
        <ErrorModal onClose={closeErrorModal} message={'Item not created. Widget setup needs attention.'} />
      )}

      {isConfirmationModalOpen && confirmationMessage && (
        <ConfirmationModal message={confirmationMessage} onClose={closeConfirmationModal} />
      )}

      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={submitForm(currentWidget, itemFieldToFieldIdKey)}
        validationSchema={dynamicSchema}
        {...validateOnlyOnSubmitProps}
      >
        {!isLoading && currentWidget && (
          <div className="intake-form__body">
            <IntakeFormImageHeader imageUrl={imageUrl} />
            <IntakeFormSection
              intakeForm={form}
              organizationId={currentWidget.organizationId}
              workspaceId={currentWidget.workspaceId}
            />

            <div className="intake-form__body-footer">
              <IntakeFormErrors />
              <IntakeFormButtons />
            </div>
          </div>
        )}
      </Formik>
    </Form>
  );
}

function NavToIntakeSettingsButton() {
  const { widgetId } = useWidgetParams();
  const widget = useSelector((state) => getWidgetForId(state, widgetId));
  const dashboard = useSelector((state) =>
    widget?.dashboardId ? getDashboardForId(state, widget.dashboardId) : undefined,
  );
  const dashboardItem = useSelector((state) => (widget ? getItemForDashboardId(state, widget.dashboardId) : undefined));
  const hasManageAccessOnDashboardItem = hasAccess(Permission.MANAGE, dashboardItem);

  const libraryResourceDashboard = useSelector((state) =>
    widget?.dashboardId ? getLibraryResourcesForDashboardId(state, Number(widget.dashboardId)) : undefined,
  );

  const libraryLocation = useSelector((state) =>
    libraryResourceDashboard ? getLibraryLocationForLibraryResourceId(state, libraryResourceDashboard.id) : undefined,
  );

  const packageStatus = libraryResourceDashboard?.packageStatus;

  if (!dashboard || !widget || !hasManageAccessOnDashboardItem || !libraryLocation) {
    return null;
  }

  const urlToIntakeSettings = getIntakeSettingsUrl(dashboard, widget, libraryLocation, packageStatus);

  return (
    <LpButtonAsLink className="intake-form__menu-nav-to-settings" primary to={urlToIntakeSettings}>
      <LpIcon icon={compassDraftingRegular} /> Design
    </LpButtonAsLink>
  );
}
