import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WorkspaceUserDao } from 'daos/workspace_user';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import RemoveSoleFieldValueCell, {
  RemoveSoleFieldValueCellSize,
} from 'features/common/custom_fields/custom_field_value/remove_sole_field_value_cell';
import UserDropdown, {
  UserDropdownProps,
  UserDropdownPlaceHolderText,
} from 'features/common/inputs/dropdowns/user_dropdown';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { awaitRequestFinish } from 'lib/api';
import { getFieldValueForId } from 'state/entities/selectors/custom_field';
import {
  getCurrentOrganizationUserForWorkspaceUser,
  getCurrentOrgUserToWsUserMapping,
  getWorkspaceUserById,
  getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername,
} from 'state/entities/selectors/user';

const CustomFieldWsUserValue = ({
  allowMultipleValues,
  fieldValueId,
  newEntry,
  fieldId,
  resourceId,
  disabled = false,
}: CustomFieldValueProps & {
  allowMultipleValues: boolean;
  fieldValueId?: number;
  newEntry: boolean;
}) => {
  const dispatch = useDispatch();
  const { updateFieldValue, createFieldValue } = useCustomFieldsApiActions(resourceId.type);
  const fieldValue = useSelector((state) => (fieldValueId ? getFieldValueForId(state, fieldValueId) : undefined));
  const workspaceUserId = fieldValue?.workspaceUserValue?.id;
  const workspaceUser = useSelector((state) =>
    workspaceUserId ? getWorkspaceUserById(state, workspaceUserId) : undefined,
  );
  const orgUser = useSelector((state) => getCurrentOrganizationUserForWorkspaceUser(state, workspaceUser));
  const orgUsers = useSelector(getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername);
  const orgUserIdToWsUserIdMap = useSelector(getCurrentOrgUserToWsUserMapping);

  const [savingUuid, setSavingUuid] = useState<string | undefined>(undefined);

  const handleSaveFinish = () => {
    setSavingUuid(undefined);
  };

  const handleChange: UserDropdownProps['onChange'] = useCallback(
    (_: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: number }) => {
      const wsUserId = orgUserIdToWsUserIdMap[value];

      if (wsUserId) {
        const payload = { workspaceUserValue: WorkspaceUserDao.id(wsUserId) };
        const request = fieldValue
          ? updateFieldValue(resourceId.id, fieldValue.id, payload)
          : createFieldValue(resourceId.id, fieldId, payload);
        const { uuid } = request;
        setSavingUuid(uuid);
        dispatch(
          awaitRequestFinish(uuid, {
            onFinish: () => handleSaveFinish(),
          }),
        );
      }
    },
    [createFieldValue, dispatch, fieldId, fieldValue, orgUserIdToWsUserIdMap, resourceId, updateFieldValue],
  );

  return (
    <span className="custom-field-input">
      <UserDropdown
        className="custom-field-table__user-dropdown"
        clearable={false}
        loading={!!savingUuid}
        onChange={handleChange}
        placeholder={allowMultipleValues ? UserDropdownPlaceHolderText.Add : UserDropdownPlaceHolderText.SelectUser}
        selectedOrgUserId={orgUser?.id}
        newEntry={newEntry}
        orgUsers={orgUsers}
        disabled={disabled}
      />

      {fieldValueId && allowMultipleValues && (
        <RemoveSoleFieldValueCell size={RemoveSoleFieldValueCellSize.Button} valueIds={[fieldValueId]} />
      )}
    </span>
  );
};

export default CustomFieldWsUserValue;
