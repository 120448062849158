import { memo, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { areEqual, GridChildComponentProps, VariableSizeGrid } from 'react-window';

import ConfirmDeleteModal from 'containers/shared/confirm_delete_modal';
import ItemMoveModal from 'containers/shared/move_modal';
import { WorkloadDayRange } from 'daos/enums';
import { isTrialOrFree } from 'daos/plan';
import { SetItemDeletionParams } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { useWorkloadTableContext, WorkloadTableContext } from 'features/common/workload/workload_table/context';
import {
  getColumnWorkloadCellType,
  getColumnWidthAndSetNameColumnWidth,
  getWorkloadRows,
  ROW_HEIGHT_PIXELS,
  getAdditionalColumnCount,
  getExpandedOrgUserIds,
} from 'features/common/workload/workload_table/helpers';
import {
  OrgUserAvailabilityCell,
  OrgUserNubCell,
  OrgUserRemainingCell,
} from 'features/common/workload/workload_table/org_user_cells';
import { AvailabilityCell, NubCell, RemainingCell } from 'features/common/workload/workload_table/task_cells';
import { useHasGeneralTeamBasedPlanning } from 'hooks/use_has_feature';
import { TrackingBoundingBox, useTrackBoundingBox } from 'hooks/use_track_bound_box';
import { getCurrentOrganizationPlan } from 'state/entities/selectors/plan';
import { getWorkloadById } from 'state/entities/selectors/workload';

import { StickyWorkloadTableElements } from './sticky_elements';
import { WorkloadCellType, WorkloadRows } from './types';

interface WorkloadTableProps {
  dayRange: WorkloadDayRange;
  workloadId: number;
  fetchWorkload: () => void;
  isFiltered: boolean;
  shouldExpandAllUsers: boolean;
}

const Cell = memo(
  ({ data, rowIndex, columnIndex, style }: PropsWithChildren<GridChildComponentProps<WorkloadRows>>) => {
    const row = data[rowIndex];
    const { workloadId, dayRange, hasGeneralTeamBasedPlanning } = useWorkloadTableContext();
    const additionalColumnCount = getAdditionalColumnCount(hasGeneralTeamBasedPlanning);
    const columnCount = dayRange + additionalColumnCount;

    if (!row || !workloadId) {
      return null;
    }

    const { item, group } = row;
    const columnCellType = getColumnWorkloadCellType({
      columnCount,
      columnIndex: columnIndex,
      hasGeneralTeamBasedPlanning,
    });

    if (item) {
      switch (columnCellType) {
        case WorkloadCellType.DateNub:
          return (
            <NubCell
              style={style}
              workloadItem={item}
              workloadId={workloadId}
              workloadGroupId={item.organizationUser?.id ?? 0}
              nubIndex={columnIndex - 1}
            />
          );
        case WorkloadCellType.ScheduledHours:
        case WorkloadCellType.PlannedHours:
          return (
            <RemainingCell
              style={style}
              workloadItemId={item.id}
              workloadId={workloadId}
              workloadGroupId={item.organizationUser?.id ?? 0}
            />
          );
        case WorkloadCellType.AvailabilityScheduled:
        case WorkloadCellType.AvailabilityPlanned:
          return (
            <AvailabilityCell
              style={style}
              workloadGroupId={item.organizationUser?.id ?? 0}
              workloadItemId={item.id}
              workloadId={workloadId}
            />
          );

        default:
          return null;
      }
    }

    if (group) {
      switch (columnCellType) {
        case WorkloadCellType.DateNub:
          return (
            <OrgUserNubCell style={style} workloadId={workloadId} nubIndex={columnIndex - 1} workloadGroup={group} />
          );
        case WorkloadCellType.ScheduledHours:
        case WorkloadCellType.PlannedHours:
          return <OrgUserRemainingCell style={style} workloadId={workloadId} orgUserId={group.organizationUser?.id} />;
        case WorkloadCellType.AvailabilityScheduled:
        case WorkloadCellType.AvailabilityPlanned:
          return (
            <OrgUserAvailabilityCell style={style} orgUserId={group.organizationUser?.id} workloadId={workloadId} />
          );

        default:
          return null;
      }
    }

    return null;
  },
  areEqual,
);

export const WorkloadTable = memo(
  ({ dayRange, workloadId, fetchWorkload, isFiltered, shouldExpandAllUsers }: WorkloadTableProps) => {
    const hasGeneralTeamBasedPlanning = useHasGeneralTeamBasedPlanning();

    const gridRef = useRef<VariableSizeGrid>(null);
    const { ref, box } = useTrackBoundingBox();
    const workload = useSelector((state) => getWorkloadById(state, workloadId));
    const planFamily = useSelector(getCurrentOrganizationPlan)?.family;
    const shouldExpand = isTrialOrFree(planFamily);
    const expandedRows = shouldExpand ? getExpandedOrgUserIds(workload) : [];

    const additionalColumnCount = getAdditionalColumnCount(hasGeneralTeamBasedPlanning);
    const columnCount = dayRange + additionalColumnCount;
    const [expandedOrgUserIds, setExpandedOrgUserIds] = useState<Set<number>>(new Set<number>(expandedRows));
    const [deletionData, setDeletionData] = useState<SetItemDeletionParams | undefined>(undefined);
    const [moveItemId, setMoveItemId] = useState<number | undefined>(undefined);

    const workloadRows = getWorkloadRows({ expandedOrgUserIds, isFiltered, workload });

    useEffect(() => {
      gridRef.current?.resetAfterColumnIndex(0, true);
    }, [box]);

    const handleDeleteClose = () => {
      setDeletionData(undefined);
    };

    const handleToggleByOrgUserId = (orgUserId: number) => {
      if (expandedOrgUserIds.has(orgUserId)) {
        expandedOrgUserIds.delete(orgUserId);
      } else {
        expandedOrgUserIds.add(orgUserId);
      }

      setExpandedOrgUserIds(new Set(expandedOrgUserIds));
    };

    const handleCloseMoveModal = () => {
      fetchWorkload();
      setMoveItemId(undefined);
    };

    useEffect(() => {
      if (shouldExpandAllUsers) {
        const workLoadUserIds = workload?.groups.map((user) => user.id);
        setExpandedOrgUserIds(new Set(workLoadUserIds));
      }
    }, [shouldExpandAllUsers, workload?.groups]);

    if (!workload) {
      return null;
    }

    if (!box) {
      return <TrackingBoundingBox innerRef={ref} />;
    }

    return (
      <WorkloadTableContext.Provider
        value={{
          fetchWorkload,
          handleToggleByOrgUserId,
          setDeletionData,
          setMoveItemId,
          workloadId,
          dayRange,
          workloadRows,
          workload,
          setExpandedOrgUserIds,
          boundingBox: box,
          hasGeneralTeamBasedPlanning,
        }}
      >
        <div className="workload-view">
          {moveItemId && <ItemMoveModal onClose={handleCloseMoveModal} itemId={moveItemId} />}

          {deletionData && (
            <ConfirmDeleteModal
              itemId={deletionData.id}
              onDelete={deletionData.deleteItemClick}
              onClose={handleDeleteClose}
            />
          )}

          <TrackingBoundingBox innerRef={ref}>
            <VariableSizeGrid<WorkloadRows>
              ref={gridRef}
              rowHeight={() => ROW_HEIGHT_PIXELS}
              columnWidth={(columnIndex) =>
                getColumnWidthAndSetNameColumnWidth(
                  columnIndex,
                  dayRange,
                  columnCount,
                  box?.width ?? 0,
                  hasGeneralTeamBasedPlanning,
                )
              }
              innerElementType={StickyWorkloadTableElements}
              estimatedRowHeight={ROW_HEIGHT_PIXELS}
              height={box.height}
              width={box.width}
              rowCount={workloadRows.length}
              columnCount={columnCount}
              itemData={workloadRows}
            >
              {Cell}
            </VariableSizeGrid>
          </TrackingBoundingBox>
        </div>
      </WorkloadTableContext.Provider>
    );
  },
  areEqual,
);
