import { useSelector } from 'react-redux';

import { getCurrentOrganization } from 'state/entities/selectors/organization';
import { getCurrentOrganizationUser, getCurrentWorkspaceUser } from 'state/entities/selectors/user';

export const useHasTimesheetPermissions = () => {
  const orgUser = useSelector(getCurrentOrganizationUser);
  const workspaceUser = useSelector(getCurrentWorkspaceUser);
  const organization = useSelector(getCurrentOrganization);

  const hasUpgradedTimeTracking = !!organization?.flags.hasUpgradedTimeTracking;

  const isTimesheetReviewer = !!workspaceUser?.timesheetReviewer;
  const isTimesheetExporter = !!workspaceUser?.timesheetExporter;
  const isManager = !!workspaceUser?.manager;
  const isAdmin = !!orgUser?.admin;

  const canExportTimesheets = hasUpgradedTimeTracking && (isTimesheetExporter || isManager || isAdmin);
  const canReviewTimesheets = hasUpgradedTimeTracking && (isTimesheetReviewer || isManager || isAdmin);

  return { canExportTimesheets, canReviewTimesheets };
};
