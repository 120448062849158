import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReadonlyRecord } from 'lib/readonly_record';
import { resetStateExtraReducer } from 'state/root_actions';

export interface LocalValidationState {
  readonly validationErrors: ReadonlyRecord<string, string> | undefined;
}

export const initialState: LocalValidationState = {
  validationErrors: undefined,
};

const localValidationSlice = createSlice({
  name: 'localValidation',
  initialState,
  reducers: {
    clearLocalValidation: () => initialState,
    clearLocalValidationErrorForKey: (state, action: PayloadAction<string>) => {
      if (state.validationErrors) {
        delete state.validationErrors[action.payload];
      }
    },
    setLocalValidationError: (state, action: PayloadAction<{ key: string; message: string }>) => {
      if (!state.validationErrors) {
        state.validationErrors = {};
      }
      state.validationErrors[action.payload.key] = action.payload.message;
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { clearLocalValidation, clearLocalValidationErrorForKey, setLocalValidationError } =
  localValidationSlice.actions;

export default localValidationSlice.reducer;
