import { Signal, useSignal } from '@preact/signals-react';
import { FilePondErrorDescription, FilePondFile, registerPlugin } from 'filepond';
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useRef } from 'react';
import { FilePond, FilePondProps } from 'react-filepond';
import { useDispatch } from 'react-redux';

import { Model } from 'daos/model_types';
import { SuccessPayload } from 'lib/api/types';
import { mergeEntities } from 'state/entities/slice';
import { EntityState } from 'state/entities/types';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import './index.scss';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview);

type AllowedFilePondProps = Omit<FilePondProps, 'checkValidity' | 'credits' | 'fileValidateTypeLabelExpectedTypes'>;
interface LpFileInputProps<T> extends AllowedFilePondProps {
  onFileUploadComplete?: (data: T | null) => void;
  onFileUploadError?: (error: FilePondErrorDescription) => void;
}

export function LpFileInput<K extends keyof EntityState, T extends Model<K>>({
  server,
  allowProcess = false,
  allowRevert = false,
  allowMultiple = false,
  allowImagePreview = false,
  maxFiles = 10,
  maxParallelUploads = 3,
  maxFileSize = '100MB',
  acceptedFileTypes,
  labelIdle = `Drag & Drop up to ${maxFiles} files or <span class="filepond--label-action">Browse</span>`,
  name = 'file',
  onFileUploadComplete,
  onFileUploadError,
  ...rest
}: LpFileInputProps<T>) {
  const dispatch = useDispatch();
  const ref = useRef<FilePond>(null);
  const warningMessage = useSignal<string | null>(null);

  function onWarning(error: { code: number }) {
    switch (error.code) {
      case 0: {
        warningMessage.value = `Maximum files to upload at once is ${maxFiles}`;
        break;
      }
    }
  }

  function onProcessFile(error: FilePondErrorDescription | null, file: FilePondFile) {
    if (error) {
      onFileUploadError?.(error);
      return;
    }
    try {
      const { data, entities } = JSON.parse(file.serverId) as SuccessPayload<T>;
      const dataAsEntityPartial = { [data.type]: { [data.id]: data } };
      const newEntityState = Object.assign(entities ?? {}, dataAsEntityPartial);

      dispatch(mergeEntities(newEntityState));
      onFileUploadComplete?.(data);
      // eslint-disable-next-line unused-imports/no-unused-vars
    } catch (_error) {
      onFileUploadComplete?.(null);
    }
    setTimeout(() => {
      ref.current?.removeFile(file);
    }, 1000);
  }

  return (
    <div className="lp-filepond">
      <FilePond
        ref={ref}
        allowMultiple={allowMultiple}
        allowProcess={allowProcess}
        allowRevert={allowRevert}
        allowImagePreview={allowImagePreview}
        onwarning={onWarning}
        onprocessfile={onProcessFile}
        maxFiles={maxFiles}
        maxParallelUploads={maxParallelUploads}
        maxFileSize={maxFileSize}
        server={server}
        acceptedFileTypes={acceptedFileTypes}
        labelIdle={labelIdle}
        name={name}
        fileValidateTypeLabelExpectedTypes="Unexpected file type"
        checkValidity
        credits={false}
        {...rest}
      />
      <FilePondWarning warningMessage={warningMessage} />
    </div>
  );
}

const warningVariants: Variants = {
  initial: {
    opacity: 0,
    y: 20,
    x: '-50%',
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    y: 38,
    scale: 1,
  },
  exit: {
    opacity: 0,
    y: 20,
    scale: 0.9,
  },
};
const warningDuration = 0.5;
const errorMessageTimeoutSeconds = 3;
const errorMessageTimeoutMilliseconds = errorMessageTimeoutSeconds * 1000;
const timerDuration = errorMessageTimeoutSeconds + warningDuration;

interface FilePondWarningProps {
  warningMessage: Signal<string | null>;
}
function FilePondWarning({ warningMessage }: FilePondWarningProps) {
  return (
    <AnimatePresence>
      {warningMessage.value && (
        <motion.div
          className="lp-filepond--warning"
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            type: 'spring',
            duration: warningDuration,
          }}
          variants={warningVariants}
          onAnimationComplete={(definition) => {
            switch (definition) {
              case 'animate': {
                setTimeout(() => {
                  warningMessage.value = null;
                }, errorMessageTimeoutMilliseconds);
                break;
              }
            }
          }}
        >
          <div className="lp-filepond--warning-text">{warningMessage.peek()}</div>
          <motion.div
            initial={{ width: '100%', opacity: 1 }}
            animate={{ width: 0, opacity: [1, 0.7, 0] }}
            transition={{ duration: timerDuration, type: 'tween', ease: 'linear' }}
            className="lp-filepond--warning-timer"
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
