import { ReactElement } from 'react';
import { GridChildComponentProps } from 'react-window';

import { Workload } from 'daos/model_types';
import { WorkloadCellType, WorkloadRowData, WorkloadRows } from 'features/common/workload/workload_table/types';

const MIN_NAME_COLUMN_WIDTH = 500;
const MAX_NAME_COLUMN_WIDTH = 1000;
const REMAINING_COLUMN_WIDTH = 80;
const AVAILABILITY_USED_COLUMN_WIDTH = 100;
const AVAILABILITY_USED_COLUMN_TEAM_PLANNING_WIDTH = 130;
export const ROW_HEIGHT_PIXELS = 38;
export const BASE_NUBS_COLUMN_WIDTH = 12;
const ADDITIONAL_COLUMN_COUNT = 3;
const ADDITIONAL_TEAM_PLANNING_COLUMN_COUNT = 5;

export const getDayNubColumnsWidth = (dayRange: number) => BASE_NUBS_COLUMN_WIDTH * dayRange;
export const getTotalRemainingColumnWidth = (hasTeamBasedPlanning: boolean) =>
  hasTeamBasedPlanning ? REMAINING_COLUMN_WIDTH * 2 : REMAINING_COLUMN_WIDTH;
export const getTotalAvailabilityColumnWidth = (hasTeamBasedPlanning: boolean) =>
  hasTeamBasedPlanning ? AVAILABILITY_USED_COLUMN_TEAM_PLANNING_WIDTH * 2 : AVAILABILITY_USED_COLUMN_WIDTH;
export const getAdditionalColumnCount = (hasTeamBasedPlanning: boolean) =>
  hasTeamBasedPlanning ? ADDITIONAL_TEAM_PLANNING_COLUMN_COUNT : ADDITIONAL_COLUMN_COUNT;

export const getTotalNonNameColumnWidths = (dayRange: number, hasTeamBasedPlanning: boolean) =>
  getDayNubColumnsWidth(dayRange) +
  getTotalRemainingColumnWidth(hasTeamBasedPlanning) +
  getTotalAvailabilityColumnWidth(hasTeamBasedPlanning);

export const getNameColumnWidth = ({
  boxWidth,
  totalOtherColumnWidths,
}: {
  boxWidth: number;
  totalOtherColumnWidths: number;
}) => {
  return Math.min(Math.max(boxWidth - totalOtherColumnWidths, MIN_NAME_COLUMN_WIDTH), MAX_NAME_COLUMN_WIDTH);
};

export const getColumnWorkloadCellType = ({
  columnIndex,
  columnCount,
  hasGeneralTeamBasedPlanning,
}: {
  columnIndex: number;
  columnCount: number;
  hasGeneralTeamBasedPlanning: boolean;
}) => {
  const additionalColumnCount = getAdditionalColumnCount(hasGeneralTeamBasedPlanning);

  const isNameColumn = columnIndex === 0;
  const isDateNubColumn = columnIndex > 0 && columnIndex <= columnCount - additionalColumnCount;
  const isScheduledHoursColumn = hasGeneralTeamBasedPlanning
    ? columnIndex === columnCount - 4
    : columnIndex === columnCount - 2;
  const isAvailabilityScheduledColumn = hasGeneralTeamBasedPlanning
    ? columnIndex === columnCount - 2
    : columnIndex === columnCount - 1;
  const isPlannedHoursColumn = hasGeneralTeamBasedPlanning && columnIndex === columnCount - 3;
  const isAvailabilityPlannedColumn = hasGeneralTeamBasedPlanning && columnIndex === columnCount - 1;

  if (isNameColumn) {
    return WorkloadCellType.Name;
  } else if (isDateNubColumn) {
    return WorkloadCellType.DateNub;
  } else if (isScheduledHoursColumn) {
    return WorkloadCellType.ScheduledHours;
  } else if (isPlannedHoursColumn) {
    return WorkloadCellType.PlannedHours;
  } else if (isAvailabilityScheduledColumn) {
    return WorkloadCellType.AvailabilityScheduled;
  } else if (isAvailabilityPlannedColumn) {
    return WorkloadCellType.AvailabilityPlanned;
  }
};

export function getColumnWidthAndSetNameColumnWidth(
  columnIndex: number,
  dayRange: number,
  columnCount: number,
  width: number,
  hasGeneralTeamBasedPlanning: boolean,
) {
  // sum the combined widths so that the name column will take up exactly how much is left
  const totalOtherWidths = getTotalNonNameColumnWidths(dayRange, hasGeneralTeamBasedPlanning);
  const nameColumnWidth = getNameColumnWidth({ boxWidth: width, totalOtherColumnWidths: totalOtherWidths });

  /**
   * This sets the name column width and allows the column to expand/shrink based on screen width within given min/max constraints.
   */
  document.documentElement.style.setProperty('--lp-workload-name-column-width', `${nameColumnWidth}px`);

  const workloadCellType = getColumnWorkloadCellType({
    columnCount,
    columnIndex,
    hasGeneralTeamBasedPlanning,
  });

  switch (workloadCellType) {
    case WorkloadCellType.Name:
      return nameColumnWidth;
    case WorkloadCellType.DateNub:
      return BASE_NUBS_COLUMN_WIDTH;
    case WorkloadCellType.ScheduledHours:
    case WorkloadCellType.PlannedHours:
      return REMAINING_COLUMN_WIDTH;
    case WorkloadCellType.AvailabilityScheduled:
    case WorkloadCellType.AvailabilityPlanned: {
      if (hasGeneralTeamBasedPlanning) {
        return AVAILABILITY_USED_COLUMN_TEAM_PLANNING_WIDTH;
      }
      return AVAILABILITY_USED_COLUMN_WIDTH;
    }
    default:
      return 0;
  }
}

export const getRenderedRowRange = (children: Array<ReactElement<GridChildComponentProps>>) =>
  children.reduce(
    ({ minRow, maxRow }, { props: { rowIndex } }) => {
      if (rowIndex < minRow) {
        minRow = rowIndex;
      }
      if (rowIndex > maxRow) {
        maxRow = rowIndex;
      }

      return { minRow, maxRow };
    },
    {
      minRow: Number.POSITIVE_INFINITY,
      maxRow: Number.NEGATIVE_INFINITY,
    },
  );

export const getWorkloadRows = ({
  expandedOrgUserIds,
  isFiltered,
  workload,
}: {
  expandedOrgUserIds: Set<number>;
  isFiltered: boolean;
  workload: Workload | undefined;
}) => {
  return (
    workload?.groups.reduce<Array<WorkloadRowData>>((acc, curr) => {
      const orgUserId = curr.organizationUser?.id;

      if (!orgUserId) {
        return acc;
      }

      acc.push({
        group: curr,
      });

      const isExpand = expandedOrgUserIds.has(orgUserId);

      const hideEmptyFilteredUser = isFiltered && !curr.workloadItems.length;

      if (hideEmptyFilteredUser) {
        return acc;
      }

      if (isExpand) {
        curr.workloadItems.forEach((item) => {
          acc.push({ item });
        });
      }

      return acc;
    }, []) ?? []
  );
};

export const getExpandedOrgUserIds = (workload: Workload | undefined) => {
  return (
    workload?.groups.reduce<Array<number>>((acc, curr) => {
      if (curr.organizationUser) {
        acc.push(curr.organizationUser.id);
      }

      return acc;
    }, []) ?? []
  );
};

interface StickyCellRenderData extends WorkloadRowData {
  rowIndex: number;
}

export const getRenderedStickyColumnRowData = ({
  maxRow,
  minRow,
  workloadRows,
}: {
  maxRow: number;
  minRow: number;
  workloadRows: WorkloadRows;
}) => {
  const newDataSlice: Array<StickyCellRenderData> = [];

  for (let i = minRow; i <= maxRow; i++) {
    const row = workloadRows[i];
    if (row) {
      newDataSlice.push({
        ...row,
        rowIndex: i,
      });
    }
  }

  return newDataSlice;
};
