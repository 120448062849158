import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router';

import { JiraAuthorizationDao } from 'daos/jira_authorization';
import { JiraOauthResponse } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import PageNotFound from 'features/common/errors/page_not_found';
import { appendQueryParamToRelativeUrl } from 'features/jira_project/modal/utils';
import { useHasFeature } from 'hooks/use_has_feature';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import { FeatureFlag } from 'lib/feature_flags';
import { frontend } from 'lib/urls';
import { getIsSlackIntegrationSupportedByEnvironment } from 'state/entities/selectors/system_settings';
import { getCurrentOrganizationUserId } from 'state/entities/selectors/user';

const IntegrationsRoutes = () => {
  const history = useHistory();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const dispatch = useDispatch();
  const organizationUserId = useSelector(getCurrentOrganizationUserId);
  const environmentSupportsSlackIntegration = useSelector(getIsSlackIntegrationSupportedByEnvironment);
  const hasJiraIntegration = useHasFeature(FeatureFlag.jiraIntegration);
  const { state, code, error } = useQueryParams();
  const isJiraCallback = !!useRouteMatch(frontend.jiraIntegrationsCallback.pattern) && state && code;

  const [returnUrl, setReturnUrl] = useState('');
  const currentUrl = window.location.href;

  const jiraOAuthResponse = useCallback(() => {
    if (error === 'access_denied') {
      history.push('/');
      return;
    }
    if (!isJiraCallback) {
      return;
    }
    const jiraOauthResponseFn = JiraAuthorizationDao.jiraOAuthResponse(
      { organizationId, workspaceId },
      { url: currentUrl },
    );
    const { uuid } = dispatch(jiraOauthResponseFn);
    dispatch(
      awaitRequestFinish<JiraOauthResponse>(uuid, {
        onSuccess: ({ data: { returnUrl, oauthCredentialsId } }) => {
          const newUrl = appendQueryParamToRelativeUrl(returnUrl, { oauthCredentialsId });
          setReturnUrl(newUrl);
        },
      }),
    );
  }, [isJiraCallback, dispatch, organizationId, workspaceId, currentUrl, error, history]);

  useEffect(() => {
    jiraOAuthResponse();
  }, [jiraOAuthResponse]);

  return (
    <Switch>
      {environmentSupportsSlackIntegration && (
        <Route exact path={frontend.slackIntegrations.pattern}>
          <Redirect to={frontend.organizationHubUsersSlackIntegrations.url({ organizationId, organizationUserId })} />
        </Route>
      )}
      {hasJiraIntegration && (
        <>
          <Route exact path={frontend.jiraIntegrations.pattern}>
            <Redirect to={frontend.organizationHubUsersJiraIntegrations.url({ organizationId, organizationUserId })} />
          </Route>
          {returnUrl && (
            <Route exact path={frontend.jiraIntegrationsCallback.pattern}>
              <Redirect to={returnUrl} />
            </Route>
          )}
        </>
      )}

      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default IntegrationsRoutes;
