import { useSelector } from 'react-redux';

import { ResourceId } from 'daos/model_types';
import { SupportedResources } from 'features/common/custom_fields/types';
import { FieldWithValueAndChoices, getFieldsAndValuesForResourceId } from 'state/entities/selectors/custom_field';

export const useGetCustomFieldByFieldIdAndResourceId = (
  fieldId: number,
  resourceId: ResourceId<SupportedResources>,
): FieldWithValueAndChoices | undefined => {
  const fieldContent = useSelector((state) => getFieldsAndValuesForResourceId(state, resourceId));
  const field = fieldContent[fieldId];

  return field;
};
