import { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'semantic-ui-react';
import * as yup from 'yup';

import LpFormError from 'containers/shared/form_errors/lp_form_error';
import { User } from 'daos/model_types';
import { UserDao } from 'daos/user';
import LpModal from 'features/common/modals/lp_modal';
import { awaitRequestFinish } from 'lib/api';
import { MAX_EMAIL_LENGTH } from 'lib/constants';

const UpdateLoginModal = ({ onClose, user }: { onClose: () => void; user: User }) => {
  const dispatch = useDispatch();
  const [newEmail, setNewEmail] = useState('');
  const [emailError, setEmailError] = useState<string | undefined>(undefined);

  const schema = yup.object().shape({
    email: yup
      .string()
      .max(MAX_EMAIL_LENGTH)
      .email()
      .required()
      .notOneOf([user.email], "Email address can't be the same"),
  });

  const handleChange = (_: SyntheticEvent, { value }: { value: string }) => {
    setNewEmail(value);
  };

  const handleSave = (_: SyntheticEvent) => {
    setEmailError(undefined);

    schema
      .validate({ email: newEmail })
      .then(() => {
        const { uuid } = dispatch(UserDao.update({ userId: user.id }, { id: user.id, email: newEmail }));
        dispatch(
          awaitRequestFinish(uuid, {
            onSuccess: () => onClose(),
            onError: ({ errors }) => {
              if (errors[0]) {
                setEmailError(`${errors[0].title}: ${errors[0].detail}`);
              }
            },
          }),
        );
      })
      .catch((err: yup.ValidationError) => {
        setEmailError(err.message);
      });
  };

  return (
    <LpModal
      className="update-login-modal"
      size="tiny"
      header={'Change Email'}
      content={
        <>
          Current Email: {user.email}
          <label htmlFor="user-email">New Email</label>
          <Input id="user-email" autoFocus value={newEmail} onChange={handleChange} />
          {!!emailError && <LpFormError error={emailError} />}
          <p>
            We’ll send a confirmation to your current email address to verify this change. Email change requests expire
            after 72 hours.
          </p>
        </>
      }
      actions={
        <>
          <Button content={'Cancel'} onClick={onClose} />
          <Button primary content={'Send Confirmation'} onClick={handleSave} />
        </>
      }
      onClose={onClose}
    />
  );
};

export default UpdateLoginModal;
