import { CustomFieldDao } from 'daos/custom_field';
import { CustomField, CustomFieldValue, ResourceId, PicklistChoice, WorkspaceUser } from 'daos/model_types';

type MultiFieldValueKey = keyof Pick<CustomFieldValue, 'picklistChoice' | 'workspaceUserValue'>;

export const removeFieldValue =
  (existingFieldValue: Partial<CustomFieldValue>, fieldValueKey: MultiFieldValueKey) =>
  (prevState: ReadonlyArray<Partial<CustomFieldValue>>) =>
    prevState.filter((value) => value[fieldValueKey]?.id !== existingFieldValue[fieldValueKey]?.id);

export const updateFieldValue =
  (
    existingFieldValue: Partial<CustomFieldValue>,
    newResource: ResourceId<PicklistChoice | WorkspaceUser>,
    fieldValueKey: MultiFieldValueKey,
  ) =>
  (prevState: ReadonlyArray<Partial<CustomFieldValue>>) =>
    prevState.reduce((acc: Array<Partial<CustomFieldValue>>, value) => {
      if (value[fieldValueKey]?.id === existingFieldValue[fieldValueKey]?.id) {
        const updatedValue = { ...value, [fieldValueKey]: newResource };
        acc.push(updatedValue);
      } else {
        acc.push(value);
      }
      return acc;
    }, []);

export const addFieldValue =
  (
    newResource: ResourceId<PicklistChoice | WorkspaceUser>,
    fieldValueKey: MultiFieldValueKey,
    customField: CustomField,
  ) =>
  (prevState: ReadonlyArray<Partial<CustomFieldValue>>) => {
    const newFieldValue: Partial<CustomFieldValue> = {
      [fieldValueKey]: newResource,
      fieldType: customField.fieldType,
      field: CustomFieldDao.id(customField.id),
    };

    return [...prevState, newFieldValue];
  };

export const getSelectedFieldValueTypeIds = (
  fieldValues: ReadonlyArray<Partial<CustomFieldValue>>,
  fieldValueKey: MultiFieldValueKey,
) => {
  const ids = new Set<number>();
  fieldValues.forEach((fieldValue) => ids.add(fieldValue[fieldValueKey]?.id ?? 0));

  return ids;
};

export const getAvailableFieldValueOptions =
  <T extends { id: number }>(selectedIds: Set<number>, options: ReadonlyArray<T>) =>
  (selectedId: number | undefined) =>
    options.filter((option) => option.id === selectedId || !selectedIds.has(option.id));

export const getOrgUserIdsForWsUserIds = (wsUserIds: Set<number>, orgUserIdsByWsUserId: Record<number, number>) => {
  const orgUserIds = new Set<number>();
  wsUserIds.forEach((wsUserId) => orgUserIds.add(orgUserIdsByWsUserId[wsUserId] ?? 0));

  return orgUserIds;
};
