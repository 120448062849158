import { bottom, createPopper, Instance, StrictModifiers } from '@popperjs/core';
import { Placement } from '@popperjs/core/lib/enums';
import { Modifier } from '@popperjs/core/lib/types';
import { noop } from 'lodash';
import { RefObject, useContext, useEffect } from 'react';

import { ScrollContext } from 'containers/shared/helpers/scroll_context';
import { getPopperFlipMutationObserver } from 'features/common/inputs/dropdowns/helpers';
import { ClickLocation } from 'hooks/use_click_location_for_context_menu';

export function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 1,
    height: 1,
    top: y,
    right: x,
    bottom: y,
    left: x,
    x,
    y,
    toJSON: noop,
  });
}

export function createPopperOverTrigger(
  dropdown: HTMLElement,
  trigger: HTMLElement,
  triggerPopupPlacement: Placement = bottom,
  popperHeightOffset?: number,
  additionalModifier?: Array<Modifier<string, any> | StrictModifiers>,
) {
  const triggerRect = trigger.getBoundingClientRect();

  const getPopperOffset = () => {
    if (popperHeightOffset) {
      return [0, -triggerRect.height + popperHeightOffset];
    }
  };

  const offsetModifier = {
    name: 'offset',
    options: {
      offset: getPopperOffset() ?? [0, -triggerRect.height],
    },
  };

  const additionalModifiers = [];

  if (additionalModifier) {
    additionalModifiers.push(...additionalModifier);
  }

  return createPopper(trigger, dropdown, {
    placement: triggerPopupPlacement,
    strategy: 'fixed',
    modifiers: [offsetModifier, ...additionalModifiers],
  });
}

function createPopperOverClickLocation(dropdown: HTMLElement, clickLocation: ClickLocation) {
  const virtualElement = {
    getBoundingClientRect: generateGetBoundingClientRect(clickLocation.left, clickLocation.top),
  };

  return createPopper(virtualElement, dropdown, {
    placement: 'right-start',
    strategy: 'fixed',
  });
}

export const usePopper = (
  dropdownRef: RefObject<HTMLElement>,
  onScrollHandler: (this: HTMLDivElement, ev: Event) => any,
  triggerRef?: RefObject<HTMLElement>,
  clickLocation?: ClickLocation,
  triggerPopupPlacement?: Placement,
  popperHeightOffset?: number,
  additionalModifier?: Array<Modifier<string, any> | StrictModifiers>,
) => {
  const { scrollableElement: scrollableElementRef } = useContext(ScrollContext);

  useEffect(() => {
    const dropdown = dropdownRef.current;
    const trigger = triggerRef?.current;

    if (!dropdown || (!trigger && !clickLocation)) {
      return;
    }

    const observer = getPopperFlipMutationObserver();
    const scrollableElement = scrollableElementRef?.current;

    let instance: Instance;

    if (trigger && !clickLocation) {
      instance = createPopperOverTrigger(
        dropdown,
        trigger,
        triggerPopupPlacement,
        popperHeightOffset,
        additionalModifier,
      );
    } else if (clickLocation) {
      instance = createPopperOverClickLocation(dropdown, clickLocation);
    }

    requestAnimationFrame(() => {
      instance.forceUpdate();
      if (dropdown) {
        dropdown.style.opacity = '1';
      }
    });

    observer.observe(dropdown, { attributeFilter: ['data-popper-placement'] });
    scrollableElement?.addEventListener('scroll', onScrollHandler);

    return () => {
      observer.disconnect();
      scrollableElement?.removeEventListener('scroll', onScrollHandler);
    };
  });
};
