/* eslint-disable check-file/filename-naming-convention */
import { headerRenderer } from 'react-data-grid';
import { useSelector } from 'react-redux';

import { getDataGridItemIds } from 'features/common/data_grid/selectors';
import { ItemHeaderFormatterProps } from 'features/common/data_grid/types';
import { ScheduleViewType, useItemsDateRange } from 'hooks/use_items_date_range';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';

const ScheduleBarHeader = () => {
  const itemIds = useSelector(getDataGridItemIds);
  const { formatLocalDate } = useLocalizedFormats();

  const { start, finish } = useItemsDateRange(itemIds, { viewType: ScheduleViewType.Grid });

  const dateRangeDisplay = `${formatLocalDate(start.toISOString())} - ${formatLocalDate(finish.toISOString())}`;

  return <>{dateRangeDisplay}</>;
};

export const SortableScheduleBarHeader = (props: ItemHeaderFormatterProps) => {
  const scheduleBarHeaderDateRange = ScheduleBarHeader();
  const scheduleBarHeaderProps = { ...props, column: { ...props.column, name: scheduleBarHeaderDateRange } };

  return headerRenderer(scheduleBarHeaderProps);
};
