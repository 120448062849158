import { useState, useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import LpSearchInputControlled from 'features/common/inputs/lp_search_input/lp_search_input_controlled';
import {
  arrowAltSquareLeftSolid as removeSelectionIcon,
  arrowAltSquareRightSolid as addSelectionIcon,
} from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { SelectionList } from 'features/common/selection_list/types';
import { useDirtyInputUpdate } from 'hooks/use_dirty_input_update';
import { usePortal } from 'hooks/use_portal';
import { moveElementFromSourceToDestinationArray } from 'lib/helpers/move_array_element';
import { lpRed, slate500 } from 'style/variables';

import { filteredOptionsByName, multiSelectCompareDisplayName } from './multi_select_helpers';
import SelectionListHeader from './selection_table/selection_header';
import SelectionListBody, { SelectionLocation } from './selection_table/selection_list';

import './multi_select_list_modal.scss';

interface MultiSelectModalProps {
  closeModal: () => void;
  initAvailableOptions: ReadonlyArray<SelectionList>;
  initSelectedOptions: ReadonlyArray<SelectionList>;
  resetColumnsOptions: { selected: ReadonlyArray<SelectionList>; available: ReadonlyArray<SelectionList> };
  onCloseAction: (availableOptions: Array<SelectionList>, selectedOptions: Array<SelectionList>) => void;
  availableTitle?: string;
  selectedTitle?: string;
  dragAndDropEnabled?: boolean;
  noSelectedOptionsContent?: string;
}

const MultiSelectModal = ({
  closeModal,
  initAvailableOptions,
  initSelectedOptions,
  onCloseAction,
  resetColumnsOptions,
  availableTitle,
  selectedTitle,
  dragAndDropEnabled = true,
  noSelectedOptionsContent = '',
}: MultiSelectModalProps) => {
  const [availableOptions, setAvailableOptions] = useState<Array<SelectionList>>(
    [...initAvailableOptions].sort(multiSelectCompareDisplayName),
  );

  const selectedOptionsInitialOptions = dragAndDropEnabled
    ? [...initSelectedOptions]
    : [...initSelectedOptions].sort(multiSelectCompareDisplayName);

  const [selectedOptions, setSelectedOptions] = useState<Array<SelectionList>>(selectedOptionsInitialOptions);
  const hasSelectedOptions = selectedOptions.length;

  const [filterString, setFilterString] = useState<string>('');

  const updateOptionsLists = useCallback(
    ({
      location,
      id,
      source,
      destination,
      setSource,
      setDestination,
    }: {
      location: SelectionLocation;
      id: string;
      source: Array<SelectionList>;
      destination: Array<SelectionList>;
      setSource: (value: Array<SelectionList>) => void;
      setDestination: (value: Array<SelectionList>) => void;
    }) => {
      const indexToMove = source.findIndex((option) => option.id === id);
      const { modifiedSource, modifiedDestination } = moveElementFromSourceToDestinationArray({
        source,
        destination,
        indexToMove,
      });

      location === SelectionLocation.SELECTED && modifiedDestination.sort(multiSelectCompareDisplayName);

      setSource([...modifiedSource]);
      setDestination([...modifiedDestination]);
    },
    [],
  );

  const moveItemToAvailableList = useCallback(
    (id: string) =>
      updateOptionsLists({
        location: SelectionLocation.SELECTED,
        id,
        source: selectedOptions,
        destination: availableOptions,
        setSource: setSelectedOptions,
        setDestination: setAvailableOptions,
      }),
    [availableOptions, selectedOptions, updateOptionsLists],
  );
  const moveItemToSelectedList = useCallback(
    (id: string) =>
      updateOptionsLists({
        location: SelectionLocation.AVAILABLE,
        id,
        source: availableOptions,
        destination: selectedOptions,
        setSource: setAvailableOptions,
        setDestination: setSelectedOptions,
      }),
    [availableOptions, selectedOptions, updateOptionsLists],
  );

  const { isDirty, inputValue, onChange, clearInput } = useDirtyInputUpdate({
    onChangeCallback: (value: string) => {
      setFilterString(value);
    },
  });

  const handleClearInputFieldFromParent = () => {
    setFilterString('');
    clearInput();
  };

  const handleAddAll = () => {
    setAvailableOptions([]);
    setSelectedOptions([...selectedOptions, ...availableOptions]);
    handleClearInputFieldFromParent();
  };

  const handleReset = () => {
    setAvailableOptions([...resetColumnsOptions.available].sort(multiSelectCompareDisplayName));
    setSelectedOptions([...resetColumnsOptions.selected]);
    handleClearInputFieldFromParent();
  };

  const handleSubmit = () => {
    onCloseAction(availableOptions, selectedOptions);
  };

  usePortal();

  return (
    <LpModal
      onClose={closeModal}
      className="multi-select-list-modal"
      header={
        <div className="multi-select-list-modal__search">
          <LpSearchInputControlled
            initialValue={inputValue}
            isDirty={isDirty}
            onChange={onChange}
            onClear={clearInput}
          />
        </div>
      }
      content={
        <div className="multi-select-list-modal__content">
          <div
            className="multi-select-list-modal__content-list"
            data-testid="multi-select-list-modal__available-options"
          >
            <SelectionListHeader title={availableTitle ?? 'Available Columns'} />
            <div className="multi-select-list-modal__content-list-body">
              <SelectionListBody
                icon={addSelectionIcon}
                iconColor={slate500}
                listOfOptions={filteredOptionsByName(availableOptions, filterString)}
                onItemClick={moveItemToSelectedList}
              />
            </div>
          </div>
          <div
            className="multi-select-list-modal__content-list"
            data-testid="multi-select-list-modal__selected-options"
          >
            <SelectionListHeader title={selectedTitle ?? 'Selected Columns'} />
            {hasSelectedOptions ? (
              <div className="multi-select-list-modal__content-list-body">
                <SelectionListBody
                  draggable={!inputValue && dragAndDropEnabled}
                  icon={removeSelectionIcon}
                  iconColor={lpRed}
                  listOfOptions={filteredOptionsByName(selectedOptions, filterString)}
                  onItemClick={moveItemToAvailableList}
                  setSelectedOptions={setSelectedOptions}
                />
              </div>
            ) : (
              <div className="multi-select-list-modal__content-list-body--empty">{noSelectedOptionsContent}</div>
            )}
          </div>
        </div>
      }
      actions={
        <div className="multi-select-list-modal__actions-section">
          <div>
            <Button
              className="multi-select-list-modal__actions-section-add-all"
              content="Add All"
              data-testid="add-all-button"
              onClick={handleAddAll}
            />
            <Button content="Reset" onClick={handleReset} data-testid="reset-button" />
          </div>
          <div>
            <Button
              className="multi-select-list-modal__actions-section-cancel"
              data-testid="cancel-button"
              content="Cancel"
              onClick={closeModal}
            />
            <Button
              className="multi-select-list-modal__actions-section-save"
              primary
              content="Save"
              data-testid="save-button"
              onClick={handleSubmit}
            />
          </div>
        </div>
      }
    />
  );
};

export default MultiSelectModal;
