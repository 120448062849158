import { createCachedSelector } from 're-reselect';

import { getItemForId } from './item';
import { getItemMetricsForId } from './item_metric';
import { createCacheByIdConfig } from './shared';

export const getWorkLimitValuesForItemId = createCachedSelector(
  getItemForId,
  getItemMetricsForId,
  (item, itemMetric) => {
    const workLimit = item?.workLimit ?? null;
    const workLimitDelta = itemMetric?.workLimitDelta ?? null;
    const workLimitAlert = itemMetric?.workLimitAlert ?? false;

    return {
      hasWorkLimitAlert: workLimitAlert,
      workLimit,
      workLimitDelta,
    };
  },
)(createCacheByIdConfig());
