import classNames from 'classnames';
import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { Comment, Ref } from 'semantic-ui-react';

import {
  ChangeActionType,
  ChangeModelType,
  PropertyChangeHistoryAction,
  PropertyChangeHistoryProperties,
} from 'daos/enums';
import { PropertyChangeHistory } from 'daos/types';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import ChangeCardProperty from 'features/common/changes/change_card_property';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getChangeForId } from 'state/entities/selectors/changes';
import { getOrganizationUserInCurrentOrgForUserId } from 'state/entities/selectors/user';

import { ChangeCardDescription } from './change_card_description';
import { OriginRow } from './change_origin';

import 'features/item_panel/sections/changes_section/change.scss';

interface UpdateChangeRowProps {
  changeId: number;
  propertyChange: PropertyChangeHistory;
}
const UpdateChangeRow = ({ changeId, propertyChange }: UpdateChangeRowProps) => {
  const change = useSelector((state) => getChangeForId(state, changeId));

  if (!change) {
    return null;
  }

  const REPRIORITIZED = 'Reprioritized';

  return (
    <div className={classNames('lp-change__card-content-table-update-change-cell')}>
      <div className="change-property__name">{propertyChange.property}:</div>
      {propertyChange.property !== REPRIORITIZED && (
        <>
          <div className={'change-property__old-description'}>
            <ChangeCardProperty
              itemId={change.item.id}
              changeId={changeId}
              propertyChangeData={propertyChange[PropertyChangeHistoryAction.Old]}
              property={propertyChange.property}
              resource={change.record}
            />
          </div>
          <div>TO</div>
        </>
      )}
      <div className={'change-property__new-description'}>
        <ChangeCardProperty
          itemId={change.item.id}
          changeId={changeId}
          propertyChangeData={propertyChange[PropertyChangeHistoryAction.New]}
          property={propertyChange.property}
          resource={change.record}
        />
      </div>
    </div>
  );
};

interface InsertOrDeleteChangeRowProps {
  changeId: number;
  propertyChange: PropertyChangeHistory;
}

const doNotShowItemFileChangePropertyOnInsertOrDelete = (
  changeRecordType: ChangeModelType,
  changeProperty: PropertyChangeHistoryProperties,
) => {
  return changeRecordType === ChangeModelType.ItemFiles && changeProperty === PropertyChangeHistoryProperties.Item;
};

const InsertOrDeleteChangeRow = ({ changeId, propertyChange }: InsertOrDeleteChangeRowProps) => {
  const change = useSelector((state) => getChangeForId(state, changeId));

  if (!change || doNotShowItemFileChangePropertyOnInsertOrDelete(change.record.type, propertyChange.property)) {
    return null;
  }

  const changePropertyToShow =
    change.changeType === ChangeActionType.DELETE ? PropertyChangeHistoryAction.Old : PropertyChangeHistoryAction.New;

  return (
    <div className={classNames('lp-change__card-content-table-simple-description-cell')}>
      <div className="change-property__name">{propertyChange.property}:</div>
      <div className={'change-property__description'}>
        <ChangeCardProperty
          itemId={change.item.id}
          changeId={changeId}
          propertyChangeData={propertyChange[changePropertyToShow]}
          property={propertyChange.property}
          resource={change.record}
        />
      </div>
    </div>
  );
};

interface BaseChangeRowProps {
  changeId: number;
  propertyChange: PropertyChangeHistory;
}
const BaseChangeRow = ({ changeId, propertyChange }: BaseChangeRowProps) => {
  const change = useSelector((state) => getChangeForId(state, changeId));
  const changeType = change?.changeType;

  if (changeType === ChangeActionType.UPDATE) {
    return <UpdateChangeRow changeId={changeId} propertyChange={propertyChange} />;
  }
  return <InsertOrDeleteChangeRow changeId={changeId} propertyChange={propertyChange} />;
};

const ChangeDate = ({ changeId }: { changeId: number }) => {
  const { formatLocalDate } = useLocalizedFormats();
  const change = useSelector((state) => getChangeForId(state, changeId));

  if (!change) {
    return <></>;
  }

  return <>{formatLocalDate(change.date)}</>;
};

const ChangeTime = ({ changeId }: { changeId: number }) => {
  const { formatLocalTimeFromDate } = useLocalizedFormats();
  const change = useSelector((state) => getChangeForId(state, changeId));

  if (!change) {
    return <></>;
  }
  return <>{formatLocalTimeFromDate(change.date)}</>;
};

const ChangeData = ({ changeId }: { changeId: number }) => {
  const change = useSelector((state) => getChangeForId(state, changeId));
  const changedByUserId = change?.changedBy?.id ?? 0;
  const orgUserId = useSelector((state) => getOrganizationUserInCurrentOrgForUserId(state, changedByUserId))?.id;

  if (!change) {
    return <></>;
  }

  return (
    <div className="lp-change">
      <div className="lp-change__date">
        <div>
          <ChangeDate changeId={changeId} />
        </div>
        <div className="lp-change__date-time">
          <ChangeTime changeId={changeId} />
        </div>
      </div>

      <Comment className="lp-change__card">
        {orgUserId && <UserAvatar className="lp-change__card-image" orgUserId={orgUserId} size={AvatarSize.Medium} />}

        <div className="lp-change__card-content-info">
          <div className="lp-change__card-content-change-description">
            <ChangeCardDescription changeId={changeId} />
          </div>
          <div className="lp-change__card-content-table">
            <OriginRow changeId={changeId} />
            <div>
              {change.propertyChanges.map((propertyChange, index) => (
                <BaseChangeRow key={`${changeId}-${index}`} changeId={changeId} propertyChange={propertyChange} />
              ))}
            </div>
          </div>
        </div>
      </Comment>
    </div>
  );
};

interface ChangeCardProps {
  changeId: number;
  childRef?: RefObject<HTMLElement>;
}
export const ChangeCard = ({ changeId, childRef }: ChangeCardProps) => {
  const change = useSelector((state) => getChangeForId(state, changeId));

  if (!change) {
    return null;
  }

  const changeData = <ChangeData changeId={changeId} />;

  return <>{childRef ? <Ref innerRef={childRef}>{changeData}</Ref> : changeData}</>;
};
