import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps, Form, StrictDropdownProps } from 'semantic-ui-react';

import TaskIconMaker from 'containers/shared/task_icon_maker';
import { ScheduleDirective } from 'daos/enums';
import { File, RateSheet } from 'daos/model_types';
import { backend } from 'daos/urls';
import { LpMotionFadeIn } from 'features/common/animated_divs';
import { LpFormikDropdown } from 'features/common/forms/formik/dropdown';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { ScheduleDirectiveDropdown } from 'features/common/inputs/dropdowns/schedule_directive_dropdown';
import { LpFileInput } from 'features/common/inputs/file_input/lp_file_input';
import { LpIcon, circleXmarkLight } from 'features/common/lp_icon';
import { useIntakeFormField } from 'features/dashboards_v2/widget_click_through/intake/form/helpers';
import { useUpdateAndRemoveMultiFormFieldValues } from 'features/dashboards_v2/widget_click_through/intake/form/section/table/custom_field_row/use_update_and_remove_custom_field_values';
import { FileIconAndNameRenderer } from 'features/item_panel/sections/files_section/file_icon_and_name';
import { getCurrentWorkspaceRateSheetsSortedByName } from 'state/entities/selectors/rate_sheet';
import { getActiveCurrentWorkspaceTaskStatusesSortedByPriority } from 'state/entities/selectors/task_status';

import './inputs.scss';

interface IntakeItemFieldInputProps {
  formikKey: string;
  disabled: boolean;
  autoFocus?: boolean;
}

export const IntakeFormDropdown = ({
  formikKey,
  options,
  disabled,
}: {
  formikKey: string;
  options: Array<DropdownItemProps>;
  disabled: boolean;
}) => {
  return (
    <PortalDropdown>
      <LpFormikDropdown
        hideErrorMessage
        showError
        disabled={disabled}
        name={formikKey}
        options={options}
        selection
        placeholder="Select"
        restFieldErrorOnChange
        className="intake-widget-user-form-section__field-dropdown"
      />
    </PortalDropdown>
  );
};

export const IntakeWorkLimitInput = ({ formikKey, disabled, autoFocus }: IntakeItemFieldInputProps) => {
  const { getFieldProps, setFieldValue, getFieldMeta } = useFormikContext();
  const { value } = getFieldProps(formikKey);
  const fieldMeta = getFieldMeta<React.ReactNode>(formikKey);

  const handleChange = (_: SyntheticEvent, { value }: { value: string }) => {
    setFieldValue(formikKey, value);
  };

  return (
    <Form.Input
      disabled={disabled}
      error={!!fieldMeta.error}
      placeholder="Hours"
      className="work-limit-input"
      value={value ?? ''}
      onChange={handleChange}
      autoFocus={autoFocus}
    />
  );
};

export const IntakeTaskStatusDropdown = ({ formikKey, disabled }: IntakeItemFieldInputProps) => {
  const activeTaskStatuses = useSelector(getActiveCurrentWorkspaceTaskStatusesSortedByPriority);
  const taskStatusOptions = activeTaskStatuses.map((ts) => ({
    disabled: ts.archived,
    text: (
      <span>
        <TaskIconMaker taskStatusId={ts.id} hasToolTip={false} /> {ts.name}
      </span>
    ),
    value: ts.id,
    search: ts.name.toLocaleLowerCase(),
    key: ts.id,
  }));

  return <IntakeFormDropdown formikKey={formikKey} options={taskStatusOptions} disabled={disabled} />;
};

export const IntakeScheduleDirectiveDropdown = ({ formikKey, disabled }: IntakeItemFieldInputProps) => {
  const { getFieldProps, setFieldValue, getFieldMeta } = useFormikContext();
  const fieldProps = getFieldProps(formikKey);
  const fieldMeta = getFieldMeta(formikKey);

  const scheduleDirective = fieldProps.value;

  const handleChange = (_: SyntheticEvent, { value }: StrictDropdownProps) => {
    setFieldValue(formikKey, value as ScheduleDirective);
  };

  return (
    <ScheduleDirectiveDropdown
      usePortal
      hasError={fieldMeta.touched && !!fieldMeta.error}
      onChange={handleChange}
      scheduleDirective={scheduleDirective}
      disabled={disabled}
    />
  );
};

export const IntakeRateSheetDropdown = ({ formikKey, disabled }: IntakeItemFieldInputProps) => {
  const rateSheets = useSelector(getCurrentWorkspaceRateSheetsSortedByName);

  const { getFieldProps } = useFormikContext();
  const { value } = getFieldProps(formikKey);

  const rateSheetOptions = rateSheets
    .filter((rateSheet: RateSheet) => String(rateSheet.id) === value || !rateSheet.archived)
    .reduce<Array<DropdownItemProps>>((acc, rateSheet) => {
      acc.push({
        key: `${rateSheet.id}`,
        search: rateSheet.name.toLowerCase(),
        text: (
          <span>
            {rateSheet.name} {rateSheet.archived ? '(archived)' : ''}
          </span>
        ),
        value: `${rateSheet.id}`,
      });

      return acc;
    }, []);

  return <IntakeFormDropdown formikKey={formikKey} options={rateSheetOptions} disabled={disabled} />;
};

export const IntakeFileInput = ({
  formikKey,
  disabled,
  organizationId,
  workspaceId,
}: IntakeItemFieldInputProps & { organizationId: string; workspaceId: string }) => {
  const { formFieldValue, setFormFieldValue, fieldMeta, setFormFieldError } =
    useIntakeFormField<Array<File>>(formikKey);

  const { removeMultiValue, addOrUpdateMultiValue } = useUpdateAndRemoveMultiFormFieldValues<File>({
    setFormFieldValue,
  });

  const onFileUpload = (data: File | null) => {
    setFormFieldError(undefined);
    data && addOrUpdateMultiValue(formFieldValue.length, data, formFieldValue);
  };

  const handleClear = (index: number) => (_: SyntheticEvent) => removeMultiValue(index, formFieldValue);

  const hasFilesToShow = !!formFieldValue.length;

  const getFileUrl = (s3Id: string | null) =>
    s3Id ? backend.fieldImage.url({ workspaceId, organizationId, s3Id }) : '';

  return (
    <div className="intake_file_section">
      <aside
        className={classNames('intake_file_section__upload', {
          'intake_file_section__upload--error': !!fieldMeta.error,
        })}
      >
        <LpFileInput<'files', File>
          allowMultiple
          server={backend.intakeFiles.url({ workspaceId, organizationId })}
          onFileUploadComplete={onFileUpload}
          disabled={disabled}
        />
      </aside>
      <div
        className={classNames('intake_file_section__files-container', {
          'intake_file_section__files-container--has-files': hasFilesToShow,
        })}
      >
        <LayoutGroup>
          <AnimatePresence mode="popLayout">
            {formFieldValue.map((file, index) => {
              return (
                <LpMotionFadeIn
                  layout
                  transition={{ type: 'spring', damping: 15 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  className="intake_file_section__files-container-file"
                  key={file.id}
                >
                  <div className="intake_file_section__files-container-file-name">
                    <FileIconAndNameRenderer
                      fileUrl={getFileUrl(file.s3Id)}
                      filename={file.filename}
                      contentCategory={file.contentCategory}
                    />
                  </div>
                  <LpIcon
                    className="intake-form-custom-field-multi__icon"
                    icon={circleXmarkLight}
                    onClick={handleClear(index)}
                  />
                </LpMotionFadeIn>
              );
            })}
          </AnimatePresence>
        </LayoutGroup>
      </div>
    </div>
  );
};
