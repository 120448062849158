import { SyntheticEvent } from 'react';
import { Checkbox, CheckboxProps, Form } from 'semantic-ui-react';

import { OrganizationProjectTypeType } from 'daos/enums';
import LpFormLabel, { LpFormLabelStyleModifier } from 'features/common/forms/lp_form_label';

const formikValueToOrganizationProjectTypeDisplay = {
  personalUse: 'Personal Use',
  educationalUse: 'Educational Use',
  professionalServices: 'Professional Services',
  productDevelopment: 'Product Development',
  informationTechnology: 'Information Technology',
  hardwareEngineering: 'Engineering (Hardware)',
  softwareEngineering: 'Engineering (Software)',
  creativeMarketing: 'Marketing & Creative',
  researchAndDevelopment: 'Research & Development',
  manufacturing: 'Manufacturing',
  humanResources: 'Human Resources',
  other: 'Other',
};

const formikValueToOrganizationProjectType: Record<string, OrganizationProjectTypeType> = {
  personalUse: OrganizationProjectTypeType.PersonalUse,
  educationalUse: OrganizationProjectTypeType.EducationalUse,
  professionalServices: OrganizationProjectTypeType.ProfessionalServices,
  productDevelopment: OrganizationProjectTypeType.ProductDevelopment,
  informationTechnology: OrganizationProjectTypeType.InformationTechnology,
  hardwareEngineering: OrganizationProjectTypeType.EngineeringHardware,
  softwareEngineering: OrganizationProjectTypeType.EngineeringSoftware,
  creativeMarketing: OrganizationProjectTypeType.MarketingAndCreative,
  researchAndDevelopment: OrganizationProjectTypeType.ResearchAndDevelopment,
  manufacturing: OrganizationProjectTypeType.Manufacturing,
  humanResources: OrganizationProjectTypeType.HumanResources,
  other: OrganizationProjectTypeType.Other,
};

export const defaultOrganizationProjectTypeCheckboxValues = (): Record<string, boolean> => {
  const organizationProjectTypesCamelCased = Object.keys(formikValueToOrganizationProjectType);
  const organizationProjectTypeCheckboxValues: Record<string, boolean> = {};

  organizationProjectTypesCamelCased.forEach((projectType) => {
    organizationProjectTypeCheckboxValues[projectType] = false;
  });

  return organizationProjectTypeCheckboxValues;
};

export const filteredOrganizationProjectTypes = (organizationProjectTypesCamelCased: Record<string, boolean>) =>
  Object.entries(organizationProjectTypesCamelCased).reduce(
    (acc: Array<OrganizationProjectTypeType>, projectCheckbox) => {
      const projectTypeKey = projectCheckbox[0];
      const projectTypeValue = projectCheckbox[1];
      const projectType = formikValueToOrganizationProjectType[projectTypeKey];

      if (projectTypeValue && projectType) {
        acc.push(projectType);
      }

      return acc;
    },
    [],
  );

export const ProjectTypeCheckboxFields = ({
  checkboxValues,
  className,
  onClick,
}: {
  checkboxValues: Record<string, boolean>;
  className: string;
  onClick: (_: SyntheticEvent, { checked, value }: CheckboxProps) => void;
}) => {
  const organizationProjectTypeCheckboxes = Object.entries(formikValueToOrganizationProjectTypeDisplay).map(
    ([key, value]) => {
      return (
        <Form.Field key={key}>
          <Checkbox
            id={key}
            label={<label htmlFor={key}>{value}</label>}
            checked={checkboxValues[key]}
            name={key}
            onClick={onClick}
          />
        </Form.Field>
      );
    },
  );

  return (
    <>
      <Form.Field>
        <LpFormLabel text={'Help us get to know your projects'} style={LpFormLabelStyleModifier.Bold} />
        <LpFormLabel
          text={'Check anything you hope to include in your workspace'}
          style={LpFormLabelStyleModifier.Italic}
        />
      </Form.Field>
      <div className={`${className}__project-desc-checkboxes`}>{organizationProjectTypeCheckboxes}</div>
    </>
  );
};
