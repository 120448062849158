import { noop } from 'lodash';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { useSetupOutpost } from 'features/academy/outpost/use_setup_outpost';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import { OrgUserGuestPassportsTable } from 'features/guest/profile/org_user_passports_table';
import { getOrgHubUserUpdateError } from 'features/organization_directory/selectors';
import { setOrgHubUserUpdateError } from 'features/organization_directory/slice';
import OrganizationDirectoryUsersProfileInformationAvatar from 'features/organization_directory/users/profile/information/avatar';
import { OrganizationDirectoryUsersProfileInformationTable } from 'features/organization_directory/users/profile/information/table';
import { OrgDirUsersProfileWsMembershipsTable } from 'features/organization_directory/users/profile/workspace_memberships/table';
import { DisconnectedUserWarning } from 'features/shared/user_profile/disconnected_user_warning';
import { getGuestWorkspacePassportsForUserId } from 'state/entities/selectors/dashboard_guest';
import {
  getCurrentOrganizationWorkspaceUsersForUserIdByWorkspaceId,
  getIsOrgUserHasAnyMemberWorkspaces,
  getOrganizationUserForId,
} from 'state/entities/selectors/user';
import { getWorkspacesById } from 'state/entities/selectors/workspace';
import './index.scss';

export const OrganizationDirectoryUsersProfile = () => {
  const { organizationUserId: organizationUserIdString } = useParams<{ organizationUserId: string }>();
  const orgUserId = Number(organizationUserIdString);
  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));

  useSetupOutpost(OutpostLocation.OrganizationUser);

  const guestWorkspacePassports = useSelector((state) =>
    orgUser ? getGuestWorkspacePassportsForUserId(state, orgUser.user.id) : [],
  );

  const isGuestProfile = useSelector((state) => !getIsOrgUserHasAnyMemberWorkspaces(state, orgUser?.user.id ?? 0));

  return (
    <OrganizationDirectoryUsersProfileRenderer
      isGuestProfile={isGuestProfile}
      guestTable={
        !!orgUser &&
        !!guestWorkspacePassports.length && (
          <OrgUserGuestPassportsTable guestOrgUser={orgUser} guestWorkspacePassports={guestWorkspacePassports} />
        )
      }
      orgUserId={orgUserId}
      profileType="organizationUser"
    />
  );
};

export const OrganizationDirectoryUsersProfileRenderer = ({
  fetchWorkspaceUser = noop,
  isGuestProfile,
  guestTable,
  orgUserId,
  profileType,
  workspaceUserDisconnected = false,
}: {
  fetchWorkspaceUser?: (afterOnFinish: () => void) => void;
  isGuestProfile: boolean;
  guestTable?: ReactNode;
  orgUserId: number;
  profileType: 'organizationUser' | 'workspaceUser';
  workspaceUserDisconnected?: boolean;
}) => {
  const dispatch = useDispatch();

  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));

  const isProfileUserDisconnected = workspaceUserDisconnected || !!orgUser?.disconnectedAt;

  const userUpdateError = useSelector(getOrgHubUserUpdateError);
  const handleDismissUserUpdateError = () => dispatch(setOrgHubUserUpdateError(undefined));

  const workspaces = Object.values(useSelector(getWorkspacesById));
  const workspaceUsersByWorkspaceId = useSelector((state) =>
    orgUser ? getCurrentOrganizationWorkspaceUsersForUserIdByWorkspaceId(state, orgUser.user.id) : {},
  );

  const memberWorkspaces = workspaces.filter((workspace) => !workspaceUsersByWorkspaceId[workspace.id]?.guestUser);

  const showWorkspaceMembershipsTable =
    profileType === 'organizationUser' &&
    !!memberWorkspaces.length &&
    memberWorkspaces.some((ws) => !!workspaceUsersByWorkspaceId[ws.id]);

  return (
    <div className="organization-directory__users-profile-content">
      {userUpdateError && (
        <LpErrorMessage
          className="organization-directory__users-profile-error"
          error={userUpdateError}
          onDismiss={handleDismissUserUpdateError}
        />
      )}
      {isProfileUserDisconnected && <DisconnectedUserWarning userType={isGuestProfile ? 'guest' : 'user'} />}
      <OrganizationDirectoryUsersProfileInformationTable
        orgUserId={orgUserId}
        isGuestProfile={isGuestProfile}
        orgUserDisconnected={isProfileUserDisconnected}
        profileType={profileType}
      />
      <OrganizationDirectoryUsersProfileInformationAvatar
        fetchWorkspaceUser={fetchWorkspaceUser}
        orgUserId={orgUserId}
        isGuestProfile={isGuestProfile}
        profileType={profileType}
      />
      {showWorkspaceMembershipsTable && <OrgDirUsersProfileWsMembershipsTable memberWorkspaces={memberWorkspaces} />}
      {guestTable}
    </div>
  );
};
