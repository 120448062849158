import classNames from 'classnames';
import moment from 'moment-timezone';
import { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DropdownProps, Form } from 'semantic-ui-react';

import { DateRangeType, DateRangeFilterSettings } from 'daos/types';
import { LpDatePicker } from 'features/common/inputs/lp_date_picker';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import {
  DATE_RANGE_FILTER_FROM,
  DATE_RANGE_FILTER_TO,
  DATE_RANGE_FILTER_TYPE,
  dateRangeFilterDisplay,
  formatDateRange,
} from 'lib/date_range';
import { getThreeWeekDateRangesForCurrentOrganizationUser } from 'state/entities/selectors/user';

export const DateRangeSetting = ({
  setFieldValue,
  getFieldProps,
  dateRangeError = false,
}: {
  setFieldValue: (key: keyof DateRangeFilterSettings, value: string | null) => void;
  getFieldProps: (key: keyof DateRangeFilterSettings) => { value: string | undefined };
  dateRangeError?: boolean;
}) => {
  const threeWeekWidgetExplicitDates = useSelector(getThreeWeekDateRangesForCurrentOrganizationUser);

  const { formatLocalDate } = useLocalizedFormats();

  const { value: dateRangeFilterType } = getFieldProps(DATE_RANGE_FILTER_TYPE) ?? {
    value: DateRangeType.AllDates,
  };
  const { value: dateRangeFilterFrom } = getFieldProps(DATE_RANGE_FILTER_FROM);
  const { value: dateRangeFilterTo } = getFieldProps(DATE_RANGE_FILTER_TO);

  function resetAllFields() {
    setFieldValue(DATE_RANGE_FILTER_TYPE, null);
    setFieldValue(DATE_RANGE_FILTER_FROM, null);
    setFieldValue(DATE_RANGE_FILTER_TO, null);
  }

  function setDateRangeAndNullRanges(dateRange: string) {
    setFieldValue(DATE_RANGE_FILTER_TYPE, dateRange);
    setFieldValue(DATE_RANGE_FILTER_FROM, null);
    setFieldValue(DATE_RANGE_FILTER_TO, null);
  }

  function updateDateRangeValues(dateRange: string) {
    if (dateRange === DateRangeType.AllDates) {
      resetAllFields();
    } else if (dateRange !== DateRangeType.CalendarDates) {
      setDateRangeAndNullRanges(dateRange);
    } else {
      setFieldValue(DATE_RANGE_FILTER_TYPE, dateRange);
    }
  }

  const handleDateRangeChange = (_e: SyntheticEvent<HTMLElement, Event>, { value: dateRange }: DropdownProps) => {
    updateDateRangeValues(dateRange as string);
  };
  const getHandleDateChange = (formikFieldName: keyof DateRangeFilterSettings) => (value: string) => {
    setFieldValue(formikFieldName, value ?? null);
  };

  const handleDateRangeFilterFromChange = getHandleDateChange(DATE_RANGE_FILTER_FROM);
  const handleDateRangeFilterToChange = getHandleDateChange(DATE_RANGE_FILTER_TO);

  const rangeOptions = useMemo(() => {
    const rangeOptions = Object.keys(DateRangeType).map((range) => {
      const dateRangeEnumStringValue = DateRangeType[range as keyof typeof DateRangeType];
      const dateRangeDisplayText = dateRangeFilterDisplay[dateRangeEnumStringValue];
      return {
        search: dateRangeDisplayText,
        text: dateRangeDisplayText,
        value: dateRangeEnumStringValue,
      };
    });

    const threeWeekWindowDropdownOption = rangeOptions.find((option) => option.value === DateRangeType.ThreeWeekWindow);
    if (
      threeWeekWindowDropdownOption &&
      threeWeekWidgetExplicitDates?.startDate &&
      threeWeekWidgetExplicitDates?.endDate
    ) {
      threeWeekWindowDropdownOption.text = formatDateRange({
        startDate: threeWeekWidgetExplicitDates?.startDate ?? null,
        endDate: threeWeekWidgetExplicitDates?.endDate ?? null,
        formatter: formatLocalDate,
        label: '3 Week Window',
      });
    }
    return rangeOptions;
  }, [threeWeekWidgetExplicitDates, formatLocalDate]);

  return (
    <div className="v2-dashboard-filter__date-range-filter">
      <div
        className={classNames({
          'v2-dashboard-filter__date-range-filter-dropdown': true,
          'v2-dashboard-filter__date-range-filter-dropdown--filtered': !!dateRangeFilterType,
        })}
      >
        <Form.Dropdown
          options={rangeOptions}
          name={DATE_RANGE_FILTER_TYPE}
          onChange={handleDateRangeChange}
          onBlur={handleDateRangeChange}
          selection
          value={dateRangeFilterType ?? DateRangeType.AllDates}
        />
      </div>
      {(dateRangeFilterType as DateRangeType) === DateRangeType.CalendarDates && (
        <>
          {dateRangeError && (
            <div className="v2-dashboard-filter__date-range-filter-error">Please enter a valid date range</div>
          )}
          <div className="v2-dashboard-filter__date-range-filter-dates">
            <LpDatePicker
              placeholder="Not Set"
              disabled={false}
              allowEmpty
              onChange={handleDateRangeFilterFromChange}
              value={dateRangeFilterFrom}
              max={moment(dateRangeFilterTo).isValid() ? dateRangeFilterTo : ''}
            />
            <LpDatePicker
              placeholder="Not Set"
              disabled={false}
              allowEmpty
              onChange={handleDateRangeFilterToChange}
              value={dateRangeFilterTo}
              min={moment(dateRangeFilterFrom).isValid() ? dateRangeFilterFrom : ''}
            />
          </div>
        </>
      )}
    </div>
  );
};
