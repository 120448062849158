import { useSelector } from 'react-redux';

import ItemIcon from 'containers/shared/item_icon';
import ItemLink from 'containers/shared/item_link';
import { getItemForId } from 'state/entities/selectors/item';

const ChangeCardItemProperty = ({ itemId }: { itemId: number }) => {
  const item = useSelector((state) => getItemForId(state, itemId));

  if (!item) {
    return <>Unknown</>;
  }

  return (
    <>
      {<ItemIcon className="lp-change__card-change-property-item-icon" itemId={itemId} hasToolTip={false} />}
      {<ItemLink itemId={itemId} openScheduleTab={false} />}
    </>
  );
};

export default ChangeCardItemProperty;
