import classnames from 'classnames';
import { forwardRef, ReactNode, Ref } from 'react';

import './inline_button.scss';

interface InlineButtonProps {
  children?: ReactNode;
  className?: string;
  onClick: () => void;
  target?: string;
}

const InlineButton = forwardRef(
  ({ children, className, onClick, target }: InlineButtonProps, ref?: Ref<HTMLAnchorElement>) => (
    <a
      ref={ref}
      role="button"
      tabIndex={0}
      className={classnames('inline_button', className)}
      onClick={onClick}
      target={target}
      rel={target == '_blank' ? 'noopener noreferrer nofollow' : undefined}
    >
      {children}
    </a>
  ),
);

export default InlineButton;
