import classNames from 'classnames';
import { Table } from 'semantic-ui-react';

import { LpInlineLoader } from 'features/common/loaders/lp_inline_loader';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { SyncErrorMessage } from 'features/jira_project/modal/types';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';

import 'features/jira_project/modal/jira_project_modal.scss';

export const JiraCredentialsEditTable = () => {
  const { formatLocalDateWithTime } = useLocalizedFormats();
  const { connectionInformation, credentialsError, isLoadingContent } = useJiraProjectModalContext();

  const formattedDateTime = formatLocalDateWithTime(connectionInformation?.timeConnected ?? '');

  const error = credentialsError === SyncErrorMessage.DisconnectedUser ? 'Disconnected from Workspace' : null;

  return (
    <>
      <JiraProjectModalTableRow>
        <JiraProjectModalTableNameCell content="Connected Jira Instance" />
        <Table.Cell className="field-value">
          <span className="custom-field-input">
            {isLoadingContent ? <LpInlineLoader className="jira-project-modal__loader" /> : connectionInformation?.name}
          </span>
        </Table.Cell>
      </JiraProjectModalTableRow>
      <JiraProjectModalTableRow>
        <JiraProjectModalTableNameCell content="Email Provided" />
        <Table.Cell className="field-value">
          <span
            className={classNames('custom-field-input', error && 'jira-project-modal__content-info-credentials-error')}
          >
            {isLoadingContent ? (
              <LpInlineLoader className="jira-project-modal__loader" />
            ) : (
              (error ?? connectionInformation?.email)
            )}
          </span>
        </Table.Cell>
      </JiraProjectModalTableRow>
      <JiraProjectModalTableRow>
        <JiraProjectModalTableNameCell content="Time Connected" />
        <Table.Cell className="field-value">
          <span className="custom-field-input">
            {isLoadingContent ? <LpInlineLoader className="jira-project-modal__loader" /> : formattedDateTime}
          </span>
        </Table.Cell>
      </JiraProjectModalTableRow>
    </>
  );
};
