import { useSelector } from 'react-redux';
import { Breadcrumb } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { usePackageCollectionUrlForPackageStatus } from 'hooks/use_package_collection_url_for_package_status';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { frontend } from 'lib/urls';
import { getItemForId } from 'state/entities/selectors/item';

import './jira_project_modal.scss';

const Header = () => {
  const { packageId, packageStatus } = useJiraProjectModalContext();
  const item = useSelector((state) => getItemForId(state, packageId));
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const packageCollectionUrl = usePackageCollectionUrlForPackageStatus(packageStatus);

  const breadcrumbLinkProps = { as: LpLink };

  return (
    <>
      <LpSvg icon={LpSvgs.JIRA} size={20} />
      <Breadcrumb size="big" className="jira-project-modal__breadcrumb">
        <Breadcrumb.Section {...breadcrumbLinkProps} to={packageCollectionUrl} content={HORIZONTAL_ELLIPSIS} />
        <Breadcrumb.Divider />
        <Breadcrumb.Section
          {...breadcrumbLinkProps}
          to={frontend.singlePackage.url({ workspaceId, organizationId, packageId })}
          content={item?.name}
        />
        <Breadcrumb.Divider />
        <Breadcrumb.Section content="Jira Integration" />
      </Breadcrumb>
    </>
  );
};

export default Header;
