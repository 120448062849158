import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import {
  ItemIntakeRowSetting,
  ItemIntakeSettableField,
} from 'features/dashboards_v2/widget_click_through/intake/enums';
import {
  ItemIntakeFormSection,
  ItemIntakeFormSectionField,
} from 'features/dashboards_v2/widget_click_through/intake/types';
import { itemTypeToSingularDisplay } from 'lib/display_helpers/item_display_name';
import { getCurrentOrganizationUser } from 'state/entities/selectors/user';

const intakeWidgetTitleInfo = ({
  templateName,
  intakeType,
  currentUserName,
}: {
  templateName: string | undefined;
  intakeType: ItemType | null;
  currentUserName: string;
}) => {
  const intakeDisplayName = intakeType ? itemTypeToSingularDisplay[intakeType] : 'Item';
  const placeholderText = `${intakeDisplayName} Name`;
  const automaticTitleText = templateName || `New ${intakeDisplayName}`;
  const editableInputText = templateName || '';
  const automaticIntakeTitle = `${automaticTitleText} - ${new Date().toLocaleDateString()} - ${currentUserName}`;

  return { intakeDisplayName, placeholderText, automaticIntakeTitle, editableInputText };
};

function getAllFieldsFromIntakeSections(sections: ReadonlyArray<ItemIntakeFormSection>) {
  const fields: Array<ItemIntakeFormSectionField> = [];
  sections.forEach((section) => fields.push(...section.fields));
  return fields;
}

function findNameFieldIndex(fields: Array<ItemIntakeFormSectionField>) {
  return fields.findIndex((field) => field.itemField === ItemIntakeSettableField.Name);
}

function getIntakeNameFieldInfo(intakeSections: ReadonlyArray<ItemIntakeFormSection> | null) {
  let templateName: string | undefined = undefined;
  let isNameFieldAutomatic = false;
  let hasNameField = false;

  if (!intakeSections) {
    return { templateName, hasNameField, isNameFieldAutomatic };
  }

  const fields = getAllFieldsFromIntakeSections(intakeSections);
  const nameFieldIndex = findNameFieldIndex(fields);
  const nameField = fields[nameFieldIndex];

  if (nameFieldIndex >= 0 && nameField) {
    hasNameField = true;
    isNameFieldAutomatic = nameField.rowSetting === ItemIntakeRowSetting.Automatic;

    if (typeof nameField.templateValue === 'string') {
      templateName = nameField.templateValue;
    }
  }

  return { templateName, hasNameField, isNameFieldAutomatic };
}

export const useGetIntakeWidgetTitleOptions = () => {
  const currentUserName = useSelector(getCurrentOrganizationUser)?.username ?? '';

  const getIntakeWidgetTitleOptions = useCallback(
    (intakeType: ItemType | null, intakeSections: ReadonlyArray<ItemIntakeFormSection>) => {
      const { templateName, hasNameField, isNameFieldAutomatic } = getIntakeNameFieldInfo(intakeSections);

      const { intakeDisplayName, placeholderText, automaticIntakeTitle, editableInputText } = intakeWidgetTitleInfo({
        intakeType,
        templateName,
        currentUserName,
      });

      const initialIntakeTitle = isNameFieldAutomatic ? automaticIntakeTitle : editableInputText;

      return {
        initialIntakeTitle,
        intakeDisplayName,
        placeholderText,
        isNameFieldAutomatic,
        hasNameField,
      };
    },
    [currentUserName],
  );

  return { getIntakeWidgetTitleOptions };
};
