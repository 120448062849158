import { escapeRegExp, isNumber } from 'lodash';
import { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { CostCode } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { frontend } from 'lib/urls';
import { getActiveCurrentWorkspaceCostCodesSortedByName } from 'state/entities/selectors/cost_code';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

const getDropdownText = ({
  costCode,
  shouldLabelDefaultCostCode,
  defaultCostCode,
}: {
  costCode: CostCode;
  shouldLabelDefaultCostCode: boolean;
  defaultCostCode?: number;
}) => (shouldLabelDefaultCostCode && defaultCostCode === costCode.id ? `${costCode.name} (default)` : costCode.name);

interface CostCodeDropdownProps {
  currentCostCodeId: number | undefined;
  disabled?: DropdownProps['disabled'];
  onChange: (value: number | undefined) => void;
  className?: string;
  useOnGrid?: boolean;
  open?: boolean;
  includeCustomizeLink?: boolean;
  shouldOpenCustomizeLinkInNewTab?: boolean;
  includeIcon?: boolean;
  hasError?: boolean;
  onClose?: () => void;
  shouldLabelDefaultCostCode?: boolean;
}

const CostCodeDropdown = ({
  currentCostCodeId,
  shouldLabelDefaultCostCode = false,
  disabled,
  onChange,
  className,
  useOnGrid = false,
  includeCustomizeLink = true,
  shouldOpenCustomizeLinkInNewTab = false,
  includeIcon = false,
  hasError = false,
  onClose,
  open,
}: CostCodeDropdownProps) => {
  const costCodes = useSelector(getActiveCurrentWorkspaceCostCodesSortedByName);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);
  const defaultCostCode = useSelector(getCurrentWorkspace)?.defaultCostCode?.id;

  const costCodeOptions = useMemo(() => {
    const options: Array<DropdownItemProps> = [
      { text: <span className="cost-code-none">None</span>, value: 0, key: 'none' },
    ];
    costCodes.forEach((costCode) => {
      const dropdownText = getDropdownText({ costCode, shouldLabelDefaultCostCode, defaultCostCode });
      options.push({
        content: dropdownText,
        text: dropdownText,
        value: costCode.id,
        key: costCode.name.toLowerCase(),
      });
    });

    if (includeCustomizeLink) {
      options.push({
        text: (
          <span className="customize-cost-codes">
            <em>Customize Cost Codes</em>
          </span>
        ),
        as: LpLink,
        to: frontend.customizeCostCodes.url({
          organizationId,
          workspaceId,
        }),
        target: shouldOpenCustomizeLinkInNewTab ? '_blank' : undefined,
        rel: shouldOpenCustomizeLinkInNewTab ? 'noopener noreferrer' : undefined,
        key: 'customize cost codes',
      });
    }

    return options;
  }, [
    costCodes,
    includeCustomizeLink,
    organizationId,
    workspaceId,
    defaultCostCode,
    shouldLabelDefaultCostCode,
    shouldOpenCustomizeLinkInNewTab,
  ]);

  const lpDropdownSearch = (options: Array<DropdownItemProps>, query: string) => {
    const re = new RegExp(escapeRegExp(query.toLowerCase()));
    return options.filter((opt) => re.test(opt.key));
  };

  const handleChange = (_: SyntheticEvent, { value }: DropdownProps) => {
    if ((isNumber(value) && value !== 0) || value === undefined) {
      return onChange(value);
    }

    return onChange(undefined);
  };

  const costCodeDropdown = (
    <Dropdown
      className={className}
      closeOnChange
      onClose={onClose}
      selectOnNavigation={false}
      disabled={disabled}
      icon={includeIcon ? undefined : null}
      onChange={handleChange}
      options={costCodeOptions}
      placeholder="Cost Code"
      search={lpDropdownSearch}
      selection
      value={currentCostCodeId}
      error={hasError}
      open={open}
    />
  );

  return useOnGrid ? <PortalDropdown>{costCodeDropdown}</PortalDropdown> : costCodeDropdown;
};

export default CostCodeDropdown;
