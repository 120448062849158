import { useDispatch, useSelector } from 'react-redux';

import { CustomFieldDao } from 'daos/custom_field';
import { CustomFieldValueDao } from 'daos/custom_field_value';
import { ItemEffectiveFieldValuesDao } from 'daos/item_effective_field_values';
import { CustomFieldValue } from 'daos/model_types';
import { filterItemIds } from 'daos/shared';
import { getDataGridItemIds } from 'features/common/data_grid/selectors';
import { getProjectHubItemIds } from 'features/ppp/project/selectors';
import { getPortfolioGridItemIds } from 'features/ppp/selectors';
import { CustomFieldValueApiActions } from 'hooks/use_custom_fields_api_actions/types';
import { awaitRequestFinish } from 'lib/api';
import { ApiRequest } from 'lib/api/types';

export const useItemFieldValueApiActions = (
  organizationId: number,
  workspaceId: number,
): CustomFieldValueApiActions => {
  const dispatch = useDispatch();
  const portfolioGridItemIds = useSelector(getPortfolioGridItemIds);
  const projectHubItemIds = useSelector(getProjectHubItemIds);
  const dataGridItemIds = useSelector(getDataGridItemIds);
  const visibleItemIds = [...new Set([...portfolioGridItemIds, ...projectHubItemIds, ...dataGridItemIds])];
  const itemCustomFieldValueParams = { organizationId, workspaceId };

  const fetchVisibleItemEffectiveFieldValues = () =>
    dispatch(
      ItemEffectiveFieldValuesDao.fetchAll({ organizationId, workspaceId }, { filter: filterItemIds(visibleItemIds) }),
    );

  const fetchVisibleItemEffectiveFieldValuesOnComplete = (apiRequest: ApiRequest) => {
    dispatch(awaitRequestFinish(apiRequest.uuid, { onFinish: fetchVisibleItemEffectiveFieldValues }));
    return apiRequest;
  };

  const updateFieldValue = (itemId: number, fieldValueId: number, payload: Partial<CustomFieldValue>) => {
    const apiRequest = dispatch(
      CustomFieldValueDao.update({ ...itemCustomFieldValueParams, itemId, fieldValueId }, payload),
    );
    fetchVisibleItemEffectiveFieldValuesOnComplete(apiRequest);
    return apiRequest;
  };

  const createFieldValue = (itemId: number, fieldId: number, payload: Partial<CustomFieldValue>) => {
    const apiRequest = dispatch(
      CustomFieldValueDao.create(
        { ...itemCustomFieldValueParams, itemId },
        { field: CustomFieldDao.id(fieldId), ...payload },
      ),
    );
    fetchVisibleItemEffectiveFieldValuesOnComplete(apiRequest);
    return apiRequest;
  };

  const removeFieldValue = (itemId: number, fieldValueId: number) => {
    const apiRequest = dispatch(
      CustomFieldValueDao.destroy({ ...itemCustomFieldValueParams, itemId, fieldValueId }, fieldValueId),
    );
    fetchVisibleItemEffectiveFieldValuesOnComplete(apiRequest);
    return apiRequest;
  };

  return { updateFieldValue, createFieldValue, removeFieldValue };
};
