import { useSelector } from 'react-redux';

import { ItemType, Permission } from 'daos/enums';
import { getItemForId } from 'state/entities/selectors/item';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

import ItemPickerModal from '.';

interface ItemPickerProps {
  onSelect: (itemId: number) => void;
  onCancel: () => void;
  requiredAccess?: Permission;
}

const ItemPickerWorkSpaceRoot = ({ onSelect, onCancel, requiredAccess = Permission.NONE }: ItemPickerProps) => {
  const workspace = useSelector(getCurrentWorkspace);
  const workspaceRoot = useSelector((state) =>
    workspace ? getItemForId(state, workspace.workspaceRoot.id) : undefined,
  );

  const handleSubmit = (itemIds: Array<number>) => {
    if (itemIds[0]) {
      onSelect(itemIds[0]);
    }
  };

  if (!workspaceRoot) {
    return null;
  }

  return (
    <ItemPickerModal
      filterItemTypes={[ItemType.TASKS, ItemType.PROJECTS, ItemType.FOLDERS]}
      initParentItem={workspaceRoot}
      onCancel={onCancel}
      onSelect={handleSubmit}
      requiredAccess={requiredAccess}
    />
  );
};

export default ItemPickerWorkSpaceRoot;
