import { AccountCircle, ExternalLink, Info, Language, Logout, Reviews, School } from '@tempo-io/sui-icons';
import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { Portal } from 'semantic-ui-react';

import { getOutpostLocation } from 'features/academy/outpost/selectors';
import { getOutpostTrackLessonsSortedByPriorityForOutpostLocation } from 'features/academy/selectors/outpost';
import { getCurrentOrganizationId, getCurrentUser, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { useCloseSubnav } from 'features/organization/left_nav/tempo_nav/hooks';
import { frontend } from 'lib/urls';
import {
  getCurrentOrganizationUser,
  getCurrentOrganizationUserId,
  getIsGuestCurrentWorkspaceUser,
} from 'state/entities/selectors/user';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

import { AcademyOutPostLesson } from './academy_lesson_outposts';

const portalNode = document.getElementById('portal');

export const integrationsNavId = 'integrations-nav';
export const helpAndSupportNavId = 'help-and-support-nav';
export const myProfileNavId = 'my-profile-nav';

export const integrationsSubnavId = 'integrations-subnav';
export const helpAndSupportSubnavId = 'help-and-support-subnav';
export const myProfileSubnavId = 'my-profile-subnav';

export const updateSubnavPosition = ({
  navElement,
  subNavElement,
  visible,
}: {
  navElement: HTMLElement | null;
  subNavElement: HTMLElement | null;
  visible: boolean;
}) => {
  if (!subNavElement || !navElement) {
    return;
  }

  const subNavElementHeight = subNavElement.getBoundingClientRect().height;
  const navElementRightEdge = navElement.getBoundingClientRect().right;
  const navElementBottomEdge = navElement.getBoundingClientRect().bottom;

  subNavElement.style.top = `${visible ? navElementBottomEdge - subNavElementHeight : 0}px`;
  subNavElement.style.left = `${visible ? navElementRightEdge : 0}px`;
  subNavElement.style.visibility = visible ? 'visible' : 'hidden';
};

interface SubNavProps {
  handleNavItemClick: (event: SyntheticEvent) => void;
  hideSubnav: () => void;
  navId: string;
}

interface IntegrationsProps extends SubNavProps {
  showJiraIntegration: boolean;
  showSlackIntegration: boolean;
}

export const IntegrationsSubNav = ({
  handleNavItemClick,
  hideSubnav,
  navId,
  showJiraIntegration,
  showSlackIntegration,
}: IntegrationsProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const organizationUserId = useSelector(getCurrentOrganizationUserId);

  useCloseSubnav(navId, integrationsSubnavId, hideSubnav);

  return (
    <Portal mountNode={portalNode} open>
      <div id={integrationsSubnavId} className="tempo-nav-reset tempo-navigation-dropdown-menu">
        <ul>
          {showJiraIntegration && (
            <li className="tempo-nav-item justify-center">
              <a
                className="tempo-nav-item-link"
                href={frontend.organizationHubUsersJiraIntegrations.url({
                  organizationId,
                  organizationUserId,
                })}
                onClick={handleNavItemClick}
              >
                <div className="tempo-nav-item-content tempo-nav-item-link-content tempo-icon-color-nav-item">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2597_255)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.6 4C3.7175 4 3 4.76875 3 5.71429V14.2857C3 15.2313 3.7175 16 4.6 16H11.7379C11.042 15.3763 10.6 14.443 10.6 13.4C10.6 11.5222 12.0327 10 13.8 10C14.5568 10 15.2522 10.2791 15.8 10.7457V5.71429C15.8 4.76875 15.0825 4 14.2 4H4.6ZM4.83431 7.17752C4.68429 7.01677 4.6 6.79876 4.6 6.57143C4.6 6.3441 4.68429 6.12608 4.83431 5.96534C4.98434 5.80459 5.18783 5.71429 5.4 5.71429C5.61217 5.71429 5.81566 5.80459 5.96569 5.96534C6.11571 6.12608 6.2 6.3441 6.2 6.57143C6.2 6.79876 6.11571 7.01677 5.96569 7.17752C5.81566 7.33827 5.61217 7.42857 5.4 7.42857C5.18783 7.42857 4.98434 7.33827 4.83431 7.17752ZM13.6 5.92857C13.9325 5.92857 14.2 6.21518 14.2 6.57143C14.2 6.92768 13.9325 7.21429 13.6 7.21429H7.6C7.2675 7.21429 7 6.92768 7 6.57143C7 6.21518 7.2675 5.92857 7.6 5.92857H13.6Z"
                        fill="black"
                      />
                    </g>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.2 16.4C15.7464 16.4 17 15.1464 17 13.6C17 12.0536 15.7464 10.8 14.2 10.8C12.6536 10.8 11.4 12.0536 11.4 13.6C11.4 15.1464 12.6536 16.4 14.2 16.4Z"
                      fill="#EBECF0"
                    />
                    <g clipPath="url(#clip1_2597_255)">
                      <rect width="4.8" height="4.8" transform="translate(11.8 11.4)" fill="white" fillOpacity="0.01" />
                      <path
                        d="M16.1201 13.5336L14.3692 11.7273L14.1973 11.55L12.8814 12.9075L12.2799 13.5336C12.2155 13.6001 12.2155 13.7054 12.2799 13.7664L13.483 15.0075L14.1973 15.7445L15.5132 14.3869L15.5347 14.3648L16.1201 13.7664C16.1846 13.6999 16.1846 13.5946 16.1201 13.5336ZM14.1973 14.2706L13.5958 13.65L14.1973 13.0294L14.7989 13.65L14.1973 14.2706Z"
                        fill="#7A869A"
                      />
                      <path
                        d="M14.1973 13.0294C13.8052 12.625 13.7998 11.9656 14.1919 11.5556L12.8814 12.9131L13.5957 13.65L14.1973 13.0294Z"
                        fill="url(#paint0_linear_2597_255)"
                      />
                      <path
                        d="M14.8042 13.6445L14.1973 14.2706C14.5948 14.6806 14.5948 15.34 14.1973 15.75L15.5186 14.3869L14.8042 13.6445Z"
                        fill="url(#paint1_linear_2597_255)"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_2597_255"
                        x1="14.092"
                        y1="12.4003"
                        x2="13.2257"
                        y2="13.24"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.176" stopColor="#344563" />
                        <stop offset="1" stopColor="#7A869A" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2597_255"
                        x1="14.3144"
                        y1="14.89"
                        x2="15.179"
                        y2="14.052"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.176" stopColor="#344563" />
                        <stop offset="1" stopColor="#7A869A" />
                      </linearGradient>
                      <clipPath id="clip0_2597_255">
                        <rect width="12.8" height="12.8" fill="white" transform="translate(3 4)" />
                      </clipPath>
                      <clipPath id="clip1_2597_255">
                        <rect width="4.8" height="4.8" fill="white" transform="translate(11.8 11.4)" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span className="tempo-nav-item-link-content tempo-label-text">Jira Integration</span>
                </div>
              </a>
            </li>
          )}

          {showSlackIntegration && (
            <li className="tempo-nav-item">
              <a
                className="tempo-nav-item-link"
                href={frontend.organizationHubUsersSlackIntegrations.url({
                  organizationId,
                  organizationUserId,
                })}
                onClick={handleNavItemClick}
              >
                <div className="tempo-nav-item-content tempo-nav-item-link-content tempo-icon-color-nav-item">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.94137 11.8469C5.94137 12.6564 5.28013 13.3176 4.47068 13.3176C3.66124 13.3176 3 12.6564 3 11.8469C3 11.0375 3.66124 10.3762 4.47068 10.3762H5.94137V11.8469Z"
                      fill="black"
                    />
                    <path
                      d="M6.68237 11.8469C6.68237 11.0375 7.34361 10.3762 8.15306 10.3762C8.9625 10.3762 9.62374 11.0375 9.62374 11.8469V15.5293C9.62374 16.3388 8.9625 17 8.15306 17C7.34361 17 6.68237 16.3388 6.68237 15.5293V11.8469Z"
                      fill="black"
                    />
                    <path
                      d="M8.15306 5.94137C7.34361 5.94137 6.68237 5.28013 6.68237 4.47068C6.68237 3.66124 7.34361 3 8.15306 3C8.9625 3 9.62374 3.66124 9.62374 4.47068V5.94137H8.15306Z"
                      fill="black"
                    />
                    <path
                      d="M8.15309 6.6824C8.96254 6.6824 9.62378 7.34364 9.62378 8.15309C9.62378 8.96253 8.96254 9.62377 8.15309 9.62377H4.47068C3.66124 9.62377 3 8.96253 3 8.15309C3 7.34364 3.66124 6.6824 4.47068 6.6824H8.15309Z"
                      fill="black"
                    />
                    <path
                      d="M14.0587 8.15309C14.0587 7.34364 14.7199 6.6824 15.5293 6.6824C16.3388 6.6824 17 7.34364 17 8.15309C17 8.96253 16.3388 9.62377 15.5293 9.62377H14.0587V8.15309Z"
                      fill="black"
                    />
                    <path
                      d="M13.3176 8.1531C13.3176 8.96254 12.6563 9.62378 11.8469 9.62378C11.0375 9.62378 10.3762 8.96254 10.3762 8.1531V4.47068C10.3762 3.66124 11.0375 3 11.8469 3C12.6563 3 13.3176 3.66124 13.3176 4.47068V8.1531Z"
                      fill="black"
                    />
                    <path
                      d="M11.8469 14.0586C12.6563 14.0586 13.3176 14.7199 13.3176 15.5293C13.3176 16.3388 12.6563 17 11.8469 17C11.0375 17 10.3762 16.3388 10.3762 15.5293V14.0586H11.8469Z"
                      fill="black"
                    />
                    <path
                      d="M11.8469 13.3176C11.0375 13.3176 10.3762 12.6564 10.3762 11.8469C10.3762 11.0375 11.0375 10.3762 11.8469 10.3762H15.5293C16.3388 10.3762 17 11.0375 17 11.8469C17 12.6564 16.3388 13.3176 15.5293 13.3176H11.8469Z"
                      fill="black"
                    />
                  </svg>
                  <span className="tempo-nav-item-link-content tempo-label-text">Slack Integration</span>
                </div>
              </a>
            </li>
          )}
        </ul>
      </div>
    </Portal>
  );
};

export const HelpSupportSubNav = ({ handleNavItemClick, hideSubnav, navId }: SubNavProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const outpostLocation = useSelector(getOutpostLocation);
  const outpostTrackLessons = useSelector((state) =>
    outpostLocation ? getOutpostTrackLessonsSortedByPriorityForOutpostLocation(state, outpostLocation) : [],
  );

  useCloseSubnav(navId, helpAndSupportSubnavId, hideSubnav);

  return (
    <Portal mountNode={portalNode} open>
      <div id={helpAndSupportSubnavId} className="tempo-nav-reset tempo-navigation-dropdown-menu">
        {!!outpostTrackLessons.length && (
          <div className="tempo-navigation-dropdown-menu-section-header">Suggested lessons from Academy</div>
        )}
        <ul>
          {outpostTrackLessons.map((trackLesson) => (
            <AcademyOutPostLesson
              key={trackLesson.academyTrackLessonId}
              trackLessonId={trackLesson.academyTrackLessonId}
              handleNavItemClick={handleNavItemClick}
            />
          ))}
          <li
            className={classNames(
              'tempo-nav-item justify-center tempo-nav-item--academy',
              !!outpostTrackLessons.length && 'tempo-nav-item--academy__has-outposts',
            )}
          >
            <a
              className="tempo-nav-item-link"
              href={frontend.academy.url({ organizationId, workspaceId })}
              onClick={handleNavItemClick}
            >
              <div className="tempo-nav-item-content tempo-nav-item-link-content tempo-icon-color-nav-item">
                <School />
                <span className="tempo-nav-item-link-content tempo-label-text">Academy</span>
              </div>
            </a>
          </li>

          <li className="tempo-nav-item justify-center">
            <a
              className="tempo-nav-item-link"
              href="https://tempo-io.atlassian.net/servicedesk/customer/portal/6/group/1051/create/1410?customfield_12777=7001"
              target="_blank"
              rel="noreferrer"
            >
              <div className="tempo-nav-item-content tempo-nav-item-link-content tempo-icon-color-nav-item">
                <Reviews />
                <span className="tempo-nav-item-link-content tempo-label-text external-link">
                  Support &amp; Feedback
                  <span className="external-link--icon">
                    <ExternalLink />
                  </span>
                </span>
              </div>
            </a>
          </li>
          <li className="tempo-nav-item">
            <a
              className="tempo-nav-item-link"
              href="https://help.tempo.io/portfoliomanager/latest/release-notes"
              target="_blank"
              rel="noreferrer"
            >
              <div className="tempo-nav-item-content tempo-nav-item-link-content tempo-icon-color-nav-item">
                <Info />
                <span className="tempo-nav-item-link-content tempo-label-text external-link">
                  Release Notes
                  <span className="external-link--icon">
                    <ExternalLink />
                  </span>
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </Portal>
  );
};

export const MyProfileSubNav = ({ handleNavItemClick, hideSubnav, navId }: SubNavProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const currentWorkspaceName = useSelector(getCurrentWorkspace)?.name;
  const isCurrentWorkspaceUserGuest = useSelector(getIsGuestCurrentWorkspaceUser);

  const currentOrgUser = useSelector(getCurrentOrganizationUser);
  const currentUser = useSelector(getCurrentUser);

  const myProfileDisplayName =
    currentUser?.firstName && currentUser?.lastName ? `${currentUser.firstName} ${currentUser.lastName}` : '';
  let myProfileDisplayUserName = currentOrgUser?.username ? currentOrgUser.username : '';
  if (myProfileDisplayName && myProfileDisplayUserName) {
    myProfileDisplayUserName = ` (${myProfileDisplayUserName})`;
  }
  useCloseSubnav(navId, myProfileSubnavId, hideSubnav);

  return (
    <Portal mountNode={portalNode} open>
      <div id={myProfileSubnavId} className="tempo-nav-reset tempo-navigation-dropdown-menu">
        <ul>
          <li data-active="false" className="tempo-nav-item">
            <a
              className="tempo-nav-item-link"
              href={
                isCurrentWorkspaceUserGuest
                  ? frontend.workspaceGuestProfile.url({
                      userId: currentOrgUser?.user.id ?? 0,
                      organizationId,
                      workspaceId,
                    })
                  : frontend.member.url({ userId: currentOrgUser?.user.id ?? 0, organizationId, workspaceId })
              }
              onClick={handleNavItemClick}
            >
              <div className="tempo-nav-item-content tempo-nav-item-link-content tempo-icon-color-nav-item">
                <span className="tempo-nav-item-link-content tempo-label-text my-profile">
                  <span className="my-profile--header">
                    <AccountCircle />
                    My Profile
                  </span>
                  <span className="my-profile--name" title={`${myProfileDisplayName} ${myProfileDisplayUserName}`}>
                    {myProfileDisplayName} {myProfileDisplayUserName}
                  </span>
                </span>
              </div>
            </a>
          </li>

          <li data-active="false" className="tempo-nav-item">
            <a
              className="tempo-nav-item-link"
              href={frontend.organizationHub.url({ organizationId })}
              onClick={handleNavItemClick}
            >
              <div className="tempo-nav-item-content tempo-nav-item-link-content tempo-icon-color-nav-item">
                <span className="tempo-nav-item-link-content tempo-label-text switch-workspace">
                  <span className="switch-workspace--header">
                    <Language />
                    Switch Workspace
                  </span>
                  <span className="switch-workspace--name" title={currentWorkspaceName}>
                    {currentWorkspaceName}
                  </span>
                </span>
              </div>
            </a>
          </li>

          <li className="tempo-nav-item">
            <a className="tempo-nav-item-link" href={frontend.logout.url({})} onClick={handleNavItemClick}>
              <div className="tempo-nav-item-content tempo-nav-item-link-content tempo-icon-color-nav-item">
                <Logout />
                <span className="tempo-nav-item-link-content tempo-label-text">Logout</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </Portal>
  );
};
