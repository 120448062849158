import classNames from 'classnames';
import { ReactNode } from 'react';

import { IconDefinition, LpIcon } from 'features/common/lp_icon';

interface InsightProps {
  displayName: string;
  icon: IconDefinition;
  value: ReactNode;
  disabled: boolean;
  hasRisk: boolean;
}

export const Insight = ({ displayName, icon, value, disabled, hasRisk }: InsightProps) => {
  return (
    <div
      className={classNames(
        'insights-widget__insight',
        disabled && 'insights-widget__insight--disabled',
        hasRisk && 'insights-widget__insight--risk',
      )}
    >
      <div className="insights-widget__insight-icon">
        <LpIcon icon={icon} />
      </div>
      <div>
        <div className="insights-widget__insight-value">{value}</div>{' '}
        <div className="insights-widget__insight-name">{displayName}</div>{' '}
      </div>
    </div>
  );
};
