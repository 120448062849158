import { noop } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Mutable } from 'utility-types';

import { ItemType, PackageStatus } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { FolderStatus, RelativePriorityType } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { JobEventData } from 'features/common/events/types';
import { isAddingItemTypeBeyondAccountLimits } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/is_adding_item_type_beyond_account_limits';
import {
  EllipsisActionDropdownProps,
  EllipsisActionHandlers,
  EllipsisActionViewLocation,
} from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { writeUrlToClipboard } from 'features/common/portable_link_handler/copy_rich_text';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { JiraTabKey } from 'features/jira_project/modal/types';
import { useItemListContext } from 'features/ppp/project/task_list/context';
import useUsageLimitsForOrg from 'hooks/use_usage_limit_for_org';
import { awaitRequestFinish } from 'lib/api';
import { ItemPortableLink } from 'lib/clipboard/portable_links';
import { defaultItemName } from 'lib/display_helpers/item_display_name';
import { frontend } from 'lib/urls';
import { getChildItemsForItemId } from 'state/entities/selectors/item';

type UseEllipsisActionHandlersProps = {
  itemIdParam: string;
  showBeyondLimitModal: () => void;
  setJobId: Dispatch<SetStateAction<string | undefined>>;
  setOpenTemplatePickerModal: Dispatch<SetStateAction<boolean>>;
  setOpenPackageToTemplateModal: Dispatch<SetStateAction<'loading' | 'success' | 'error' | undefined>>;
  setOpenProjectToTemplateModal: Dispatch<SetStateAction<boolean>>;
  setOpenTemplateToPackageModal: Dispatch<SetStateAction<boolean>>;
  setOpenTemplateToProjectModal: Dispatch<SetStateAction<boolean>>;
  setShowProjectPowerFeatureModal?: Dispatch<SetStateAction<boolean>>;
  setShowJiraProjectModal?: Dispatch<SetStateAction<boolean>>;
  setPackageId?: Dispatch<SetStateAction<number>>;
  hideDropdown: () => void;
} & Omit<EllipsisActionDropdownProps, 'handleHideContextMenu' | 'isTemplateGrid'>;

interface UseEllipsisActionHandlersReturnProps {
  handlers: EllipsisActionHandlers;
}

export const useEllipsisActionHandlers = ({
  afterDelete,
  fetchItems,
  hideDropdown,
  item,
  itemIdParam,
  setDeletionData,
  setEstimateModalAssignmentId,
  setMoveItemId,
  showBeyondLimitModal,
  viewLocation,
  setJobId,
  setOpenTemplatePickerModal,
  setOpenPackageToTemplateModal,
  setOpenProjectToTemplateModal,
  setOpenTemplateToPackageModal,
  setOpenTemplateToProjectModal,
  setShowProjectPowerFeatureModal,
  setShowJiraProjectModal,
  setPackageId,
}: UseEllipsisActionHandlersProps): UseEllipsisActionHandlersReturnProps => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { canAddTasks, canAddProjects, reFetchOrgLimits } = useUsageLimitsForOrg();

  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const itemChild = useSelector((state) => getChildItemsForItemId(state, item.id));

  const { fetchItems: fetchProjectHubItems } = useItemListContext();

  if (!item) {
    return {
      handlers: {
        handleAddItem: () => noop,
        handleAddJiraProject: () => noop,
        handleCopyLink: noop,
        handleEditItem: noop,
        handleItemDelete: noop,
        handleMemberAccess: noop,
        handleMoveItem: noop,
        handleOpenItem: noop,
        handleOpenTemplatePicker: noop,
        handleOpenPackageToTemplateModal: noop,
        handleOpenProjectToTemplateModal: noop,
        handleOpenTemplateToPackageModal: noop,
        handleOpenTemplateToProjectModal: noop,
      },
    };
  }

  const handleEditItem = () => {
    hideDropdown();

    if (item.itemType === ItemType.ASSIGNMENTS) {
      setEstimateModalAssignmentId?.(item.id);
    } else {
      history.push(`#panelId=${item.id}`);
    }
  };

  const handleCopyLink = () => {
    writeUrlToClipboard(new ItemPortableLink(item.id, organizationId, workspaceId, item?.name));
  };

  const handleOpenItem = () => {
    hideDropdown();

    const urlParams = { organizationId, workspaceId };
    const packageUrlParams = { packageId: item.id, ...urlParams };

    if (item.itemType === ItemType.PACKAGES) {
      history.push(frontend.singlePackage.url(packageUrlParams));
    } else if (item.itemType === ItemType.PROJECTS || item.itemType === ItemType.FOLDERS) {
      history.push(frontend.projectProject.url({ itemId: item.id, ...urlParams }));
    }
  };

  const deleteItemClick = () => {
    const { uuid } = dispatch(ItemDao.destroy({ organizationId, workspaceId, itemId: item.id }, item.id));

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => {
          afterDelete?.({ item });
        },
        onFinish: () => {
          setDeletionData(undefined);
          reFetchOrgLimits();
        },
      }),
    );
  };

  const handleItemDelete = () => {
    hideDropdown();
    setDeletionData({
      id: item.id,
      name: item.name ?? '',
      child: itemChild?.map((item) => item.id) ?? [],
      deleteItemClick,
    });
  };

  const handleMemberAccess = () => {
    hideDropdown();
    history.push(`#panelId=${item.id}&panelSection=${TabNames.MemberAccess}Tab`);
  };

  const isBeyondLimitForItemType = (itemType: ItemType) =>
    isAddingItemTypeBeyondAccountLimits({
      itemTypes: [itemType],
      canAddTasks,
      canAddProjects,
    });

  const handleAddItem = (addItemType: ItemType, expandSubFolders: boolean) => () => {
    if (isBeyondLimitForItemType(addItemType)) {
      return showBeyondLimitModal();
    }

    const itemPayload: Mutable<Partial<Item>> = {
      name: defaultItemName(addItemType),
      parent: item.parent ? ItemDao.id(item.parent.id) : undefined,
      relativePriority: { type: RelativePriorityType.AFTER, id: item.id },
      itemType: addItemType,
    };

    const requiresFolderStatus = addItemType === ItemType.PROJECTS || addItemType === ItemType.FOLDERS;

    const stackedAncestryInProjectView =
      (item.itemType === ItemType.PROJECTS || item.itemType === ItemType.FOLDERS) &&
      viewLocation === EllipsisActionViewLocation.ProjectAncestorRow &&
      Number(itemIdParam) !== item.id;

    const addingFromProjectTableHeader =
      (addItemType === ItemType.FOLDERS || addItemType === ItemType.TASKS) &&
      viewLocation === EllipsisActionViewLocation.ProjectAncestorRow;

    const addingFromCollectionOrPackageTableHeader =
      addItemType === ItemType.PROJECTS && item.itemType === ItemType.PACKAGES;

    const addingFromProjectHubExpandedFolder = item.itemType === ItemType.FOLDERS && expandSubFolders;

    if (requiresFolderStatus) {
      itemPayload.folderStatus = FolderStatus.ACTIVE;
    }

    if (
      (addingFromProjectHubExpandedFolder ||
        addingFromCollectionOrPackageTableHeader ||
        addingFromProjectTableHeader) &&
      !stackedAncestryInProjectView
    ) {
      itemPayload.parent = ItemDao.id(item.id);
      itemPayload.relativePriority = { type: RelativePriorityType.BEFORE };
    }

    if (stackedAncestryInProjectView) {
      itemPayload.parent = ItemDao.id(Number(itemIdParam));
      itemPayload.relativePriority = { type: RelativePriorityType.BEFORE };
    }

    const { uuid } = dispatch(ItemDao.create({ organizationId, workspaceId }, itemPayload));

    dispatch(
      awaitRequestFinish<Item>(uuid, {
        onSuccess: ({ data }) => {
          fetchItems?.();

          fetchProjectHubItems && fetchProjectHubItems();

          history.push(`#panelId=${data.id}`);
        },
        onFinish: () => reFetchOrgLimits(),
      }),
    );

    hideDropdown();
  };

  const handleAddJiraProject = (hasManageAccess: boolean | undefined) => () => {
    if (!hasManageAccess && setShowProjectPowerFeatureModal) {
      return setShowProjectPowerFeatureModal(true);
    }

    setPackageId && setPackageId(item.id);
    setShowJiraProjectModal && setShowJiraProjectModal(true);
    history.push(`#panelSection=${JiraTabKey.Welcome}`);
  };

  const handleMoveItem = () => {
    if (setMoveItemId) {
      setMoveItemId(item.id);
    }

    hideDropdown();
  };

  const handleOpenTemplatePicker = () => {
    if (isBeyondLimitForItemType(ItemType.PROJECTS)) {
      return showBeyondLimitModal();
    }

    setOpenTemplatePickerModal(true);
  };

  const handleOpenPackageToTemplateModal = () => {
    setOpenPackageToTemplateModal('loading');

    const { uuid } = dispatch(
      ItemDao.duplicateBulk(
        {
          organizationId,
          workspaceId,
        },
        [item.id],
        undefined,
        PackageStatus.TEMPLATE,
      ),
    );

    dispatch(
      awaitRequestFinish<JobEventData>(uuid, {
        onSuccess: ({ data }) => setJobId(data.jobId),
      }),
    );
  };

  const handleOpenProjectToTemplateModal = () => setOpenProjectToTemplateModal(true);
  const handleOpenTemplateToProjectModal = () => {
    if (isBeyondLimitForItemType(ItemType.PROJECTS)) {
      return showBeyondLimitModal();
    }

    setOpenTemplateToProjectModal(true);
  };

  const handleOpenTemplateToPackageModal = () => {
    setOpenTemplateToPackageModal(true);
  };

  return {
    handlers: {
      handleAddItem,
      handleAddJiraProject,
      handleCopyLink,
      handleEditItem,
      handleItemDelete,
      handleMemberAccess,
      handleMoveItem,
      handleOpenItem,
      handleOpenTemplatePicker,
      handleOpenPackageToTemplateModal,
      handleOpenProjectToTemplateModal,
      handleOpenTemplateToPackageModal,
      handleOpenTemplateToProjectModal,
    },
  };
};
