import { Dispatch, SetStateAction } from 'react';

import { getCustomFieldFilterKeyForFieldType } from 'daos/custom_field_filter';
import { CustomField } from 'daos/model_types';
import { CustomFieldFilterWithRequiredId } from 'daos/types';
import { CustomPropertiesSelectedValue } from 'features/common/custom_item_filter/types';

const removeAllValuesExceptCurrentSelectedValue = (
  selectedValues: ReadonlyArray<CustomPropertiesSelectedValue>,
  currentSelectedValue: CustomPropertiesSelectedValue | undefined,
) => {
  return selectedValues.filter((value) => value !== currentSelectedValue);
};

export function updateDropdownChoices<T extends { id: number }>(
  dropdownChoices: Array<T>,
  selectedValues: ReadonlyArray<CustomPropertiesSelectedValue> | undefined,
  currentSelectedValue: CustomPropertiesSelectedValue | undefined,
): Array<T> {
  if (!selectedValues || !selectedValues.length) {
    return dropdownChoices;
  }

  return dropdownChoices.filter((option) => {
    return !removeAllValuesExceptCurrentSelectedValue(selectedValues, currentSelectedValue).includes(option.id);
  });
}

/**
 * Custom Field Property State Management Helpers
 */
export const updateCustomFieldPropInState = ({
  fieldId,
  prevState,
  updatedProp,
}: {
  fieldId: number;
  prevState: ReadonlyArray<CustomFieldFilterWithRequiredId>;
  updatedProp: CustomFieldFilterWithRequiredId;
}) =>
  prevState.reduce((acc: Array<CustomFieldFilterWithRequiredId>, curr) => {
    if (curr.customFieldId === fieldId) {
      acc.push(updatedProp);
      return acc;
    }
    acc.push(curr);
    return acc;
  }, []);

export const removeCustomFieldPropFromState = ({
  fieldId,
  prevState,
}: {
  fieldId: number;
  prevState: ReadonlyArray<CustomFieldFilterWithRequiredId>;
}) => prevState.filter((cfProp) => cfProp.customFieldId !== fieldId);

export const addCustomFieldPropToState = ({
  prevState,
  newProp,
}: {
  prevState: ReadonlyArray<CustomFieldFilterWithRequiredId>;
  newProp: CustomFieldFilterWithRequiredId;
}) => [...prevState, newProp];

/**
 * Handles multi custom field type properties
 */
export const updateMultiValueField = ({
  field,
  value,
  index,
  selectedCustomFieldProp,
  setOtherCustomFieldProperties,
  setMultiSelectedValues,
}: {
  field: CustomField;
  value: number | undefined;
  index: number;
  selectedCustomFieldProp: CustomFieldFilterWithRequiredId | undefined;
  setOtherCustomFieldProperties: (
    value: (
      prevState: ReadonlyArray<CustomFieldFilterWithRequiredId>,
    ) => ReadonlyArray<CustomFieldFilterWithRequiredId>,
  ) => void;
  setMultiSelectedValues: Dispatch<
    SetStateAction<ReadonlyArray<string> | ReadonlyArray<number> | ReadonlyArray<boolean>>
  >;
}) => {
  const customFieldFilterKey = getCustomFieldFilterKeyForFieldType(field.fieldType);
  if (!customFieldFilterKey) {
    return;
  }

  if (selectedCustomFieldProp) {
    const selectedValues = (selectedCustomFieldProp[customFieldFilterKey] as ReadonlyArray<number>) ?? [];
    if (!value) {
      const updatedValues = selectedValues.filter((_, idx) => idx !== index);

      if (updatedValues.length === 0) {
        setMultiSelectedValues([]);
        return setOtherCustomFieldProperties((prevState) =>
          removeCustomFieldPropFromState({ fieldId: field.id, prevState }),
        );
      }

      const updatedProp = { ...selectedCustomFieldProp, [customFieldFilterKey]: updatedValues };
      setMultiSelectedValues(updatedValues);
      return setOtherCustomFieldProperties((prevState) =>
        updateCustomFieldPropInState({ fieldId: field.id, prevState, updatedProp }),
      );
    }

    if (value) {
      const updatedValues = [...selectedValues];
      updatedValues.splice(index, 1, value);
      const updatedProp = { ...selectedCustomFieldProp, [customFieldFilterKey]: updatedValues };
      setMultiSelectedValues(updatedValues);
      return setOtherCustomFieldProperties((prevState) =>
        updateCustomFieldPropInState({ fieldId: field.id, prevState, updatedProp }),
      );
    }
  } else if (value) {
    const newProp = { customFieldId: field.id, [customFieldFilterKey]: [value] };
    setMultiSelectedValues([value]);
    return setOtherCustomFieldProperties((prevState) => addCustomFieldPropToState({ prevState, newProp }));
  }
};
