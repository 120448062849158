import { useSelector } from 'react-redux';

import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { favoriteUrl, getFavoriteNameAndColor } from 'features/favorite/helpers';
import { useHasTeamBasedPlanning } from 'hooks/use_has_feature';
import favoriteViewDisplayName from 'lib/display_helpers/favorite_view_name';
import { getFavoriteForId } from 'state/entities/selectors/favorite';
import { getGroupForId } from 'state/entities/selectors/group';
import { getItemForId, getPackageStatusForItemId } from 'state/entities/selectors/item';
import { getLibraryResourcesForId } from 'state/entities/selectors/library_resources';
import { getOrganizationUserInCurrentOrgForUserId, getUserForId } from 'state/entities/selectors/user';

export const useFavoriteData = (favoriteId: number) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const hasTeamBasedPlanning = useHasTeamBasedPlanning();

  const favorite = useSelector((state) => getFavoriteForId(state, favoriteId));
  const {
    view: favoriteViewType,
    group: favoriteGroup,
    item: favoriteItem,
    user: favoriteUser,
    packageStatus: favoritePackageStatus,
    libraryResource: favoriteLibraryResource,
  } = favorite || {};

  const { item, group, user, orgUser, libraryResource, packageStatus } = useSelector((state) => {
    const item = favoriteItem ? getItemForId(state, favoriteItem.id) : undefined;
    const packageStatus = favoriteItem ? getPackageStatusForItemId(state, favoriteItem.id) : undefined;
    const libraryResource = favoriteLibraryResource
      ? getLibraryResourcesForId(state, favoriteLibraryResource.id)
      : undefined;
    const group = favoriteGroup ? getGroupForId(state, favoriteGroup.id) : undefined;
    const user = favoriteUser ? getUserForId(state, favoriteUser.id) : undefined;
    const orgUser = user ? getOrganizationUserInCurrentOrgForUserId(state, user.id) : undefined;

    return { item, group, user, orgUser, libraryResource, packageStatus };
  });

  const { favoriteColor, favoriteName } = getFavoriteNameAndColor({
    item,
    group,
    user,
    orgUser,
    packageStatus: favoritePackageStatus || packageStatus,
    libraryResource,
    favoriteViewType,
    hasTeamBasedPlanning,
  });

  const favoriteSubSection = favoriteViewDisplayName({
    favorite,
    itemType: item?.itemType,
    userType: user?.userType,
    libraryResource,
  });

  const favoriteItemId = item?.id;

  const favoriteUrlTo = favorite
    ? favoriteUrl({
        organizationId,
        workspaceId,
        favorite,
        itemId: favoriteItemId,
        packageStatus: favoritePackageStatus || packageStatus,
        favoriteUserUserType: user?.userType,
        libraryResource,
      })
    : '';

  return { favoriteName, favoriteSubSection, favoriteUrlTo, favoriteColor };
};
