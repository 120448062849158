import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ItemType } from 'daos/enums';
import { isAncestorItemScheduleBar as isAncestorItem } from 'features/common/schedule_bar/helpers';
import { ScheduleBarRendererProps } from 'features/common/schedule_bar/types';
import {
  calcItemDataForScheduleBar,
  useItemDataForScheduleBar,
} from 'features/common/schedule_bar/use_item_data_for_schedule_bar';
import { useItemDataForScheduleTooltip, ScheduleHoverStatus } from 'hooks/use_item_data_for_schedule_hover';
import { useItemBelongsToDoneIteration, useItemDataForStatus } from 'hooks/use_item_data_for_status';
import { ScheduleViewType } from 'hooks/use_items_date_range';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getHasPredecessors, getHasSuccessors } from 'state/entities/selectors/dependency';
import { DateRange, getItemForId } from 'state/entities/selectors/item';
import { getItemMetricsForId } from 'state/entities/selectors/item_metric';
import { getIterationForId, getIterationMetricsForId } from 'state/entities/selectors/iterations';

export const useGetScheduleBarRendererProps = ({
  dateRange,
  hasAssignmentPredecessor,
  hasAssignmentSuccessor,
  isAssignmentPlaceholder = false,
  isAssignmentTrackingOnly = false,
  isDateTimeMode = false,
  isDebugMode = false,
  itemId,
  showDateLabels,
  viewType,
}: {
  dateRange: DateRange;
  hasAssignmentPredecessor?: boolean;
  hasAssignmentSuccessor?: boolean;
  isAssignmentPlaceholder?: boolean;
  isAssignmentTrackingOnly?: boolean;
  isDateTimeMode?: boolean;
  isDebugMode?: boolean;
  itemId: number;
  showDateLabels: boolean;
  viewType: ScheduleViewType;
}) => {
  const { pathname } = useLocation();
  const { formatLocalDate, formatLocalDateWithTime } = useLocalizedFormats();
  const formatDateForDisplay = (day: string) => (isDateTimeMode ? formatLocalDateWithTime(day) : formatLocalDate(day));

  const item = useSelector((state) => getItemForId(state, itemId));
  const itemMetrics = useSelector((state) => getItemMetricsForId(state, itemId));
  const hasPredecessors = useSelector((state) => getHasPredecessors(state, itemId));
  const hasSuccessors = useSelector((state) => getHasSuccessors(state, itemId));

  const statusBooleans = useItemDataForStatus(itemId);
  const isIncomplete = useItemDataForScheduleTooltip(itemId) === ScheduleHoverStatus.NotComplete;

  const { isOnHold: isParentOnHold, isEffectivelyOnHold: isParentEffectivelyOnHold } = useItemDataForStatus(
    item?.parent?.id,
  );
  const assignedToDoneIteration = useItemBelongsToDoneIteration(item);

  const offsetPercentsStylesAndShowRollUps = useItemDataForScheduleBar({ itemId, dateRange, viewType });

  if (!item) {
    return null;
  }

  const showPredecessor =
    (hasAssignmentPredecessor || hasPredecessors) &&
    !statusBooleans.isDone &&
    !statusBooleans.isOnHold &&
    !statusBooleans.isEffectivelyOnHold;
  const showSuccessor =
    (hasAssignmentSuccessor &&
      !statusBooleans.isDone &&
      !statusBooleans.isOnHold &&
      !statusBooleans.isEffectivelyOnHold) ||
    hasSuccessors;
  const showRollupDates = viewType === ScheduleViewType.ItemPanel;

  const { itemType, organizationUser } = item;

  const isAncestorItemScheduleBar = isAncestorItem({ itemType, pathname });
  const isAssignment = itemType === ItemType.ASSIGNMENTS;
  const isTask = itemType === ItemType.TASKS;
  const isAssignmentNeedsAttention = !organizationUser;

  const scheduleBarRendererProps: ScheduleBarRendererProps = {
    ...statusBooleans,
    ...offsetPercentsStylesAndShowRollUps,
    ...item,
    effectiveTargetFinish: itemMetrics?.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics?.effectiveTargetStart,
    formatDateForDisplay,
    highEffort: item.highEffort ?? undefined,
    isAncestorItemScheduleBar,
    isAssignment,
    isAssignmentNeedsAttention,
    isAssignmentPlaceholder,
    isAssignmentTrackingOnly,
    isDebugMode,
    isIncomplete,
    isParentEffectivelyOnHold,
    isParentOnHold,
    isTask,
    itemId,
    rollupEarliestActiveTargetFinish: itemMetrics?.rollupEarliestActiveTargetFinish,
    rollupLatestTargetFinish: itemMetrics?.rollupLatestTargetFinish,
    showDateLabels,
    showPredecessor,
    showRollupDates,
    showSuccessor,
    viewType,
    assignedToDoneIteration,
  };

  return scheduleBarRendererProps;
};

export const useIterationScheduleBarProps = ({
  dateRange,
  iterationId,
}: {
  dateRange: DateRange;
  iterationId: number;
}) => {
  const iteration = useSelector((state) => getIterationForId(state, iterationId));
  const iterationMetric = useSelector((state) => getIterationMetricsForId(state, iterationId));

  const { formatLocalDate } = useLocalizedFormats();

  if (!iteration || !iterationMetric) {
    return null;
  }

  const formatDateForDisplay = (day: string) => formatLocalDate(day);

  const effectiveTargetFinish = iterationMetric.effectiveTargetFinish;
  const effectiveTargetStart = iterationMetric.effectiveTargetStart;
  const expectedFinish = iterationMetric.expectedFinish;
  const expectedStart = iterationMetric.expectedStart;
  const targetFinish = iteration.targetFinish;
  const targetStart = iteration.targetStart;
  const doneDate = iteration.doneDate;
  const rollupEarliestActiveTargetFinish = iterationMetric.rangeFinish?.startDate;
  const rollupLatestTargetFinish = iterationMetric.rangeFinish?.endDate;
  const latestFinish = iterationMetric.latestFinish;

  const isDone = !!iteration.done;
  const isClipped = !!iterationMetric.totalClippedEffort;
  const isLate = !!iterationMetric.isLate;
  const isLateRisk = !!iterationMetric.isLateRisk;
  const isScheduled = !iteration.done;

  const isDoneAfterEffectiveTargetFinish = (doneDate ?? '').localeCompare(effectiveTargetFinish ?? '') > 0;
  const isDoneAfterTargetFinish = (doneDate ?? '').localeCompare(targetFinish ?? '') > 0;
  const isExpectedStartAfterEffectiveTargetFinish =
    (expectedStart ?? '').localeCompare(effectiveTargetFinish ?? '') > 0;
  const isExpectedStartBeforeEffectiveTargetStart = (expectedStart ?? '') < (effectiveTargetStart ?? '');
  const isExpectedStartEqualExpectedFinish = (expectedStart ?? '') === (expectedFinish ?? '');

  const offsetsAndStyles = calcItemDataForScheduleBar({
    assignmentEffectiveTargetFinish: undefined,
    dateRange,
    doneDate,
    effectiveTargetFinish,
    effectiveTargetStart,
    expectedFinish,
    expectedStart,
    isAssignmentItem: false,
    isClipped,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isEffectivelyOnHold: false,
    isLate,
    isLateRisk,
    isOnHold: false,
    isScheduled: true,
    isTaskItem: false,
    itemDateRangeFinish: dateRange.finish,
    itemDateRangeStart: dateRange.start,
    latestFinish,
    rollupEarliestActiveTargetFinish,
    rollupLatestTargetFinish,
    targetFinish,
    targetStart,
  });

  const scheduleBarRendererProps: ScheduleBarRendererProps = {
    ...offsetsAndStyles,
    doneDate: iteration.doneDate,
    effectiveTargetFinish,
    effectiveTargetStart,
    expectedFinish: expectedFinish ?? null,
    expectedStart: expectedStart ?? null,
    isClipped,
    isLate,
    latestFinish: latestFinish ?? null,
    targetFinish,
    isAncestorItemScheduleBar: false,
    isAssignmentNeedsAttention: false,
    isAssignment: false,
    isAssignmentPlaceholder: false,
    isAssignmentTrackingOnly: false,
    isDebugMode: false,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isDoneLate: isDoneAfterEffectiveTargetFinish || isDoneAfterTargetFinish,
    isEffectivelyOnHold: false,
    isExpectedStartAfterEffectiveTargetFinish,
    isExpectedStartBeforeEffectiveTargetStart,
    isExpectedStartEqualExpectedFinish,
    isIncomplete: !!iteration.done,
    isLateRisk,
    isOnHold: false,
    isParentEffectivelyOnHold: false,
    isParentOnHold: false,
    isScheduled,
    isTask: false,
    itemId: undefined,
    highEffort: iterationMetric.highRemainingWork,
    rollupEarliestActiveTargetFinish,
    rollupLatestTargetFinish,
    formatDateForDisplay,
    showDateLabels: false,
    showPredecessor: false,
    showRollupDates: false,
    showRollupEarliestActiveTargetFinish: false,
    showRollupLatestTargetFinish: false,
    showSuccessor: false,
    targetStart,
    viewType: ScheduleViewType.Column,
    assignedToDoneIteration: isDone,
  };

  return scheduleBarRendererProps;
};
