import { ListWidgetType } from 'daos/enums';
import { SelectionList } from 'features/common/selection_list/types';
import { WIDGET_ORDER_DEFAULT } from 'lib/constants';

export const sortableColumnOptions = (
  columnsSelected: ReadonlyArray<SelectionList>,
  listType: ListWidgetType | null,
) => {
  const isPeopleReport = listType === ListWidgetType.Users;

  const sortableColumns = columnsSelected
    .filter((column) => column.sortable && !(isPeopleReport && column.id === 'username'))
    .map((column) => ({
      key: column.id,
      search: column.name,
      text: column.name,
      value: column.id,
    }));

  const defaultSearchText = isPeopleReport ? 'Username' : 'Priority';
  const defaultSortString = `Default (by ${defaultSearchText})`;

  sortableColumns.unshift({
    key: WIDGET_ORDER_DEFAULT,
    search: defaultSortString,
    text: defaultSortString,
    value: WIDGET_ORDER_DEFAULT,
  });

  return sortableColumns;
};
