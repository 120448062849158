import { useSelector } from 'react-redux';

import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { JiraConnectedProjects } from 'features/integrations/jira/jira_integration_connections/jira_connected_projects';
import JiraIntegrationTopNavHeader from 'features/integrations/jira/jira_integration_top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { getCurrentOrganizationUserId } from 'state/entities/selectors/user';

const JiraIntegrationConfiguration = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const organizationUserId = useSelector(getCurrentOrganizationUserId);

  if (!organizationId || !organizationUserId) {
    return null;
  }

  return (
    <>
      <JiraIntegrationTopNavHeader navMenuDisplay={NavigationMenuDisplay.JiraIntegration} />
      <JiraConnectedProjects />
    </>
  );
};

export default JiraIntegrationConfiguration;
