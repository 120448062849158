import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { ChangeEntity, PropertyChange } from 'daos/types';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { getItemForId } from 'state/entities/selectors/item';
import {
  getOrganizationUserForId,
  getOrganizationUserIdForWorkspaceUserId,
  getOrganizationUserInCurrentOrgForUserId,
} from 'state/entities/selectors/user';

interface ChangeCardUserPropertyProps {
  propertyChangeData: PropertyChange;
  resource: ChangeEntity;
}

const ChangeCardUserProperty = ({ propertyChangeData, resource }: ChangeCardUserPropertyProps) => {
  const item = useSelector((state) => getItemForId(state, resource.id));
  const assignmentOrgUserId: number = propertyChangeData.value;
  const orgUserIdDerivedFromUserId = useSelector((state) =>
    propertyChangeData.value ? getOrganizationUserInCurrentOrgForUserId(state, propertyChangeData.value) : undefined,
  );
  const orgUserIdDerivedFromWsUserId = useSelector((state) =>
    propertyChangeData.value?.id
      ? getOrganizationUserIdForWorkspaceUserId(state, propertyChangeData.value?.id)
      : undefined,
  );

  const avatarOrgUserId =
    item?.itemType === ItemType.ASSIGNMENTS
      ? assignmentOrgUserId
      : (orgUserIdDerivedFromWsUserId ?? orgUserIdDerivedFromUserId?.id ?? 0);

  const orgUser = useSelector((state) => getOrganizationUserForId(state, avatarOrgUserId));

  if (!orgUser) {
    return <>None</>;
  }

  return (
    <>
      <UserAvatar
        className="lp-change__card-change-property-user"
        orgUserId={avatarOrgUserId}
        size={AvatarSize.ExtraSmall_1_2}
      />
      {orgUser?.username ?? ''}
    </>
  );
};

export default ChangeCardUserProperty;
