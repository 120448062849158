import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { GroupType } from 'daos/enums';
import { Group } from 'daos/model_types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { groupRecordBy, readonlyArray, ReadonlyRecord } from 'lib/readonly_record';
import { RootState } from 'state/root_reducer';

export const getGroupsById = ({ entities }: RootState): ReadonlyRecord<string, Group> => entities.groups;

export const getGroupForId = (state: RootState, id: number | string) => getGroupsById(state)[id];

export const getGroupsForIds = (state: RootState, ids: ReadonlyArray<number | string>) =>
  ids.map((id) => getGroupForId(state, id));

const getGroupsByWorkspaceId = createSelector(getGroupsById, (groups) =>
  groupRecordBy(groups, (group) => group.workspace.id),
);

export const getCurrentWorkspaceGroups = createSelector(
  getGroupsByWorkspaceId,
  getCurrentWorkspaceId,
  (groupsByWsId, wsId) => groupsByWsId[wsId],
);

const getCurrentWorkspaceAdminGroups = createSelector(getCurrentWorkspaceGroups, (allWorkspaceGroups) => {
  return allWorkspaceGroups?.filter((wsGroup) => wsGroup.groupType === GroupType.Administrative) ?? [];
});

export const getAllCurrentWorkspaceWorkTeams = createSelector(getCurrentWorkspaceGroups, (allWorkspaceGroups) => {
  return allWorkspaceGroups?.filter((wsGroup) => wsGroup.groupType === GroupType.Work) ?? [];
});

const getActiveCurrentWorkspaceWorkTeams = createSelector(getAllCurrentWorkspaceWorkTeams, (allWorkspaceGroups) => {
  return allWorkspaceGroups?.filter((wsGroup) => wsGroup.archivedAt === null) ?? [];
});

export const getCurrentWorkspaceGroupsSortedByName = createSelector(getCurrentWorkspaceAdminGroups, (groups) =>
  readonlyArray(sortBy(groups, (group) => (group.name ?? '').toLowerCase())),
);

export const getCurrentWorkspaceWorkTeamsSortedByName = createSelector(getActiveCurrentWorkspaceWorkTeams, (groups) =>
  readonlyArray(sortBy(groups, (group) => (group.name ?? '').toLowerCase())),
);

export const getGroupTypeForGroupId = createSelector(getGroupForId, (group) => group?.groupType);
