import { RefObject, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { DependencyDao } from 'daos/dependency';
import { Permission } from 'daos/enums';
import { Item } from 'daos/model_types';
import {
  getBulkSelectedItemResources,
  getBulkSelectedItems,
  getBulkSelectedItemsContextMenuType,
  getParentResourcesForSelectedItems,
} from 'features/common/bulk_selection/selectors';
import { BulkSelectionOptionsItemType } from 'features/common/bulk_selection/types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  BulkCopyLinksMenuItem,
  BulkCreateDependenciesMenuItem,
  BulkDeleteMenuItem,
  BulkDuplicateMenuItem,
  BulkEditMenuItem,
  BulkMoveMenuItem,
  BulkRemoveDependenciesMenuItem,
} from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/bulk_menu_items';
import PortalActionDropdown from 'features/common/inputs/dropdowns/portal_action_dropdown';
import { useHasAccessToEditForProjectBulkActions } from 'features/ppp/project/task_list/use_has_edit_access_for_project_bulk_actions';
import { ClickLocation } from 'hooks/use_click_location_for_context_menu';
import { useHasAccessToMultipleResources } from 'hooks/use_has_access';
import { awaitRequestFinish } from 'lib/api';
import { SuccessPayload } from 'lib/api/types';
import { getAncestorProjectIdForItemId, getItemsForIdsSortedByGlobalPriority } from 'state/entities/selectors/item';
import { removeEntities } from 'state/entities/slice';
import { RootState } from 'state/root_reducer';

const useHasAccessForPortfolioOrPackageViewBulkActions = () => {
  const selectedBulkItemResources = useSelector(getBulkSelectedItemResources);
  const portfolioGridPackageResources = useSelector(getParentResourcesForSelectedItems);

  return {
    hasAccessToEditProjects: useHasAccessToMultipleResources(Permission.MANAGE, selectedBulkItemResources, 'items'),
    hasAccessToDeleteAndMoveProjects: useHasAccessToMultipleResources(
      Permission.MANAGE,
      portfolioGridPackageResources,
      'items',
    ),
  };
};

interface Props {
  clickLocation?: ClickLocation;
  handleHideContextMenu?: () => void;
  handleDuplicateItems: (items: ReadonlyArray<Item>) => void;
  showBulkEdit: () => void;
  showActionTriggerIcon?: boolean;
  showConfirmDelete: () => void;
  showMoveModal: () => void;
  triggerRef?: RefObject<HTMLSpanElement>;
}

export const BulkItemEllipsisActionDropdown = ({
  clickLocation,
  handleHideContextMenu,
  handleDuplicateItems,
  showBulkEdit,
  showActionTriggerIcon,
  showConfirmDelete,
  showMoveModal,
  triggerRef,
}: Props) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false);
    handleHideContextMenu?.();
  }, [handleHideContextMenu]);

  const [confirmDeleteDependencies, setConfirmDeleteDependencies] = useState(false);

  const selectedItems = useSelector(getBulkSelectedItems);
  const selectedItemIds = selectedItems.map((item) => item.id);
  const selectedItemsSortedByPriority = useSelector((state: RootState) =>
    getItemsForIdsSortedByGlobalPriority(state, selectedItemIds),
  );

  const menuType = useSelector(getBulkSelectedItemsContextMenuType);
  const isPortfolioGridBulkMenu = menuType === BulkSelectionOptionsItemType.Projects;

  const { hasAccessToEditProjects, hasAccessToDeleteAndMoveProjects } =
    useHasAccessForPortfolioOrPackageViewBulkActions();

  const firstSelectedItemId = selectedItemIds[0];
  const projectIdForSelectedTasksAndFolders = useSelector((state: RootState) =>
    firstSelectedItemId ? getAncestorProjectIdForItemId(state, firstSelectedItemId) : undefined,
  );
  const hasAccessToEditForTasksAndFolders = useHasAccessToEditForProjectBulkActions(
    projectIdForSelectedTasksAndFolders ?? 0,
  );

  const hasAccessToDuplicateDeleteAndMove = isPortfolioGridBulkMenu
    ? hasAccessToDeleteAndMoveProjects
    : hasAccessToEditForTasksAndFolders;
  const hasAccessToEdit = isPortfolioGridBulkMenu ? hasAccessToEditProjects : hasAccessToEditForTasksAndFolders;

  const handleItemsDelete = useCallback(() => {
    showConfirmDelete();
    closeDropdown();
  }, [closeDropdown, showConfirmDelete]);
  const handleItemsMove = useCallback(() => {
    showMoveModal();
    closeDropdown();
  }, [closeDropdown, showMoveModal]);
  const handleItemsEdit = useCallback(() => {
    showBulkEdit();
    closeDropdown();
  }, [closeDropdown, showBulkEdit]);

  const onDuplicateItemsClick = useCallback(() => {
    handleDuplicateItems(selectedItems);
    closeDropdown();
  }, [closeDropdown, handleDuplicateItems, selectedItems]);

  const handleDependencyCreate = useCallback(() => {
    const selectedItemIdsInOrder = selectedItemsSortedByPriority.map((item) => item.id);

    dispatch(
      DependencyDao.createForItemChain(
        {
          organizationId,
          workspaceId,
        },
        selectedItemIdsInOrder,
      ),
    );
  }, [dispatch, organizationId, workspaceId, selectedItemsSortedByPriority]);

  const handleDependencyRemove = useCallback(() => {
    const selectedItemIdsInOrder = selectedItemsSortedByPriority.map((item) => item.id);
    const { uuid } = dispatch(
      DependencyDao.destroyBulk(
        {
          organizationId,
          workspaceId,
        },
        selectedItemIdsInOrder,
      ),
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: (response: SuccessPayload<Array<number>>) => {
          const deletedIds = response.data as Array<number>;
          dispatch(
            removeEntities({
              ids: deletedIds,
              entityType: 'dependencies',
            }),
          );
        },
      }),
    );

    setConfirmDeleteDependencies(false);
  }, [dispatch, organizationId, workspaceId, selectedItemsSortedByPriority]);

  const dropdownMenuItems = useMemo(() => {
    if (!menuType) {
      return [];
    }

    switch (menuType) {
      case BulkSelectionOptionsItemType.Projects:
      case BulkSelectionOptionsItemType.Folders:
      case BulkSelectionOptionsItemType.Tasks:
        return [
          <BulkEditMenuItem key="edit" disabled={!hasAccessToEdit} label={menuType} onClick={handleItemsEdit} />,
          <BulkMoveMenuItem
            key="move"
            disabled={!hasAccessToDuplicateDeleteAndMove}
            label={menuType}
            onClick={handleItemsMove}
          />,
          <BulkCopyLinksMenuItem
            key="copy-links"
            selectedItemIds={selectedItemIds}
            onEllipsisMenuCopySuccess={closeDropdown}
          />,
          <Dropdown.Divider key="divider-1" />,
          <BulkDuplicateMenuItem
            key="duplicate"
            disabled={!hasAccessToDuplicateDeleteAndMove}
            label={menuType}
            onClick={onDuplicateItemsClick}
          />,
          <BulkCreateDependenciesMenuItem key={'bulkCreateDependencies'} onClick={handleDependencyCreate} />,
          <BulkRemoveDependenciesMenuItem
            key={'bulkRemoveDependencies'}
            onClick={openConfirmDeleteDependenciesModal}
          />,
          <Dropdown.Divider key="divider-2" />,
          <BulkDeleteMenuItem
            key="delete"
            disabled={!hasAccessToDuplicateDeleteAndMove}
            label={menuType}
            onClick={handleItemsDelete}
          />,
        ];
      case BulkSelectionOptionsItemType.TasksAndFolders:
        return [
          <BulkMoveMenuItem
            key="move"
            disabled={!hasAccessToDuplicateDeleteAndMove}
            label={menuType}
            onClick={handleItemsMove}
          />,
          <BulkCopyLinksMenuItem
            key="copy-links"
            selectedItemIds={selectedItemIds}
            onEllipsisMenuCopySuccess={closeDropdown}
          />,
          <Dropdown.Divider key="divider-1" />,
          <BulkCreateDependenciesMenuItem key={'bulkCreateDependencies'} onClick={handleDependencyCreate} />,
          <BulkRemoveDependenciesMenuItem
            key={'bulkRemoveDependencies'}
            onClick={openConfirmDeleteDependenciesModal}
          />,
          <Dropdown.Divider key="divider-2" />,
          <BulkDeleteMenuItem
            key="delete"
            disabled={!hasAccessToDuplicateDeleteAndMove}
            label={menuType}
            onClick={handleItemsDelete}
          />,
        ];
    }
  }, [
    closeDropdown,
    handleDependencyCreate,
    handleItemsDelete,
    handleItemsEdit,
    handleItemsMove,
    hasAccessToDuplicateDeleteAndMove,
    hasAccessToEdit,
    menuType,
    onDuplicateItemsClick,
    selectedItemIds,
  ]);

  return (
    <>
      <PortalActionDropdown
        className="context-menu"
        clickLocation={clickLocation}
        triggerRef={triggerRef}
        onOpen={openDropdown}
        onClose={closeDropdown}
        showTrigger={showActionTriggerIcon}
        open={isDropdownOpen}
      >
        {dropdownMenuItems}
      </PortalActionDropdown>
      {confirmDeleteDependencies && (
        <BaseConfirmModal
          confirmButtonText={'Delete'}
          content={<>You are about to REMOVE all predecessor and successor dependencies on these items.</>}
          onClose={closeConfirmDeleteDependenciesModal}
          onConfirm={handleDependencyRemove}
        />
      )}
    </>
  );

  function openConfirmDeleteDependenciesModal() {
    setConfirmDeleteDependencies(true);
  }

  function closeConfirmDeleteDependenciesModal() {
    setConfirmDeleteDependencies(false);
  }
};
