import { Dispatch, RefObject, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Item } from 'daos/model_types';
import { getIsAnyItemDuplicationInProgress } from 'features/common/duplicate/selectors';
import { ellipsisActionDropdownMethods } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/ellipsis_action_dropdown_methods';
import { useEllipsisActionHandlers } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/use_ellipsis_action_handlers';
import { useEllipsisActionMenuItems } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/use_ellipsis_action_menu_items';
import {
  EllipsisActionViewLocation,
  SetItemDeletionParams,
} from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import PortalActionDropdown from 'features/common/inputs/dropdowns/portal_action_dropdown';
import { ClickLocation } from 'hooks/use_click_location_for_context_menu';

interface SingleItemEllipsisActionDropdownProps {
  afterDelete?: ({ item }: { item: Item }) => void;
  expandSubFolders?: boolean;
  fetchItems?: () => void;
  clickLocation?: ClickLocation;
  handleHideContextMenu?: () => void;
  item: Item;
  handleDuplicateItem: (item: Item) => void;
  setDeletionData: Dispatch<SetStateAction<SetItemDeletionParams | undefined>>;
  setEstimateModalAssignmentId?: Dispatch<SetStateAction<number | undefined>>;
  setMoveItemId?: Dispatch<SetStateAction<number | undefined>>;
  showActionTriggerIcon?: boolean;
  triggerRef?: RefObject<HTMLSpanElement>;
  viewLocation?: EllipsisActionViewLocation;
  showBeyondLimitModal: () => void;
  setJobId: Dispatch<SetStateAction<string | undefined>>;
  setOpenTemplatePickerModal: Dispatch<SetStateAction<boolean>>;
  setOpenPackageToTemplateModal: Dispatch<SetStateAction<'loading' | 'success' | 'error' | undefined>>;
  setOpenProjectToTemplateModal: Dispatch<SetStateAction<boolean>>;
  setOpenTemplateToPackageModal: Dispatch<SetStateAction<boolean>>;
  setOpenTemplateToProjectModal: Dispatch<SetStateAction<boolean>>;
  setShowProjectPowerFeatureModal?: Dispatch<SetStateAction<boolean>>;
  setShowJiraProjectModal?: Dispatch<SetStateAction<boolean>>;
  setPackageId?: Dispatch<SetStateAction<number>>;
}

export const SingleItemEllipsisActionDropdown = ({
  afterDelete,
  expandSubFolders = false,
  fetchItems,
  clickLocation,
  handleHideContextMenu,
  item,
  handleDuplicateItem,
  setDeletionData,
  setEstimateModalAssignmentId,
  setMoveItemId,
  showActionTriggerIcon,
  triggerRef,
  viewLocation,
  showBeyondLimitModal,
  setJobId,
  setOpenTemplatePickerModal,
  setOpenPackageToTemplateModal,
  setOpenProjectToTemplateModal,
  setOpenTemplateToPackageModal,
  setOpenTemplateToProjectModal,
  setShowProjectPowerFeatureModal,
  setShowJiraProjectModal,
  setPackageId,
}: SingleItemEllipsisActionDropdownProps) => {
  const { itemId: itemIdParam } = useParams<{ itemId: string }>();

  const isAnyItemDuplicating = useSelector(getIsAnyItemDuplicationInProgress);

  const [usingDropdown, setUsingDropdown] = useState(false);

  const openDropdown = () => setUsingDropdown(true);

  const hideDropdown = () => {
    handleHideContextMenu && handleHideContextMenu();
    setUsingDropdown(false);
  };

  const { handlers } = useEllipsisActionHandlers({
    afterDelete,
    fetchItems,
    hideDropdown,
    item,
    itemIdParam,
    setDeletionData,
    setEstimateModalAssignmentId,
    setMoveItemId,
    showBeyondLimitModal,
    viewLocation,
    setJobId,
    setOpenTemplatePickerModal,
    setOpenPackageToTemplateModal,
    setOpenProjectToTemplateModal,
    setOpenTemplateToPackageModal,
    setOpenTemplateToProjectModal,
    setShowProjectPowerFeatureModal,
    setShowJiraProjectModal,
    setPackageId,
  });

  const { dropdownMenuItems } = useEllipsisActionMenuItems(
    item.id,
    item.itemType,
    ellipsisActionDropdownMethods({
      ...handlers,
      expandSubFolders,
      handleDuplicateItem,
      isGridView: viewLocation === EllipsisActionViewLocation.GridView,
      item,
      itemIdParam,
      isAnyItemDuplicating,
      hideDropdown,
    }),
    viewLocation,
  );

  return (
    <PortalActionDropdown
      className="context-menu"
      clickLocation={clickLocation}
      triggerRef={triggerRef}
      onOpen={openDropdown}
      onClose={hideDropdown}
      showTrigger={showActionTriggerIcon}
      open={usingDropdown}
    >
      {dropdownMenuItems}
    </PortalActionDropdown>
  );
};
