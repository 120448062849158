import { combineReducers } from '@reduxjs/toolkit';

import outpost, { initialState as outpostsInitialState } from 'features/academy/outpost/slice';
import rulesEditor, {
  initialState as rulesEditorInitialState,
} from 'features/administration/settings/settings_page/rate_sheets/rules_editor/slice';
import bulkSelection, { initialState as bulkSelectionInitialState } from 'features/common/bulk_selection/slice';
import current, { initialState as currentInitialState } from 'features/common/current/slice';
import customItemFilter, {
  initialState as customItemFilterInitialState,
} from 'features/common/custom_item_filter/slice';
import itemDataGrid, { initialState as itemDataGridInitialState } from 'features/common/data_grid/slice';
import duplicate, { initialState as duplicateInitialState } from 'features/common/duplicate/slice';
import events, { initialState as eventsInitialState } from 'features/common/events/slice';
import itemPicker, { initialState as itemPickerInitialState } from 'features/common/item_picker/slice';
import localValidation, { initialState as localValidationInitialState } from 'features/common/local_validation/slice';
import taskBoard, { initialState as taskBoardInitialState } from 'features/common/task_board/slice';
import timesheetsReview, {
  initialState as timesheetsReviewInitialState,
} from 'features/common/timesheet/timesheet_review/slice';
import errors, { initialState as errorInitialState } from 'features/errors/slice';
import library, { initialState as libraryInitialState } from 'features/library/slice';
import organizationHub, {
  initialState as organizationDirectoryInitialState,
} from 'features/organization_directory/slice';
import projectHub, { initialState as projectHubInitialState } from 'features/ppp/project/slice';
import portfolioGrid, { initialState as portfolioGridInitialState } from 'features/ppp/slice';
import entities, { initialState as entitiesInitialState } from 'state/entities/slice';

export const rootReducer = combineReducers({
  bulkSelection,
  current,
  customItemFilter,
  library,
  duplicate,
  entities,
  errors,
  events,
  itemDataGrid,
  itemPicker,
  localValidation,
  organizationHub,
  outpost,
  portfolioGrid,
  projectHub,
  rulesEditor,
  taskBoard,
  timesheetsReview,
});

export type RootState = ReturnType<typeof rootReducer>;

export const initialRootState: RootState = {
  bulkSelection: bulkSelectionInitialState,
  current: currentInitialState,
  customItemFilter: customItemFilterInitialState,
  library: libraryInitialState,
  duplicate: duplicateInitialState,
  entities: entitiesInitialState,
  errors: errorInitialState,
  events: eventsInitialState,
  itemDataGrid: itemDataGridInitialState,
  itemPicker: itemPickerInitialState,
  localValidation: localValidationInitialState,
  organizationHub: organizationDirectoryInitialState,
  outpost: outpostsInitialState,
  portfolioGrid: portfolioGridInitialState,
  projectHub: projectHubInitialState,
  rulesEditor: rulesEditorInitialState,
  taskBoard: taskBoardInitialState,
  timesheetsReview: timesheetsReviewInitialState,
};
