import { useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { WorkspaceUser } from 'daos/model_types';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { DisconnectModalProps } from 'features/organization_directory/users/profile/workspace_memberships/disconnect_modal';
import { useHasAccess } from 'hooks/use_has_access';
import { getWorkspaceUserById } from 'state/entities/selectors/user';

interface WorkspaceMemberCheckboxProps {
  isAdmin: boolean;
  isCurrentUser: boolean;
  orgUserId: number;
  wsUserId: number;
  updateWsUser: (updateProps: Partial<WorkspaceUser>) => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDisconnectModalProps: React.Dispatch<React.SetStateAction<DisconnectModalProps | undefined>>;
}

const WorkspaceMemberCheckBox = ({
  isAdmin,
  isCurrentUser,
  orgUserId,
  updateWsUser,
  wsUserId,
  setIsModalOpen,
  setDisconnectModalProps,
}: WorkspaceMemberCheckboxProps) => {
  const handleReconnectWsUser = () => updateWsUser({ disconnected: false });

  const targetWsUser = useSelector((state) => getWorkspaceUserById(state, wsUserId));
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  const currentUserHasAdminAccessOnWsUser = useHasAccess(Permission.ADMIN, WorkspaceUserDao.id(wsUserId));

  if (!targetWsUser) {
    return null;
  }

  const onMemberChange = () => {
    if (!targetWsUser.disconnectedAt) {
      setDisconnectModalProps({
        workspaceId: currentWorkspaceId,
        workspaceUserId: wsUserId,
        organizationUserId: orgUserId,
        close: () => setIsModalOpen(false),
      });
      setIsModalOpen(true);
    } else {
      handleReconnectWsUser();
    }
  };

  return (
    <>
      <Checkbox
        checked={!targetWsUser.disconnectedAt}
        className="user-profile__table-checkbox"
        disabled={!currentUserHasAdminAccessOnWsUser || isCurrentUser || isAdmin || targetWsUser.manager}
        onChange={onMemberChange}
      />
    </>
  );
};

export default WorkspaceMemberCheckBox;
