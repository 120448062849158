import { useSignalEffect } from '@preact/signals-react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'semantic-ui-react';

import { OrderItemFetch } from 'daos/item_enums';
import { ItemFileDao } from 'daos/item_file';
import { ItemFile } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { useSearchPageContext } from 'features/common/search/context';
import { UseItemSearchProps } from 'features/common/search/hooks/use_item_search';
import { ResultFile } from 'features/common/search/result_file';
import { awaitRequestFinish } from 'lib/api';
import { searchItemFilesFilter } from 'lib/quick_filter_and_search_helpers/search';

interface SearchFilesTabProps {
  emptyState: ReactNode;
  searchProps: UseItemSearchProps;
}
export function SearchFilesTab({ emptyState, searchProps }: SearchFilesTabProps) {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const [searchResults, setSearchResults] = useState<ReadonlyArray<ItemFile> | undefined>(undefined);
  const { searchEvent } = useSearchPageContext();
  const itemFileSearch = (searchValue: string, includeDone: boolean) => {
    const { uuid } = dispatch(
      ItemFileDao.fetchAll(
        {
          organizationId,
          workspaceId,
        },
        {
          filter: searchItemFilesFilter({ ...searchProps, searchValue, includeDone }),
          include: {
            includeItems: true,
            includeFiles: true,
          },
          query: { limit: 500, order: OrderItemFetch.GlobalPriority },
        },
      ),
    );

    dispatch(
      awaitRequestFinish<ReadonlyArray<ItemFile>>(uuid, {
        onSuccess: ({ data }) => {
          setSearchResults(data);
        },
      }),
    );
  };

  useSignalEffect(() => {
    const searchEventValue = searchEvent.value;
    if (!searchEventValue) {
      setSearchResults(undefined);
      return;
    }

    itemFileSearch(searchEventValue.searchTerm, searchEventValue.includeDone);
  });

  return (
    <AnimatePresence>
      {searchResults?.length ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="lp-search__results"
        >
          <List divided className="lp-search__results-files">
            {searchResults.map((itemFile, index) => (
              <ResultFile key={itemFile.id} itemFile={itemFile} resultNumber={index + 1} />
            ))}
          </List>
        </motion.div>
      ) : (
        emptyState
      )}
    </AnimatePresence>
  );
}
