/* eslint-disable check-file/filename-naming-convention */
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { JiraProjectModalFormFields } from 'features/jira_project/modal/types';
import { DEFAULT_JIRA_HIGH_ESTIMATE_PERCENTAGE, DEFAULT_JIRA_LOW_ESTIMATE_PERCENTAGE } from 'lib/constants';

const EstimatePercentageInput = ({ highValue, lowValue }: { highValue: number; lowValue: number }) => {
  const { canModify } = useJiraProjectModalContext();
  const [isEditing, setIsEditing] = useState(false);

  const startEditMode = () => setIsEditing(true);
  const { setFieldValue } = useFormikContext<JiraProjectModalFormFields>();

  const handleChange = (_: React.SyntheticEvent, { value }: { value: string }) => {
    const updatedHighEstimateValue = Number(value) > 100 ? 100 : Math.abs(Number(value));
    const updatedLowEstimateValue = -Math.abs(Number(updatedHighEstimateValue));

    setFieldValue(JiraProjectModalFormFields.LowEstimate, updatedLowEstimateValue);
    setFieldValue(JiraProjectModalFormFields.HighEstimate, updatedHighEstimateValue);
  };

  const handleBlur = () => {
    setFieldValue(JiraProjectModalFormFields.LowEstimate, lowValue ?? DEFAULT_JIRA_LOW_ESTIMATE_PERCENTAGE);
    setFieldValue(JiraProjectModalFormFields.HighEstimate, highValue ?? DEFAULT_JIRA_HIGH_ESTIMATE_PERCENTAGE);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <Form.Input
        disabled={!canModify}
        onChange={handleChange}
        onBlur={handleBlur}
        value={highValue}
        type="number"
        max={100}
        min={0}
      />
    );
  }

  return (
    <Form.Input disabled={!canModify} autoFocus={false} value={`${highValue}%`} onFocus={startEditMode} type="string" />
  );
};

export default EstimatePercentageInput;
