import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import NavigationSection from 'containers/shared/navigation_section';
import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { useSetupOutpost } from 'features/academy/outpost/use_setup_outpost';
import { OrganizationPickerModal } from 'features/administration/organization_switch/organization_picker_modal';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { AuthType } from 'features/authentication/enums';
import useCurrentUserAuthType from 'features/authentication/hooks/use_current_user_auth_type';
import { OrganizationLinkCrumb } from 'features/common/breadcrumbs/breadcrumb_helpers';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { globeSolid as workspaceIcon } from 'features/common/lp_icon';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import OrganizationWorkspaces from 'features/organization_directory/organization_workspaces';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { getIsGuestCurrentWorkspaceUser } from 'state/entities/selectors/user';

import './index.scss';

const OrganizationDirectory = ({
  setEntityNotFound,
}: {
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}) => {
  const isGuestWsUser = useSelector(getIsGuestCurrentWorkspaceUser);
  const [orgSwitchOpen, setOrgSwitchOpen] = useState(false);

  const handleOrgSwitchClosed = () => setOrgSwitchOpen(false);
  const handleOrgSwitchOpen = () => setOrgSwitchOpen(true);

  const authType = useCurrentUserAuthType();
  const canSwitchOrgs = !isGuestWsUser && authType === AuthType.LP_AUTH;

  const sections = [
    { key: 'organization-name', content: <OrganizationLinkCrumb icon={workspaceIcon} /> },
    {
      key: NavigationMenuDisplay.Workspaces,
      content: (
        <>
          {NavigationMenuDisplay.Workspaces}
          {canSwitchOrgs && (
            <span className="switch-org">
              (
              <span className="clickable" onClick={handleOrgSwitchOpen}>
                Change Org
              </span>
              )
            </span>
          )}
        </>
      ),
    },
  ];

  useSetupOutpost(OutpostLocation.OrganizationWorkspaces);

  return (
    <>
      <TopNavHeader breadcrumb={<LpBreadcrumb sections={sections} />} />
      <div className="organization-directory">
        {orgSwitchOpen && (
          <OrganizationPickerModal handleCloseModal={handleOrgSwitchClosed} setEntityNotFound={setEntityNotFound} />
        )}

        <NavigationSection headerText={'Active (Select to switch workspaces) '}>
          <OrganizationWorkspaces />
        </NavigationSection>
      </div>
    </>
  );
};

export default OrganizationDirectory;
