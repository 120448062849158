import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { Dashboard, Organization } from 'daos/model_types';
import { LpIcon, cloudCheckSolid } from 'features/common/lp_icon';
import {
  getCurrentUserDashboardGuestOrgsSortedByName,
  getCurrentUserActiveGuestSortedWorkspacesKeyedByOrgIdMap,
} from 'state/entities/selectors/dashboard_guest';

import { WorkspaceBody } from './workspace_body';

const OrganizationTable = ({
  organization,
  sortedDashboardsByWsIdMap,
}: {
  organization: Organization;
  sortedDashboardsByWsIdMap: Map<string, Array<Dashboard>>;
}) => {
  const sortedWorkspacesByOrgIdMap = useSelector(getCurrentUserActiveGuestSortedWorkspacesKeyedByOrgIdMap);

  const organizationName = organization.name;
  const workspaces = sortedWorkspacesByOrgIdMap.get(String(organization.id)) ?? [];

  return (
    <Table compact className="dashboard-passport-table">
      <Table.Header className="dashboard-passport-table__organization-header">
        <Table.Row>
          <Table.HeaderCell colSpan={2}>
            <LpIcon icon={cloudCheckSolid} /> {organizationName}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {workspaces.map((ws) => (
        <WorkspaceBody
          key={ws.id}
          workspace={ws}
          sortedDashboardsByWsIdMap={sortedDashboardsByWsIdMap}
          organizationId={String(organization.id)}
        />
      ))}
    </Table>
  );
};

export const PassportTables = ({ dashboardsByWsIdMap }: { dashboardsByWsIdMap: Map<string, Array<Dashboard>> }) => {
  const sortedOrgsByName = useSelector(getCurrentUserDashboardGuestOrgsSortedByName);

  return (
    <>
      {sortedOrgsByName.map((org) => (
        <OrganizationTable key={org.id} organization={org} sortedDashboardsByWsIdMap={dashboardsByWsIdMap} />
      ))}
    </>
  );
};
