import { useCallback } from 'react';
import { Table } from 'semantic-ui-react';

import { CustomField } from 'daos/model_types';
import { CustomFieldFilterWithRequiredId } from 'daos/types';
import { exclamationTriangleSolid, LpIcon } from 'features/common/lp_icon';
import { customFilterAllowedCustomFields } from 'state/entities/selectors/custom_field';
import { red500 } from 'style/variables';

import { CreatedByRow, CustomFieldRow, NameContainsRow, CheckBoxRow } from './other_properties_row';

import './other_properties_options_table.scss';

export interface OtherPropertiesTableValue {
  createdByUserId?: number;
  nameContainsValue: string;
  otherCustomFieldProperties: ReadonlyArray<CustomFieldFilterWithRequiredId>;
  selectedAtRisk: boolean;
  selectedWorkLimitRisk: boolean;
  selectedAsap: boolean;
  selectedHasFiles: boolean;
}

interface OtherPropertiesTableProps {
  customFields: ReadonlyArray<CustomField>;
  onChange: (value: OtherPropertiesTableValue) => void;
  showAsapRow?: boolean;
  showHasFilesRow: boolean;
  title: string;
  value: OtherPropertiesTableValue;
}

const OtherPropertiesOptionsTable = ({
  customFields,
  onChange,
  showAsapRow = false,
  showHasFilesRow,
  title,
  value,
}: OtherPropertiesTableProps) => {
  const allowedCustomFields = customFields.filter((field) => customFilterAllowedCustomFields.has(field.fieldType));
  const handleRowChange = useCallback(
    (newValue: Partial<OtherPropertiesTableValue>) => {
      onChange({ ...value, ...newValue });
    },
    [onChange, value],
  );

  return (
    <div className="other-properties-options-table">
      <span className="other-properties-options-table__header">
        <div className="other-properties-options-table__header-name">{title}</div>
      </span>
      <div className="other-properties-options-table__scroll-container">
        <Table celled>
          <Table.Body>
            <>
              <CheckBoxRow
                onChange={(value) => handleRowChange({ selectedAtRisk: value })}
                checked={value.selectedAtRisk}
                name={'Schedule Risk'}
                content={<LpIcon className="icon" color={red500} icon={exclamationTriangleSolid} />}
              />

              <CheckBoxRow
                onChange={(value) => handleRowChange({ selectedWorkLimitRisk: value })}
                checked={value.selectedWorkLimitRisk}
                name={'Work Limit Risk'}
                content={<LpIcon className="icon" color={red500} icon={exclamationTriangleSolid} />}
              />

              {showAsapRow && (
                <CheckBoxRow
                  onChange={(value) => handleRowChange({ selectedAsap: value })}
                  checked={value.selectedAsap}
                  name={'ASAP'}
                  content={'Priority Override is Active'}
                />
              )}

              <NameContainsRow
                onChange={(value) => handleRowChange({ nameContainsValue: value })}
                value={value.nameContainsValue}
              />

              {showHasFilesRow && (
                <CheckBoxRow
                  onChange={(value) => handleRowChange({ selectedHasFiles: value })}
                  checked={value.selectedHasFiles}
                  name={'Has Files'}
                />
              )}

              <CreatedByRow
                onChange={(value) => handleRowChange({ createdByUserId: value })}
                value={value.createdByUserId}
              />

              {allowedCustomFields.map((customField) => {
                return (
                  <CustomFieldRow
                    key={customField.id}
                    customField={customField}
                    onChange={(value) => handleRowChange({ otherCustomFieldProperties: value })}
                    value={value.otherCustomFieldProperties}
                  />
                );
              })}
            </>
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default OtherPropertiesOptionsTable;
