import classNames from 'classnames';
import { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'semantic-ui-react';

import { PackageStatus } from 'daos/enums';
import { Dashboard, Widget } from 'daos/model_types';
import { LpButtonAsLink } from 'features/common/as_components';
import { chevronRightSolid, LpIcon } from 'features/common/lp_icon';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { useGetIntakeWidgetTitleOptions } from 'features/dashboards_v2/widget_click_through/intake/hooks/use_get_intake_widget_title_options';
import { IntakeWidgetData, IntakeWidgetUrlParams } from 'features/dashboards_v2/widget_click_through/intake/types';
import { LibraryLocation } from 'features/library/types';
import { getIsGuestCurrentWorkspaceUser } from 'state/entities/selectors/user';

import { getIntakeFormUrl } from './helpers';

import './index.scss';

export const IntakeWidget = ({ widget }: { widget: Widget<IntakeWidgetData> }) => {
  const { title } = widget.config;
  const { dashboard, inDesign, libraryLocation, packageStatus } = useDashboardContext();
  const { getIntakeWidgetTitleOptions } = useGetIntakeWidgetTitleOptions();

  const { itemType, sections } = widget.data.form;

  const { initialIntakeTitle, hasNameField, placeholderText, intakeDisplayName, isNameFieldAutomatic } =
    getIntakeWidgetTitleOptions(itemType, sections);

  if (!dashboard) {
    return null;
  }

  const header = title ? null : `New ${intakeDisplayName}`;
  const { organizationId, workspaceId, id: widgetId } = widget;
  const intakeWidgetUrlParams = { organizationId, workspaceId, widgetId };

  const body = (
    <div className="intake-widget-input">
      <IntakeWidgetInput
        key={initialIntakeTitle}
        inDesign={inDesign}
        isNameFieldAutomatic={isNameFieldAutomatic}
        placeholderText={placeholderText}
        dashboard={dashboard}
        initialIntakeTitle={initialIntakeTitle}
        hasNameField={hasNameField}
        intakeWidgetUrlParams={intakeWidgetUrlParams}
        libraryLocation={libraryLocation}
        packageStatus={packageStatus ?? undefined}
      />
    </div>
  );

  return <RenderedWidget body={body} header={header} />;
};

const IntakeWidgetInput = ({
  inDesign,
  isNameFieldAutomatic,
  placeholderText,
  dashboard,
  initialIntakeTitle,
  hasNameField,
  intakeWidgetUrlParams,
  libraryLocation,
  packageStatus,
}: {
  inDesign: boolean;
  isNameFieldAutomatic: boolean;
  placeholderText: string;
  initialIntakeTitle: string;
  dashboard: Dashboard;
  hasNameField: boolean;
  intakeWidgetUrlParams: IntakeWidgetUrlParams;
  libraryLocation: LibraryLocation | undefined;
  packageStatus: PackageStatus | undefined;
}) => {
  const isGuest = useSelector(getIsGuestCurrentWorkspaceUser);
  const [intakeTitle, setIntakeTitle] = useState(initialIntakeTitle);

  const handleChange = (_: SyntheticEvent, { value }: { value: string }) => setIntakeTitle(value);

  return (
    <>
      {hasNameField && (
        <Input
          className={classNames('intake-widget-input__item-name', {
            'intake-widget-input__item-name--in-design': inDesign,
          })}
          disabled={isNameFieldAutomatic || inDesign}
          placeholder={placeholderText}
          onChange={handleChange}
          value={intakeTitle}
        />
      )}

      <LpButtonAsLink
        className="intake-widget-input__button"
        disabled={inDesign}
        to={`${getIntakeFormUrl({ dashboard, intakeWidgetUrlParams, isGuest, libraryLocation, packageStatus })}${
          intakeTitle ? `#intakeTitle=${intakeTitle}` : ''
        }`}
        primary
      >
        <div className="intake-widget-input__button-content">
          Next <LpIcon icon={chevronRightSolid} />
        </div>
      </LpButtonAsLink>
    </>
  );
};
