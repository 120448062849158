/* eslint-disable no-restricted-imports */
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';

import LpPopUp from '../lp_popup';

export { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
export { faJira as jira, faSlack as slack } from '@fortawesome/free-brands-svg-icons';
export {
  faAbacus as abacusLight,
  faBallPile as ballPileLight,
  faBarsStaggered as barsStaggeredLight,
  faBell as bellLight,
  faBolt as boldLight,
  faBoxAlt as boxAltLight,
  faBoxHeart as boxHeartLight,
  faBriefcase as briefcaseLight,
  faBullseyePointer as bullseyePointerLight,
  faCactus as cactusLight,
  faCalendarArrowUp as calendarArrowUpLight,
  faCalendarCheck as calendarCheckLight,
  faCalendarRange as calendarRangeLight,
  faCameraRetro as cameraRetroLight,
  faCircle as circleLight,
  faCircleMinus as circleMinusLight,
  faCirclePlus as circlePlusLight,
  faCircleXmark as circleXmarkLight,
  faClock as clockLight,
  faClockRotateLeft as clockRotateLeftLight,
  faCodeBranch as codeBranchLight,
  faCoffee as coffeeLight,
  faCogs as cogsLight,
  faComputerMouse as computerMouseLight,
  faCopy as copyLight,
  faDoNotEnter as doNotEnterLight,
  faEdit as editLight,
  faExclamationTriangle as exclamationTriangleLight,
  faFile as fileLight,
  faFilter as filterLight,
  faFlagCheckered as flagCheckeredLight,
  faFlag as flagLight,
  faFlask as flaskLight,
  faFolder as folderLight,
  faFolderOpen as folderOpenLight,
  faFolderTree as folderTreeLight,
  faFolders as foldersLight,
  faGift as giftLight,
  faHexagon as hexagonLight,
  faIdCardClip as idCardClipLight,
  faIdCard as idCardLight,
  faImage as imageLight,
  faImageSlash as imageSlashLight,
  faInfoCircle as infoCircleLight,
  faKey as keyLight,
  faLineColumns as lineColumnsLight,
  faLinkHorizontal as linkHorizontalLight,
  faLinkHorizontalSlash as linkHorizontalSlashLight,
  faLocationCircle as locationCircleLight,
  faMagic as magicLight,
  faMagnifyingGlassLocation as magnifyingGlassLocationLight,
  faMap as mapLight,
  faMapMarkedAlt as mapMarkedAltLight,
  faMinusCircle as minusCircleLight,
  faNote as noteLight,
  faPaperPlane as paperPlaneLight,
  faPaperclip as paperclipLight,
  faPenToSquare as penToSquareLight,
  faPencil as pencilLight,
  faPlane as planeLight,
  faPlayCircle as playCircleLight,
  faPoll as pollLight,
  faPresentation as presentationLight,
  faRabbitFast as rabbitFastLight,
  faRadar as radarLight,
  faRobot as robotLight,
  faScroll as scrollLight,
  faSearch as searchLight,
  faSeedling as seedlingLight,
  faSensorAlert as sensorAlertLight,
  faShieldCheck as shieldCheckLight,
  faShieldMinus as shieldMinusLight,
  faShippingFast as shippingFastLight,
  faShuttleVan as shuttleVanLight,
  faSortAmountDownAlt as sortAmountAltLight,
  faSpaceShuttle as spaceShuttleLight,
  faSplit as splitLight,
  faSquareKanban as squareKanbanLight,
  faStar as starLight,
  faStars as starsLight,
  faTableCells as tableCellsLight,
  faTable as tableLight,
  faTag as tagLight,
  faThumbtack as thumbtackLight,
  faTimesCircle as timesCircleLight,
  faTrashXmark as trashXmarkLight,
  faUserClock as userClockLight,
  faUserHeadset as userHeadsetLight,
  faUserPlus as userPlusLight,
  faUsersClass as usersClassLight,
  faWrench as wrenchLight,
  faLayerGroup as layerGroupLight,
  faArchive as archiveLight,
  faBoxOpen as boxOpenLight,
} from '@fortawesome/pro-light-svg-icons';
export {
  faAngleDown as angleDownRegular,
  faAngleRight as angleRightRegular,
  faArrowAltRight as arrowAltRightRegular,
  faArrowLeft as arrowLeftRegular,
  faArrowLeftToLine as arrowLeftToLineRegular,
  faArrowRightToLine as arrowRightToLineRegular,
  faArrowRotateLeft as arrowRotateLeftRegular,
  faArrowTurnDownRight as arrowTurnDownRightRegular,
  faArrowUpRight as arrowUpRightRegular,
  faBan as banRegular,
  faBookOpen as bookOpenRegular,
  faCalendarArrowUp as calendarArrowUpRegular,
  faCalendarCheck as calendarCheckRegular,
  faCardsBlank as cardsBlankRegular,
  faCheckCircle as checkCircleRegular,
  faChevronDoubleLeft as chevronDoubleLeftRegular,
  faChevronDoubleRight as chevronDoubleRightRegular,
  faCircleArrowDown as circleArrowDownRegular,
  faCircleMinus as circleMinusRegular,
  faCircleXmark as circleXmarkRegular,
  faClockThree as clockThreeRegular,
  faCloudDownloadAlt as cloudDownloadRegular,
  faCog as cogRegular,
  faCompassDrafting as compassDraftingRegular,
  faDash as dashRegular,
  faEdit as editRegular,
  faExclamationTriangle as exclamationTriangleRegular,
  faFolderOpen as folderOpenRegular,
  faFolderClosed as folderClosedRegular,
  faFolder as folderRegular,
  faGift as giftRegular,
  faGlobe as globeRegular,
  faGraduationCap as graduationCapRegular,
  faGrid2 as grid2Regular,
  faIdCard as idCardRegular,
  faKey as keyRegular,
  faLevelDownAlt as levelDownAltRegular,
  faLoader as loaderRegular,
  faMessageQuote as messagesQuoteRegular,
  faMinus as minusRegular,
  faNote as noteRegular,
  faPassport as passportRegular,
  faPenToSquare as penToSquareRegular,
  faPlusCircle as plusCircleRegular,
  faPlus as plusRegular,
  faArrowRight as rightArrowRegular,
  faRocketLaunch as rocketLaunchRegular,
  faRotate as rotateRegular,
  faShieldAlt as shieldAltRegular,
  faSquare as squareRegular,
  faSquareUser as squareUserRegular,
  faStar as starRegular,
  faStream as streamRegular,
  faTimes as timesRegular,
  faToggleOff as toggleOffRegular,
  faToggleOn as toggleOnRegular,
  faUsers as usersRegular,
  faDotCircle as dotCircleRegular,
  faPauseCircle as pauseCircleRegular,
  faCircle as circleRegular,
  faXmark as xmarkRegular,
} from '@fortawesome/pro-regular-svg-icons';

export {
  faAngleLeft as angleLeftSolid,
  faAngleRight as angleRightSolid,
  faAnglesRight as anglesRightSolid,
  faAperture as apertureSolid,
  faArchive as archiveSolid,
  faArrowAltDown as arrowAltDownSolid,
  faArrowAltRight as arrowAltRightSolid,
  faArrowAltSquareDown as arrowAltSquareDownSolid,
  faArrowAltSquareLeft as arrowAltSquareLeftSolid,
  faArrowAltSquareRight as arrowAltSquareRightSolid,
  faArrowAltSquareUp as arrowAltSquareUpSolid,
  faArrowAltUp as arrowAltUpSolid,
  faArrowDownWideShort as arrowDownWideShortSolid,
  faArrowRightArrowLeft as arrowRightArrowLeftSolid,
  faArrowRotateRight as arrowRotateRightSolid,
  faArrowTurnDown as arrowTurnDownSolid,
  faArrowTurnUp as arrowTurnUpSolid,
  faArrowUpRightAndArrowDownLeftFromCenter as arrowUpRightAndArrowDownLeftFromCenterSolid,
  faArrowUpRightFromSquare as arrowUpRightFromSquareSolid,
  faArrowUpShortWide as arrowUpShortWideSolid,
  faArrowsV as arrowsVSolid,
  faAt as atSolid,
  faBackspace as backspaceSolid,
  faBallPile as ballPileSolid,
  faBars as barsSolid,
  faBarsStaggered as barsStaggeredSolid,
  faBookmark as bookmarkSolid,
  faBoxAlt as boxAltSolid,
  faBoxHeart as boxHeartSolid,
  faBoxOpen as boxOpenSolid,
  faBox as boxSolid,
  faBoxTaped as boxTapedSolid,
  faBullseyePointer as bullseyePointerSolid,
  faBullseye as bullseyeSolid,
  faBusinessTime as businessTimeSolid,
  faCactus as cactusSolid,
  faCalculator as calculatorSolid,
  faCalendarArrowUp as calendarArrowUpSolid,
  faCalendarCheck as calendarCheckSolid,
  faCalendarDay as calendarDaySolid,
  faCalendarExclamation as calendarExclamationSolid,
  faCaretDown as caretDownSolid,
  faCaretLeft as caretLeftSolid,
  faCaretRight as caretRightSolid,
  faChalkboard as chalkboardSolid,
  faCheckCircle as checkCircleSolid,
  faCheck as checkSolid,
  faCheckSquare as checkSquareSolid,
  faChevronCircleLeft as chevronCircleLeftSolid,
  faChevronDown as chevronDownSolid,
  faChevronLeft as chevronLeftSolid,
  faChevronRight as chevronRightSolid,
  faCircleCheck as circleCheckSolid,
  faCircleDot as circleDotSolid,
  faCircleExclamation as circleExclamationSolid,
  faCircleMinus as circleMinusSolid,
  faCirclePause as circlePauseSolid,
  faCirclePlus as circlePlusSolid,
  faCircleQuestion as circleQuestionSolid,
  faCircleSmall as circleSmallSolid,
  faCircle as circleSolid,
  faClock as clockSolid,
  faCloudCheck as cloudCheckSolid,
  faCloudDownloadAlt as cloudDownloadAltSolid,
  faCloudDownload as cloudDownloadSolid,
  faCloudExclamation as cloudExclamationSolid,
  faCloudUploadAlt as cloudUploadAltSolid,
  faCode as codeSolid,
  faCoffee as coffeeSolid,
  faCog as cogSolid,
  faCogs as cogsSolid,
  faCoin as coinSolid,
  faColumns as columnsSolid,
  faCompress as compressSolid,
  faConveyorBeltAlt as conveyorBeltAltSolid,
  faCreditCard as creditCardSolid,
  faCube as cubeSolid,
  faDotCircle as dotCircleSolid,
  faDownRight as downRightSolid,
  faEllipsisH as ellipsisHSolid,
  faEllipsisV as ellipsisVSolid,
  faEnvelopeOpenText as envelopeOpenTextSolid,
  faEnvelopesBulk as envelopesBulkSolid,
  faExclamation as exclamationSolid,
  faExclamationTriangle as exclamationTriangleSolid,
  faExpand as expandSolid,
  faEye as eyeSolid,
  faFileAudio as fileAudioSolid,
  faFileCode as fileCodeSolid,
  faFileCsv as fileCsvSolid,
  faFileExcel as fileExcelSolid,
  faFileImage as fileImageSolid,
  faFileLines as fileLinesSolid,
  faFilePdf as filePdfSolid,
  faFilePowerpoint as filePowerpointSolid,
  faFile as fileSolid,
  faFileSpreadsheet as fileSpreadSheet,
  faFileVideo as fileVideoSolid,
  faFileWord as fileWordSolid,
  faFileZipper as fileZipperSolid,
  faFilter as filterSolid,
  faFlagCheckered as flagCheckeredSolid,
  faFlagSwallowtail as flagShallowtailSolid,
  faFolderClosed as folderClosedSolid,
  faFolderOpen as folderOpenSolid,
  faFolderUser as folderUserSolid,
  faGear as gearSolid,
  faGift as giftSolid,
  faGlobe as globeSolid,
  faGraduationCap as graduationCapSolid,
  faGrid as gridSolid,
  faGripDotsVertical as gripDotsVerticalSolid,
  faHistory as historySolid,
  faHome as homeSolid,
  faIdCard as idCardSolid,
  faImage as imageSolid,
  faInfoCircle as infoCircleSolid,
  faInputNumeric as inputNumericSolid,
  faInputText as inputTextSolid,
  faIslandTropical as islandTropicalSolid,
  faKey as keySolid,
  faLampDesk as lampDeskSolid,
  faLayerGroup as layerGroupSolid,
  faLeft as leftArrowSolid,
  faLinkHorizontal as linkHorizontalSolid,
  faList as listSolid,
  faLocationArrow as locationArrowSolid,
  faLockAlt as lockAltSolid,
  faMapLocationDot as mapLocationDotSolid,
  faMedkit as medkitSolid,
  faMemoCircleInfo as memoCircleInfoSolid,
  faMessageQuote as messagesQuoteSolid,
  faMessages as messagesSolid,
  faMinusCircle as minusCircleSolid,
  faNote as noteSolid,
  faPaperclip as paperclipSolid,
  faPassport as passportSolid,
  faPauseCircle as pauseCircleSolid,
  faPause as pauseSolid,
  faPencil as pencilSolid,
  faPeopleCarry as peopleCarrySolid,
  faPeopleGroup as peopleGroupSolid,
  faPlane as planeSolid,
  faPlay as playSolid,
  faPlusCircle as plusCircleSolid,
  faPlus as plusSolid,
  faPlusSquare as plusSquareSolid,
  faPoll as pollSolid,
  faQuestionCircle as questionCircleSolid,
  faRectangleLandscape as rectangleLandscapeSolid,
  faRectangleWide as rectangleWideSolid,
  faRight as rightArrowSolid,
  faRobot as robotSolid,
  faScissors as scissorsSolid,
  faSearch as searchSolid,
  faSensorCloud as sensorCloudSolid,
  faSensorFire as sensorFireSolid,
  faShareFromSquare as shareFromSquare,
  faShieldCheck as shieldCheckSolid,
  faShieldHalved as shieldHalvedSolid,
  faShuffle as shuffleSolid,
  faSignOut as signOutSolid,
  faSparkles as sparklesSolid,
  faSpinner as spinnerSolid,
  faSquareCheck as squareCheckSolid,
  faSquareKanban as squareKanbanSolid,
  faSquare1 as squareOneSolid,
  faSquareQuestion as squareQuestionSolid,
  faSquare as squareSolid,
  faSquareUpRight as squareUpRightSolid,
  faSquareUser as squareUserSolid,
  faStarExclamation as starExclamationSolid,
  faStar as starSolid,
  faStars as starsSolid,
  faStream as streamSolid,
  faTableList as tableListSolid,
  faTag as tagSolid,
  faTags as tagsSolid,
  faText as textSolid,
  faThumbtack as thumbtackSolid,
  faTimeline as timelineSolid,
  faTimesCircle as timesCircleSolid,
  faTrash as trashSolid,
  faTrashXmark as trashXmarkSolid,
  faTriangle as triangleSolid,
  faUserAlt as userAltSolid,
  faUserCircle as userCircleSolid,
  faUserClock as userClockSolid,
  faUserCog as userCogSolid,
  faUserFriends as userFriendSolid,
  faUserGroup as userGroupSolid,
  faUserHeadset as userHeadsetSolid,
  faUserPen as userPenSolid,
  faUserPlus as userPlusSolid,
  faUserShield as userShieldSolid,
  faUser as userSolid,
  faUserTimes as userTimesSolid,
  faUsers as usersSolid,
  faWindowClose as windowCloseSolid,
  faWindowMaximize as windowMaximizeSolid,
} from '@fortawesome/pro-solid-svg-icons';

export interface LpIconProps extends FontAwesomeIconProps {
  hoverText?: string;
}

export const LpIcon = forwardRef<SVGSVGElement, LpIconProps>(({ hoverText, ...faProps }, ref) =>
  hoverText ? (
    <LpPopUp
      positionStrategy="fixed"
      placement="top"
      trigger={<FontAwesomeIcon {...faProps} ref={ref} />}
      content={<div>{hoverText}</div>}
    />
  ) : (
    <FontAwesomeIcon {...faProps} ref={ref} />
  ),
);
