import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { ItemType, PackageStatus } from 'daos/enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { angleRightSolid, LpIcon } from 'features/common/lp_icon';
import { usePackageCollectionUrlForPackageStatus } from 'hooks/use_package_collection_url_for_package_status';
import { frontend } from 'lib/urls';
import { getAncestryForBreadcrumb, getPackageStatusForItemId } from 'state/entities/selectors/item';

import './item_ancestry_breadcrumb.scss';

const CollectionSection = ({
  packageStatus,
  className,
  includeLink,
}: {
  packageStatus: PackageStatus;
  className?: string;
  includeLink: boolean;
}) => {
  const packageCollectionUrl = usePackageCollectionUrlForPackageStatus(packageStatus);

  const collectionName = packageStatusDisplayNameByPackageStatus[packageStatus];

  return (
    <span className={classNames('ancestry-breadcrumb__item', `ancestry-breadcrumb__item--${packageStatus}`, className)}>
      {includeLink ? <LpLink to={packageCollectionUrl}>{collectionName}</LpLink> : collectionName}
    </span>
  );
};

export const BreadcrumbSection = ({
  itemId,
  itemType,
  itemName,
  className = 'ancestry-item-breadcrumb',
  includeLink,
}: {
  itemId: number;
  itemType: ItemType;
  itemName: string | null;
  className?: string;
  includeLink: boolean;
}) => {
  const currentOrganizationId = useSelector(getCurrentOrganizationId);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  const destinationURL =
    itemType === ItemType.PACKAGES
      ? frontend.singlePackage.url({
          packageId: itemId,
          organizationId: currentOrganizationId,
          workspaceId: currentWorkspaceId,
        })
      : frontend.projectProject.url({
          itemId,
          organizationId: currentOrganizationId,
          workspaceId: currentWorkspaceId,
        });

  if (!itemName) {
    return null;
  }

  return (
    <span className={classNames(className, `${className}--${itemType}`)} key={itemId}>
      {includeLink ? <LpLink to={destinationURL}>{itemName}</LpLink> : itemName}
    </span>
  );
};

interface ItemBreadcrumbRendererProps {
  ancestryBreadcrumbs: Array<{
    readonly id: number;
    readonly name: string | null;
    readonly itemType: ItemType;
  }>;
  disabled?: boolean;
}

export const ItemAncestryBreadcrumbRenderer = ({
  ancestryBreadcrumbs,
  disabled = false,
}: ItemBreadcrumbRendererProps) => {
  return (
    <div className="ancestry-breadcrumb">
      {'['}
      <Breadcrumb
        size="small"
        className={classNames('ancestry-breadcrumb__list')}
        divider={<LpIcon icon={angleRightSolid} />}
        sections={ancestryBreadcrumbs.map(({ id, name, itemType }) => (
          <BreadcrumbSection
            className="ancestry-breadcrumb__item"
            key={id}
            itemType={itemType}
            itemName={name}
            itemId={id}
            includeLink={!disabled}
          />
        ))}
      />
      {']'}
    </div>
  );
};

interface ItemAncestryBreadcrumbProps {
  buildAncestryUpToItemType: ItemType;
  className?: string;
  itemId: number;
  includeLink?: boolean;
  includeCollection?: boolean;
}
export const ItemAncestryBreadcrumb = ({
  buildAncestryUpToItemType,
  className = '',
  itemId,
  includeLink = true,
  includeCollection = false,
}: ItemAncestryBreadcrumbProps) => {
  const itemPackageStatus = useSelector((state) => getPackageStatusForItemId(state, itemId));
  const ancestry = useSelector((state) => getAncestryForBreadcrumb(state, itemId, buildAncestryUpToItemType));

  if (!ancestry.length && !includeCollection) {
    return null;
  }

  const breadCrumbSections = ancestry.map((ancestryItem) => (
    <BreadcrumbSection
      key={ancestryItem.id}
      itemId={ancestryItem.id}
      itemType={ancestryItem.itemType}
      itemName={ancestryItem.name}
      className={className}
      includeLink={includeLink}
    />
  ));

  if (includeCollection && itemPackageStatus) {
    breadCrumbSections.unshift(
      <CollectionSection
        key={itemPackageStatus}
        packageStatus={itemPackageStatus}
        className={className}
        includeLink={includeLink}
      />,
    );
  }

  return (
    <div className="ancestry-breadcrumb">
      {'['}
      <Breadcrumb
        size="small"
        className={classNames('ancestry-breadcrumb__list', className)}
        divider={<LpIcon icon={angleRightSolid} />}
        sections={breadCrumbSections}
      />
      {']'}
    </div>
  );
};
