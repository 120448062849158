import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { getCurrentUserId } from 'features/common/current/selectors';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { blurElementOnEnterKey } from 'lib/helpers/blur_element_on_key_press';
import { getOrganizationUserInCurrentOrgForUserId } from 'state/entities/selectors/user';

export const CustomFieldCurrency = ({ fieldId, resourceId, disabled = false }: CustomFieldValueProps) => {
  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);
  const { updateFieldValue, createFieldValue, removeFieldValue } = useCustomFieldsApiActions(resourceId.type);

  const { allowsMultipleValues, values, currencyUnit } = field ?? {};

  if (allowsMultipleValues) {
    throw new Error('multiple values not supported by currency field');
  }

  const fieldValue = values?.[0];
  const fieldValueCurrency = String(fieldValue?.currency ?? '');

  const handleBlur = (value: string) => {
    if (!value) {
      fieldValue && removeFieldValue(resourceId.id, fieldValue.id);
    } else if (!isNaN(Number(value))) {
      const payload = { currency: Number(value) };
      if (fieldValue) {
        updateFieldValue(resourceId.id, fieldValue.id, payload);
      } else {
        createFieldValue(resourceId.id, fieldId, payload);
      }
    }
  };
  return (
    <ControlledCustomFieldCurrency
      classname={`lp-custom-field-currency__${fieldId}`}
      hasError={false}
      currencyUnit={currencyUnit}
      currencyValue={fieldValueCurrency}
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
};

export const ControlledCustomFieldCurrency = ({
  hasError,
  classname = '',
  currencyValue,
  currencyUnit,
  onBlur,
  disabled,
  hasAutoFocus = false,
}: {
  hasError: boolean;
  currencyValue: string;
  classname?: string;
  currencyUnit: string | null | undefined;
  onBlur: (value: string) => void;
  disabled: boolean;
  hasAutoFocus?: boolean;
}) => {
  const { formatCurrency } = useLocalizedFormats();
  const userId = useSelector(getCurrentUserId);
  const orgUser = useSelector((state) => getOrganizationUserInCurrentOrgForUserId(state, userId));

  const [value, setValue] = useState(currencyValue);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setValue(currencyValue ?? '');
  }, [currencyValue, currencyUnit]);

  const handleChange = (_: React.SyntheticEvent, { value }: { value: string }) => {
    setValue(value);
  };

  const handleBlur = () => {
    if (!value) {
      setValue('');
      onBlur('');
    } else if (!isNaN(Number(value))) {
      setValue(value);
      onBlur(value);
    } else {
      setValue(currencyValue);
    }
    setIsEditing(false);
  };

  const formatedValue = useMemo(() => {
    return currencyUnit && value && orgUser && formatCurrency(value, currencyUnit, orgUser.numberFormat);
  }, [currencyUnit, formatCurrency, orgUser, value]);

  const startEditMode = () => setIsEditing(true);

  return (
    <>
      {isEditing ? (
        <Form.Input
          className={classname}
          onKeyDown={blurElementOnEnterKey}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          type="number"
        />
      ) : (
        <Form.Input
          error={hasError}
          className={classname}
          autoFocus={hasAutoFocus}
          onFocus={startEditMode}
          value={formatedValue}
          disabled={disabled}
        />
      )}
    </>
  );
};
