import { useFormikContext } from 'formik';
import { SyntheticEvent } from 'react';
import { Button } from 'semantic-ui-react';

import { LpIcon, plusSolid } from 'features/common/lp_icon';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { FieldMappingRow } from 'features/jira_project/modal/sections/field_mapping_section/field_mapping_row';
import {
  HandleAddFieldClick,
  HandleDeleteRowClick,
  ShouldDisableAddFieldButton,
} from 'features/jira_project/modal/sections/field_mapping_section/types';
import { useFieldMappings } from 'features/jira_project/modal/sections/field_mapping_section/use_field_mappings';
import {
  getAvailableFieldMappingRowNameColumnValues,
  getFieldMappingNameColumnPicklistOptions,
  getFieldMappingPicklistOptions,
} from 'features/jira_project/modal/sections/field_mapping_section/utils/picklist_utils';
import {
  FieldMappingRowType,
  FieldMappingValue,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  JiraTable,
} from 'features/jira_project/modal/types';
import { ProductName } from 'lib/use_product_name';

interface FieldMappingTableProps {
  shouldDisableAddFieldButton: ShouldDisableAddFieldButton;
  handleAddFieldClick: HandleAddFieldClick;
  handleDeleteRowClick: HandleDeleteRowClick;
  rowType: FieldMappingRowType;
  allLpFields: Array<FieldMappingValue>;
}

export const FieldMappingTable = ({
  shouldDisableAddFieldButton,
  handleAddFieldClick,
  handleDeleteRowClick,
  rowType,
  allLpFields,
}: FieldMappingTableProps) => {
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const { findFieldMappingItem, deleteFieldMappingItem } = useFieldMappings();
  const {
    jiraProjectMappingFieldData,
    addedJiraFieldMappingRowItems,
    addedLpFieldMappingRowItems,
    setAddedJiraFieldMappingRowItems,
    setAddedLpFieldMappingRowItems,
  } = useJiraProjectModalContext();

  const addedFieldMappingRows =
    rowType === FieldMappingRowType.LpToJira ? addedLpFieldMappingRowItems : addedJiraFieldMappingRowItems;
  const setAddedFieldMappingRows =
    rowType === FieldMappingRowType.LpToJira ? setAddedLpFieldMappingRowItems : setAddedJiraFieldMappingRowItems;
  const availableFieldMappingRowNameColumnValues = getAvailableFieldMappingRowNameColumnValues({
    rowType,
    jiraProjectMappingFieldData,
    allLpFields,
    addedFieldMappingRows,
  });

  const handleNameColumnPicklistChange = ({ nameMappingId, index }: { nameMappingId: string; index: number }) => {
    setAddedFieldMappingRows((prev) => {
      const previousRow = Array.from(prev)[index];
      const updatedItems = [...prev];
      if (!nameMappingId) {
        updatedItems.splice(index, 1);
        return new Set(updatedItems);
      }
      updatedItems[index] = availableFieldMappingRowNameColumnValues.find(
        (item) => item.id === nameMappingId,
      ) as FieldMappingValue;

      const previousFieldMappingItem = findFieldMappingItem({ rowId: previousRow?.id ?? '', rowType });
      if (previousFieldMappingItem) {
        deleteFieldMappingItem({ fieldMappingItem: previousFieldMappingItem });
      }
      return new Set(updatedItems);
    });
  };

  return (
    <JiraProjectTable
      isEmpty={!addedFieldMappingRows.size}
      optionText
      title={
        rowType === FieldMappingRowType.LpToJira ? (
          <>
            Map <ProductName /> Fields
          </>
        ) : (
          'Map Jira Fields'
        )
      }
      collapsible
      isLast={rowType === FieldMappingRowType.LpToJira}
      jiraTable={rowType === FieldMappingRowType.LpToJira ? JiraTable.LpToJira : JiraTable.JiraToLp}
      actionButton={
        <Button
          disabled={shouldDisableAddFieldButton(rowType)}
          onClick={(e) => handleAddFieldClick({ event: e, fieldType: rowType })}
          primary
        >
          <LpIcon icon={plusSolid} />
          {rowType === FieldMappingRowType.LpToJira ? (
            <>
              <ProductName /> Field
            </>
          ) : (
            'Jira Field'
          )}
        </Button>
      }
    >
      {Array.from(addedFieldMappingRows).map((nameMapping, index) => {
        const nameColumnOptions = getFieldMappingNameColumnPicklistOptions({
          rowType,
          nameMapping,
          allLpFields,
          jiraProjectMappingFieldData,
          addedJiraFieldMappingRowItems,
          addedLpFieldMappingRowItems,
          fieldMappingValues: values[JiraProjectModalFormFields.FieldMappings],
        });
        const picklistOptions = getFieldMappingPicklistOptions({
          rowType,
          allLpFields,
          jiraProjectMappingFieldData,
          nameMapping,
          addedJiraFieldMappingRowItems,
          addedLpFieldMappingRowItems,
          fieldMappingValues: values[JiraProjectModalFormFields.FieldMappings],
        });

        return (
          <FieldMappingRow
            nameMapping={nameMapping}
            nameColumnPicklistData={nameColumnOptions}
            onNameColumnPicklistChange={(_: SyntheticEvent<HTMLElement, Event>, data: { value: string }) =>
              handleNameColumnPicklistChange({ nameMappingId: data.value, index })
            }
            picklistData={picklistOptions}
            key={nameMapping.id}
            rowType={rowType}
            onDeleteRowClick={handleDeleteRowClick}
          />
        );
      })}
    </JiraProjectTable>
  );
};
