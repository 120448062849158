import { all, call, select, takeEvery } from 'typed-redux-saga';

import { InvitationState, MarketingConsentStatus } from 'daos/enums';
import { Invitation } from 'daos/model_types';
import { filterAnd, filterEq } from 'daos/shared';
import { backend } from 'daos/urls';
import { getCurrentOrganizationId, getCurrentUser, getCurrentUserId } from 'features/common/current/selectors';
import { setCurrentWorkspaceId } from 'features/common/current/slice';
import { getMarketingTags } from 'features/common/marketing_tags';
import { HttpMethod, SuccessPayload } from 'lib/api/types';
import { IdentifiedUserProperties, avoClient } from 'lib/avo/client';
import { idToSegmentReadableString } from 'lib/avo/helpers';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import { getCurrentOrganizationPlan } from 'state/entities/selectors/plan';
import { getCurrentOrganizationSubscription } from 'state/entities/selectors/subscription';
import { getCurrentOrganizationUser, getCurrentWorkspaceUser } from 'state/entities/selectors/user';

const { segmentKey } = window as any;

function* handleUserIdentified() {
  const currentUserId = yield* select(getCurrentUserId);
  const currentUser = yield* select(getCurrentUser);
  const currentOrganizationUser = yield* select(getCurrentOrganizationUser);
  const currentWorkspaceUser = yield* select(getCurrentWorkspaceUser);
  const currentOrganizationId = yield* select(getCurrentOrganizationId);
  const organization = yield* select(getCurrentOrganization);
  const currentOrganizationPlan = yield* select(getCurrentOrganizationPlan);
  const marketingTags = yield* select(getMarketingTags);
  const currentOrganizationSubscription = yield* select(getCurrentOrganizationSubscription);

  const organizationId = organization?.id;
  const userEmail = currentUser?.email;

  let wasInvitedUser = false;

  if (organizationId && userEmail) {
    // @ts-expect-error: Suppressing lint due to a type mismatch from dynamic JSON parsing.
    const responseBody: SuccessPayload<ReadonlyArray<Invitation>> = yield* call(
      fetchInvitations,
      currentOrganizationId,
      userEmail,
    );

    if (responseBody.data.some((invitation: Invitation) => invitation.email === userEmail)) {
      wasInvitedUser = true;
    }
  }

  if (segmentKey && currentUserId) {
    const properties: IdentifiedUserProperties = {
      userId_: idToSegmentReadableString(currentUserId),
      emailUser: currentUser?.email ?? '',
      firstName: currentUser?.firstName ?? '',
      lastName: currentUser?.lastName ?? '',
      userTimezone: currentOrganizationUser?.timezone ?? '',
      userRole: currentOrganizationUser?.admin ? 'admin' : 'member',
      firstLogin: currentUser?.createdAt ?? '',
      accountIdUser: idToSegmentReadableString(currentOrganizationId),
      sfAccountId: currentOrganizationId,
      userCountry: currentUser?.countryCode ?? '',
      marketingConsent: `${currentUser?.marketingConsent == MarketingConsentStatus['Opt-In']}`,
      liquidplannerLicenseType: currentOrganizationPlan?.family ?? '',
      planId: idToSegmentReadableString(organization?.planId),
      liquidplannerCompanyName: organization?.name ?? '',
      guestUser: currentWorkspaceUser?.guestUser ? 'true' : 'false',
      lastLogin: currentWorkspaceUser?.lastAccessedAt ?? '',
      accountMemberNumber: idToSegmentReadableString(currentWorkspaceUser?.id),
      userUtmSource: marketingTags.utm_source ?? '',
      userUtmMedium: marketingTags.utm_medium ?? '',
      userUtmCampaign: marketingTags.utm_campaign ?? '',
      userUtmTerm: marketingTags.utm_term ?? '',
      userUtmContent: marketingTags.utm_content ?? '',
      phoneUser: currentUser?.phoneNumber ?? '',
      jobTitle: currentUser?.jobTitle ?? '',
      companySize: organization?.companySize ?? '',
      subId: currentOrganizationSubscription?.chargebeeSubscriptionId ?? '',
      hasVerifiedEmail: currentUser?.unconfirmedEmail === null,
      wasInvitedUser,
    };

    avoClient?.identifiedUser(properties);

    fetch(
      backend.userAnalyticsIdentify.url({
        organizationId: currentOrganizationId,
        workspaceId: currentWorkspaceUser?.workspace?.id ?? 0,
      }),
      { method: 'POST' },
    );
  }
}

function* fetchInvitations(
  organizationId: number,
  userEmail: string,
): Generator<Promise<Response>, SuccessPayload<ReadonlyArray<Invitation>>, unknown> {
  const response: Response = (yield fetch(
    backend.organizationInvitations.url(
      { organizationId },
      {
        filter: filterAnd(filterEq('state', InvitationState.Accepted), filterEq('email', userEmail)),
      },
    ),
    { method: HttpMethod.GET },
  )) as Response;

  return (yield response.json()) as SuccessPayload<ReadonlyArray<Invitation>>;
}

export default function* rootSaga() {
  yield* all([takeEvery(setCurrentWorkspaceId, handleUserIdentified)]);
}
