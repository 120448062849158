import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { TaskPushSetting } from 'daos/external_integration_enums';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { FieldMappingTable } from 'features/jira_project/modal/sections/field_mapping_section/field_mapping_table';
import { Legend } from 'features/jira_project/modal/sections/field_mapping_section/legend';
import {
  HandleAddFieldClick,
  HandleDeleteRowClick,
  ShouldDisableAddFieldButton,
} from 'features/jira_project/modal/sections/field_mapping_section/types';
import {
  addFieldMappingRow,
  deleteFieldMappingRow,
  shouldDisableAddRowButton,
} from 'features/jira_project/modal/sections/field_mapping_section/utils/row_utils';
import {
  FieldMappingRowType,
  FieldMappingValue,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  lpNameSystemMapping,
} from 'features/jira_project/modal/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';
import {
  getNonSystemFieldsByFieldTypesUsedOnTasks,
  supportedJiraMappingCustomFieldTypes,
} from 'state/entities/selectors/custom_field';

import 'features/jira_project/modal/jira_project_modal.scss';

const DateAndFieldMappingSection = () => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const hasJiraIssueCreationFeature = useHasFeature(FeatureFlag.jiraIssueCreation);

  const {
    addedLpFieldMappingRowItems,
    setAddedLpFieldMappingRowItems,
    addedJiraFieldMappingRowItems,
    setAddedJiraFieldMappingRowItems,
    jiraProjectMappingFieldData,
    isLoadingContent,
    isLoadingMappingScreenData,
  } = useJiraProjectModalContext();
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const { taskPushSetting } = values;
  const hasManualPushSetting = taskPushSetting === TaskPushSetting.Manual;
  const customLpFields = useSelector((state) =>
    getNonSystemFieldsByFieldTypesUsedOnTasks(state, supportedJiraMappingCustomFieldTypes),
  );

  const customLpFieldsAsNameMapping: Array<FieldMappingValue> = customLpFields.map((field) => {
    return {
      name: field.name,
      id: field.id.toString(),
      type: field.fieldType,
    };
  });
  const allLpFields = [...lpNameSystemMapping, ...customLpFieldsAsNameMapping].sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const jiraAcademyPermalink = frontend.portableAcademyLesson.url({
    portableLinkName: 'integrations_jira_integration',
  });

  const jiraIds = values[JiraProjectModalFormFields.FieldMappings].map((field) => field.sourceFieldId);
  const lpIds = values[JiraProjectModalFormFields.FieldMappings]
    .map((field) => field.lpCustomFieldId || field.lpSystemField)
    .toString();
  const filteredJiraProjectMappingFieldData = jiraProjectMappingFieldData.filter((item) => !jiraIds.includes(item.id));
  const filteredLpFields = allLpFields.filter((item) => !lpIds.includes(item.id));

  const handleAddFieldClick: HandleAddFieldClick = ({
    event,
    fieldType,
  }: {
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    fieldType: FieldMappingRowType;
  }) => {
    event.preventDefault();
    const rows =
      fieldType === FieldMappingRowType.JiraToLp ? addedJiraFieldMappingRowItems : addedLpFieldMappingRowItems;
    const data = fieldType === FieldMappingRowType.JiraToLp ? filteredJiraProjectMappingFieldData : filteredLpFields;
    const setRows =
      fieldType === FieldMappingRowType.JiraToLp ? setAddedJiraFieldMappingRowItems : setAddedLpFieldMappingRowItems;
    addFieldMappingRow({ rows, data, setRows });
  };

  const handleDeleteRowClick: HandleDeleteRowClick = ({
    fieldType,
    nameMapping,
  }: {
    fieldType: FieldMappingRowType;
    nameMapping: FieldMappingValue;
  }) => {
    const rows =
      fieldType === FieldMappingRowType.JiraToLp ? addedJiraFieldMappingRowItems : addedLpFieldMappingRowItems;
    const setRows =
      fieldType === FieldMappingRowType.JiraToLp ? setAddedJiraFieldMappingRowItems : setAddedLpFieldMappingRowItems;
    deleteFieldMappingRow({ rows, setRows, row: nameMapping });
  };

  const shouldDisableAddFieldButton: ShouldDisableAddFieldButton = (fieldType: FieldMappingRowType) => {
    const data = fieldType === FieldMappingRowType.JiraToLp ? filteredJiraProjectMappingFieldData : filteredLpFields;
    const rows =
      fieldType === FieldMappingRowType.JiraToLp ? addedJiraFieldMappingRowItems : addedLpFieldMappingRowItems;
    return shouldDisableAddRowButton({ data, rows });
  };

  useEffect(() => {
    if (isLoadingContent && !isLoadingMappingScreenData) {
      setIsTransitioning(true);
    }
    if (!isLoadingContent && isLoadingMappingScreenData) {
      setIsTransitioning(false);
    }
  }, [isLoadingContent, isLoadingMappingScreenData]);

  const isLoading = isLoadingContent || isLoadingMappingScreenData || isTransitioning;
  const shouldShowLegend = hasJiraIssueCreationFeature && hasManualPushSetting && !isLoading;

  return (
    <>
      <JiraProjectModalInfo
        title="Field Mapping"
        helperText={
          <>
            <span>
              Map system fields to custom data. If additional custom fields are needed, they can be created and mapped
              post setup.
              <br />
              Click{' '}
              <LpLink target="_blank" rel="noopener noreferrer" to={jiraAcademyPermalink}>
                here
              </LpLink>{' '}
              to explore the comprehensive glossary for <ProductName /> Fields.
            </span>
          </>
        }
      />
      {shouldShowLegend && <Legend />}
      <div className="jira-project-modal__loader-container">
        {isLoading ? (
          <LpOverlayLoader size="massive" className="jira-project-modal__loader" active />
        ) : (
          <div className="jira-project-modal__content-bordered-container">
            <div>
              <FieldMappingTable
                shouldDisableAddFieldButton={shouldDisableAddFieldButton}
                handleAddFieldClick={handleAddFieldClick}
                handleDeleteRowClick={handleDeleteRowClick}
                rowType={FieldMappingRowType.JiraToLp}
                allLpFields={allLpFields}
              />
            </div>
            <div>
              <FieldMappingTable
                shouldDisableAddFieldButton={shouldDisableAddFieldButton}
                handleAddFieldClick={handleAddFieldClick}
                handleDeleteRowClick={handleDeleteRowClick}
                rowType={FieldMappingRowType.LpToJira}
                allLpFields={allLpFields}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DateAndFieldMappingSection;
