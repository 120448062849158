import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { WorkingTeamColumn } from 'containers/shared/custom_column/types';
import { ItemWorkGroups } from 'daos/model_types';
import { RootState } from 'state/root_reducer';

import { getGroupForId } from './group';
import { getAncestryForItemId } from './item';
import { createCacheByIdConfig } from './shared';

const getItemWorkGroupsById = (state: RootState) => state.entities.itemWorkGroups;

export const getAllItemWorkGroups = createSelector(getItemWorkGroupsById, (itemWorkGroupsById) => {
  return Object.values(itemWorkGroupsById);
});

export const getItemWorkGroupForItemId = createSelector(
  getItemWorkGroupsById,
  (_: RootState, itemId: number) => itemId,
  (itemWorkGroupsById, itemId) => {
    return Object.values(itemWorkGroupsById).find((itemWorkGroup) => itemWorkGroup.item?.id === itemId);
  },
);

export const getSelfOrAncestryItemWorkGroupForItemId = createSelector(
  getItemWorkGroupsById,
  (state: RootState, itemId: number) => getAncestryForItemId(state, itemId),
  (itemWorkGroupsById, ancestry) => {
    const ancestryIds = ancestry.map((item) => item.id).reverse();
    for (const ancestryId of ancestryIds) {
      const value = Object.values(itemWorkGroupsById).find((itemWorkGroup) => itemWorkGroup.item?.id === ancestryId);
      if (value) {
        return value;
      }
    }
  },
);

export const getWorkingTeamForItemId = createSelector(
  (state: RootState) => state,
  (_: RootState, itemId: number) => itemId,
  getSelfOrAncestryItemWorkGroupForItemId,
  (state, itemId, itemWorkGroup) => {
    if (!itemWorkGroup) {
      return undefined;
    }
    const team = getGroupForId(state, itemWorkGroup.group.id);
    return {
      id: team?.id,
      name: team?.name,
      inherited: itemWorkGroup.item.id !== itemId,
    } as WorkingTeamColumn;
  },
);

export const getWorkingTeamsForItemIdsIndexedByItemId = createCachedSelector(
  (state: RootState) => state,
  (_: RootState, itemIds: Array<number>) => itemIds,
  (state, itemIds) => {
    return itemIds.reduce((acc: { [id: number]: WorkingTeamColumn | undefined }, itemId) => {
      acc[itemId] = getWorkingTeamForItemId(state, itemId);
      return acc;
    }, {});
  },
)(createCacheByIdConfig());

export const getItemWorkGroupsForGroupId = createSelector(
  getItemWorkGroupsById,
  (_: RootState, groupId: number) => groupId,
  (itemWorkGroupsById, groupId) => {
    return Object.values(itemWorkGroupsById).filter((itemWorkGroup) => itemWorkGroup.group?.id === groupId);
  },
);

export const getItemWorkGroupsByGroupIdForGroupIds = createSelector(
  getItemWorkGroupsById,
  (_: RootState, groupIds: Array<number>) => groupIds,
  (itemWorkGroupsById, groupIds) => {
    return Object.values(itemWorkGroupsById)
      .filter((itemWorkGroup) => groupIds.includes(itemWorkGroup.group?.id))
      .reduce((map, itemWorkGroup) => {
        map.set(itemWorkGroup.group.id, itemWorkGroup);
        return map;
      }, new Map<number, ItemWorkGroups>());
  },
);
