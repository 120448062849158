import { createRef, Dispatch, MouseEvent, MutableRefObject, SetStateAction } from 'react';

import { ClickLocation } from 'hooks/use_click_location_for_context_menu';

interface UseEllipsisActionClickLocationReturn {
  handleHideContextMenu: () => void;
  handleShowContextMenu: (e: MouseEvent) => void;
  triggerRef: MutableRefObject<HTMLSpanElement | null>;
}

export const useEllipsisActionClickLocationContext = (
  clickLocation: ClickLocation | undefined,
  setClickLocation: Dispatch<SetStateAction<ClickLocation | undefined>>,
  action?: () => void,
): UseEllipsisActionClickLocationReturn => {
  const triggerRef = clickLocation?.ref ?? createRef<HTMLSpanElement>();
  const defaultAction = () => {
    if (triggerRef.current) {
      triggerRef.current.click();
    }
  };

  const handleShowContextMenu = (e: MouseEvent) => {
    e.preventDefault();

    setClickLocation({ ref: triggerRef, top: e.clientY, left: e.clientX });

    action ? action() : defaultAction();
  };

  const handleHideContextMenu = () => setClickLocation(undefined);

  return {
    handleShowContextMenu,
    handleHideContextMenu,
    triggerRef,
  };
};
