import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getFormattedDateOrEmptyString } from 'containers/shared/custom_column/display_helpers';
import { Columns, StandardColumns } from 'containers/shared/custom_column/enum';
import { useItemDataForStatus } from 'hooks/use_item_data_for_status';
import { DisplayValueWithOptionalWarningIcon } from 'lib/display_helpers/warning_icon_with_value';
import { getItemForId } from 'state/entities/selectors/item';
import { getItemMetricsForId } from 'state/entities/selectors/item_metric';

import { useLocalizedFormats } from './use_locale_from_user';

const getFormattedFinishRange = ({
  doneDate,
  expectedFinish,
  latestFinish,
  formatDate,
}: {
  doneDate: string | null | undefined;
  expectedFinish: string | null | undefined;
  latestFinish: string | null | undefined;
  formatDate: (date: string) => string;
}) => {
  const formattedExpectedFinish = getFormattedDateOrEmptyString(expectedFinish, formatDate);
  const formattedLatestFinish = getFormattedDateOrEmptyString(latestFinish, formatDate);

  if (!doneDate && formattedExpectedFinish && formattedLatestFinish) {
    return `${formattedExpectedFinish} - ${formattedLatestFinish}`;
  }

  return '-';
};

export const useScheduleDateDisplay = (itemId: number, dateOption: Columns) => {
  const item = useSelector((state) => getItemForId(state, itemId));
  const itemMetric = useSelector((state) => getItemMetricsForId(state, itemId));
  const { isLate, isLateRisk } = useItemDataForStatus(itemId);

  const { formatLocalDate } = useLocalizedFormats();

  const doneDate = item?.doneDate;
  const expectedFinish = item?.expectedFinish;
  const expectedStart = item?.expectedStart;
  const latestFinish = item?.latestFinish;
  const targetFinish = item?.targetFinish ?? itemMetric?.effectiveTargetFinish;
  const targetStart = item?.targetStart ?? itemMetric?.effectiveTargetStart;

  return useMemo(() => {
    function scheduleLateAlert(date: string) {
      return <DisplayValueWithOptionalWarningIcon value={date} showWarningIcon={isLate || isLateRisk} />;
    }

    switch (dateOption) {
      case StandardColumns.TargetStart:
        return getFormattedDateOrEmptyString(targetStart, formatLocalDate);
      case StandardColumns.TargetFinish:
        return targetFinish ? scheduleLateAlert(formatLocalDate(targetFinish)) : '';
      case StandardColumns.ExpectedFinish:
        return expectedFinish ? scheduleLateAlert(formatLocalDate(expectedFinish)) : '';
      case StandardColumns.LatestFinish:
        return latestFinish ? scheduleLateAlert(formatLocalDate(latestFinish)) : '';
      case StandardColumns.FinishRange:
        return !doneDate && expectedFinish && latestFinish
          ? scheduleLateAlert(
              getFormattedFinishRange({ doneDate, expectedFinish, latestFinish, formatDate: formatLocalDate }),
            )
          : '-';
      case StandardColumns.ExpectedStart:
        return getFormattedDateOrEmptyString(expectedStart, formatLocalDate);
      case StandardColumns.DoneDate:
        return getFormattedDateOrEmptyString(doneDate, formatLocalDate);
      default:
        return '';
    }
  }, [
    dateOption,
    isLate,
    isLateRisk,
    targetStart,
    formatLocalDate,
    targetFinish,
    expectedFinish,
    latestFinish,
    doneDate,
    expectedStart,
  ]);
};
