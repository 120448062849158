import classNames from 'classnames';
import { noop } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import { DragUpdate, DropResult } from 'react-beautiful-dnd';
import { Table } from 'semantic-ui-react';

import { ScrollContext } from 'containers/shared/helpers/scroll_context';
import { LpDragAndDrop, LpDraggable, LpDroppable, LpRenderedClone } from 'features/common/drag_drop';
import { DragHandle } from 'features/common/drag_drop/drag_handle';
import { IconDefinition, LpIcon } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { SelectionList } from 'features/common/selection_list/types';
import reorderRows from 'lib/display_helpers/reorder_rows';
import { gray300, lpBrandWhite } from 'style/variables';

interface SelectionListBodyProps {
  iconColor: string;
  icon: IconDefinition;
  onItemClick: (id: string) => void;
  listOfOptions: Array<SelectionList>;
  draggable?: boolean;
  setSelectedOptions?: Dispatch<SetStateAction<Array<SelectionList>>>;
}

interface SelectionListRowContentProps {
  id: string;
  name: string;
  selectionRowIcon?: SelectionList['listIcon'];
  icon: IconDefinition;
  iconColor: string;
  onItemClick: (id: string) => void;
  optionalCustomClassname?: string;
  disabledPopupText?: string;
}

export enum SelectionLocation {
  AVAILABLE = 'available',
  SELECTED = 'selected',
}

export const SelectionListRowContent = ({
  id,
  icon,
  iconColor,
  name,
  selectionRowIcon,
  onItemClick,
  optionalCustomClassname,
  disabledPopupText,
}: SelectionListRowContentProps) => {
  const isRowDisabled = Boolean(disabledPopupText);
  const onClick = useCallback(() => (isRowDisabled ? undefined : onItemClick(id)), [id, isRowDisabled, onItemClick]);

  const computedClassNames = classNames('multi-select-list-modal__content-table-cell-name', optionalCustomClassname);

  const listRow = (
    <>
      {!!selectionRowIcon && selectionRowIcon}
      <div className={computedClassNames} data-testid={'multi-select-list-modal__column'}>
        {name}
      </div>
      <LpIcon
        size="2x"
        data-testid={icon.iconName}
        className={classNames('multi-select-list-modal__content-table-cell-icon', {
          'multi-select-list-modal__content-table-cell-icon--disabled': isRowDisabled,
        })}
        icon={icon}
        onClick={onClick}
        color={iconColor}
      />
    </>
  );

  const listRowWithPopup = (
    <LpPopUp
      className="multi-select-list-modal__popup"
      placement="top"
      trigger={<div className="multi-select-list-modal__popup-trigger">{listRow}</div>}
      content={<div>{disabledPopupText}</div>}
    />
  );

  return disabledPopupText ? listRowWithPopup : listRow;
};

const selectionListTableCellClassName = ({ isDisabled }: { isDisabled: boolean }) => {
  return classNames('multi-select-list-modal__content-table-cell', {
    'multi-select-list-modal__content-table-cell--disabled': isDisabled,
  });
};

const SelectionListBody = ({
  listOfOptions,
  onItemClick = noop,
  icon,
  iconColor,
  draggable,
  setSelectedOptions = noop,
}: SelectionListBodyProps) => {
  const [dragActive, setDragActive] = useState(false);
  const [hasDestination, setHasDestination] = useState(false);
  const tableBody = useRef<HTMLDivElement>(null);

  const tableStyle = {
    backgroundColor: hasDestination ? gray300 : lpBrandWhite,
    transition: 'background-color 350ms',
  };

  const onDragStart = () => setDragActive(true);
  const onDragUpdate = ({ destination }: DragUpdate) => setHasDestination(!!destination);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;

      if (destination && destination.index !== source.index) {
        setSelectedOptions(
          reorderRows([...listOfOptions], {
            prevIndex: source.index,
            newIndex: destination.index,
          }),
        );
      }

      setHasDestination(false);
      setDragActive(false);
    },
    [listOfOptions, setSelectedOptions],
  );

  const table = (
    <Table data-testid="selection-list-table">
      <Table.Body>
        {listOfOptions.map((option) => (
          <Table.Row key={option.id}>
            <Table.Cell
              className={selectionListTableCellClassName({
                isDisabled: !!option.disabledPopupText,
              })}
            >
              <SelectionListRowContent
                id={option.id}
                icon={icon}
                iconColor={iconColor}
                name={option.name}
                selectionRowIcon={option.listIcon}
                disabledPopupText={option.disabledPopupText}
                onItemClick={onItemClick}
                optionalCustomClassname={option.optionalCustomClassname}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  const draggableTable = (
    <LpDragAndDrop onDragEnd={onDragEnd} onDragStart={onDragStart} onDragUpdate={onDragUpdate} table={true}>
      <ScrollContext.Provider value={{ scrollableElement: tableBody }}>
        <div ref={tableBody}>
          <Table
            className={classNames('multi-select-list-modal__content-table', {
              'drag-active': dragActive,
            })}
            style={tableStyle}
          >
            <LpDroppable
              droppableId="grid-column-select"
              renderClone={(provided, snapshot, rubric) => {
                const option = listOfOptions[rubric.source.index];

                if (!option) {
                  return (
                    <LpRenderedClone
                      key={rubric.draggableId}
                      draggableProvided={provided}
                      draggableStateSnapshot={snapshot}
                      draggableRubric={rubric}
                    >
                      <Table.Cell />
                    </LpRenderedClone>
                  );
                }

                return (
                  <LpRenderedClone
                    key={rubric.draggableId}
                    draggableProvided={provided}
                    draggableStateSnapshot={snapshot}
                    draggableRubric={rubric}
                  >
                    <Table.Cell
                      className={selectionListTableCellClassName({
                        isDisabled: !!option.disabledPopupText,
                      })}
                    >
                      <DragHandle disable={!!option.disabledPopupText} />
                      <SelectionListRowContent
                        id={option.id}
                        icon={icon}
                        iconColor={iconColor}
                        name={option.name}
                        selectionRowIcon={option.listIcon}
                        disabledPopupText={option.disabledPopupText}
                        onItemClick={onItemClick}
                        optionalCustomClassname={option.optionalCustomClassname}
                      />
                    </Table.Cell>
                  </LpRenderedClone>
                );
              }}
            >
              {listOfOptions.map((option, index) => (
                <LpDraggable key={option.id} draggableId={String(option.id)} index={index}>
                  <Table.Cell
                    className={selectionListTableCellClassName({
                      isDisabled: !!option.disabledPopupText,
                    })}
                  >
                    <DragHandle disable={!!option.disabledPopupText} />
                    <SelectionListRowContent
                      id={option.id}
                      icon={icon}
                      iconColor={iconColor}
                      name={option.name}
                      selectionRowIcon={option.listIcon}
                      disabledPopupText={option.disabledPopupText}
                      onItemClick={onItemClick}
                      optionalCustomClassname={option.optionalCustomClassname}
                    />
                  </Table.Cell>
                </LpDraggable>
              ))}
            </LpDroppable>
          </Table>
        </div>
      </ScrollContext.Provider>
    </LpDragAndDrop>
  );

  return draggable ? draggableTable : table;
};

export default SelectionListBody;
