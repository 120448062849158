import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { WorkspaceUser } from 'daos/model_types';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { useHasAccess } from 'hooks/use_has_access';
import { getWorkspaceUserById } from 'state/entities/selectors/user';

interface WorkspaceManagerRowProps {
  isAdmin: boolean;
  isCurrentWsUser: boolean;
  isManager: boolean;
  workspaceUserId: number;
  updateWsUser: (updateProps: Partial<WorkspaceUser>) => void;
}

const WorkspaceManagerCheckbox = ({
  isAdmin,
  isCurrentWsUser,
  isManager,
  workspaceUserId,
  updateWsUser,
}: WorkspaceManagerRowProps) => {
  const workspaceUser = useSelector((state) => getWorkspaceUserById(state, workspaceUserId));

  const hasAdminAccess = useHasAccess(Permission.ADMIN, WorkspaceUserDao.id(workspaceUserId));

  const handleWsUserManager = (_: React.SyntheticEvent, { checked }: CheckboxProps) =>
    updateWsUser({ manager: checked });

  if (!workspaceUser) {
    return null;
  }

  return (
    <Checkbox
      checked={isManager || isAdmin}
      className="user-profile__table-checkbox"
      disabled={!hasAdminAccess || isCurrentWsUser || isAdmin || !!workspaceUser.disconnectedAt}
      onChange={handleWsUserManager}
    />
  );
};

export default WorkspaceManagerCheckbox;
