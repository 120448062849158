import { useSelector } from 'react-redux';

import { getItemMetricsForId } from 'state/entities/selectors/item_metric';

export const useLoggedWorkForItemIds = (itemIds?: ReadonlyArray<number | undefined>) => {
  const itemMetrics = useSelector((state) => itemIds?.map((id) => getItemMetricsForId(state, id ?? 0)));

  const loggedWork = itemMetrics?.reduce((acc, itemMetric) => acc + (itemMetric?.loggedWork ?? 0), 0);

  return loggedWork ?? 0;
};
