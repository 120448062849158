import { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'semantic-ui-react';
import * as yup from 'yup';

import LpFormError from 'containers/shared/form_errors/lp_form_error';
import { UserDao } from 'daos/user';
import LpModal from 'features/common/modals/lp_modal';
import { awaitRequestFinish } from 'lib/api';

const MIN_PASSWORD_LENGTH = 8;

const schema = yup.object().shape({
  previousPassword: yup.string().required(),
  password: yup.string().required().min(MIN_PASSWORD_LENGTH),
  passwordConfirmation: yup
    .string()
    .test('passwords-do-not-match', 'passwords do not match.', function (passwordConfirmation) {
      return this.parent.password === passwordConfirmation;
    }),
});

const ChangePasswordModal = ({ onClose, userId }: { onClose: () => void; userId: number }) => {
  const dispatch = useDispatch();
  const [previousPassword, setPreviousPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [apiError, setApiError] = useState<undefined | string>(undefined);
  const [previousPasswordError, setPreviousPasswordError] = useState<undefined | string>(undefined);
  const [passwordError, setPasswordError] = useState<undefined | string>(undefined);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<undefined | string>(undefined);

  const handleChange =
    (stateSetter: React.Dispatch<React.SetStateAction<string>>) =>
    (_: SyntheticEvent, { value }: { value: string }) => {
      stateSetter(value);
    };

  const handleSave = (_: SyntheticEvent) => {
    setApiError(undefined);
    setPreviousPasswordError(undefined);
    setPasswordError(undefined);
    setPasswordConfirmationError(undefined);

    schema
      .validate({ previousPassword, password, passwordConfirmation }, { abortEarly: false })
      .then(() => {
        const { uuid } = dispatch(UserDao.update({ userId }, { id: userId, password, previousPassword }));

        dispatch(
          awaitRequestFinish(uuid, {
            onSuccess: () => onClose(),
            onError: ({ errors }) => {
              if (errors[0]) {
                setApiError(errors[0].detail ? `${errors[0].title}: ${errors[0].detail}` : errors[0].title);
              }
            },
          }),
        );
      })
      .catch((error: yup.ValidationError) => {
        error.inner.forEach((e) => {
          switch (e.path) {
            case 'previousPassword':
              setPreviousPasswordError(e.message);
              break;
            case 'password':
              setPasswordError(e.message);
              break;
            case 'passwordConfirmation':
              setPasswordConfirmationError(e.message);
              break;
            default:
              setApiError(e.message);
          }
        });
      });
  };

  return (
    <LpModal
      className="change-password-modal"
      size="tiny"
      header={'Change Password'}
      content={
        <>
          {!!apiError && <LpFormError error={apiError} />}
          <div className="change-password-modal__input-section">
            <label htmlFor="user-previous-password">Previous Password</label>
            <Input
              id="user-previous-password"
              autoFocus
              icon="lock"
              type="password"
              value={previousPassword}
              onChange={handleChange(setPreviousPassword)}
            />
            {!!previousPasswordError && <LpFormError error={previousPasswordError} />}
          </div>

          <div className="change-password-modal__input-section">
            <label htmlFor="user-new-password">New Password</label>
            <Input
              id="user-new-password"
              type="password"
              icon="lock"
              value={password}
              onChange={handleChange(setPassword)}
            />
            {!!passwordError && <LpFormError error={passwordError} />}
          </div>

          <div className="change-password-modal__input-section">
            <label htmlFor="user-confirm-password">Confirm New Password</label>
            <Input
              id="user-confirm-password"
              type="password"
              icon="lock"
              value={passwordConfirmation}
              onChange={handleChange(setPasswordConfirmation)}
            />
            {!!passwordConfirmationError && <LpFormError error={passwordConfirmationError} />}
          </div>
        </>
      }
      actions={
        <>
          <Button content={'Cancel'} onClick={onClose} />
          <Button primary content={'Save'} onClick={handleSave} />
        </>
      }
      onClose={onClose}
    />
  );
};

export default ChangePasswordModal;
