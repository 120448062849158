import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { LpButtonAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import LpModal from 'features/common/modals/lp_modal';
import { frontend } from 'lib/urls';
import { getCurrentOrganizationUser } from 'state/entities/selectors/user';

import { PlanGadget } from './plan_gadget';

import './beyond_limit_modal.scss';

interface BeyondLimitModalProps {
  onClose: () => void;
}

const BeyondLimitModal = ({ onClose }: BeyondLimitModalProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const isAdmin = useSelector(getCurrentOrganizationUser)?.admin;

  return (
    <LpModal
      className="beyond-limit-modal"
      onClose={onClose}
      size="tiny"
      header={'Beyond an account limit'}
      content={
        <>
          <p>Consider adding licenses, upgrading or talking to a product advisor.</p>
          <PlanGadget includeWorkspace={true} />
        </>
      }
      actions={
        <>
          {isAdmin && (
            <LpButtonAsLink
              className="administration"
              onClick={onClose}
              to={frontend.workspaceHub.url({ organizationId, workspaceId })}
              color="orange"
            >
              Administration
            </LpButtonAsLink>
          )}
          <Button content="Close" onClick={onClose} primary />
        </>
      }
    />
  );
};

export default BeyondLimitModal;
