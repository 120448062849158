import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { EmailConfirmationDao } from 'daos/email_confirmation';
import { UserDao } from 'daos/user';
import { getCurrentUser } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';
import { ProductName } from 'lib/use_product_name';

import 'features/authentication/unauthenticated/token/invitation/unconfirmed_email_banner/index.scss';

const UnconfirmedEmailBanner = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [sendConfirmEmail, setSendConfirmEmail] = useState(true);

  const confirmationButtonAction = useCallback(() => {
    const fetchAction = sendConfirmEmail ? EmailConfirmationDao.reCreateConfirmation() : UserDao.fetchCurrent();
    const { uuid } = dispatch(fetchAction);

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => {
          setSendConfirmEmail((preState) => !preState);
        },
        onError: () => {
          setSendConfirmEmail(false);
        },
      }),
    );
  }, [dispatch, sendConfirmEmail]);

  const buttonText = useMemo(() => {
    return sendConfirmEmail ? 'Send Again' : 'Check your status';
  }, [sendConfirmEmail]);

  return (
    <div className="unconfirmed-email">
      {sendConfirmEmail ? (
        <div>
          <div>Before you can invite others, we need to confirm your account.</div>
          Look for an email from{' '}
          <span className="unconfirmed-email__liquidplanner">
            <ProductName />
          </span>{' '}
          sent to{' '}
          <span className="unconfirmed-email__to-email">{currentUser?.unconfirmedEmail ?? currentUser?.email}</span>
        </div>
      ) : (
        <i className="unconfirmed-email__status">
          Please check your Email and confirm your account before checking your status
        </i>
      )}

      <Button className="unconfirmed-email__button" onClick={confirmationButtonAction}>
        {buttonText}
      </Button>
    </div>
  );
};

export default UnconfirmedEmailBanner;
