import { toNumber } from 'lodash/fp';

import { StoryPoints } from 'daos/model_types';
import { timeStringInSecondsFromTimeStringWithUnits } from 'features/item_panel/sections/planning_section/work_limit/helpers';
import { timeStringInHoursFromTimeStringWithUnits } from 'lib/display_helpers/estimate_and_tracking_helpers';

import { EditableStoryPoints } from './types';

export const formatStoryPointsForSave = (storyPoints: ReadonlyArray<EditableStoryPoints>) =>
  storyPoints.map(({ id, value, highEffort, lowEffort }) => {
    return {
      id,
      value: toNumber(value),
      highEffort: highEffort ? storyPointEstimateFormatted(highEffort) : undefined,
      lowEffort: lowEffort ? storyPointEstimateFormatted(lowEffort) : undefined,
    };
  });

export const formatEditedStoryPointsForSave = (
  formStoryPoints: ReadonlyArray<EditableStoryPoints>,
  initialStoryPoints: ReadonlyArray<StoryPoints>,
) => {
  const formattedStoryPoints: Array<Partial<StoryPoints>> = [];

  initialStoryPoints.forEach((initialPoint) => {
    const formStoryPoint = formStoryPoints.find((formPoint) => formPoint.id === initialPoint.id);

    if (!formStoryPoint) {
      formattedStoryPoints.push({ id: initialPoint.id, delete: true });
    }
  });

  formStoryPoints.forEach((formPoint) => {
    const { id, value, highEffort, lowEffort } = formPoint;
    formattedStoryPoints.push({
      id,
      value: toNumber(value),
      highEffort: highEffort ? storyPointEstimateFormatted(highEffort) : null,
      lowEffort: lowEffort ? storyPointEstimateFormatted(lowEffort) : null,
    });
  });

  return formattedStoryPoints;
};

const storyPointEstimateFormatted = (estimate: string) => {
  const hours = timeStringInHoursFromTimeStringWithUnits(estimate);
  return hours ? toNumber(timeStringInSecondsFromTimeStringWithUnits(hours)) : null;
};
