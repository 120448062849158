import { useSelector } from 'react-redux';

import EditableUserAvatar from 'features/common/avatars/editable_user_avatar';
import { LpIcon, passportSolid } from 'features/common/lp_icon';
import { ChangeEmailPasswordSection } from 'features/shared/user_profile';
import { ConvertGuestToPaidWorkspaceMember } from 'features/shared/user_profile/convert_guest_to_paid_member';
import ToggleUserWorkspaceMembership from 'features/shared/user_profile/toggle_user_workspace_membership';
import {
  getCurrentOrganizationUser,
  getCurrentWorkspaceUser,
  getOrganizationUserForId,
} from 'state/entities/selectors/user';
import { slate600 } from 'style/variables';

const OrganizationDirectoryUsersProfileInformationAvatar = ({
  fetchWorkspaceUser,
  orgUserId,
  isGuestProfile,
  profileType,
}: {
  fetchWorkspaceUser: (afterOnfinish: () => void) => void;
  orgUserId: number;
  isGuestProfile: boolean;
  profileType: 'workspaceUser' | 'organizationUser';
}) => {
  const organizationUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const currentOrganizationUser = useSelector(getCurrentOrganizationUser);
  const currentWorkspaceUser = useSelector(getCurrentWorkspaceUser);

  const isCurrentlyViewingSelf = currentOrganizationUser?.id === orgUserId;

  const isCurrentOrgUserAdmin = !!currentOrganizationUser?.admin;
  const isCurrentWorkspaceUserManager = !!currentWorkspaceUser?.manager;

  const isOrgAdminOrWsManager = isCurrentOrgUserAdmin || isCurrentWorkspaceUserManager;

  return (
    <div className="organization-directory__users-profile-information-avatar-section">
      {isGuestProfile ? (
        <>
          <LpIcon
            icon={passportSolid}
            color={slate600}
            className="organization-directory__users-profile-information-avatar-guest"
          />

          {isCurrentlyViewingSelf && (
            <div className="organization-directory__users-profile-information-avatar-section-change-email-password">
              <ChangeEmailPasswordSection userId={organizationUser?.user.id ?? 0} />
            </div>
          )}
          {isOrgAdminOrWsManager && profileType === 'workspaceUser' && (
            <div className="organization-directory__users-profile-information-avatar-guest-button">
              <span>Dashboard Guest</span>
              <ToggleUserWorkspaceMembership
                isGuestProfile={isGuestProfile}
                orgUserId={orgUserId}
                afterToggle={fetchWorkspaceUser}
              />

              <ConvertGuestToPaidWorkspaceMember orgUserId={orgUserId} />
            </div>
          )}
        </>
      ) : (
        <>
          <EditableUserAvatar
            className="avatar-edit-menu organization-directory__users-profile-information-avatar"
            orgUserId={orgUserId}
            edit={true}
          />
          <div className="avatar-edit-menu organization-directory__users-profile-information-avatar-its-me">
            {currentOrganizationUser?.id === orgUserId ? "It's me!" : organizationUser?.username}
          </div>
        </>
      )}
    </div>
  );
};

export default OrganizationDirectoryUsersProfileInformationAvatar;
