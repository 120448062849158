import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LibraryResourceOrderBy, PackageStatus } from 'daos/enums';
import { LpColorHex } from 'daos/item_enums';
import { LibraryResourceDao } from 'daos/library_resource';
import { LibraryResource, LibraryResourceType } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { TabQueryParam } from 'features/common/search';
import { DashboardType } from 'features/dashboards/dashboard_types';
import { awaitRequestFinish } from 'lib/api';
import { getLibraryViewLibraryResourceFilter } from 'lib/quick_filter_and_search_helpers/quick_filter';
import { frontend } from 'lib/urls';
import { getPackageIdForItemId } from 'state/entities/selectors/item';

import { LibraryLocation } from './types';

const collectionLibraryDashboardByPackageStatus = {
  [PackageStatus.SCHEDULED]: frontend.scheduledCollectionDashboard,
  [PackageStatus.BACKLOG]: frontend.pendingCollectionDashboard,
  [PackageStatus.ARCHIVED]: frontend.archiveCollectionDashboard,
  [PackageStatus.CAPACITY]: undefined,
  [PackageStatus.TEMPLATE]: undefined,
};

const collectionLibraryReportByPackageStatus = {
  [PackageStatus.SCHEDULED]: frontend.scheduledCollectionReport,
  [PackageStatus.BACKLOG]: frontend.pendingCollectionReport,
  [PackageStatus.ARCHIVED]: frontend.archiveCollectionReport,
  [PackageStatus.CAPACITY]: undefined,
  [PackageStatus.TEMPLATE]: undefined,
};

export const dashboardTypeByLibraryLocation = {
  [LibraryLocation.Workspace]: DashboardType.workspaces,
  [LibraryLocation.Collection]: DashboardType.collections,
  [LibraryLocation.Package]: DashboardType.packages,
  [LibraryLocation.Project]: DashboardType.projects,
};

export const libraryResourceClickThruRoute = ({
  libraryResource,
  libraryLocation,
}: {
  libraryResource: LibraryResource;
  libraryLocation: LibraryLocation;
}) => {
  const { itemId, packageStatus, dashboardId, widgetId, workspaceId, organizationId, libraryResourceType } =
    libraryResource;

  if (libraryResourceType === LibraryResourceType.Dashboard && dashboardId) {
    return getDashboardRoute({
      organizationId,
      workspaceId,
      itemId,
      dashboardId,
      libraryLocation,
      packageStatus,
    });
  }

  if (libraryResourceType === LibraryResourceType.TableReport && widgetId) {
    return getReportRoute({
      itemId,
      libraryLocation,
      organizationId,
      workspaceId,
      widgetId,
      packageStatus,
    });
  }

  return '';
};

export const getDashboardRoute = ({
  itemId,
  libraryLocation,
  organizationId,
  workspaceId,
  dashboardId,
  packageStatus,
}: {
  itemId: number | string;
  libraryLocation: LibraryLocation;
  organizationId: number | string;
  workspaceId: number | string;
  dashboardId: number | string;
  packageStatus: PackageStatus | null;
}) => {
  switch (libraryLocation) {
    case LibraryLocation.Workspace:
      return frontend.workspaceDashboard.url({ organizationId, workspaceId, dashboardId });
    case LibraryLocation.Collection: {
      const route = packageStatus ? collectionLibraryDashboardByPackageStatus[packageStatus] : undefined;
      return route ? route.url({ organizationId, workspaceId, dashboardId }) : '';
    }
    case LibraryLocation.Package:
      return frontend.packageLibraryDashboard.url({ organizationId, workspaceId, packageId: itemId, dashboardId });
    case LibraryLocation.Project:
      return frontend.projectLibraryDashboard.url({ organizationId, workspaceId, itemId: itemId, dashboardId });
    default:
      return frontend.workspaceLibrary.url({ organizationId, workspaceId });
  }
};

export const getReportRoute = ({
  itemId,
  libraryLocation,
  organizationId,
  workspaceId,
  widgetId,
  packageStatus,
}: {
  itemId: number | string;
  libraryLocation: LibraryLocation;
  organizationId: number | string;
  workspaceId: number | string;
  widgetId: number | string;
  packageStatus: PackageStatus | null;
}) => {
  switch (libraryLocation) {
    case LibraryLocation.Workspace:
      return frontend.workspaceReport.url({ organizationId, workspaceId, widgetId });
    case LibraryLocation.Collection: {
      const route = packageStatus ? collectionLibraryReportByPackageStatus[packageStatus] : undefined;
      return route ? route.url({ organizationId, workspaceId, widgetId }) : '';
    }
    case LibraryLocation.Package:
      return frontend.packageLibraryReport.url({ organizationId, workspaceId, packageId: itemId, widgetId });
    case LibraryLocation.Project:
      return frontend.projectLibraryReport.url({ organizationId, workspaceId, itemId, widgetId });
    default:
      return frontend.workspaceLibrary.url({ organizationId, workspaceId });
  }
};

export const getLibraryUrlForDashboardType = ({
  itemId,
  libraryLocation,
  organizationId,
  workspaceId,
}: {
  itemId: number;
  libraryLocation: LibraryLocation;
  organizationId: number | string;
  workspaceId: number | string;
}) => {
  switch (libraryLocation) {
    case LibraryLocation.Workspace:
      return '';
    case LibraryLocation.Collection:
      return frontend.packageLibrary.url({ organizationId, workspaceId, packageId: itemId });
    case LibraryLocation.Package:
      return frontend.projectLibrary.url({ organizationId, workspaceId, itemId: itemId });
    case LibraryLocation.Project:
      return frontend.projectLibrary.url({ organizationId, workspaceId, itemId: itemId });
    default:
      return frontend.projectsAll.url({ organizationId, workspaceId });
  }
};

const collectionSearchRoutes = ({
  organizationId,
  workspaceId,
  packageStatus,
}: {
  organizationId: number | string;
  workspaceId: number | string;
  packageStatus: PackageStatus;
}) => {
  switch (packageStatus) {
    case PackageStatus.SCHEDULED:
      return frontend.scheduledCollectionSearch.url({ organizationId, workspaceId });
    case PackageStatus.BACKLOG:
      return frontend.pendingCollectionSearch.url({ organizationId, workspaceId });
    case PackageStatus.ARCHIVED:
      return frontend.archiveCollectionSearch.url({ organizationId, workspaceId });
    case PackageStatus.TEMPLATE:
      return frontend.templateCollectionSearch.url({ organizationId, workspaceId });
    default:
      return frontend.scheduledCollectionSearch.url({ organizationId, workspaceId });
  }
};

export const searchRoutes = ({
  itemId,
  libraryLocation,
  organizationId,
  workspaceId,
  packageStatus = PackageStatus.SCHEDULED,
}: {
  itemId: number;
  libraryLocation: LibraryLocation;
  organizationId: number | string;
  workspaceId: number | string;
  packageStatus?: PackageStatus;
}) => {
  switch (libraryLocation) {
    case LibraryLocation.Workspace:
      return `${frontend.projectsSearch.url({ organizationId, workspaceId })}?searchType=${
        TabQueryParam.DashboardsAndReports
      }`;
    case LibraryLocation.Collection:
      return `${collectionSearchRoutes({
        organizationId,
        workspaceId,
        packageStatus,
      })}?searchType=${TabQueryParam.DashboardsAndReports}`;
    case LibraryLocation.Package:
      return `${frontend.packageSearch.url({
        organizationId,
        workspaceId,
        packageId: itemId,
      })}?searchType=${TabQueryParam.DashboardsAndReports}`;
    case LibraryLocation.Project:
      return `${frontend.projectSearch.url({ organizationId, workspaceId, itemId })}?searchType=${
        TabQueryParam.DashboardsAndReports
      }`;
    default:
      return frontend.workspaceLibrary.url({ organizationId, workspaceId });
  }
};

export const dateDisplayText = (dayDifference: number, defaultDateDisplayText: string) => {
  if (dayDifference === 0) {
    return 'Today';
  }
  if (dayDifference === 1) {
    return 'Yesterday';
  }
  return defaultDateDisplayText;
};

export const getLibraryResourceColor = (libraryLocation: LibraryLocation, dashboardColor?: string) => {
  if (dashboardColor) {
    return dashboardColor;
  }

  switch (libraryLocation) {
    case LibraryLocation.Workspace:
      return LpColorHex.TIGER_ORANGE;
    case LibraryLocation.Package:
      return LpColorHex.SMART_SLATE;
    case LibraryLocation.Project:
      return LpColorHex.RELIGHT_BLUE;

    default:
      return LpColorHex.RELIGHT_BLUE;
  }
};

export const useFetchLibraryResources = ({
  itemId,
  libraryLocation,
  packageStatus,
}: {
  itemId: number;
  libraryLocation: LibraryLocation;
  packageStatus?: PackageStatus | null;
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const [libraryResources, setLibraryResources] = useState<ReadonlyArray<LibraryResource>>([]);

  const fetchFilteredLibraryResources = useCallback(
    ({ inputValue }: { inputValue: string }) => {
      const filter = getLibraryViewLibraryResourceFilter({ itemId, inputValue, libraryLocation, packageStatus });

      const { uuid } = dispatch(
        LibraryResourceDao.fetchAll(
          { organizationId, workspaceId },
          {
            filter,
            query: { order: LibraryResourceOrderBy.Name },
          },
        ),
      );

      dispatch(
        awaitRequestFinish<ReadonlyArray<LibraryResource>>(uuid, {
          onSuccess: ({ data }) => {
            if (data) {
              setLibraryResources(data);
            }
          },
        }),
      );
    },
    [itemId, libraryLocation, packageStatus, dispatch, organizationId, workspaceId],
  );

  return { fetchFilteredLibraryResources, libraryResources };
};

export const collectionLibraryByPackageStatus = {
  [PackageStatus.SCHEDULED]: frontend.scheduledCollectionLibrary,
  [PackageStatus.BACKLOG]: frontend.pendingCollectionLibrary,
  [PackageStatus.ARCHIVED]: frontend.archiveCollectionLibrary,
  [PackageStatus.CAPACITY]: undefined,
  [PackageStatus.TEMPLATE]: undefined,
};

export const useNavigateUpToLink = (
  organizationId: string | number,
  workspaceId: string | number,
  itemId: number,
  libraryLocation: LibraryLocation,
  packageStatus?: PackageStatus,
) => {
  const packageId = useSelector((state) => getPackageIdForItemId(state, itemId));

  switch (libraryLocation) {
    case LibraryLocation.Collection:
      return frontend.workspaceLibrary.url({
        organizationId,
        workspaceId,
      });
    case LibraryLocation.Project:
      return frontend.packageLibrary.url({
        organizationId,
        workspaceId,
        packageId,
      });
    case LibraryLocation.Package: {
      const collectionLibrary = packageStatus && collectionLibraryByPackageStatus[packageStatus];
      if (collectionLibrary) {
        return collectionLibrary.url({
          organizationId,
          workspaceId,
        });
      }

      break;
    }
    default:
      return undefined;
  }
};

export const isLibraryResourceDashboard = (libraryResourceType: LibraryResourceType) => {
  return libraryResourceType === LibraryResourceType.Dashboard;
};
