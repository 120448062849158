import { useSelector } from 'react-redux';

import { IterationNoticeLabelRenderer } from 'containers/shared/notice_icons/iteration_label';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { getIterationForItemId } from 'state/entities/selectors/iterations';

export const IterationNotice = ({ itemId }: { itemId: number }) => {
  const hasIterationFeature = useHasFeature(FeatureFlag.iterationBucket);

  const iteration = useSelector((state) => (hasIterationFeature ? getIterationForItemId(state, itemId) : undefined));

  if (!iteration) {
    return null;
  }

  return <IterationNoticeLabelRenderer iteration={iteration} />;
};
