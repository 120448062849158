import { createSelector } from 'reselect';

import { OrganizationUserSlackIntegration } from 'daos/model_types';
import { ReadonlyRecord } from 'lib/readonly_record';
import { RootState } from 'state/root_reducer';

import { getNumberArgument } from './shared';

const getOrganizationUserSlackIntegrationsById = (state: RootState) => state.entities.organizationUserSlackIntegrations;

export const getOrganizationUserSlackIntegrationsByOrgUserId = createSelector(
  getOrganizationUserSlackIntegrationsById,
  (slackIntegrationsById) =>
    Object.values(slackIntegrationsById).reduce(
      (acc: ReadonlyRecord<number, OrganizationUserSlackIntegration>, current) => ({
        ...acc,
        [current.orgUserId]: current,
      }),
      {},
    ),
);

const getOrganizationUserSlackIntegrationsByOrgId = createSelector(
  getOrganizationUserSlackIntegrationsById,
  (slackIntegrationsById) =>
    Object.values(slackIntegrationsById).reduce(
      (acc: ReadonlyRecord<number, Array<OrganizationUserSlackIntegration>>, current) => ({
        ...acc,
        [current.orgId]: (acc[current.orgId] ?? []).concat(current),
      }),
      {},
    ),
);

export const getOrganizationUserSlackIntegrationsForOrgUserId = createSelector(
  getOrganizationUserSlackIntegrationsByOrgUserId,
  getNumberArgument,
  (slackIntegrationsByOrgUserId, orgUserId) => slackIntegrationsByOrgUserId[orgUserId],
);

export const getOrganizationUserSlackIntegrationsForOrgId = createSelector(
  getOrganizationUserSlackIntegrationsByOrgId,
  getNumberArgument,
  (slackIntegrationsByOrgId, orgId) => slackIntegrationsByOrgId[orgId] ?? [],
);
