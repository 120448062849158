import { createCachedSelector } from 're-reselect';

import { getIsNonNoteCustomFieldApplicableForItemType } from 'containers/shared/custom_column/helpers';
import { ItemType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { ColumnsSelection } from 'daos/types';
import { readonlyArray } from 'lib/readonly_record';
import { getAllWorkspaceNonNoteCustomFields } from 'state/entities/selectors/custom_field';
import { createCacheByIdConfig } from 'state/entities/selectors/shared';
import { getListWidgetsColumns, getPppsColumns } from 'state/entities/selectors/system_settings';
import { RootState } from 'state/root_reducer';

/** get all columns (static and custom) from system settings  */
const getItemColumns = (state: RootState, itemType: ItemType, forPPPGrid: boolean) => {
  return forPPPGrid
    ? getPppColumnsForItemType(state, itemType as keyof ColumnsSelection)
    : getListWidgetsColumnsForItemType(state, itemType as keyof ColumnsSelection);
};

export const getPackageItemColumns = (state: RootState, forPPPGrid = false) =>
  getItemColumns(state, ItemType.PACKAGES, forPPPGrid);

export const getProjectItemColumns = (state: RootState, forPPPGrid = false) =>
  getItemColumns(state, ItemType.PROJECTS, forPPPGrid);

export const getSubfolderItemColumns = (state: RootState, forPPPGrid = false) =>
  getItemColumns(state, ItemType.FOLDERS, forPPPGrid);

export const getTaskItemColumns = (state: RootState, forPPPGrid = false) =>
  getItemColumns(state, ItemType.TASKS, forPPPGrid);

export const getAssignmentItemColumns = (state: RootState, forPPPGrid = false) =>
  getItemColumns(state, ItemType.ASSIGNMENTS, forPPPGrid);

/** columns and ALL custom fields */
export const getPackageItemAndAllFieldColumns = (state: RootState) =>
  getListWidgetsColumnsAndAllFields(state, ItemType.PACKAGES);
export const getProjectItemAndAllFieldColumns = (state: RootState) =>
  getListWidgetsColumnsAndAllFields(state, ItemType.PROJECTS);
export const getSubfolderItemAndAllFieldColumns = (state: RootState) =>
  getListWidgetsColumnsAndAllFields(state, ItemType.FOLDERS);
export const getTaskItemAndAllFieldColumns = (state: RootState) =>
  getListWidgetsColumnsAndAllFields(state, ItemType.TASKS);

export const getListWidgetsColumnsForItemType = createCachedSelector(
  (state: RootState, key: keyof ColumnsSelection) => getListWidgetsColumns(state, key),
  (columns) => columns.map((column) => column.toString()),
)(createCacheByIdConfig());

const getPppColumnsForItemType = createCachedSelector(
  (state: RootState, key: keyof ColumnsSelection) => getPppsColumns(state, key),
  (columns) => columns.map((column) => column.toString()),
)(createCacheByIdConfig());

const getListWidgetsColumnsAndAllFields = createCachedSelector(
  (state: RootState, key: keyof ColumnsSelection) => getListWidgetsColumns(state, key),
  (state: RootState, key: keyof ColumnsSelection) => getAllSupportedCustomFields(state, key),
  (columns, fields) => {
    const namesOfColumns = columns.map((column) => column.toString()) ?? [];
    const fieldIdStrings = fields.map((field) => field.id.toString());
    return readonlyArray(namesOfColumns.concat(fieldIdStrings));
  },
)(createCacheByIdConfig());

const getAllSupportedCustomFields = createCachedSelector(
  getAllWorkspaceNonNoteCustomFields,
  (_: RootState, itemType: ItemType) => itemType,
  getItemTypeSupportedFields,
)(createCacheByIdConfig());

function getItemTypeSupportedFields(fields: ReadonlyArray<CustomField>, itemType: ItemType) {
  return readonlyArray(fields.filter((field) => getIsNonNoteCustomFieldApplicableForItemType(field, itemType)));
}
