import classNames from 'classnames';
import { forwardRef, memo, ReactElement } from 'react';
import { areEqual } from 'react-window';

import { WorkloadGroup, WorkloadItem } from 'daos/model_types';
import { useWorkloadTableContext } from 'features/common/workload/workload_table/context';
import {
  getNameColumnWidth,
  getRenderedRowRange,
  getRenderedStickyColumnRowData,
  getTotalNonNameColumnWidths,
  ROW_HEIGHT_PIXELS,
} from 'features/common/workload/workload_table/helpers';
import { OrgUserNameCell } from 'features/common/workload/workload_table/org_user_cells';
import { NameCell } from 'features/common/workload/workload_table/task_cells';
import { WorkloadHeader } from 'features/common/workload/workload_table/workload_header';

interface StickyWorkloadTableElementsProps {
  children: Array<ReactElement>;
}
export const StickyWorkloadTableElements = memo(
  forwardRef<HTMLDivElement, StickyWorkloadTableElementsProps>(({ children }, ref) => {
    const { workload, workloadRows, dayRange, boundingBox, hasGeneralTeamBasedPlanning } = useWorkloadTableContext();
    const { minRow, maxRow } = getRenderedRowRange(children);

    const totalNonNameColumnsWidth = getTotalNonNameColumnWidths(dayRange, hasGeneralTeamBasedPlanning);

    const nameCellWidth = getNameColumnWidth({
      boxWidth: boundingBox?.width ?? 0,
      totalOtherColumnWidths: totalNonNameColumnsWidth,
    });

    if (!workload) {
      return null;
    }

    const height = workloadRows.length * ROW_HEIGHT_PIXELS;

    const width = nameCellWidth + totalNonNameColumnsWidth;

    return (
      <div
        style={{
          height,
          width,
        }}
        ref={ref}
        className="workload-view__sticky-elements-container"
      >
        <WorkloadHeader />
        <StickyColumn minRow={minRow} maxRow={maxRow} cellWidth={nameCellWidth} />
        <div
          className={classNames(
            'workload-view__sticky-elements-container-workload-grid',
            hasGeneralTeamBasedPlanning && 'workload-view__sticky-elements-container-workload-grid--team-planning',
          )}
        >
          {children}
        </div>
      </div>
    );
  }),
  areEqual,
);

function getOrgUserId(group?: WorkloadGroup, item?: WorkloadItem) {
  return group?.organizationUser?.id || item?.organizationUser?.id;
}

interface StickyColumnProps {
  minRow: number;
  maxRow: number;
  cellWidth: number;
}
const StickyColumn = memo(({ minRow, maxRow, cellWidth }: StickyColumnProps) => {
  const { workloadRows } = useWorkloadTableContext();

  const dataSlice = getRenderedStickyColumnRowData({ maxRow, minRow, workloadRows });

  return (
    <div
      style={{
        width: cellWidth,
      }}
      className="workload-view__sticky-elements-container-sticky-column"
    >
      {dataSlice.map(({ group, item, rowIndex }) => (
        <StickyCell
          key={`${getOrgUserId(group, item)}-${group?.id}-${item?.id}-${item?.schedulingLabels.scheduleEntryType}`}
          rowIndex={rowIndex}
          group={group}
          orgUserId={getOrgUserId(group, item)}
          item={item}
          cellWidth={cellWidth}
        />
      ))}
    </div>
  );
}, areEqual);

interface StickyCellProps {
  group?: WorkloadGroup;
  item?: WorkloadItem;
  orgUserId?: number;
  rowIndex: number;
  cellWidth: number;
}
const StickyCell = memo<StickyCellProps>(({ item, orgUserId, rowIndex, cellWidth }) => {
  const { workloadRows, setMoveItemId, fetchWorkload, setDeletionData, handleToggleByOrgUserId } =
    useWorkloadTableContext();

  const style = {
    height: ROW_HEIGHT_PIXELS,
    top: ROW_HEIGHT_PIXELS * rowIndex,
    left: 0,
    width: cellWidth,
  };

  if (item) {
    return (
      <NameCell
        style={style}
        fetchWorkload={fetchWorkload}
        workloadItem={item}
        setMoveItemId={setMoveItemId}
        setDeletionData={setDeletionData}
      />
    );
  }

  const isExpand = !!workloadRows[rowIndex + 1]?.item;

  return (
    <OrgUserNameCell
      orgUserId={orgUserId}
      isExpand={isExpand}
      style={style}
      handleToggleByOrgUserId={handleToggleByOrgUserId}
    />
  );
}, areEqual);
