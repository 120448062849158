import { CSSProperties, memo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { areEqual } from 'react-window';

import LpLink from 'containers/shared/lp_link';
import { ScheduleEntryType } from 'daos/enums';
import { WorkloadGroup } from 'daos/model_types';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import ToggleButton from 'features/common/inputs/toggle_button';
import { useWorkloadTableContext } from 'features/common/workload/workload_table/context';
import WorkloadNub from 'features/common/workload/workload_table/workload_nub';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours, roundedPercentage } from 'lib/helpers';
import { frontend } from 'lib/urls';
import { getWorkloadById, getWorkloadGroupsIndexedByGroupIdForWorkloadId } from 'state/entities/selectors/workload';

interface OrgUserNameCellProps {
  orgUserId?: number;
  style: CSSProperties;
  isExpand: boolean;
  handleToggleByOrgUserId: (orgUserId: number) => void;
}

export const OrgUserNameCell = ({ orgUserId, style, isExpand, handleToggleByOrgUserId }: OrgUserNameCellProps) => {
  const { workloadId } = useWorkloadTableContext();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const guestRoute = !!useRouteMatch(frontend.dashboardGuestWidget.pattern)?.isExact;

  const workloadGroupsByGroupId = useSelector((state) =>
    workloadId ? getWorkloadGroupsIndexedByGroupIdForWorkloadId(state, workloadId) : null,
  );

  const workloadGroup = orgUserId ? workloadGroupsByGroupId?.[orgUserId] : undefined;
  const username = workloadGroup?.username;
  const userId = workloadGroup?.user?.id;

  if (!orgUserId || !workloadGroup || !username || !userId) {
    return null;
  }

  const averageAvailabilitySeconds = workloadGroup?.averageAvailabilitySeconds;
  const averageAvailability = averageAvailabilitySeconds
    ? formatAsHours(convertSecondsToHours(averageAvailabilitySeconds))
    : '0h';

  return (
    <div key={orgUserId} style={style} className="workload-view__section-header-name">
      <ToggleButton
        className="workload-view__section-header-name-toggle"
        expanded={isExpand}
        onToggle={() => {
          handleToggleByOrgUserId(orgUserId);
        }}
      />
      <span
        className="workload-view__section-header-toggle-area"
        onClick={() => {
          handleToggleByOrgUserId(orgUserId);
        }}
      >
        <LpLink
          disabled={guestRoute}
          className="workload-view__section-header-toggle-area-link"
          to={frontend.memberWorkload.url({
            organizationId,
            userId,
            workspaceId,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <UserAvatar
            orgUserId={orgUserId}
            key={orgUserId + username}
            className="workload-view__org-user-icon"
            size={AvatarSize.Small_2_0}
          />{' '}
          <span>{username}</span>
        </LpLink>{' '}
        <span className="workload-view__average-availability-paren">( </span>
        <span className="workload-view__average-availability">
          <LpLink
            disabled={guestRoute}
            to={frontend.memberAvailability.url({
              organizationId,
              userId,
              workspaceId,
            })}
            onClick={(e) => e.stopPropagation()}
          >{`${averageAvailability}/d availability`}</LpLink>
        </span>{' '}
        <span className="workload-view__average-availability-paren"> )</span>
      </span>
    </div>
  );
};

interface OrgUserRemainingCellProps {
  style: CSSProperties;
  workloadId: number;
  orgUserId?: number;
}

export const OrgUserRemainingCell = ({ style, workloadId, orgUserId }: OrgUserRemainingCellProps) => {
  const workloadGroups = useSelector((state) => getWorkloadGroupsIndexedByGroupIdForWorkloadId(state, workloadId));
  const workloadGroup = workloadGroups && orgUserId ? workloadGroups[orgUserId] : undefined;

  if (!workloadGroup) {
    return null;
  }

  return (
    <div style={style} className="workload-view__section-header-remaining">
      <span>{formatAsHours(convertSecondsToHours(workloadGroup.effortSeconds))}</span>
    </div>
  );
};

interface OrgUserAvailabilityCellProps {
  style: CSSProperties;
  orgUserId?: number;
  workloadId: number;
}

export const OrgUserAvailabilityCell = ({ style, orgUserId, workloadId }: OrgUserAvailabilityCellProps) => {
  const workloadGroups = useSelector((state) => getWorkloadGroupsIndexedByGroupIdForWorkloadId(state, workloadId));
  const workloadGroup = workloadGroups && orgUserId ? workloadGroups[orgUserId] : undefined;

  if (!workloadGroup) {
    return null;
  }

  return (
    <div style={style} className="workload-view__section-header-availability">
      <span>{`${
        workloadGroup.availabilitySeconds
          ? roundedPercentage(workloadGroup.effortSeconds, workloadGroup.availabilitySeconds)
          : 100
      }%`}</span>
    </div>
  );
};

interface OrgUserNubCellProps {
  style: CSSProperties;
  workloadId: number;
  nubIndex: number;
  workloadGroup: WorkloadGroup;
}
export const OrgUserNubCell = memo(({ style, workloadId, nubIndex, workloadGroup }: OrgUserNubCellProps) => {
  const workload = useSelector((state) => getWorkloadById(state, workloadId));
  const date = workload?.dates[nubIndex];

  if (!date) {
    return null;
  }

  const lastDateIndex = workload.dates.length - 1;
  const { dailyWorkSeconds, dailyAvailabilitySeconds, lateByDate, timeOffByDate, scheduleTypeByDate } = workloadGroup;

  return (
    <div style={style} className="workload-view__section-header-nub">
      <WorkloadNub
        dailyWorkSeconds={dailyWorkSeconds[date]}
        date={date}
        isParentTaskLate={false}
        isRollup={true}
        late={lateByDate[date] !== undefined}
        planned={scheduleTypeByDate[date] === ScheduleEntryType.Planned}
        timeOff={timeOffByDate[date] !== undefined}
        unavailable={(dailyAvailabilitySeconds[date] ?? 0) === 0}
        username={workloadGroup.username}
        workScheduledBeyondRange={lastDateIndex === nubIndex}
      />
    </div>
  );
}, areEqual);
