import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { signal } from '@preact/signals-react';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import { debounce } from 'lodash';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'react-window';
import { Portal } from 'semantic-ui-react';

import { RelativePriorityType } from 'daos/item_enums';
import { WidgetGroupDao } from 'daos/widget_groups';
import { WidgetDao } from 'daos/widgets';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { getWidgetGroupIdsSortedByPriorityForDashboardId } from 'features/dashboards/selectors';
import { DashboardsDraggableType, useDashboardContext } from 'features/dashboards_v2/context';
import {
  customCollisionDetectionAlgorithm,
  DRAG_OVER_DEBOUNCE_DURATION_MILLISECONDS,
  recenterPointerLocation,
  reorderWidgetGroup,
  reorderWidgetIntoNewGroup,
  reorderWidgetIntoNewGroupOverWidget,
  reorderWidgetWithinGroup,
} from 'features/dashboards_v2/drag_and_drop/helpers';
import { SortableWidgetGroup } from 'features/dashboards_v2/drag_and_drop/sortable_widget_group';
import { WidgetRenderer } from 'features/dashboards_v2/widget/renderer';
import { WidgetGroupIds } from 'features/dashboards_v2/widget/types';
import { WidgetGroup } from 'features/dashboards_v2/widget_group';
import { WidgetSettingsRenderer } from 'features/dashboards_v2/widget_settings/renderer';
import { awaitRequestFinish } from 'lib/api';
import { decelerateCubicBezier } from 'style/variables';

const portalNode = document.getElementById('portal');

const groupIdsAndWidgetIds = signal<Array<WidgetGroupIds>>([]);

interface WidgetGroupsProps {
  dashboardId: string;
}
export const WidgetGroups = memo(({ dashboardId }: WidgetGroupsProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const currentGroupIdsAndWidgetIds = useSelector((state) =>
    getWidgetGroupIdsSortedByPriorityForDashboardId(state, dashboardId),
  );
  const {
    inDesign,
    setActiveGroupId,
    activeGroupId,
    showSettingsForWidget,
    activeWidgetId,
    setActiveWidgetId,
    isGroupLayoutTransitioning,
  } = useDashboardContext();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  useEffect(() => {
    if (activeGroupId || activeWidgetId) {
      return;
    }

    groupIdsAndWidgetIds.value = currentGroupIdsAndWidgetIds;
  }, [activeGroupId, activeWidgetId, currentGroupIdsAndWidgetIds]);

  useEffect(() => {
    return () => {
      groupIdsAndWidgetIds.value = [];
    };
  }, []);

  function onDragStart({ active }: DragStartEvent) {
    const data = active.data.current;

    if (!data) {
      return;
    }

    if (data.type === DashboardsDraggableType.Group) {
      setActiveGroupId({
        id: active.id,
        widgetGroupId: data.widgetGroupId,
        index: data.index,
        widgetIds: data.widgetIds,
        type: DashboardsDraggableType.Widget,
      });
    }

    if (data.type === DashboardsDraggableType.Widget) {
      setActiveWidgetId({
        id: active.id,
        widgetId: data.widgetId,
        index: data.index,
        type: DashboardsDraggableType.Widget,
        widgetGroupId: data.widgetGroupId,
        WidgetGroupIndex: data.widgetGroupIndex,
      });
    }
  }

  const onDragOver = useMemo(
    () =>
      debounce(async ({ active, over }: DragOverEvent) => {
        const activeData = active.data.current;
        const overData = over?.data.current;
        if (!activeData || !overData) {
          return;
        }

        if (!over || over.id == active.id) {
          return;
        }

        if (activeData.type === DashboardsDraggableType.Group) {
          const from = activeData.index;
          const to = overData.type === DashboardsDraggableType.Group ? overData.index : overData.widgetGroupIndex;

          groupIdsAndWidgetIds.value = await reorderWidgetGroup({ from, to, items: groupIdsAndWidgetIds.value });
        }

        if (activeData.type === DashboardsDraggableType.Widget) {
          const isSameGroup = activeData.widgetGroupId === overData.widgetGroupId;
          const overWidget = overData.type === DashboardsDraggableType.Widget;
          const overGroup = overData.type === DashboardsDraggableType.Group;
          const widgetIsNotSelf = overData.widgetId !== activeData.widgetId;

          if (isSameGroup && overWidget && widgetIsNotSelf) {
            const from = activeData.index;
            const to = overData.index;
            const widgetGroupIndex = activeData.widgetGroupIndex;

            groupIdsAndWidgetIds.value = await reorderWidgetWithinGroup({
              from,
              to,
              widgetGroupIndex,
              items: groupIdsAndWidgetIds.value,
            });
          } else if (!isSameGroup && overWidget) {
            const from = activeData.index;
            const to = overData.index;
            const oldWidgetGroupIndex = activeData.widgetGroupIndex;
            const newWidgetGroupIndex = overData.widgetGroupIndex;

            groupIdsAndWidgetIds.value = await reorderWidgetIntoNewGroupOverWidget({
              to,
              from,
              newWidgetGroupIndex,
              oldWidgetGroupIndex,
              items: groupIdsAndWidgetIds.value,
            });
          } else if (!isSameGroup && overGroup) {
            const from = activeData.index;
            const oldWidgetGroupIndex = activeData.widgetGroupIndex;
            const newWidgetGroupIndex = overData.index;

            groupIdsAndWidgetIds.value = await reorderWidgetIntoNewGroup({
              from,
              newWidgetGroupIndex,
              oldWidgetGroupIndex,
              items: groupIdsAndWidgetIds.value,
            });
          }
        }
      }, DRAG_OVER_DEBOUNCE_DURATION_MILLISECONDS),
    [],
  );

  function onDragCancel() {
    setActiveGroupId(null);
    setActiveWidgetId(null);
  }

  function onDragEnd({ active }: DragEndEvent) {
    const activeData = active.data.current;

    if (!activeData) {
      return;
    }

    let request;

    if (activeData.type === DashboardsDraggableType.Group) {
      const newGroupIndex = activeData.index;
      const groupId = groupIdsAndWidgetIds.value[newGroupIndex]?.widgetGroupId;

      if (!groupId) {
        return;
      }

      const nextGroupId = groupIdsAndWidgetIds.value[newGroupIndex + 1]?.widgetGroupId;

      const relativePriority = nextGroupId
        ? { type: RelativePriorityType.BEFORE, id: Number(nextGroupId) }
        : { type: RelativePriorityType.AFTER };

      request = dispatch(
        WidgetGroupDao.update(
          {
            organizationId,
            workspaceId,
            dashboardId,
            widgetGroupId: groupId.toString(),
          },
          { relativePriority },
        ),
      );
    }

    if (activeData.type === DashboardsDraggableType.Widget) {
      const isSameGroup = activeData.widgetGroupId === activeWidgetId?.widgetGroupId;
      const newWidgetIndex = activeData.index;
      const groupIndex = activeData.widgetGroupIndex;
      const group = groupIdsAndWidgetIds.value[groupIndex];
      const nextWidgetId = group?.widgetIds[newWidgetIndex + 1]?.widgetId.toString();
      const widgetGroupId = isSameGroup ? undefined : activeData.widgetGroupId;

      const relativePriority = nextWidgetId
        ? { type: RelativePriorityType.BEFORE, id: Number(nextWidgetId) }
        : { type: RelativePriorityType.AFTER };

      request = dispatch(
        WidgetDao.update(
          {
            organizationId,
            workspaceId,
            dashboardId,
            widgetId: activeData.widgetId,
          },
          { relativePriority, widgetGroupId },
        ),
      );
    }

    if (!request) {
      setActiveGroupId(null);
      setActiveWidgetId(null);
      return;
    }

    dispatch(
      awaitRequestFinish(request.uuid, {
        onFinish: () => {
          setActiveGroupId(null);
          setActiveWidgetId(null);
        },
      }),
    );
  }

  const settingsForWidget = showSettingsForWidget && (
    <WidgetSettingsRenderer dashboardId={dashboardId} widgetId={showSettingsForWidget.toString()} />
  );

  if (!inDesign) {
    return (
      <LayoutGroup>
        {groupIdsAndWidgetIds.value.map(({ id, widgetGroupId, widgetIds }, index) => (
          <WidgetGroup
            dashboardId={dashboardId}
            key={id}
            widgetGroupId={widgetGroupId.toString()}
            index={index}
            widgetIds={widgetIds}
          />
        ))}
        {settingsForWidget}
      </LayoutGroup>
    );
  }

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={customCollisionDetectionAlgorithm(isGroupLayoutTransitioning)}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragCancel={onDragCancel}
        onDragEnd={onDragEnd}
      >
        <SortableContext items={groupIdsAndWidgetIds.value} strategy={() => null}>
          <LayoutGroup>
            {groupIdsAndWidgetIds.value.map(({ id, widgetGroupId, widgetIds }, index) => (
              <SortableWidgetGroup
                dashboardId={dashboardId}
                key={id}
                widgetGroupId={widgetGroupId.toString()}
                index={index}
                widgetIds={widgetIds}
              />
            ))}
          </LayoutGroup>

          <Portal mountNode={portalNode} open={true}>
            <DragOverlay
              modifiers={[recenterPointerLocation]}
              className="v2-dashboards-view__drag-overlay"
              dropAnimation={{
                duration: 300,
                easing: decelerateCubicBezier,
              }}
            >
              <AnimatePresence initial={false}>
                {activeGroupId && (
                  <WidgetGroup
                    key={activeGroupId.id}
                    dashboardId={dashboardId}
                    widgetGroupId={activeGroupId.widgetGroupId.toString()}
                    isOverlay={true}
                    index={activeGroupId.index}
                    widgetIds={activeGroupId.widgetIds}
                  />
                )}
                {activeWidgetId && (
                  <WidgetRenderer
                    key={activeWidgetId.id}
                    dashboardId={dashboardId}
                    widgetId={activeWidgetId.widgetId.toString()}
                    isOverlay={true}
                  />
                )}
              </AnimatePresence>
            </DragOverlay>
          </Portal>
        </SortableContext>
      </DndContext>
      {settingsForWidget}
    </>
  );
}, areEqual);
