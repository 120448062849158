import classNames from 'classnames';
import { Suspense, useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';

import ItemPanelBreadcrumb from 'features/common/breadcrumbs/item_panel_breadcrumb';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import RichTextEditor from 'features/common/inputs/rich_text_editor';
import LpModal from 'features/common/modals/lp_modal';
import { useItemPanelContext } from 'features/item_panel/item_panel_context';
import { useItemPanelParams } from 'features/item_panel/use_item_panel_params';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';
import { awaitRequestFinish } from 'lib/api';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';

import 'features/common/custom_fields/custom_field_value/custom_field_note.scss';

const CustomFieldNote = ({
  disabled,
  showsEditButton,
  height,
  fieldId,
  resourceId,
}: CustomFieldValueProps & {
  showsEditButton?: boolean;
  height?: string;
}) => {
  const dispatch = useDispatch();

  const { setActions, defaultActions } = useItemPanelContext();
  const { updateFieldValue, createFieldValue, removeFieldValue } = useCustomFieldsApiActions(resourceId.type);

  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);
  const { itemPanelId: itemId } = useItemPanelParams();

  const { allowsMultipleValues, values } = field ?? {};

  const fieldValue = values?.[0];

  const [content, setContent] = useState(fieldValue?.html ?? '');
  const [prevContent, setPrevContent] = useState('');
  const [failedToSaveNote, setFailedToSaveNote] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const noteName = field?.name ?? 'Note';

  const handleEditorChange = useCallback((updates: string) => {
    setContent(updates);
  }, []);

  const handleEditNoteButton = useCallback(() => {
    setIsEditing(true);
    setPrevContent(content);
    setFailedToSaveNote(false);
  }, [content]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setContent(prevContent);
    setPrevContent('');
  }, [prevContent]);

  const handleSave = useCallback(
    (closeAfterSave: boolean) => () => {
      setPrevContent(content);
      const hasHtmlContent = content !== '';

      if (!hasHtmlContent) {
        fieldValue && removeFieldValue(resourceId.id, fieldValue.id);
        closeAfterSave && setIsEditing(false);
        return;
      }

      if (hasHtmlContent) {
        const payload = { html: content };
        const createOrUpdateRequest = () =>
          fieldValue
            ? updateFieldValue(resourceId.id, fieldValue.id, payload)
            : createFieldValue(resourceId.id, fieldId, payload);
        const { uuid } = createOrUpdateRequest();
        dispatch(
          awaitRequestFinish(uuid, {
            onError: () => {
              setFailedToSaveNote(true);
            },
            onSuccess: () => {
              setFailedToSaveNote(false);
              closeAfterSave && setIsEditing(false);
            },
          }),
        );
        return;
      }
    },
    [content, createFieldValue, dispatch, fieldId, fieldValue, removeFieldValue, resourceId, updateFieldValue],
  );

  const editingActions = useMemo(
    () => [
      failedToSaveNote ? (
        <span key={`error-note-save`} className="note-editing-modal--save-error">
          Failed to save
        </span>
      ) : undefined,
      <Button content="Cancel" onClick={handleCancel} key="cancel-btn" />,
      <Button content="Save" primary onClick={handleSave(false)} key="save-continue-btn" disabled={disabled} />,
      <Button content="Save & Close" primary onClick={handleSave(true)} key="save-close-btn" disabled={disabled} />,
    ],
    [disabled, failedToSaveNote, handleCancel, handleSave],
  );

  const readOnlyActions = useMemo(
    () => [
      <Button
        className={'custom-field-table__note-edit-button'}
        color="blue"
        content="Edit Note"
        disabled={disabled}
        key="edit-btn"
        onClick={handleEditNoteButton}
      />,
    ],
    [disabled, handleEditNoteButton],
  );

  const editingNoteName = useMemo(
    () => [
      <span key="note-name" style={{ float: 'left' }}>
        {`Editing "${noteName}"`}
      </span>,
    ],
    [noteName],
  );

  useEffect(() => {
    setContent(fieldValue?.html ?? '');
  }, [fieldValue?.html]);

  useEffect(() => {
    setActions([...readOnlyActions, ...defaultActions]);

    return () => {
      setActions(defaultActions);
    };
  }, [defaultActions, readOnlyActions, setActions]);

  const noteSection = useMemo(
    () => <RichTextEditor content={fieldValue?.html ?? ''} handleEditorChange={handleEditorChange} height={height} />,
    [fieldValue?.html, handleEditorChange, height],
  );

  const editor = useMemo(
    () => (
      <>
        {isEditing ? (
          <Suspense
            fallback={
              <div
                className={classNames('read-only-panel', { 'read-only-panel--placeholder-text': !fieldValue })}
                dangerouslySetInnerHTML={{
                  __html: fieldValue?.html ?? `Click Edit Note to begin${HORIZONTAL_ELLIPSIS}`,
                }}
              />
            }
          >
            {itemId ? (
              <LpModal
                className="lp-item-panel-modal note-editing-modal"
                size="large"
                header={<ItemPanelBreadcrumb itemId={itemId} isClickable={false} />}
                content={
                  <div
                    className={classNames(
                      'note-editing-modal-section',
                      failedToSaveNote && 'note-editing-modal-section--error',
                    )}
                  >
                    {noteSection}
                  </div>
                }
                actions={[editingNoteName, ...editingActions]}
              />
            ) : (
              noteSection
            )}
          </Suspense>
        ) : (
          <div
            className={classNames('read-only-panel', { 'read-only-panel--placeholder-text': !fieldValue })}
            dangerouslySetInnerHTML={{
              __html: fieldValue?.html ?? `Click Edit Note to begin${HORIZONTAL_ELLIPSIS}`,
            }}
          />
        )}
        {showsEditButton && <span className="note-user-actions">{isEditing ? editingActions : readOnlyActions}</span>}
      </>
    ),
    [
      editingActions,
      editingNoteName,
      failedToSaveNote,
      fieldValue,
      isEditing,
      itemId,
      noteSection,
      readOnlyActions,
      showsEditButton,
    ],
  );

  if (allowsMultipleValues) {
    throw new Error('multiple values not supported by Note field');
  }

  return <>{editor}</>;
};

export default CustomFieldNote;
