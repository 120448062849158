import { TaskGroupHierarchy } from 'daos/external_integration_enums';
import { LpSvgs } from 'features/common/lp_svg';
import {
  SubFolderOrganizationName,
  SubFolderOrganizationValues,
  TimesheetAuthStatus,
  WorklogOption,
} from 'features/jira_project/modal/types';

export const getWorklogOptions = (shouldShowTimesheetOption: boolean) => [
  {
    text: 'Do not pull any Worklogs',
    value: WorklogOption.DO_NOT_PULL,
  },
  { text: 'Pull from Jira', value: WorklogOption.PULL_FROM_JIRA },
  ...(shouldShowTimesheetOption
    ? [
        {
          text: 'Pull from Tempo Timesheets',
          value: WorklogOption.PULL_FROM_TEMPO_TIMESHEETS,
        },
      ]
    : []),
];

export const worklogOption = (syncJiraWorklog: boolean, syncTimesheetWorklog: boolean) => {
  if (syncJiraWorklog) {
    return WorklogOption.PULL_FROM_JIRA;
  } else if (syncTimesheetWorklog) {
    return WorklogOption.PULL_FROM_TEMPO_TIMESHEETS;
  } else {
    return WorklogOption.DO_NOT_PULL;
  }
};

export const hasAccessChanged = (
  syncTimesheetWorklog: boolean,
  tempoTimesheetAuth: TimesheetAuthStatus,
  setShowAlert: (showAlert: boolean) => void,
) => {
  if (syncTimesheetWorklog && tempoTimesheetAuth === TimesheetAuthStatus.Failure) {
    setShowAlert(true);
  } else {
    setShowAlert(false);
  }
};

export const getSubFolderOrganizationOptions = (isJiraPremiumUser: boolean): Array<SubFolderOrganizationValues> => [
  {
    name: SubFolderOrganizationName.FLAT_LIST,
    description: 'Pulls tasks in a flat list in the project.',
    subtitle: 'Pulls tasks in a flat list in the project',
    id: TaskGroupHierarchy.GroupFlatLevel,
    image: LpSvgs.FLAT_LIST,
  },
  {
    name: SubFolderOrganizationName.EPIC_BASED_SUBFOLDERS,
    description: 'Create a sub-folder for each Epic (level 1), with child tasks inside.',
    subtitle: 'Sub-folder for each Epic (level 1), with child tasks inside.',
    id: TaskGroupHierarchy.GroupByEpic,
    image: LpSvgs.EPIC_BASED_SUBFOLDER,
  },
  ...(isJiraPremiumUser
    ? [
        {
          name: SubFolderOrganizationName.HIERARCHICAL_SUBFOLDERS,
          description:
            'Create a sub-folder for each custom hierarchical issue type (i.e., level 1 and above e.g., Initiative, Feature, Epic), with child items nested within the corresponding type.',
          subtitle:
            'Sub-folder for each custom hierarchical issue type (i.e., level 1 and above e.g., Initiative, Feature, Epic), with child items nested within the corresponding type.',
          id: TaskGroupHierarchy.GroupByParentAncestry,
          image: LpSvgs.HIERARCHICAL_SUBFOLDERS,
        },
      ]
    : []),
];

export const isOptionDisabled = (
  optionId: TaskGroupHierarchy,
  canSelectLowerHierarchicalLevel: { isFlatListDisabled: boolean; isEpicBasedDisabled: boolean },
) => {
  const { isFlatListDisabled, isEpicBasedDisabled } = canSelectLowerHierarchicalLevel;

  switch (optionId) {
    case TaskGroupHierarchy.GroupFlatLevel:
      return isFlatListDisabled;
    case TaskGroupHierarchy.GroupByEpic:
      return isEpicBasedDisabled;
    default:
      return false;
  }
};

export const getDisabledPopupText = (optionId: TaskGroupHierarchy) => {
  switch (optionId) {
    case TaskGroupHierarchy.GroupFlatLevel:
      return 'This option is disabled because once a Sub-Folder structure is set up, it cannot be reverted to a Flat List.';
    case TaskGroupHierarchy.GroupByEpic:
      return 'This option is disabled because once a Hierarchical Sub-Folder structure is set up, it cannot be reverted to an Epic-Based Sub-Folder.';
    default:
      return '';
  }
};
