import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { upgradedPlanFamilies } from 'daos/plan';
import { getCurrentOrganizationPlan } from 'state/entities/selectors/plan';

export type OrgWsCompanyDisplay = 'Organization' | 'Workspace' | 'Company';

const lpCompanyNameRegex = /^[a-zA-Z0-9\s-_]*$/;
const lpCompanyNameLengthLimit = 92;

const nameValidationErrors = {
  orgWsNameRequired: (entity: OrgWsCompanyDisplay) => `${entity} Name is required`,
  orgWsNameInvalid: (entity: OrgWsCompanyDisplay) =>
    `Your ${entity} Name must only contain letters, numbers, spaces, hyphens or underscores`,
  orgWsNameTooLong: (entity: OrgWsCompanyDisplay) =>
    `${entity} Name must be no more than ${lpCompanyNameLengthLimit} characters`,
};

export const useNameValidation = (entity: OrgWsCompanyDisplay) => {
  const currentPlanFamily = useSelector(getCurrentOrganizationPlan)?.family;
  const isPaidPlan = currentPlanFamily && upgradedPlanFamilies.includes(currentPlanFamily);

  const paidPlanNameValidation = yup.string().trim().required(nameValidationErrors.orgWsNameRequired(entity));
  const freePlanNameValidation = paidPlanNameValidation
    .clone()
    .matches(lpCompanyNameRegex, nameValidationErrors.orgWsNameInvalid(entity))
    .max(lpCompanyNameLengthLimit, nameValidationErrors.orgWsNameTooLong(entity));

  return isPaidPlan ? paidPlanNameValidation : freePlanNameValidation;
};
