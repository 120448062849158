import { PickByValue } from 'utility-types';

import { ReadonlyRecord, ReadonlyRecordKey } from 'lib/readonly_record';

export const stringComparison =
  <T>(target: keyof PickByValue<T, string | number | undefined>, isAscending: boolean) =>
  (valueA: T, valueB: T) => {
    const cellA = valueA[target] as string;
    const cellB = valueB[target] as string;
    return isAscending ? cellA.localeCompare(cellB) : cellB.localeCompare(cellA);
  };

export const stringDateComparison =
  <T>(
    target: keyof PickByValue<T, string | number | undefined>,
    isAscending: boolean,
    dateStringSorter: (date1: string, date2: string) => number,
  ) =>
  (valueA: T, valueB: T) => {
    const cellA = valueA[target] as string;
    const cellB = valueB[target] as string;

    return isAscending ? dateStringSorter(cellA, cellB) : dateStringSorter(cellB, cellA);
  };

export const numberComparison =
  <T>(target: keyof PickByValue<T, string | number | undefined>, isAscending: boolean) =>
  (valueA: T, valueB: T) => {
    const cellA = valueA[target] as number;
    const cellB = valueB[target] as number;
    return isAscending ? cellA - cellB : cellB - cellA;
  };

export const typeComparison =
  <T, K extends ReadonlyRecordKey>(
    target: keyof PickByValue<T, string | number | undefined>,
    typeOrder: ReadonlyRecord<K, number>,
  ) =>
  (lockA: T, lockB: T) => {
    const itemTypeA = lockA[target] as K;
    const itemTypeB = lockB[target] as K;

    const lockATypeValue = typeOrder[itemTypeA];
    const lockBTypeValue = typeOrder[itemTypeB];

    return lockBTypeValue - lockATypeValue;
  };

const iterateOnComparisonFns = <T>(itemA: T, itemB: T, comparisonFns: Array<(itemA: T, itemB: T) => number>) => {
  for (let i = 0; i < comparisonFns.length; i++) {
    const currentComparisonFn: (itemA: T, itemB: T) => number = comparisonFns[i] as (itemA: T, itemB: T) => number;
    const result = currentComparisonFn(itemA, itemB);
    if (result !== 0) {
      return result;
    }
  }
  return 0;
};

export const multiComparisonSort = <T>(
  items: Array<T>,
  comparisonFns: Array<(itemA: T, itemB: T) => number>,
): Array<T> => {
  if (!comparisonFns.length) {
    return items;
  }

  return items.sort((itemA, itemB) => {
    return iterateOnComparisonFns(itemA, itemB, comparisonFns);
  });
};
