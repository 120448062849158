import { booleanCompare } from 'lib/helpers';
import { ReadonlyRecord } from 'lib/readonly_record';

import { Assignee } from './types';

export const compareAssigneesByDoneBoolean = (a: Assignee, b: Assignee) => booleanCompare(a.done, b.done);

export const mapAssigneesByOrgUserId = (assignees: ReadonlyArray<Assignee>) => {
  const assigneeMap = new Map<number, Assignee>();
  assignees.forEach((assignee) => {
    const existingAssignee = assigneeMap.get(assignee.orgUserId);

    if (existingAssignee) {
      const existingAssigneeIsDone = existingAssignee.done;
      const currentAssigneeIsDone = assignee.done;

      if (existingAssigneeIsDone && !currentAssigneeIsDone) {
        assigneeMap.set(existingAssignee.orgUserId, { ...existingAssignee, done: false });
      }
    } else {
      assigneeMap.set(assignee.orgUserId, assignee);
    }
  });

  return assigneeMap;
};

export const mapAssigneesByOrgUserIdOrderedByDoneBoolean = (assignees: ReadonlyArray<Assignee>) => {
  const sortedAssignees = [...assignees].sort(compareAssigneesByDoneBoolean);
  return mapAssigneesByOrgUserId(sortedAssignees);
};

export const mapWidgetAssignees = (
  widgetAssigneesByOrgUserId: ReadonlyRecord<
    string,
    {
      disconnectedAt: string | null;
      done: string | null;
      orgUserId: number;
      username: string;
    }
  >,
) => {
  const assignees: ReadonlyArray<Assignee> = Object.values(widgetAssigneesByOrgUserId).map((widgetAssignee) => ({
    orgUserId: widgetAssignee.orgUserId,
    disconnected: !!widgetAssignee.disconnectedAt,
    done: !!widgetAssignee.done,
    username: widgetAssignee.username,
  }));

  return mapAssigneesByOrgUserIdOrderedByDoneBoolean(assignees);
};
