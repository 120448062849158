import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { Permission, UserType } from 'daos/enums';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import CustomFieldRow from 'features/common/custom_fields/custom_field_row';
import { useHasAccess } from 'hooks/use_has_access';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { awaitRequestFinish } from 'lib/api';
import { getFieldsUsedOnUserSortedByPriorityForWsUserRescourceId as getFieldsForWsUser } from 'state/entities/selectors/custom_field';
import { getCurrentOrganization } from 'state/entities/selectors/organization';
import {
  getOrganizationUserInCurrentOrgForUserId,
  getUserForId,
  getWorkspaceUserForSpaceIdAndUserId,
} from 'state/entities/selectors/user';

import { EmailNotificationPreferences } from './email_notification_preference';

interface WorkspaceAttributesTableProps {
  userId: number;
  isViewingCurrentSelf: boolean;
}

const WorkspaceAttributesTable = ({ userId, isViewingCurrentSelf }: WorkspaceAttributesTableProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => getUserForId(state, userId));
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const workspaceUser = useSelector((state) => getWorkspaceUserForSpaceIdAndUserId(state, workspaceId, userId));
  const fields = useSelector((state) => (workspaceUser && getFieldsForWsUser(state, workspaceUser)) || []);
  const orgUser = useSelector((state) => getOrganizationUserInCurrentOrgForUserId(state, userId));
  const organization = useSelector(getCurrentOrganization);
  const hasDataCustomization = organization?.flags.hasDataCustomization;

  const workspaceUserId = workspaceUser?.id;

  const workspaceUserResource = workspaceUser ? WorkspaceUserDao.id(workspaceUser) : undefined;
  const hasAccess = useHasAccess(Permission.EDIT, workspaceUserResource);

  const { formatLocalDate } = useLocalizedFormats();

  useEffect(() => {
    if (workspaceUserId) {
      const { uuid } = dispatch(
        WorkspaceUserDao.fetch(
          { organizationId, workspaceId, workspaceUserId },
          { include: { includeFieldValues: true, includeFields: true } },
        ),
      );

      dispatch(awaitRequestFinish(uuid, {}));
    }
  }, [dispatch, organizationId, workspaceId, workspaceUserId]);

  if (!workspaceUser || !orgUser || !workspaceUserResource) {
    return null;
  }

  return (
    <Table className="user-profile__workspace-attributes-table custom-field-table" celled compact definition>
      <Table.Body>
        {fields.map((field) => (
          <CustomFieldRow
            key={field.id}
            disabled={!hasAccess || !hasDataCustomization}
            field={field}
            resource={workspaceUserResource}
          />
        ))}

        <Table.Row>
          <Table.Cell collapsing content={`${user?.userType === UserType.Member ? 'Member Since' : 'Created'}`} />
          <Table.Cell colSpan="2" content={formatLocalDate(workspaceUser.createdAt)} />
        </Table.Row>

        {isViewingCurrentSelf && (
          <Table.Row>
            <Table.Cell collapsing content={'Email Notification Preferences'} />
            <Table.Cell colSpan="2" content={<EmailNotificationPreferences organizationUserId={orgUser.id} />} />
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default WorkspaceAttributesTable;
