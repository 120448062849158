import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { OrganizationUserEmailNotifications } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { resource, createBody } = defineModel({
  apiType: 'organizationUserEmailNotifications',
  type: 'ORGANIZATION_USER_EMAIL_NOTIFICATION',
});

interface OrganizationUserEmailNotificationsUrlParams {
  organizationId: number;
  organizationUserId: number;
}

const { ORGANIZATION_USER_EMAIL_NOTIFICATION, resourceId } = resource;

const fetch = (params: OrganizationUserEmailNotificationsUrlParams) =>
  request(backend.organizationUserEmailNotifications.url(params), ORGANIZATION_USER_EMAIL_NOTIFICATION, {
    method: HttpMethod.GET,
  });

const upsert = (
  params: OrganizationUserEmailNotificationsUrlParams,
  orgUserEmailIntegration: Partial<OrganizationUserEmailNotifications>,
) =>
  request(
    backend.organizationUserEmailNotificationsUpsert.url(params),
    ORGANIZATION_USER_EMAIL_NOTIFICATION,
    createBody(orgUserEmailIntegration),
  );

export const OrganizationUserEmailNotificationsDao = {
  fetch,
  upsert,
  id: resourceId,
} as const;
