import { SyntheticEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, CheckboxProps, Dropdown, DropdownProps } from 'semantic-ui-react';

import { EmailFrequency, EmailFrequencyDisplay } from 'daos/enums';
import { OrganizationUserEmailNotificationsDao } from 'daos/organization_user_email_notifications';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { getOrganizationUserEmailNotificationsForOrgUserId } from 'state/entities/selectors/organization_user_email_notifications';
import './email_notification_preference.scss';

const emailFrequencyNotificationOptions = Object.values(EmailFrequency).map((value) => {
  return { value: value, text: EmailFrequencyDisplay[value], key: value };
});

export const EmailNotificationPreferences = ({ organizationUserId }: { organizationUserId: number }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);

  const currentOrgUserEmailNotificationPreferences = useSelector((state) =>
    getOrganizationUserEmailNotificationsForOrgUserId(state, organizationUserId),
  );

  const orgUserEmailNotifications = {
    discussionMentions: currentOrgUserEmailNotificationPreferences?.discussionMentions || false,
    discussionSubscription: currentOrgUserEmailNotificationPreferences?.discussionSubscription || false,
    notifyAssignedUser: currentOrgUserEmailNotificationPreferences?.notifyAssignedUser || false,
    emailFrequency: currentOrgUserEmailNotificationPreferences?.emailFrequency || EmailFrequency.NONE,
  };

  const updateUserEmailNotificationPreferences = (updatedEmailNotificationPreferences: {
    discussionMentions?: boolean;
    discussionSubscription?: boolean;
    notifyAssignedUser?: boolean;
    emailFrequency?: EmailFrequency;
  }) => {
    dispatch(
      OrganizationUserEmailNotificationsDao.upsert(
        { organizationId, organizationUserId },
        {
          id: currentOrgUserEmailNotificationPreferences?.id || 0,
          orgId: organizationId,
          orgUserId: organizationUserId,
          ...orgUserEmailNotifications,
          ...updatedEmailNotificationPreferences,
        },
      ),
    );
  };

  const handleDropdownChange = (_: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    if (value !== orgUserEmailNotifications.emailFrequency) {
      updateUserEmailNotificationPreferences({
        emailFrequency: value as EmailFrequency,
      });
    }
  };

  const handleCheckboxChange = (_: SyntheticEvent, { checked, value }: CheckboxProps) => {
    const updatedCheckedValue = { [value as string]: checked };
    updateUserEmailNotificationPreferences({
      ...updatedCheckedValue,
    });
  };

  const fetchUserEmailNotificationPreferences = useCallback(() => {
    dispatch(OrganizationUserEmailNotificationsDao.fetch({ organizationId, organizationUserId }));
  }, [dispatch, organizationId, organizationUserId]);

  useEffect(() => {
    fetchUserEmailNotificationPreferences();
  }, [fetchUserEmailNotificationPreferences]);

  return (
    <div className="email_notification_preferences">
      <div className="email_notification_preferences__form">
        <div className="email_notification_preferences__form__dropdown-label">Send me email notifications</div>

        <Dropdown
          selection
          className="email_notification_preferences__form__dropdown"
          options={emailFrequencyNotificationOptions}
          value={orgUserEmailNotifications.emailFrequency}
          onChange={handleDropdownChange}
        />

        <Checkbox
          checked={orgUserEmailNotifications.discussionSubscription}
          onChange={handleCheckboxChange}
          value={'discussionSubscription'}
          className="email_notification_preferences__form__checkbox-follow"
          label="Notify me when there is a new post in a Discussion I follow"
        />

        <Checkbox
          checked={orgUserEmailNotifications.discussionMentions}
          onChange={handleCheckboxChange}
          value={'discussionMentions'}
          className="email_notification_preferences__form__checkbox-post"
          label="Notify me when I am mentioned in a new post"
        />

        <Checkbox
          checked={orgUserEmailNotifications.notifyAssignedUser}
          onChange={handleCheckboxChange}
          value={'notifyAssignedUser'}
          className="email_notification_preferences__form__checkbox-new-assignment"
          label="Notify me when I have a new assignment"
        />
      </div>

      <div className="email_notification_preferences__notices">
        <ul>
          <li>You need Project Observer access (or above) on the plan item in order to receive email notifications.</li>
          <li>If you do not see notifications, check your spam filter.</li>
          <li>
            Email Notification Preference settings are the same for all the workspaces you belong to in the
            Organization.
          </li>
          <li>You won&apos;t be notified about discussions you post.</li>
        </ul>
      </div>
    </div>
  );
};
