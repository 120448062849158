import { noop } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { UserType } from 'daos/enums';
import { OrganizationUserDao } from 'daos/organization_user';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { DisconnectWorkspaceGuestModal } from 'features/guest/disconnect_modals';
import BeyondLimitModal from 'features/organization_directory/manage_account/beyond_limit_modal';
import { InviteErrorModal } from 'features/organization_directory/users/invite/invite_errors';
import { DisconnectModal } from 'features/organization_directory/users/profile/workspace_memberships/disconnect_modal';
import { awaitRequestFinish } from 'lib/api';
import { ApiError, ErrorCodes } from 'lib/api/types';
import {
  getOrganizationUserForId,
  getUserForId,
  getWorkspaceUserForOrganizationUserId,
} from 'state/entities/selectors/user';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

const ToggleUserWorkspaceMembership = ({
  afterToggle = noop,
  orgUserId,
  isGuestProfile = false,
}: {
  afterToggle?: (afterOnFinish: () => void) => void;
  orgUserId: number;
  isGuestProfile?: boolean;
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const { id: workspaceId = 0, name: workspaceName = 'this workspace' } = useSelector(getCurrentWorkspace) ?? {};

  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const wsUser = useSelector((state) => getWorkspaceUserForOrganizationUserId(state, orgUserId));
  const isWsUserDisconnected = !!wsUser?.disconnectedAt;

  const user = useSelector((state) => (orgUser ? getUserForId(state, orgUser.user.id) : undefined));

  const [showBeyondLimitModal, setShowBeyondLimitModal] = useState(false);
  const hideBeyondLimitModal = () => setShowBeyondLimitModal(false);

  const [isDisconnectMemberModalOpen, setIsDisconnectMemberModalOpen] = useState(false);
  const hideDisconnectMemberModal = () => setIsDisconnectMemberModalOpen(false);

  const [reconnectError, setReconnectError] = useState<ApiError | undefined>(undefined);
  const closeReconnectErrorModal = () => setReconnectError(undefined);

  if (!user || !orgUser || !wsUser) {
    return null;
  }

  const fetchOrgUser = () => {
    const { uuid } = dispatch(OrganizationUserDao.fetch({ organizationId, organizationUserId: orgUserId }));
    dispatch(awaitRequestFinish(uuid, { onSuccess: () => afterToggle(noop) }));
  };

  const toggleMembership = () => {
    const isDisconnectingMember = user.userType === UserType.Member && !isWsUserDisconnected;
    if (isDisconnectingMember) {
      return setIsDisconnectMemberModalOpen(true);
    }

    const { uuid } = dispatch(
      WorkspaceUserDao.update(
        { organizationId, workspaceId, workspaceUserId: wsUser.id },
        { id: wsUser.id, disconnected: !isWsUserDisconnected },
      ),
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: fetchOrgUser,
        onError: ({ errors }) => {
          if (errors[0]) {
            if (errors[0].code === ErrorCodes.LimitExceeded) {
              return setShowBeyondLimitModal(true);
            }
            setReconnectError(errors[0]);
          }
        },
      }),
    );
  };

  const handleConfirmDisconnectGuest = () => {
    hideDisconnectMemberModal();
    const { uuid } = dispatch(
      WorkspaceUserDao.update(
        {
          organizationId,
          workspaceId,
          workspaceUserId: wsUser.id,
        },
        { id: wsUser.id, disconnected: true },
      ),
    );

    dispatch(awaitRequestFinish(uuid, { onSuccess: () => afterToggle(noop) }));
  };

  const buttonLabel = () => {
    if (user.userType === UserType.Member) {
      let label = isWsUserDisconnected ? 'Reconnect' : 'Disconnect';
      if (isGuestProfile) {
        label += ' All';
      }

      return label;
    }
    return isWsUserDisconnected ? 'Reactivate' : 'Deactivate';
  };

  return (
    <>
      {showBeyondLimitModal && <BeyondLimitModal onClose={hideBeyondLimitModal} />}
      {reconnectError && (
        <InviteErrorModal error={reconnectError} onClose={closeReconnectErrorModal} isReconnect={true} />
      )}
      {isDisconnectMemberModalOpen && (
        <>
          {isGuestProfile ? (
            <DisconnectWorkspaceGuestModal
              onClose={hideDisconnectMemberModal}
              username={orgUser.username}
              onConfirm={handleConfirmDisconnectGuest}
              workspaceName={workspaceName}
            />
          ) : (
            <DisconnectModal
              close={hideDisconnectMemberModal}
              fetch={fetchOrgUser}
              workspaceId={workspaceId}
              workspaceUserId={wsUser.id}
              organizationUserId={orgUser.id}
            />
          )}
        </>
      )}
      <Button primary={isWsUserDisconnected} negative={!isWsUserDisconnected} fluid onClick={toggleMembership}>
        {buttonLabel()}
      </Button>
    </>
  );
};

export default ToggleUserWorkspaceMembership;
