import { useSelector } from 'react-redux';

import { trackViewUrl } from 'features/academy/helpers';
import {
  getTrackLessonsSortedByPriorityForTrack,
  getTracksSortedByPriorityForCourse,
} from 'features/academy/selectors';
import { LpButtonAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { frontend } from 'lib/urls';

const NextCourseButton = ({ courseId }: { courseId: string }) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const nextCourseTracks = useSelector((state) => getTracksSortedByPriorityForCourse(state, courseId));
  const firstTrackIdInCourse = nextCourseTracks[0]?.id;

  const trackLessonsInNextTrack = useSelector((state) =>
    firstTrackIdInCourse ? getTrackLessonsSortedByPriorityForTrack(state, String(firstTrackIdInCourse)) : undefined,
  );
  const firstLessonsIdInFirstTrack = trackLessonsInNextTrack?.[0]?.academyLessonId;

  if (!firstTrackIdInCourse || !firstLessonsIdInFirstTrack) {
    return null;
  }

  return (
    <LpButtonAsLink
      to={trackViewUrl({
        courseId,
        trackId: firstTrackIdInCourse,
        lessonId: firstLessonsIdInFirstTrack,
        path: frontend.track.url({ organizationId, workspaceId, courseId, trackId: String(firstTrackIdInCourse) }),
      })}
      key="next-btn"
      className="lp-academy-modal__footer-actions-next"
    >
      Next Course
    </LpButtonAsLink>
  );
};

export default NextCourseButton;
