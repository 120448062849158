import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { RootState } from 'state/root_reducer';

export const getOutpostsById = (state: RootState) => state.entities.academyOutpostTrackLessons;

const getOutpostTrackLessonsForOutpostLocation = createSelector(
  (_: RootState, outpostLocation: OutpostLocation) => outpostLocation,
  getOutpostsById,
  (outpostLocation, outpostTrackLessonMap) => {
    return Object.values(outpostTrackLessonMap).filter(
      (outpostTrackLesson) => outpostTrackLesson.outpost === outpostLocation,
    );
  },
);

export const getOutpostTrackLessonsSortedByPriorityForOutpostLocation = createSelector(
  (state: RootState, outpostLocation: OutpostLocation) =>
    getOutpostTrackLessonsForOutpostLocation(state, outpostLocation),
  (outpostsForLocation) => {
    return sortBy(outpostsForLocation, (outpost) => outpost.priority);
  },
);
