import { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { RateSheetType } from 'daos/enums';
import { RateSheet } from 'daos/model_types';
import { itemAccessPermission } from 'daos/permission';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { hasAccess } from 'hooks/use_has_access';
import { getItemForId } from 'state/entities/selectors/item';
import {
  getActiveCurrentWorkspaceBillingRateSheets,
  getActiveCurrentWorkspacePayRateSheets,
} from 'state/entities/selectors/rate_sheet';

interface RateSheetDropdownProps {
  className?: string;
  rateSheetId?: string | null;
  disabled?: boolean;
  isOpen?: boolean;
  itemId: number;
  placeholderText?: string;
  onChange: (value: string | null) => void;
  onClose?: () => void;
  useOnGrid?: boolean;
  dataTestid?: string;
  rateSheetType: RateSheetType;
}

export function RateSheetDropdown({
  className,
  rateSheetId,
  disabled,
  isOpen,
  useOnGrid,
  onChange,
  onClose,
  placeholderText = 'Select',
  itemId,
  dataTestid,
  rateSheetType,
}: RateSheetDropdownProps) {
  const item = useSelector((state) => getItemForId(state, itemId));
  const rateSheets = useSelector((state) => {
    return rateSheetType === RateSheetType.BillingRate
      ? getActiveCurrentWorkspaceBillingRateSheets(state)
      : getActiveCurrentWorkspacePayRateSheets(state);
  });

  const rateSheetOptions = useMemo(
    () =>
      rateSheets
        .filter((rateSheet: RateSheet) => String(rateSheet.id) === rateSheetId || !rateSheet.archived)
        .reduce<Array<DropdownItemProps>>(
          (prev, rateSheet) => {
            prev.push({
              key: `${rateSheet.id}`,
              search: rateSheet.name.toLowerCase(),
              text: (
                <span>
                  {rateSheet.name}
                  {rateSheet.archived ? ' (archived)' : ''}
                </span>
              ),
              value: `${rateSheet.id}`,
            });

            return prev;
          },
          [{ text: <span className="rate-sheet-none">None</span>, search: 'none', value: 'none', key: 'none' }],
        ),
    [rateSheetId, rateSheets],
  );

  if (!item) {
    return null;
  }

  const hasItemAccess = hasAccess(itemAccessPermission(item.itemType), item);

  const onChangeHandler = (_: SyntheticEvent, { value }: DropdownProps) => {
    onChange(value === 'none' ? null : (value as string));
  };

  const rateSheetDropdown = (
    <Dropdown
      className={className}
      onChange={onChangeHandler}
      closeOnChange
      disabled={!hasItemAccess || disabled}
      options={rateSheetOptions}
      selection
      placeholder={placeholderText}
      selectOnNavigation={false}
      value={rateSheetId ?? ''}
      search={lpDropdownSearch}
      open={isOpen}
      onClose={onClose}
      data-testid={dataTestid}
    />
  );

  return useOnGrid ? <PortalDropdown>{rateSheetDropdown}</PortalDropdown> : rateSheetDropdown;
}
