import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserType } from 'daos/enums';
import { OrganizationUserFilter } from 'daos/filter_properties';
import { OrganizationUser } from 'daos/model_types';
import { OrganizationApiTokens, OrganizationApiToken } from 'daos/organization_api_tokens';
import { OrganizationUserDao } from 'daos/organization_user';
import { filterAnd, filterEq } from 'daos/shared';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';

export const useFetchApiTokensAndUsers = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);

  const [apiTokens, setApiTokens] = useState<Array<OrganizationApiToken>>([]);
  const [orgUsers, setOrgUsers] = useState<ReadonlyArray<OrganizationUser>>([]);

  const [fetchingTokens, setFetchingTokens] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);

  const fetchApiTokens = useCallback(() => {
    setFetchingTokens(true);
    const { uuid } = dispatch(
      OrganizationApiTokens.fetchAll({
        organizationId,
      }),
    );

    dispatch(
      awaitRequestFinish<Array<OrganizationApiToken>>(uuid, {
        onSuccess: ({ data }) => setApiTokens(data),
        onFinish: () => setFetchingTokens(false),
      }),
    );
  }, [dispatch, organizationId, setApiTokens]);

  const fetchOrgUsers = useCallback(() => {
    setFetchingUsers(true);
    const { uuid } = dispatch(
      OrganizationUserDao.fetchAll(
        { organizationId },
        {
          filter: filterAnd(
            filterEq(OrganizationUserFilter.UserType, UserType.Member),
            filterEq(OrganizationUserFilter.DashboardGuest, false),
          ),
          include: { includeUser: true },
        },
      ),
    );
    dispatch(
      awaitRequestFinish<ReadonlyArray<OrganizationUser>>(uuid, {
        onSuccess: ({ data }) => setOrgUsers(data),
        onFinish: () => setFetchingUsers(false),
      }),
    );
  }, [dispatch, organizationId, setOrgUsers]);

  return {
    apiTokens,
    setApiTokens,
    orgUsers,
    fetchingInProgress: fetchingTokens || fetchingUsers,
    fetchApiTokens,
    fetchOrgUsers,
  };
};
