import ItemIcon from 'containers/shared/item_icon';
import ItemLink from 'containers/shared/item_link';
import { ChangeActionType, ChangeModelType, ItemType } from 'daos/enums';
import { Item } from 'daos/model_types';
import { ChangeEntity } from 'daos/types';
import { CIRCULAR_BULLET_POINT } from 'lib/constants';
import { getItemNameForItem, itemTypeToSingularDisplay } from 'lib/display_helpers/item_display_name';
import { isAssignment } from 'state/entities/selectors/item';

import { changeDescriber } from './helpers';

interface ChangeDescriptionProps {
  itemType?: ItemType;
  changeActionType: ChangeActionType;
  changeRecordType: ChangeModelType;
  fieldName?: string;
}

export function ChangeDescription({ itemType, changeActionType, changeRecordType, fieldName }: ChangeDescriptionProps) {
  const changedItemType = itemType && itemTypeToSingularDisplay[itemType].toLocaleUpperCase();
  const changeDescriptionText = changeDescriber(changeActionType, changeRecordType, fieldName);

  const isItemInsert = changeActionType === ChangeActionType.INSERT && changeRecordType === ChangeModelType.Items;

  return isItemInsert ? (
    <div>{`${changedItemType} ${changeDescriptionText}:`}</div>
  ) : (
    <div>{changeDescriptionText}</div>
  );
}

const ItemDescriptionIconAndName = ({ itemId }: { itemId: number }) => {
  return (
    <div className="lp-change__card-content-description-info-item">
      <ItemIcon className={'lp-change__card-content-description-icon'} itemId={itemId} />
      <ItemLink itemId={itemId} openScheduleTab={false} />
    </div>
  );
};

const AssignmentsDescription = ({ item }: { item: Item }) => {
  if (!item.parent?.id) {
    return null;
  }

  const itemName = getItemNameForItem(item);

  return (
    <>
      <div className={'lp-change__card-content-description-info-link lp-change__card-content-description-info-item'}>
        {CIRCULAR_BULLET_POINT} <span className="lp-change__card-content-description-info-item-name">{itemName} </span>
      </div>
      on
      <ItemDescriptionIconAndName itemId={item.parent.id} />
    </>
  );
};

export function ItemDescription({
  changeItemId,
  item,
  changeRecord,
  changeActionType,
}: {
  changeItemId: number;
  item: Item;
  changeRecord: ChangeEntity;
  changeActionType: ChangeActionType;
}) {
  if (changeRecord?.type === ChangeModelType.ItemFiles && changeActionType === ChangeActionType.UPDATE) {
    return null;
  }

  if (isAssignment(item)) {
    return <AssignmentsDescription item={item} />;
  }

  return <ItemDescriptionIconAndName itemId={changeItemId} />;
}
