import { Card } from 'semantic-ui-react';

import TaskIconMaker from 'containers/shared/task_icon_maker';
import { TaskStatus } from 'daos/model_types';
import { GuestTaskCardAssignees } from 'features/common/task_board/guest/avatars';
import { GuestBoardTask, GuestTaskBoardTaskStatus } from 'features/common/task_board/guest/types';
import { GuestTaskBoardCardFooter } from 'features/common/task_board/task_card/task_card_footer';
import { getContrast } from 'lib/color';
import { formatAsStoryPoints } from 'lib/display_helpers/format_as_story_points';
import { useIsItemPlanned } from 'lib/planned_work_helpers';

export const GuestTaskCard = ({ task, taskStatus }: { task: GuestBoardTask; taskStatus: GuestTaskBoardTaskStatus }) => {
  const isPlanned = useIsItemPlanned(task.id ?? 0);

  return (
    <div
      className="ui card lp-task-board__card"
      style={{
        backgroundColor: `#${taskStatus.color}`,
      }}
    >
      <Card.Content className="lp-task-board__card-header" style={{ backgroundColor: `#${taskStatus.color}` }}>
        <Card.Description>
          <div className="lp-task-board__card-header-ancestry" style={{ color: getContrast(taskStatus.color) }}>
            {task.ancestry.map(
              (ancestor) =>
                !!ancestor.name && (
                  <div className="lp-task-board__card-header-ancestry-row" key={ancestor.id}>
                    {ancestor.name}
                  </div>
                ),
            )}
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content className="lp-task-board__card-task lp-task-board__card-task-guest">
        <Card.Description>
          <TaskIconMaker taskStatus={taskStatus as TaskStatus} isPlanned={isPlanned} /> {task.name}
        </Card.Description>
        <Card.Meta>
          <GuestTaskCardAssignees assignees={task.assignees} />
        </Card.Meta>
      </Card.Content>
      <GuestTaskBoardCardFooter
        showSchedulingLimitsLabel={task.hasSchedulingLimit}
        showDependencyLabel={task.hasSuccessorOrPredecessor}
        showRiskLabel={task.hasRisk}
        asapStatus={task.scheduleDirective}
        metricsMessage={task.totalWorkExpected}
        timelineMessage={task.storyPointsValue ? formatAsStoryPoints(task.storyPointsValue) : ''}
        taskStatusColor={taskStatus.color}
      />
    </div>
  );
};
