import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { LpMenuItemAsLink } from 'features/common/as_components';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { useFavoriteData } from 'features/favorite/hooks/use_favorite_data';
import { reportLeftMenuSegmentOriginAction } from 'features/organization/left_nav/helpers';
import { OriginAction } from 'lib/avo/client';
import { getCurrentWorkspaceFavoritesByPriority } from 'state/entities/selectors/favorite';

interface FavoriteListItemProps {
  favoriteId: number;
}

export const FavoriteListItem = ({ favoriteId }: FavoriteListItemProps) => {
  const { favoriteName, favoriteColor, favoriteUrlTo, favoriteSubSection } = useFavoriteData(favoriteId);
  const location = useLocation();
  const favorites = useSelector(getCurrentWorkspaceFavoritesByPriority);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  if (!favoriteName || !favoriteUrlTo || !favoriteSubSection) {
    return null;
  }

  return (
    <LpMenuItemAsLink
      key={favoriteId}
      to={favoriteUrlTo}
      active={false}
      onClick={() =>
        reportLeftMenuSegmentOriginAction({
          originAction: OriginAction.CLICKED_ON_FAVORITE,
          workspaceId: workspaceId,
          pathName: location.pathname,
          favoriteType: favoriteSubSection,
          numberOfFavorites: favorites.length,
        })
      }
    >
      <div
        className="favorite-item__line"
        style={{
          borderLeft: `6px solid #${favoriteColor}`,
        }}
      />
      <div className="favorite-item__info">
        <p>{favoriteName}</p>
        <p>{favoriteSubSection}</p>
      </div>
    </LpMenuItemAsLink>
  );
};
