import { request } from 'lib/api';

import { ItemType } from './enums';
import { Item } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { ItemIncludes } from './types';
import { backend } from './urls';

const includes = ({ includeEstimates, includeTimesheetEntries }: ItemIncludes = {}) =>
  reduceIncludedOptions([includeEstimates && 'estimates', includeTimesheetEntries && 'timesheetEntries']);

interface AssignmentsParams {
  organizationId: number;
  workspaceId: number;
}

interface SingleAssignmentParams extends AssignmentsParams {
  itemId: number;
}

interface AssignmentOptions {
  include?: ItemIncludes;
}

const { createBody, deleteBody, updateBody, resource } = defineModel({
  apiType: 'items',
  itemType: 'assignments',
  relationships: ['organizationUser', 'parent', 'workspace'],
  type: 'ASSIGNMENT',
});

const { ASSIGNMENT, resourceId } = resource;

export interface Assignment extends Item {
  readonly itemType: ItemType.ASSIGNMENTS;
}

const assignmentCreate = (params: AssignmentsParams, assignment: Partial<Assignment>) =>
  request(backend.items.url(params), ASSIGNMENT, createBody(assignment));

const assignmentDestroy = (params: SingleAssignmentParams, assignmentId: number) =>
  request(backend.item.url(params), ASSIGNMENT, deleteBody(assignmentId));

const assignmentUpdate = (
  params: SingleAssignmentParams,
  assignment: Partial<Assignment>,
  { include }: AssignmentOptions = {},
) =>
  request(backend.item.url(params, { include: includes(include) }), ASSIGNMENT, updateBody(params.itemId, assignment));

export const AssignmentDao = {
  create: assignmentCreate,
  destroy: assignmentDestroy,
  id: resourceId,
  update: assignmentUpdate,
} as const;
