import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { Input } from 'semantic-ui-react';

import { IconDefinition, LpIcon, timesCircleSolid as clearInputIcon, filterLight } from 'features/common/lp_icon';

import './index.scss';

export interface LpSearchOptionalProps {
  className?: string;
  onKeyDown?: (event: KeyboardEvent) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  icon?: IconDefinition;
  placeholder?: string;
}

interface LpSearchInputProps {
  initialValue: string;
  onChange: (_: SyntheticEvent<Element, Event>, { value }: { value: string }) => void;
  onClear: () => void;
  isDirty: boolean;
  autoFocus: boolean;
  disabled: boolean;
  icon: IconDefinition;
  placeholder: string;
  className?: string;
  onKeyDown?: (event: KeyboardEvent) => void;
}

const LpSearchInput = ({
  className,
  onKeyDown,
  initialValue,
  onChange,
  onClear,
  isDirty,
  autoFocus,
  disabled,
  icon = filterLight,
  placeholder,
}: LpSearchInputProps) => {
  return (
    <span className="lp-search-input-icon-container">
      <Input
        autoFocus={autoFocus}
        className={classNames(
          className,
          'lp-search-input',
          { dirty: isDirty },
          initialValue.length && 'lp-search-input--has-content',
        )}
        icon={
          <i className={classNames('icon', 'lp-search-input__filter-icon')}>
            <LpIcon className="icon" icon={icon} size="lg" color="grey" />
          </i>
        }
        onChange={onChange}
        value={initialValue}
        iconPosition="left"
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        type="text"
        disabled={disabled}
        data-testid="lp-search-input"
      />
      <LpIcon
        icon={clearInputIcon}
        onClick={onClear}
        className={classNames(
          'lp-search-input__clear-icon',
          { 'lp-search-input__clear-icon--enabled': !disabled },
          { 'lp-search-input__clear-icon--disabled': disabled },
          !initialValue.length && 'lp-search-input__clear-icon--no-content',
        )}
        data-testid="lp-search-input__clear-icon"
      />
    </span>
  );
};

export default LpSearchInput;
