import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import { CustomFieldType } from 'daos/enums';
import { PicklistChoice, CustomField, OrganizationUser } from 'daos/model_types';
import PicklistDropdown from 'features/common/inputs/dropdowns/picklist_dropdown';
import UserDropdown, { UserDropdownPlaceHolderText } from 'features/common/inputs/dropdowns/user_dropdown';
import { getPicklistChoicesSortedByPriorityForFieldId } from 'state/entities/selectors/custom_field';
import {
  getCurrentWsUserToOrgUserMapping,
  getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername,
  getCurrentOrgUserToWsUserMapping,
} from 'state/entities/selectors/user';

import { updateDropdownChoices } from './helpers';
import './custom_field_value_input.scss';

interface CustomFieldValueInputProps {
  field: CustomField;
  handleChange: (id: number | boolean | undefined) => void;
  selectedValue?: string | number | boolean;
  selectedMultiValues?: ReadonlyArray<number | string | boolean>;
  disabled: boolean;
}

const CustomFieldValueInput = ({
  field,
  handleChange,
  selectedValue,
  selectedMultiValues,
  disabled,
}: CustomFieldValueInputProps) => {
  const orgUsers = useSelector(getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername);
  const picklistOptions = useSelector((state) => getPicklistChoicesSortedByPriorityForFieldId(state, field.id));
  const currentWsUserIdsByOrgUserId = useSelector(getCurrentOrgUserToWsUserMapping);
  const orgUserIdsByCurrentWsUserId = useSelector(getCurrentWsUserToOrgUserMapping);
  const fieldType = field.fieldType;

  const handleCheckBoxChange = useCallback(
    (_: React.SyntheticEvent, { checked }: CheckboxProps) => {
      handleChange(checked);
    },
    [handleChange],
  );

  const handlePicklistChange = useCallback(
    (_: SyntheticEvent, { value }: { value: number | undefined }) => {
      handleChange(value);
    },
    [handleChange],
  );

  const handleUserChange = useCallback(
    (_: SyntheticEvent, { value }: { value: number | undefined }) => {
      const workspaceUserId = value ? currentWsUserIdsByOrgUserId[value] : undefined;
      handleChange(workspaceUserId);
    },
    [currentWsUserIdsByOrgUserId, handleChange],
  );

  const updatedMultiPicklistChoiceOptions = useMemo(
    () => updateDropdownChoices<PicklistChoice>(picklistOptions, selectedMultiValues, selectedValue),
    [picklistOptions, selectedMultiValues, selectedValue],
  );

  const updatedOrgUserOptions = useMemo(() => {
    if (fieldType !== CustomFieldType.MULTI_USER && fieldType !== CustomFieldType.USER) {
      return [];
    }
    const selectedOrgUserIds = selectedMultiValues?.reduce((orgUserIds: Array<number>, curr) => {
      const wsUserId = (curr as number | undefined) ?? undefined;
      const orgUserId = wsUserId ? orgUserIdsByCurrentWsUserId[wsUserId] : undefined;
      const isSelectedWsUser = selectedValue !== undefined && wsUserId === selectedValue;

      if (orgUserId && !isSelectedWsUser) {
        orgUserIds.push(orgUserId);
      }
      return orgUserIds;
    }, []);
    return updateDropdownChoices<OrganizationUser>([...orgUsers], selectedOrgUserIds, selectedValue);
  }, [fieldType, orgUserIdsByCurrentWsUserId, orgUsers, selectedMultiValues, selectedValue]);

  const hasSelectedValue = !!selectedValue;
  const customFieldValueInput = useMemo(() => {
    switch (fieldType) {
      case CustomFieldType.CHECKBOX:
        return <Checkbox checked={hasSelectedValue} onChange={handleCheckBoxChange} disabled={disabled} />;
      case CustomFieldType.CURRENCY:
        return <>{CustomFieldType.CURRENCY}</>;
      case CustomFieldType.DATE:
        return <>{CustomFieldType.DATE}</>;
      case CustomFieldType.MULTILINE_TEXT:
        return <>{CustomFieldType.MULTILINE_TEXT}</>;
      case CustomFieldType.ITEM:
      case CustomFieldType.MULTI_ITEM:
        return <span>item</span>;
      case CustomFieldType.LINK:
      case CustomFieldType.MULTI_LINK:
        return <span>link</span>;
      case CustomFieldType.NUMERIC:
        return <>{CustomFieldType.NUMERIC}</>;
      case CustomFieldType.PICKLIST:
      case CustomFieldType.MULTI_PICKLIST:
        return (
          <PicklistDropdown
            placeHolder={'Add'}
            fieldChoiceId={selectedValue as number}
            picklist={updatedMultiPicklistChoiceOptions}
            onChange={handlePicklistChange}
            disabled={disabled}
          />
        );
      case CustomFieldType.TEXT:
      case CustomFieldType.MULTI_TEXT:
        return <>text</>;
      case CustomFieldType.USER:
      case CustomFieldType.MULTI_USER: {
        const wsUserId = selectedValue as number | undefined;
        const selectedUserOrgUserId = wsUserId ? orgUserIdsByCurrentWsUserId[wsUserId] : undefined;

        return (
          <UserDropdown
            clearable={true}
            onChange={handleUserChange}
            placeholder={UserDropdownPlaceHolderText.SelectUser}
            selectedOrgUserId={selectedUserOrgUserId}
            orgUsers={updatedOrgUserOptions}
            newEntry={selectedValue === undefined}
            disabled={disabled}
          />
        );
      }
      default:
        return null;
    }
  }, [
    disabled,
    fieldType,
    hasSelectedValue,
    handleCheckBoxChange,
    selectedValue,
    updatedMultiPicklistChoiceOptions,
    handlePicklistChange,
    orgUserIdsByCurrentWsUserId,
    handleUserChange,
    updatedOrgUserOptions,
  ]);

  return <span className="custom-data-modal__custom-field-input">{customFieldValueInput}</span>;
};

export default CustomFieldValueInput;
