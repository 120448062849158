import { useSelector } from 'react-redux';
import { Checkbox, StrictCheckboxProps } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { OrganizationUserDao } from 'daos/organization_user';
import { useHasAccess } from 'hooks/use_has_access';
import { getWorkspaceUserById } from 'state/entities/selectors/user';
import { getCurrentWorkspace } from 'state/entities/selectors/workspace';

interface OrganizationAdminCheckBoxProps {
  isAdmin: boolean;
  isCurrentUser: boolean;
  orgUserId: number;
  wsUserId: number;
  updateOrgUser: ({ admin }: { admin: boolean }) => void;
}

const OrganizationAdminCheckBox = ({
  isAdmin,
  isCurrentUser,
  orgUserId,
  updateOrgUser,
  wsUserId,
}: OrganizationAdminCheckBoxProps) => {
  const handleAdminChange = (_: React.SyntheticEvent, { checked }: StrictCheckboxProps) =>
    updateOrgUser({ admin: !!checked });

  const targetWsUser = useSelector((state) => getWorkspaceUserById(state, wsUserId));
  const currentWS = useSelector(getCurrentWorkspace);

  const currentUserHasAdminAccessOnOrgUser = useHasAccess(Permission.ADMIN, OrganizationUserDao.id(orgUserId));

  if (!targetWsUser || !currentWS) {
    return null;
  }

  return (
    <Checkbox
      checked={isAdmin}
      className="user-profile__table-checkbox"
      disabled={!currentUserHasAdminAccessOnOrgUser || isCurrentUser || !!targetWsUser.disconnectedAt}
      onChange={handleAdminChange}
    />
  );
};

export default OrganizationAdminCheckBox;
