import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TaskStatusIcon } from 'containers/shared/task_icon_maker';
import { SchedulingType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { TaskBoardWidgetData } from 'features/dashboards_v2/widget/widgets/task_board/types';
import { useHasTeam25TeamBasedPlanning } from 'hooks/use_has_feature';
import { progressRatio } from 'lib/helpers';
import { mergeEntities } from 'state/entities/slice';
import './index.scss';

function getInvertedPercent(count: number, maxCount: number): string {
  const percent = progressRatio(count, maxCount) * 100;
  const minValue = percent > 0 ? Math.max(0, percent) : 0;

  return (minValue - 100).toFixed(0);
}

export const TaskBoardWidget = ({ widget }: { widget: WidgetWithData }) => {
  const dispatch = useDispatch();
  const data = widget.data as TaskBoardWidgetData;

  useEffect(() => {
    dispatch(
      mergeEntities({
        items: {
          [data.item.id]: data.item,
        },
      }),
    );
  }, [data.item, dispatch]);

  const hasTeam25TeamBasedPlanning = useHasTeam25TeamBasedPlanning();

  return (
    <RenderedWidget
      body={
        <div className="task-board-widget__body">
          <AnimatePresence initial={false}>
            {data.statuses.map((taskStatus) => (
              <motion.div
                layout
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                className="task-board-widget__body-column"
                key={taskStatus.id}
              >
                <TaskStatusIcon
                  className="task-board-widget__body-column-icon"
                  hasToolTip={false}
                  schedulingType={taskStatus.schedulingType ?? SchedulingType.Scheduled}
                  color={`#${taskStatus.color}`}
                  isPlanned={(hasTeam25TeamBasedPlanning && data.item.teamPlanned) ?? false}
                />
                <div className="task-board-widget__body-column-progress">
                  <div
                    className="task-board-widget__body-column-progress-bar"
                    style={{
                      backgroundColor: `#${taskStatus.color}`,
                      transform: `translateY(${getInvertedPercent(taskStatus.count, data.maxTaskCount)}%)`,
                    }}
                  />
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      }
    />
  );
};
