import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { WorkspaceUser } from 'daos/model_types';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { useHasAccess } from 'hooks/use_has_access';
import { getCurrentOrganization } from 'state/entities/selectors/organization';

import { isTimesheetExporterCheckboxDisabled } from './helpers';
interface TimesheetExporterCheckboxProps {
  isAdmin: boolean;
  isCurrentWsUser: boolean;
  isManager: boolean;
  isExporter: boolean;
  workspaceUserId: number;
  isWsUserDisconnected: boolean;
  updateWsUser: (updateProps: Partial<WorkspaceUser>) => void;
}

export const TimesheetExporterCheckbox = ({
  isAdmin,
  isCurrentWsUser,
  isManager,
  isExporter,
  isWsUserDisconnected,
  workspaceUserId,
  updateWsUser,
}: TimesheetExporterCheckboxProps) => {
  const currentOrg = useSelector(getCurrentOrganization);

  const hasUpgradedTimeTracking = !!currentOrg?.flags.hasUpgradedTimeTracking;

  const hasAdminAccess = useHasAccess(Permission.ADMIN, WorkspaceUserDao.id(workspaceUserId));

  const handleTsExporter = (_: React.SyntheticEvent, { checked }: CheckboxProps) => {
    updateWsUser({ timesheetExporter: checked });
  };

  const isDisabled = isTimesheetExporterCheckboxDisabled({
    isAdmin,
    isManager,
    isCurrentWsUser,
    isWsUserDisconnected,
    hasUpgradedTimeTracking,
    hasAdminAccess,
  });

  return (
    <Checkbox
      checked={isExporter || isAdmin}
      className="user-profile__table-checkbox"
      data-testid="checkbox"
      disabled={isDisabled}
      onChange={handleTsExporter}
    />
  );
};
