import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { Model, ResourceId, Organization, OrganizationUser } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { resource, arrayBody } = defineModel({
  apiType: 'organizationSsoUsers',
  relationships: [],
  type: 'ORGANIZATION_SSO_USERS',
});

export interface OrganizationSsoUser extends Model<'organizationSsoUsers'> {
  readonly organization: ResourceId<Organization>;
  readonly ssoEnabled: boolean;
  readonly organizationUser: ResourceId<OrganizationUser>;
  readonly ssoAvailable: boolean;
}

const { ORGANIZATION_SSO_USERS, resourceId } = resource;

interface Includes {
  includeOrganizationUser?: boolean;
}

interface OrgSsoUserOptions {
  filter?: string;
  include?: Includes;
}

const includes = ({ includeOrganizationUser }: Includes = {}) =>
  reduceIncludedOptions([includeOrganizationUser && 'organizationUsers']);

interface SsoUsersParams {
  organizationId: number;
}

interface SsoUserParams extends SsoUsersParams {
  organizationSsoUserId: number;
}

const fetchCurrentOrganizationSsoUsers = (params: SsoUsersParams, { filter, include }: OrgSsoUserOptions = {}) =>
  request(
    backend.organizationSingleSignOnUsers.url(params, { filter, include: includes(include) }),
    ORGANIZATION_SSO_USERS,
    {
      method: HttpMethod.GET,
    },
  );

const ssoUserFetch = (params: SsoUserParams) =>
  request(backend.organizationSingleSignOnUser.url(params), ORGANIZATION_SSO_USERS, {
    method: HttpMethod.GET,
  });

const ssoUsersUpsert = (params: SsoUsersParams, ssoUser: Array<Partial<OrganizationSsoUser>>) =>
  request(backend.organizationSingleSignOnUsersUpsert.url(params), ORGANIZATION_SSO_USERS, {
    ...arrayBody(undefined, ssoUser),

    method: HttpMethod.POST,
  });

export const OrganizationSsoUsersDao = {
  fetchCurrentOrgUsers: fetchCurrentOrganizationSsoUsers,
  fetch: ssoUserFetch,
  id: resourceId,
  upsert: ssoUsersUpsert,
} as const;
