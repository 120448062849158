import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { OrganizationUserOrderBy } from './enums';
import { OrganizationUser } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { updateBody, arrayBody, resource } = defineModel({
  apiType: 'organizationUsers',
  relationships: ['organization', 'user'],
  type: 'ORGANIZATION_USER',
});

const { ORGANIZATION_USER, resourceId } = resource;

interface Includes {
  includeUser?: boolean;
  includeWorkspaceUsers?: boolean;
  includeOrganizationUser?: boolean;
  includeSlackIntegration?: boolean;
  includeCustomFieldItems?: boolean;
  includeDashboardGuestWorkspaces?: boolean;
}

const includes = ({
  includeUser,
  includeWorkspaceUsers,
  includeOrganizationUser,
  includeSlackIntegration,
  includeCustomFieldItems,
  includeDashboardGuestWorkspaces,
}: Includes = {}) =>
  reduceIncludedOptions([
    includeUser && 'user',
    includeWorkspaceUsers && 'workspaceUsers.workspace',
    includeOrganizationUser && 'organizationUser.user',
    includeSlackIntegration && 'slackIntegration',
    includeCustomFieldItems && 'workspaceUsers.fieldValues.itemValue',
    includeDashboardGuestWorkspaces && 'dashboardGuests.workspaces',
  ]);

interface OrgUsersParams {
  organizationId: number;
}

interface SingleOrgUserParam extends OrgUsersParams {
  organizationUserId: number;
}

interface OrgUserOptions {
  filter?: string;
  include?: Includes;
  query?: { order?: OrganizationUserOrderBy };
  skipReduce?: boolean;
  target?: string;
}

const createNonMember = (
  params: OrgUsersParams & { workspaceId: number; userType: string },
  data: Partial<OrganizationUser>,
  { include }: OrgUserOptions = {},
) =>
  request(backend.nonMemberUser.url(params, { include: includes(include) }), ORGANIZATION_USER, {
    body: JSON.stringify({ data }),
    method: HttpMethod.POST,
  });

const createNonMemberBulk = (
  params: OrgUsersParams & { workspaceId: number; groupId: number },
  data: Array<Partial<OrganizationUser>>,
  { include }: OrgUserOptions = {},
) =>
  request(backend.organizationResources.url(params, { include: includes(include) }), ORGANIZATION_USER, {
    ...arrayBody(undefined, data),
    method: HttpMethod.POST,
  });

const bulkUpdate = (
  params: OrgUsersParams & { workspaceId: number },
  data: Array<Partial<OrganizationUser>>,
  { include }: OrgUserOptions = {},
) =>
  request(backend.organizationUsersBulk.url(params, { include: includes(include) }), ORGANIZATION_USER, {
    ...arrayBody(undefined, data),
    method: HttpMethod.PATCH,
  });

const fetch = (params: SingleOrgUserParam, { include }: OrgUserOptions = {}) =>
  request(backend.organizationUser.url(params, { include: includes(include) }), ORGANIZATION_USER, {
    method: HttpMethod.GET,
  });

const fetchAll = (params: OrgUsersParams, { filter, query, include }: OrgUserOptions = {}) =>
  request(backend.organizationUsers.url(params, { filter, query, include: includes(include) }), ORGANIZATION_USER, {
    method: HttpMethod.GET,
  });

const orgUserUpdate = (
  params: SingleOrgUserParam,
  orgUser: Partial<OrganizationUser>,
  { include }: OrgUserOptions = {},
) =>
  request(
    backend.organizationUser.url(params, { include: includes(include) }),
    ORGANIZATION_USER,
    updateBody(params.organizationUserId, orgUser),
  );

export const OrganizationUserDao = {
  createNonMember,
  createNonMemberBulk,
  fetch,
  fetchAll,
  id: resourceId,
  update: orgUserUpdate,
  bulkUpdate,
} as const;
