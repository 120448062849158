import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { LpIcon } from 'features/common/lp_icon';
import { customIcon, CustomIcon } from 'lib/display_helpers/custom_icon';
import initials from 'lib/display_helpers/initials';
import { getGroupForId } from 'state/entities/selectors/group';
import { lpBrandWhite } from 'style/variables';

import customizeAvatarClassNames, { AvatarSize } from './avatar_helpers';

import './group_avatar.scss';

interface GroupAvatarProps {
  groupId: number;
  size?: AvatarSize;
  round?: boolean;
  marginRight?: boolean;
  color?: string;
  icon?: CustomIcon | null;
  defaultToNullIconIfNoSelectedIcon?: boolean;
  className?: string;
}

const GroupAvatar = ({
  groupId,
  size = AvatarSize.Medium,
  round,
  marginRight,
  color,
  icon,
  className,
  defaultToNullIconIfNoSelectedIcon = false,
}: GroupAvatarProps) => {
  const group = useSelector((state) => getGroupForId(state, groupId));

  if (!group) {
    return null;
  }

  let groupIcon = icon;
  if (!groupIcon) {
    if (defaultToNullIconIfNoSelectedIcon) {
      groupIcon = null;
    } else {
      groupIcon = group.icon ?? null;
    }
  }

  const groupColor = color ? color : group.color;

  return (
    <GroupAvatarDisplay
      name={group.name}
      color={groupColor}
      icon={groupIcon}
      className={className}
      size={size}
      round={round}
      marginRight={marginRight}
    />
  );
};

interface GroupAvatarDisplayProps {
  name: string;
  size?: AvatarSize;
  round?: boolean;
  marginRight?: boolean;
  color: string;
  icon: CustomIcon | null;
  className?: string;
}

export const GroupAvatarDisplay = ({
  name,
  size,
  round,
  marginRight,
  color,
  icon,
  className,
}: GroupAvatarDisplayProps) => {
  const groupStyles = {
    color: lpBrandWhite,
    backgroundColor: `#${color}`,
  };

  const groupIcon = icon ? customIcon(icon) : undefined;

  return (
    <div
      style={groupStyles}
      className={customizeAvatarClassNames({
        size,
        className: classNames('group-avatar', className),
        round,
        marginRight,
      })}
    >
      {groupIcon ? <LpIcon icon={groupIcon} /> : initials(name)}
    </div>
  );
};

export default GroupAvatar;
