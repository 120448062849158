import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { Organization, Model, ResourceId, User, OrganizationUser } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { updateBody, createBody, resource } = defineModel({
  apiType: 'organizationApiUsers',
  relationships: [],
  type: 'ORGANIZATION_API_USERS',
});

export interface OrganizationApiToken extends Model<'organizationApiUsers'> {
  readonly createdAt: string;
  readonly createdBy: ResourceId<User>;
  readonly description: string;
  readonly organizationId: ResourceId<Organization>;
  readonly organizationUserId: ResourceId<OrganizationUser>;
  readonly token: string;
  readonly tokenActive: boolean;
}

const { ORGANIZATION_API_USERS, resourceId } = resource;

interface ApiTokensParams {
  organizationId: number;
}

interface SingleApiTokenParams extends ApiTokensParams {
  tokenId: number;
}

const create = (params: ApiTokensParams, orgApiTokenPayload: Partial<OrganizationApiToken>) =>
  request(backend.organizationApiTokens.url(params), ORGANIZATION_API_USERS, createBody(orgApiTokenPayload));

const deleteToken = (params: SingleApiTokenParams) =>
  request(backend.organizationApiToken.url(params), ORGANIZATION_API_USERS, {
    method: HttpMethod.DELETE,
  });

const fetchAll = (params: ApiTokensParams) =>
  request(backend.organizationApiTokens.url(params), ORGANIZATION_API_USERS, {
    method: HttpMethod.GET,
  });

const updateToken = (params: SingleApiTokenParams, orgApiTokenPayload: Partial<OrganizationApiToken>) =>
  request(
    backend.organizationApiToken.url(params),
    ORGANIZATION_API_USERS,
    updateBody(params.tokenId, orgApiTokenPayload),
  );

export const OrganizationApiTokens = {
  id: resourceId,
  create,
  delete: deleteToken,
  fetchAll,
  update: updateToken,
} as const;
