import { Dispatch as ReduxDispatch } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { Dispatch, Dispatch as ReactDispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router';
import { Route, useHistory } from 'react-router-dom';

import { User } from 'daos/model_types';
import { SystemSettingsDao } from 'daos/system_settings';
import { UserDao } from 'daos/user';
import { Authenticated } from 'features/authentication/authenticated';
import { AuthenticatedAndDisconnected } from 'features/authentication/authenticated/disconnected/routes';
import { EntityNotFound, EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { isServerError } from 'features/authentication/authenticated/errors/helpers/is_server_error';
import { ServerError } from 'features/authentication/authenticated/errors/server_error';
import { useHandleDisconnected } from 'features/authentication/helpers';
import { FrontloadDataProps, useFrontloadData } from 'features/authentication/hooks/use_frontload_data';
import { UnauthenticatedRoutes } from 'features/authentication/unauthenticated';
import { MissingAccountPage } from 'features/authentication/unauthenticated/missing_account';
import { TokenRoutes } from 'features/authentication/unauthenticated/token';
import { getIsCurrentUserAuthenticated } from 'features/common/current/selectors';
import { setCurrentUserId } from 'features/common/current/slice';
import { setApiError } from 'features/errors/slice';
import { useApplicationReloadEventHandler } from 'hooks/use_application_reload_event_handler';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import { ApiError } from 'lib/api/types';
import { frontend } from 'lib/urls';
import { resetRootState } from 'state/root_actions';

interface AuthenticationProps {
  selectedOrgId: number | undefined;
  selectedWsId: number | undefined;
  setSelectedOrgId: Dispatch<SetStateAction<number | undefined>>;
  setSelectedWsId: Dispatch<SetStateAction<number | undefined>>;
}

export const Authentication = ({
  selectedOrgId,
  selectedWsId,
  setSelectedOrgId,
  setSelectedWsId,
}: AuthenticationProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleDisconnected = useHandleDisconnected();

  const { token } = useQueryParams();

  const isUserAuthenticated = useSelector(getIsCurrentUserAuthenticated);

  const [fetchingCurrentUser, setFetchingCurrentUser] = useState(false);
  const [entityNotFoundType, setEntityNotFound] = useState<EntityTypeNotFound | undefined>(undefined);
  const [serverError, setServerError] = useState<ApiError | null>(null);

  const frontloadData = useFrontloadData({ setEntityNotFound, setSelectedOrgId, setSelectedWsId, setServerError });

  useApplicationReloadEventHandler();

  useEffect(() => {
    dispatch(SystemSettingsDao.fetch());
  }, [dispatch]);

  useEffect(() => {
    if (!fetchingCurrentUser && !token && !isUserAuthenticated) {
      setFetchingCurrentUser(true);
      fetchCurrentUser({
        dispatch,
        handleDisconnected,
        redirectToAccountLocked: () => history.push(frontend.emailConfirmationExpired.url({})),
        onConnected: ({ organizations }: FrontloadDataProps) => {
          frontloadData({ organizations });
        },
        setServerError,
      });
    }
  }, [dispatch, handleDisconnected, fetchingCurrentUser, frontloadData, history, isUserAuthenticated, token]);

  if (token) {
    return (
      <TokenRoutes
        token={token}
        setSelectedOrgId={setSelectedOrgId}
        setSelectedWsId={setSelectedWsId}
        setEntityNotFound={setEntityNotFound}
      />
    );
  }

  if (entityNotFoundType) {
    return (
      <EntityNotFound
        setEntityNotFound={setEntityNotFound}
        entityType={entityNotFoundType}
        setFetchingCurrentUser={setFetchingCurrentUser}
      />
    );
  }

  if (serverError) {
    return <ServerError error={serverError} />;
  }

  return (
    <Switch>
      <Route
        path={[
          frontend.authenticated.pattern,
          frontend.dashboardGuest.pattern,
          frontend.dashboardPassports.pattern,
          frontend.integrations.pattern,
          frontend.organization.pattern,
          frontend.portableCourse.pattern,
          frontend.portableAcademyLesson.pattern,
          frontend.portableDashboard.pattern,
          frontend.portableReport.pattern,
          frontend.portableLibraryItemDashboard.pattern,
          frontend.portableManageAccount.pattern,
          frontend.portableAcademy.pattern,
        ]}
        render={() => (
          <Authenticated
            selectedWsId={selectedWsId}
            selectedOrgId={selectedOrgId}
            setEntityNotFound={setEntityNotFound}
          />
        )}
      />

      <Route
        path={frontend.disconnected.pattern}
        render={() => (
          <AuthenticatedAndDisconnected
            setSelectedOrgId={setSelectedOrgId}
            setSelectedWsId={setSelectedWsId}
            setEntityNotFound={setEntityNotFound}
          />
        )}
      />

      {!isUserAuthenticated && (
        <Route exact path={frontend.missingAccount.pattern} render={() => <MissingAccountPage />} />
      )}

      <UnauthenticatedRoutes
        setSelectedOrgId={setSelectedOrgId}
        setSelectedWsId={setSelectedWsId}
        setEntityNotFound={setEntityNotFound}
      />
    </Switch>
  );
};

interface FetchCurrentUserProps {
  dispatch: ReduxDispatch;
  handleDisconnected: () => void;
  redirectToAccountLocked: () => void;
  onConnected: ({ organizations }: FrontloadDataProps) => void;
  setServerError: ReactDispatch<SetStateAction<ApiError | null>>;
}

const fetchCurrentUser = ({
  dispatch,
  handleDisconnected,
  redirectToAccountLocked,
  onConnected,
  setServerError,
}: FetchCurrentUserProps) => {
  const { uuid } = dispatch(
    UserDao.fetchCurrent({
      include: { includeActiveBanner: true, includeOrganization: true },
    }),
  );

  dispatch(
    awaitRequestFinish<User>(uuid, {
      onError: ({ errors }) => {
        const error = errors[0];
        const unauthorizedError = error?.status === 401;

        if (error) {
          if (isServerError(error.status)) {
            setServerError(error);
          }

          if (!unauthorizedError) {
            dispatch(setApiError(error));
          }
        }

        const accountLockedError = error?.code === 'account_locked';

        if (accountLockedError) {
          dispatch(resetRootState({ setCurrentUserIdNull: true }));
          redirectToAccountLocked();
        }
      },
      onSuccess: ({ data, entities }) => {
        const organizations = entities.organizations;
        const organizationUsers = entities.organizationUsers;

        dispatch(setCurrentUserId(data.id));

        const isDisconnected = isEmpty(organizations) || isEmpty(organizationUsers);

        if (isDisconnected) {
          return handleDisconnected();
        }

        onConnected({ organizations });
      },
    }),
  );
};
