import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

import { Workspace, WorkspaceUser } from 'daos/model_types';
import { EntityLookupById } from 'state/entities/types';
import { resetRootState } from 'state/root_actions';

export interface CurrentState {
  readonly organizationId?: number;
  readonly userId?: number | null;
  readonly workspaceId?: number;
  readonly disconnected: boolean;
  readonly reloadOnNextNavigation: boolean;
  readonly showContactSupportForm: boolean;
  readonly workspaceUsers: ReadonlyArray<WorkspaceUser>;
  readonly workspacesById: EntityLookupById<Workspace>;
}

export const initialState: CurrentState = {
  organizationId: undefined,
  userId: undefined,
  workspaceId: undefined,
  disconnected: false,
  reloadOnNextNavigation: false,
  showContactSupportForm: false,
  workspaceUsers: [],
  workspacesById: {},
};

export const loadingAppComplete = createAction('current/LOADING_APP_COMPLETE');

const currentSlice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    setCurrentOrganizationId: (state, action: PayloadAction<number | undefined>) => {
      state.organizationId = action.payload;
    },
    setCurrentUserDisconnected: (state, action: PayloadAction<boolean>) => {
      state.disconnected = action.payload;
    },
    setCurrentUserId: (state, action: PayloadAction<number | undefined>) => {
      state.userId = action.payload;
    },
    setCurrentWorkspaceId: (state, action: PayloadAction<number | undefined>) => {
      state.workspaceId = action.payload;
    },
    setReloadOnNextNavigation: (state) => {
      state.reloadOnNextNavigation = true;
    },
    setShowContactSupportForm: (state, action: PayloadAction<boolean>) => {
      state.showContactSupportForm = action.payload;
    },
    setCurrentWorkspaceUsers: (state, action: PayloadAction<ReadonlyArray<WorkspaceUser>>) => {
      state.workspaceUsers = castDraft(action.payload);
    },
    setCurrentWorkspaces: (state, action: PayloadAction<EntityLookupById<Workspace>>) => {
      state.workspacesById = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetRootState, (_, action) => {
      if (action.payload) {
        return { ...initialState, userId: null };
      }

      return initialState;
    });
  },
});

export const {
  setCurrentOrganizationId,
  setCurrentUserDisconnected,
  setCurrentUserId,
  setCurrentWorkspaceId,
  setReloadOnNextNavigation,
  setShowContactSupportForm,
  setCurrentWorkspaceUsers,
  setCurrentWorkspaces,
} = currentSlice.actions;

export default currentSlice.reducer;
