import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { Item } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { setItemDuplicationStatus } from 'features/common/duplicate/slice';
import { isAddingItemTypeBeyondAccountLimits } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/helpers/is_adding_item_type_beyond_account_limits';
import useUsageLimitsForOrg from 'hooks/use_usage_limit_for_org';
import { awaitRequestFinish } from 'lib/api';

export const useDuplicateItems = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const { canAddMoreTasks, canAddMoreProjects, reFetchOrgLimits } = useUsageLimitsForOrg();

  const isBeyondAccountLimit = useCallback(
    (items: ReadonlyArray<Item>) => {
      const canAddTasks = canAddMoreTasks(items.length);
      const canAddProjects = canAddMoreProjects(items.length);
      const itemTypes = items.reduce((acc: Array<ItemType>, item) => {
        if (!acc.includes(item.itemType)) {
          acc.push(item.itemType);
        }
        return acc;
      }, []);

      return isAddingItemTypeBeyondAccountLimits({
        itemTypes: itemTypes,
        canAddTasks,
        canAddProjects,
      });
    },
    [canAddMoreProjects, canAddMoreTasks],
  );

  return useCallback(
    (
      items: ReadonlyArray<Item>,
      {
        onBeyondLimitError,
        onSuccess,
      }: {
        onBeyondLimitError?: () => void;
        onSuccess?: () => void;
      },
    ) => {
      if (items.length < 1) {
        return;
      }

      if (isBeyondAccountLimit(items)) {
        return onBeyondLimitError?.();
      }

      const itemIds = items.map((item) => item.id);

      dispatch(setItemDuplicationStatus({ itemIds, isDuplicating: true }));

      const { uuid } = dispatch(ItemDao.duplicateBulk({ organizationId, workspaceId }, itemIds));

      dispatch(
        awaitRequestFinish(uuid, {
          onSuccess: () => onSuccess?.(),
          onFinish: () => {
            reFetchOrgLimits();
            dispatch(setItemDuplicationStatus({ itemIds, isDuplicating: false }));
          },
        }),
      );
    },
    [dispatch, isBeyondAccountLimit, organizationId, reFetchOrgLimits, workspaceId],
  );
};
